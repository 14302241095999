import React, { FC } from "react";

import styles from "./styles.module.scss";
import { Checkbox as CheckboxIcon, EmptyCheckbox } from "~icons";

interface IProps {
  value: boolean;
  onClick: (e: React.MouseEvent) => void;
}
export const Checkbox: FC<IProps> = ({ value, onClick }) => {
  return value ? (
    <div onClick={onClick} className={styles.checkboxContainer}>
      <CheckboxIcon />
    </div>
  ) : (
    <div onClick={onClick} className={styles.checkboxContainer}>
      <EmptyCheckbox />
    </div>
  );
};
