import { StrictModifierNames, Modifier } from "react-popper";
import { Options, createPopper } from "@popperjs/core";
import { useDraft } from "../../useDraft";
import { Button, PopperPopup } from "~components";
import { PublishingStatus, IDraft } from "~models";
import { DeleteModal, ShareCodeModal } from "../../modals";
import { FC } from "react";
import clsx from "clsx";
import {
  CopyTo,
  DotsVertical,
  Duplicate,
  FileDownload,
  FileNone,
  Pen,
  Refresh,
  Share,
  TrashAlt,
} from "~icons";

import styles from "./styles.module.scss";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";

interface IProps {
  draft: IDraft;
  index: number;
  updateDraftStatus: (status: PublishingStatus, index: number) => void;
  showTableLoader?: () => void;
  updateTableData?: () => void;
}

export const DraftItemSquare: FC<IProps> = ({
  draft,
  index,
  updateDraftStatus,
  showTableLoader,
  updateTableData,
}: IProps) => {
  const {
    myRole,
    modalRef,
    showPopper,
    showDeletionModal,
    showShareCodeModal,
    handleDelete,
    handlePublish,
    handleEdit,
    handleDownloadPdf,
    handleUnpublish,
    handleOpenPopper,
    handleClosePopper,
    handleDuplicate,
    handleCloseShareModal,
    handleOpenDeletionModal,
    handleCloseDeletionModal,
    handleOpenShareModal,
  } = useDraft({
    draft,
    index,
    updateDraftStatus,
    showTableLoader,
    updateTableData,
  });

  const isDark = useReactiveVar(darkTheme);

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-end",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  return (
    <div>
      <div className={styles.draftItem}>
        <div
          className={
            isDark ? clsx(styles.content, styles.contentDark) : styles.content
          }
        >
          <div className={styles.content__header}>
            <Button backgroundType={"green"} text="Edit" onClick={handleEdit} />

            <PopperPopup
              showPopper={showPopper}
              onClosePopper={handleClosePopper}
              onOpenPopper={handleOpenPopper}
              options={popperOptions}
              trigger={
                <DotsVertical fill={`${isDark ? "white" : "#0B0F1C"}`} />
              }
              popup={
                <div
                  ref={modalRef}
                  className={clsx(styles.draftItem__modal, {})}
                >
                  <div
                    className={styles.draftItem__modalItem}
                    onClick={handleEdit}
                  >
                    <Pen fill={`${isDark ? "white" : "#0B0F1C"}`} /> Edit
                  </div>

                  <div
                    onClick={handleDuplicate}
                    className={clsx(styles.draftItem__modalItem, styles.margin)}
                  >
                    <Duplicate fill={`${isDark ? "white" : "#0B0F1C"}`}/> Duplicate
                  </div>
                  <div className={styles.draftItem__modalItem}>
                    <CopyTo /> Copy to
                  </div>

                  <div className={styles.draftItem__modalUnderline} />
                  {draft.status !== PublishingStatus.Published && (
                    <div
                      onClick={handlePublish}
                      className={styles.draftItem__modalItem}
                    >
                      <Refresh width={20} height={20} fill={`${isDark ? "white" : "#0B0F1C"}`} />{" "}
                      {draft.status === PublishingStatus.Unpublished
                        ? "Publish"
                        : "Publish latest changes"}
                    </div>
                  )}

                  {draft.status === PublishingStatus.Published && (
                    <div
                      onClick={handleUnpublish}
                      className={styles.draftItem__modalItem}
                    >
                      <FileNone /> Unpublish
                    </div>
                  )}

                  <div className={styles.draftItem__modalUnderline} />

                  <div
                    onClick={handleOpenShareModal}
                    className={styles.draftItem__modalItem}
                  >
                    <Share /> Share Checkem
                  </div>
                  <div
                    className={styles.draftItem__modalItem}
                    onClick={handleDownloadPdf}
                  >
                    <FileDownload /> Generate PDF
                  </div>
                  {myRole !== "User" && (
                    <div
                      onClick={handleOpenDeletionModal}
                      className={styles.draftItem__modalItem}
                    >
                      <TrashAlt fill={`${isDark ? "white" : "#0B0F1C"}`} /> Delete
                    </div>
                  )}
                </div>
              }
            />
          </div>
          <p className={styles.content__title}>{draft.title}</p>
          <div
            className={clsx(styles.content__footer, {
              [styles.yellow]:
                draft.status === PublishingStatus.UnpublishedChanges,
              [styles.red]: draft.status === PublishingStatus.Unpublished,
              [styles.green]: draft.status === PublishingStatus.Published,
            })}
          >
            <div className={styles.content__footerDot} />
            <p>
              {draft.status === PublishingStatus.UnpublishedChanges
                ? "Unpublished Changes"
                : draft.status}
            </p>

            {draft.status !== PublishingStatus.Published && (
              <div
                className={styles.content__footerRefresh}
                onClick={handlePublish}
              >
                <Refresh fill="#29B473" />
              </div>
            )}
          </div>
        </div>
      </div>

      <DeleteModal
        isOpen={showDeletionModal}
        itemName="draft"
        onCloseModal={handleCloseDeletionModal}
        handleDelete={handleDelete}
      />

      <ShareCodeModal
        isOpen={showShareCodeModal}
        id={draft.id}
        type="Draft"
        onCloseModal={handleCloseShareModal}
      />
    </div>
  );
};
