import { PlusSymbol, Trash, TrashAlt } from "~icons";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { Button, Counter, Input, TextArea } from "~components";
import { useAssesmentItem } from "./useAssesmentItem";
import { IItem } from "~models";
import { Draggable } from "react-beautiful-dnd";
import FroalaEditor from "react-froala-wysiwyg";

interface IAssesment {
  title: string;
  description: string;
  findings: { name: string; score: number }[];
}

interface IProps {
  item: IItem;
  itemIndex: number;
  assesment: IAssesment;
  assesmentIndex: number;
  assesmentsLength: number;
}

export const AssesmentItem = ({
  item,
  itemIndex,
  assesment,
  assesmentIndex,
  assesmentsLength,
}: IProps) => {
  const {
    blockDeletingFindings,
    blockDeletingAssesment,
    handleTitleChange,
    handleDescriptionChange,
    handleFindingNameChange,
    handleFindingScoreChange,
    handleAddFinding,
    handleDeleteFinding,
    handleDeleteAssesment,
  } = useAssesmentItem({
    item,
    itemIndex,
    assesment,
    assesmentIndex,
    assesmentsLength,
  });
  return (
    <Draggable draggableId={`${assesmentIndex}`} index={assesmentIndex}>
      {(provided) => (
        <div
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={styles.assesmentItem}
        >
          <div className={styles.assesmentItem__header}>
            Assessment {assesmentIndex + 1}
            <div
              onClick={handleDeleteAssesment}
              className={clsx(styles.assesmentItem__headerButton, {
                [styles.disable]: blockDeletingAssesment,
              })}
            >
              <TrashAlt fill="#EF2F32" />
            </div>
          </div>

          <Input
            label="Title"
            placeholder="Enter title..."
            value={assesment.title}
            onChange={handleTitleChange}
          />

          <div className={styles.assesmentItem__description}>
          <div className={styles.assesmentItem__label}>Assessment</div>
            {/* <TextArea
              label="Assessment"
              placeholder="Enter description..."
              value={assesment.description}
              onChange={handleDescriptionChange}
            /> */}
            <FroalaEditor
                  model={assesment.description}
                  onModelChange={(value: string) => handleDescriptionChange(value)}
                  config={{
                    key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                    htmlUntouched: true,
                    listAdvancedTypes: false,
                    listAdvancedPositions: true,
                    fileupload: false,
                    // toolbarInline: true,
                    // toolbarVisibleWithoutSelection: true,
                    imageUpload: false,
                  }}
                />
          </div>

          <div className={styles.assesmentItem__finding}>
            <div>
              <p className={styles.assesmentItem__findingLabel}>Finding</p>
              {assesment.findings.map((item, index) => (
                <div
                  key={`${index} - assesment - input`}
                  className={styles.assesmentItem__findingItem}
                >
                  <Input
                    value={item.name}
                    placeholder="Enter finding..."
                    onChange={(value) => handleFindingNameChange(value, index)}
                  />
                </div>
              ))}
            </div>
            <div>
              <p className={styles.assesmentItem__findingLabel}>Score</p>
              {assesment.findings.map((item, index) => (
                <div
                  key={`${index} - assesment - counter`}
                  className={styles.assesmentItem__findingCounter}
                >
                  <Counter
                    allowNegative
                    value={item.score}
                    onChange={(value) => {
                      handleFindingScoreChange(`${value}`, index);
                    }}
                    autoFocus={false}
                  />
                  <div
                    className={clsx({
                      [styles.disable]: blockDeletingFindings,
                    })}
                    onClick={() =>
                      !blockDeletingFindings && handleDeleteFinding(index)
                    }
                  >
                    <Trash
                      stroke={
                        blockDeletingFindings ? `hsl(240, 11%, 96%)` : "#979DB1"
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.assesmentItem__findingButton}>
            <Button
              onClick={handleAddFinding}
              text="Add finding/score"
              backgroundType={"white"}
              showShadow
              textColor="#29B473"
              icon={<PlusSymbol fill="#29B473" />}
            />
          </div>

          <div className={styles.assesmentItem__underline} />
        </div>
      )}
    </Draggable>
  );
};
