import { Button, Input, Modal } from "~components";
import styles from "./styles.module.scss";
import { Link } from "~icons";
import { useCreateFolder } from "./useCreateFolder";

interface IProps {
  isOpen: boolean;
  isAdmin?: boolean;
  folderId?: number;
  onCloseModal: () => void;
  handleSuccess: () => void;
}

export const CreateFolderModal = ({
  isOpen,
  isAdmin,
  folderId,
  onCloseModal,
  handleSuccess,
}: IProps) => {
  const {
    showLoader,
    title,
    handleCloseModal,
    handleTitleChange,
    handleCreateButton,
  } = useCreateFolder({ isAdmin, folderId, onCloseModal, handleSuccess });
  if (!isOpen) return null;

  return (
    <Modal disableMediaStyles onCloseModal={handleCloseModal}>
      <div className={styles.modal}>
        <p className={styles.modal__title}>Enter Folder name</p>
        <div className={styles.modal__field}>
          <input
            value={title}
            onChange={(e) => handleTitleChange(e.target.value)}
            placeholder="Folder name"
          />
        </div>
        <div className={styles.modal__underline} />

        <Button
          loading={showLoader}
          disable={!title}
          text="Save"
          backgroundType={"green"}
          onClick={handleCreateButton}
        />
      </div>
    </Modal>
  );
};
