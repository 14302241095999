import { FC, ReactNode, useState } from "react";
import { ContactUsModal, PrivacyModal } from "~modals";
import { AppLogo } from "../../assets/icons";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { Navigate } from "react-router-dom";

interface IProps {
  children: ReactNode;
  modalClassName?: string;
  isOnboarding?: boolean;
}

export const OnboardingLayout: FC<IProps> = ({ children, modalClassName }) => {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const togglePivacyModal = () => setShowPrivacyModal(!showPrivacyModal);
  const toggleTermsModal = () => setShowTermsModal(!showTermsModal);
  const toggleContactModal = () => setShowContactModal(!showContactModal);

  return (
    <div className={styles.onboardingLayout}>
      <div className={styles.onboardingLayout__content}>
        <div>
          <div
            className={styles.onboardingLayout__logo}
            onClick={() => <Navigate to={"/dashboard"} replace />}
          >
            <AppLogo />
          </div>
          <div>
            <div
              className={clsx(styles.onboardingLayout__modal, modalClassName)}
            >
              {children}
            </div>
          </div>
        </div>

        <div className={styles.onboardingLayout__footer}>
          <p
            className={styles.onboardingLayout__footerButton}
            // onClick={togglePivacyModal}
          >
            <span onClick={togglePivacyModal}>Privacy Policy</span> &
            <span onClick={toggleTermsModal}>Terms of Service</span>
          </p>
          <span>·</span>
          <p
            className={styles.onboardingLayout__footerButton}
            onClick={toggleContactModal}
          >
            <span>Contact Us</span>
          </p>
        </div>
      </div>

      {showPrivacyModal && <PrivacyModal onCloseModal={togglePivacyModal} />}
      {showTermsModal && (
        <PrivacyModal onCloseModal={toggleTermsModal} isTermsOfService />
      )}
      {showContactModal && <ContactUsModal onCloseModal={toggleContactModal} />}
    </div>
  );
};
