import { Avatar, Button, Input, Modal, PaymentInput } from "~components";
import { useAddCard } from "./useAddCard";
import { IOrganization } from "~models";

import styles from "./styles.module.scss";

interface IProps {
  organization: IOrganization;
  handleCloseModal: () => void;
  handleFinish: () => void;
}

export const AddCardModal = ({
  handleCloseModal,
  organization,
  handleFinish,
}: IProps) => {
  const {
    formRef,
    handleSubmit,
    name,
    setName,
    isDisable,
    loading,
    handleAcceptButton,
  } = useAddCard(handleCloseModal, handleFinish);

  return (
    <Modal disableMediaStyles onCloseModal={handleCloseModal}>
      <div className={styles.addCard}>
        <p className={styles.addCard__title}>Add card</p>
        <p className={styles.addCard__subtitle}>
          Please enter your credit card details
        </p>
        <div className={styles.addCard__row}>
          {/* <div className={styles.addCard__image}>
            {!!organization.picture && <img src={organization.picture} />}
          </div> */}
          <Avatar
            uri={organization.picture}
            type={"organization"}
            item={organization}
            containerStyles={styles.addCard__image}
          />

          <p className={styles.addCard__name}>{organization.name}</p>
        </div>
        <div className={styles.addCard__input}>
          <Input
            value={name}
            onChange={setName}
            label="Full Name"
            placeholder="Enter your full name"
          />
        </div>

        <div className={styles.addCard__input}>
          <PaymentInput formRef={formRef} handleSubmit={handleSubmit} />
        </div>

        <div className={styles.addCard__buttons}>
          <Button
            onClick={handleCloseModal}
            backgroundType={"white"}
            text={"Cancel"}
          />
          <Button
            loading={loading}
            onClick={handleAcceptButton}
            disable={isDisable}
            backgroundType={"green"}
            text={"Add card"}
          />
        </div>
      </div>
    </Modal>
  );
};
