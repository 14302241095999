import { useEffect, useRef, useState } from "react";
import { useBuilder } from "../../context";
import {
  IActiveItem,
  ISection,
  defaultItem,
  defaultSection,
} from "../../constants";
import { useOutsideClick } from "~hooks";
import { useMutation } from "@apollo/client";
import {
  CHANGE_SECTION_ORDERS,
  CREATE_ITEM,
  DUPLICATE_SECTION,
  REMOVE_SECTION,
  UPDATE_SECTION,
} from "~mutations";
import { IItem } from "~models";

interface IProps {
  section: ISection;
  index: number;
}

export const useSection = ({ section, index }: IProps) => {
  const {
    setList,
    list,
    activeTabIndex,
    activeItem,
    setActiveItem,
    isDragging,
    error,
    pdfFormat,
  } = useBuilder();

  const popupContentRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);

  const [updateSection] = useMutation(UPDATE_SECTION);
  const [removeSection] = useMutation(REMOVE_SECTION);
  const [duplicateSection] = useMutation(DUPLICATE_SECTION);
  const [createItem] = useMutation(CREATE_ITEM);
  const [changeSectionsOrder] = useMutation(CHANGE_SECTION_ORDERS);

  const removeItemsKey = () => {
    let clonedSection: ISection = structuredClone(section);
    //@ts-ignore
    delete clonedSection["items"];
    return clonedSection;
  };

  const [prevSafeSection, setPrevSafeSection] = useState<string>(
    JSON.stringify(removeItemsKey())
  );

  const sectionLoading = section.items.findIndex((item) => item.id === 0) > -1;

  const contentRef = useRef<HTMLDivElement>(null);

  const [collapsed, setCollapsed] = useState(false);

  const isSectionActive =
    activeItem?.id === section.id &&
    activeItem.type === "section" &&
    !pdfFormat;

  const sectionIndex = list.tabs[activeTabIndex].sections.findIndex(
    (item) => item.id === section.id
  );

  const showError =
    error.sectionIds.findIndex((item) => item === section?.id) > -1;

  const enableActiveSection = () => {
    if (
      !!activeItem &&
      activeItem.id === section.id &&
      activeItem.type === "section"
    )
      return;
    setActiveItem({
      type: "section",
      id: section.id,
      sectionIndex: sectionIndex,
      sectionId: section.id,
    });
  };

  const toggleCollapsed = () => setCollapsed(!collapsed);

  const addFakeItem = () => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[sectionIndex].items = [
        ...state.tabs[activeTabIndex].sections[sectionIndex].items,
        {
          ...defaultItem,
          tabSectionId: section.id,
          id: 0,
          type: null,
        },
      ];
      return state;
    });
    if (section.collapsable && collapsed) setCollapsed(false);
  };

  const addRealItem = (realItem: IItem) => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[sectionIndex].items[
        state.tabs[activeTabIndex].sections[sectionIndex].items.length - 1
      ] = realItem;
      return state;
    });
    setActiveItem({
      type: "item",
      id: realItem.id,
      sectionIndex: sectionIndex,
      sectionId: section.id,
    });
  };

  const addItem = () => {
    addFakeItem();
    createItem({ variables: { input: { tabSectionId: section.id } } }).then(
      (resp) => addRealItem(resp.data.createSectionItem)
    );
  };

  const handleChangeName = (value: string) => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[sectionIndex].name = value;
      return state;
    });
  };

  const handleChangeDescription = (value: string) => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[sectionIndex].description = value;
      return state;
    });
  };

  const deleteSection = () => {
    if (!activeItem) return;
    removeSection({ variables: { id: section.id } });
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections.splice(activeItem?.sectionIndex, 1);
      return state;
    });
  };

  const addFakeSection = () => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections.splice(index + 1, 0, {
        ...defaultSection,
        id: 0,
        order: state.tabs[activeTabIndex].sections.length,
      });
      return state;
    });
  };

  const addRealSection = (realSection: ISection) => {
    setList((prev) => {
      let state = { ...prev };
      let index = state.tabs[activeTabIndex].sections.findIndex(
        (item) => item.id === 0
      );
      state.tabs[activeTabIndex].sections[index] = realSection;
      return state;
    });
  };

  const handleAddSection = () => {
    addFakeSection();
    handleClosePopup();

    updateSection({
      variables: {
        input: {
          id: section.id,
          name: section.name,
          description: section.description,
          color: section.color,
          checkbox: section.checkbox,
          collapsable: section.collapsable,
          startCollapsable: section.startCollapsable,
        },
      },
    }).then(async () => {
      duplicateSection({ variables: { id: section.id } }).then((resp) => {
        addRealSection(resp.data.duplicateTabSection);
      });
    });
  };

  const onSaveChanges = () => {
    if (prevSafeSection !== JSON.stringify(removeItemsKey())) {
      updateSection({
        variables: {
          input: {
            id: section.id,
            name: section.name,
            description: section.description,
            color: section.color,
            checkbox: section.checkbox,
            collapsable: section.collapsable,
            startCollapsable: section.startCollapsable,
          },
        },
      });
      setPrevSafeSection(JSON.stringify(removeItemsKey()));
    }
  };

  const handleOpenPopup = () => !pdfFormat && setShowPopup(true);
  const handleClosePopup = () => !pdfFormat && setShowPopup(false);
  useOutsideClick(popupContentRef, handleClosePopup, "mouseup");

  const moveItemDown = () => {
    const isSectionLastInTab =
      list.tabs[activeTabIndex].sections.length - 1 === sectionIndex;
    if (isSectionLastInTab) return;

    setList((prev) => {
      let state = { ...prev };
      let nextSection = structuredClone(
        state.tabs[activeTabIndex].sections[sectionIndex + 1]
      );
      state.tabs[activeTabIndex].sections[sectionIndex + 1] = section;
      state.tabs[activeTabIndex].sections[sectionIndex] = nextSection;
      changeSectionsOrder({
        variables: {
          input: {
            ids: state.tabs[activeTabIndex].sections.map((item) => item.id),
          },
        },
      });
      return state;
    });
  };

  const moveItemUp = () => {
    if (sectionIndex === 0) return;

    setList((prev) => {
      let state = { ...prev };
      let prevSection = structuredClone(
        state.tabs[activeTabIndex].sections[sectionIndex - 1]
      );
      state.tabs[activeTabIndex].sections[sectionIndex - 1] = section;
      state.tabs[activeTabIndex].sections[sectionIndex] = prevSection;
      changeSectionsOrder({
        variables: {
          input: {
            ids: state.tabs[activeTabIndex].sections.map((item) => item.id),
          },
        },
      });
      return state;
    });
  };

  useEffect(() => {
    onSaveChanges();
  }, [JSON.stringify(activeItem), isDragging]);

  return {
    isSectionActive,
    sectionLoading,
    popupContentRef,
    showPopup,
    enableActiveSection,
    showError,
    collapsed,
    sectionIndex,
    contentRef,
    pdfFormat,
    moveItemDown,
    moveItemUp,
    handleAddSection,
    deleteSection,
    handleOpenPopup,
    toggleCollapsed,
    addItem,
    handleChangeName,
    handleChangeDescription,
  };
};
