import { Button, ImagePicker, Input, Modal, SwitchField } from "~components";
import { useEditOrganization } from "./useEditOrganization";
import { Camera } from "~icons";
import clsx from "clsx";

import styles from "./styles.module.scss";
import { DeleteOrganization } from "./modals";

export const OrganizationEditing = () => {
  const {
    loading,
    name,
    isPrivate,
    error,
    isDisable,
    pictureUrl,
    updating,
    showDeleteModal,
    showDeleteLoader,
    setFile,
    handleSaveButton,
    handleNameChange,
    handleCancelButton,
    handlePrivateStatus,
    handlOpenDeleteModal,
    handleCloseDeleteModal,
    handleDeleteOrganization,
  } = useEditOrganization();

  return (
    <div className={styles.editOrganization}>
      <div className={styles.editOrganization__switchField}>
        <SwitchField
          text="Make your organization private"
          value={isPrivate}
          onClick={handlePrivateStatus}
        />
      </div>

      <p className={styles.editOrganization__subtitle}>
        If your organization is private, it’ll not be discoverable in the in-app
        search for non-organization users.
      </p>

      <ImagePicker
        containerClassName={styles.editOrganization__picker}
        saveImage={setFile}
      >
        <div
          className={clsx(styles.editOrganization__pickerAvatar, {
            [styles.exist]: !!pictureUrl,
          })}
        >
          {!!pictureUrl ? <img src={pictureUrl} /> : <Camera />}
        </div>

        <p>Change picture</p>
      </ImagePicker>

      <div className={styles.editOrganization__input}>
        <Input
          label="Organization Name"
          placeholder="Enter organization Name"
          error={error}
          value={name}
          onChange={handleNameChange}
        />
      </div>

      <div className={styles.editOrganization__buttons}>
        <Button
          loading={updating}
          onClick={handleSaveButton}
          text="Save"
          backgroundType={"green"}
          disable={isDisable}
        />
        <Button
          onClick={handleCancelButton}
          text="Cancel"
          backgroundType={"white"}
          showShadow
          disable={isDisable}
        />
      </div>

      <div className={styles.editOrganization__deleteButton}>
        <Button
          loading={showDeleteLoader}
          onClick={handlOpenDeleteModal}
          text="Delete organization"
          backgroundColor="#EF2F32"
          textColor="#FFFFFF"
        />
      </div>

      <DeleteOrganization
        showLoader={showDeleteLoader}
        isOpen={showDeleteModal}
        onCloseModal={handleCloseDeleteModal}
        handleDelete={handleDeleteOrganization}
      />
    </div>
  );
};
