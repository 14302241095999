import {
  ApolloClient,
  InMemoryCache,
  FetchResult,
  ApolloLink,
  createHttpLink,
  GraphQLRequest,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition, Observable } from "@apollo/client/utilities";
import {
  alertVar,
  choosedOrganizationVar,
  showPanelVar,
} from "../graphgl/localCache";
import { GraphQLError } from "graphql";
import { GraphQLClient } from "graphql-request";
import { REFRESH_TOKENS } from "~queries";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import {
  CachePersistor,
  LocalStorageWrapper,
  persistCache,
} from "apollo3-cache-persist";

interface Tokens {
  accessToken: string;
  refreshToken: string;
}

interface AccessToken {
  accessToken: string;
}

const httpLink = createHttpLink({
  uri: "https://api.resusc.io/graphql",
  // uri: "https://dev-api.resusc.io/graphql",
});

export const clientForQueryWithoutHook = (token: string | undefined | null) =>
  new GraphQLClient("https://api.resusc.io/graphql", {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

const isRefreshRequest = (operation: GraphQLRequest) => {
  return operation.operationName === "refreshToken";
};

// Returns accesstoken if opoeration is not a refresh token request
const returnTokenDependingOnOperation = (operation: GraphQLRequest) => {
  if (isRefreshRequest(operation))
    return localStorage.getItem("refreshToken") || "";
  else return localStorage.getItem("accessToken") || "";
};

const authLink = setContext((operation, { headers }) => {
  let token = returnTokenDependingOnOperation(operation);
  const choosedOrganization = choosedOrganizationVar();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      organizationId: choosedOrganization?.id,
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case "UNAUTHENTICATED":
            // ignore 401 error for a refresh request
            if (operation.operationName === "refreshToken") return;

            const observable = new Observable<FetchResult<Record<string, any>>>(
              (observer) => {
                // used an annonymous function for using an async function
                (async () => {
                  try {
                    const accessToken = await refreshToken();

                    if (!accessToken) {
                      throw new GraphQLError("Empty AccessToken");
                    }

                    // Retry the failed request
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);
                  } catch (err) {
                    observer.error(err);
                  }
                })();
              }
            );

            return observable;
        }
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

const wsLink = new GraphQLWsLink(
  createClient({
    url: "wss://api.resusc.io/graphql",
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  ApolloLink.from([errorLink, authLink, httpLink])
);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        choosedOrganization: {
          read() {
            return choosedOrganizationVar();
          },
        },
        alert: {
          read() {
            return alertVar();
          },
        },
        showPanel: {
          read() {
            return showPanelVar();
          },
        },
      },
    },
  },
});

export const client = new ApolloClient({
  link: splitLink,
  cache,
});

const refreshToken = async () => {
  try {
    let data: {
      refreshTokens: { access_token: string; refresh_token: string };
    } = await clientForQueryWithoutHook(
      localStorage.getItem("refreshToken")
    ).request(REFRESH_TOKENS);
    if (!!data?.refreshTokens.access_token) {
      localStorage.setItem("accessToken", data.refreshTokens.access_token);
      localStorage.setItem("refreshToken", data.refreshTokens.refresh_token);
      return data.refreshTokens.access_token;
    } else {
      localStorage.clear();
      return "";
    }
  } catch (err) {
    localStorage.clear();
    throw err;
  }
};
