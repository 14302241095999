import { ReactNode } from "react";
import { metronomeSounds } from "~audio";

export interface IPageHeader {
  title: string;
  color?: "red";
  counter?: number | string;
  leftComponent?: ReactNode;
  rightComponent?: ReactNode;
  type: string;
  to: string;
}

export interface IAlert {
  type: "red" | "green" | "blue" | "orange";
  text: string;
  isLoading?: boolean;
}

export interface IActiveMetronome {
  key: keyof typeof metronomeSounds;
  listId: number;
  itemId: number;
}

export enum PublishingStatus {
  Unpublished = "Unpublished",
  Published = "Published",
  UnpublishedChanges = "UnpublishedChanges",
}
