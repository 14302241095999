import { Download, FileCheckCompleted, Loader, TrashAlt } from "~icons";
import { useDropzone } from "react-dropzone";
import { usePdfTab } from "./usePdfTab";
import clsx from "clsx";

import styles from "./styles.module.scss";
import { downloadFile } from "~services";

export const PdfTab = () => {
  const { list, showLoader, onDrop, removePdfUrl } = usePdfTab();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [],
    },
  });

  return (
    <div className={styles.pdfTab}>
      {!!list.pdfUrl ? (
        <div className={clsx(styles.pdfTab__uploader, styles.active)}>
          <div onClick={removePdfUrl} className={styles.pdfTab__uploaderTrash}>
            <TrashAlt fill="#152659" />
          </div>

          <div
            onClick={() =>
              list.pdfUrl &&
              list.pdfName &&
              downloadFile(list.pdfUrl, list.pdfName)
            }
            className={styles.pdfTab__uploaderDownload}
          >
            <Download fill="#152659" />
          </div>
          <div className={styles.pdfTab__uploaderIcon}>
            <FileCheckCompleted stroke="#3A6CFF" />
          </div>
          <div className={styles.pdfTab__uploaderName}>
            <a href={list.pdfUrl} target="_blank">
              {list.pdfName}
            </a>
          </div>
        </div>
      ) : (
        <div {...getRootProps()} className={styles.pdfTab__uploader}>
          <input {...getInputProps()} />

          {showLoader ? (
            <div className={styles.pdfTab__uploaderLoader}>
              <Loader fill={"#FDBB12"} />
            </div>
          ) : (
            <>
              <div className={styles.pdfTab__uploaderIcon}>
                <Download fill={"#FDBB12"} />
              </div>
              Upload PDF protocol
            </>
          )}
        </div>
      )}
    </div>
  );
};
