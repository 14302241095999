import { File, Loader } from "~icons";
import { usePDF } from "../../usePDF";
import { IPdf } from "~models";
import dayjs from "dayjs";

import styles from "./styles.module.scss";

interface IProps {
  pdf: IPdf;
  index: number;
}

export const PDFItemImport = ({ pdf, index }: IProps) => {
  const { showLoader, handleImportButton } = usePDF({
    pdf,
    index,
  });

  return (
    <div>
      <div className={styles.pdfItem}>
        <div className={styles.pdfItem__horizontal}>
          <div className={styles.pdfItem__horizontalName}>
            <File fill="#0B0F1C" />
            {pdf.title}
          </div>
          <div className={styles.pdfItem__horizontalDate}>
            {dayjs(pdf.createdAt).format("MMMM D, YYYY")}
          </div>
          <div className={styles.pdfItem__horizontalAuthor}>
            <p>
              {pdf.firstName} {pdf.lastName}
            </p>
            <div
              onClick={handleImportButton}
              className={styles.pdfItem__horizontalAuthorButton}
            >
              {showLoader ? (
                <Loader fill="#FFFFFF" />
              ) : (
                <p>Import to Checkem Builder</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.pdfItem__underline} />
    </div>
  );
};
