import { Button, Modal, PageHeader } from "~components";
import { CreateOrganization } from "~blocks";
import { useHomePage } from "./useHomePage";
import { DashboardLayout } from "~layouts";
import { Outlet } from "react-router-dom";
import { PlusSymbol } from "~icons";

import styles from "./styles.module.scss";

export const AllOrganizations = () => {
  const {
    pageHeader,
    choosedOrganization,
    showOrganizationModal,
    handleOrganizationModalOpen,
    handleOrganizationModalClose,
  } = useHomePage();

  return (
    <DashboardLayout
      title="All Organizations"
      rightItem={
        <div className={styles.homePage__inviteButtonWrapper}>
          <Button
            onClick={handleOrganizationModalOpen}
            icon={<PlusSymbol fill="#FFFFFF" />}
            text={"Create new organization"}
            backgroundType={"green"}
            buttonClassName={styles.homePage__inviteButton}
          />
        </div>
      }
    >
      <div className={styles.homePage}>
        <Outlet />

        {showOrganizationModal && (
          <Modal
            containerClassName={styles.homePage__organization}
            modalClassName={styles.homePage__organizationContent}
            onCloseModal={handleOrganizationModalClose}
            hideCross
          >
            <CreateOrganization
              showTopIcons
              onCrossClick={handleOrganizationModalClose}
              containerClassName={styles.homePage__organizationBlock}
              onFinish={handleOrganizationModalClose}
            />
          </Modal>
        )}
      </div>
    </DashboardLayout>
  );
};
