import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_SHARING_CODE } from "~mutations";

interface IProps {
  type: "Pdf" | "Draft" | "Folder";
  id: number;
  isOpen: boolean;
  onCloseModal: () => void;
}

export const useShareCodeModal = ({
  id,
  type,
  isOpen,
  onCloseModal,
}: IProps) => {
  const [shareCode, setShareCode] = useState<string | null>(null);
  const [getSharingCode] = useMutation(GET_SHARING_CODE);

  const getCode = () => {
    let input = {};
    type === "Draft"
      ? Object.assign(input, { draftId: id })
      : type === "Pdf"
      ? Object.assign(input, { pdfId: id })
      : Object.assign(input, { folderId: id });
    getSharingCode({ variables: { input }, fetchPolicy: "network-only" }).then(
      (resp) => setShareCode(resp.data.createShareCode)
    );
  };

  const handleCloseModal = () => {
    !!setShareCode && setShareCode("");
    onCloseModal();
  };

  useEffect(() => {
    isOpen && getCode();
  }, [isOpen]);

  return { shareCode, handleCloseModal };
};
