import { gql } from "@apollo/client";

export const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      firstName
      lastName
      avatar
      onboarding
      isVerified
      email
      authType
      role
    }
  }
`;

export const GET_USER_INFO = gql`
  query user($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      avatar
      email
      isVerified
      onboarding
      role
      authType
      providerId
      createdAt
      updatedAt
    }
  }
`;

export const GET_MY_ORGANIZATION_REQUESTS = gql`
  query myOrganizationRequests {
    myOrganizationRequests {
      id
      organization {
        id
        name
        picture
        additionalMembers
        createdAt
        _count {
          members
        }
      }
    }
  }
`;

export const GET_MY_INVITATIONS = gql`
  query organizationInvitations($input: FindOrganizationInvitationsInput!) {
    organizationInvitations(input: $input) {
      id
      userId
      organizationId
      role
      organization {
        id
        name
        picture
        additionalMembers
        _count {
          members
        }
      }
      createdAt
    }
  }
`;

export const GET_ALL_USERS_PAGINATION_DATA = gql`
  query getAllUsersPaginationData(
    $input: FindUserInput!
    $countInput: CountUserInput!
  ) {
    users(input: $input) {
      id
      firstName
      lastName
      avatar
      email
      role
      createdAt
    }
    usersCount(input: $countInput)
  }
`;

export const GET_USER_ORGANIZATIONS = gql`
  query getUserOrganizations($input: GetUserOrganizationsWithId!) {
    getUserOrganizations(input: $input) {
      id
      name
    }
  }
`;
