import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import clsx from "clsx";
import { FC, FormEvent, useState } from "react";
import styles from "./styles.module.scss";

interface IProps {
  handleSubmit: (value: FormEvent) => void;
  formRef: React.RefObject<HTMLFormElement>;
}

export const PaymentInput: FC<IProps> = ({ handleSubmit, formRef }) => {
  const [isFocused, setIsFocused] = useState(false);
  const cardStyle = {
    style: {
      base: {
        color: "#0B0F1C",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontWeight: "400",
        "::placeholder": {
          color: "#979DB1",
        },
      },
      invalid: {
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontWeight: "400",
        color: "#EF2F32",
        iconColor: "#EF2F32",
      },
    },
    placeholder: "1234123412341234",
    // hidePostalCode: true,
  };

  return (
    <div className={styles.playmentInput}>
      <p>Card info</p>
      <div
        className={clsx(styles.playmentInput__input, {
          [styles.focus]: isFocused,
        })}
      >
        <form ref={formRef} onSubmit={handleSubmit}>
          <CardElement
            options={cardStyle}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </form>
      </div>
    </div>
  );
};
