import { useMutation, useReactiveVar } from "@apollo/client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { alertVar, choosedOrganizationVar } from "~localCache";
import { IFolder } from "~models";
import {
  IMPORT_FOLDER,
  REMOVE_FOLDER,
  REMOVE_FOLDER_FROM_FOLDER,
} from "~mutations";

interface IProps {
  folder: IFolder;
  handleView?: boolean;
  handleImport?: boolean;
  showTableLoader?: () => void;
  updateTableData?: () => void;
}
export const useFolder = ({
  folder,
  handleView,
  handleImport,
  showTableLoader,
  updateTableData,
}: IProps) => {
  const navigate = useNavigate();
  const choosedOrganization = useReactiveVar(choosedOrganizationVar);

  const [removeFolder] = useMutation(REMOVE_FOLDER);
  const [importFolder] = useMutation(IMPORT_FOLDER);
  const [removeFolderFromFolder] = useMutation(REMOVE_FOLDER_FROM_FOLDER);

  const [showPopper, setShowPopper] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [showShareCodeModal, setShowShareCodeModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false)

  const [showImportButtonLoader, setShowImportButtonLoader] = useState(false);

  const handleOpenShareModal = () => {
    handleClosePopper();
    setShowShareCodeModal(true);
  };
  const handleCloseShareModal = () => setShowShareCodeModal(false);

  const handleOpenMoveModal = () => {
    handleClosePopper();
    setShowMoveModal(true)
  }

  const handleCloseMoveModal = () => {
    handleClosePopper();
    setShowMoveModal(false)
  }

  const handleOpenPopper = () => setShowPopper(true);
  const handleClosePopper = () => setShowPopper(false);

  const handleOpenDeletionModal = () => {
    handleClosePopper();
    setShowDeletionModal(true);
  };
  const handleCloseDeletionModal = () => setShowDeletionModal(false);

  const handleDelete = () => {
    handleClosePopper();
    !!showTableLoader && showTableLoader();
    handleCloseDeletionModal();
    removeFolder({ variables: { id: folder.id } }).then(() => {
      !!updateTableData && updateTableData();
    });
  };

  const handleImportButton = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setShowImportButtonLoader(true);
    importFolder({
      variables: {
        input: { organizationId: choosedOrganization?.id, id: folder.id },
      },
    }).then(() => {
      alertVar({
        text: "The folder has been successfully imported",
        type: "green",
      });
      setShowImportButtonLoader(false);
    });
  };

  const handleFolderClick = () => {
    if (handleView) {
      navigate(`/dashboard/folder/${folder.id}`);
      return;
    } else if (handleImport) {
      navigate(`/dashboard/folderImport/${folder.id}`);
      return;
    } else navigate(`/dashboard/folderBuilder/${folder.id}`);
  };

  const handleMoveOut = () => {
    alertVar({
      type: "blue",
      text: "Folder is moving out of folder",
      isLoading: true,
    });
    removeFolderFromFolder({ variables: { id: folder.id } }).then(() => {
      !!updateTableData && updateTableData();
      alertVar({
        type: "green",
        text: "Folder was successfully moved out from folder",
      });
    });
  };

  return {
    showPopper,
    showDeletionModal,
    showShareCodeModal,
    showImportButtonLoader,
    showMoveModal,
    handleMoveOut,
    handleDelete,
    handleFolderClick,
    handleOpenPopper,
    handleClosePopper,
    handleOpenDeletionModal,
    handleCloseDeletionModal,
    handleOpenShareModal,
    handleCloseShareModal,
    handleImportButton,
    handleOpenMoveModal,
    handleCloseMoveModal,
  };
};
