import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { IMessage, MessageRate } from "~models";
import { RATE_ANSWER } from "~mutations";
import { GET_CURRENT_USER } from "~queries";

interface IProps {
  message: IMessage;
  messageIndex: number;
  setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>;
}

export const useMessage = ({ message, messageIndex, setMessages }: IProps) => {
  const { data } = useQuery(GET_CURRENT_USER);
  console.log("data", data);

  const [rateAnswer] = useMutation(RATE_ANSWER);

  const leaveRate = (rate: MessageRate) => {
    setMessages((prev) => {
      let state = structuredClone(prev);
      state[messageIndex].rate = rate;
      return state;
    });
    rateAnswer({ variables: { input: { chatItemId: message.id, rate } } });
  };

  return { data, leaveRate };
};
