import styles from "./styles.module.scss";

import { IItem } from "../../../../../constants";
import { Button, Counter as CounterComponent } from "~components";
import { Return } from "~icons";

interface IProps {
  item: IItem;
  isDisable: boolean;
}

export const CheckboxItem = ({ item, isDisable }: IProps) => {
  return <div className={styles.checkboxItem}></div>;
};
