import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import { Button, Input } from "~components";
import { useCalculatorBody } from "./useCalculatorBody";

interface IProps {
  label: "Total" | "Ideal";
  gender: "Male" | "Female";
  age: string;
  kg: string;
  lbs: string;
  setKg: (value: string) => void;
  setLbs: (value: string) => void;
  cm?: string;
  setCm?: (value: string) => void;
  inch?: string;
  setInch?: (value: string) => void;
}

export const CalculatorBody: React.FC<IProps> = ({
  label,
  cm,
  setCm,
  inch,
  gender,
  setInch,
  age,
  kg,
  setKg,
  lbs,
  setLbs
}) => {
  const {
    isTotalWeight,
    shownKg,
    shownLbs,
    handleChangeLbs,
    handleChangeKg,
    handleClear,
  } = useCalculatorBody(label, gender, age,kg, lbs, setKg, setLbs, cm, setCm, inch, setInch);
  return (
    <div className={styles.calculatorBody}>
      <p className={styles.calculatorBody__title}>{label} Body Weight</p>
      {isTotalWeight ? (
        <Input
          value={lbs}
          onChange={(val) => handleChangeLbs(val)}
          label={"LBS"}
          placeholder={"LBS"}
        />
      ) : (
        <Input
          value={cm}
          onChange={(val) => {
            setCm && setCm(val);
            setInch && setInch("");
          }}
          label={"Length in CM"}
          placeholder={"CM"}
        />
      )}

      <p className={styles.calculatorBody__or}>or</p>
      {isTotalWeight ? (
        <Input
          value={kg}
          onChange={(val) => handleChangeKg(val)}
          label={"KG"}
          placeholder={"KG"}
        />
      ) : (
        <Input
          value={inch}
          onChange={(val) => {
            setInch && setInch(val);
            setCm && setCm("");
          }}
          label={"Length in IN"}
          placeholder={"IN"}
        />
      )}
      <Button
        buttonClassName={styles.calculatorBody__buttonSpace}
        backgroundType="green"
        text="Import from tape"
      />
      <div className={styles.calculatorBody__resultBox}>
        <p className={styles.calculatorBody__resultText}>{label} Body Weight</p>
        <p className={styles.calculatorBody__resultText}>{shownKg} KG</p>
        <p className={styles.calculatorBody__resultText}>{shownLbs} LBS</p>
      </div>
      <Button
        buttonClassName={styles.calculatorBody__buttonSpace}
        backgroundType="gray"
        text="Clear"
        onClick={handleClear}
      />
      <Button
        buttonClassName={styles.calculatorBody__exportButton}
        backgroundType="green"
        text="Export to Checkems"
      />
    </div>
  );
};
