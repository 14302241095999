import { Download, FileCheckCompleted, Loader, TrashAlt } from "~icons";
import { useUploadPdfModal } from "./useUploadPdfModal";
import { Button, Input, Modal } from "~components";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";

import styles from "./styles.module.scss";

interface IProps {
  isOpen: boolean;
  folderId?: number;
  isAdmin?: boolean;
  onCloseModal: () => void;
  handleSuccessResult: () => void;
}

export const UploadPdfModal = ({
  isOpen,
  folderId,
  isAdmin,
  onCloseModal,
  handleSuccessResult,
}: IProps) => {
  const {
    file,
    title,
    showLoader,
    disableUploadButton,
    onDrop,
    uploadPdf,
    handleFileChange,
    handleModalClose,
    handleTitleChange,
  } = useUploadPdfModal({
    onCloseModal,
    handleSuccessResult,
    isAdmin,
    folderId,
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [],
    },
  });

  if (!isOpen) return null;

  return (
    <Modal disableMediaStyles onCloseModal={handleModalClose}>
      <div className={styles.uploadPdfModal}>
        <p className={styles.uploadPdfModal__title}>PDF Upload</p>
        <Input
          label="PDF Title"
          placeholder="PDF Title"
          value={title}
          onChange={handleTitleChange}
        />
        {!!file ? (
          <div className={clsx(styles.uploadPdfModal__uploader, styles.active)}>
            <div
              onClick={() => handleFileChange(null)}
              className={styles.uploadPdfModal__uploaderTrash}
            >
              <TrashAlt fill="#152659" />
            </div>
            <div className={styles.uploadPdfModal__uploaderIcon}>
              <FileCheckCompleted stroke="#3A6CFF" />
            </div>
            <div className={styles.uploadPdfModal__uploaderName}>
              {file.name}
            </div>
          </div>
        ) : (
          <div {...getRootProps()} className={styles.uploadPdfModal__uploader}>
            <input {...getInputProps()} />

            {showLoader ? (
              <div className={styles.uploadPdfModal__uploaderLoader}>
                <Loader fill={"#FDBB12"} />
              </div>
            ) : (
              <>
                <div className={styles.uploadPdfModal__uploaderIcon}>
                  <Download fill={"#FDBB12"} />
                </div>
                Upload PDF protocol
              </>
            )}
          </div>
        )}

        <div className={styles.uploadPdfModal__buttons}>
          <Button
            backgroundType={"white"}
            showShadow
            text="Cancel"
            onClick={handleModalClose}
          />
          <Button
            disable={disableUploadButton}
            loading={showLoader}
            backgroundType={"green"}
            text="Upload"
            onClick={uploadPdf}
          />
        </div>
      </div>
    </Modal>
  );
};
