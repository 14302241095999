import { StrictModifierNames, Modifier } from "react-popper";
import { Options, createPopper } from "@popperjs/core";
import { Avatar, PopperPopup } from "~components";
import { IUser } from "~models";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  CopyTo,
  DotsVertical,
  Duplicate,
  FileDownload,
  Pen,
  Share,
  TrashAlt,
} from "~icons";

import styles from "./styles.module.scss";
import { useAdminItem } from "./useAdminItem";

interface IProps {
  user: IUser;
  index: number;
  handleDeleteAdmin: (id: number) => void;
}

export const AdminItem = ({ user, handleDeleteAdmin, index }: IProps) => {
  const { showPopper, handleOpenPopper, handleClosePopper, handleEditButtom } =
    useAdminItem({
      user,
    });

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  return (
    <>
      <div className={styles.adminItem}>
        <div className={styles.adminItem__user}>
          {!!user && (
            <>
              <Avatar
                uri={user?.avatar}
                item={user}
                type="user"
                containerStyles={styles.adminItem__userAvatar}
              />
              <div className={styles.adminItem__userRight}>
                <p className={styles.adminItem__userName}>
                  {user.firstName} {user.lastName}
                </p>
                <p className={styles.adminItem__userEmail}>{user.email}</p>
              </div>
            </>
          )}
        </div>
        <div className={styles.adminItem__organizations}>
          <div className={styles.adminItem__organizationsLong}>
            3 organizations
          </div>
          <div className={styles.adminItem__organizationsShort}>3</div>
        </div>

        <div className={styles.adminItem__date}>
          <div className={styles.adminItem__dateLong}>
            {dayjs(user.createdAt).format("MMMM DD, YYYY")}
          </div>
          <div className={styles.adminItem__dateShort}>
            {dayjs(user.createdAt).format("DD/MM/YY")}
          </div>

          <PopperPopup
            showPopper={showPopper}
            onClosePopper={handleClosePopper}
            onOpenPopper={handleOpenPopper}
            options={popperOptions}
            trigger={<DotsVertical fill="#0B0F1C" />}
            popup={
              <div className={clsx(styles.adminItem__modal, {})}>
                <div
                  onClick={handleEditButtom}
                  className={styles.adminItem__modalItem}
                >
                  <Pen fill="#0B0F1C" /> Edit
                </div>
              </div>
            }
          />
        </div>
      </div>
      <div className={styles.adminItem__underline} />
    </>
  );
};
