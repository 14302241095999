import { Button, Modal } from "~components";
import styles from "./styles.module.scss";

interface IProps {
  checklistDeletionId: number | null;
  onCloseModal: () => void;
  handleDeleteChecklist: (id: number) => void;
}

export const DeleteChecklistModal = ({
  checklistDeletionId,
  onCloseModal,
  handleDeleteChecklist,
}: IProps) => {
  if (checklistDeletionId === null) return null;

  return (
    <Modal disableMediaStyles onCloseModal={onCloseModal}>
      <div className={styles.modal}>
        <p className={styles.modal__title}>
          Are you sure you want to delete this checklist?
        </p>

        <p className={styles.modal__subtitle}>
          If you delete this checklist now, the information will not be saved
        </p>

        <div className={styles.modal__buttons}>
          <Button
            onClick={onCloseModal}
            text="Cancel"
            backgroundColor="#979DB1"
            textColor="#FFFFFF"
          />
          <Button
            onClick={() => handleDeleteChecklist(checklistDeletionId)}
            text="Delete"
            backgroundColor="#EF2F32"
            textColor="#FFFFFF"
          />
        </div>
      </div>
    </Modal>
  );
};
