import {
  PageHeader,
  Pagination,
  SearchInput,
  Table,
  TableLoader,
} from "~components";
import { useAllMembers } from "./useAllMembers";
import { MemberItem } from "./components";
import { useRef } from "react";

import styles from "./styles.module.scss";
import { useMembers } from "../Members/useMembers";

export const AllMembers = () => {
  const {
    loading,
    searchValue,
    setSearchValue,
    members,
    handleChangeRole,
    fetchedCount,
    limit,
    handleNextPage,
    handlePrevPage,
    allMembersCount,
    choosedOrganization,
    deleteMember,
  } = useAllMembers();
  const { pageHeader } = useMembers();

  const scrollBlockRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={styles.allMembers__search}>
        <SearchInput
          value={searchValue}
          onChange={setSearchValue}
          placeholder={"Search for users, emails"}
        />
      </div>

      <div style={{ marginBottom: 20 }}>
        <PageHeader data={pageHeader} />
      </div>

      {loading ? (
        <TableLoader />
      ) : (
        <Table
          scrollBlockRef={scrollBlockRef}
          header={[
            { header: "Member", className: styles.allMembers__memberHeader },
            {
              header: "",
              className: styles.allMembers__dateHeader,
            },
            { header: "Role", className: styles.allMembers__roleHeader },
          ]}
          data={members}
          renderTableItem={(item, index) => (
            <MemberItem
              key={`${item.id} - ${index}`}
              dataLength={members.length}
              item={item}
              index={index}
              scrollBlockRef={scrollBlockRef}
              handleChangeRole={handleChangeRole}
              choosedOrganization={choosedOrganization}
              deleteMember={deleteMember}
            />
          )}
        />
      )}

      <div className={styles.allMembers__pagination}>
        <Pagination
          allResults={allMembersCount}
          fetchedResult={fetchedCount}
          limit={limit}
          dataLength={members.length}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
        />
      </div>
    </>
  );
};
