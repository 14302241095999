import { DraggableLocation, DropResult } from "react-beautiful-dnd";
import { useMutation } from "@apollo/client";
import { useBuilder } from "./context";
import { useState } from "react";
import {
  CHANGE_ITEM_ORDERS,
  CHANGE_SECTION_ORDERS,
  UPDATE_ITEM,
} from "~mutations";
import { ISection } from "~models";

export const useDragAndDrop = () => {
  const {
    list,
    activeItem,
    activeTabIndex,
    setList,
    setActiveItem,
    setIsDragging,
  } = useBuilder();

  const [changeSectionsOrder] = useMutation(CHANGE_SECTION_ORDERS);
  const [changeItemsOrder] = useMutation(CHANGE_ITEM_ORDERS);
  const [updateItem] = useMutation(UPDATE_ITEM);

  const [hideButton, setHideButton] = useState(false);

  const updateItemsOrderOnServer = async (ids: number[]) => {
    await changeItemsOrder({
      variables: { input: { ids } },
    });
  };

  const updateItemSection = (
    id: number,
    tabSectionId: number,
    ids: number[]
  ) => {
    updateItem({
      variables: {
        input: { id, tabSectionId },
      },
    }).then(async () => await updateItemsOrderOnServer(ids));
  };

  const onChangeSectionsOrder = (
    source: DraggableLocation,
    destination: DraggableLocation
  ) => {
    let newSections = Array.from(list.tabs[activeTabIndex].sections);
    let orderedSection = { ...newSections[source.index] };
    newSections.splice(source.index, 1);
    newSections.splice(destination.index, 0, orderedSection);

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections = newSections;
      return state;
    });

    if (activeItem?.type === "item") {
      let newIndex = newSections.findIndex(
        (item) => item.id === activeItem?.sectionId
      );
      if (newIndex > -1) {
        setActiveItem({ ...activeItem, sectionIndex: newIndex });
      }
    }
    changeSectionsOrder({
      variables: { input: { ids: newSections.map((item) => item.id) } },
    });
    setHideButton(false);
    setIsDragging(false);
  };

  const onChangeItemsOrderInsideSection = (
    start: ISection,
    source: DraggableLocation,
    destination: DraggableLocation
  ) => {
    const newItems = Array.from(start.items);
    const orderedItem = { ...newItems[source.index] };
    newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, orderedItem);

    const newSection = {
      ...start,
      items: newItems,
    };

    const sectionIndex = list.tabs[activeTabIndex].sections.findIndex(
      (item) => item.id === start.id
    );

    let newSections = [...list.tabs[activeTabIndex].sections];
    newSections[sectionIndex] = newSection;

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections = newSections;
      return state;
    });
    // changeItemsOrder({
    //   variables: { input: { ids: newItems.map((item) => item.id) } },
    // });
    updateItemsOrderOnServer(newItems.map((item) => item.id));
    setHideButton(false);
    setIsDragging(false);
  };

  const onChangeItemsOrderOutsideSection = (
    start: ISection,
    finish: ISection,
    source: DraggableLocation,
    destination: DraggableLocation
  ) => {
    const startsectionIndex = list.tabs[activeTabIndex].sections.findIndex(
      (item) => item.id === start.id
    );

    const finishsectionIndex = list.tabs[activeTabIndex].sections.findIndex(
      (item) => item.id === finish.id
    );

    let startItems = Array.from(start.items);
    let orderedItem = {
      ...startItems[source.index],
      tabSectionId: +destination.droppableId,
    };
    startItems.splice(source.index, 1);

    let finishItems = Array.from(finish.items);
    finishItems.splice(destination.index, 0, orderedItem);

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[startsectionIndex].items = startItems;
      state.tabs[activeTabIndex].sections[finishsectionIndex].items =
        finishItems;

      return state;
    });

    if (activeItem?.type === "item" && activeItem.id === orderedItem.id) {
      setActiveItem({
        ...activeItem,
        sectionIndex: finishsectionIndex,
        sectionId: list.tabs[activeTabIndex].sections[finishsectionIndex].id,
      });
    }

    updateItemSection(
      orderedItem.id,
      orderedItem.tabSectionId,
      finishItems.map((item) => item.id)
    );

    // updateItem({
    //   variables: {
    //     input: { id: orderedItem.id, tabSectionId: orderedItem.tabSectionId },
    //   },
    // })
    //   .then(async (resp) => {
    //     await changeItemsOrder({
    //       variables: { input: { ids: finishItems.map((item) => item.id) } },
    //     });
    //   })
    //   .catch((err) => {});

    setHideButton(false);
    setIsDragging(false);
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, type } = result;
    if (!destination) {
      setHideButton(false);
      setIsDragging(false);
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      setHideButton(false);
      setIsDragging(false);
      return;
    }

    if (type === "section") {
      onChangeSectionsOrder(source, destination);
      return;
    }

    const start = list.tabs[activeTabIndex].sections.find(
      (item) => `${item.id}` === source.droppableId
    );
    const finish = list.tabs[activeTabIndex].sections.find(
      (item) => `${item.id}` === destination.droppableId
    );

    if (!start || !finish) return;

    if (start === finish) {
      onChangeItemsOrderInsideSection(start, source, destination);
      return;
    }
    onChangeItemsOrderOutsideSection(start, finish, source, destination);
  };

  const onDragStart = () => {
    setHideButton(true);
    setIsDragging(true);
  };

  return { hideButton, onDragEnd, onDragStart };
};
