import { Button, FolderItem, Modal, Table, TableLoader } from "~components";
import styles from "./styles.module.scss";
import { IDraft, IFolder, IPdf } from "~models";
import { useMoveModal } from "./useMoveModal";
import { LeftArrow } from "~icons";

interface IProps {
  isOpen: boolean;
  onCloseModal: () => void;
  updateTableData?: () => void;
  itemToMove: IFolder | IPdf | IDraft;
}

export const MoveModal = ({
  isOpen,
  onCloseModal,
  itemToMove,
  updateTableData,
}: IProps) => {
  const {
    folders,
    activeFolder,
    isLoading,
    onFolderClick,
    onBackArrowClicked,
    handleMove,
  } = useMoveModal({
    itemToMove,
    isOpen,
    updateTableData,
  });
  if (!isOpen) return null;

  return (
    <Modal disableMediaStyles onCloseModal={onCloseModal}>
      <div className={styles.modal}>
        <div>
          <p className={styles.modal__title}>Choose location to move to</p>

          {activeFolder && (
            <div className={styles.modal__header}>
              <div
                onClick={onBackArrowClicked}
                className={styles.modal__headerIcon}
              >
                <LeftArrow fill="#979DB1" />
              </div>
              {activeFolder.title}
            </div>
          )}
          {isLoading && <TableLoader />}
          {!isLoading && (
            <Table
              folders={folders}
              header={[
                { header: "Name", className: styles.modal__tableHeader },
              ]}
              data={[]}
              renderTableItem={(item, index) => <></>}
              renderFolderItem={(folder, index) => (
                <FolderItem
                  handleMove
                  onFolderClickMoveModal={onFolderClick}
                  hideActions
                  folder={folder}
                  index={index}
                />
              )}
            />
          )}
        </div>

        <div className={styles.modal__buttons}>
          <Button
            onClick={onCloseModal}
            text="Cancel"
            backgroundColor="#FFFFFF"
            textColor="#0B0F1C"
            showShadow
          />
          <Button
            onClick={() => {
              handleMove();
              onCloseModal();
            }}
            text="Move"
            backgroundColor="#29B473"
            textColor="#FFFFFF"
          />
        </div>
      </div>
    </Modal>
  );
};
