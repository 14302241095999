import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useUpdateEffect } from "usehooks-ts";
import { IUserInvitation } from "~models";
import { GET_MY_INVITATIONS } from "~queries";
import { client } from "~services";

export const useMyRequests = () => {
  const { data } = useQuery(GET_MY_INVITATIONS, {
    variables: { input: {} },
  });

  const [searchValue, setSearchValue] = useState("");
  const [defaultData, setDefaultData] = useState<IUserInvitation[]>([]);
  const [filteredData, setFilteredData] = useState<IUserInvitation[]>([]);

  const removeRequestFromCache = (id: number) => {
    const normalizedId = client.cache.identify({
      id,
      __typename: "UserInvitation",
    });
    client.cache.evict({ id: normalizedId });
    client.cache.gc();
  };

  const removeItemFromData = async (index: number, id: number) => {
    setFilteredData((prev) => {
      let state = [...prev];
      state.splice(index, 1);
      return state;
    });

    let defaultDataIndex = defaultData.findIndex((item) => item.id === id);
    if (defaultDataIndex > -1) {
      setDefaultData((prev) => {
        let state = [...prev];
        state.splice(defaultDataIndex, 1);
        return state;
      });
    }

    removeRequestFromCache(id);
  };

  useEffect(() => {
    !!data &&
      data?.organizationInvitations &&
      setDefaultData(data?.organizationInvitations);

    !!data &&
      data?.organizationInvitations &&
      !searchValue &&
      setFilteredData(data?.organizationInvitations);
  }, [data]);

  const filterRquests = () => {
    if (!!searchValue) {
      let filteredData = defaultData.filter((item: IUserInvitation) =>
        item.organization.name
          ?.toLowerCase()
          .includes(searchValue.toLowerCase())
      );
      setFilteredData(filteredData);
    } else {
      setFilteredData(defaultData);
    }
  };

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      filterRquests();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, defaultData]);

  return {
    myOrganizationRequests: filteredData,
    searchValue,
    setSearchValue,
    handleButtonClick: removeItemFromData,
  };
};
