import ContentLoader from "react-content-loader";
import styles from "./styles.module.scss";

export const TableLoader = () => {
  return (
    <div className={styles.tableLoader}>
      <ContentLoader
        speed={1}
        backgroundColor="#DBDEE7"
        foregroundColor="#ebedf2"
        title=""
        style={{
          flex: 1,
        }}
      >
        <rect x="0" y="0" rx="7" ry="7" width="100%" height="40" />

        <rect x="12" y="54" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="54" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="88" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="103" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="103" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="137" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="152" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="152" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="186" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="201" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="201" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="235" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="250" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="250" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="284" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="299" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="299" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="333" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="348" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="348" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="382" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="397" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="397" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="431" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="446" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="446" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="480" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="495" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="495" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="529" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="544" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="544" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="578" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="593" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="593" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="627" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="642" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="642" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="676" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="691" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="691" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="725" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="740" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="740" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="774" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="789" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="789" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="823" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="838" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="838" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="872" rx="7" ry="7" width="100em" height="2" />

        <rect x="12" y="887" rx="5" ry="5" width="20" height="20" />
        <rect x="52" y="887" rx="5" ry="5" width="268" height="20" />
        <rect x="0" y="921" rx="7" ry="7" width="100em" height="2" />
      </ContentLoader>
    </div>
  );
};
