import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { alertVar, choosedOrganizationVar } from "~localCache";
import { IDraft, IFolder, IPdf } from "~models";
import {
  MOVE_DRAFT_TO_FOLDER,
  MOVE_FOLDER_TO_FOLDER,
  MOVE_PDF_TO_FOLDER,
  REMOVE_DRAFT_FROM_FOLDER,
  REMOVE_FOLDER_FROM_FOLDER,
  REMOVE_PDF_FROM_FOLDER,
} from "~mutations";
import { GET_FOLDER, GET_ORGANIZATION_FOLDERS } from "~queries";

interface IProps {
  itemToMove: IFolder | IDraft | IPdf;
  isOpen: boolean;
  updateTableData?: () => void;
}

export const useMoveModal = ({
  itemToMove,
  isOpen,
  updateTableData,
}: IProps) => {
  const choosedOrganization = useReactiveVar(choosedOrganizationVar);
  const [getFolders] = useLazyQuery(GET_ORGANIZATION_FOLDERS);
  const [getFolder] = useLazyQuery(GET_FOLDER);

  const [moveFolderToFolder] = useMutation(MOVE_FOLDER_TO_FOLDER);
  const [movePdfToFolder] = useMutation(MOVE_PDF_TO_FOLDER);
  const [moveDraftToFolder] = useMutation(MOVE_DRAFT_TO_FOLDER);

  const [removeFolderFromFolder] = useMutation(REMOVE_FOLDER_FROM_FOLDER);
  const [removeDraftFromFolder] = useMutation(REMOVE_DRAFT_FROM_FOLDER);
  const [removePdfFromFolder] = useMutation(REMOVE_PDF_FROM_FOLDER);

  const [folders, setFolders] = useState<IFolder[]>([]);
  const [activeFolder, setActiveFolder] = useState<IFolder | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const getAllFolders = () => {
    if (isOpen) {
      setIsLoading(true);

      setActiveFolder(null);

      const foldersInput = {
        skip: 0,
        take: 49,
        organizationId: choosedOrganization?.id,
      };
      getFolders({ variables: { foldersInput }, fetchPolicy: "no-cache" }).then(
        (res) => {
          setFolders(
            res?.data?.folders.filter(
              (item: IFolder) => item.id !== itemToMove.id
            )
          );
          setIsLoading(false);
        }
      );
    }
  };

  const onFolderClick = (clickedFolder: IFolder) => {
    if (isOpen) {
      setIsLoading(true);
      const foldersInput = {
        skip: 0,
        take: 49,
        parentFolderId: clickedFolder?.id,
        organizationId: choosedOrganization?.id,
      };
      setActiveFolder(clickedFolder);
      getFolders({ variables: { foldersInput }, fetchPolicy: "no-cache" }).then(
        (res) => {
          setFolders(
            res?.data?.folders.filter(
              (item: IFolder) => item.id !== itemToMove.id
            )
          );
          setIsLoading(false);
        }
      );
    }
  };

  const onBackArrowClicked = () => {
    console.log(activeFolder, "active");
    if (!activeFolder?.parentFolderId) {
      getAllFolders();
    } else {
      getFolder({ variables: { id: activeFolder.parentFolderId } }).then(
        (resp) => {
          onFolderClick(resp.data.folder);
        }
      );
    }
  };

  const movingFunc =
    itemToMove.type === "Draft"
      ? moveDraftToFolder
      : itemToMove.type === "Pdf"
      ? movePdfToFolder
      : moveFolderToFolder;

  const movingOutFunc =
    itemToMove.type === "Draft"
      ? removeDraftFromFolder
      : itemToMove.type === "Pdf"
      ? removePdfFromFolder
      : removeFolderFromFolder;

  const handleMove = () => {
    const input = {};

    if (!activeFolder) {

      alertVar({
        text: `The ${
          itemToMove.type === "Draft"
            ? "draft"
            : itemToMove.type === "Pdf"
            ? "PDF"
            : "folder"
        } is moving`,
        type: "blue",
        isLoading: true,
      });

      movingOutFunc({ variables: { id: itemToMove.id } })
        .then(() => {
          alertVar({
            text: `The ${
              itemToMove.type === "Draft"
                ? "draft"
                : itemToMove.type === "Pdf"
                ? "PDF"
                : "folder"
            } has been successfully moved`,
            type: "green",
          });
          updateTableData?.();
        })
        .catch((err) => {
          alertVar({
            text: err.graphQLErrors[0].message,
            type: "red",
          });
        });

    } else {
      itemToMove.type === "Draft"
        ? Object.assign(input, {
            draftId: itemToMove.id,
            folderId: activeFolder?.id,
          })
        : itemToMove.type === "Pdf"
        ? Object.assign(input, {
            pdfId: itemToMove.id,
            folderId: activeFolder?.id,
          })
        : Object.assign(input, {
            childFolderId: itemToMove.id,
            parentFolderId: activeFolder.id,
          });

      alertVar({
        text: `The ${
          itemToMove.type === "Draft"
            ? "draft"
            : itemToMove.type === "Pdf"
            ? "PDF"
            : "folder"
        } is moving`,
        type: "blue",
        isLoading: true,
      });
      
      movingFunc({
        variables: {
          input,
        },
      })
        .then(() => {
          alertVar({
            text: `The ${
              itemToMove.type === "Draft"
                ? "draft"
                : itemToMove.type === "Pdf"
                ? "PDF"
                : "folder"
            } has been successfully moved`,
            type: "green",
          });
          updateTableData?.();
        })
        .catch((err) => {
          alertVar({
            text: err.graphQLErrors[0].message,
            type: "red",
          });
        });
    }
  };

  useEffect(() => {
    getAllFolders();
  }, [isOpen]);

  return {
    folders,
    isLoading,
    activeFolder,
    onFolderClick,
    onBackArrowClicked,
    handleMove,
  };
};
