import Popup from "reactjs-popup";
import { Calculator } from "~icons";

import styles from "./styles.module.scss";
import { useCalculatorPopup } from "./useCalculatorPopup";
import clsx from "clsx";
import { Button, Checkbox, Dropdown } from "~components";
import { CalculatorBody } from "./CalculatorBody";

export const CalculatorPopup = () => {
  const {
    popupRef,
    popupContentRef,
    popupTriggerRef,
    year,
    month,
    gender,
    cm,
    inch,
    age,
    kg,
    setKg,
    lbs,
    setLbs,
    setCm,
    setInch,
    setGenderFemale,
    setGenderMale,
    setYear,
    setMonth,
    togglePopup,
  } = useCalculatorPopup();

  return (
    <div
      ref={popupTriggerRef}
      className={clsx(styles.calculator, styles.calculatorShadow)}
      onClick={togglePopup}
    >
      <Popup
        ref={popupRef}
        arrow={false}
        lockScroll={true}
        closeOnDocumentClick={false}
        position={"bottom right"}
        trigger={<div style={{ position: "absolute", left: 0, top: 0 }} />}
      >
        <div ref={popupContentRef} className={styles.popup}>
          <p className={styles.popup__title}>Peds Age Based</p>
          <div className={styles.popup__ageSelect}>
            <Dropdown
              data={[
                "Empty",
                ...Array.from({ length: 13 }, (_, i) => i.toString()),
              ]}
              value={year}
              onChange={(_, value) => setYear(value)}
              placeholder="Year"
            />
            <Dropdown
              data={[
                "Empty",
                ...Array.from({ length: 12 }, (_, i) => i.toString()),
              ]}
              value={month}
              onChange={(_, value) => setMonth(value)}
              placeholder="Month"
            />
          </div>
          <div className={styles.popup__clearButtonContainer}>
            <div className={styles.popup__clearButton}>
              <Button
                buttonClassName={styles.calculatorBody__buttonSpace}
                backgroundType="gray"
                text="Clear"
                onClick={() => {
                  setYear("Empty");
                  setMonth("Empty");
                }}
              />
            </div>
          </div>
          <div className={styles.popup__genderSelect}>
            <div className={styles.popup__genderCheckbox}>
              <Checkbox value={gender === "Male"} onClick={setGenderMale} />
              <p>Male</p>
            </div>
            <div className={styles.popup__genderCheckbox}>
              <Checkbox value={gender === "Female"} onClick={setGenderFemale} />
              <p>Female</p>
            </div>
          </div>
          <div className={styles.popup__body}>
            <CalculatorBody
              label="Total"
              gender={gender}
              age={age}
              cm={cm}
              kg={kg}
              lbs={lbs}
              setKg={setKg}
              setLbs={setLbs}
            />
            <div className={styles.popup__bodyDivider}></div>
            <CalculatorBody
              label="Ideal"
              cm={cm}
              setCm={setCm}
              setInch={setInch}
              inch={inch}
              gender={gender}
              age={age}
              kg={kg}
              lbs={lbs}
              setKg={setKg}
              setLbs={setLbs}
            />
          </div>
        </div>
      </Popup>
      <Calculator />
    </div>
  );
};
