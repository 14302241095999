import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IPdf } from "~models";
import { GET_PDF } from "~queries";

export const usePDFViewer = () => {
  const params = useParams();
  const navigate = useNavigate();
  let id = !!params?.id ? +params.id : -1;

  const [numPages, setNumPages] = useState(0);
  const [skipIndex, setSkipIndex] = useState(0);

  const from = skipIndex * 25;
  const to = (skipIndex + 1) * 25 < numPages ? (skipIndex + 1) * 25 : numPages;

  const disableNextPagesButton = to === numPages;
  const disablePrevPagesButton = from === 0;

  const handleNextPages = () =>
    !disableNextPagesButton && setSkipIndex(skipIndex + 1);
  const handlePrevPages = () =>
    !disablePrevPagesButton && setSkipIndex(skipIndex - 1);

  const { data, loading } = useQuery(GET_PDF, {
    variables: { id },
    skip: !params?.id,
  });

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const goBack = () => {
    window.history.state && window.history.state.idx > 0
      ? navigate(-1)
      : navigate("/dashboard/lists");
  };

  return {
    pdf: !!data?.pdf ? data.pdf : null,
    numPages,
    skipIndex,
    loading,
    from,
    to,
    disableNextPagesButton,
    disablePrevPagesButton,
    goBack,
    handleNextPages,
    handlePrevPages,
    onDocumentLoadSuccess,
  };
};
