import { FC } from "react";
import { Button, ImagePicker, Input } from "~components";
import { Camera } from "~icons";
import clsx from "clsx";

import styles from "./styles.module.scss";

interface IProps {
  name: string;
  setName: (value: string) => void;
  goNext: () => void;
  pictureUri: string;
  setPicture: (value: Blob) => void;
  isDisable: boolean;
  error: string;
  createOrganization: () => void;
  loading: boolean;
}

export const OrganizationName: FC<IProps> = ({
  name,
  goNext,
  setName,
  pictureUri,
  setPicture,
  isDisable,
  error,
  loading,
  createOrganization,
}) => {
  return (
    <div className={styles.organizationName}>
      <div className={styles.organizationName__title}>
        What’s the name of your organization?
      </div>
      <div className={styles.organizationName__subtitle}>
        This will be the name of your Checkem organization. Choose something
        your team will recognize.
      </div>

      <div className={styles.organizationName__content}>
        <ImagePicker
          containerClassName={styles.organizationName__picker}
          saveImage={setPicture}
        >
          <div
            className={clsx(styles.organizationName__pickerAvatar, {
              [styles.exist]: !!pictureUri,
            })}
          >
            {!!pictureUri ? <img src={pictureUri} /> : <Camera />}
          </div>

          <p>
            Change picture
            <br />
            (optional)
          </p>
        </ImagePicker>

        <div className={styles.organizationName__contentInput}>
          <Input
            type={"limited"}
            value={name}
            onChange={setName}
            placeholder={"Ex: Medical Garden Inc."}
            label={"Organization Name"}
            error={error}
          />
        </div>
      </div>
      <div className={styles.organizationName__button}>
        <Button
          loading={loading}
          disable={isDisable}
          onClick={createOrganization}
          text={"Next"}
          backgroundType={"green"}
        />
      </div>
    </div>
  );
};
