import { GridView, LeftArrow, Loader, TableView } from "~icons";
import ContentLoader from "react-content-loader";
import { DashboardLayout } from "~layouts";
import { useFolder } from "./useFolder";
import {
  PDFItemView,
  Pagination,
  SearchInput,
  Table,
  ChecklistItem,
  TableLoader,
  GridWrapper,
  PDFItemViewSquare,
  ChecklistItemSquare,
  FolderItem,
  FolderItemSquare,
  SearchBar,
} from "~components";

import styles from "./styles.module.scss";

export const Folder = () => {
  const {
    drafts,
    loading,
    paginationLoading,
    draftsCount,
    fetchedCount,
    searchValue,
    PAGE_LIMIT,
    viewType,
    folderTitle,
    folders,
    goBack,
    setViewType,
    setSearchValue,
    handleNextPage,
    handlePrevPage,
  } = useFolder();

  return (
    <DashboardLayout title="Home">
      <div className={styles.folder}>
        <div className={styles.folder__header}>
          <div onClick={goBack} className={styles.folder__headerIcon}>
            <LeftArrow fill="#979DB1" />
          </div>
          {!!folderTitle ? (
            folderTitle
          ) : (
            <ContentLoader
              speed={1}
              backgroundColor="#DBDEE7"
              foregroundColor="#ebedf2"
              title=""
              height={32}
            >
              <rect x="0" y="0" rx="7" ry="7" width="300" height="32" />
            </ContentLoader>
          )}
        </div>
        <SearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          viewType={viewType}
          setViewType={setViewType}
        />

        {loading && <TableLoader />}

        {!loading && viewType === "table" && (
          <Table
            loading={paginationLoading}
            handleLoad={handleNextPage}
            folders={folders}
            renderFolderItem={(folder, index) => {
              return (
                <FolderItem
                  key={`${folder.id} - ${index} - folder`}
                  folder={folder}
                  index={index}
                  handleView
                  hideActions
                />
              );
            }}
            header={[{ header: "Name", className: styles.folder__name }]}
            data={drafts}
            renderTableItem={(item, index) => {
              return item.type === "Pdf" ? (
                <PDFItemView pdf={item} />
              ) : (
                <ChecklistItem checklist={item} />
              );
            }}
          />
        )}

        {!loading && viewType === "grid" && (
          // <div className={styles.folder__gridWrapper}>
          //   <div className={styles.folder__grid}>
          //     {drafts.map((item: any, index) => {
          //       return item.type === "Pdf" ? (
          //         <PDFItemViewSquare pdf={item} />
          //       ) : (
          //         <ChecklistItemSquare checklist={item} />
          //       );
          //     })}
          //   </div>
          // </div>
          <GridWrapper
            folders={folders}
            data={drafts}
            loading={paginationLoading}
            handleLoad={handleNextPage}
            renderFolderItem={(folder, index) => (
              <FolderItemSquare
                handleView
                hideActions
                folder={folder}
                index={index}
              />
            )}
            renderItem={(item, index) => {
              return item.type === "Pdf" ? (
                <PDFItemViewSquare pdf={item} key={`${item} - ${index}`} />
              ) : (
                <ChecklistItemSquare
                  checklist={item}
                  key={`${item} - ${index}`}
                />
              );
            }}
          />
        )}

        {paginationLoading && (
          <div className={styles.paginationLoader}>
            <Loader fill="#616A85" />
          </div>
        )}

        <div className={styles.pagination}>
          <div className={styles.pagination__left}>
            Showing{" "}
            <span>
              {fetchedCount + PAGE_LIMIT > draftsCount
                ? draftsCount
                : !!draftsCount && !!(fetchedCount + PAGE_LIMIT)
                ? fetchedCount + PAGE_LIMIT
                : 0}
            </span>{" "}
            of <span>{draftsCount}</span> Results
          </div>
        </div>

        {/* <Pagination
          allResults={draftsCount}
          fetchedResult={fetchedCount + PAGE_LIMIT}
          limit={PAGE_LIMIT}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
        /> */}
      </div>
    </DashboardLayout>
  );
};
