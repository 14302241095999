import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  alertVar,
  choosedOrganizationVar,
  GET_CHOOSED_ORGANIZATIOM,
} from "~localCache";
import { IOrganization } from "~models";
import { REMOVE_ORGANIZATION, UPDATE_ORGANIZATION } from "~mutations";
import { GET_MY_ORGANIZATIONS, GET_ORGANIZATION_DETAIL } from "~queries";
import {
  clearChoosedOrganization,
  client,
  setChoosedOrganization,
  uploadFile,
} from "~services";

export const useEditOrganization = () => {
  let navigate = useNavigate();
  const [organization, setOrganization] = useState<IOrganization | null>(null);
  const [mutateUpdateOrganization] = useMutation(UPDATE_ORGANIZATION);
  const [removeOrganization] = useMutation(REMOVE_ORGANIZATION);
  const [updateMyOrganizations] = useLazyQuery(GET_MY_ORGANIZATIONS);
  const {
    data: { myOrganizations },
  } = useQuery(GET_MY_ORGANIZATIONS);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showDeleteLoader, setShowDeleteLoader] = useState(false);

  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [file, setFile] = useState<Blob | null>(null);
  const [name, setName] = useState<string>("");

  const [error, setError] = useState<string>("");

  const [loading, setLoading] = useState(true);

  const [updating, setUpdating] = useState(false);

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [queryOrganizationDetail] = useLazyQuery(GET_ORGANIZATION_DETAIL, {
    variables: { id: choosedOrganization.id },
  });

  const handlePrivateStatus = () => setIsPrivate(!isPrivate);
  const handleNameChange = (value: string) => {
    !!error && setError("");
    setName(value);
  };

  const handleCancelButton = () => {
    setFile(null);
    setName(!!organization?.name ? organization?.name : "");
    setIsPrivate(!!organization?.isPrivate);
  };

  const handleSaveButton = async () => {
    setUpdating(true);
    const input = {
      id: organization?.id,
      name,
      isPrivate: isPrivate,
    };

    if (!!file) {
      let pictureUrl = await uploadFile(file, file.type);
      Object.assign(input, { picture: pictureUrl });
    }

    mutateUpdateOrganization({ variables: { input } })
      .then((resp) => {
        setOrganization({ ...organization, ...resp.data.updateOrganization });
        choosedOrganizationVar({
          ...choosedOrganization,
          ...resp.data.updateOrganization,
        });
        setFile(null);
        setUpdating(false);
        alertVar({
          type: "green",
          text: "The organization has been updated successfully",
        });
      })
      .catch((err) => {
        setUpdating(false);
        setError(err.graphQLErrors[0].message);
      });
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handlOpenDeleteModal = () => setShowDeleteModal(true);

  const isDisable =
    (organization?.name === name &&
      !file &&
      organization.isPrivate === isPrivate) ||
    loading;

  const pictureUrl = !!file ? URL.createObjectURL(file) : organization?.picture;

  useEffect(() => {
    if (!!choosedOrganization) {
      setLoading(true);
      queryOrganizationDetail()
        .then((resp) => {
          setIsPrivate(resp.data.organization.isPrivate);
          setName(resp.data.organization.name);
          setOrganization(resp.data.organization);
          setLoading(false);
        })
        .catch((err) => {
          console.log("ERR", err);
        });
    }
  }, [choosedOrganization]);

  const removeOrganizationFromCache = () => {
    const normalizedId = client.cache.identify({
      id: choosedOrganization.id,
      __typename: "Organization",
    });
    client.cache.evict({ id: normalizedId });
    client.cache.gc();
  };

  const handleDeleteOrganization = () => {
    setShowDeleteLoader(true);

    removeOrganizationFromCache();
    removeOrganization({ variables: { id: choosedOrganization.id } }).then(
      async (resp) => {
        alertVar({
          type: "green",
          text: "The organization was successfully deleted",
        });
        await updateMyOrganizations({
          variables: {},
          fetchPolicy: "network-only",
        }).then(async () => {
          setShowDeleteLoader(false);
          if (myOrganizations.length === 1) {
            clearChoosedOrganization();
            choosedOrganizationVar(null);
            navigate("/dashboard/common");
          } else {
            const index = myOrganizations.findIndex(
              (organization: IOrganization) =>
                organization.id === choosedOrganization.id
            );

            if (index > 0) {
              choosedOrganizationVar(myOrganizations[0]);
              setChoosedOrganization(myOrganizations[0]);
            } else {
              choosedOrganizationVar(myOrganizations[1]);
              setChoosedOrganization(myOrganizations[1]);
            }
            navigate("/dashboard/common");
          }
        });
      }
    );
  };

  return {
    loading,
    setFile,
    name,
    isPrivate,
    handlePrivateStatus,
    error,
    showDeleteModal,
    handleNameChange,
    isDisable,
    pictureUrl,
    handleCancelButton,
    updating,
    showDeleteLoader,
    handleSaveButton,
    handleDeleteOrganization,
    handleCloseDeleteModal,
    handlOpenDeleteModal,
  };
};
