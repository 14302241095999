import { regex } from "~constants";
import { IError } from "./models";
import { IData } from "./models";

export const checkDataErrors = (data: IData): IError | null => {
  if (data.password.length < 8) {
    return { password: "Password must have at least 8 characters" };
  } else if (!regex.atLeastOneNumber.test(data.password)) {
    return { password: "Password must have at least one digit" };
  } else if (!regex.atLeastOneSymbol.test(data.password)) {
    return { password: "Password must have at least one special character" };
  } else if (data.password !== data.confirmPassword) {
    return { confirmPassword: "Repeat password must match password" };
  } else return null;
};
