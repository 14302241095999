import { IItem } from "../../../../constants";
import {
  Button,
  ColorPicker,
  Input,
  SoundDropdown,
  SwitchField,
} from "~components";
import styles from "./styles.module.scss";
import { useTimeMarker } from "./useTimeMarker";
import { Trash } from "~icons";
import { Fragment } from "react";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const TimeMarkerSettings = ({ item, itemIndex }: IProps) => {
  const parsedData =
    typeof item.data === "string" ? JSON.parse(item.data) : item.data;

  let validItem = { ...item, data: parsedData };

  if (parsedData.hasOwnProperty("buttonTitle")) {
    validItem = {
      ...item,
      data: { buttons: [{ title: parsedData.buttonTitle, color: "#29B473" }] },
    };
  }

  const {
    disableAddButtom,
    handleTitleChange,
    disableDeleteButton,
    handleDeleteButton,
    handleAddButton,
    handleChooseColor,
  } = useTimeMarker({
    item: validItem,
    itemIndex,
  });
  return (
    <div className={styles.markerSettings}>
      <div className={styles.markerSettings__underline} />

      {validItem.data.buttons.map(
        (buttonItem: { title: string; color: string }, index: number) => {
          return (
            <Fragment key={`${index} - mark button`}>
              <div className={styles.markerSettings__row}>
                <Input
                  onChange={(value) => handleTitleChange(value, index)}
                  value={buttonItem.title}
                  label="Button text"
                  placeholder="Button text"
                />
                <Trash
                  onClick={() => handleDeleteButton(index)}
                  stroke={disableDeleteButton ? "#DBDEE7" : "#979DB1"}
                />
              </div>
              <div className={styles.markerSettings__colorPicker}>
                <p>Button color</p>
                <ColorPicker
                  showOnlyCircle
                  showCircle
                  text=""
                  color={buttonItem.color}
                  onChoose={(color) => handleChooseColor(color, index)}
                />
              </div>
            </Fragment>
          );
        }
      )}

      <div className={styles.markerSettings__addButton}>
        <Button
          disable={disableAddButtom}
          backgroundType={"green"}
          onClick={handleAddButton}
          text="Add button"
        />
      </div>
    </div>
  );
};
