import { useLazyQuery, useMutation } from "@apollo/client";
import { CANCEL_ORGANIZATION_REGUEST } from "~mutations";
import { GET_MY_ORGANIZATION_REQUESTS } from "~queries";
import { IOrganizationRequest } from "~models";
import { useUpdateEffect } from "usehooks-ts";
import { useEffect, useState } from "react";
import { client } from "~services";

export const useMyRequests = () => {
  const [mutateCancelRequest] = useMutation(CANCEL_ORGANIZATION_REGUEST);
  const [queryGetMyRequests] = useLazyQuery(GET_MY_ORGANIZATION_REQUESTS);

  const [searchValue, setSearchValue] = useState("");
  const [defaultData, setDefaultData] = useState<IOrganizationRequest[]>([]);
  const [filteredData, setFilteredData] = useState<IOrganizationRequest[]>([]);

  const [showLoaderIndex, setShowLoaderIndex] = useState<number | null>(null);

  const removeRequestFromCache = (id: number) => {
    const normalizedId = client.cache.identify({
      id,
      __typename: "OrganizationRequest",
    });
    client.cache.evict({ id: normalizedId });
    client.cache.gc();
  };

  const removeItemFromData = (index: number, id: number) => {
    setFilteredData((prev) => {
      let state = [...prev];
      state.splice(index, 1);
      return state;
    });

    let defaultDataIndex = defaultData.findIndex((item) => item.id === id);
    if (defaultDataIndex > -1) {
      setDefaultData((prev) => {
        let state = [...prev];
        state.splice(defaultDataIndex, 1);
        return state;
      });
    }

    removeRequestFromCache(id);
  };

  const handleCancelRequest = (index: number, id: number) => {
    setShowLoaderIndex(index);
    mutateCancelRequest({ variables: { id } })
      .then((resp) => {
        removeRequestFromCache(id);
        setShowLoaderIndex(null);
        removeItemFromData(index, id);
      })
      .catch((err) => console.log("ERR", err));
  };

  useEffect(() => {
    queryGetMyRequests().then((resp) => {
      setDefaultData(resp.data.myOrganizationRequests);
      setFilteredData(resp.data.myOrganizationRequests);
    });
  }, []);

  const filterRquests = () => {
    if (!!searchValue) {
      let filteredData = defaultData.filter((item: IOrganizationRequest) =>
        item.organization.name
          ?.toLowerCase()
          .includes(searchValue.toLowerCase())
      );
      setFilteredData(filteredData);
    } else {
      setFilteredData(defaultData);
    }
  };

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      filterRquests();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    myOrganizationRequests: filteredData,
    searchValue,
    showLoaderIndex,
    setSearchValue,
    handleCancelRequest,
  };
};
