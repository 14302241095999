import { gql } from "@apollo/client";

export const GET_CHAT_ITEMS = gql`
  query chatItems($input: FindAllChatItemsInput!) {
    chatItems(input: $input) {
      id
      data
      type
      rate
      createdAt
    }
  }
`;

export const GET_CHAT_AI_DATA = gql`
  query getChatAIData($chatId: Int!, $input: FindAllChatItemsInput!) {
    notepad(chatId: $chatId) {
      id
      data
      rewrited
      assessments {
        id
        type
        data
        explanation
        source
      }
      differentials {
        id
        type
        data
        explanation
        source
      }
      diagnosis
      treatments {
        id
        type
        data
        explanation
        source
      }
      createdAt
      updatedAt
    }
    chatItems(input: $input) {
      id
      data
      type
      rate
      createdAt
    }
  }
`;
