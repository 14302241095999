import { BellNotification } from "~icons";
import { useQuery } from "@apollo/client";
import { IPageHeader } from "~models";
import { useState } from "react";
import {
  GET_ALL_ORGANIZATIONS_COUNT,
  GET_MY_INVITATIONS,
  GET_MY_ORGANIZATIONS,
  GET_MY_ORGANIZATION_REQUESTS,
} from "~queries";

import { GET_CHOOSED_ORGANIZATIOM } from "~localCache";

export const useHomePage = () => {
  const { data: organizationData } = useQuery(GET_ALL_ORGANIZATIONS_COUNT, {
    variables: { input: {} },
  });

  const {
    data: { myOrganizations },
  } = useQuery(GET_MY_ORGANIZATIONS);

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const { data: requestsData } = useQuery(GET_MY_ORGANIZATION_REQUESTS, {
    variables: {},
  });

  const { data: invitationsData } = useQuery(GET_MY_INVITATIONS, {
    variables: { input: {} },
  });

  const [showOrganizationModal, setShowOrganizationModal] = useState(false);

  const handleOrganizationModalOpen = () => {
    setShowOrganizationModal(true);
  };

  const handleOrganizationModalClose = () => {
    setShowOrganizationModal(false);
  };

  let pageHeader: IPageHeader[] = [
    {
      title: "All",
      type: "all",
      counter: organizationData?.organizationsCount,
      to: "/dashboard/common/allOrganizations",
    },
    {
      title: "My Organizations",
      type: "myOrganization",
      counter: myOrganizations?.length,
      to: "/dashboard/common/myOrganizations",
    },
    {
      title: "Sent Requests",
      type: "sentRequest",
      counter: requestsData?.myOrganizationRequests?.length,
      to: "/dashboard/common/sentRequests",
    },
    {
      title: "Invitations",
      color: "red",
      counter: invitationsData?.organizationInvitations?.length,
      leftComponent: <BellNotification />,
      type: "Invitations",
      to: "/dashboard/common/invitations",
    },
  ];

  return {
    pageHeader,
    choosedOrganization,
    showOrganizationModal,
    handleOrganizationModalOpen,
    handleOrganizationModalClose,
  };
};
