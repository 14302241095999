import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RESET_PASSWORD } from "~mutations";
import { IData, IError } from "../contants/models";
import { isDisabled } from "../helpers/dataValidator";

export const useResetPassword = () => {
  const navigate = useNavigate();

  const [data, setData] = useState<IData>({
    email: "",
  });

  const [mutationResetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const [error, setError] = useState<IError>({});

  const onChangeData = (value: string, key: keyof IData) => {
    !!error && setError({});
    setData((prev) => {
      let state = { ...prev };
      state[key] = value;
      return state;
    });
  };

  const disableButton = isDisabled(data);

  const onSubmit = () => {
    mutationResetPassword({
      variables: { resetPasswordInput: { email: data.email } },
    })
      .then(() => {
        navigate("/confirmCode", {
          state: { email: data.email, isReset: true },
        });
      })
      .catch((error) => console.log("ERROR", error));
  };

  return {
    data,
    onChangeData,
    loading,
    error,
    disableButton,
    onSubmit,
  };
};
