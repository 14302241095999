import React, { useState } from "react";
import styles from "./styles.module.scss";
import { PopperPopup, SearchInput, CalculatorPopup } from "~components";
import { Options, createPopper } from "@popperjs/core";
import { StrictModifierNames, Modifier } from "react-popper";
import { Calculator, GridView, Letter, SortIcon, TableView, Timer } from "~icons";
import clsx from "clsx";
import { ActiveListsModule } from "../../navigations/Dashboard/LeftPanel/modules";
import { useReactiveVar } from "@apollo/client";
import { sortMethod } from "~localCache";

interface IProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  setViewType: (type: "grid" | "table") => void;
  viewType: "grid" | "table";
}

export const SearchBar = ({
  searchValue,
  setSearchValue,
  setViewType,
  viewType,
}: IProps) => {
  const [showPopper, setShowPopper] = useState(false);
  const sortBy = useReactiveVar(sortMethod)

  const setSortBy = (type: 'time' | 'alphabet') => {
    sortMethod(type)
  }

  const handleSetTime = () => {
    setSortBy("time")
    setShowPopper(false)
  }

  const handleSetAlphabet = () => {
    setSortBy("alphabet")
    setShowPopper(false)
  }

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-end",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [20, 0],
        },
      },
    ],
  };
  return (
    <div className={styles.search}>
      <SearchInput
        value={searchValue}
        onChange={setSearchValue}
        placeholder="Search for Checkems or items"
      />

      <div style={{ marginLeft: 15 }}>
        <ActiveListsModule noLabel />
      </div>
      <div style={{ marginLeft: 15 }}>
        <CalculatorPopup />
      </div>
      <div className={styles.searchRight}>
        <div
          className={styles.searchButton}
          onClick={() => setViewType("table")}
        >
          <TableView fill={viewType === "table" ? "#29B473" : "#979DB1"} />
        </div>

        <div
          className={styles.searchButton}
          onClick={() => setViewType("grid")}
        >
          <GridView fill={viewType === "grid" ? "#29B473" : "#979DB1"} />
        </div>
      </div>
      <PopperPopup
        showPopper={showPopper}
        onClosePopper={() => setShowPopper(false)}
        onOpenPopper={() => setShowPopper(true)}
        options={popperOptions}
        trigger={<SortIcon />}
        popup={
          <div
            // ref={modalRef}
            className={clsx(styles.search__modal, {})}
          >
            <div className={styles.search__modalItem} onClick={handleSetTime}>
              <Timer /> Sort by time
            </div>
            <div className={styles.search__modalItem} onClick={handleSetAlphabet}>
              <Letter /> Sort by alphabet
            </div>
          </div>
        }
      />
    </div>
  );
};
