export const downloadFile = (url: string, filename: string) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename || "file";
      link.click();
    })
    .catch((error) => {
      console.log("Помилка при завантаженні файлу:", error);
    });
};
