import { useBuilder } from "../../../../context";
import { IItem } from "../../../../constants";
import { DropResult } from "react-beautiful-dnd";

interface IProps {
  item: IItem;
  itemIndex: number;
}

interface IAssesment {
  title: string;
  description: string;
  findings: { name: string; score: number }[];
}

export const useAlgorithm = ({ item, itemIndex }: IProps) => {
  const { setList, activeItem, activeTabIndex } = useBuilder();

  let data =
    typeof item.data === "string"
      ? structuredClone(JSON.parse(item.data))
      : structuredClone(item.data);

  const onAssesmentDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) return;

    let newAssesments: IAssesment[] = Array.from(item.data.assessments);
    let orderedAssesment = { ...newAssesments[source.index] };
    newAssesments.splice(source.index, 1);
    newAssesments.splice(destination.index, 0, orderedAssesment);

    data.assessments = newAssesments;

    if (!activeItem) return;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleAddAlgorithm = () => {
    if (!activeItem) return;
    data.assessments.push({
      title: "",
      description: "",
      findings: [
        { name: "", score: "0" },
        { name: "", score: "0" },
      ],
    });
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  return {
    handleAddAlgorithm,
    onAssesmentDragEnd,
  };
};
