import { gql } from "@apollo/client";

export const MEMBER_REQUEST_SUBSCRIPTION = gql`
  subscription newRequest($organizationId: Float!) {
    newRequest(organizationId: $organizationId) {
      id
      created
      canceled
    }
  }
`;

export const MEMBERS_COUNT_SUBSCRIPTION = gql`
  subscription changeMembersCounters($organizationId: Int!) {
    changeMembersCounters(organizationId: $organizationId) {
      members
      admins
      membersLeft
      adminsLeft
    }
  }
`;

export const NEW_NOTIFICATION = gql`
  subscription newNotification($input: NewNotificationInput!) {
    newNotification(input: $input) {
      message
      sound
      itemId
      itemType
      executeDate
    }
  }
`;
