import { Modal, Button, Input } from "~components";
import { Disclaimer } from "~icons";
import { IOrganization } from "~models";
import { useLeaveOwnerModal } from "./useLeaveOwnerModal";

import styles from "./styles.module.scss";

interface IProps {
  onCloseModal: () => void;
  handleSuccessResult: () => void;
  text: string;
  organizations?: IOrganization[];
  isDelete?: boolean;
  isMyProfile?: boolean;
  userId?: number;
}

export const LeaveOwnerModal = ({
  onCloseModal,
  text,
  handleSuccessResult,
  organizations = [],
  isDelete,
  isMyProfile,
  userId,
}: IProps) => {
  const {
    step,
    data,
    loader,
    isDisable,
    handleNextStep,
    handlePrevStep,
    onChangeEmail,
    onSubmit,
  } = useLeaveOwnerModal({
    handleSuccessResult,
    organizations,
    isDelete,
    isMyProfile,
    userId,
  });

  return (
    <Modal
      containerClassName={styles.leaveOwnerModal__container}
      modalClassName={styles.leaveOwnerModal__modal}
      onCloseModal={onCloseModal}
      disableMediaStyles
    >
      <div className={styles.leaveOwnerModal}>
        {step === 1 && (
          <div className={styles.leaveOwnerModal__first}>
            <Disclaimer />
            <p className={styles.leaveOwnerModal__title}>Disclaimer</p>

            <p className={styles.leaveOwnerModal__subtitle}>{text}</p>

            <div className={styles.leaveOwnerModal__firstButtons}>
              <Button
                onClick={onCloseModal}
                text="Cancel"
                backgroundColor="#EF2F32"
                textColor="#FFFFFF"
              />

              <Button
                onClick={handleNextStep}
                text="Select a new owner"
                backgroundType={"green"}
              />
            </div>
          </div>
        )}

        {step === 2 && (
          <div className={styles.leaveOwnerModal__second}>
            <p className={styles.leaveOwnerModal__title}>
              Enter the new owner's email
            </p>

            <p className={styles.leaveOwnerModal__subtitle}>
              Enter your email address so we can send him an invitation
            </p>

            {data?.map((item, index) => (
              <div
                key={`${item.organization.id} - ${index}`}
                className={styles.leaveOwnerModal__row}
              >
                <div className={styles.leaveOwnerModal__rowName}>
                  <Input
                    disable
                    label="Organization"
                    value={item.organization.name}
                    placeholder={"Organization name"}
                  />
                </div>

                <p className={styles.leaveOwnerModal__rowTitle}>
                  {item.organization.name}
                </p>
                <div className={styles.leaveOwnerModal__rowEmail}>
                  <Input
                    labelClassName={styles.label}
                    label="Email"
                    value={item.email}
                    placeholder={"Enter owner email"}
                    onChange={(value) => onChangeEmail(index, value)}
                  />
                </div>
              </div>
            ))}

            <div className={styles.leaveOwnerModal__secondButtons}>
              <Button
                onClick={onCloseModal}
                text="Cancel"
                showShadow
                backgroundType={"white"}
              />

              <Button
                loading={loader}
                disable={isDisable()}
                onClick={isDelete ? handleNextStep : onSubmit}
                text={isDelete ? "Next" : "Send Invite"}
                backgroundType={"green"}
              />
            </div>
          </div>
        )}

        {step === 3 && (
          <>
            <div className={styles.leaveOwnerModal__first}>
              <Disclaimer />
              <p className={styles.leaveOwnerModal__title}>Disclaimer</p>

              <p className={styles.leaveOwnerModal__subtitle}>
                Deleting the account cannot be undone and they will lose all
                information associated with their account
              </p>

              <div className={styles.leaveOwnerModal__firstButtons}>
                <Button
                  onClick={handlePrevStep}
                  text="Back"
                  backgroundColor={"white"}
                  showShadow
                />

                <Button
                  loading={loader}
                  onClick={onSubmit}
                  text="Delete Account"
                  backgroundColor="#EF2F32"
                  textColor="#FFFFFF"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
