import { IItem } from "../../../../constants";
import { Input, SoundDropdown, SwitchField } from "~components";
import styles from "./styles.module.scss";
import { useSelector } from "./useSelector";
import { PlusSymbol, Trash } from "~icons";
import clsx from "clsx";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const SelectorSettings = ({ item, itemIndex }: IProps) => {
  const {
    toggleMultipleSelection,
    onChangeSelection,
    disableRemoveSelections,
    handleAddSelection,
    handleRemoveSelection,
  } = useSelector({
    item,
    itemIndex,
  });
  return (
    <div className={styles.selectorSettings}>
      <SwitchField
        text="Multiple selection"
        value={item.data.multiple}
        onClick={toggleMultipleSelection}
      />
      <div className={styles.selectorSettings__underline} />
      {item.data.selections.map((item: string, index: number) => (
        <div
          key={`selector - ${index}`}
          className={styles.selectorSettings__field}
        >
          <Input
            value={item}
            onChange={(value) => onChangeSelection(value, index)}
            placeholder="Enter Text..."
          />
          <div
            onClick={() => handleRemoveSelection(index)}
            className={clsx({ [styles.disactive]: disableRemoveSelections })}
          >
            <Trash stroke={disableRemoveSelections ? "#DBDEE7" : "#979DB1"} />
          </div>
        </div>
      ))}
      <div
        className={styles.selectorSettings__addButton}
        onClick={handleAddSelection}
      >
        <PlusSymbol fill="#29B473" />
        Add row
      </div>
    </div>
  );
};
