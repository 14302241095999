import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { IFolder, UserAccessRole } from "~models";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_USER } from "~queries";
import { choosedOrganizationVar } from "~localCache";
import { useEffect, useRef } from "react";

interface IProps {
  data: any[];
  draggableTypes?: string[];
  folders: IFolder[];
  renderItem: (item: any, index: number) => any;
  renderFolderItem?: (
    item: IFolder,
    index: number,
    isDraggingOver: boolean
  ) => any;
  onDragEnd: (result: DropResult) => void;
  disableDraggable?: boolean;
  handleLoad?: () => void;
  loading?: boolean;
}

export const GridDraggable = ({
  folders,
  data,
  draggableTypes,
  onDragEnd,
  renderItem,
  renderFolderItem,
  disableDraggable,
  handleLoad,
  loading,
}: IProps) => {
  const { data: userData } = useQuery(GET_CURRENT_USER);
  const choosedOrganization = choosedOrganizationVar();
  const contentRef = useRef<HTMLDivElement>(null);

  const isEditingAccess =
    (!!choosedOrganization?.members &&
      choosedOrganization?.members.length > 0 &&
      choosedOrganization?.members[0].role !== "User") ||
    (!!userData &&
      !!userData?.currentUser &&
      userData.currentUser.role !== UserAccessRole.User);

  function getStyle(style: any, snapshot: any) {
    if (!snapshot.isDropAnimating || !!snapshot.draggingOver) {
      return style;
    }

    return {
      ...style,
      transform: null,
    };
  }

  function getFolderStyle(style: any, snapshot: any) {
    if (!snapshot.isDragging) return {};
    return style;
  }

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      contentRef.current as HTMLDivElement;
    if (scrollHeight - scrollTop <= clientHeight && !loading && handleLoad) {
      handleLoad();
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      // onDragEnd={() => {}}
    >
      <div
        className={styles.gridDraggable}
        ref={contentRef}
        onScroll={handleScroll}
      >
        <div
          className={clsx(
            styles.gridDraggable__items,
            styles.gridDraggable__itemsFolders
          )}
        >
          <div className={styles.gridDraggable__itemsContent}>
            {folders.map((folder, index) => {
              return (
                <Droppable key={folder.id} droppableId={`${folder.id}`}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className={clsx(styles.gridDraggable__folderWrapper)}
                    >
                      {/* {!!renderFolderItem &&
                        renderFolderItem(
                          folder,
                          index,
                          snapshot.isDraggingOver
                        )} */}
                      <Draggable
                        key={folder.id}
                        draggableId={`${folder.id}`}
                        index={index}
                      >
                        {(provided, draggableSnapshot) => (
                          <>
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getFolderStyle(
                                provided.draggableProps.style,
                                draggableSnapshot
                              )}
                            >
                              {!!renderFolderItem &&
                                renderFolderItem(
                                  folder,
                                  index,
                                  snapshot.isDraggingOver &&
                                    !draggableSnapshot.isDragging
                                )}
                            </div>
                            {draggableSnapshot.isDragging && (
                              <div className={styles.table__copiedItem}>
                                {!!renderFolderItem &&
                                  renderFolderItem(
                                    folder,
                                    index,
                                    snapshot.isDraggingOver &&
                                      !draggableSnapshot.isDragging
                                  )}
                              </div>
                            )}
                          </>
                        )}
                      </Draggable>

                      {provided.placeholder && (
                        <div
                          style={{
                            height: "0",
                          }}
                        >
                          {provided.placeholder}
                        </div>
                      )}
                    </div>
                  )}
                </Droppable>
              );
            })}
          </div>
        </div>

        <Droppable
          direction="horizontal"
          droppableId="ITEMS"
          isDropDisabled={true}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={styles.gridDraggable__items}
            >
              <div className={styles.gridDraggable__itemsContent}>
                {data.map((item, index: number) => {
                  let isDragDisabled = !!draggableTypes
                    ? !draggableTypes.includes(item.type)
                    : false;

                  return (
                    <Draggable
                      isDragDisabled={
                        isDragDisabled || !isEditingAccess || disableDraggable
                      }
                      key={item.id}
                      draggableId={`${item.id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getStyle(
                              provided.draggableProps.style,
                              snapshot
                            )}
                          >
                            {renderItem(item, index)}
                          </div>
                          {snapshot.isDragging && (
                            <div className={styles.copiedItem}>
                              {renderItem(item, index)}
                            </div>
                          )}
                        </>
                      )}
                      {/* <div>{renderItem(item, index)}</div> */}
                    </Draggable>
                  );
                })}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};
