import { useLazyQuery, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PopupActions } from "reactjs-popup/dist/types";
import { useOutsideClick } from "~hooks";
import { GET_CHOOSED_ORGANIZATIOM, darkTheme } from "~localCache";
import { IActiveChecklist } from "~models";
import { CREATE_CHAT, REMOVE_PROGRESS } from "~mutations";
import { GET_ALL_ACTIVE_PROGRESSES } from "~queries";

export const useActiveListsModule = () => {
  const navigate = useNavigate();
  const popupRef = useRef<PopupActions>(null);
  const popupContentRef = useRef<HTMLDivElement>(null);
  const popupTriggerRef = useRef<HTMLDivElement>(null);

  const isDark = useReactiveVar(darkTheme);

  const setIsDark = (value: boolean) => {
    darkTheme(value);
    localStorage.setItem("theme", value ? "dark" : "light");
  };

  const [removeProgress] = useMutation(REMOVE_PROGRESS);

  const [createChat] = useMutation(CREATE_CHAT);
  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const { data, error } = useQuery(GET_ALL_ACTIVE_PROGRESSES, {
    variables: { organizationId: choosedOrganization?.id },
    skip: !choosedOrganization?.id,
  });

  const handleCreateChatAI = async () => {
    createChat({ variables: { input: { title: "Some default title" } } }).then(
      (resp) => {
        navigate(`/chat/${resp.data.createChat.id}`);
      }
    );
  };
  const [updateActiveProgress] = useLazyQuery(GET_ALL_ACTIVE_PROGRESSES, {
    variables: {organizationId: choosedOrganization?.id},
    fetchPolicy: 'network-only',
  });

  const handleDeleteProgressButton = (id: number) => {
    handleClosePopup();
    removeProgress({ variables: { id } }).then(async () => {
      await updateActiveProgress();
    });
  };
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    if (!showPopup) {
      popupRef.current?.open();
      setShowPopup(true);
    } else {
      popupRef.current?.close();
      setShowPopup(false);
    }
  };

  const handleShopPopup = () => {
    if (!showPopup) {
      popupRef.current?.open();
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    if (showPopup) {
      popupRef.current?.close();
      setShowPopup(false);
    }
  };

  const handleItemClick = (checklistId: number, progressId: number) => {
    navigate(`/checklist/${checklistId}?progressId=${progressId}`);
  };

  const handleCreateButtonClick = () => {
    navigate("/dashboard/home/active");
    togglePopup();
  };

  useOutsideClick(
    popupContentRef,
    handleClosePopup,
    "mousedown",
    popupTriggerRef
  );

  const countDublicates = (items: IActiveChecklist[]) => {
    const itemCounts: any = {};
    return items.map((item, index) => {
      const count = (itemCounts[item.checklist.title] || 0) + 1;
      itemCounts[item.checklist.title] = count;

      const newItem =
        count > 1 ? `${item.checklist.title} (${count})` : item.checklist.title;
      return { ...item, checklist: { ...item.checklist, title: newItem } };
    });
  };

  useEffect(() => {}, [error]);

  return {
    proggress: !data?.getAllMyActiveProgresses
      ? []
      : data.getAllMyActiveProgresses,
    popupRef,
    popupContentRef,
    popupTriggerRef,
    isDark,
    setIsDark,
    togglePopup,
    handleShopPopup,
    handleItemClick,
    handleCreateButtonClick,
    countDublicates,
    handleDeleteProgressButton,
    handleCreateChatAI,
  };
};
