import { gql } from "@apollo/client";

export const GENERATE_NOTEPAD_RESPONSE = gql`
  mutation generateNotepadResponse($input: GenerateNotepadResponseInput!) {
    generateNotepadResponse(input: $input) {
      id
      data
      rewrited
      assessments {
        id
        type
        data
        explanation
        source
      }
      differentials {
        id
        type
        data
        explanation
        source
      }
      diagnosis
      treatments {
        id
        type
        data
        explanation
        source
      }
      createdAt
      updatedAt
    }
  }
`;

export const REWRITE_NOTEPAD = gql`
  mutation rewriteNotepad($input: RewriteNotepadInput!) {
    rewriteNotepad(input: $input) {
      id
      data
      rewrited
      assessments {
        id
        type
        data
        explanation
        source
      }
      differentials {
        id
        type
        data
        explanation
        source
      }
      diagnosis
      treatments {
        id
        type
        data
        explanation
        source
      }
      createdAt
      updatedAt
    }
  }
`;

export const UNDO_REWRITE_NOTEPAD = gql`
  mutation undoRewriteNotepad($chatId: Int!) {
    undoRewriteNotepad(chatId: $chatId) {
      id
      data
      rewrited
      assessments {
        id
        type
        data
        explanation
        source
      }
      differentials {
        id
        type
        data
        explanation
        source
      }
      diagnosis
      treatments {
        id
        type
        data
        explanation
        source
      }
      createdAt
      updatedAt
    }
  }
`;

export const GENERATE_NOTEPAD_TREATMENTS = gql`
  mutation generateNotepadTreatments($input: GenerateNotepadTreatmentsInput!) {
    generateNotepadTreatments(input: $input) {
      id
      data
      rewrited
      assessments {
        id
        type
        data
        explanation
        source
      }
      differentials {
        id
        type
        data
        explanation
        source
      }
      diagnosis
      treatments {
        id
        type
        data
        explanation
        source
      }
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_NOTEPAD_ANSWER = gql`
  mutation removeNotepadAnswer($id: Int!, $chatId: Int!) {
    removeNotepadAnswer(id: $id, chatId: $chatId) {
      id
    }
  }
`;

export const UPDATE_NOTEPAD = gql`
  mutation updateNotepad($input: GenerateNotepadResponseInput!) {
    updateNotepad(input: $input) {
      id
    }
  }
`;
