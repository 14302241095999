import { DashboardLayout } from "~layouts";
import { Outlet } from "react-router-dom";
import { PageHeader } from "~components";
import { IPageHeader } from "~models";

import styles from "./styles.module.scss";

export const OrganizationSettings = () => {
  const pageHeader: IPageHeader[] = [
    {
      title: "General",
      type: "general",
      to: "/dashboard/organizationSettings/editing",
    },
    {
      title: "Plan & Billing",
      type: "billing",
      to: "/dashboard/organizationSettings/billing",
    },
  ];

  return (
    <DashboardLayout
      contentClassName={styles.organizationSettings__layout}
      title="Organization Settings"
    >
      <div className={styles.organizationSettings}>
        <PageHeader data={pageHeader} />
        <Outlet />
      </div>
    </DashboardLayout>
  );
};
