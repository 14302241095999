export const regex = {
  // minLength2: /^.{2,}$/,
  // maxLength30: /^.{0,30}$/,
  // onlyEnglishLetters: /^[a-zA-Z]+$/,
  onlyNumbers: /^[0-9]+$/,
  negativeOrPositivNumbers: /^[-+]?\d+$/,
  // atLeastOneCapitalLetter: /[A-Z]/,
  atLeastOneNumber: /\d/,
  minLength8: /^.{8,}$/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

  atLeastOneSymbol: /.*\W.*/,
};
