import { LeftArrow } from "~icons";
import { Document, Outline, Page, pdfjs } from "react-pdf";

import styles from "./styles.module.scss";
import { Viewer, Worker } from "@react-pdf-viewer/core";  
import { usePDFViewer } from "./usePDFViewer";
import { Button, Pagination } from "~components";
import clsx from "clsx";
import { downloadFile } from "~services";

import { ToolbarSlot, toolbarPlugin } from "@react-pdf-viewer/toolbar";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

export const PDFViewer = () => {
  const {
    pdf,
    from,
    to,
    numPages,
    loading,
    skipIndex,
    disableNextPagesButton,
    disablePrevPagesButton,
    handleNextPages,
    handlePrevPages,
    goBack,
    onDocumentLoadSuccess,
  } = usePDFViewer();
  const options = {
    cMapUrl: "cmaps/",
    standardFontDataUrl: "standard_fonts/",
  };

  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  // console.log("PDFFFF", pdf);
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  if (loading || !pdf) return null;

  return (
    <div className={styles.pdfViewer}>
      <div className={styles.pdfViewer__header}>
        <div onClick={goBack} className={styles.pdfViewer__headerArrow}>
          <LeftArrow fill="#0B0F1C" />
        </div>
        <div className={styles.pdfViewer__headerContent}>
          <p>{pdf.title}</p>

          <div className={styles.pdfViewer__headerButton}>
            <Button
              onClick={() => downloadFile(pdf.file, pdf.title)}
              text="Download PDF"
              backgroundType="white"
              showShadow
            />
          </div>
        </div>
      </div>

      {/* <div className={styles.pdfViewer__content}> */}

      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div
          className="rpv-core__viewer"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            className={styles.pdfViewer__content}
          >
            <Toolbar>
              {(slots: ToolbarSlot) => {
                const {
                  CurrentPageInput,
                  Download,
                  EnterFullScreen,
                  GoToNextPage,
                  GoToPreviousPage,
                  NumberOfPages,
                  Print,
                  ShowSearchPopover,
                  Zoom,
                  ZoomIn,
                  ZoomOut,
                } = slots;
                return (
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div style={{ padding: "0px 2px" }}>
                      <ShowSearchPopover />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <ZoomOut />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <Zoom />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <ZoomIn />
                    </div>
                    <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                      <GoToPreviousPage />
                    </div>
                    <div style={{ padding: "0px 2px", display: 'flex', alignItems: 'center' }}>
                      <CurrentPageInput /> / <NumberOfPages />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <GoToNextPage />
                    </div>
                    <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                      <EnterFullScreen />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <Download />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <Print />
                    </div>
                  </div>
                );
              }}
            </Toolbar>
          </div>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
            }}
          >
            <Viewer fileUrl={pdf.file} plugins={[toolbarPluginInstance]} />
          </div>
        </div>
      </Worker>
      {/* </div> */}
      {/* <div className={styles.pagination}>
        <div className={styles.pagination__left}>
          Showing <span>{from}</span> to <span>{to}</span> of{" "}
          <span>{numPages}</span> Pages
        </div>
        <div className={styles.pagination__right}>
          <div
            onClick={handlePrevPages}
            className={clsx(styles.pagination__rightButton, {
              [styles.disable]: disablePrevPagesButton,
            })}
          >
            <LeftArrow fill={disablePrevPagesButton ? "#DBDEE7" : "#0B0F1C"} />
          </div>

          <div
            onClick={handleNextPages}
            className={clsx(styles.pagination__rightButton, {
              [styles.disable]: disableNextPagesButton,
            })}
          >
            <div className={styles.rotate}>
              <LeftArrow
                fill={disableNextPagesButton ? "#DBDEE7" : "#0B0F1C"}
              />
            </div>
          </div>
        </div> */}
      {/* </div> */}
    </div>
  );
};
