import { IItem } from "~models";
import { useChecklist } from "../../../../context";

import { useTimer as useTimerHoc } from "react-timer-hook";
import { useEffect, useState } from "react";
import { alarmSounds } from "~audio";
import { useDidUpdateEffect } from "~hooks";
import { useMutation } from "@apollo/client";
import { REMOVE_NOTIFICATION, SET_NOTIFICATION } from "~mutations";

interface IProps {
  item: IItem;
  progressItem: any;
}

export const useTimer = ({ item, progressItem }: IProps) => {
  const { progress, setProgress } = useChecklist();

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [removeNotification] = useMutation(REMOVE_NOTIFICATION);

  const itemIndex = progress.data.items.findIndex(
    (elem: { id: number }) => elem.id === item.id
  );

  const [playMusic, setPlayMusic] = useState(true);

  let secondsFromItem = !!item.data.seconds ? +item.data.seconds : 0;
  let minutesFromItem = !!item.data.minutes ? +item.data.minutes * 60 : 0;

  let totalSecondsFromItem = secondsFromItem + minutesFromItem;

  const time = new Date();
  time.setSeconds(time.getSeconds() + totalSecondsFromItem);

  const { hours, minutes, seconds, isRunning, start, pause, restart, resume } =
    useTimerHoc({
      expiryTimestamp: time,
      autoStart: false,
    });

  const totalSeconds = hours * 60 * 60 + minutes * 60 + seconds;

  const toHHMMSS = (secs: string) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    let res = [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");

    if (+secs > 3600) {
      return res;
    } else return "00:" + res;
  };

  const getSheduledData = () => {
    let currentDate = new Date().getTime();
    const data = {
      itemId: progressItem.id,
      itemType: progressItem.type,
      progressId: progress.id,
      scheduleData: [
        {
          repeat: false,
          // executeDate: new Date(
          //   currentTimestamp + +progressItem.leftSeconds * 1000
          // ),
          // seconds: currentTimestamp + +progressItem.leftSeconds * 1000,
          executeDate: new Date(currentDate + seconds * 1000),
          seconds: +seconds,
          sound: item.data.sound,
          message: item.data.text,
        },
      ],
    };

    setNotification({ variables: { input: data } });
  };

  const handleStart = () => {
    let time = new Date().getTime();
    let currentDate = new Date().getTime();

    const data = {
      itemId: progressItem.id,
      itemType: progressItem.type,
      progressId: progress.id,
      scheduleData: [
        {
          repeat: false,
          executeDate: new Date(currentDate + progressItem.leftSeconds * 1000),
          seconds: +progressItem.leftSeconds,
          sound: item.data.sound,
          message: item.data.text,
        },
      ],
    };

    !progressItem.isMuted && setNotification({ variables: { input: data } });

    if (!progressItem.firstStart) {
      start();
      setProgress((prev) => {
        let state = { ...prev };
        state.data.items[itemIndex].firstStart = time;
        state.data.items[itemIndex].paused = false;

        return state;
      });
    } else {
      let newTime = new Date();
      newTime.setSeconds(newTime.getSeconds() + totalSeconds);
      restart(newTime, true);
      setProgress((prev) => {
        let state = { ...prev };
        state.data.items[itemIndex].lastStart = time;
        state.data.items[itemIndex].paused = false;

        return state;
      });
    }
  };

  const handlePause = () => {
    let time = new Date().getTime();

    // !playMusic && setPlayMusic(true);
    pause();
    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].leftSeconds = totalSeconds;
      state.data.items[itemIndex].paused = true;
      state.data.items[itemIndex].lastPause = time;

      return state;
    });
    removeNotification({
      variables: { input: { itemId: progressItem.id } },
    });
  };

  const handleReset = () => {
    let newTime = new Date();
    newTime.setSeconds(newTime.getSeconds() + totalSecondsFromItem);
    restart(newTime, false);
    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].leftSeconds = totalSecondsFromItem;
      state.data.items[itemIndex].paused = true;
      state.data.items[itemIndex].firstStart = 0;
      state.data.items[itemIndex].lastStart = 0;
      state.data.items[itemIndex].lastPause = 0;
      state.data.items[itemIndex].finisedTime = 0;
      return state;
    });
    removeNotification({
      variables: { input: { itemId: progressItem.id } },
    });
  };

  const toggleMute = () => {
    if (progressItem?.isMuted) {
      if (!progressItem.paused) {
        getSheduledData();
      }
    } else {
      removeNotification({
        variables: { input: { itemId: progressItem.id } },
      });
    }
    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].isMuted = !progressItem?.isMuted;
      return state;
    });
  };

  useEffect(() => {
    if (totalSeconds === 0 && !progressItem.finisedTime) {
      let newTime = new Date();

      // let key: keyof typeof alarmSounds = item.data.sound;
      // let audio = new Audio(alarmSounds[key]);
      // audio.play();
      // setProgress((prev) => {
      //   let state = { ...prev };
      //   state.data.items[itemIndex].lastPause = new Date().getTime();
      //   return state;
      // });
      setProgress((prev) => {
        let state = { ...prev };
        state.data.items[itemIndex].finisedTime = newTime;
        return state;
      });
    }
  }, [totalSeconds, playMusic]);

  const disableResetButton = totalSeconds === totalSecondsFromItem;
  const disablePlayButton = totalSeconds === 0;

  const converted = toHHMMSS(`${totalSeconds}`);

  const continueStopwatch = (seconds: number) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + seconds);
    restart(time, seconds === 0 ? false : !progressItem.paused);
  };

  useEffect(() => {
    if (progressItem?.paused) {
      if (!!progressItem?.leftSeconds && +progressItem?.leftSeconds) {
        continueStopwatch(+progressItem?.leftSeconds);
      }
    } else if (progressItem?.paused === false) {
      if (!!progressItem?.firstStart || !!progressItem?.lastStart) {
        let time = new Date().getTime();
        let difference =
          (!!progressItem?.lastStart
            ? time - +progressItem?.lastStart
            : time - +progressItem?.firstStart) / 1000;

        let leftSeconds = +progressItem.leftSeconds - difference;
        if (leftSeconds < 0 || typeof leftSeconds !== "number")
          setPlayMusic(false);
        continueStopwatch(leftSeconds >= 0 ? leftSeconds : 0);
      }
    }

    if (!progress.data.items[itemIndex]?.totalSeconds) {
      setProgress((prev) => {
        let state = { ...prev };
        state.data.items[itemIndex].totalSeconds = totalSecondsFromItem;
        return state;
      });
    }
  }, []);

  useDidUpdateEffect(() => {
    if (progressItem?.paused) pause();
  }, [progressItem?.paused]);

  useEffect(() => {
    if (!progressItem?.firstStart) handleReset();
  }, [progressItem?.firstStart]);

  return {
    converted,
    handleStart,
    handlePause,
    handleReset,
    toggleMute,
    disableResetButton,
    isRunning,
    disablePlayButton,
  };
};
