import { useBuilder } from "../../../../context";
import { IItem } from "../../../../constants";
interface IProps {
  item: IItem;
  itemIndex: number;
}

export const useStopwatch = ({ item, itemIndex }: IProps) => {
  const { setList, activeTabIndex, activeItem } = useBuilder();

  const toggleRecordTime = () => {
    if (!activeItem) return;
    let data = { ...item.data, record: !item?.data?.record };
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const changePlaybackOrder = () => {
    if (!activeItem) return;
    let data = { ...item.data, afterEachOther: !item?.data?.afterEachOther };
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleAddAlert = () => {
    if (!activeItem) return;
    let alarm = {
      text: "",
      sound: "sound1",
      minutes: 0,
      seconds: 0,
      repeat: false,
    };

    setList((prev) => {
      let state = { ...prev };
      let data = !!item?.data?.alarms
        ? { ...item.data }
        : { record: false, alarms: [] };
      data.alarms.push(alarm);
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;

      return state;
    });
  };

  const handleAlertName = (value: string, index: number) => {
    if (!activeItem) return;
    let data = { ...item.data };
    data.alarms[index].text = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleSoundName = (value: string, index: number) => {
    if (!activeItem) return;
    let data = { ...item.data };
    data.alarms[index].sound = value;

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleChangeTextToSpeech = (value: string, index: number) => {
    if (!activeItem) return;
    let data = { ...item.data };
    data.alarms[index].textToSpeech = value;

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleTTS = async (text: string) => {
    const apiKey = "AIzaSyAGy73cnWSp6epnO2fK-BYfZBkgL_dy7K4";
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;
    const data = {
      input: { text },
      voice: { languageCode: "en-US", ssmlGender: "NEUTRAL" },
      audioConfig: { audioEncoding: "MP3" },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const audioData = await response.json();

      const audioBase64 = audioData.audioContent;
      const audioBuffer = Uint8Array.from(atob(audioBase64), (c) =>
        c.charCodeAt(0)
      );
      const audioBlob = new Blob([audioBuffer], { type: "audio/mp3" });
      const audioUrl = URL.createObjectURL(audioBlob);

      const audio = new Audio(audioUrl);
      audio.play();
    } catch (error) {
      console.error("Error fetching TTS data:", error);
    }
  };

  const handleRingMinutes = (value: string, index: number) => {
    if (!activeItem) return;
    let data = { ...item.data };
    data.alarms[index].ringMinutes = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleRingSeconds = (value: string, index: number) => {
    if (!activeItem) return;
    let data = { ...item.data };
    data.alarms[index].ringSeconds = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleMinutes = (value: string, index: number) => {
    if (!activeItem) return;
    let data = { ...item.data };
    data.alarms[index].minutes = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleSeconds = (value: string, index: number) => {
    if (!activeItem) return;
    let data = { ...item.data };
    data.alarms[index].seconds = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const toggleRepeat = (index: number) => {
    if (!activeItem) return;
    let data = { ...item.data };

    const newRepeatValue = !data.alarms[index].repeat;

    const minutes = !!data.alarms[index].minutes
      ? data.alarms[index].minutes
      : 0;
    const seconds = !!data.alarms[index].seconds
      ? data.alarms[index].seconds
      : 0;
    const ringMinutes = !!data.alarms[index].ringMinutes
      ? data.alarms[index].ringMinutes
      : 0;
    const ringSeconds = !!data.alarms[index].ringSeconds
      ? data.alarms[index].ringSeconds
      : 0;

    if (newRepeatValue) {
      data.alarms[index].ringMinutes = minutes;
      data.alarms[index].ringSeconds = seconds;

      data.alarms[index].minutes = 0;
      data.alarms[index].seconds = 0;
    } else {
      data.alarms[index].minutes = ringMinutes;
      data.alarms[index].seconds = ringSeconds;
    }

    data.alarms[index].repeat = newRepeatValue;

    setList((prev) => {
      let state = { ...prev };
      if (!activeItem) return state;
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleDeleteItem = (index: number) => {
    setList((prev) => {
      let state = { ...prev };
      if (!activeItem) return state;
      let itemData = state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data
      if(typeof itemData === "string") itemData = JSON.parse(itemData)
      itemData.alarms.splice(index, 1)
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = itemData
      return state;
    });
  };

  return {
    handleRingMinutes,
    handleRingSeconds,
    handleTTS,
    handleAddAlert,
    handleAlertName,
    handleSoundName,
    handleMinutes,
    handleSeconds,
    toggleRepeat,
    handleDeleteItem,
    toggleRecordTime,
    changePlaybackOrder,
    handleChangeTextToSpeech,
  };
};
