import { useEffect, useRef, useState } from "react";
import { defaultSection } from "./constants";
import { useBuilder } from "./context";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_DRAFT } from "~queries";
import { PublishingStatus, ISection } from "~models";
import {
  CREATE_SECTION,
  REMOVE_DRAFT,
  REMOVE_ITEM,
  REMOVE_SECTION,
  UPDATE_DRAFT,
  UPDATE_TAB,
} from "~mutations";
import { useOutsideClick } from "~hooks";
import { GET_CHOOSED_ORGANIZATIOM, alertVar } from "~localCache";
import { goBack as goBackHelper } from "~helpers";

export const useListBuilder = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const tabInputRef = useRef<HTMLInputElement>(null);

  const {
    list,
    pdfFormat,
    activeItem,
    activeTabId,
    activeTabIndex,
    showPdfTab,
    setList,
    setError,
    setActiveItem,
    setIsPdfFormat,
  } = useBuilder();

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [queryDraft] = useLazyQuery(GET_DRAFT);
  const [updateDraft] = useMutation(UPDATE_DRAFT);
  const [updateTab] = useMutation(UPDATE_TAB);
  const [createSection] = useMutation(CREATE_SECTION);
  const [removeSection] = useMutation(REMOVE_SECTION);
  const [removeItem] = useMutation(REMOVE_ITEM);
  const [removeDraft] = useMutation(REMOVE_DRAFT);

  const [draftLoading, setDraftLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeletionLoader, setShowDeletionLoader] = useState(false);
  const [tabPrevName, setTabPrevName] = useState("");
  const [showSavingLoader, setShowSavingLoader] = useState(false);

  const handleOpenDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const tabLoading = list.tabs[activeTabIndex].id === 0;
  const sectionLoading =
    list.tabs[activeTabIndex].sections.findIndex((item) => item.id === 0) > -1;

  const onChangeListTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setList((prev) => {
      let state = { ...prev };
      state.title = e.target.value;
      return state;
    });
  };

  const saveListTitleOnServer = () => {
    updateDraft({
      variables: {
        input: {
          id: list.id,
          title: list.title,
        },
      },
    });
  };

  const handleBackRouteName = location?.state?.isAdmin
    ? "/dashboard/checkemDrafts"
    : "/dashboard/lists";

  const addFakeSection = () => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections.push({
        ...defaultSection,
        id: 0,
        order: state.tabs[activeTabIndex].sections.length,
      });
      return state;
    });
  };

  const addRealSection = (realSection: ISection) => {
    let index = list.tabs[activeTabIndex].sections.length - 1;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[index] = realSection;
      return state;
    });
    setActiveItem({
      type: "section",
      id: realSection.id,
      sectionId: realSection.id,
      sectionIndex: index,
    });
  };

  const handleAddSection = () => {
    addFakeSection();
    createSection({
      variables: { input: { checklistTabId: activeTabId } },
    }).then((resp) => addRealSection(resp.data.createTabSection));
  };

  const handleTabTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 50) return;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].name = e.target.value;
      return state;
    });
  };

  const handleDeleteDraft = () => {
    setShowDeletionLoader(true);
    removeDraft({ variables: { id: list.id } }).then(() => {
      navigate(handleBackRouteName);
    });
  };

  const handleTabBlur = () => {
    if (list.tabs[activeTabIndex].name.length === 0) {
      setList((prev) => {
        let state = { ...prev };
        state.tabs[activeTabIndex].name = tabPrevName;
        return state;
      });
    } else if (tabPrevName !== list.tabs[activeTabIndex].name) {
      updateTab({
        variables: {
          input: {
            id: list.tabs[activeTabIndex].id,
            name: list.tabs[activeTabIndex].name,
          },
        },
      });
      setTabPrevName(list.tabs[activeTabIndex].name);
    }
  };

  useOutsideClick(tabInputRef, handleTabBlur);

  const goBack = () => {
    setActiveItem(null);
    if (!list.title) {
      setShowSavingLoader(true);
      updateDraft({
        variables: {
          input: {
            id: list.id,
            title: "Untitled"
          },
        },
      });
    }
    setShowSavingLoader(true);
    setTimeout(() => {
      setShowSavingLoader(false);
      navigate(handleBackRouteName);
    }, 250);
  };

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    if (
      window.confirm(
        'Please use the "Save and exit" button, otherwise some changes may not be saved'
      )
    ) {
      goBack();
    } else {
      window.history.pushState(null, "", window.location.pathname);
    }
  };

  const handleUserKeyPress = (event: any) => {
    if (event.code === "Backspace" && !!activeItem) {
      const inputs = ["input", "textarea", "select", "button"];
      var activeElement = document.activeElement;

      if (
        activeElement &&
        inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1
      ) {
        return false;
      }
      if (activeItem.type === "section") {
        removeSection({ variables: { id: activeItem.sectionId } });
        setList((prev) => {
          let state = { ...prev };
          state.tabs[activeTabIndex].sections.splice(
            activeItem?.sectionIndex,
            1
          );
          return state;
        });
      } else {
        removeItem({ variables: { id: activeItem.id } }).catch(() => {});
        setList((prev) => {
          let state = { ...prev };
          const itemIndex = state.tabs[activeTabIndex].sections[
            activeItem.sectionIndex
          ].items.findIndex((item) => item.id === activeItem.id);
          if (itemIndex > -1) {
            state.tabs[activeTabIndex].sections[
              activeItem.sectionIndex
            ].items.splice(itemIndex, 1);
          }
          return state;
        });
      }
    }
  };

  useEffect(() => {
    const id = !!params?.id ? +params?.id : -1;
    if (id > -1) {
      queryDraft({
        variables: { id },
        fetchPolicy: "network-only",
      })
        .then((resp) => {
          if (
            resp.data.draft.tabs[0].sections.length === 1 &&
            resp.data.draft.tabs[0].sections[0].items.length === 0
          ) {
            setActiveItem({
              type: "section",
              id: resp.data.draft.tabs[0].sections[0].id,
              sectionId: resp.data.draft.tabs[0].sections[0].id,
              sectionIndex: 0,
            });
          }
          setList(structuredClone(resp.data.draft));
          setTabPrevName(resp.data.draft.tabs[0].name);
          setDraftLoading(false);
        })
        .catch(() => {
          alertVar({ type: "red", text: "Something went wrong" });
          navigate(handleBackRouteName);
        });
    }

    !!location?.state?.error && setError(location.state.error);
    !!location?.state?.pdf && setIsPdfFormat(true);

    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    window.onbeforeunload = () => {
      return "";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    setTabPrevName(list.tabs[activeTabIndex].name);
  }, [activeTabIndex]);

  return {
    tabInputRef,
    containerRef,
    list,
    draftLoading,
    tabLoading,
    activeTabIndex,
    sectionLoading,
    pdfFormat,
    myRole: choosedOrganization.members[0].role,
    showDeleteModal,
    showDeletionLoader,
    showPdfTab,
    showSavingLoader,
    handleAddSection,
    saveListTitleOnServer,
    goBack,
    handleTabTitleChange,
    onChangeListTitle,
    handleDeleteDraft,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
  };
};
