import { Button, Modal } from "~components";
import { CheckCircle } from "~icons";

import styles from "./styles.module.scss";

interface IProps {
  isOpen: boolean;
  showLoader: boolean;
  handleCompleteChecklist: () => void;
  onCloseModal: () => void;
}

export const CompleteChecklistModal = ({
  isOpen,
  showLoader,
  handleCompleteChecklist,
  onCloseModal,
}: IProps) => {
  if (!isOpen) return null;

  return (
    <Modal disableMediaStyles onCloseModal={onCloseModal}>
      <div className={styles.modal}>
        <CheckCircle />
        <p className={styles.modal__title}>
          Once completed, you will no longer be able to make changes to this
          checklist.
        </p>

        <div className={styles.modal__buttons}>
          <Button
            onClick={onCloseModal}
            text="Cancel"
            backgroundColor="#EF2F32"
            textColor="#FFFFFF"
          />

          <Button
            loading={showLoader}
            onClick={handleCompleteChecklist}
            text="Complete"
            backgroundType={"green"}
          />
        </div>
      </div>
    </Modal>
  );
};
