import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { regex } from "~constants";
import { useAuth } from "~hooks";
import { alertVar, choosedOrganizationVar } from "~localCache";
import { IOrganization } from "~models";
import { TRANSFER_OWNER } from "~mutations";
import { GET_CURRENT_USER, GET_MY_ORGANIZATIONS } from "~queries";
import { client } from "~services";

interface IProps {
  handleSuccessResult: () => void;
  organizations?: IOrganization[];
  isDelete?: boolean;
  isMyProfile?: boolean;
  userId?: number;
}

export const useLeaveOwnerModal = ({
  handleSuccessResult,
  organizations = [],
  isDelete,
  isMyProfile,
  userId,
}: IProps) => {
  const {
    data: { currentUser },
  } = useQuery(GET_CURRENT_USER);

  const navigate = useNavigate();
  const { setUser } = useAuth();
  const [getMyOrganizations] = useLazyQuery(GET_MY_ORGANIZATIONS);
  const [step, setStep] = useState(1);
  const [transferOwner] = useMutation(TRANSFER_OWNER);
  const [data, setData] = useState(
    organizations?.map((item) => {
      return { organization: item, email: "" };
    })
  );

  const [loader, setLoader] = useState(false);

  const onChangeEmail = (index: number, value: string) => {
    setData((prev) => {
      if (!prev) return data;
      let state = [...prev];
      state[index].email = value;
      return state;
    });
  };

  const handleNextStep = () => setStep(step + 1);
  const handlePrevStep = () => setStep(step - 1);

  const isDisable = () => {
    const index = data.findIndex((item) => !regex.email.test(item.email));
    return index > -1 ? true : false;
  };

  const onSubmit = () => {
    setLoader(true);
    const input = data.map((item) => {
      return { organizationId: item.organization.id, email: item.email };
    });

    transferOwner({
      variables: { input: input, isDelete: false, userId: currentUser?.id },
    })
      .then(async (resp) => {
        if (isDelete) {
          if (isMyProfile) {
            choosedOrganizationVar(null);
            localStorage.clear();
            setUser(null);
            setTimeout(() => {
              client.resetStore();
            }, 100);
          } else {
            alertVar({
              type: "green",
              text: "The user was successfully deleted",
            });
            navigate("/dashboard");
          }
        } else {
          await getMyOrganizations({
            variables: {},
            fetchPolicy: "network-only",
          });
          setLoader(false);
          handleSuccessResult();
        }
      })
      .catch((err) => {
        setLoader(false);
        if (!!err?.graphQLErrors[0]?.message) {
          alertVar({ type: "red", text: err.graphQLErrors[0].message });
        }
      });
  };

  return {
    step,
    data,
    loader,
    isDisable,
    handleNextStep,
    handlePrevStep,
    onChangeEmail,
    onSubmit,
  };
};
