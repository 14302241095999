import { useRef, useState } from "react";
import { PopupActions } from "reactjs-popup/dist/types";
import { useOutsideClick } from "~hooks";
import { ITab, defaultTab } from "../../../../constants";
import { useBuilder } from "../../../../context";
import { useMutation } from "@apollo/client";
import { DUPLICATE_TAB, REMOVE_TAB, UPDATE_TAB } from "~mutations";

interface IProps {
  tab: ITab;
}

export const useTab = ({ tab }: IProps) => {
  const {
    list,
    error,
    activeTabId,
    activeTabIndex,
    showPdfTab,
    activeItem,
    setActiveItem,
    setList,
    setActiveTabId,
    setShowPdfTab,
  } = useBuilder();

  const [updateTab] = useMutation(UPDATE_TAB);
  const [deleteTab] = useMutation(REMOVE_TAB);
  const [duplicateTab] = useMutation(DUPLICATE_TAB);

  const popupRef = useRef<PopupActions>(null);
  const popupContentRef = useRef<HTMLDivElement>(null);
  const tabRef = useRef<HTMLDivElement>(null);

  const [showPopup, setShowPopup] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const [prevName, setPrevName] = useState("");

  const handlePopupClose = () => {
    if (showPopup) {
      popupRef.current?.close();
      setShowPopup(false);
    }
  };
  const handlePopupOpen = () => {
    popupRef.current?.open();
    setShowPopup(true);
  };

  const handleClosePdfUploader = () => setShowPdfTab(false);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 50) return;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].name = e.target.value;
      return state;
    });
  };

  const togglePopup = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    showPopup ? handlePopupClose() : handlePopupOpen();
  };
  const isTabActive = tab.id === activeTabId;
  const disableDeleteButton = list.tabs.length === 1;

  const changeActiveTab = () => {
    if (!!activeItem) {
      setActiveItem(null);
      setTimeout(() => {
        showPdfTab && setShowPdfTab(false);
        setActiveTabId(tab.id);
      }, 0);
    } else {
      showPdfTab && setShowPdfTab(false);
      setActiveTabId(tab.id);
    }

    // !!activeItem && setActiveItem(null);
    // showPdfTab && setShowPdfTab(false);
    // setActiveTabId(tab.id);
  };

  const handleDeleteTab = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (disableDeleteButton) return;
    handlePopupClose();
    let tabs = [...list.tabs]
      .filter((item) => item.id !== tab.id)
      .map((item, index) => Object.assign(item, { order: index + 1 }));
    setList((prev) => {
      let state = { ...prev };
      state.tabs = tabs;
      return state;
    });

    deleteTab({ variables: { id: tab.id } });
  };

  const handleEditableEnable = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setPrevName(tab.name);
    setIsEditable(true);
    handlePopupClose();
  };

  const showError = error.tabIds.findIndex((item) => item === tab.id) > -1;

  const handleClickOutsideTab = () => {
    if (!isEditable) return;
    if (tab.name.length === 0) {
      setList((prev) => {
        let state = { ...prev };
        state.tabs[activeTabIndex].name = prevName;
        return state;
      });
    } else {
      updateTab({ variables: { input: { id: tab.id, name: tab.name } } });
    }
    setIsEditable(false);
  };

  const addFakeTab = () => {
    setList((prev) => {
      let state = { ...prev };
      let newTab = {
        ...defaultTab,
        name: `Tab ${list.tabs.length + 1}`,
        id: 0,
        order: list.tabs.length + 1,
      };
      let tabs = [...prev.tabs, newTab];
      state.tabs = tabs;
      return state;
    });
    setActiveTabId(0);
  };

  const addRealTab = (tab: ITab) => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[state.tabs.length - 1] = tab;
      return state;
    });
    setActiveTabId(tab.id);
  };

  const handleDuplicateTab = () => {
    addFakeTab();
    duplicateTab({ variables: { id: tab.id } }).then((resp) =>
      addRealTab(resp.data.duplicateChecklistTab)
    );
  };

  useOutsideClick(popupContentRef, handlePopupClose, "mousedown");
  useOutsideClick(tabRef, handleClickOutsideTab);

  return {
    popupRef,
    popupContentRef,
    isTabActive,
    isEditable,
    tabRef,
    disableDeleteButton,
    showError,
    showPdfTab,
    handlePopupOpen,
    handlePopupClose,
    togglePopup,
    changeActiveTab,
    handleEditableEnable,
    handleChangeName,
    handleDeleteTab,
    handleDuplicateTab,
  };
};
