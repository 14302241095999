import { FC, useState } from "react";
import { clsx } from "clsx";
import styles from "./styles.module.scss";

interface IProps {
  label?: string;
  placeholder: string;
  value?: string;
  onChange?: (val: string) => void;
}
export const TextArea: FC<IProps> = ({
  label,
  placeholder,
  value = "",
  onChange,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const setActiveFocused = () => setIsFocused(true);
  const setDisactiveFocused = () => setIsFocused(false);

  return (
    <div className={styles.textareaContainer}>
      {!!label && (
        <div className={styles.textareaContainer__label}>{label}</div>
      )}
      <div className={clsx(styles.inputWrapper, { [styles.focus]: isFocused })}>
        <textarea
          value={value}
          onChange={(e) => !!onChange && onChange(e.target.value)}
          placeholder={placeholder}
          onFocus={setActiveFocused}
          onBlur={setDisactiveFocused}
        />
      </div>
    </div>
  );
};
