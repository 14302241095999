export const alarmSounds = {
  sound1: require("./alarm/sound1.wav"),
  sound2: require("./alarm/sound2.wav"),
  sound3: require("./alarm/sound3.wav"),
  sound4: require("./alarm/sound4.wav"),
  sound5: require("./alarm/sound5.wav"),
};

export const metronomeSounds = {
  "8bmp": require("./metronome/8bpm.wav"),
  "10bmp": require("./metronome/10bpm.wav"),
  "12bmp": require("./metronome/12bpm.wav"),
  "15_2bmp": require("./metronome/15_2.wav"),
  "16bmp": require("./metronome/16bpm.wav"),
  "20bmp": require("./metronome/20bpm.wav"),
  "25bmp": require("./metronome/25bpm.wav"),
  "30_2bmp": require("./metronome/30_2.wav"),
  "30bmp": require("./metronome/30bpm.wav"),
  "110bmp": require("./metronome/110bpm.wav"),
  ventilation: require("./metronome/ventilation.wav"),
};
