import { CopyToClipboard } from "react-copy-to-clipboard";
import { useShareCodeModal } from "./useShareCodeModal";
import ContentLoader from "react-content-loader";
import { alertVar } from "~localCache";
import { Modal } from "~components";
import { Duplicate } from "~icons";

import styles from "./styles.module.scss";

interface IProps {
  id: number;
  type: "Pdf" | "Draft" | "Folder";
  isOpen: boolean;
  onCloseModal: () => void;
}

export const ShareCodeModal = ({ id, type, isOpen, onCloseModal }: IProps) => {
  const { shareCode, handleCloseModal } = useShareCodeModal({
    id,
    type,
    isOpen,
    onCloseModal,
  });

  if (!isOpen) return null;

  return (
    <Modal onCloseModal={handleCloseModal}>
      <div className={styles.modal}>
        <p className={styles.modal__title}>
          {type === "Pdf" ? "PDF" : "Checkem"} code
        </p>
        {!!shareCode ? (
          <div className={styles.modal__field}>
            <p>{shareCode}</p>

            <CopyToClipboard
              text={shareCode}
              onCopy={() =>
                alertVar({ text: "Code copied successfully", type: "green" })
              }
            >
              <div className={styles.modal__fieldIcon}>
                <Duplicate />
              </div>
            </CopyToClipboard>
          </div>
        ) : (
          <ContentLoader
            speed={1}
            width={1096}
            height={27}
            style={{ marginTop: "12px" }}
            viewBox="0 0 1096 27"
            backgroundColor="#DBDEE7"
            foregroundColor="#ebedf2"
          >
            <rect x="0" y="0" rx="7" ry="7" width="277" height="27" />
          </ContentLoader>
        )}

        <div className={styles.modal__underline} />
      </div>
    </Modal>
  );
};
