import React, { FC } from "react";

import styles from "./styles.module.scss";
import { MinusSymbol, PlusSymbol } from "~icons";
import clsx from "clsx";
import { regex } from "~constants";

interface IProps {
  value: number | string;
  onChange: (val: number | string) => void;
  minValue?: number;
  maxValue?: number;
  isDisable?: boolean;
  autoFocus?: boolean;
  allowNegative?: boolean;
}

export const Counter: FC<IProps> = ({
  value,
  minValue = 0,
  maxValue,
  onChange,
  isDisable,
  autoFocus = true,
  allowNegative,
}) => {
  const disableMinusButton =
    (minValue !== undefined && value === minValue) || isDisable;
  const disablePlusButton =
    (maxValue !== undefined && value === maxValue) || isDisable;

  const handleMinusButton = () => !disableMinusButton && onChange(+value - 1);
  const handlePlusButton = () => !disablePlusButton && onChange(+value + 1);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      allowNegative &&
      (e.target.value === "" ||
        e.target.value === "-" ||
        regex.negativeOrPositivNumbers.test(e.target.value))
    ) {
      onChange(e.target.value);
      return;
    }
    if (/^[0-9]*$/.test(e.target.value) || e.target.value === "") {
      const number = +e.target.value;

      if (typeof minValue === "number" && number < minValue) return;
      if (typeof maxValue === "number" && number > maxValue) return;
      onChange(number);
    }
  };

  const onBlur = () => {
    if (value === "" || value === "-") {
      onChange(0);
    }
  };

  return (
    <div className={styles.counter}>
      <div
        onClick={handleMinusButton}
        className={clsx(styles.counter__button, {
          [styles.counter__buttonDisable]: disableMinusButton,
        })}
      >
        <MinusSymbol fill={disableMinusButton ? "#979DB1" : "#0B0F1C"} />
      </div>
      <input
        onBlur={onBlur}
        autoFocus={autoFocus}
        disabled={isDisable}
        className={clsx(styles.counter__input, { [styles.disable]: isDisable })}
        value={value}
        onChange={handleInputChange}
      />
      <div
        className={clsx(styles.counter__button, {
          [styles.counter__buttonDisable]: disablePlusButton,
        })}
        onClick={handlePlusButton}
      >
        <PlusSymbol fill={disablePlusButton ? "#979DB1" : "#0B0F1C"} />
      </div>
    </div>
  );
};
