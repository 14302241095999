import styles from "./styles.module.scss";

import { useCalculator } from "./useCalculator";
import { Button, Input } from "~components";
import { IItem } from "~models";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const Calculator = ({ item, isDisable, progressItem }: IProps) => {
  const {
    isNoData,
    itemWithParsedData,
    disableCalculateButton,
    calculate,
    handleInputChange,
  } = useCalculator({ item, progressItem });

  return (
    <div className={styles.calculator}>
      {isNoData ? (
        <div className={styles.calculator__noData}>No data</div>
      ) : (
        <>
          {itemWithParsedData.data.inputs.map(
            (item: { name: string }, index: number) => {
              return !!item.name ? (
                <div
                  key={`${index} - calculator `}
                  className={styles.calculator__field}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Input
                    value={
                      !!progressItem.inputs ? progressItem.inputs[index] : ""
                    }
                    onChange={(value) => handleInputChange(value, index)}
                    disable={isDisable}
                    label={item.name}
                    placeholder="Enter value"
                  />
                </div>
              ) : null;
            }
          )}

          <Button
            onClick={calculate}
            disable={isDisable || disableCalculateButton}
            backgroundType={"green"}
            text="Calculate"
          />

          {!!progressItem?.outputs &&
            progressItem.outputs.map(
              (item: { name: string; result: number }, index: number) => (
                <div
                  key={`output - ${index}`}
                  className={styles.calculator__output}
                >
                  <p className={styles.calculator__outputName}>{item.name}</p>
                  <p className={styles.calculator__outputResult}>
                    {item.result}
                  </p>
                </div>
              )
            )}

          {/* {item.data.outputs.map((item: { name: string; logic: string }) => {
            return !!item.name ? (
              <div className={styles.calculator__field}>
                <Input label={item.name} placeholder="Enter value" />
              </div>
            ) : null;
          })} */}
        </>
      )}
    </div>
  );
};
