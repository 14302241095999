import styles from "./styles.module.scss";

import { IItem } from "../../../../../constants";
import { useSelector } from "./useSelector";
import clsx from "clsx";

interface IProps {
  item: IItem;
  isDisable: boolean;
}

export const Selector = ({ item, isDisable }: IProps) => {
  const { isNoData, fieldSelections } = useSelector({ item });
  return (
    <div className={styles.selector}>
      {isNoData ? (
        <div className={styles.selector__noData}>No data</div>
      ) : (
        <>
          {fieldSelections.map((item: string, index: number) => (
            <div
              key={`${item} - ${index}`}
              className={clsx(styles.selector__selection, {
                [styles.disable]: isDisable,
              })}
            >
              {item}
            </div>
          ))}
        </>
      )}
    </div>
  );
};
