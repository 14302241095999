import { IItem } from "../../../../constants";
import { Input, SoundDropdown } from "~components";
import styles from "./styles.module.scss";
import { useMetronome } from "./useMetronome";
import clsx from "clsx";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const MetronomeSettings = ({ item, itemIndex }: IProps) => {
  const { handleType } = useMetronome({
    item,
    itemIndex,
  });
  return (
    <>
      <div className={styles.metronomeSettings__underline} />
      <div className={styles.metronomeSettings}>
        <div
          className={clsx(styles.metronomeSettings__bg, {
            [styles.right]: item.data.type === "Ventilations",
          })}
        />
        <div
          onClick={() => handleType("CPR")}
          className={clsx({ [styles.active]: item.data.type === "CPR" })}
        >
          CPR
        </div>
        <div
          onClick={() => handleType("Ventilations")}
          className={clsx({
            [styles.active]: item.data.type === "Ventilations",
          })}
        >
          Ventilations
        </div>
      </div>
    </>
  );
};
