import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CHANGE_PASSWORD } from "~mutations";
import { IData, IError } from "../contants/models";
import { checkDataErrors, isDisabled } from "../helpers/dataValidator";

export const useChoosePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState<IData>({
    password: "",
    confirmPassword: "",
  });

  const [mutationResetPassword, { loading }] = useMutation(CHANGE_PASSWORD);

  const [error, setError] = useState<IError>({});

  const onChangeData = (value: string, key: keyof IData) => {
    !!error && setError({});
    setData((prev) => {
      let state = { ...prev };
      state[key] = value;
      return state;
    });
  };

  const disableButton = isDisabled(data);

  const onSubmit = () => {
    let error = checkDataErrors(data);
    if (!!error) {
      setError(error);
      return;
    }
    mutationResetPassword({
      variables: {
        changePasswordInput: {
          email: location.state.email,
          password: data.password,
        },
      },
    }).then((resp) => {
      navigate("/signIn");
    });
  };

  return { data, onChangeData, loading, error, disableButton, onSubmit };
};
