import React, { FC, useEffect, useRef } from "react";
import clsx from "clsx";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import styles from "./styles.module.scss";
import { IFolder, UserAccessRole } from "~models";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_USER } from "~queries";
import { choosedOrganizationVar } from "~localCache";

interface IProps {
  header: {
    header: string;
    flex?: number;
    className?: string;
  }[];
  draggableTypes?: string[];
  data: any[];
  renderTableItem: (item: any, index: number) => any;
  renderFolderItem?: (
    item: IFolder,
    index: number,
    isDraggingOver: boolean
  ) => any;
  scrollBlockRef?: React.Ref<HTMLDivElement>;
  folders: IFolder[];
  onDragEnd: (result: DropResult) => void;
  handleLoad?: () => void;
  loading?: boolean;
  disableDraggable?: boolean;
}

export const TableDraggable: FC<IProps> = ({
  header,
  data,
  folders,
  renderTableItem,
  renderFolderItem,
  draggableTypes,
  onDragEnd,
  handleLoad,
  disableDraggable,
  loading,
}) => {
  const { data: userData } = useQuery(GET_CURRENT_USER);
  const choosedOrganization = choosedOrganizationVar();
  const contentRef = useRef<HTMLDivElement>(null);

  const isEditingAccess =
    (!!choosedOrganization?.members &&
      choosedOrganization?.members.length > 0 &&
      choosedOrganization?.members[0].role !== "User") ||
    (!!userData &&
      !!userData?.currentUser &&
      userData.currentUser.role !== UserAccessRole.User);

  function getStyle(style: any, snapshot: any) {
    if (!snapshot.isDragging) return { height: 0 };
    return style;
  }

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      contentRef.current as HTMLDivElement;
    if (scrollHeight - scrollTop <= clientHeight && !loading && handleLoad) {
      handleLoad();
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={styles.table}>
        <div className={styles.table__header}>
          {header.map((item, index) => {
            return (
              <div
                key={`${item.header} - ${index}`}
                className={clsx(styles.table__headerItem, item?.className)}
                style={!!item.flex ? { flex: item.flex } : {}}
              >
                <p> {item.header}</p>
              </div>
            );
          })}
        </div>

        <div
          className={styles.table__content}
          onScroll={handleScroll}
          ref={contentRef}
        >
          <div>
            {folders.map((folder, index) => {
              return (
                <Droppable key={folder.id} droppableId={`${folder.id}`}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className={styles.table__folderWrapper}
                    >
                      <Draggable
                        key={folder.id}
                        draggableId={`${folder.id}`}
                        index={index}
                      >
                        {(provided, draggableSnapshot) => (
                          <>
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getStyle(
                                provided.draggableProps.style,
                                draggableSnapshot
                              )}
                            >
                              {!!renderFolderItem &&
                                renderFolderItem(
                                  folder,
                                  index,
                                  snapshot.isDraggingOver &&
                                    !draggableSnapshot.isDragging
                                )}
                            </div>
                            {draggableSnapshot.isDragging && (
                              <div className={styles.table__copiedItem}>
                                {!!renderFolderItem &&
                                  renderFolderItem(
                                    folder,
                                    index,
                                    snapshot.isDraggingOver &&
                                      !draggableSnapshot.isDragging
                                  )}
                              </div>
                            )}
                          </>
                        )}
                      </Draggable>

                      {/* <div style={{ display: "none" }}> */}
                      {provided.placeholder}
                      {/* </div> */}
                    </div>
                  )}
                </Droppable>
              );
            })}
          </div>

          <Droppable droppableId="ITEMS" isDropDisabled={true}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className={styles.table__rows}>
                {data.map((item, index) => {
                  let isDragDisabled = !!draggableTypes
                    ? !draggableTypes.includes(item.type)
                    : false;
                  return (
                    <Draggable
                      isDragDisabled={
                        isDragDisabled || !isEditingAccess || disableDraggable
                      }
                      key={item.id}
                      draggableId={`${item.id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {renderTableItem(item, index)}
                          </div>
                          {snapshot.isDragging && (
                            <div className={styles.copiedItem}>
                              {renderTableItem(item, index)}
                            </div>
                          )}
                        </>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>

        {/* <div ref={scrollBlockRef} className={styles.table__rows}>
          {data.map((item, index) => renderTableItem(item, index))}
        </div> */}
      </div>
    </DragDropContext>
  );
};
