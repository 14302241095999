import { DotsVertical, Loader, TrashAlt, UserProfile, Pen } from "~icons";
import { Avatar, Dropdown, PopperPopup } from "~components";
import { StrictModifierNames, Modifier } from "react-popper";
import { Options, createPopper } from "@popperjs/core";
import { useMemberItem } from "./useMemberItem";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  IOrganization,
  OrganizationMember,
  OrganizationMemberRole,
} from "~models";

import styles from "./styles.module.scss";

interface IProps {
  dataLength: number;
  item: OrganizationMember;
  index: number;
  scrollBlockRef?: React.RefObject<HTMLDivElement>;
  handleChangeRole: (value: OrganizationMemberRole, index: number) => void;
  choosedOrganization: IOrganization;
  deleteMember: (id: number) => void;
}

export const MemberItem = ({
  dataLength,
  index,
  item,
  scrollBlockRef,

  handleChangeRole,
  choosedOrganization,
  deleteMember,
}: IProps) => {
  const navigate = useNavigate();

  const {
    itemRef,
    onChangeMemberRole,
    rotate,
    showLoader,
    modalRef,
    isMyMember,
    showDeleteButton,
    showEditButton,
    isIUser,
    isInvitedMember,
    showPopper,
    handleClosePopper,
    handleOpenPopper,
  } = useMemberItem({
    dataLength,
    item,
    index,
    scrollBlockRef,
    handleChangeRole,
    choosedOrganization,
  });

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  return (
    <>
      <div ref={itemRef} className={styles.memberItem}>
        <div className={styles.memberItem__member}>
          {!!item?.user?.firstName ? (
            <Avatar
              uri={item?.user?.avatar}
              item={item.user}
              type="user"
              containerStyles={styles.memberItem__memberAvatar}
            />
          ) : (
            <div className={styles.memberItem__memberAvatar} />
          )}

          <div className={styles.memberItem__memberInitials}>
            <p>
              {item?.user?.firstName} {item?.user?.lastName}
            </p>
            <p>
              {!!item?.user?.email ? item?.user?.email : item?.appInvite?.email}
            </p>
          </div>
        </div>
        <div
          className={clsx(styles.memberItem__date, {
            [styles.invited]: isInvitedMember,
          })}
        >
          {isInvitedMember ? (
            "Invited"
          ) : (
            <>
              <p className={styles.memberItem__dateLarge}>
                {dayjs(item.createdAt).format("MMMM D, YYYY")}
              </p>
              <p className={styles.memberItem__dateShort}>
                {dayjs(item.createdAt).format("DD/MM/YY")}
              </p>
            </>
          )}
        </div>
        <div className={styles.memberItem__role}>
          {item.role === OrganizationMemberRole.Owner ||
          isIUser ||
          isInvitedMember ? (
            <p>{item.role}</p>
          ) : (
            <div className={styles.memberItem__roleLeft}>
              <div className={styles.memberItem__roleDropdown}>
                <Dropdown
                  value={item.role}
                  onChange={(index, value) =>
                    onChangeMemberRole(value as OrganizationMemberRole)
                  }
                  rotate={rotate}
                  placeholder="Role"
                  data={[
                    OrganizationMemberRole.Admin,
                    OrganizationMemberRole.User,
                  ]}
                />
              </div>
              {showLoader && <Loader fill="#0B0F1C" />}
            </div>
          )}

          <PopperPopup
            showPopper={showPopper}
            onClosePopper={handleClosePopper}
            onOpenPopper={handleOpenPopper}
            options={popperOptions}
            trigger={<DotsVertical fill="#0B0F1C" />}
            popup={
              <div ref={modalRef} className={styles.memberItem__modal}>
                {!isMyMember && (
                  <div className={styles.memberItem__modalItem}>
                    <UserProfile stroke="#0B0F1C" /> View Profile
                  </div>
                )}

                {showEditButton && (
                  <div
                    className={clsx(
                      styles.memberItem__modalItem,
                      styles.margin
                    )}
                    onClick={() => navigate(`profile/${item.user.id}`)}
                  >
                    <Pen fill={"#0B0F1C"} /> Edit Profile
                  </div>
                )}

                {showDeleteButton && (
                  <div
                    className={clsx(
                      styles.memberItem__modalItem,
                      styles.margin
                    )}
                    onClick={() => deleteMember(item.id)}
                  >
                    <TrashAlt fill={"#0B0F1C"} />
                    {isInvitedMember
                      ? "Cancel invitation"
                      : isMyMember
                      ? "Leave organization"
                      : "Delete Member"}
                  </div>
                )}
              </div>
            }
          />
        </div>
      </div>
      <div className={styles.memberItem__underline} />
    </>
  );
};
