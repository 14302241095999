import { GridView, LeftArrow, Loader, TableView } from "~icons";
import ContentLoader from "react-content-loader";
import { DashboardLayout } from "~layouts";
import { useAdminFolder } from "./useAdminFolder";
import {
  Pagination,
  SearchInput,
  Table,
  TableLoader,
  ChecklistItemImport,
  PDFItemImport,
  PDFItemImportSquare,
  ChecklistItemImportSquare,
  FolderItem,
  GridWrapper,
  FolderItemSquare,
  PDFItemViewSquare,
  SearchBar,
} from "~components";

import styles from "./styles.module.scss";

export const AdminFolder = () => {
  const {
    items,
    folders,
    loading,
    paginationLoading,
    draftsCount,
    fetchedCount,
    searchValue,
    PAGE_LIMIT,
    viewType,
    folderTitle,
    goBack,
    setViewType,
    setSearchValue,
    handleNextPage,
    handlePrevPage,
  } = useAdminFolder();

  console.log(items, 'items')

  return (
    <DashboardLayout title="Library">
      <div className={styles.adminFolder}>
        <div className={styles.adminFolder__header}>
          <div onClick={goBack} className={styles.adminFolder__headerIcon}>
            <LeftArrow fill="#979DB1" />
          </div>

          {!!folderTitle ? (
            folderTitle
          ) : (
            <ContentLoader
              speed={1}
              backgroundColor="#DBDEE7"
              foregroundColor="#ebedf2"
              title=""
              height={32}
            >
              <rect x="0" y="0" rx="7" ry="7" width="300" height="32" />
            </ContentLoader>
          )}
        </div>
        <SearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          viewType={viewType}
          setViewType={setViewType}
        />

        {loading && <TableLoader />}

        {!loading && viewType === "table" && (
          <Table
            folders={folders}
            loading={paginationLoading}
            handleLoad={handleNextPage}
            // header={[
            //   { header: "Name", className: styles.adminFolder__name },
            //   // {
            //   //   header: "Last modified",
            //   //   className: styles.adminFolder__date,
            //   // },
            //   // { header: "Action", className: styles.adminFolder__action },
            //   // { header: "Type", className: styles.adminFolder__type },
            //   // { header: "Status", className: styles.adminFolder__status },
            // ]}
            header={[
              { header: "Name", className: styles.adminFolder__name },
              {
                header: "Last modified",
                className: styles.adminFolder__date,
              },
              { header: "", className: styles.adminFolder__author },
            ]}
            renderFolderItem={(folder, index) => {
              return (
                <FolderItem
                  key={`${folder.id} - ${index} - folder`}
                  folder={folder}
                  index={index}
                  // handleView
                  hideActions
                  handleImport
                />
              );
            }}
            data={items}
            renderTableItem={(item, index) => {
              return item.type === "Pdf" ? (
                <PDFItemImport pdf={item} index={index} />
              ) : (
                <ChecklistItemImport checklist={item} />
              );
            }}
          />
        )}

        {!loading && viewType === "grid" && (
          <GridWrapper
            folders={folders}
            data={items}
            loading={paginationLoading}
            handleLoad={handleNextPage}
            renderFolderItem={(folder, index) => (
              <FolderItemSquare
                // handleView
                handleImport
                hideActions
                folder={folder}
                index={index}
              />
            )}
            renderItem={(item, index) => {
              return item.type === "Pdf" ? (
                <PDFItemImportSquare
                  pdf={item}
                  index={index}
                  key={`${item} - ${index}`}
                />
              ) : (
                <ChecklistItemImportSquare
                  checklist={item}
                  key={`${item} - ${index}`}
                />
              );
            }}
          />
        )}

        {paginationLoading && (
          <div className={styles.paginationLoader}>
            <Loader fill="#616A85" />
          </div>
        )}

        <div className={styles.pagination}>
          <div className={styles.pagination__left}>
            Showing{" "}
            <span>
              {fetchedCount + PAGE_LIMIT > draftsCount
                ? draftsCount
                : !!draftsCount && !!(fetchedCount + PAGE_LIMIT)
                ? fetchedCount + PAGE_LIMIT
                : 0}
            </span>{" "}
            of <span>{draftsCount}</span> Results
          </div>
        </div>

        {/* <Pagination
          allResults={draftsCount}
          fetchedResult={fetchedCount + PAGE_LIMIT}
          limit={PAGE_LIMIT}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
        /> */}
      </div>
    </DashboardLayout>
  );
};
