import { useBuilder } from "../../../../context";
import { IItem } from "../../../../constants";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const useSelector = ({ item, itemIndex }: IProps) => {
  const { setList, activeItem, activeTabIndex } = useBuilder();

  const toggleMultipleSelection = () => {
    if (!activeItem) return;
    let data = { ...item.data };
    data.multiple = !item.data.multiple;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;

      return state;
    });
  };

  const onChangeSelection = (value: string, index: number) => {
    if (!activeItem) return;
    let data = { ...item.data };
    data.selections[index] = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;

      return state;
    });
  };

  const handleAddSelection = () => {
    if (!activeItem) return;
    let data = { ...item.data };
    data.selections.push("");
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;

      return state;
    });
  };

  const handleRemoveSelection = (index: number) => {
    if (!activeItem) return;
    let data = { ...item.data };
    data.selections.splice(index, 1);
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const disableRemoveSelections = item.data.selections.length === 2;

  return {
    toggleMultipleSelection,
    disableRemoveSelections,
    onChangeSelection,
    handleAddSelection,
    handleRemoveSelection,
  };
};
