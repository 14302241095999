import { useOrganizationsManagement } from "./useOrganizationsManagement";
import { CreateOrganization } from "~blocks";
import {
  Button,
  Modal,
  Pagination,
  SearchInput,
  Table,
  TableLoader,
} from "~components";
import { DashboardLayout } from "~layouts";
import { PlusSymbol } from "~icons";

import styles from "./styles.module.scss";
import { IOrganization } from "~models";
import { OrganizationItem } from "./OrganizationItem";
import { ActiveListsModule } from "../../../../navigations/Dashboard/LeftPanel/modules";

export const OrganizationManagement = () => {
  const {
    choosedOrganization,
    showOrganizationModal,
    handleOrganizationModalOpen,
    handleOrganizationModalClose,

    organizations,
    loadingOrganization,
    organizationsCount,
    handleNextPage,
    handlePrevPage,
    fetchedCount,
    loading,
    searchValue,
    setSearchValue,
    handleChangeRequestsField,
    updateOrganization,
  } = useOrganizationsManagement();
  return (
    <DashboardLayout
      title="All Organizations"
      rightItem={
        <div className={styles.organizationsManagement__inviteButton}>
          <PlusSymbol fill="#FFFFFF" />
          <p />
        </div>
      }
    >
      <div className={styles.organizationsManagement}>
        <div className={styles.organizationsManagement__search}>
          <SearchInput
            placeholder="Search for companies"
            value={searchValue}
            onChange={setSearchValue}
          />
        </div>
        {loading ? (
          <TableLoader />
        ) : (
          <Table
            header={[
              {
                header: "Organization",
                className: styles.organizationsManagement__organization,
              },

              {
                header: "Members",
                className: styles.organizationsManagement__members,
              },
              {
                header: "Date of creation",
                className: styles.organizationsManagement__date,
              },
              {
                header: "",
                className: styles.organizationsManagement__actions,
              },
            ]}
            data={organizations}
            renderTableItem={(item, index) => (
              <OrganizationItem
                key={`${item} - ${index}`}
                orgnanization={item}
                index={index}
              />
            )}
          />
        )}
        <Pagination
          allResults={organizationsCount}
          fetchedResult={fetchedCount + 10}
          limit={10}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
        />

        {showOrganizationModal && (
          <Modal
            containerClassName={styles.organizationsManagement__modal}
            modalClassName={styles.organizationsManagement__modalContent}
            onCloseModal={handleOrganizationModalClose}
            hideCross
          >
            <CreateOrganization
              showTopIcons
              onCrossClick={handleOrganizationModalClose}
              containerClassName={styles.organizationsManagement__modalBlock}
              onFinish={handleOrganizationModalClose}
            />
          </Modal>
        )}
      </div>
    </DashboardLayout>
  );
};
