import { FC } from "react";
import { Button, Counter, Input, PaymentInput } from "~components";
import styles from "./styles.module.scss";
import { SubscriptionType, usePayment } from "../../hooks";

import { CheckMark, Multiplication } from "~icons";
import { IOrganization } from "~models";
import clsx from "clsx";

interface IProps {
  goBack?: () => void;
  goNext: () => void;
  organization: IOrganization | null;
  organizationFromProps?: IOrganization;
  handleSuccessPayment?: () => void;
}

export const EnterPayment: FC<IProps> = ({
  goBack,
  goNext,
  organization,
  organizationFromProps,
  handleSuccessPayment,
}) => {
  const {
    minimumPackagePrice,
    usersCount,
    totalPrice,
    setUsersCount,
    yearlyPrice,
    monthlyPrice,
    adminsCount,
    setAdminsCount,
    isDisable,
    loading,
    handleSubmit,
    formRef,
    handleCheckoutClick,
    name,
    handleNameChange,
    onChangeUsersCount,
    selectedSubscription,
    setSelectedSubscription,
  } = usePayment({ goNext, organization, handleSuccessPayment });

  return (
    <div className={styles.paymentBlock}>
      <div className={styles.paymentBlock__title}>Organization</div>
      {/* <div className={styles.paymentBlock__subtitle}>
        Lorem ipsum
      </div> */}
      <div className={styles.paymentBlock__chooseSubscription}>
        <div
          className={clsx(
            styles.paymentBlock__top,
            selectedSubscription === SubscriptionType.MONTHLY &&
              styles.paymentBlock__topActive
          )}
          onClick={() => setSelectedSubscription(SubscriptionType.MONTHLY)}
        >
          <div className={styles.paymentBlock__topTitle}>
            Monthly subscription
          </div>
          <div className={styles.paymentBlock__subscriptionPrice}>
            ${monthlyPrice}/month
          </div>
          {/* <div className={styles.paymentBlock__feature}>
            Lorem ipsum dolor sit amet consectetur.
            <CheckMark
              height={28}
              width={28}
              fill={"#29B473"}
              stroke={"#29B473"}
            />
          </div>
          <div className={styles.paymentBlock__feature}>
            Lorem ipsum dolor sit amet consectetur.
            <CheckMark
              height={28}
              width={28}
              fill={"#29B473"}
              stroke={"#29B473"}
            />
          </div>
          <div className={styles.paymentBlock__feature}>
            Lorem ipsum dolor sit amet consectetur.
            <CheckMark
              height={28}
              width={28}
              fill={"#29B473"}
              stroke={"#29B473"}
            />
          </div>
          <div className={styles.paymentBlock__feature}>
            Lorem ipsum dolor sit amet consectetur.
            <CheckMark
              height={28}
              width={28}
              fill={"#29B473"}
              stroke={"#29B473"}
            />
          </div> */}
          {/* <div className={styles.paymentBlock__prices}>
            <div className={styles.paymentBlock__pricesLeft}>1 user</div>
            <div className={styles.paymentBlock__pricesRight}>
              ${minimumPackagePrice} USD
            </div>
          </div>

          <div className={styles.paymentBlock__underline} />
          <div className={styles.paymentBlock__topRow}>
            Additional users
            <div className={styles.paymentBlock__topRowRight}>
              <Counter value={usersCount} onChange={onChangeUsersCount} />
              <p>${monthlyPrice} USD</p>
            </div>
          </div>
          <div className={styles.paymentBlock__underline} />
          <div className={styles.paymentBlock__topTotal}>
            <p>Total due</p>
            <p>${totalPrice} USD</p>
          </div> */}
        </div>
        <div
          className={clsx(
            styles.paymentBlock__top,
            selectedSubscription === SubscriptionType.YEARLY &&
              styles.paymentBlock__topActive
          )}
          onClick={() => setSelectedSubscription(SubscriptionType.YEARLY)}
        >
          <div className={styles.paymentBlock__topTitle}>
            Yearly subscription
          </div>
          <div className={styles.paymentBlock__subscriptionPrice}>
            ${yearlyPrice}/year
          </div>
          {/* <div className={styles.paymentBlock__feature}>
            Lorem ipsum dolor sit amet consectetur.
            <CheckMark
              height={28}
              width={28}
              fill={"#29B473"}
              stroke={"#29B473"}
            />
          </div>
          <div className={styles.paymentBlock__feature}>
            Lorem ipsum dolor sit amet consectetur.
            <CheckMark
              height={28}
              width={28}
              fill={"#29B473"}
              stroke={"#29B473"}
            />
          </div>
          <div className={styles.paymentBlock__feature}>
            Lorem ipsum dolor sit amet consectetur.
            <CheckMark
              height={28}
              width={28}
              fill={"#29B473"}
              stroke={"#29B473"}
            />
          </div>
          <div className={styles.paymentBlock__feature}>
            Lorem ipsum dolor sit amet consectetur.
            <CheckMark
              height={28}
              width={28}
              fill={"#29B473"}
              stroke={"#29B473"}
            />
          </div> */}
        </div>
      </div>

      <div className={styles.paymentBlock__title}>
        Enter your payment details
      </div>
      <div className={styles.paymentBlock__subtitle}>
        If paying by purchase order, email <span>sales@resusc.io</span> for more
        information.
      </div>

      <div className={styles.paymentBlock__topRow}>
        Number of users
        <div className={styles.paymentBlock__topRowRight}>
          <Counter value={usersCount} onChange={onChangeUsersCount} />
          <p>
            $
            {selectedSubscription === SubscriptionType.MONTHLY
              ? monthlyPrice
              : yearlyPrice}{" "}
            USD
          </p>
        </div>
      </div>
      <Input
        placeholder="Full Name"
        label="Full Name"
        value={name}
        onChange={handleNameChange}
      />

      <div className={styles.paymentBlock__paymentInput}>
        <PaymentInput formRef={formRef} handleSubmit={handleSubmit} />
      </div>

      <div className={styles.paymentBlock__underlineSolid} />

      <div className={styles.paymentBlock__buttons}>
        {!organizationFromProps ? (
          <Button
            text="Back"
            backgroundType={"white"}
            showShadow
            onClick={goBack}
          />
        ) : (
          <div />
        )}

        <Button
          loading={loading}
          disable={isDisable}
          onClick={handleCheckoutClick}
          text="Check Out"
          backgroundType={"green"}
        />
      </div>
    </div>
  );
};
