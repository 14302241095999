import { gql } from "@apollo/client";

// export const INVATIONS_SUBSCRIPTION = gql`
//   subscription newInvitation($userId: Float!) {
//     newInvitation(userId: $userId) {
//       id
//       userId
//       organization {
//         id
//         picture
//         name
//         membersCount
//       }
//       role
//       createdAt
//       organizationId
//     }
//   }
// `;

export const INVITATION_SUBSCRIPTION = gql`
  subscription newInvitation($userId: Float!) {
    newInvitation(userId: $userId) {
      id
      created
      canceled
    }
  }
`;
