import { Navigate } from "react-router-dom";
import { Outlet, useLocation } from "react-router";
import { useAuth } from "~hooks";
export const PublicRoutes = () => {
  const { user } = useAuth();
  const location = useLocation();

  return !!user ? (
    !!user.onboarding ? (
      <Navigate to="/dashboard" state={{ from: location }} replace />
    ) : (
      <Navigate to="/getStarted" state={{ from: location }} replace />
    )
  ) : (
    <Outlet />
  );
};
