import { gql } from "@apollo/client";

export const REFRESH_TOKENS = gql`
  query refreshTokens {
    refreshTokens {
      access_token
      refresh_token
    }
  }
`;
