import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FormEvent, useRef, useState } from "react";
import {
  GET_CHOOSED_ORGANIZATIOM,
  alertVar,
  choosedOrganizationVar,
} from "~localCache";
import { IOrganization, IUserInvitation } from "~models";
import { ACCEPT_ORGANIZATION_INVITATION, ADD_CARD } from "~mutations";
import { GET_ALL_ORGANIZATIONS, GET_MY_ORGANIZATIONS } from "~queries";

export const useAcceptOwnershipModal = (
  invitation: IUserInvitation,
  removeLocalFile: () => void,
  handleCloseModal: () => void
) => {
  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const formRef = useRef<HTMLFormElement>(null);
  const elements = useElements();
  const stripe = useStripe();
  const cardElement = elements?.getElement(CardElement);

  const [addCard] = useMutation(ADD_CARD);
  const [isCardInputFilled, setIsCardInputFilled] = useState(false);
  const [mutationAcceptInvitation] = useMutation(
    ACCEPT_ORGANIZATION_INVITATION
  );
  const [queryGetMyOrganization] = useLazyQuery(GET_MY_ORGANIZATIONS, {
    fetchPolicy: "network-only",
  });

  cardElement?.on("change", function (event) {
    setIsCardInputFilled(false);
    if (event.complete) {
      setIsCardInputFilled(true);
    }
  });

  const [name, setName] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const isDisable = !isCardInputFilled || name.length < 2;

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements || !cardElement) {
      return;
    }
    setLoading(true);
    const stripeResponse = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    const { error, paymentMethod } = stripeResponse;
    if (error || !paymentMethod) {
      return;
    }

    const paymentMethodId = paymentMethod.id;

    addCard({
      variables: {
        input: {
          organizationId: invitation.organizationId,
          paymentMethod: paymentMethodId,
        },
      },
    })
      .then(async (resp) => {
        await mutationAcceptInvitation({
          variables: { id: invitation.id },
        }).then(async (resp) => {
          await queryGetMyOrganization({
            variables: {},
            fetchPolicy: "network-only",
          });
          !choosedOrganization &&
            choosedOrganizationVar(resp.data.acceptOrganizationInvitations);
          setLoading(false);
          removeLocalFile();
          handleCloseModal();
        });
      })
      .catch((err) => {
        setLoading(false);
        if (!!err?.graphQLErrors[0]?.message) {
          alertVar({ type: "red", text: err.graphQLErrors[0].message });
        }
      });
  };

  const handleAcceptButton = () => formRef.current?.requestSubmit();

  return {
    formRef,
    handleSubmit,
    name,
    setName,
    handleAcceptButton,
    loading,
    isDisable,
  };
};
