import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { OrganizationMember, OrganizationMemberRole } from "~models";
import { GET_ALL_ORGANIZATIONS_MEMBERS_COUNT } from "~queries";
import { Avatar, Button, Dropdown } from "~components";
import { GET_CHOOSED_ORGANIZATIOM } from "~localCache";
import { useAuth, useDidMount } from "~hooks";
import { useRef, useState } from "react";
import dayjs from "dayjs";
import {
  ACCEPT_ORGANIZATION_REQUEST,
  CANCEL_ORGANIZATION_REGUEST,
} from "~mutations";

import styles from "./styles.module.scss";

interface IProps {
  dataLength: number;
  item: OrganizationMember;
  index: number;
  scrollBlockRef?: React.RefObject<HTMLDivElement>;
  removeItemFromData: (index: number, id: number) => void;
}

export const RequestItem = ({
  dataLength,
  index,
  item,
  scrollBlockRef,
  removeItemFromData,
}: IProps) => {
  const { user } = useAuth();
  const [acceptRequest] = useMutation(ACCEPT_ORGANIZATION_REQUEST);
  const [declineRequest] = useMutation(CANCEL_ORGANIZATION_REGUEST);

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [updateMembersCount] = useLazyQuery(
    GET_ALL_ORGANIZATIONS_MEMBERS_COUNT
  );

  const [rotate, setRotate] = useState(false);
  const [showAcceptLoader, setShowAcceptLoader] = useState(false);
  const [showDeclineLoader, setShowDeclineLoader] = useState(false);

  const [choosedRole, setChoosedRole] = useState<
    OrganizationMemberRole.User | OrganizationMemberRole.Admin
  >(OrganizationMemberRole.User);

  const handleAcceptButton = () => {
    setShowAcceptLoader(true);
    acceptRequest({
      variables: { input: { role: choosedRole, id: item.id } },
    }).then(async () => {
      await updateMembersCount({
        variables: { input: { organizationId: choosedOrganization.id } },
        fetchPolicy: "network-only",
      });

      removeItemFromData(index, item.id);
      setShowAcceptLoader(false);
    });
  };

  const handleDeclineButton = () => {
    setShowDeclineLoader(true);
    declineRequest({
      variables: {
        input: {
          requestId: item.id,
          organizationId: item.organizationId,
          userId: user?.id,
        },
      },
    }).then(() => {
      removeItemFromData(index, item.id);
      setShowDeclineLoader(false);
    });
  };

  const testRef = useRef<HTMLDivElement>(null);

  const isTwoLasts = dataLength - 1 === index || dataLength - 2 === index;

  const handleChangeRole = (
    newRole: OrganizationMemberRole.Admin | OrganizationMemberRole.User
  ) => {
    setChoosedRole(newRole);
  };

  const checkIsNeedRotate = () => {
    const scrollBlockHeight = scrollBlockRef?.current?.clientHeight;
    const itemOffsetTop = testRef?.current?.offsetTop;

    const difference =
      typeof itemOffsetTop === "number" && typeof scrollBlockHeight === "number"
        ? scrollBlockHeight - itemOffsetTop
        : 1000;

    difference < 115 && isTwoLasts && setRotate(true);
  };

  useDidMount(checkIsNeedRotate);

  return (
    <>
      <div ref={testRef} className={styles.requestedItem}>
        <div className={styles.requestedItem__member}>
          {/* <div className={styles.requestedItem__memberAvatar}>
            {!!item.user.avatar && <img src={item.user.avatar} />}
          </div> */}
          <Avatar
            uri={item.user.avatar}
            item={item.user}
            type="user"
            containerStyles={styles.requestedItem__memberAvatar}
          />
          <div className={styles.requestedItem__memberInitials}>
            <p>
              {item.user.firstName} {item.user.lastName}
            </p>
            <p>{item.user.email}</p>
          </div>
        </div>
        <div className={styles.requestedItem__date}>
          {dayjs(item.createdAt).format("MMMM D, YYYY")}
        </div>
        <div className={styles.requestedItem__role}>
          {item.role === OrganizationMemberRole.Owner ? (
            <p>Owner</p>
          ) : (
            <div className={styles.requestedItem__roleLeft}>
              <div className={styles.requestedItem__roleDropdown}>
                <Dropdown
                  value={choosedRole}
                  onChange={(index, value) =>
                    handleChangeRole(
                      value as
                        | OrganizationMemberRole.User
                        | OrganizationMemberRole.Admin
                    )
                  }
                  rotate={rotate}
                  placeholder="Role"
                  data={[
                    OrganizationMemberRole.Admin,
                    OrganizationMemberRole.User,
                  ]}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.requestedItem__actions}>
          <Button
            onClick={handleAcceptButton}
            loading={showAcceptLoader}
            buttonClassName={styles.requestedItem__actionsButton}
            text="Accept"
            backgroundType={"green"}
          />

          <Button
            onClick={handleDeclineButton}
            loading={showDeclineLoader}
            buttonClassName={styles.requestedItem__actionsButton}
            text="Decline"
            backgroundColor="#EF2F32"
            textColor="#FFFFFF"
          />
        </div>
      </div>
      <div className={styles.requestedItem__underline} />
    </>
  );
};
