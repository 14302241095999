import { Button, Input, Modal, PaymentInput } from "~components";
import styles from "./styles.module.scss";
import { useAcceptOwnershipModal } from "./useAcceptOwnershipModal";
import { IUserInvitation } from "~models";

interface IProps {
  handleCloseModal: () => void;
  removeLocalFile: () => void;
  invitation: IUserInvitation;
}

export const AcceptOwnershipModal = ({
  handleCloseModal,
  removeLocalFile,
  invitation,
}: IProps) => {
  const {
    formRef,
    handleSubmit,
    name,
    setName,
    loading,
    handleAcceptButton,
    isDisable,
  } = useAcceptOwnershipModal(invitation, removeLocalFile, handleCloseModal);
  return (
    <Modal onCloseModal={handleCloseModal}>
      <div className={styles.acceptOwnership}>
        <p className={styles.acceptOwnership__title}>Accept Ownership</p>
        <p className={styles.acceptOwnership__subtitle}>
          Please enter your credit card details to accept ownership of this
          organization
        </p>
        <div className={styles.acceptOwnership__row}>
          <div className={styles.acceptOwnership__image}>
            {!!invitation.organization.picture && (
              <img src={invitation.organization.picture} />
            )}
          </div>
          <p className={styles.acceptOwnership__name}>
            {invitation.organization.name}
          </p>
        </div>
        <div className={styles.acceptOwnership__input}>
          <Input
            value={name}
            onChange={setName}
            label="Full Name"
            placeholder="Enter your full name"
          />
        </div>

        <div className={styles.acceptOwnership__input}>
          <PaymentInput formRef={formRef} handleSubmit={handleSubmit} />
        </div>

        <div className={styles.acceptOwnership__buttons}>
          <Button
            onClick={handleCloseModal}
            backgroundType={"white"}
            text={"Cancel"}
          />
          <Button
            disable={isDisable}
            loading={loading}
            onClick={handleAcceptButton}
            backgroundType={"green"}
            text={"Accept Ownership"}
          />
        </div>
      </div>
    </Modal>
  );
};
