import { regex } from "~constants";
import { useBuilder } from "../../../../../../context";
import { IItem } from "~models";

interface IProps {
  item: IItem;
  itemIndex: number;
  assesment: IAssesment;
  assesmentIndex: number;
  assesmentsLength: number;
}

interface IAssesment {
  title: string;
  description: string;
  findings: { name: string; score: number }[];
}

export const useAssesmentItem = ({
  item,
  assesment,
  assesmentIndex,
  itemIndex,
  assesmentsLength,
}: IProps) => {
  const { setList, activeItem, activeTabIndex } = useBuilder();

  let data =
    typeof item.data === "string"
      ? structuredClone(JSON.parse(item.data))
      : structuredClone(item.data);

  const blockDeletingFindings = assesment.findings.length <= 2;
  const blockDeletingAssesment = assesmentsLength === 1;

  const handleTitleChange = (value: string) => {
    if (!activeItem) return;
    data.assessments[assesmentIndex].title = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleDescriptionChange = (value: string) => {
    if (!activeItem) return;
    data.assessments[assesmentIndex].description = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleFindingNameChange = (value: string, findingIndex: number) => {
    if (!activeItem) return;
    data.assessments[assesmentIndex].findings[findingIndex].name = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleFindingScoreChange = (value: string, findingIndex: number) => {
    if (!activeItem) return;
    if (
      value !== "-" &&
      value !== "" &&
      !regex.negativeOrPositivNumbers.test(value)
    )
      return;
    data.assessments[assesmentIndex].findings[findingIndex].score = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleAddFinding = () => {
    if (!activeItem) return;
    data.assessments[assesmentIndex].findings.push({ name: "", score: "0" });
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleDeleteFinding = (findingIndex: number) => {
    if (!activeItem) return;
    data.assessments[assesmentIndex].findings.splice(findingIndex, 1);
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleDeleteAssesment = () => {
    if (!activeItem || blockDeletingAssesment) return;
    data.assessments.splice(assesmentIndex, 1);
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  return {
    blockDeletingFindings,
    blockDeletingAssesment,
    handleDeleteAssesment,
    handleTitleChange,
    handleDescriptionChange,
    handleFindingNameChange,
    handleFindingScoreChange,
    handleAddFinding,
    handleDeleteFinding,
  };
};
