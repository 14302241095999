import { OnboardingLayout } from "~layouts";
import { Loader } from "~icons";
import AuthCode from "react-auth-code-input";
import styles from "./styles.module.scss";
import { useConfirmation } from "./hooks/useConfirmation";
import clsx from "clsx";

export const CodeConfirmation = () => {
  const {
    onChangeCode,
    loading,
    showError,
    location,
    resendCode,
    inputRef,
    seconds,
  } = useConfirmation();

  return (
    <OnboardingLayout>
      <div className={styles.codeConfirmation}>
        <div className={styles.codeConfirmation__header}>
          Check your email for a code
        </div>

        <div className={styles.codeConfirmation__subtitle}>
          Please enter a 6-digit code we’ve sent to{" "}
          <span>{location.state.email}.</span>
          This code expires in 15 minutes
        </div>

        <div
          className={clsx(styles.codeConfirmation__inputs, {
            [styles.error]: showError,
          })}
        >
          <AuthCode
            ref={inputRef}
            disabled={loading}
            containerClassName={styles.codeConfirmation__inputsContainer}
            onChange={onChangeCode}
          />

          {showError && (
            <div className={styles.codeConfirmation__error}>Incorrect code</div>
          )}
        </div>
        <div className={styles.codeConfirmation__loader}>
          {loading && (
            <>
              <Loader fill={"#616A85"} />
              Checking your code
            </>
          )}
        </div>

        <div className={styles.codeConfirmation__resend}>
          <div
            className={clsx(styles.codeConfirmation__resendButton)}
            onClick={resendCode}
          >
            {seconds > 0
              ? `Resend code in ${
                  seconds === 60
                    ? "01:00"
                    : seconds > 9
                    ? `00:${seconds}`
                    : `00:0${seconds}`
                }`
              : "Resend code"}
            {/* {codeWasResended ? "Code was resended" : "Resend code"} */}
          </div>
        </div>
        <div className={styles.codeConfirmation__spamText}>
          Can’t find the code? Check your spam folder
        </div>
      </div>
    </OnboardingLayout>
  );
};
