import { FC } from "react";
import { Switch } from "~components";
import styles from "./styles.module.scss";
import clsx from "clsx";

interface IProps {
  value: boolean;
  onClick: () => void;
  text: string;
  containerClassName?: string;
}

export const SwitchField: FC<IProps> = ({
  value,
  onClick,
  text,
  containerClassName,
}) => {
  return (
    <div className={clsx(styles.switchField, containerClassName)}>
      {text}

      <Switch value={value} onClick={onClick} />
    </div>
  );
};
