import { useQuery, useReactiveVar } from "@apollo/client";
import { useState } from "react";
import { GET_CHOOSED_ORGANIZATIOM, choosedOrganizationVar } from "~localCache";
import { IOrganization } from "~models";

interface IProps {
  orgnanization: IOrganization;
}

export const useOrganizationItem = ({ orgnanization }: IProps) => {
  const { data } = useQuery(GET_CHOOSED_ORGANIZATIOM);
  const [showPopper, setShowPopper] = useState(false);
  const choosedOrganization = useReactiveVar(choosedOrganizationVar);

  const handleOpenPopper = () => setShowPopper(true);
  const handleClosePopper = () => setShowPopper(false);

  const handleViewButton = () => {
    choosedOrganizationVar({ ...choosedOrganization, ...orgnanization });
  };

  return { showPopper, handleOpenPopper, handleClosePopper, handleViewButton };
};
