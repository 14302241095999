import styles from "./styles.module.scss";

import { IItem } from "~models";
import { useDropdownData } from "./useDropdownData";
import { Dropdown } from "~components";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins/align.min.js";

import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/js/plugins/lists.min.js";

import "froala-editor/js/plugins/table.min.js";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const DropdownData = ({ item, isDisable, progressItem }: IProps) => {
  const { titles, handleChoose } = useDropdownData({
    item,
    isDisable,
    progressItem,
  });
  return (
    <div className={styles.dropdownData}>
      <div
        className={styles.dropdownData__selector}
        onClick={(e) => e.stopPropagation()}
      >
        <Dropdown
          isDisable={isDisable}
          label="Choose"
          data={titles}
          placeholder="Choose"
          value={progressItem?.choosedItem?.title}
          onChange={(index) => handleChoose(index)}
        />
      </div>

      {!!progressItem?.choosedItem && (
        <FroalaEditor
          model={progressItem?.choosedItem.model}
          config={{
            key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
            htmlUntouched: true,
            toolbarInline: true,
            toolbarVisibleWithoutSelection: true,
            isDisable: true,
            events: {
              initialized() {
                const editor: any = this;
                editor.edit.off();
              },
            },
          }}
        />
      )}
    </div>
  );
};
