import React from "react";
import { Bell, Play, Stopwatch as StopwatchIcon } from "~icons";

import styles from "./styles.module.scss";
import { Button } from "~components";
import { IItem } from "../../../../../constants";

interface IProps {
  item: IItem;
  isDisable: boolean;
}

export const Stopwatch = ({ item, isDisable }: IProps) => {
  return (
    <div className={styles.stopwatch}>
      <div className={styles.stopwatch__content}>
        <div className={styles.stopwatch__row}>
          <div className={styles.stopwatch__contentIcon}>
            <StopwatchIcon />
          </div>
          <p>00:00:00</p>
        </div>
        <div className={styles.stopwatch__contentRight}>
          <div className={styles.stopwatch__contentButton}>
            <Button
              disable={isDisable}
              icon={<Play />}
              backgroundType={"green"}
              text=""
            />
          </div>
          <div className={styles.stopwatch__contentReset}>
            <Button
              disable={isDisable}
              backgroundType={"white"}
              text="Reset"
              showShadow
            />
          </div>
          <div className={styles.stopwatch__contentButton}>
            <Button
              disable={isDisable}
              icon={<Bell />}
              backgroundType={"white"}
              text=""
              showShadow
            />
          </div>
        </div>
      </div>
    </div>
  );
};
