import { gql } from "@apollo/client";

export const CREATE_USER_OR_ADMIN = gql`
  mutation createUserBySuperAdmin($input: CreateUserBySuperAdminInput!) {
    createUserBySuperAdmin(input: $input) {
      id
      firstName
      lastName
      email
    }
  }
`;
