import { gql } from "@apollo/client";

export const GET_NOTEPAD = gql`
  query notepad($chatId: Int!) {
    notepad(chatId: $chatId) {
      id
      data
      rewrited
      assessments {
        id
        type
        data
        explanation
        source
      }
      differentials {
        id
        type
        data
        explanation
        source
      }
      diagnosis
      treatments {
        id
        type
        data
        explanation
        source
      }
      createdAt
      updatedAt
    }
  }
`;
