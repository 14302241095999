import { OnboardingLayout } from "~layouts";
import { Button, Input } from "~components";
import { LeftArrow } from "~icons";

import styles from "./styles.module.scss";
import { useResetPassword } from "./hooks/useResetPassword";
import { useNavigate } from "react-router-dom";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { data, onChangeData, loading, disableButton, onSubmit } =
    useResetPassword();

  const navigateToSignIn = () => navigate("/signIn");

  return (
    <OnboardingLayout>
      <div className={styles.resetPassword}>
        <div className={styles.resetPassword__header}>Reset your password</div>
        <div className={styles.resetPassword__subtitle}>
          We’ll email you instructions to reset your password.
        </div>

        <Input
          label="Email address"
          value={data.email}
          onChange={(value) => onChangeData(value, "email")}
          placeholder={"Enter your email address..."}
        />

        <div className={styles.resetPassword__button}>
          <Button
            loading={loading}
            disable={disableButton}
            onClick={onSubmit}
            text="Reset Password"
            backgroundType={"green"}
          />
        </div>

        <div className={styles.resetPassword__return}>
          <div
            className={styles.resetPassword__returnButton}
            onClick={navigateToSignIn}
          >
            <LeftArrow fill={"#29B473"} />
            Back to Login
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
};
