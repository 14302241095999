import { FC, useRef, useState } from "react";
import { clsx } from "clsx";
import styles from "./styles.module.scss";
import {
  Stopwatch,
  Timer,
  Metronome,
  TextOnly,
  UpDownArrow,
  Calculator,
  MedCheck,
  InputText,
  Counter,
  Dropdown,
  TimeMarker,
  CheckboxType,
  Algorithm,
  BodyScan,
  Chart,
} from "~icons";
import { useOutsideClick } from "~hooks";
import { ChecklistItemType } from "~models";

const typeData = [
  {
    icon: <CheckboxType />,
    type: ChecklistItemType.Checkbox,
    name: "Text only",
  },
  { icon: <Stopwatch />, type: ChecklistItemType.Stopwatch, name: "Stopwatch" },
  { icon: <Timer />, type: ChecklistItemType.Timer, name: "Timer" },
  { icon: <Metronome />, type: ChecklistItemType.Metronome, name: "Metronome" },
  {
    icon: <TextOnly />,
    type: ChecklistItemType.TextBox,
    name: "Text/Media Box",
  },
  {
    icon: <Calculator />,
    type: ChecklistItemType.Calculator,
    name: "Calculator",
  },
  {
    icon: <MedCheck />,
    type: ChecklistItemType.MedCheck,
    name: "MedCheck",
  },
  {
    icon: <InputText />,
    type: ChecklistItemType.InputText,
    name: "Input Text",
  },
  {
    icon: <Counter />,
    type: ChecklistItemType.Counter,
    name: "Counter",
  },
  {
    icon: <Dropdown />,
    type: ChecklistItemType.DropdownSelector,
    name: "Dropdown Selector",
  },
  {
    icon: <TimeMarker />,
    type: ChecklistItemType.TimeMarker,
    name: "Time Marker",
  },
  {
    icon: <Algorithm />,
    type: ChecklistItemType.Algorithm,
    name: "Algorithm",
  },
  {
    icon: <BodyScan />,
    type: ChecklistItemType.BurnedAreas,
    name: "Burn Chart",
  },
  {
    icon: <Chart />,
    type: ChecklistItemType.TableData,
    name: "Dropdown Data",
  },
];

interface IProps {
  label?: string;
  type: null | ChecklistItemType;
  onChange?: (value: ChecklistItemType) => void;
  isDisable?: boolean;
}
export const TypeSelector: FC<IProps> = ({
  label,
  onChange,
  type,
  isDisable,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDropdownChange = (value: ChecklistItemType) => {
    !!onChange && onChange(value);
    setIsOpen(false);
  };

  const toggleDropdownState = () => !isDisable && setIsOpen(!isOpen);

  const handleDropdownClose = () => {
    if (isOpen && !isDisable) {
      setIsOpen(false);
    }
  };

  const item = !!type
    ? typeData.find((item) => item.type === type)
    : typeData[0];

  useOutsideClick(dropdownRef, handleDropdownClose);

  return (
    <div ref={dropdownRef} className={styles.dropdownContainer}>
      {!!label && (
        <div className={styles.dropdownContainer__label}>{label}</div>
      )}
      <div
        onClick={toggleDropdownState}
        className={clsx(styles.dropdownContainer__field, {
          [styles.dropdownContainer__fieldPlaceholder]: !type,
          [styles.active]: isOpen,
        })}
      >
        {!!type ? (
          <div className={styles.dropdownContainer__fieldValue}>
            {item?.icon}
            {item?.name}
          </div>
        ) : (
          <>Choose type</>
        )}
        <div
          className={clsx(styles.dropdownContainer__fieldIcon, {
            [styles.rotate]: isOpen,
          })}
        >
          <UpDownArrow fill="#0B0F1C" />
        </div>
      </div>

      <div
        className={clsx(styles.dropdownContainer__select, {
          [styles.close]: !isOpen,
        })}
      >
        <div className={styles.dropdownContainer__selectBlock}>
          {typeData.map((item, index) => (
            <div
              onClick={() => handleDropdownChange(item.type)}
              key={`${item.type} - ${index}`}
              className={styles.dropdownContainer__item}
            >
              {item.icon}
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
