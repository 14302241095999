import { useRef, useState } from "react";
import { useChecklist } from "../../context";
import { PopupActions } from "reactjs-popup/dist/types";
import { useOutsideClick } from "~hooks";
import { useQuery } from "@apollo/client";
import { GET_ALL_ACTIVE_PROGRESSES } from "~queries";
import { GET_CHOOSED_ORGANIZATIOM } from "~localCache";
import { useLocation, useNavigate } from "react-router-dom";
import { IActiveChecklist } from "~models";

export const useFooter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const popupRef = useRef<PopupActions>(null);
  const popupContentRef = useRef<HTMLDivElement>(null);
  const popupTriggerRef = useRef<HTMLDivElement>(null);

  const { showPdfTab, setShowPdfTab } = useChecklist();

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);
  const isPreview =
    new URLSearchParams(location.search).get("preview") === "true";

  const { data } = useQuery(GET_ALL_ACTIVE_PROGRESSES, {
    variables: { organizationId: choosedOrganization?.id },
    skip: !choosedOrganization?.id,
  });

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    if (!showPopup) {
      popupRef.current?.open();
      setShowPopup(true);
    } else {
      popupRef.current?.close();
      setShowPopup(false);
    }
  };

  const handleShopPopup = () => {
    if (!showPopup) {
      popupRef.current?.open();
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    if (showPopup) {
      popupRef.current?.close();
      setShowPopup(false);
    }
  };

  const { list, progress, activeTabIndex, activeTabId, setActiveTabId } =
    useChecklist();
  const handleTabButton = (id: number) => {
    showPdfTab && setShowPdfTab(false);
    setActiveTabId(id);
  };

  const handleItemClick = (checklistId: number, progressId: number) => {
    togglePopup();
    navigate(`/checklist/${checklistId}?progressId=${progressId}`, {
      state: { from: "checklist" },
    });
  };

  const handleCreateButtonClick = () => {
    console.log("asdsd");
    navigate("/dashboard/home/active");
  };

  const handleShowPdfTab = () => setShowPdfTab(true);

  const countDublicates = (items: IActiveChecklist[]) => {
    const itemCounts: any = {};
    return items.map((item, index) => {
      const count = (itemCounts[item.checklist.title] || 0) + 1;
      itemCounts[item.checklist.title] = count;
  
      const newItem = count > 1 ? `${item.checklist.title} (${count})` : item.checklist.title;
      return { ...item, checklist: { ...item.checklist, title: newItem } };
    });
  };

  useOutsideClick(
    popupContentRef,
    handleClosePopup,
    "mousedown",
    popupTriggerRef
  );

  return {
    allProggress: !data?.getAllMyActiveProgresses
      ? []
      : data.getAllMyActiveProgresses,
    progress,
    list,
    activeTabIndex,
    popupRef,
    popupContentRef,
    popupTriggerRef,
    isPreview,
    showPdfTab,

    handleTabButton,
    togglePopup,
    handleShopPopup,
    handleItemClick,
    handleShowPdfTab,
    handleCreateButtonClick,
    countDublicates
  };
};
