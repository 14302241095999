import { useDeleteUserModal } from "./useDeleteUserModal";
import { Modal, Button, Input } from "~components";
import { Disclaimer, Loader } from "~icons";

import styles from "./styles.module.scss";

interface IProps {
  onCloseModal: () => void;
  handleSuccessResult: () => void;
  userId?: number;
}

export const DeleteUserModal = ({
  onCloseModal,
  handleSuccessResult,
  userId,
}: IProps) => {
  const {
    step,
    data,
    mainLoading,
    isMyProfile,
    organizationsData,
    showDeletingloader,
    isDisable,
    handleNextStep,
    handlePrevStep,
    onChangeEmail,
    onSubmit,
    handleDeleteAccount,
  } = useDeleteUserModal({
    handleSuccessResult,
    userId,
  });

  return (
    <Modal
      containerClassName={styles.deleteUserModal__container}
      modalClassName={styles.deleteUserModal__modal}
      onCloseModal={onCloseModal}
      disableMediaStyles
    >
      <div className={styles.deleteUserModal}>
        {mainLoading ? (
          <div className={styles.deleteUserModal__loading}>
            <Loader fill="#EF2F32" />
          </div>
        ) : organizationsData.getUserOrganizations.length > 0 ? (
          <>
            {step === 1 && (
              <div className={styles.deleteUserModal__warning}>
                <Disclaimer />
                <p className={styles.deleteUserModal__title}>Disclaimer</p>

                <p className={styles.deleteUserModal__subtitle}>
                  {isMyProfile
                    ? `Before you delete your account, assign a new owner to your
                  organization(s)`
                    : `Before you delete this account, assign a new owner to this user's
                  organization(s)`}
                </p>

                <div className={styles.deleteUserModal__warningButtons}>
                  <Button
                    onClick={onCloseModal}
                    text="Cancel"
                    backgroundColor="#EF2F32"
                    textColor="#FFFFFF"
                  />

                  <Button
                    onClick={handleNextStep}
                    text="Select a new owner"
                    backgroundType={"green"}
                  />
                </div>
              </div>
            )}

            {step === 2 && (
              <div className={styles.deleteUserModal__transferring}>
                <p className={styles.deleteUserModal__title}>
                  Enter the new owner's email
                </p>

                <p className={styles.deleteUserModal__subtitle}>
                  Enter your email address so we can send him an invitation
                </p>

                {data?.map((item, index) => (
                  <div
                    key={`${item.organization.id} - ${index}`}
                    className={styles.deleteUserModal__row}
                  >
                    <div className={styles.deleteUserModal__rowName}>
                      <Input
                        disable
                        label="Organization"
                        value={item.organization.name}
                        placeholder={"Organization name"}
                      />
                    </div>

                    <p className={styles.deleteUserModal__rowTitle}>
                      {item.organization.name}
                    </p>
                    <div className={styles.deleteUserModal__rowEmail}>
                      <Input
                        labelClassName={styles.label}
                        label="Email"
                        value={item.email}
                        placeholder={"Enter owner email"}
                        onChange={(value) => onChangeEmail(index, value)}
                      />
                    </div>
                  </div>
                ))}

                <div className={styles.deleteUserModal__transferringButtons}>
                  <Button
                    onClick={onCloseModal}
                    text="Cancel"
                    showShadow
                    backgroundType={"white"}
                  />

                  <Button
                    loading={showDeletingloader}
                    disable={isDisable()}
                    onClick={handleNextStep}
                    text={"Next"}
                    backgroundType={"green"}
                  />
                </div>
              </div>
            )}

            {step === 3 && (
              <>
                <div className={styles.deleteUserModal__warning}>
                  <Disclaimer />
                  <p className={styles.deleteUserModal__title}>Disclaimer</p>

                  <p className={styles.deleteUserModal__subtitle}>
                    Deleting the account cannot be undone and they will lose all
                    information associated with their account
                  </p>

                  <div className={styles.deleteUserModal__warningButtons}>
                    <Button
                      onClick={handlePrevStep}
                      text="Back"
                      backgroundColor={"white"}
                      showShadow
                    />

                    <Button
                      loading={showDeletingloader}
                      onClick={onSubmit}
                      text="Delete Account"
                      backgroundColor="#EF2F32"
                      textColor="#FFFFFF"
                    />
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className={styles.deleteUserModal__deleting}>
            <p className={styles.deleteUserModal__deletingTitle}>
              {isMyProfile
                ? `Are you sure you want to delete your account?`
                : `Are you sure you want to delete this account?`}
            </p>

            <p className={styles.deleteUserModal__deletingSubtitle}>
              {isMyProfile
                ? `Your account will be permanently deleted`
                : `This account will be permanently deleted`}
            </p>

            <div className={styles.deleteUserModal__deletingButtons}>
              <Button
                onClick={onCloseModal}
                text="Cancel"
                backgroundColor="#979DB1"
                textColor="#FFFFFF"
              />
              <Button
                loading={showDeletingloader}
                onClick={handleDeleteAccount}
                text="Delete"
                backgroundColor="#EF2F32"
                textColor="#FFFFFF"
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
