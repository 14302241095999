import { FC } from "react";
import { Button, Input, Modal, TextArea } from "~components";
import styles from "./styles.module.scss";

interface IProps {
  onCloseModal: () => void;
}

export const ContactUsModal: FC<IProps> = ({ onCloseModal }) => {
  return (
    <Modal onCloseModal={onCloseModal}>
      <div className={styles.contactUsModal}>
        <p className={styles.contactUsModal__title}>Contact Us</p>
        <p className={styles.contactUsModal__subtitle}>
          Please contact us using this form with any questions, concerns, or
          feedback. Alternatively, you can email us at{" "}
          <span>support@resusc.io</span> or contact us via call/text at (650)
          674-2069.
        </p>

        <Input label="Name" placeholder="Name" />
        <div className={styles.contactUsModal__input}>
          <Input label="Email" placeholder="Email" />
        </div>
        <TextArea label="Message" placeholder="Message" />

        <div className={styles.contactUsModal__buttons}>
          <Button
            text="Cancel"
            backgroundType={"white"}
            showShadow
            onClick={onCloseModal}
          />
          <Button text="Send" backgroundType={"green"} />
        </div>
      </div>
    </Modal>
  );
};
