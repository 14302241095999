import { useEffect, useState } from "react";
import i18n from "i18next";

const languages = [
  { code: "en", name: "English (US)" },
  { code: "uk", name: "Ukraine (UK)" },
];

export const useEditLanguage = () => {
  const [defaultLanguage, setDefaultLanguage] = useState(languages[0].code);
  const [languageFromLS, setLanguageFromLS] = useState<string | null>(null);

  const handleChangeLanguage = (index: number) => {
    setDefaultLanguage(languages[index].code);
  };

  const handleSaveLanguage = () => {
    localStorage.setItem("language", defaultLanguage);
    i18n.changeLanguage(defaultLanguage);
    setLanguageFromLS(defaultLanguage);
  };

  const handleCancelLanguage = () => {
    !!languageFromLS
      ? setDefaultLanguage(languageFromLS)
      : setDefaultLanguage(languages[0].code);
  };

  const choosedLanguageValue = languages.find(
    (item) => item.code === defaultLanguage
  )?.name;

  useEffect(() => {
    let choosedLanguage = localStorage.getItem("language");
    setLanguageFromLS(!!choosedLanguage ? choosedLanguage : languages[0].code);
    setDefaultLanguage(!!choosedLanguage ? choosedLanguage : languages[0].code);
  }, []);

  const disableLangugeBlockButtons =
    !languageFromLS || languageFromLS === defaultLanguage;

  return {
    choosedLanguageValue: !!choosedLanguageValue
      ? choosedLanguageValue
      : languages[0].name,
    disableLangugeBlockButtons,
    handleChangeLanguage,
    handleSaveLanguage,
    handleCancelLanguage,
  };
};
