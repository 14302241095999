import { useCreateUserModal } from "./useCreateUserModal";
import clsx from "clsx";
import {
  Dropdown,
  Input,
  Modal,
  SwitchField,
  OrganizationSearch,
  Button,
} from "~components";
import styles from "./styles.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  handleCreateUser: (isSuperAdmin: boolean) => void;
}

export const CreateUserModal = ({
  isOpen,
  onClose,
  handleCreateUser,
}: IProps) => {
  const {
    email,
    password,
    isSuperAdmin,
    showOrganizationPopup,
    choosedOrganization,
    disableCreateButton,
    showCreatingLoader,
    error,
    firstName,
    lastName,
    handleEmailChange,
    handlePasswordChange,
    toggleOrganizationValue,
    toggleSuperAdminValue,
    updateChoosedOrganization,
    handleCreateButton,
    handleFirstNameChange,
    handleLastNameChange,
  } = useCreateUserModal({ onClose, handleCreateUser });
  if (!isOpen) return null;

  return (
    <Modal
      containerClassName={styles.modal__container}
      onCloseModal={onClose}
      disableMediaStyles
    >
      <div className={styles.modal}>
        <p className={styles.modal__title}>New User</p>
        <p className={styles.modal__subtitle}>
          Enter email and create password for the new user
        </p>

        <div className={styles.modal__input}>
          <Input
            value={firstName}
            onChange={handleFirstNameChange}
            label="First name"
            placeholder="First name"
          />
        </div>
        <div className={styles.modal__input}>
          <Input
            value={lastName}
            onChange={handleLastNameChange}
            label="Last name"
            placeholder="Last name"
          />
        </div>
        <div className={styles.modal__input}>
          <Input
            value={email}
            onChange={handleEmailChange}
            label="Email"
            placeholder="Enter email"
            error={error}
          />
        </div>
        <div className={styles.modal__input}>
          <Input
            value={password}
            onChange={handlePasswordChange}
            type={"secure"}
            label="Password"
            placeholder="Enter password"
          />
        </div>
        <div className={styles.modal__switchedField}>
          <SwitchField
            text="Make Super-Super Admin"
            value={isSuperAdmin}
            onClick={toggleSuperAdminValue}
          />
        </div>
        <SwitchField
          text="Assign to Organization"
          value={showOrganizationPopup}
          onClick={toggleOrganizationValue}
          containerClassName={clsx({
            [styles.openSwicher]: showOrganizationPopup,
          })}
        />
        {showOrganizationPopup && (
          <div className={styles.modal__popup}>
            <p className={styles.searchInput__label}>Organization</p>

            <OrganizationSearch
              choosedOrganization={choosedOrganization}
              updateChoosedOrganization={updateChoosedOrganization}
            />

            <Dropdown
              placeholder="Choose Role"
              label="Role"
              data={["User", "Admin"]}
              onChange={() => {}}
            />
          </div>
        )}
        <div className={styles.modal__buttons}>
          <Button
            backgroundType="white"
            showShadow
            text="Cancel"
            onClick={onClose}
          />
          <Button
            disable={disableCreateButton}
            loading={showCreatingLoader}
            backgroundType="green"
            text="Create"
            onClick={handleCreateButton}
          />
        </div>
      </div>
    </Modal>
  );
};
