import { StrictModifierNames, Modifier } from "react-popper";
import { useProgressItem } from "../../useProgressItem";
import { Options, createPopper } from "@popperjs/core";
import { Button, PopperPopup } from "~components";
import { IActiveChecklist } from "~models";
import {
  CheckSquareBroken,
  DotsVertical,
  EmptyPlay,
  FileDownload,
  TrashAlt,
} from "~icons";
import dayjs from "dayjs";
import clsx from "clsx";

import styles from "./styles.module.scss";

interface IProps {
  activeChecklist: IActiveChecklist;
  index: number;
  handleContinue: () => void;
  deleteProgress: (id: number, index: number) => void;
}

export const ProgressItem = ({
  activeChecklist,
  index,
  handleContinue,
  deleteProgress,
}: IProps) => {
  const {
    modalRef,
    myRole,
    openListPreview,
    openPdfPreview,
    showPopper,
    handleClosePopper,
    handleOpenPopper,
  } = useProgressItem({
    activeChecklist,
  });

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  const popperPopup = (
    <PopperPopup
      showPopper={showPopper}
      onClosePopper={handleClosePopper}
      onOpenPopper={handleOpenPopper}
      options={popperOptions}
      trigger={<DotsVertical fill="#0B0F1C" />}
      popup={
        <div ref={modalRef} className={clsx(styles.progressItem__modal, {})}>
          {!activeChecklist.completed ? (
            <>
              <div
                onClick={handleContinue}
                className={styles.progressItem__modalItem}
              >
                <EmptyPlay stroke="#0B0F1C" />
                Complete
              </div>
            </>
          ) : (
            <>
              {myRole !== "User" && (
                <div
                  onClick={openListPreview}
                  className={styles.progressItem__modalItem}
                >
                  <FileDownload /> View
                </div>
              )}
              <div
                onClick={openPdfPreview}
                className={styles.progressItem__modalItem}
              >
                <FileDownload /> Generate PDF
              </div>
            </>
          )}

          {myRole !== "User" && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleClosePopper();
                deleteProgress(activeChecklist.id, index);
              }}
              className={styles.progressItem__modalItem}
            >
              <TrashAlt fill="#0B0F1C" /> Delete
            </div>
          )}
        </div>
      }
    />
  );

  return (
    <div>
      <div className={styles.progressItem}>
        <div className={clsx(styles.progressItem__horizontal)}>
          <div className={styles.progressItem__horizontalName}>
            <CheckSquareBroken stroke="#29B473" />{" "}
            {activeChecklist.checklist.title}
          </div>
          <div className={styles.progressItem__horizontalMember}>
            <div className={styles.progressItem__row}>
              {!!activeChecklist?.user?.avatar && (
                <img
                  src={activeChecklist?.user?.avatar}
                  className={styles.avatar}
                />
              )}
              {activeChecklist?.user?.firstName}{" "}
              {activeChecklist?.user?.lastName}
            </div>
          </div>

          <div className={styles.progressItem__horizontalStatus}>
            <p> {dayjs(activeChecklist.updatedAt).format("MMMM D, YYYY")}</p>
            {popperPopup}
          </div>
        </div>
      </div>

      <div className={styles.progressItem__underline} />
    </div>
  );
};
