import { Layout } from "~icons";
import styles from "./styles.module.scss";
import { useSettings } from "./useSettings";
import clsx from "clsx";
import { ItemSettings, SectionSettings } from "./modules";

export const Settings = () => {
  const { showEmptyBlock, activeItem } = useSettings();
  return (
    <div className={clsx(styles.settings, { [styles.empty]: showEmptyBlock })}>
      {showEmptyBlock && (
        <div className={styles.settings__empty}>
          <Layout />
          Select a section or item
          <br />
          to edit its settings
        </div>
      )}

      <SectionSettings />
      {activeItem?.type === "item" && <ItemSettings />}
    </div>
  );
};
