import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { CreateOrganization } from "~blocks";
import { Modal, NumberWidget } from "~components";
import {
  Academy,
  AppLogo,
  Book,
  CheckSquareBroken,
  FileCheckCompleted,
  FilePlus,
  Help,
  Home,
  Settings,
  SuperUser,
  UsersProfilesMembers,
} from "~icons";
import { UserModule, OrganizationModule, ActiveListsModule } from "./modules";
import ContentLoader from "react-content-loader";
import styles from "./styles.module.scss";
import { useLeftPanel } from "./useLeftPanel";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { FeedbackModal } from "~modals";
import { UserAccessRole } from "~models";
import { checkIfMemberIsUser, checkIfUserIsSuperAdmin } from "~helpers";

interface IProps {
  loading?: boolean;
  showPanel: boolean;
}

const ns = "leftPanel";

export const LeftPanel: FC<IProps> = ({ loading, showPanel }) => {
  const {
    showFeedbackModal,
    requestsCount,
    onLinkClick,
    currentUser,
    role,
    choosedOrganization,
    handleAvatarClick,
    handleOpenFeedbackModal,
    handleCloseFeedbackModal,
    handleCreateChatAI,
  } = useLeftPanel();
  const { t, i18n } = useTranslation(["leftPanel"]);
  const linkClassName = (navData: { isActive: boolean }) =>
    navData.isActive ? styles.active : "";
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);

  const handleOrganizationModalOpen = () => {
    setShowOrganizationModal(true);
  };

  const handleOrganizationModalClose = () => {
    setShowOrganizationModal(false);
  };

  return (
    <div className={clsx(styles.leftPanel, { [styles.show]: showPanel })}>
      {showOrganizationModal && (
        <Modal
          containerClassName={styles.leftPanel__organization}
          modalClassName={styles.leftPanel__organizationContent}
          onCloseModal={handleOrganizationModalClose}
          hideCross
        >
          <CreateOrganization
            showTopIcons
            onCrossClick={handleOrganizationModalClose}
            containerClassName={styles.leftPanel__organizationBlock}
            onFinish={handleOrganizationModalClose}
          />
        </Modal>
      )}
      <div className={styles.leftPanel__logo} onClick={handleAvatarClick}>
        <AppLogo />
      </div>

      {loading ? (
        <div className={styles.leftPanel__content}>
          {!!choosedOrganization ? (
            <ContentLoader
              speed={1}
              width={248}
              height={"auto"}
              backgroundColor="#DBDEE7"
              foregroundColor="#ebedf2"
              title=""
            >
              <rect x="0" y="0" rx="7" ry="7" width="248" height="76" />
              <rect x="20" y="114" rx="3" ry="3" width="123" height="12" />
              <rect x="20" y="151" rx="4" ry="4" width="170" height="20" />
              <rect x="20" y="200" rx="4" ry="4" width="208" height="20" />
              <rect x="20" y="249" rx="4" ry="4" width="170" height="20" />
              <rect x="20" y="298" rx="4" ry="4" width="208" height="20" />
              <rect x="20" y="347" rx="4" ry="4" width="170" height="20" />
              <rect x="20" y="396" rx="4" ry="4" width="208" height="20" />
              <rect x="20" y="466" rx="3" ry="3" width="123" height="12" />
              <rect x="20" y="503" rx="4" ry="4" width="208" height="20" />
            </ContentLoader>
          ) : (
            <ContentLoader
              speed={1}
              width={248}
              height={"auto"}
              backgroundColor="#DBDEE7"
              foregroundColor="#ebedf2"
              title=""
            >
              <rect x="0" y="0" rx="7" ry="7" width="248" height="76" />
              <rect x="20" y="114" rx="3" ry="3" width="123" height="12" />
              <rect x="20" y="151" rx="4" ry="4" width="170" height="20" />
            </ContentLoader>
          )}

          <ContentLoader
            speed={1}
            backgroundColor="#DBDEE7"
            foregroundColor="#ebedf2"
            title=""
          >
            <rect x="0" y="0" rx="7" ry="7" width="248" height="74" />
          </ContentLoader>
        </div>
      ) : (
        <div className={styles.leftPanel__content}>
          <div>
            <OrganizationModule
              handleOrganizationModalOpen={handleOrganizationModalOpen}
              onLinkClick={onLinkClick}
            />

            {!!choosedOrganization && (
              <>
                <p className={styles.leftPanel__contentTitle}>
                  {t("organization")}
                </p>
                <NavLink
                  className={linkClassName}
                  onClick={onLinkClick}
                  to={"/dashboard/home"}
                >
                  <CheckSquareBroken />
                  {/* Home */}
                  {t("Home")}
                </NavLink>

                {(!checkIfMemberIsUser(choosedOrganization) ||
                  checkIfUserIsSuperAdmin(currentUser)) && (
                  <>
                    <NavLink
                      className={linkClassName}
                      onClick={onLinkClick}
                      to={"/dashboard/lists"}
                    >
                      <FilePlus />
                      {t("Checkem Builder")}
                    </NavLink>

                    <NavLink
                      className={linkClassName}
                      onClick={onLinkClick}
                      to={"/dashboard/library"}
                    >
                      <Book />
                      {t("Checkem Library")}
                    </NavLink>
                    {/* <NavLink
                      className={linkClassName}
                      // onClick={onLinkClick}
                      to={"/chat"}
                    >
                      <Book />
                      Checkem AI
                    </NavLink> */}
                    <div
                      onClick={handleCreateChatAI}
                      className={styles.leftPanel__contentLinkButton}
                    >
                      <Book />
                      Checkem AI
                    </div>
                    <NavLink
                      className={linkClassName}
                      onClick={onLinkClick}
                      to={"/dashboard/completed"}
                    >
                      <FileCheckCompleted />
                      {t("Completed Checkems")}
                    </NavLink>

                    <NavLink
                      className={linkClassName}
                      onClick={onLinkClick}
                      to={"/dashboard/members"}
                    >
                      <UsersProfilesMembers />
                      {t("Members")}
                      <div className={styles.leftPanel__contentCounter}>
                        {requestsCount > 0 && (
                          <NumberWidget
                            backgroundType={"red"}
                            text={requestsCount}
                          />
                        )}
                      </div>
                    </NavLink>

                    <NavLink
                      className={linkClassName}
                      onClick={onLinkClick}
                      to={"/dashboard/organizationSettings"}
                    >
                      <Settings />
                      {t("Organization Settings")}
                    </NavLink>
                  </>
                )}
              </>
            )}

            <p className={styles.leftPanel__contentTitle}>Support</p>

            {role !== UserAccessRole.User && (
              <>
                <NavLink
                  onClick={onLinkClick}
                  className={linkClassName}
                  to={"/dashboard/organizationsManagement"}
                >
                  <Home />
                  All Organizations
                </NavLink>

                <NavLink
                  onClick={onLinkClick}
                  className={linkClassName}
                  to={"/dashboard/usersManagement"}
                >
                  <UsersProfilesMembers />
                  All Users
                </NavLink>
                {role === UserAccessRole.SuperSuperAdmin && (
                  <NavLink
                    onClick={onLinkClick}
                    className={linkClassName}
                    to={"/dashboard/adminsManagement"}
                  >
                    <SuperUser />
                    Super Admin List
                  </NavLink>
                )}

                <NavLink
                  onClick={onLinkClick}
                  className={linkClassName}
                  to={"/dashboard/checkemDrafts"}
                >
                  <FilePlus />
                  Checkem Library Builder
                </NavLink>

                <NavLink
                  onClick={onLinkClick}
                  className={linkClassName}
                  to={"/dashboard/helpAndFeedback"}
                >
                  <Help />

                  {t("Help and Feedback")}
                </NavLink>
              </>
            )}

            {role === UserAccessRole.User && (
              <a
                href="https://forms.gle/yBirGJYziCkthWMQA"
                target="_blank"
                // onClick={handleOpenFeedbackModal}
                className={styles.leftPanel__contentLinkButton}
                rel="noreferrer"
              >
                <Help />
                {t("Help and Feedback")}
              </a>
            )}

            {/* <NavLink
              onClick={onLinkClick}
              className={linkClassName}
              to={"/dashboard/academy"}
            >
              <Academy />

              {t("Academy")}
            </NavLink> */}
          </div>

          <UserModule onLinkClick={onLinkClick} />
        </div>
      )}

      {/* {!loading && !!choosedOrganization && <ActiveListsModule />} */}

      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={handleCloseFeedbackModal}
      />
    </div>
  );
};
