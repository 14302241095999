import { useQuery } from "@apollo/client";
import { useUpdateEffect } from "usehooks-ts";
import { alertVar, GET_ALERT } from "~localCache";

export const useAlert = () => {
  const {
    data: { alert },
  } = useQuery(GET_ALERT);

  const handleCloseAlert = () => alertVar(null);

  useUpdateEffect(() => {
    if (!!alert && !alert?.isLoading) {
      const delayDebounceFn = setTimeout(() => {
        alertVar(null);
      }, 3000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [alert]);

  return {
    data: alert,
    handleCloseAlert,
  };
};
