import React from "react";
import {
  Bell,
  Play,
  Stopwatch as StopwatchIcon,
  Timer as TimerIcon,
} from "~icons";

import styles from "./styles.module.scss";
import { Button } from "~components";
import { useTimer } from "./useTimer";
import { IItem } from "../../../../../constants";

interface IProps {
  item: IItem;
  isDisable: boolean;
}
export const Timer = ({ item, isDisable }: IProps) => {
  const { converted } = useTimer({ item });
  return (
    <div className={styles.timer}>
      <div className={styles.timer__content}>
        <div className={styles.timer__row}>
          <div className={styles.timer__contentIcon}>
            <TimerIcon />
          </div>
          <p>{converted}</p>
        </div>
        <div className={styles.timer__contentRight}>
          <div className={styles.timer__contentButton}>
            <Button
              disable={isDisable}
              icon={<Play />}
              backgroundType={"green"}
              text=""
            />
          </div>
          <div className={styles.timer__contentReset}>
            <Button
              disable={isDisable}
              backgroundType={"white"}
              text="Reset"
              showShadow
            />
          </div>
          <div className={styles.timer__contentButton}>
            <Button
              disable={isDisable}
              icon={<Bell />}
              backgroundType={"white"}
              text=""
              showShadow
            />
          </div>
        </div>
      </div>
    </div>
  );
};
