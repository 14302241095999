import { GET_ORGANIZATIONS_PAGINATION_DATA } from "~queries";
import { useLazyQuery } from "@apollo/client";
import { useUpdateEffect } from "usehooks-ts";
import { useEffect, useRef, useState } from "react";
import { IOrganization } from "~models";
import { useOutsideClick } from "~hooks";

const PAGE_LIMIT = 49;

interface IProps {
  choosedOrganization: IOrganization | null;
  updateChoosedOrganization: (value: IOrganization | null) => void;
}

export const useOrganizationSearch = ({
  choosedOrganization,
  updateChoosedOrganization,
}: IProps) => {
  const inputRef = useRef<HTMLDivElement>(null);

  const [fetchedCount, setFetchedCount] = useState(0);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [allOrganizationsCount, setAllOrganizationsCount] = useState(0);

  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [isFocused, setIsFocused] = useState(false);
  const setActiveFocus = () => setIsFocused(true);
  const setDisactiveFocus = () => setIsFocused(false);

  const handleCrossClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    !!choosedOrganization
      ? updateChoosedOrganization(null)
      : setSearchValue("");
  };

  const [getData] = useLazyQuery(GET_ORGANIZATIONS_PAGINATION_DATA);

  const loadData = () => {
    setLoading(true);

    const countInput = {};
    const organizationInput = { skip: 0, take: PAGE_LIMIT };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(organizationInput, { search: searchValue });
    }

    getData({
      variables: { countInput, organizationInput },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      fetchedCount !== 0 && setFetchedCount(0);

      setAllOrganizationsCount(resp.data.organizationsCount);
      setOrganizations(resp.data.organizations);
      setLoading(false);
    });
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(allOrganizationsCount);
    const skip = fetchedCount + PAGE_LIMIT;
    if (skip >= maxPage) return;
    setLoading(true);
    let take = PAGE_LIMIT;
    if (allOrganizationsCount - skip < 10) {
      take = allOrganizationsCount - skip;
    }
    const countInput = {};
    const organizationInput = { skip: skip, take: PAGE_LIMIT };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(organizationInput, { search: searchValue });
    }
    getData({
      variables: { countInput, organizationInput },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllOrganizationsCount(resp.data.organizationsCount);
      setOrganizations([...organizations, ...resp.data.organizations]);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  useOutsideClick(inputRef, setDisactiveFocus);

  useEffect(() => {
    loadData();
  }, []);

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      loadData();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    loading,
    inputRef,
    isFocused,
    searchValue,
    organizations,
    organizationsCount: allOrganizationsCount,
    handleNextPage,
    setSearchValue,
    setActiveFocus,
    handleCrossClick,
    setDisactiveFocus,
  };
};
