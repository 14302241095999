import { IItem } from "~models";
import { useChecklist } from "../../../../context";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

enum BODY_PARTS {
  FRONT_HEAD = "FRONT_HEAD",
  BACK_HEAD = "BACK_HEAD",
  FRONT_TORSO = "FRONT_TORSO",
  BACK_TORSO = "BACK_TORSO",
  FRONT_LEFT_HAND = "FRONT_LEFT_HAND",
  FRONT_RIGHT_HAND = "FRONT_RIGHT_HAND",
  BACK_LEFT_HAND = "BACK_LEFT_HAND",
  BACK_RIGHT_HAND = "BACK_RIGHT_HAND",
  FRONT_LEFT_LEG = "FRONT_LEFT_LEG",
  FRONT_RIGHT_LEG = "FRONT_RIGHT_LEG",
  BACK_LEFT_LEG = "BACK_LEFT_LEG",
  BACK_RIGHT_LEG = "BACK_RIGHT_LEG",
  GROIN = "GROIN",
}

const PERCENTS_FOR_ADULT = {
  [BODY_PARTS.FRONT_HEAD]: 4.5,
  [BODY_PARTS.BACK_HEAD]: 4.5,
  [BODY_PARTS.FRONT_LEFT_HAND]: 4.5,
  [BODY_PARTS.FRONT_RIGHT_HAND]: 4.5,
  [BODY_PARTS.FRONT_TORSO]: 18,
  [BODY_PARTS.BACK_TORSO]: 18,
  [BODY_PARTS.FRONT_LEFT_LEG]: 9,
  [BODY_PARTS.FRONT_RIGHT_LEG]: 9,
  [BODY_PARTS.BACK_LEFT_HAND]: 4.5,
  [BODY_PARTS.BACK_RIGHT_HAND]: 4.5,
  [BODY_PARTS.BACK_LEFT_LEG]: 9,
  [BODY_PARTS.BACK_RIGHT_LEG]: 9,
  [BODY_PARTS.GROIN]: 1,
};

const PERCENTS_FOR_CHILD = {
  [BODY_PARTS.FRONT_HEAD]: 7,
  [BODY_PARTS.BACK_HEAD]: 7,
  [BODY_PARTS.FRONT_LEFT_HAND]: 4.5,
  [BODY_PARTS.FRONT_RIGHT_HAND]: 4.5,
  [BODY_PARTS.FRONT_TORSO]: 18,
  [BODY_PARTS.BACK_TORSO]: 18,
  [BODY_PARTS.FRONT_LEFT_LEG]: 8,
  [BODY_PARTS.FRONT_RIGHT_LEG]: 8,
  [BODY_PARTS.BACK_LEFT_HAND]: 4.5,
  [BODY_PARTS.BACK_RIGHT_HAND]: 4.5,
  [BODY_PARTS.BACK_LEFT_LEG]: 8,
  [BODY_PARTS.BACK_RIGHT_LEG]: 8,
  [BODY_PARTS.GROIN]: 0,
};

export const useBurnedAreas = ({ item, progressItem, isDisable }: IProps) => {
  const { progress, setProgress } = useChecklist();

  const itemIndex = progress.data.items.findIndex(
    (elem: { id: number }) => elem.id === item.id
  );

  let data: BODY_PARTS[] =
    typeof progressItem?.data === "string"
      ? JSON.parse(progressItem.data)
      : !!progressItem.data
      ? progressItem.data
      : [];

  const handleClick = (e: React.MouseEvent, partName: BODY_PARTS) => {
    e.stopPropagation()
    if (isDisable) return;
    setProgress((prev) => {
      let state = { ...prev };
      if (!!data) {
        let index = data.findIndex((item: BODY_PARTS) => item === partName);
        if (index > -1) {
          data.splice(index, 1);
          state.data.items[itemIndex].data = data;
        } else {
          data.push(partName);
          state.data.items[itemIndex].data = data;
        }
      } else {
        state.data.items[itemIndex].data = [partName];
      }
      return state;
    });
  };

  const isChoosed = (partName: BODY_PARTS) =>
    data?.findIndex((item) => item === partName) > -1;

  const percent = !!data
    ? data.reduce((changedValue: number, currentValue: BODY_PARTS) => {
        let operand =
          item.data.type === "Adult"
            ? PERCENTS_FOR_ADULT[currentValue]
            : PERCENTS_FOR_CHILD[currentValue];
        return changedValue + operand;
      }, 0)
    : 0;

  return { BODY_PARTS, percent, handleClick, isChoosed };
};
