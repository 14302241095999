import { useRef, useState } from "react";
import { PopupActions } from "reactjs-popup/dist/types";
import { useOutsideClick } from "~hooks";

interface IProps {
  onChoose: (color: string) => void;
}

export const useColorPicker = ({ onChoose }: IProps) => {
  const popupRef = useRef<PopupActions>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const [showPicker, setShowPicker] = useState(false);

  const handleOpenPopup = () => {
    popupRef.current?.open();
  };

  const handleClosePopup = () => {
    popupRef.current?.close();
  };

  useOutsideClick(modalRef, handleClosePopup);

  const [choosedColor, setColor] = useState("#FFFFFF");

  const handleChange = (color: any) => {
    setColor(color.hex);
  };

  const handleChangeComplete = (color: any) => {
    setColor(color.hex);
  };

  const handleSaveColor = (color: string) => {
    onChoose(color);
    handleClosePopup();
  };

  return {
    handleOpenPopup,
    popupRef,
    modalRef,
    showPicker,
    choosedColor,
    handleChange,
    handleChangeComplete,
    setShowPicker,
    handleSaveColor,
  };
};
