import { Options, createPopper } from "@popperjs/core";
import { StrictModifierNames } from "react-popper";
import React, { useState, useRef } from "react";
import { useOutsideClick } from "~hooks";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { Modifier } from "react-popper";

import styles from "./styles.module.scss";

interface IPortalProps {
  children: React.ReactNode;
}

interface IProps {
  trigger: React.ReactNode;
  popup: React.ReactNode;
  options?: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  };
  showPopper: boolean;
  onOpenPopper: () => void;
  onClosePopper: () => void;
}

export const Portal = ({ children }: IPortalProps) => {
  return createPortal(children, document.body);
};

export const PopperPopup = ({
  showPopper,
  trigger,
  popup,
  options,
  onOpenPopper,
  onClosePopper,
}: IProps) => {
  const buttonRef = useRef(null);
  const popperRef = useRef(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

  let popperOptions = !!options
    ? options
    : {
        modifiers: [
          {
            name: "arrow",
            options: {
              element: arrowRef,
            },
          },
          {
            name: "offset",
            options: {
              offset: [0, 0],
            },
          },
        ],
      };

  const { styles: popperStyles, attributes } = usePopper(
    buttonRef.current,
    popperRef.current,
    popperOptions
  );

  useOutsideClick(popperRef, onClosePopper);

  return (
    <div className={styles.popperPopup}>
      <div
        className={styles.popperPopup__trigger}
        ref={buttonRef}
        onClick={(e) => {
          e.stopPropagation();
          onOpenPopper();
        }}
      >
        {trigger}
      </div>

      {showPopper ? (
        <Portal>
          <div
            ref={popperRef}
            style={popperStyles.popper}
            {...attributes.popper}
            className={styles.popperPopup__popper}
          >
            <div ref={setArrowRef} style={popperStyles.arrow} id="arrow" />
            {popup}
          </div>
        </Portal>
      ) : null}
    </div>
  );
};
