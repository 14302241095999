import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IUser } from "~models";

interface IProps {
  user: IUser;
}

export const useUserItem = ({ user }: IProps) => {
  const navigate = useNavigate();
  const [showPopper, setShowPopper] = useState(false);

  const handleOpenPopper = () => setShowPopper(true);
  const handleClosePopper = () => setShowPopper(false);

  const handleEditButton = () => navigate(`/dashboard/profile/${user.id}`);

  return { showPopper, handleOpenPopper, handleClosePopper, handleEditButton };
};
