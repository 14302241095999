import styles from "./styles.module.scss";

import { IItem } from "~models";
import { useCounter } from "./useCounter";
import { Counter as CounterComponent } from "~components";
import clsx from "clsx";
import { MinusSymbol, PlusSymbol } from "~icons";
import dayjs from "dayjs";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const Counter = ({ item, isDisable, progressItem }: IProps) => {
  const {
    handleActiveFocus,
    handleDisactiveFocus,
    handleMinusButton,
    handleInputChange,
    handlePlusButton,
    disableMinusButton,
    onBlur,
    focused,
    textareaRef,
  } = useCounter({
    item,
    progressItem,
    isDisable,
  });

  return (
    <div className={styles.counter}>
      <div className={styles.content}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleMinusButton();
          }}
          className={clsx(styles.content__button, {
            [styles.content__buttonDisable]: disableMinusButton,
          })}
        >
          <MinusSymbol
            fill={disableMinusButton || isDisable ? "#979DB1" : "#0B0F1C"}
          />
        </div>
        <input
          onBlur={onBlur}
          disabled={true}
          className={clsx(styles.content__input)}
          placeholder={"0"}
          value={progressItem?.value}
          onChange={handleInputChange}
        />
        <div
          className={clsx(styles.content__button, {
            [styles.content__buttonDisable]: isDisable,
          })}
          onClick={(e) => {
            e.stopPropagation();
            handlePlusButton();
          }}
        >
          <PlusSymbol fill={isDisable ? "#979DB1" : "#0B0F1C"} />
        </div>
      </div>
      {item.data.record && !!progressItem?.timestamps && (
        <div className={styles.content__times}>
          {progressItem.timestamps.map((item: number) => {
            return (
              <p key={`${item} - counter`}>
                {dayjs(item).format("hh:mm:ss A")}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};
