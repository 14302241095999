import { createContext, FC, ReactNode, useState, useContext } from "react";
import { testChecklist } from "./constants";
import { IDraft, IProgress } from "~models";

let defaultProgress: IProgress = {
  id: -1,
  completed: false,
  data: { items: [], sections: [] },
  organizationId: -1,
  userId: -1,
  checklistId: -1,
  user: {
    email: "",
  },
};

const BuilderContext = createContext<{
  list: IDraft;
  activeTabId: number;
  activeTabIndex: number;
  progress: IProgress;
  completed: Boolean;
  audio: HTMLAudioElement;
  pdfFormat: boolean;
  showPdfTab: boolean;
  setList: React.Dispatch<React.SetStateAction<IDraft>>;
  setActiveTabId: React.Dispatch<React.SetStateAction<number>>;
  setProgress: React.Dispatch<React.SetStateAction<IProgress>>;
  setIsPdfFormat: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPdfTab: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  pdfFormat: false,
  list: testChecklist,
  progress: defaultProgress,
  activeTabId: testChecklist.tabs[0].id,
  showPdfTab: false,
  audio: new Audio(),
  activeTabIndex: 0,
  completed: false,
  setList: () => {},
  setActiveTabId: () => {},
  setProgress: () => {},
  setIsPdfFormat: () => {},
  setShowPdfTab: () => {},
});

interface IProps {
  children: ReactNode;
}

export const useChecklist = () => {
  return useContext(BuilderContext);
};

export const BuilderProvider: FC<IProps> = ({ children }) => {
  let [list, setList] = useState<IDraft>(
    JSON.parse(JSON.stringify(testChecklist))
  );

  const [showPdfTab, setShowPdfTab] = useState(false);
  const [pdfFormat, setIsPdfFormat] = useState(false);

  let [progress, setProgress] = useState<IProgress>(defaultProgress);

  const [activeTabId, setActiveTabId] = useState<number>(0);

  const activeTabIndex = list.tabs.findIndex((item) => item.id === activeTabId);
  if (activeTabIndex === -1) {
    setActiveTabId(list.tabs[0].id);
  }

  return (
    <BuilderContext.Provider
      value={{
        list,
        activeTabId,
        activeTabIndex,
        progress,
        completed: false,
        audio: new Audio(),
        pdfFormat,
        showPdfTab,
        setShowPdfTab,
        setList,
        setProgress,
        setActiveTabId,
        setIsPdfFormat,
      }}
    >
      {children}
    </BuilderContext.Provider>
  );
};

export default BuilderContext;
