import styles from "./styles.module.scss";

import { IItem } from "../../../../../constants";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";
import clsx from "clsx";

interface IProps {
  item: IItem;
}

export const Metronome = ({ item }: IProps) => {
  const type =
    typeof item?.data === "string"
      ? JSON.parse(item.data)?.type
      : item.data.type;
  const isDark = useReactiveVar(darkTheme);
  return (
    <div className={styles.metronome}>
      {type === "CPR" ? (
        <div className={styles.metronome__cpr}>
          <div
            className={
              isDark
                ? clsx(styles.metronome__cprRow, styles.metronome__cprRowDark)
                : styles.metronome__cprRow
            }
          >
            <div className={styles.metronome__cprButton}>Continuous</div>
            <div className={styles.metronome__cprButton}>
              Continuous+10 BPM Ventilations
            </div>
          </div>
          <div
            className={
              isDark
                ? clsx(styles.metronome__cprRow, styles.metronome__cprRowDark)
                : styles.metronome__cprRow
            }
          >
            <div className={styles.metronome__cprButton}>30:2</div>
            <div className={styles.metronome__cprButton}>15:2</div>
          </div>
        </div>
      ) : (
        <div className={styles.metronome__ventilations}>
          <div className={styles.metronome__ventilationsColumn}>
            <div className={styles.metronome__ventilationsButton}>8 BPM</div>
            <div className={styles.metronome__ventilationsButton}>12 BPM</div>
            <div className={styles.metronome__ventilationsButton}>16 BPM</div>
          </div>
          <div className={styles.metronome__ventilationsColumn}>
            <div className={styles.metronome__ventilationsButton}>10 BPM</div>
          </div>
          <div className={styles.metronome__ventilationsColumn}>
            <div className={styles.metronome__ventilationsButton}>20 BPM</div>
            <div className={styles.metronome__ventilationsButton}>25 BPM</div>
            <div className={styles.metronome__ventilationsButton}>30 BPM</div>
          </div>
        </div>
      )}
    </div>
  );
};
