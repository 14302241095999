import { useUsersManagement } from "./useUsersManagement";
import { CreateOrganization } from "~blocks";
import {
  Button,
  Modal,
  Pagination,
  SearchInput,
  Table,
  TableLoader,
} from "~components";
import { DashboardLayout } from "~layouts";
import { PlusSymbol } from "~icons";

import styles from "./styles.module.scss";
import { IOrganization } from "~models";
import { UserItem } from "./UserItem";
import { CreateUserModal } from "~modals";

export const UsersManagement = () => {
  const {
    members,
    loadingMembers,
    allMembersCount,
    membersCount,
    handleNextPage,
    handlePrevPage,
    fetchedCount,
    loading,
    searchValue,
    setSearchValue,
    handleChangeRole,
    limit: PAGE_LIMIT,
    choosedOrganization,
    deleteMember,
    handleCreateUser,
    showCreateUserModal,
    handleOpenCreateUserModal,
    handleCloseCreateUserModal,
  } = useUsersManagement();
  return (
    <DashboardLayout
      title="All Users"
      rightItem={
        <div
          onClick={handleOpenCreateUserModal}
          className={styles.usersManagement__createButton}
        >
          <PlusSymbol fill="#FFFFFF" />
          <p />
        </div>
      }
    >
      <div className={styles.usersManagement}>
        <div className={styles.usersManagement__search}>
          <SearchInput
            placeholder="Search for companies"
            value={searchValue}
            onChange={setSearchValue}
          />
        </div>
        {loading ? (
          <TableLoader />
        ) : (
          <Table
            header={[
              {
                header: "User",
                className: styles.usersManagement__user,
              },

              {
                header: "Organizations",
                className: styles.usersManagement__organizations,
              },
              {
                header: "",
                className: styles.usersManagement__date,
              },
            ]}
            data={members}
            renderTableItem={(item, index) => (
              <UserItem
                key={`${item} - ${index}`}
                user={item}
                index={index}
                // handleDeleteDraft={() => {}}
                // updateDraftStatus={() => {}}
                // updateSharedCode={() => {}}
              />
            )}
          />
        )}
        <Pagination
          allResults={allMembersCount}
          fetchedResult={fetchedCount + 10}
          limit={10}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
        />

        <CreateUserModal
          isOpen={showCreateUserModal}
          onClose={handleCloseCreateUserModal}
          handleCreateUser={handleCreateUser}
        />
      </div>
    </DashboardLayout>
  );
};
