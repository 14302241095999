import { useMutation, useQuery } from "@apollo/client";
import { DELETE_USER, TRANSFER_OWNER } from "~mutations";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IOrganization } from "~models";
import { alertVar, choosedOrganizationVar } from "~localCache";
import { regex } from "~constants";
import { useAuth } from "~hooks";
import { client } from "~services";
import {
  GET_CURRENT_USER,
  GET_MY_ORGANIZATIONS,
  GET_USER_ORGANIZATIONS,
} from "~queries";

interface IProps {
  handleSuccessResult: () => void;
  userId?: number;
}

export const useDeleteUserModal = ({ handleSuccessResult, userId }: IProps) => {
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const [mutateDeleteUser] = useMutation(DELETE_USER);
  const [transferOwner] = useMutation(TRANSFER_OWNER);

  const { data: organizationsData, loading } = useQuery(
    GET_USER_ORGANIZATIONS,
    {
      variables: { input: { userId, organizationMemberRole: "Owner" } },
      fetchPolicy: "network-only",
    }
  );

  const {
    data: { currentUser },
  } = useQuery(GET_CURRENT_USER);

  const [step, setStep] = useState(1);
  const [data, setData] = useState<
    { organization: IOrganization; email: string }[]
  >([]);

  const [showDeletingloader, setShowDeletingLoader] = useState(false);

  const isMyProfile = currentUser?.id === userId;

  const onChangeEmail = (index: number, value: string) => {
    setData((prev) => {
      if (!prev) return data;
      let state = [...prev];
      state[index].email = value;
      return state;
    });
  };

  const handleNextStep = () => setStep(step + 1);
  const handlePrevStep = () => setStep(step - 1);

  const isDisable = () => {
    const index = data.findIndex((item) => !regex.email.test(item.email));
    return index > -1 ? true : false;
  };

  const onSubmit = () => {
    setShowDeletingLoader(true);
    const input = data.map((item) => {
      return { organizationId: item.organization.id, email: item.email };
    });

    transferOwner({
      variables: { input: input, isDelete: true, userId },
    })
      .then(async (resp) => {
        if (isMyProfile) {
          setShowDeletingLoader(false);
          choosedOrganizationVar(null);
          localStorage.clear();
          setUser(null);
          setTimeout(() => {
            client.resetStore();
          }, 100);
        } else handleSuccessResult();
      })
      .catch((err) => {
        setShowDeletingLoader(false);
        if (!!err?.graphQLErrors[0]?.message) {
          alertVar({ type: "red", text: err.graphQLErrors[0].message });
        }
      });
  };

  const handleDeleteAccount = () => {
    setShowDeletingLoader(true);
    mutateDeleteUser({ variables: { id: userId } })
      .then((resp) => {
        if (isMyProfile) {
          setShowDeletingLoader(false);
          choosedOrganizationVar(null);
          localStorage.clear();
          setUser(null);
          setTimeout(() => {
            client.resetStore();
          }, 100);
        } else handleSuccessResult();
      })
      .catch((err) => console.log("ERR", err));
  };

  useEffect(() => {
    if (!loading && !!organizationsData) {
      setData(
        organizationsData.getUserOrganizations.map((item: IOrganization) => {
          return { organization: item, email: "" };
        })
      );
    }
  }, [loading, organizationsData]);

  return {
    step,
    data,
    isMyProfile,
    showDeletingloader,
    mainLoading: loading,
    organizationsData,
    isDisable,
    handleNextStep,
    handlePrevStep,
    onChangeEmail,
    onSubmit,
    handleDeleteAccount,
  };
};
