import { gql } from "@apollo/client";

export const GET_PDF = gql`
  query pdf($id: Int!) {
    pdf(id: $id) {
      id
      title
      status
      file
    }
  }
`;
