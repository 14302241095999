import { IItem } from "~models";
import { useTimeMarker } from "./useTimeMarker";
import { Button } from "~components";
import { Return } from "~icons";
import dayjs from "dayjs";
import clsx from "clsx";

import styles from "./styles.module.scss";
import { cloneDeep } from "@apollo/client/utilities";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

interface ITimeMarkerItem {
  buttonIndex: number;
  clickNumber: number;
  time: Date;
}

export const TimeMarker = ({ item, isDisable, progressItem }: IProps) => {
  const parsedData =
    typeof item.data === "string" ? JSON.parse(item.data) : item.data;

  let validItem = { ...item, data: parsedData };

  if (parsedData.hasOwnProperty("buttonTitle")) {
    validItem = {
      ...item,
      data: { buttons: [{ title: parsedData.buttonTitle, color: "#29B473" }] },
    };
  }

  const {
    isTimeNoAdded,
    handleMarkTime,
    handleResetTime,
    handleRemoveLastStep,
  } = useTimeMarker({
    item: validItem,
    progressItem,
    isDisable,
  });

  // console.log()

  const reversedTimes = !!progressItem?.times
    ? cloneDeep(progressItem?.times).reverse()
    : [];

  return (
    <div className={styles.timeMarker}>
      {/* <div className={styles.timeMarker__left}>
        {!!progressItem?.times &&
          progressItem.times.map((item: Date) => (
            <div key={dayjs(item).toString()}>
              {dayjs(item).format("hh:mm:ss A")}
            </div>
          ))}
      </div>
      <div className={styles.timeMarker__right}>
        <div
          className={clsx(styles.timeMarker__returnButton, {
            [styles.disable]: isDisable || isTimeNoAdded,
          })}
          onClick={handleRemoveLastStep}
        >
          <Return fill={isTimeNoAdded ? "#DBDEE7" : "#0B0F1C"} />
        </div>

        <div className={styles.timeMarker__resetButton}>
          <Button
            onClick={handleResetTime}
            disable={isDisable || isTimeNoAdded}
            text="Reset"
            backgroundType={"white"}
            showShadow
          />
        </div>

        <div className={styles.timeMarker__markButton}>
          <Button
            disable={isDisable}
            text={item.data.buttonTitle}
            backgroundType={"green"}
            onClick={handleMarkTime}
          />
        </div>
      </div> */}

      <div className={styles.timeMarker__markButtons}>
        {validItem.data.buttons.map(
          (buttomItem: { title: string; color: string }, index: number) => {
            return (
              <div
                key={`${index} - mark button`}
                className={styles.timeMarker__markButton}
              >
                <Button
                  onClick={() => handleMarkTime(index)}
                  disable={isDisable}
                  text={buttomItem.title}
                  // backgroundType={"green"}
                  textColor={"#FFFFFF"}
                  backgroundColor={
                    !!buttomItem.color ? buttomItem.color : "#29B473"
                  }
                />
              </div>
            );
          }
        )}
      </div>

      <div className={styles.timeMarker__text}>
        {!!progressItem?.times &&
          reversedTimes.map((buttonItem: ITimeMarkerItem, index: number) => (
            <div key={`${dayjs(buttonItem.time).toString()} ${index}`}>
              {dayjs(buttonItem.time).format("hh:mm:ss A")} (
              {validItem.data.buttons[buttonItem.buttonIndex].title} -{" "}
              {buttonItem.clickNumber})
            </div>
          ))}
      </div>

      <div className={styles.timeMarker__buttons}>
        <div
          className={clsx(styles.timeMarker__returnButton, {
            [styles.disable]: isDisable || isTimeNoAdded,
          })}
          onClick={handleRemoveLastStep}
        >
          <Return fill={isTimeNoAdded || isDisable ? "#DBDEE7" : "#0B0F1C"} />
        </div>

        <div className={styles.timeMarker__resetButton}>
          <Button
            onClick={handleResetTime}
            disable={isDisable || isTimeNoAdded}
            text="Reset"
            backgroundType={"white"}
            showShadow
          />
        </div>
      </div>
    </div>
  );
};
