import "./App.css";
import { MainNavigation } from "~navigations";
import { ApolloProvider, useReactiveVar } from "@apollo/client";
import { client } from "./services/authService";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_WEB_CLIENT_ID } from "~constants";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Alert } from "~components";
import { AudioPlayerProvider } from "react-use-audio-player";
import { Document, Page, pdfjs } from "react-pdf";
import { darkTheme } from "~localCache";
import { useEffect } from "react";

const stripePromise = loadStripe(
  !!process.env?.REACT_APP_STRIPE_PUBLISHABLE_KEY
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    : ""
);

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

function App() {
  const isDark = useReactiveVar(darkTheme);

  useEffect(() => {
    const value = localStorage.getItem("theme")
    if(value) {
      darkTheme(value === 'light' ? false : true)
    }
    document.body.classList.toggle("dark-mode", isDark);
  }, [isDark]);

  return (
    <Elements stripe={stripePromise}>
      <GoogleOAuthProvider clientId={GOOGLE_WEB_CLIENT_ID}>
        <ApolloProvider client={client}>
          <AudioPlayerProvider>
            <Alert />
            <MainNavigation />
          </AudioPlayerProvider>
        </ApolloProvider>
      </GoogleOAuthProvider>
    </Elements>
  );
}

export default App;
