import styles from "./styles.module.scss";

import { IItem } from "~models";
import { useMedCheck } from "./useMedCheck";
import { Button, Input } from "~components";
import clsx from "clsx";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const MedCheck = ({ item, isDisable, progressItem }: IProps) => {
  const {
    disableCheckButton,
    disableOverrideButton,
    CHECK_STATUSES,
    handleNameChange,
    handleDateChange,
    handleCheck,
    handleOverrideClick,
  } = useMedCheck({
    item,
    isDisable,
    progressItem,
  });

  return (
    <div className={styles.medcheck}>
      <div className={styles.medcheck__inputs} onClick={e => e.stopPropagation()}>
        <Input
          onChange={handleNameChange}
          value={progressItem?.name}
          disable={isDisable}
          label="Medication name"
          placeholder="Enter name"
        />
        <Input
          onChange={handleDateChange}
          value={progressItem?.exp}
          disable={isDisable}
          label="Expiration date"
          placeholder="MM/YYYY"
          mask="99/9999"
        />
      </div>
      <div className={styles.medcheck__buttons}>
        <Button
          onClick={handleOverrideClick}
          disable={disableOverrideButton}
          backgroundType={"white"}
          showShadow
          text="Override"
        />
        <Button
          onClick={handleCheck}
          disable={disableCheckButton}
          backgroundType={"green"}
          text="Check"
        />
      </div>

      {!!progressItem?.status && (
        <p
          className={clsx(styles.medcheck__result, {
            [styles.red]:
              progressItem.status === "INVALID_NAME_AND_DATE" ||
              progressItem.status === "INVALID_NAME" ||
              progressItem.status === "DATE_EXPIRED",
            [styles.green]: progressItem.status === "HAS_NOT_EXPIRED",
            [styles.orange]: progressItem.status === "OVERRODE",
          })}
        >
          {CHECK_STATUSES[progressItem.status as keyof typeof CHECK_STATUSES]}
        </p>
      )}
    </div>
  );
};
