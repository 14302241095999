import { alertVar, choosedOrganizationVar } from "~localCache";
import { PublishingStatus, IPdf } from "~models";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useRef, useState } from "react";
import {
  DUPLICATE_PDF,
  IMPORT_PDF,
  PUBLISH_PDF,
  REMOVE_PDF,
  REMOVE_PDF_FROM_FOLDER,
  UPDATE_PDF,
} from "~mutations";

interface IProps {
  pdf: IPdf;
  index?: number;
  updatePDFStatus?: (status: PublishingStatus, index: number) => void;
  showTableLoader?: () => void;
  updateTableData?: () => void;
}

export const usePDF = ({
  pdf,
  index,
  updatePDFStatus,
  showTableLoader,
  updateTableData,
}: IProps) => {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const choosedOrganization = choosedOrganizationVar();

  const [publishPdf] = useMutation(PUBLISH_PDF);
  const [updatePdf] = useMutation(UPDATE_PDF);
  const [importPdf] = useMutation(IMPORT_PDF);
  const [duplicatePdf] = useMutation(DUPLICATE_PDF);
  const [removePdf] = useMutation(REMOVE_PDF);
  const [removePdfFromFolder] = useMutation(REMOVE_PDF_FROM_FOLDER);

  const [showPopper, setShowPopper] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [showShareCodeModal, setShowShareCodeModal] = useState(false);

  const [showMoveModal, setShowMoveModal] = useState(false)

  const handleOpenMoveModal = () => {
    handleClosePopper();
    setShowMoveModal(true)
  }

  const handleCloseMoveModal = () => {
    handleClosePopper();
    setShowMoveModal(false)
  }

  const handleOpenPopper = () => setShowPopper(true);
  const handleClosePopper = () => setShowPopper(false);

  const handleOpenDeletionModal = () => {
    handleClosePopper();
    setShowDeletionModal(true);
  };
  const handleCloseDeletionModal = () => setShowDeletionModal(false);

  const handleOpenShareModal = () => {
    handleClosePopper();
    setShowShareCodeModal(true);
  };
  const handleCloseShareModal = () => setShowShareCodeModal(false);

  const handlePublish = () => {
    if (!updatePDFStatus || typeof index !== "number") return;
    alertVar({
      type: "blue",
      text: "PDF is publishing",
      isLoading: true,
    });
    handleClosePopper();
    publishPdf({
      variables: { id: pdf.id },
    })
      .then(() => {
        updatePDFStatus(PublishingStatus.Published, index);
        alertVar({
          type: "green",
          text: "PDF was successfully published",
        });
      })
      .catch((err) => {
        alertVar({
          type: "red",
          text: "Error: this checklist can’t be published, because it has empty fields",
          isLoading: false,
        });
        navigate(`/builder/${pdf.id}`, {
          state: {
            error: JSON.parse(err.graphQLErrors[0].extensions.response.error),
          },
        });
      });
  };

  const handleUnpublish = () => {
    if (!updatePDFStatus || typeof index !== "number") return;

    alertVar({
      type: "blue",
      text: "PDF is unpublishing",
      isLoading: true,
    });
    handleClosePopper();
    updatePdf({
      variables: {
        input: {
          id: pdf.id,
          status: PublishingStatus.Unpublished,
        },
      },
    }).then(() => {
      updatePDFStatus(PublishingStatus.Unpublished, index);
      alertVar({
        type: "green",
        text: "PDF was successfully unpublished",
      });
    });
  };

  const handleView = () => {
    navigate(`/pdf/${pdf.id}`);
  };

  const handleDelete = () => {
    handleClosePopper();
    !!showTableLoader && showTableLoader();
    removePdf({ variables: { id: pdf.id } }).then(() => {
      !!updateTableData && updateTableData();
    });
  };

  const handleDuplicate = () => {
    handleClosePopper();
    !!showTableLoader && showTableLoader();
    duplicatePdf({ variables: { id: pdf.id } }).then(() => {
      !!updateTableData && updateTableData();
    });
  };

  const handleImportButton = () => {
    setShowLoader(true);
    importPdf({
      variables: {
        input: {
          organizationId: choosedOrganization?.id,
          pdfId: pdf.id,
        },
      },
    }).then(() => {
      alertVar({
        text: "The PDF has been successfully imported",
        type: "green",
      });
      setShowLoader(false);
    });
  };

  const handleMoveOut = () => {
    alertVar({
      type: "blue",
      text: "PDF is moving out of folder",
      isLoading: true,
    });
    removePdfFromFolder({ variables: { id: pdf.id } }).then(() => {
      !!updateTableData && updateTableData();
      alertVar({
        type: "green",
        text: "PDF was successfully moved out from folder",
      });
    });
  };

  return {
    modalRef,
    myRole: !!choosedOrganization?.members
      ? choosedOrganization?.members[0].role
      : "User",
    showPopper,
    showLoader,
    showDeletionModal,
    showShareCodeModal,
    showMoveModal,
    handleDelete,
    handleView,
    handleDuplicate,
    handleOpenPopper,
    handleClosePopper,
    handleImportButton,
    handlePublish,
    handleUnpublish,
    handleOpenDeletionModal,
    handleCloseDeletionModal,
    handleOpenShareModal,
    handleCloseShareModal,
    handleMoveOut,
    handleCloseMoveModal,
    handleOpenMoveModal
  };
};
