import { useState } from "react";

export const useHelpAndFeedback = () => {
  const [searchValue, setSearchValue] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSerchValueChange = (value: string) => setSearchValue(value);
  return {
    loading,
    searchValue,
    handleSerchValueChange,
  };
};
