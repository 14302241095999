import { Navigate } from "react-router-dom";
import { Outlet, useLocation } from "react-router";
export const PrivateRoutes = () => {
  const location = useLocation();
  const token = localStorage.getItem("accessToken");

  return !!token ? (
    <Outlet />
  ) : (
    <Navigate to="/signIn" state={{ from: location }} />
  );
};
