import { IItem } from "../../../../constants";
import { Input, SoundDropdown } from "~components";
import styles from "./styles.module.scss";
import { useMedCheck } from "./useMedCheck";
import clsx from "clsx";
import { Cross } from "~icons";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const MedCheckSettings = ({ item, itemIndex }: IProps) => {
  const {
    inputValue,
    inputRef,
    fieldRef,
    focus,
    onChangeInputValue,
    handleActiveFocus,
    handleDeleteItem,
  } = useMedCheck({
    item,
    itemIndex,
  });
  return (
    <div className={styles.medcheckSettings}>
      <div className={styles.medcheckSettings__underline} />

      <p className={styles.medcheckSettings__label}>Medication Names</p>
      <div
        onClick={handleActiveFocus}
        ref={fieldRef}
        className={clsx(styles.medcheckSettings__field, {
          [styles.active]: focus,
        })}
      >
        {item.data.map((item: string, index: number) => (
          <div
            className={styles.medcheckSettings__fieldItem}
            key={`${item} - ${index}`}
          >
            {item}

            <div onClick={() => handleDeleteItem(index)}>
              <Cross fill="#616A85" />
            </div>
          </div>
        ))}
        <input
          className={clsx(styles.medcheckSettings__fieldInput, {
            [styles.active]: focus,
            [styles.full]: item.data.length === 0,
          })}
          placeholder={
            item.data.length === 0
              ? "Enter medication names, separated by commas..."
              : ""
          }
          ref={inputRef}
          onChange={onChangeInputValue}
          value={inputValue}
        />
      </div>
    </div>
  );
};
