import { useLazyQuery, useQuery, useSubscription } from "@apollo/client";
import { useEffect } from "react";
import { GET_CHOOSED_ORGANIZATIOM, choosedOrganizationVar } from "~localCache";
import {
  GET_ALL_ORGANIZATIONS_MEMBERS_COUNT,
  GET_MY_ORGANIZATIONS,
} from "~queries";
import { MEMBERS_COUNT_SUBSCRIPTION } from "~subscriptions";

export const useMembersSubscription = () => {
  const [getMembersCount] = useLazyQuery(GET_ALL_ORGANIZATIONS_MEMBERS_COUNT);

  const [updateMyOrganizations] = useLazyQuery(GET_MY_ORGANIZATIONS, {
    fetchPolicy: "network-only",
  });

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const { data } = useSubscription(MEMBERS_COUNT_SUBSCRIPTION, {
    variables: { organizationId: choosedOrganization?.id },
  });

  const updateCachedOrganization = (data: any) => {
    choosedOrganizationVar({
      ...choosedOrganization,
      counters: {
        ...choosedOrganization.counter,
        ...data.changeMembersCounters,
      },
    });
  };

  useEffect(() => {
    if (!!data) {
      updateCachedOrganization(data);
      updateMyOrganizations();
      getMembersCount({
        variables: { input: { organizationId: choosedOrganization?.id } },
        fetchPolicy: "network-only",
      });
    }
  }, [JSON.stringify(data)]);
};
