import clsx from "clsx";
import { FC, ReactNode } from "react";
import { DownArrow, LeftArrow } from "~icons";
import styles from "./styles.module.scss";
import { useDashboardLayout } from "./useDashboardLayout";

interface IProps {
  title: string;
  children: ReactNode;
  customLeftComponent?: ReactNode;
  rightItem?: ReactNode;
  contentClassName?: string;
}

export const DashboardLayout: FC<IProps> = ({
  title,
  children,
  rightItem,
  contentClassName,
  customLeftComponent,
}) => {
  const { choosedOrganization, handleOpenLeftPanel } = useDashboardLayout();

  return (
    <div className={clsx(styles.dashboardLayout)}>
      <div
        className={styles.dashboardLayout__organization}
        onClick={handleOpenLeftPanel}
      >
        <div className={styles.dashboardLayout__organizationArrow}>
          <DownArrow fill="#979DB1" />
        </div>

        {!!choosedOrganization ? (
          <>
            <div className={styles.dashboardLayout__organizationLogo}>
              {!!choosedOrganization.picture && (
                <img src={choosedOrganization.picture} />
              )}
            </div>
            <div>
              <p className={styles.dashboardLayout__organizationTitle}>
                {choosedOrganization.name}
              </p>
              <p className={styles.dashboardLayout__organizationSubtitle}>
                {choosedOrganization._count.members} members
              </p>
            </div>
          </>
        ) : (
          <p className={styles.dashboardLayout__organizationEmpty}>
            No organizations
          </p>
        )}
      </div>
      <div className={styles.dashboardLayout__header}>
        {!!customLeftComponent ? (
          customLeftComponent
        ) : (
          <p className={styles.dashboardLayout__title}>{title}</p>
        )}
        {!!rightItem && rightItem}
      </div>
      <div className={clsx(styles.dashboardLayout__content, contentClassName)}>
        {children}
      </div>
    </div>
  );
};
