import { StrictModifierNames, Modifier } from "react-popper";
import { Options, createPopper } from "@popperjs/core";
import { usePDF } from "../../usePDF";
import { DeleteModal, ShareCodeModal } from "../../modals";
import { PopperPopup } from "~components";
import { PublishingStatus, IPdf } from "~models";
import { FC } from "react";
import clsx from "clsx";
import {
  CopyTo,
  DotsVertical,
  Duplicate,
  FileNone,
  Refresh,
  Share,
  TrashAlt,
} from "~icons";

import styles from "./styles.module.scss";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";

interface IProps {
  pdf: IPdf;
  index: number;
  updatePDFStatus: (status: PublishingStatus, index: number) => void;
  showTableLoader?: () => void;
  updateTableData?: () => void;
}

export const PDFItemSquare: FC<IProps> = ({
  pdf,
  index,
  updatePDFStatus,
  showTableLoader,
  updateTableData,
}: IProps) => {
  const {
    modalRef,
    myRole,
    showPopper,
    showDeletionModal,
    showShareCodeModal,
    handleDelete,
    handleDuplicate,
    handlePublish,
    handleUnpublish,
    handleOpenPopper,
    handleClosePopper,
    handleOpenDeletionModal,
    handleCloseDeletionModal,
    handleOpenShareModal,
    handleCloseShareModal,
  } = usePDF({
    pdf,
    index,
    updatePDFStatus,
    showTableLoader,
    updateTableData,
  });

  const isDark = useReactiveVar(darkTheme);
  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-end",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  return (
    <div>
      <div className={styles.pdfItem}>
        <div className={isDark ? clsx(styles.content, styles.contentDark) : styles.content}>
          <div className={styles.content__header}>
            <div className={styles.content__headerPdf}>PDF</div>

            <PopperPopup
              showPopper={showPopper}
              onClosePopper={handleClosePopper}
              onOpenPopper={handleOpenPopper}
              options={popperOptions}
              trigger={<DotsVertical fill={isDark ? "white" : "#0B0F1C"} />}
              popup={
                <div ref={modalRef} className={clsx(styles.pdfItem__modal, {})}>
                  <div
                    onClick={handleDuplicate}
                    className={clsx(styles.pdfItem__modalItem, styles.margin)}
                  >
                    <Duplicate /> Duplicate
                  </div>
                  <div className={styles.pdfItem__modalItem}>
                    <CopyTo /> Copy to
                  </div>

                  <div className={styles.pdfItem__modalUnderline} />
                  {pdf.status !== PublishingStatus.Published && (
                    <div
                      onClick={handlePublish}
                      className={styles.pdfItem__modalItem}
                    >
                      <Refresh width={20} height={20} fill={isDark ? "white" : "#0B0F1C"} />{" "}
                      {pdf.status === PublishingStatus.Unpublished
                        ? "Publish"
                        : "Publish latest changes"}
                    </div>
                  )}

                  {pdf.status === PublishingStatus.Published && (
                    <div
                      onClick={handleUnpublish}
                      className={styles.pdfItem__modalItem}
                    >
                      <FileNone /> Unpublish
                    </div>
                  )}

                  <div className={styles.pdfItem__modalUnderline} />

                  <div
                    onClick={handleOpenShareModal}
                    className={styles.pdfItem__modalItem}
                  >
                    <Share /> Share PDF
                  </div>
                  {/* <div
                    className={styles.pdfItem__modalItem}
                    onClick={handleDownloadPdf}
                  >
                    <FileDownload /> Generate PDF
                  </div> */}
                  {myRole !== "User" && (
                    <div
                      onClick={handleOpenDeletionModal}
                      className={styles.pdfItem__modalItem}
                    >
                      <TrashAlt fill={isDark ? "white" : "#0B0F1C"} /> Delete
                    </div>
                  )}
                </div>
              }
            />
          </div>
          <p className={styles.content__title}>{pdf.title}</p>
          <div
            className={clsx(styles.content__footer, {
              [styles.yellow]:
                pdf.status === PublishingStatus.UnpublishedChanges,
              [styles.red]: pdf.status === PublishingStatus.Unpublished,
              [styles.green]: pdf.status === PublishingStatus.Published,
            })}
          >
            <div className={styles.content__footerDot} />
            <p>
              {pdf.status === PublishingStatus.UnpublishedChanges
                ? "Unpublished Changes"
                : pdf.status}
            </p>

            {pdf.status !== PublishingStatus.Published && (
              <div
                className={styles.content__footerRefresh}
                onClick={handlePublish}
              >
                <Refresh fill="#29B473" />
              </div>
            )}
          </div>
        </div>
      </div>

      <DeleteModal
        isOpen={showDeletionModal}
        itemName="pdf"
        onCloseModal={handleCloseDeletionModal}
        handleDelete={handleDelete}
      />

      <ShareCodeModal
        isOpen={showShareCodeModal}
        id={pdf.id}
        type="Pdf"
        onCloseModal={handleCloseShareModal}
      />
    </div>
  );
};
