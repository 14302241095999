import { IItem } from "~models";
import { useChecklist } from "../../../../context";
import { cloneDeep } from "@apollo/client/utilities";

interface IProps {
  item: IItem;
  progressItem: any;
  isDisable: boolean;
}

interface ITimeMarkerItem {
  buttonIndex: number;
  clickNumber: number;
  time: Date;
}

export const useTimeMarker = ({ item, progressItem, isDisable }: IProps) => {
  const { progress, setProgress } = useChecklist();

  const itemIndex = progress.data.items.findIndex(
    (elem: { id: number }) => elem.id === item.id
  );

  const isTimeNoAdded = !progressItem?.times
    ? true
    : progressItem?.times.length === 0;

  const handleMarkTime = (buttonIndex: number) => {
    if (isDisable) return;
    let time = new Date();

    setProgress((prev) => {
      let state = { ...prev };
      if (!state.data.items[itemIndex]?.times) {
        state.data.items[itemIndex].times = [
          {
            buttonIndex,
            clickNumber: 1,
            time,
          },
        ];
      } else {
        let data: ITimeMarkerItem[] = structuredClone(
          state.data.items[itemIndex]?.times
        );
        let lastItem = cloneDeep(data)
          .reverse()
          .find((item: ITimeMarkerItem) => item.buttonIndex === buttonIndex);
        data.push({
          buttonIndex,
          clickNumber: !!lastItem ? lastItem.clickNumber + 1 : 1,
          time,
        });
        state.data.items[itemIndex].times = data;
      }
      return state;
    });
  };

  const handleResetTime = () => {
    if (isDisable) return;
    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].times = [];
      return state;
    });
  };

  const handleRemoveLastStep = () => {
    if (isTimeNoAdded || isDisable) return;
    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].times.pop();
      return state;
    });
  };

  return {
    isTimeNoAdded,
    handleMarkTime,
    handleResetTime,
    handleRemoveLastStep,
  };
};
