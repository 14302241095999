import { InvationItem } from "./components/InvationItem";
import { useMyRequests } from "./useMyInvitations";
import { PageHeader, SearchInput, Table } from "~components";
import { IUserInvitation } from "~models";

import styles from "./styles.module.scss";
import { useHomePage } from "../AllOrganizations/useHomePage";
import { ActiveListsModule } from "../../../../navigations/Dashboard/LeftPanel/modules";

export const MyInvitation = () => {
  const {
    myOrganizationRequests,
    searchValue,
    setSearchValue,
    handleButtonClick,
  } = useMyRequests();
  const { pageHeader } = useHomePage();

  return (
    <>
      <div className={styles.invitations__search}>
        <SearchInput
          placeholder="Search for companies"
          value={searchValue}
          onChange={setSearchValue}
        />
        <ActiveListsModule noLabel />
      </div>

      <div style={{ marginBottom: 20 }}>
        <PageHeader data={pageHeader} />
      </div>
      <Table
        header={[
          {
            header: "Organization",
            className: styles.invitations__organizationHeader,
          },
          { header: "Members", className: styles.invitations__membersHeader },
          {
            header: "Invitation sent",
            className: styles.invitations__dateHeader,
          },
          { header: "Role", className: styles.invitations__roleHeader },
        ]}
        data={myOrganizationRequests}
        renderTableItem={(item: IUserInvitation, index) => (
          <InvationItem
            item={item}
            index={index}
            handleButtonClick={handleButtonClick}
          />
        )}
      />
    </>
  );
};
