import { useBuilder } from "../../../context";
import { IActiveItem, IItem, defaultItem } from "../../../constants";
import { ChecklistItemType } from "~models";
import { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "~hooks";
import { useMutation } from "@apollo/client";
import {
  CHANGE_ITEM_ORDERS,
  DUPLICATE_ITEM,
  REMOVE_ITEM,
  UPDATE_ITEM,
} from "~mutations";

interface IProps {
  item: IItem;
  sectionIndex: number;
  sectionId: number;
  tabIndex?: number;
  index: number;
}

export const useItem = ({
  item,
  sectionIndex,
  sectionId,
  tabIndex,
  index,
}: IProps) => {
  const popupContentRef = useRef<HTMLDivElement>(null);

  const {
    setList,
    activeTabIndex,
    isDragging,
    setActiveItem,
    activeItem,
    list,
    error,
    pdfFormat,
  } = useBuilder();

  const [updateItem] = useMutation(UPDATE_ITEM);
  const [duplicateItem] = useMutation(DUPLICATE_ITEM);
  const [removeItem] = useMutation(REMOVE_ITEM);

  const [changeItemsOrder] = useMutation(CHANGE_ITEM_ORDERS);

  const [showPopup, setShowPopup] = useState(false);

  const [prevSafeSection, setPrevSafeSection] = useState<string>(
    JSON.stringify(item)
  );

  const showError = error.itemIds.findIndex((id) => id === item.id) > -1;

  const [collapsed, setCollapsed] = useState(false);

  const itemIndex = pdfFormat
    ? 0
    : list.tabs[activeTabIndex].sections[sectionIndex].items.findIndex(
        (elem) => elem.id === item.id
      );

  const isItemActive =
    activeItem?.id === item.id && activeItem.type === "item" && !pdfFormat;

  const enableActiveItem = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (!!activeItem && activeItem.id === item.id && activeItem.type === "item")
      return;
    setActiveItem({
      type: "item",
      id: item.id,
      sectionIndex: sectionIndex,
      sectionId,
    });
  };

  const getDefaultData = (type: ChecklistItemType) => {
    switch (type) {
      case ChecklistItemType.Stopwatch:
        return { record: false, afterEachOther: false, alarms: [] };
      case ChecklistItemType.Timer:
        return { sound: "sound1", record: false };
      case ChecklistItemType.Metronome:
        return { type: "CPR" };
      case ChecklistItemType.BurnedAreas:
        return { type: "Adult" };
      case ChecklistItemType.Calculator:
        return { inputs: [{ name: "" }], outputs: [{ name: "", logic: "" }] };
      case ChecklistItemType.MedCheck:
        return [];
      case ChecklistItemType.TextBox:
        return { model: "" };
      case ChecklistItemType.TableData:
        return { template: "", tables: [{ model: "", title: "" }] };
      case ChecklistItemType.Counter:
        return {
          allowNegative: false,
          record: false,
        };
      case ChecklistItemType.TimeMarker:
        return {
          buttons: [
            {
              title: "Mark Time",
            },
          ],
        };
      case ChecklistItemType.DropdownSelector:
        return { multiple: false, selections: ["", ""] };
      case ChecklistItemType.Algorithm:
        return {
          assessments: [
            {
              title: "",
              description: "",
              findings: [
                { name: "", score: "0" },
                { name: "", score: "0" },
              ],
            },
          ],
          results: [{ from: "", to: "", result: "" }],
        };

      default:
        return null;
    }
  };

  const onChangeType = (type: ChecklistItemType) => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[sectionIndex].items[itemIndex].type =
        type;
      state.tabs[activeTabIndex].sections[sectionIndex].items[itemIndex].data =
        getDefaultData(type);
      return state;
    });
  };

  const handleChangeDescription = (value: string) => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[sectionIndex].items[
        itemIndex
      ].description = value;
      return state;
    });
  };

  const handleOpenPopup = () => !pdfFormat && setShowPopup(true);
  const handleClosePopup = () => !pdfFormat && setShowPopup(false);
  useOutsideClick(popupContentRef, handleClosePopup, "mouseup");

  const deleteItem = () => {
    setShowPopup(false);
    if (
      activeItem?.id === item.id &&
      activeItem.sectionIndex === sectionIndex &&
      activeItem.type === "item"
    ) {
      setActiveItem(null);
      removeItem({ variables: { id: item.id } }).catch(() => {});

      setList((prev) => {
        let state = { ...prev };
        state.tabs[activeTabIndex].sections[sectionIndex].items.splice(
          itemIndex,
          1
        );
        return state;
      });
    }
  };

  const addFakeItem = () => {
    if (!activeItem) return;
    let fakeItem = {
      ...defaultItem,
      tabSectionId: activeItem?.sectionId,
      id: 0,
      type: null,
    };
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[sectionIndex].items.splice(
        itemIndex + 1,
        0,
        fakeItem
      );
      return state;
    });
  };

  const toggleCollapsed = () => setCollapsed(!collapsed);

  const addRealItem = (realItem: IItem) => {
    if (!activeItem) return;

    let input = {
      checkbox: item.checkbox,
      collapsable: item.collapsable,
      data: JSON.stringify(item.data),
      description: item.description,
      id: realItem.id,
      startCollapsable: item.startCollapsable,
      tabSectionId: item.tabSectionId,
      type: item.type,
    };

    updateItem({
      variables: {
        input,
      },
    });

    setList((prev) => {
      let state = { ...prev };
      let fakeItemIndex = state.tabs[activeTabIndex].sections[
        sectionIndex
      ].items.findIndex((item: IItem) => item.id === 0);
      if (fakeItemIndex > -1) {
        state.tabs[activeTabIndex].sections[sectionIndex].items[fakeItemIndex] =
          { ...item, ...realItem };
      }
      return state;
    });

    setActiveItem({ type: "item", id: realItem.id, sectionId, sectionIndex });
  };

  const addItem = () => {
    handleClosePopup();
    addFakeItem();

    let input = {
      id: item.id,
      type: item.type,
      description: item.description,
      checkbox: item.checkbox,
    };
    if (!!item.data)
      Object.assign(input, {
        data:
          typeof item.data === "string" ? item.data : JSON.stringify(item.data),
      });

    updateItem({
      variables: {
        input,
      },
    }).then(async () => {
      duplicateItem({ variables: { id: item.id } }).then(async (resp) => {
        addRealItem(resp.data.duplicateSectionItem);
      });
    });
  };

  const onSaveChanges = () => {
    let input = {
      id: item.id,
      type: item.type,
      // data: JSON.stringify(item.data),
      description: item.description,
      checkbox: item.checkbox,
      collapsable: item.collapsable,
      startCollapsable: item.startCollapsable,
    };
    if (!!item.data)
      Object.assign(input, {
        data:
          typeof item.data === "string" ? item.data : JSON.stringify(item.data),
      });

    if (prevSafeSection !== JSON.stringify(item)) {
      updateItem({
        variables: {
          input,
        },
      });
      setPrevSafeSection(JSON.stringify(item));
    }
  };

  const updateItemsOrderOnServer = async (ids: number[]) => {
    await changeItemsOrder({
      variables: { input: { ids } },
    });
  };

  const updateItemSection = (
    id: number,
    tabSectionId: number,
    ids: number[]
  ) => {
    updateItem({
      variables: {
        input: { id, tabSectionId },
      },
    }).then(async () => await updateItemsOrderOnServer(ids));
  };

  const moveItemUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let localActiveItem: IActiveItem = {
      type: "item",
      id: item.id,
      sectionIndex: sectionIndex,
      sectionId,
    };
    if (!activeItem) {
      e.stopPropagation();
      setActiveItem(localActiveItem);
    }

    if (index > 0) {
      setList((prev) => {
        let state = structuredClone(prev);
        let prevItem = structuredClone(
          state.tabs[activeTabIndex].sections[localActiveItem.sectionIndex]
            .items[index - 1]
        );

        state.tabs[activeTabIndex].sections[
          localActiveItem?.sectionIndex
        ].items[index - 1] = item;
        state.tabs[activeTabIndex].sections[
          localActiveItem?.sectionIndex
        ].items[index] = prevItem;

        updateItemsOrderOnServer(
          state.tabs[activeTabIndex].sections[
            localActiveItem?.sectionIndex
          ].items.map((item: IItem) => item.id)
        );
        return state;
      });
    } else if (localActiveItem.sectionIndex > 0) {
      const prevSectionIndex = localActiveItem.sectionIndex - 1;
      setList((prev) => {
        let state = structuredClone(prev);
        state.tabs[activeTabIndex].sections[prevSectionIndex].items.push(item);

        updateItemSection(
          item.id,
          list.tabs[activeTabIndex].sections[prevSectionIndex].id,
          state.tabs[activeTabIndex].sections[prevSectionIndex].items.map(
            (item: IItem) => item.id
          )
        );

        let items = [
          ...state.tabs[activeTabIndex].sections[localActiveItem?.sectionIndex]
            .items,
        ];
        items.splice(index, 1);
        state.tabs[activeTabIndex].sections[
          localActiveItem?.sectionIndex
        ].items = items;
        return state;
      });

      setActiveItem({
        type: "item",
        id: item.id,
        sectionIndex: prevSectionIndex,
        sectionId: list.tabs[activeTabIndex].sections[prevSectionIndex].id,
      });
    }
  };

  const moveItemDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let localActiveItem: IActiveItem = {
      type: "item",
      id: item.id,
      sectionIndex: sectionIndex,
      sectionId,
    };
    if (!activeItem) {
      e.stopPropagation();
      setActiveItem(localActiveItem);
    }

    const isItemLastInSection =
      list.tabs[activeTabIndex].sections[localActiveItem.sectionIndex].items
        .length -
        1 ===
      index;
    const isSectionLastInTab =
      list.tabs[activeTabIndex].sections.length - 1 ===
      localActiveItem.sectionIndex;

    if (!isItemLastInSection) {
      setList((prev) => {
        let state = structuredClone(prev);
        let nextItem = structuredClone(
          state.tabs[activeTabIndex].sections[localActiveItem?.sectionIndex]
            .items[index + 1]
        );

        state.tabs[activeTabIndex].sections[
          localActiveItem?.sectionIndex
        ].items[index + 1] = item;
        state.tabs[activeTabIndex].sections[
          localActiveItem?.sectionIndex
        ].items[index] = nextItem;

        updateItemsOrderOnServer(
          state.tabs[activeTabIndex].sections[
            localActiveItem?.sectionIndex
          ].items.map((item: IItem) => item.id)
        );
        return state;
      });
    } else if (isItemLastInSection && !isSectionLastInTab) {
      const nextSectionIndex = localActiveItem.sectionIndex + 1;
      setList((prev) => {
        let state = structuredClone(prev);
        state.tabs[activeTabIndex].sections[nextSectionIndex].items.unshift(
          item
        );

        updateItemSection(
          item.id,
          list.tabs[activeTabIndex].sections[nextSectionIndex].id,
          state.tabs[activeTabIndex].sections[nextSectionIndex].items.map(
            (item: IItem) => item.id
          )
        );

        let items = [
          ...state.tabs[activeTabIndex].sections[localActiveItem?.sectionIndex]
            .items,
        ];
        items.splice(items.length - 1, 1);
        state.tabs[activeTabIndex].sections[
          localActiveItem?.sectionIndex
        ].items = items;
        return state;
      });
      setActiveItem({
        type: "item",
        id: item.id,
        sectionIndex: nextSectionIndex,
        sectionId: list.tabs[activeTabIndex].sections[nextSectionIndex].id,
      });
    }
  };

  useEffect(() => {
    onSaveChanges();
  }, [JSON.stringify(activeItem), isDragging]);

  return {
    isItemActive,
    popupContentRef,
    showPopup,
    showError,
    collapsed,
    pdfFormat,
    addItem,
    handleOpenPopup,
    onChangeType,
    handleChangeDescription,
    enableActiveItem,
    deleteItem,
    toggleCollapsed,
    moveItemUp,
    moveItemDown,
  };
};
