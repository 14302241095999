import { GET_CHOOSED_ORGANIZATIOM } from "~localCache";
import { useLazyQuery, useQuery } from "@apollo/client";
import { IPageHeader, ISubscription } from "~models";
import { useEffect, useState } from "react";
import {
  GET_ALL_ORGANIZATIONS_MEMBERS_COUNT,
  GET_BILLING_PAGE_INFO,
  GET_ORGANIZATION_REQUESTS,
} from "~queries";

export const useMembers = () => {
  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);
  const [getData] = useLazyQuery(GET_BILLING_PAGE_INFO);

  const { data } = useQuery(GET_ALL_ORGANIZATIONS_MEMBERS_COUNT, {
    variables: { input: { organizationId: choosedOrganization.id } },
  });

  const { data: requestsData } = useQuery(GET_ORGANIZATION_REQUESTS, {
    variables: {
      input: { organizationId: choosedOrganization?.id },
    },
  });

  const [showInviteModal, setShowInviteModal] = useState(false);

  const [subscription, setSubscription] = useState<ISubscription | null>(null);

  const handleOpenInviteModal = () => setShowInviteModal(true);
  const handleCloseInviteModal = () => setShowInviteModal(false);

  const updateData = () => {

    getData({
      variables: {
        subscriptionInput: choosedOrganization.id,
        cardsInut: choosedOrganization.id,
      },
      fetchPolicy: "network-only",
    }).then((resp) => {
      setSubscription(resp.data.retrieveSubscription);
      console.log(resp.data, 'data')
    });
  };


  useEffect(() => {
    updateData();
  }, [choosedOrganization.id]);

  let pageHeader: IPageHeader[] = [
    {
      title: "All",
      type: "all",
      counter: data?.organizationMembersCount,
      to: "/dashboard/members/all",
    },
  ];

  const showRequestsTab = !!choosedOrganization?.members
    ? choosedOrganization.members[0].role !== "User"
    : false;

  if (showRequestsTab) {
    pageHeader.push({
      title: "User Requests",
      type: "requests",
      color: "red",
      counter: requestsData?.organizationRequests?.length,
      to: "/dashboard/members/requests",
    });
  }

  const isDisableInviteButton = choosedOrganization.status !== "active";

  return {
    pageHeader,
    showInviteModal,
    choosedOrganization,
    isDisableInviteButton,
    handleOpenInviteModal,
    handleCloseInviteModal,
    subscription,
    updateData
  };
};
