import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  GET_ALL_ACTIVE_PROGRESSES,
  GET_CHECKLIST_AND_CREATED_PROGRESS_DATA,
  GET_CHECKLIST_AND_PROGRESS_DATA,
} from "~queries";
import { useChecklist as useChecklistHoc } from "./context";
import {
  COMPLETE_PROGRESS,
  REMOVE_PROGRESS,
  RESET_PROGRESS,
  UPDATE_PROGRESS,
} from "~mutations";
import {
  GET_CHOOSED_ORGANIZATIOM,
  activeMetronomeVar,
  alertVar,
} from "~localCache";
import { useAudioPlayer } from "react-use-audio-player";
import { IProgress } from "~models";
import { goBack as goBackHelper } from "~helpers";

export const useChecklist = () => {
  const { stop } = useAudioPlayer();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const pdf = new URLSearchParams(location.search).get("pdf");
  const preview = new URLSearchParams(location.search).get("preview");
  const progressId = new URLSearchParams(location.search).get("progressId");

  const activeMetronome = activeMetronomeVar();

  const {
    list,
    setProgress,
    pdfFormat,
    progress,
    setList,
    completed,
    activeTabIndex,
    setIsPdfFormat,
    showPdfTab,
  } = useChecklistHoc();

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [queryData] = useLazyQuery(GET_CHECKLIST_AND_PROGRESS_DATA);
  const [queryDataForPDF] = useLazyQuery(
    GET_CHECKLIST_AND_CREATED_PROGRESS_DATA
  );

  const [updateActiveProgress] = useLazyQuery(GET_ALL_ACTIVE_PROGRESSES);
  const [removeProgress] = useMutation(REMOVE_PROGRESS);
  const [updateProgress] = useMutation(UPDATE_PROGRESS);
  const [completeProgress] = useMutation(COMPLETE_PROGRESS);

  const [resetProgress] = useMutation(RESET_PROGRESS);

  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [checklistLoading, setChecklistLoading] = useState(true);
  const [showCompleteLoader, setShowCompleteLoader] = useState(false);
  const [showRemoveLoader, setShowRemoveLoader] = useState(false);
  const [showResetLoader, setShowResetLoader] = useState(false);

  const handleOpenDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  let destination =
    !!location?.state?.from && location.state.from === "checklist"
      ? "/dashboard/home"
      : undefined;

  const handleDeleteButton = () => {
    setShowRemoveLoader(true);
    stopActiveMetronome();
    removeProgress({ variables: { id: progress.id } }).then(async () => {
      await updateActiveProgress({
        variables: { organizationId: choosedOrganization?.id },
        fetchPolicy: "network-only",
      }).then(() =>
        goBackHelper({
          navigate,
          defaultDestination: "/dashboard/home",
          destination,
        })
      );
    });
  };

  const getData = (progressId: number, checklistId: number) => {
    setChecklistLoading(true);
    queryData({
      variables: { progressId, checklistId },
      fetchPolicy: "network-only",
    })
      .then((resp) => {
        if (resp.data.checklist.id !== resp.data.getMyProgress.checklistId) {
          alertVar({ type: "red", text: "Something was wrong." });
          goBackHelper({
            navigate,
            defaultDestination: "/dashboard/home",
            destination,
          });
          return;
        }
        updateActiveProgress({
          variables: { organizationId: choosedOrganization?.id },
          fetchPolicy: "network-only",
        });
        setProgress({
          ...structuredClone(resp.data.getMyProgress),
          data: JSON.parse(resp.data.getMyProgress.data),
        });
        setList(structuredClone(resp.data.checklist));
        setChecklistLoading(false);
      })
      .catch(() => {
        alertVar({ type: "red", text: "Something was wrong." });
        let destination =
          !!location?.state?.from && location.state.from === "checklist"
            ? "/dashboard/home"
            : undefined;
        goBackHelper({
          navigate,
          defaultDestination: "/dashboard/home",
          destination,
        });
      });
  };

  const getDataForPreview = (progressId: number, checklistId: number) => {
    setChecklistLoading(true);
    pdf === "true" && setIsPdfFormat(true);
    queryDataForPDF({
      variables: { progressId, checklistId },
      fetchPolicy: "network-only",
    })
      .then((resp) => {
        setProgress({
          ...structuredClone(resp.data.progress),
          data: JSON.parse(resp.data.progress.data),
        });
        setList(structuredClone(resp.data.checklist));
        setChecklistLoading(false);
      })
      .catch(() => {
        alertVar({ type: "red", text: "Something was wrong." });
        goBackHelper({
          navigate,
          defaultDestination: "/dashboard/home",
          destination,
        });
      });
  };

  useEffect(() => {
    if (!!params?.id && progressId) {
      if (pdf === "true" || preview === "true") {
        getDataForPreview(+progressId, +params.id);
      } else {
        getData(+progressId, +params.id);
      }
    }
  }, [progressId]);

  const saveLastChanges = () => {
    updateProgress({
      variables: {
        updateProgressInput: {
          id: progress.id,
          data: JSON.stringify(progress.data),
          updatedAt: new Date(),
        },
      },
    });
  };

  const handleBackArrowClick = () => {
    saveLastChanges();
    goBackHelper({
      navigate,
      defaultDestination: "/dashboard/home",
      destination,
    });
  };

  const goBack = () => {
    updateProgress({
      variables: {
        updateProgressInput: {
          id: progress.id,
          data: JSON.stringify(progress.data),
          updatedAt: new Date(),
        },
      },
    })
      .then(() => {
        goBackHelper({
          navigate,
          defaultDestination: "/dashboard/home",
          destination,
        });
      })
      .catch((err) => console.log("ERR"));
  };

  const stopActiveItems = async (timestamp: number) => {
    let updatedProgress: IProgress = structuredClone(progress);
    updatedProgress.data.items = updatedProgress.data.items.map((item) => {
      if (item.type === "Stopwatch" && !!item?.firstStart && !item.paused) {
        let seconds = !!item?.seconds ? item?.seconds : 0;
        if (!!item?.lastStart) {
          seconds = seconds + (timestamp - item.lastStart) / 1000;
        } else {
          seconds = seconds + (timestamp - item.firstStart) / 1000;
        }
        return { ...item, paused: true, lastPause: timestamp, seconds };
      } else if (item.type === "Timer" && !!item?.firstStart && !item.paused) {
        let difference = 0;
        if (!!item?.lastStart) {
          difference = (timestamp - +item?.lastStart) / 1000;
        } else {
          difference = (timestamp - +item?.firstStart) / 1000;
        }
        let leftSeconds = !!item.leftSeconds
          ? +item.leftSeconds - difference
          : item.totalSeconds - difference;

        return { ...item, paused: true, lastPause: timestamp, leftSeconds };
      } else if (
        item.type === "Metronome" &&
        activeMetronome?.itemId === item.id
      ) {
        stop();
        activeMetronomeVar(null);
        return item;
      } else return item;
    });
    setProgress(updatedProgress);
    return updatedProgress;
  };

  const stopActiveMetronome = () => {
    progress.data.items.forEach((item, index) => {
      if (item.type === "Metronome" && activeMetronome?.itemId === item.id) {
        stop();
        activeMetronomeVar(null);
      }
    });
  };

  const hideCompleteModal = () => setShowCompleteModal(false);

  const handleCompleteClick = () => {
    setShowCompleteModal(true);
  };

  const handleResetButton = () => {
    setShowResetLoader(true);
    stopActiveMetronome();
    resetProgress({
      variables: {
        resetProgressInput: { id: progress.id, checklistId: list.id },
      },
    }).then((resp) => {
      setProgress({
        ...structuredClone(resp.data.resetProgress),
        data: JSON.parse(resp.data.resetProgress.data),
      });
      setShowResetLoader(false);
    });
  };

  const completeChecklist = async () => {
    setShowCompleteLoader(true);
    let timestamp = new Date().getTime();
    let updatedProgress = await stopActiveItems(timestamp);
    completeProgress({
      variables: {
        completeProgressInput: {
          id: progress.id,
          data: JSON.stringify(updatedProgress.data),
          completed: true,
          updatedAt: new Date(),
        },
      },
    })
      .then(async () => {
        await updateActiveProgress({
          variables: { organizationId: choosedOrganization?.id },
          fetchPolicy: "network-only",
        }).then(() => {
          let destination =
            !!location?.state?.from && location.state.from === "checklist"
              ? "/dashboard/home"
              : undefined;
          goBackHelper({
            navigate,
            defaultDestination: "/dashboard/home",
            destination,
          });
        });
      })
      .catch((err) => console.log(err));
  };

  return {
    showCompleteLoader,
    showRemoveLoader,
    checklistLoading,
    list,
    activeTabIndex,
    completed,
    pdfFormat,
    progress,
    myRole: choosedOrganization.members[0].role,
    showCompleteModal,
    showResetLoader,
    showDeleteModal,
    showPdfTab,
    goBack,
    saveLastChanges,
    handleCompleteClick,
    handleBackArrowClick,
    handleDeleteButton,
    hideCompleteModal,
    completeChecklist,
    handleResetButton,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
  };
};
