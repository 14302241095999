import { FC, ReactNode } from "react";

import { clsx } from "clsx";

import styles from "./styles.module.scss";
import { Loader } from "~icons";

interface IProps {
  text: string;
  showShadow?: boolean;
  textColor?: string;
  icon?: ReactNode;
  backgroundType?: "green" | "gray" | "white" | "none";
  backgroundColor?: string;
  onClick?: () => void;
  loading?: boolean;
  loaderColor?: string;
  disable?: boolean;
  buttonClassName?: string;
}

export const Button: FC<IProps> = ({
  text,
  backgroundType = "none",
  backgroundColor,
  showShadow,
  textColor,
  icon,
  onClick,
  loading,
  loaderColor = "#FFFFFF",
  disable,
  buttonClassName,
}) => {
  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!disable && !loading && !!onClick) {
      e.stopPropagation();
      onClick();
    }
  };

  let inlineStyles = {};
  !!backgroundColor && Object.assign(inlineStyles, { backgroundColor });
  !!textColor && Object.assign(inlineStyles, { color: textColor });

  const getLoaderColor = () => {
    if (!!loaderColor) return loaderColor;
    switch (backgroundType) {
      case "green":
        return "#FFFFFF";
      case "gray":
        return "#0B0F1C";
      case "white":
        return "#0B0F1C";
      default:
        return "#0B0F1C";
    }
  };

  return (
    <div className={styles.buttonContainer}>
      <button
        onClick={handleOnClick}
        type={"button"}
        className={clsx(styles.button, buttonClassName, {
          [styles.shadow]: showShadow,
          [styles.button__green]: backgroundType === "green",
          [styles.button__gray]: backgroundType === "gray",
          [styles.button__white]: backgroundType === "white",
          [styles.disable]: disable,
        })}
        style={inlineStyles}
      >
        {loading && (
          <div className={styles.buttonContainer__loader}>
            <Loader fill={getLoaderColor()} />
          </div>
        )}
        <div
          className={clsx(styles.centeringBlock, loading && styles.hiddenBlock)}
        >
          {icon && icon}
        </div>
        <div className={clsx(loading && styles.hiddenBlock)}>{text}</div>
      </button>
    </div>
  );
};
