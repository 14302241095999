import { IData, IError } from "../contants/models";
import { regex } from "~constants";

export const checkDataErrors = (data: IData): IError | null => {
  if (!data.email) {
    return { email: "Email field is requiered" };
  } else if (!regex.email.test(data.email)) {
    return { email: "Invalid email" };
  } else if (!data.password) {
    return { password: "Password field is requiered" };
  } else if (data.password.length < 8) {
    return { password: "Incorrect password" };
  } else return null;
};

export const isDisabled = (data: IData) =>
  data.email.length < 4 || data.password.length < 4;
