import { AddCardModal } from "./AddCardModal";
import { CreateOrganization } from "~blocks";
import { Button, Modal } from "~components";
import { useBilling } from "./useBilling";
import {
  AmericanEx,
  Jcb,
  Loader,
  Mastercard,
  Multiplication,
  PlusSymbol,
  Visa,
} from "~icons";

import styles from "./styles.module.scss";
import { AddUsersModal } from "./modals";
import { EnterPayment } from "../../../../blocks/CreateOrganization/modules";
import { useMemo } from "react";
import { SubscriptionType } from "../../../../blocks/CreateOrganization/hooks";

export const Billing = () => {
  const {
    cards,
    showLoader,
    subscription,
    showAddCardModal,
    showAddUserModal,
    choosedOrganization,
    showCreateOrganizationModal,
    updateData,
    updateCards,
    handleAddModal,
    handleChangeCardButton,
    handleAddPaymentButton,
    handleCloseAddCardModal,
    handleOpenAddUserModal,
    handleCloseAddUserModal,
    handleCloseCreateOrganizationModal,
  } = useBilling();

  const allMembersCount = useMemo(() => {
    return choosedOrganization.counters.membersLeft +
      choosedOrganization.counters.members;
  }, [choosedOrganization]);
  const allAvaliableMembersCount = choosedOrganization.counters.membersLeft;
  const usedMembersCount = choosedOrganization.counters.members;

  const subscriptionCost = subscription ? subscription?.totalPrice / (allAvaliableMembersCount - 1) : 0;

  return (
    <div className={styles.billing}>
      {showLoader ? (
        <div className={styles.billing__loader}>
          <Loader fill={"#29B473"} />
        </div>
      ) : (
        <>
          {choosedOrganization.status === "active" && subscription && (
            <>
              <div className={styles.widget}>
                <p className={styles.widget__title}>
                  Your current plan: {choosedOrganization.name}
                </p>
                <div className={styles.widget__row}>
                  Renewal date
                  <p className={styles.widget__date}>
                    {subscription.renewalDate}
                  </p>
                </div>
                <div className={styles.widget__row}>
                  <span>{allMembersCount - 1} additional users</span>$
                  {subscription.totalPrice} USD
                </div>
                <div className={styles.widget__underlineDashed} />
                <div className={styles.widget__row}>
                  Available users{" "}
                  <span>
                    {usedMembersCount + 1}/{allMembersCount}
                  </span>
                </div>
                <Button
                  onClick={
                    cards.length === 0
                      ? handleAddPaymentButton
                      : handleOpenAddUserModal
                  }
                  text="Add more users"
                  backgroundType={"white"}
                  showShadow
                  buttonClassName={styles.widget__button}
                />
                <div className={styles.widget__underline} />
                <div className={styles.widget__row}>
                  <p className={styles.widget__cost}>Subscription cost</p>
                  <p className={styles.widget__price}>
                    ${subscription.totalPrice} USD/month
                  </p>
                </div>
              </div>

              {cards.length > 0 && (
                <>
                  <p className={styles.billing__label}>Your Cards</p>
                  {cards.map((item, index) => (
                    <div
                      key={`${item.id} - ${index}`}
                      className={styles.billing__row}
                    >
                      <div className={styles.billing__field}>
                        <span>
                          {item.brand.charAt(0).toUpperCase() +
                            item.brand.slice(1)}{" "}
                          ....{item.last4}
                        </span>
                        {item.brand === "visa" && <Visa />}
                        {item.brand === "mastercard" && <Mastercard />}
                        {item.brand === "jcb" && <Jcb />}
                        {item.brand === "amex" && <AmericanEx />}
                      </div>
                      {item.default ? (
                        <span className={styles.billing__defaultText}>
                          Default Payment Method
                        </span>
                      ) : (
                        <span
                          onClick={() => handleChangeCardButton(index)}
                          className={styles.billing__makeDefault}
                        >
                          Make Default Payment Method
                        </span>
                      )}
                    </div>
                  ))}
                </>
              )}
              <div
                className={styles.billing__button}
                onClick={handleAddPaymentButton}
              >
                <PlusSymbol fill="#29B473" />
                Add more Payment Methods
              </div>
            </>
          )}
          {!subscription?.id && (
            <EnterPayment
              goNext={() => console.log("next")}
              organization={choosedOrganization}
              organizationFromProps={choosedOrganization}
              handleSuccessPayment={updateData}
            />
          )}
        </>
      )}

      {showCreateOrganizationModal && (
        <Modal
          containerClassName={styles.billing__organizationModal}
          onCloseModal={handleCloseCreateOrganizationModal}
          hideCross
        >
          <CreateOrganization
            handleSuccessPayment={updateData}
            organization={choosedOrganization}
            showTopIcons
            onCrossClick={handleCloseCreateOrganizationModal}
            onFinish={handleCloseCreateOrganizationModal}
          />
        </Modal>
      )}

      {showAddCardModal && (
        <AddCardModal
          handleCloseModal={handleCloseAddCardModal}
          organization={choosedOrganization}
          handleFinish={updateCards}
        />
      )}

      <AddUsersModal
        updateData={updateData}
        isOpen={showAddUserModal}
        subscriptionType={subscriptionCost === 50 ? SubscriptionType.YEARLY : SubscriptionType.MONTHLY}
        onClose={handleCloseAddUserModal}
        handleAddUser={handleAddModal}
      />
    </div>
  );
};
