import { IItem } from "../../../../../constants";

interface IProps {
  item: IItem;
}

export const useTimer = ({ item }: IProps) => {
  const toHHMMSS = (secs: string) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    let res = [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");

    if (+secs > 3600) {
      return res;
    } else return "00:" + res;
  };

  const minutes = !!item.data?.minutes ? +item.data.minutes : 0;
  const seconds = !!item.data?.seconds ? +item.data.seconds : 0;

  const generalSeconds = minutes * 60 + seconds;

  const converted = toHHMMSS(`${generalSeconds}`);

  return { converted };
};
