import styles from "./styles.module.scss";

import { IItem } from "~models";
import { useInputText } from "./useInputText";
import { Button, Input, TextArea } from "~components";
import TextareaAutosize from "react-textarea-autosize";
import clsx from "clsx";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const InputText = ({ item, isDisable, progressItem }: IProps) => {
  const {
    textareaRef,
    focused,
    handleActiveFocus,
    handleDisactiveFocus,
    handleInputChange,
  } = useInputText({
    item,
    isDisable,
    progressItem,
  });
  return (
    <div className={styles.inputText} onClick={(e) => e.stopPropagation()}>
      <TextareaAutosize
        value={progressItem?.value}
        onChange={handleInputChange}
        disabled={isDisable}
        ref={textareaRef}
        onFocus={handleActiveFocus}
        onBlur={handleDisactiveFocus}
        placeholder="Enter text"
        className={clsx(styles.inputText__field, { [styles.active]: focused })}
      />
    </div>
  );
};
