import { Tab } from "./components/Tab";
import styles from "./styles.module.scss";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Cross, Download, PlusSymbol } from "~icons";
import { useFooter } from "./useFooter";
import { ITab } from "../../constants";
import clsx from "clsx";

interface IProps {
  tabLoading: boolean;
}

export const Footer = ({ tabLoading }: IProps) => {
  const {
    list,
    showPdfTab,
    onDragEnd,
    addPDFTab,
    removePDFTab,
    getListStyle,
    handleAddTab,
    handleShowPdfUploder,
  } = useFooter();

  return (
    <>
      <div className={styles.footer__underline} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              className={clsx(styles.footer, {})}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {list.isPdfDeleted ? (
                <div onClick={addPDFTab} className={styles.footer__pdfTab}>
                  <div className={styles.footer__pdfTabPlus}>
                    <PlusSymbol fill="#FDBB12" />
                  </div>
                </div>
              ) : (
                <div
                  className={clsx(styles.footer__pdfTab, {
                    [styles.active]: showPdfTab,
                  })}
                  onClick={handleShowPdfUploder}
                >
                  Add PDF Protocol{" "}
                  <div
                    onClick={removePDFTab}
                    className={styles.footer__pdfTabCross}
                  >
                    <Cross fill={showPdfTab ? "#29B473" : "#FDBB12"} />
                  </div>
                </div>
              )}

              {list.tabs.map((item: ITab, index: number) => (
                <Draggable
                  isDragDisabled={tabLoading}
                  key={item.id}
                  draggableId={`${item.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Tab tab={item} />
                    </div>
                  )}
                </Draggable>
              ))}

              <div className={styles.footer__add} onClick={handleAddTab}>
                <PlusSymbol width={"24px"} height={"24px"} fill="#29B473" />
                {/* <Download /> */}
              </div>

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
