import { makeVar } from "@apollo/client";
import { IActiveMetronome, IAlert, IOrganization } from "~models";

export const choosedOrganizationVar = makeVar<IOrganization | null>(null);
export const alertVar = makeVar<IAlert | null>(null);
export const showPanelVar = makeVar<boolean>(false);
export const sortMethod = makeVar<"time" | "alphabet">("alphabet")
export const viewTypes = makeVar<"table" | "grid">("table")
export const darkTheme = makeVar<boolean>(false)
export const activeMetronomeVar = makeVar<IActiveMetronome | null>(null);
