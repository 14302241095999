import { useSubscription } from "@apollo/client";
import { useEffect } from "react";
import { alarmSounds } from "~audio";
import { useAuth } from "~hooks";
import { client } from "~services";
import { NEW_NOTIFICATION } from "~subscriptions";

export const useNewNotification = () => {
  const { user } = useAuth();

  const { data } = useSubscription(NEW_NOTIFICATION, {
    variables: { input: { userId: user?.id } },
    client: client,
  });

  console.log("DATA", data);

  const handleTTS = async (text: string) => {
    const apiKey = "AIzaSyAGy73cnWSp6epnO2fK-BYfZBkgL_dy7K4";
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;
    const data = {
      input: { text },
      voice: { languageCode: "en-US", ssmlGender: "NEUTRAL" },
      audioConfig: { audioEncoding: "MP3" },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const audioData = await response.json();

      const audioBase64 = audioData.audioContent;
      const audioBuffer = Uint8Array.from(atob(audioBase64), (c) =>
        c.charCodeAt(0)
      );
      const audioBlob = new Blob([audioBuffer], { type: "audio/mp3" });
      const audioUrl = URL.createObjectURL(audioBlob);

      const audio = new Audio(audioUrl);
      audio.play();
    } catch (error) {
      console.error("Error fetching TTS data:", error);
    }
  };

  useEffect(() => {
    if (!!data) {
      if (data.newNotification.sound === "test-to-speech") {
        handleTTS(data.newNotification.message);
      } else {
        let audio = new Audio(
          alarmSounds[data.newNotification.sound as keyof typeof alarmSounds]
        );
        audio.play();
      }
    }
  }, [JSON.stringify(data)]);
};
