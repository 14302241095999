import { IItem } from "../../../constants";
import { ChecklistItemType } from "~models";
import styles from "./styles.module.scss";
import { Checkbox } from "~components";
import { TypeSelector } from "./components/TypeSelector";
import { useItem } from "./useItem";
import { Draggable } from "react-beautiful-dnd";
import clsx from "clsx";
import AutosizeInput from "../../../../../../../components/InputAutosize";
import {
  Stopwatch,
  Timer,
  Metronome,
  Calculator,
  MedCheck,
  TextBox,
  TimeMarker,
  CheckboxItem,
  BurnedAreas,
  DropdownData,
} from "./modules";
import {
  DotsVertical,
  DownArrow,
  Duplicate,
  ExclamationCircle,
  LeftArrow,
  Loader,
  TrashAlt,
} from "~icons";
import { InputText } from "./modules/InputText";
import { Counter } from "./modules/Counter";
import { Selector } from "./modules/Selector";
import { Algorithm } from "./modules/Algorithm";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";
import FroalaEditor from "react-froala-wysiwyg";

interface IProps {
  item: IItem;
  sectionIndex: number;
  index: number;
  sectionId: number;
  tabIndex?: number;
}

export const Item = ({
  item,
  sectionIndex,
  index,
  sectionId,
  tabIndex,
}: IProps) => {
  const {
    showPopup,
    isItemActive,
    popupContentRef,
    showError,
    collapsed,
    pdfFormat,
    addItem,
    onChangeType,
    handleChangeDescription,
    enableActiveItem,
    handleOpenPopup,
    deleteItem,
    toggleCollapsed,
    moveItemDown,
    moveItemUp,
  } = useItem({
    item,
    sectionIndex,
    sectionId,
    tabIndex,
    index,
  });

  const isDark = useReactiveVar(darkTheme);

  if (item.id === 0) {
    return (
      <div className={styles.item__loader}>
        <Loader fill="#29B473" />
      </div>
    );
  }

  return (
    <Draggable
      isDragDisabled={pdfFormat}
      draggableId={`${item.id} - item`}
      index={index}
    >
      {(provided, snapshot) => {
        return (
          <div
            onClick={enableActiveItem}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className={
              isDark
                ? clsx(styles.item, styles.itemDark, {
                    [styles.active]: isItemActive,
                    [styles.collapsed]: collapsed,
                  })
                : clsx(styles.item, {
                    [styles.active]: isItemActive,
                    [styles.collapsed]: collapsed,
                  })
            }
          >
            {showError && <ExclamationCircle />}

            <div
              className={
                isDark
                  ? clsx(styles.item__header, styles.item__headerDark)
                  : styles.item__header
              }
            >
              <div className={styles.item__headerLeft}>
                {item.collapsable && (
                  <div
                    onClick={toggleCollapsed}
                    className={clsx(styles.item__headerLeftArrow, {
                      [styles.rotate]: !collapsed,
                    })}
                  >
                    <DownArrow fill="#0B0F1C" />
                  </div>
                )}
                {item.description === null ? (
                  <div />
                ) : (
                  // <AutosizeInput
                  //   disabled={pdfFormat}
                  //   placeholder="Item description"
                  //   value={item.description}
                  //   onChange={handleChangeDescription}
                  // />
                  <FroalaEditor
                    model={item.description
                      ?.replace(/^(<br>)+|(<br>)+$/g, "")
                      .replace(
                        /<p>\s*<br>\s*<\/p>|<p>\s*<\/p>|<p><strong><em><\/em><\/strong><br><\/p>|<p><strong><em><br><\/em><\/strong><\/p>/g,
                        ""
                      )}
                    onModelChange={(value: string) =>
                      handleChangeDescription(value)
                    }
                    config={{
                      key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                      htmlUntouched: true,
                      listAdvancedTypes: false,
                      listAdvancedPositions: true,
                      fileupload: false,
                      // toolbarInline: true,
                      // toolbarVisibleWithoutSelection: true,
                      imageUpload: false,
                      placeholderText: "Item description",
                    }}
                  />
                )}
              </div>

              {item.checkbox && <Checkbox value={false} onClick={() => {}} />}
            </div>

            {collapsed ? (
              <div className={styles.item__underline} />
            ) : (
              <>
                {item.type !== ChecklistItemType.Stopwatch &&
                  item.type !== ChecklistItemType.Timer &&
                  item.type !== ChecklistItemType.Metronome &&
                  item.type !== ChecklistItemType.Calculator &&
                  item.type !== ChecklistItemType.MedCheck &&
                  item.type !== ChecklistItemType.InputText &&
                  item.type !== ChecklistItemType.Counter &&
                  item.type !== ChecklistItemType.TextBox &&
                  item.type !== ChecklistItemType.DropdownSelector &&
                  item.type !== ChecklistItemType.TimeMarker &&
                  item.type !== ChecklistItemType.Checkbox &&
                  item.type !== ChecklistItemType.Algorithm &&
                  item.type !== ChecklistItemType.BurnedAreas &&
                  item.type !== ChecklistItemType.TableData && (
                    <div className={styles.item__noData}>No data</div>
                  )}
                {item.type === ChecklistItemType.Stopwatch && (
                  <Stopwatch item={item} isDisable />
                )}
                {item.type === ChecklistItemType.Timer && (
                  <Timer item={item} isDisable />
                )}
                {item.type === ChecklistItemType.Metronome && (
                  <Metronome item={item} />
                )}
                {item.type === ChecklistItemType.TextBox && (
                  <TextBox item={item} isDisable />
                )}
                {item.type === ChecklistItemType.Calculator && (
                  <Calculator item={item} isDisable />
                )}
                {item.type === ChecklistItemType.MedCheck && (
                  <MedCheck item={item} isDisable />
                )}

                {item.type === ChecklistItemType.InputText && (
                  <InputText item={item} isDisable />
                )}

                {item.type === ChecklistItemType.Counter && (
                  <Counter item={item} isDisable />
                )}

                {item.type === ChecklistItemType.DropdownSelector && (
                  <Selector item={item} isDisable />
                )}

                {item.type === ChecklistItemType.TimeMarker && (
                  <TimeMarker item={item} isDisable />
                )}

                {item.type === ChecklistItemType.Checkbox && (
                  <CheckboxItem item={item} isDisable />
                )}

                {item.type === ChecklistItemType.Algorithm && (
                  <Algorithm item={item} isDisable />
                )}

                {item.type === ChecklistItemType.BurnedAreas && (
                  <BurnedAreas item={item} isDisable />
                )}

                {item.type === ChecklistItemType.TableData && (
                  <DropdownData item={item} isDisable />
                )}

                <div className={styles.item__footer}>
                  <TypeSelector
                    isDisable={pdfFormat}
                    type={item.type}
                    onChange={onChangeType}
                  />

                  <div className={styles.item__footerButtons}>
                    <div onClick={moveItemUp}>
                      <LeftArrow fill="#616A85" />
                    </div>
                    <div className={styles.item__footerButtonsLine} />
                    <div onClick={moveItemDown}>
                      <LeftArrow fill="#616A85" />
                    </div>
                    <div className={styles.item__footerButtonsLine} />
                    <div onClick={handleOpenPopup}>
                      <DotsVertical fill="#616A85" />
                    </div>
                  </div>

                  {/* <div
                    className={styles.item__footerButtons}
                    onClick={handleOpenPopup}
                  >
                    <LeftArrow fill="#616A85" />
                  </div>
                  <div
                    className={styles.item__footerButtons}
                    onClick={handleOpenPopup}
                  >
                    <LeftArrow fill="#616A85" />
                  </div>
                  <div
                    className={styles.item__footerButtons}
                    onClick={handleOpenPopup}
                  >
                    <DotsVertical fill="#616A85" />
                  </div> */}

                  <div
                    ref={popupContentRef}
                    className={clsx(styles.item__footerPopup, {
                      [styles.active]: showPopup,
                    })}
                  >
                    <div
                      className={styles.item__footerPopupItem}
                      onClick={deleteItem}
                    >
                      <TrashAlt fill="#0B0F1C" /> Delete
                    </div>
                    <div
                      className={styles.item__footerPopupItem}
                      onClick={addItem}
                    >
                      <Duplicate fill="#0B0F1C" /> Duplicate
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      }}
    </Draggable>
  );
};
