import { NumberWidget } from "~components";
import { FC, ReactNode } from "react";
import styles from "./styles.module.scss";
import { IPageHeader } from "~models";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

interface IProps {
  data: IPageHeader[];
}

export const PageHeader: FC<IProps> = ({ data }) => {
  let activeTab = "";

  const linkClassName = (navData: { isActive: boolean }) => {
    return clsx(styles.pageHeader__item, {
      [styles.active]: navData.isActive,
    });
  };

  const renderTabs = () =>
    data.map((item, index) => {
      const inlineStyle = {};
      !!item.color && Object.assign(inlineStyle, { color: item.color });

      return (
        <NavLink
          to={item.to}
          key={`${item.title} - ${index}`}
          className={linkClassName}
          style={inlineStyle}
        >
          {({ isActive }) => (
            <>
              {!!item.leftComponent && (
                <div className={styles.pageHeader__itemLeft}>
                  {item.leftComponent}
                </div>
              )}
              {item.title}

              {item.counter !== undefined && (
                <div className={styles.pageHeader__itemCounter}>
                  <NumberWidget
                    backgroundType={
                      !!item.color ? item.color : isActive ? "green" : "gray"
                    }
                    text={item.counter}
                  />
                </div>
              )}

              {!!item.rightComponent && (
                <div className={styles.pageHeader__itemRight}>
                  {item.rightComponent}
                </div>
              )}
            </>
          )}
        </NavLink>
      );
    });

  return (
    <div className={styles.pageHeader}>
      {renderTabs()}
      <div className={styles.pageHeader__underline} />
    </div>
  );
};
