import { useLazyQuery, useSubscription } from "@apollo/client";
import { useEffect } from "react";
import { useAuth } from "~hooks";
import { GET_MY_INVITATIONS } from "~queries";
import { INVITATION_SUBSCRIPTION } from "~subscriptions";

export const useInvitationSubscription = () => {
  const { user } = useAuth();

  const { data } = useSubscription(INVITATION_SUBSCRIPTION, {
    variables: { userId: user?.id },
  });

  const [queryGetMyInvitations] = useLazyQuery(GET_MY_INVITATIONS);

  useEffect(() => {
    if (!!data)
      queryGetMyInvitations({
        variables: { input: {} },
        fetchPolicy: "network-only",
      });
  }, [JSON.stringify(data)]);
};
