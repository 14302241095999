import { gql } from "@apollo/client";

export const GET_MY_ORGANIZATIONS = gql`
  query myOrganizations {
    myOrganizations {
      id
      name
      picture
      additionalMembers
      status
      counters {
        members
        membersLeft
        admins
        adminsLeft
      }
      members {
        id
        role
      }
      _count {
        members
      }
    }
  }
`;

export const GET_ALL_ORGANIZATIONS = gql`
  query organizations($input: FindOrganizationsInput!) {
    organizations(input: $input) {
      id
      name
      picture
      additionalMembers
      status
      members {
        id
        role
      }
      requests {
        id
        userId
      }
      _count {
        members
      }
    }
  }
`;

export const GET_ALL_ORGANIZATIONS_COUNT = gql`
  query organizationsCount($input: CountOrganizationInput!) {
    organizationsCount(input: $input)
  }
`;

export const GET_ORGANIZATION_DETAIL = gql`
  query organization($id: Int!) {
    organization(id: $id) {
      id
      name
      picture
      additionalMembers
      checkemAi
      checkemTape
      status
      isPrivate
      members {
        id
        role
      }
      createdAt
      requests {
        id
      }
      _count {
        members
      }
    }
  }
`;

export const GET_ORGANIZATION_MEMBERS = gql`
  query organizationMembers($input: FindOrganizationMembersInput!) {
    organizationMembers(input: $input) {
      id
      userId
      organizationId
      role
      createdAt
      user {
        id
        avatar
        firstName
        lastName
        createdAt
        email
      }
    }
  }
`;

export const GET_ALL_ORGANIZATIONS_MEMBERS_COUNT = gql`
  query organizationMembersCount($input: CountOrganizationMembersInput!) {
    organizationMembersCount(input: $input)
  }
`;

export const GET_MEMBERS_PAGINATION_DATA = gql`
  query getMembersPaginationData(
    $countInput: CountOrganizationMembersInput!
    $membersInput: FindOrganizationMembersInput!
  ) {
    organizationMembersCount(input: $countInput)
    organizationMembers(input: $membersInput) {
      id
      userId
      organizationId
      role
      createdAt
      user {
        id
        avatar
        firstName
        lastName
        createdAt
        email
      }
      status
      appInvite {
        email
      }
    }
  }
`;

export const GET_ORGANIZATION_REQUESTS = gql`
  query organizationRequests($input: FindOrganizationRequestsInput!) {
    organizationRequests(input: $input) {
      id
      userId
      organizationId
      user {
        id
        firstName
        lastName
        avatar
        email
      }
      createdAt
    }
  }
`;

export const GET_SUBSCRIPTION_DETAIL = gql`
  query retrieveSubscription($id: Int!) {
    retrieveSubscription(id: $id) {
      id
      renewalDate
      totalPrice
      status
    }
  }
`;

export const GET_CARDS = gql`
  query retrieveCards($organizationId: Int!) {
    retrieveCards(organizationId: $organizationId) {
      id
      brand
      country
      expMonth
      expYear
      last4
      default
      created
    }
  }
`;

export const GET_BILLING_PAGE_INFO = gql`
  query getBillingPageInfo($subscriptionInput: Int!, $cardsInut: Int!) {
    retrieveSubscription(id: $subscriptionInput) {
      id
      renewalDate
      totalPrice
      status
    }
    retrieveCards(organizationId: $cardsInut) {
      id
      brand
      country
      expMonth
      expYear
      last4
      default
      created
    }
  }
`;

export const GET_ORGANIZATIONS_PAGINATION_DATA = gql`
  query getOrganizationsPaginationData(
    $countInput: CountOrganizationInput!
    $organizationInput: FindOrganizationsInput!
  ) {
    organizationsCount(input: $countInput)
    organizations(input: $organizationInput) {
      id
      name
      picture
      additionalMembers
      members {
        id
        role
      }
      requests {
        id
        userId
      }
      _count {
        members
      }
    }
  }
`;

export const GET_MY_ORGANIZATIONS_AND_ACTIVE_PROGRESSES = gql`
  query getMyOrganizationsAndProgresses($organizationId: Int!) {
    myOrganizations {
      id
      name
      picture
      additionalMembers
      status
      counters {
        members
        membersLeft
        admins
        adminsLeft
      }
      members {
        id
        role
      }
      _count {
        members
      }
    }
    getAllMyActiveProgresses(organizationId: $organizationId) {
      id
      completed
      data
      organizationId
      checklistId
      userId
      createdAt
      updatedAt
      checklist {
        id
        title
      }
    }
  }
`;

export const GET_ALL_APP_ORGANIZAZTION = gql`
  query getAllAppOrganization(
    $input: FindOrganizationsInput!
    $countInput: CountOrganizationInput!
  ) {
    findAllOrganizations(input: $input) {
      id
      name
      picture
      additionalMembers
      checkemAi
      checkemTape
      isPrivate
      status
      members {
        id
        role
      }
      createdAt
      status
      _count {
        members
      }
    }
    countAllOrganizations(input: $countInput)
  }
`;
