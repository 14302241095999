import { checkDataErrors } from "../contants/dataValidator";
import { IData, IError } from "../contants/models";
import { useMutation } from "@apollo/client";
import { UPDATE_PASSWORD } from "~mutations";
import { alertVar } from "~localCache";
import { useState } from "react";

export const useEditPassword = () => {
  const [updatePassword] = useMutation(UPDATE_PASSWORD);

  const [data, setData] = useState<IData>({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState<IError>({});

  const [loadingPasswordBlock, setLoadingPasswordBlock] = useState(false);

  const onChangePasswordData = (value: string, key: keyof IData) => {
    !!error && setError({});
    setData((prev) => {
      let state = { ...prev };
      state[key] = value;
      return state;
    });
  };

  const isDisablePasswordSave =
    data.currentPassword.length < 4 ||
    data.password.length < 4 ||
    data.confirmPassword !== data.password;

  const isDisablePasswordCancel =
    !data.confirmPassword && !data.currentPassword && !data.password;

  const handeCancelPasswordChanges = () => {
    !!error && setError({});
    setData({
      currentPassword: "",
      password: "",
      confirmPassword: "",
    });
  };

  const savePassword = async () => {
    const error = checkDataErrors(data);
    if (!!error) {
      setError(error);
      return;
    }
    setLoadingPasswordBlock(true);
    updatePassword({
      variables: {
        input: {
          oldPassword: data.currentPassword,
          newPassword: data.password,
        },
      },
    })
      .then((resp) => {
        alertVar({ type: "green", text: "Changes was successfully saved" });
        setLoadingPasswordBlock(false);
        handeCancelPasswordChanges();
      })
      .catch((err) => {
        setLoadingPasswordBlock(false);
        setError({
          [err.graphQLErrors[0].extensions.response.error]:
            err.graphQLErrors[0].extensions.response.message,
        });
      });
  };

  return {
    passwordData: data,
    loadingPasswordBlock,
    passwordError: error,
    isDisablePasswordSave,
    isDisablePasswordCancel,
    savePassword,
    onChangePasswordData,
    handeCancelPasswordChanges,
  };
};
