import { StrictModifierNames, Modifier } from "react-popper";
import {
  DotsVertical,
  Folder,
  FolderPlusAdd,
  Loader,
  Share,
  TrashAlt,
  Undo,
  CopyTo,
} from "~icons";
import { Options, createPopper } from "@popperjs/core";
import { useFolder } from "../../useFolder";
import { DeleteModal, ShareCodeModal, MoveModal } from "../../modals";
import { PopperPopup } from "~components";
import { IFolder } from "~models";
import clsx from "clsx";

import styles from "./styles.module.scss";
import dayjs from "dayjs";

interface IProps {
  folder: IFolder;
  index: number;
  isDraggingOver?: boolean;
  hideActions?: boolean;
  handleView?: boolean;
  handleMove?: boolean;
  handleImport?: boolean;
  showTableLoader?: () => void;
  updateTableData?: () => void;
  onFolderClickMoveModal?: (clickedFolder: IFolder) => void;
}

export const FolderItem = ({
  folder,
  hideActions,
  handleView,
  handleImport,
  handleMove,
  isDraggingOver,
  showTableLoader,
  updateTableData,
  onFolderClickMoveModal,
}: IProps) => {
  const {
    showPopper,
    showDeletionModal,
    showShareCodeModal,
    showMoveModal,
    showImportButtonLoader,
    handleDelete,
    handleFolderClick,
    handleOpenPopper,
    handleClosePopper,
    handleOpenDeletionModal,
    handleCloseDeletionModal,
    handleOpenShareModal,
    handleCloseShareModal,
    handleImportButton,
    handleMoveOut,
    handleCloseMoveModal,
    handleOpenMoveModal,
  } = useFolder({
    folder,
    handleView,
    handleImport,
    showTableLoader,
    updateTableData,
  });

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  return (
    <div
      onClick={() => handleMove ? onFolderClickMoveModal?.(folder) : handleFolderClick()}
      className={clsx(styles.folderItem, {
        [styles.folderItem__active]: isDraggingOver,
        [styles.folderItem__full]: !handleImport,
      })}
    >
      <div className={styles.folderItem__left}>
        {isDraggingOver ? <FolderPlusAdd /> : <Folder />}
        {folder.title}
      </div>

      {handleImport && (
        <>
          <div className={styles.folderItem__date}>
            {dayjs(folder.updatedAt).format("MMMM D, YYYY")}
          </div>
          <div className={styles.folderItem__author}>
            <p>
              {folder?.firstName} {folder?.lastName}
            </p>
            {handleImport && (
              <div
                onClick={handleImportButton}
                className={styles.folderItem__importButton}
              >
                {showImportButtonLoader ? (
                  <Loader fill="#FFFFFF" />
                ) : (
                  <p>Import to Checkem Builder</p>
                )}
              </div>
            )}
          </div>
        </>
      )}

      {!hideActions && (
        <PopperPopup
          showPopper={showPopper}
          onClosePopper={handleClosePopper}
          onOpenPopper={handleOpenPopper}
          options={popperOptions}
          trigger={<DotsVertical fill="#0B0F1C" />}
          popup={
            <div
              onClick={(e) => e.stopPropagation()}
              className={clsx(styles.folderItem__modal, {})}
            >
              <div
                onClick={handleFolderClick}
                className={styles.folderItem__modalItem}
              >
                <Folder stroke="#0B0F1C" /> Open
              </div>

              <div
                className={styles.folderItem__modalItem}
                onClick={handleOpenMoveModal}
              >
                <CopyTo /> Move
              </div>

              <div
                onClick={handleOpenShareModal}
                className={styles.folderItem__modalItem}
              >
                <Share /> Share Folder
              </div>

              {/* {!!folder?.parentFolderId && (
                <div
                  onClick={handleMoveOut}
                  className={styles.folderItem__modalItem}
                >
                  <Undo fill={"#0B0F1C"} /> Move out of folder
                </div>
              )} */}
              <div
                onClick={handleOpenDeletionModal}
                className={styles.folderItem__modalItem}
                style={{ color: "#EF2F32" }}
              >
                <TrashAlt fill="#EF2F32" /> Delete
              </div>
            </div>
          }
        />
      )}

      <DeleteModal
        isOpen={showDeletionModal}
        itemName="folder"
        onCloseModal={handleCloseDeletionModal}
        handleDelete={handleDelete}
      />

      <ShareCodeModal
        isOpen={showShareCodeModal}
        id={folder.id}
        type="Folder"
        onCloseModal={handleCloseShareModal}
      />

      <MoveModal
        isOpen={showMoveModal}
        onCloseModal={handleCloseMoveModal}
        itemToMove={folder}
        updateTableData={updateTableData}
      />
    </div>
  );
};
