import { DashboardLayout } from "~layouts";
import { Outlet } from "react-router-dom";
import { Button, Modal, PageHeader } from "~components";

import styles from "./styles.module.scss";
import { PlusSymbol } from "~icons";
import { CreateOrganization } from "~blocks";
import { useState } from "react";

export const HomePage = () => {
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);

  const handleOrganizationModalOpen = () => {
    setShowOrganizationModal(true);
  };

  const handleOrganizationModalClose = () => {
    setShowOrganizationModal(false);
  };

  const PAGE_HEADER = [
    { title: "Active", type: "active", to: "/dashboard/home/active" },
    {
      title: "My Completed / In Progress",
      type: "completed",
      to: "/dashboard/home/completed",
    },
  ];

  return (
    <DashboardLayout
      title="Home"
      // rightItem={
      //   <div className={styles.homePage__inviteButtonWrapper}>
      //     <Button
      //       onClick={handleOrganizationModalOpen}
      //       icon={<PlusSymbol fill="#FFFFFF" />}
      //       text={"Create new organization"}
      //       backgroundType={"green"}
      //       buttonClassName={styles.homePage__inviteButton}
      //     />
      //   </div>
      // }
    >
      <div className={styles.homePage}>
        {/* <PageHeader data={PAGE_HEADER} /> */}
        <Outlet />

        {showOrganizationModal && (
          <Modal
            containerClassName={styles.homePage__organization}
            modalClassName={styles.homePage__organizationContent}
            onCloseModal={handleOrganizationModalClose}
            hideCross
          >
            <CreateOrganization
              showTopIcons
              onCrossClick={handleOrganizationModalClose}
              containerClassName={styles.homePage__organizationBlock}
              onFinish={handleOrganizationModalClose}
            />
          </Modal>
        )}
      </div>
    </DashboardLayout>
  );
};
