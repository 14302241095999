import { IItem } from "~models";
import { useChecklist } from "../../../../context";

interface IProps {
  item: IItem;
  progressItem: any;
  isDisable: boolean;
}

export const useSelector = ({ item, progressItem, isDisable }: IProps) => {
  const { progress, setProgress } = useChecklist();

  const itemIndex = progress.data.items.findIndex(
    (elem: { id: number }) => elem.id === item.id
  );

  const handleItemClick = (value: string) => {
    if (isDisable) return;
    const isArrayCreated = !!progressItem?.selections;
    const canChoosedMultiply = item.data.multiple;
    const index = !isArrayCreated
      ? -1
      : progressItem.selections.findIndex((item: string) => item === value);

    if (index === -1) {
      setProgress((prev) => {
        let state = { ...prev };
        if (isArrayCreated) {
          if (
            state.data.items[itemIndex].selections.length > 0 &&
            !canChoosedMultiply
          ) {
            state.data.items[itemIndex].selections.splice(0, 1);
            state.data.items[itemIndex].selections.push(value);
          } else {
            state.data.items[itemIndex].selections.push(value);
          }
        } else {
          state.data.items[itemIndex].selections = [value];
        }
        return state;
      });
    } else {
      setProgress((prev) => {
        let state = { ...prev };
        state.data.items[itemIndex].selections.splice(index, 1);
        return state;
      });
    }
  };

  const itemWithParsedData =
    typeof item.data === "string"
      ? { ...item, data: JSON.parse(item.data) }
      : item;
  const fieldSelections = itemWithParsedData.data.selections.filter(
    (item: string) => !!item
  );
  const isNoData = fieldSelections.length === 0;

  return { isNoData, fieldSelections, itemWithParsedData, handleItemClick };
};
