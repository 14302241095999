import { IOrganization, IOrganizationRequest } from "~models";
import { useOrganizationItem } from "./useOrganizationItem";
import { Avatar, Button } from "~components";
import { FC } from "react";

import styles from "./styles.module.scss";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";
import clsx from "clsx";

interface IProps {
  item: IOrganization;
  index: number;
  handleChangeRequestsField: (
    index: number,
    value: IOrganizationRequest[]
  ) => void;
  updateOrganization: (index: number, value: IOrganization) => void;
}

export const OrganizationItem: FC<IProps> = ({
  item,
  index,
  handleChangeRequestsField,
  updateOrganization,
}) => {
  const {
    handleSendRequest,
    handleCancelRequest,
    loading,
    isIAmMember,
    isISendRequest,
  } = useOrganizationItem({
    item,
    index,
    handleChangeRequestsField,
    updateOrganization,
  });

  const isDark = useReactiveVar(darkTheme);

  return (
    <div style={{ position: "relative" }}>
      <div
        key={`${item.id} - ${index}`}
        className={
          !isDark
            ? styles.organizationItem
            : clsx(styles.organizationItem, styles.organizationItem__dark)
        }
      >
        <div className={styles.organizationItem__top}>
          <Avatar
            uri={item?.picture}
            item={item}
            type="organization"
            containerStyles={styles.organizationItem__topAvatar}
          />
          <div className={styles.organizationItem__topTitle}>{item.name}</div>
          <div className={styles.organizationItem__topSubtitle}>
            {item?._count?.members} members
          </div>
        </div>

        <div className={styles.organizationItem__button}>
          <Button
            disable={isIAmMember}
            loading={loading}
            onClick={isISendRequest ? handleCancelRequest : handleSendRequest}
            text={
              isIAmMember
                ? "Member"
                : isISendRequest
                ? "Cancel request"
                : "Ask to Join"
            }
            backgroundType={isISendRequest ? "white" : "green"}
            showShadow={isISendRequest}
            loaderColor={isISendRequest ? "#0B0F1C" : "#FFFFFF"}
          />
        </div>
      </div>
    </div>
  );
};
