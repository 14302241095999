import clsx from "clsx";
import { FC } from "react";
import styles from "./styles.module.scss";

interface IProps {
  backgroundType?: "green" | "gray" | "red" | "blue";
  backgroundColor?: string;
  textColor?: string;
  text: string | number;
  containerClassName?: string;
}

export const NumberWidget: FC<IProps> = ({
  backgroundColor,
  textColor,
  backgroundType = "green",
  text,
  containerClassName,
}) => {
  let inlineStyles = {};
  !!backgroundColor && Object.assign(inlineStyles, { backgroundColor });
  !!textColor && Object.assign(inlineStyles, { color: textColor });

  return (
    <div
      className={clsx(
        styles.numberWidget,
        {
          [styles.green]: backgroundType === "green",
          [styles.gray]: backgroundType === "gray",
          [styles.red]: backgroundType === "red",
          [styles.blue]: backgroundType === "blue",
        },
        containerClassName
      )}
      style={inlineStyles}
    >
      {text}
    </div>
  );
};
