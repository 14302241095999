import { ITab, defaultTab } from "../../constants";
import { useBuilder } from "../../context";
import { useMutation } from "@apollo/client";
import { CHANGE_TAB_ORDERS, CREATE_TAB, UPDATE_DRAFT } from "~mutations";

export const useFooter = () => {
  const { list, setShowPdfTab, showPdfTab, setList, setActiveTabId } =
    useBuilder();

  const [createTab] = useMutation(CREATE_TAB);
  const [updateDraft] = useMutation(UPDATE_DRAFT);
  const [changeTabOrders] = useMutation(CHANGE_TAB_ORDERS);

  const reorder = (list: ITab[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((item, index) => (item.order = index));
    return result;
  };

  const removePDFTab = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setList((prev) => {
      let state = { ...prev };
      state.isPdfDeleted = true;
      state.pdfUrl = "";
      state.pdfName = "";
      return state;
    });

    if (showPdfTab) {
      setShowPdfTab(false);
    }

    updateDraft({ variables: { input: { id: list.id, isPdfDeleted: true } } });
  };

  const addPDFTab = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setList((prev) => {
      let state = { ...prev };
      state.isPdfDeleted = false;
      return state;
    });
    setShowPdfTab(true);
    updateDraft({ variables: { input: { id: list.id, isPdfDeleted: false } } });
  };

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? "rgba(41, 180, 115, 0.05)" : "white",
  });

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items: ITab[] = reorder(
      list.tabs,
      result.source.index,
      result.destination.index
    );

    setList((prev) => {
      let state = { ...prev };
      state.tabs = items;
      return state;
    });
    changeTabOrders({
      variables: { input: { ids: items.map((item) => item.id) } },
    });
  };

  const addFakeTab = () => {
    setList((prev) => {
      let state = { ...prev };
      let newTab = {
        ...defaultTab,
        name: `Tab ${list.tabs.length + 1}`,
        id: 0,
        order: list.tabs.length + 1,
      };
      let tabs = [...prev.tabs, newTab];
      state.tabs = tabs;
      return state;
    });
    setActiveTabId(0);
  };

  const addRealTab = (tab: ITab) => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[state.tabs.length - 1] = tab;
      return state;
    });
    setActiveTabId(tab.id);
  };

  const handleAddTab = () => {
    addFakeTab();
    createTab({
      variables: {
        input: { name: `Tab ${list.tabs.length + 1}`, draftId: list.id },
      },
    }).then((resp) => addRealTab(resp.data.createChecklistTab));
  };

  const handleShowPdfUploder = () => setShowPdfTab(true);

  return {
    list,
    showPdfTab,
    setList,
    onDragEnd,
    getListStyle,
    handleAddTab,
    removePDFTab,
    addPDFTab,
    handleShowPdfUploder,
  };
};
