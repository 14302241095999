import styles from "./styles.module.scss";

import { IItem } from "../../../../../constants";
import { Dropdown } from "~components";

interface IProps {
  item: IItem;
  isDisable: boolean;
}

export const DropdownData = ({ item, isDisable }: IProps) => {
  return (
    <div className={styles.dropdownData}>
      <Dropdown
        value=""
        onChange={() => {}}
        data={[""]}
        placeholder="Choose"
        isDisable
      />
    </div>
  );
};
