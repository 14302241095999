import { useEffect, useMemo, useState } from "react";
const weightsByAge = {
  "0-0": 3,
  "0-1": 4,
  "0-2": 6,
  "0-3": 6,
  "0-4": 7,
  "0-5": 8,
  "0-6": 8,
  "0-7": 8,
  "0-8": 9,
  "0-9": 9,
  "0-10": 9,
  "0-11": 9,
  "1-0": 10,
  "1-1": 10,
  "1-2": 10,
  "1-3": 10,
  "1-4": 10,
  "1-5": 11,
  "1-6": 11,
  "1-7": 11,
  "1-8": 11,
  "1-9": 12,
  "1-10": 12,
  "1-11": 12,
  "2-0": 12,
  "2-1": 12,
  "2-2": 12,
  "2-3": 13,
  "2-4": 13,
  "2-5": 13,
  "2-6": 13,
  "2-7": 14,
  "2-8": 14,
  "2-9": 14,
  "2-10": 14,
  "2-11": 14,
  "3-0": 14,
  "3-1": 14,
  "3-2": 15,
  "3-3": 15,
  "3-4": 15,
  "3-5": 15,
  "3-6": 15,
  "3-7": 16,
  "3-8": 16,
  "3-9": 16,
  "3-10": 16,
  "3-11": 16,
  "4-0": 16,
  "4-1": 16,
  "4-2": 17,
  "4-3": 17,
  "4-4": 17,
  "4-5": 17,
  "4-6": 17,
  "4-7": 18,
  "4-8": 18,
  "4-9": 18,
  "4-10": 18,
  "4-11": 18,
  "5-0": 18,
  "5-1": 19,
  "5-2": 19,
  "5-3": 19,
  "5-4": 19,
  "5-5": 19,
  "5-6": 19,
  "5-7": 20,
  "5-8": 20,
  "5-9": 20,
  "5-10": 20,
  "5-11": 20,
  "6-0": 21,
  "6-1": 21,
  "6-2": 21,
  "6-3": 21,
  "6-4": 21,
  "6-5": 21,
  "6-6": 22,
  "6-7": 22,
  "6-8": 22,
  "6-9": 22,
  "6-10": 22,
  "6-11": 23,
  "7-0": 23,
  "7-1": 23,
  "7-2": 23,
  "7-3": 24,
  "7-4": 24,
  "7-5": 24,
  "7-6": 24,
  "7-7": 24,
  "7-8": 25,
  "7-9": 25,
  "7-10": 25,
  "7-11": 25,
  "8-0": 25,
  "8-1": 26,
  "8-2": 26,
  "8-3": 26,
  "8-4": 26,
  "8-5": 27,
  "8-6": 27,
  "8-7": 27,
  "8-8": 27,
  "8-9": 27,
  "8-10": 28,
  "8-11": 28,
  "9-0": 28,
  "9-1": 28,
  "9-2": 29,
  "9-3": 29,
  "9-4": 29,
  "9-5": 29,
  "9-6": 30,
  "9-7": 30,
  "9-8": 30,
  "9-9": 30,
  "9-10": 31,
  "9-11": 31,
  "10-0": 31,
  "10-1": 31,
  "10-2": 32,
  "10-3": 32,
  "10-4": 33,
  "10-5": 33,
  "10-6": 33,
  "10-7": 34,
  "10-8": 34,
  "10-9": 35,
  "10-10": 35,
  "10-11": 35,
  "11-0": 36,
  "11-1": 36,
  "11-2": 37,
  "11-3": 37,
  "11-4": 37,
  "11-5": 38,
  "11-6": 38,
  "11-7": 39,
  "11-8": 39,
  "11-9": 40,
  "11-10": 40,
  "11-11": 40,
  "12-0": 40,
  "12-1": 40,
  "12-2": 41,
  "12-3": 41,
  "12-4": 42,
  "12-5": 42,
  "12-6": 42,
  "12-7": 43,
  "12-8": 43,
  "12-9": 44,
  "12-10": 44,
  "12-11": 45,
};

const weightsByHeight = {
  "50": 3,
  "55": 4,
  "58": 6,
  "61": 6,
  "64": 7,
  "66": 8,
  "68": 8,
  "69": 8,
  "71": 9,
  "72": 9,
  "73": 9,
  "74": 9,
  "76": 10,
  "77": 10,
  "78": 10,
  "79": 10,
  "80": 10,
  "81": 11,
  "82": 11,
  "83": 11,
  "84": 11,
  "85": 12,
  "86": 12,
  "87": 12,
  "88": 12,
  "89": 13,
  "90": 13,
  "91": 13,
  "92": 14,
  "93": 14,
  "94": 14,
  "95": 14,
  "96": 14,
  "97": 15,
  "98": 15,
  "99": 15,
  "100": 16,
  "101": 16,
  "102": 16,
  "103": 16,
  "104": 17,
  "105": 17,
  "106": 17,
  "107": 18,
  "108": 18,
  "109": 18,
  "110": 19,
  "111": 19,
  "112": 19,
  "113": 20,
  "114": 20,
  "115": 20,
  "116": 21,
  "117": 21,
  "118": 22,
  "119": 22,
  "120": 22,
  "121": 23,
  "122": 23,
  "123": 24,
  "124": 24,
  "125": 24,
  "126": 25,
  "127": 25,
  "128": 26,
  "129": 27,
  "130": 27,
  "131": 27,
  "132": 28,
  "133": 29,
  "134": 29,
  "135": 30,
  "136": 31,
  "137": 31,
  "138": 32,
  "139": 33,
  "140": 34,
  "141": 34,
  "142": 35,
  "143": 36,
  "144": 37,
  "145": 38,
  "146": 39,
  "147": 40,
  "148": 40,
  "149": 40,
  "150": 41,
  "151": 42,
  "152": 42,
  "153": 43,
  "154": 44,
  "155": 45,
};
const weightsByAgeFemale = {
  "0-0": 3,
  "0-1": 4,
  "0-2": 5,
  "0-3": 6,
  "0-4": 6,
  "0-5": 7,
  "0-6": 7,
  "0-7": 8,
  "0-8": 8,
  "0-9": 8,
  "0-10": 8,
  "0-11": 9,
  "1-0": 9,
  "1-1": 9,
  "1-2": 9,
  "1-3": 10,
  "1-4": 10,
  "1-5": 10,
  "1-6": 10,
  "1-7": 10,
  "1-8": 11,
  "1-9": 11,
  "1-10": 11,
  "1-11": 11,
  "2-0": 12,
  "2-1": 12,
  "2-2": 12,
  "2-3": 12,
  "2-4": 12,
  "2-5": 12,
  "2-6": 13,
  "2-7": 13,
  "2-8": 13,
  "2-9": 13,
  "2-10": 14,
  "2-11": 14,
  "3-0": 14,
  "3-1": 14,
  "3-2": 14,
  "3-3": 14,
  "3-4": 15,
  "3-5": 15,
  "3-6": 15,
  "3-7": 15,
  "3-8": 15,
  "3-9": 16,
  "3-10": 16,
  "3-11": 16,
  "4-0": 16,
  "4-1": 16,
  "4-2": 16,
  "4-3": 17,
  "4-4": 17,
  "4-5": 17,
  "4-6": 17,
  "4-7": 17,
  "4-8": 18,
  "4-9": 18,
  "4-10": 18,
  "4-11": 18,
  "5-0": 18,
  "5-1": 18,
  "5-2": 18,
  "5-3": 19,
  "5-4": 19,
  "5-5": 19,
  "5-6": 19,
  "5-7": 19,
  "5-8": 19,
  "5-9": 20,
  "5-10": 20,
  "5-11": 20,
  "6-0": 20,
  "6-1": 20,
  "6-2": 21,
  "6-3": 21,
  "6-4": 21,
  "6-5": 21,
  "6-6": 21,
  "6-7": 21,
  "6-8": 22,
  "6-9": 22,
  "6-10": 22,
  "6-11": 22,
  "7-0": 22,
  "7-1": 23,
  "7-2": 23,
  "7-3": 23,
  "7-4": 23,
  "7-5": 23,
  "7-6": 24,
  "7-7": 24,
  "7-8": 24,
  "7-9": 24,
  "7-10": 25,
  "7-11": 25,
  "8-0": 25,
  "8-1": 25,
  "8-2": 26,
  "8-3": 26,
  "8-4": 26,
  "8-5": 26,
  "8-6": 27,
  "8-7": 27,
  "8-8": 27,
  "8-9": 27,
  "8-10": 28,
  "8-11": 28,
  "9-0": 28,
  "9-1": 28,
  "9-2": 29,
  "9-3": 29,
  "9-4": 29,
  "9-5": 30,
  "9-6": 30,
  "9-7": 30,
  "9-8": 31,
  "9-9": 31,
  "9-10": 31,
  "9-11": 32,
  "10-0": 32,
  "10-1": 32,
  "10-2": 32,
  "10-3": 33,
  "10-4": 33,
  "10-5": 34,
  "10-6": 34,
  "10-7": 34,
  "10-8": 35,
  "10-9": 35,
  "10-10": 36,
  "10-11": 36,
  "11-0": 37,
  "11-1": 37,
  "11-2": 38,
  "11-3": 38,
  "11-4": 38,
  "11-5": 38,
  "11-6": 39,
  "11-7": 39,
  "11-8": 39,
  "11-9": 40,
  "11-10": 40,
  "11-11": 40,
  "12-0": 41,
  "12-1": 41,
  "12-2": 42,
  "12-3": 42,
  "12-4": 42,
  "12-5": 42,
  "12-6": 43,
  "12-7": 43,
  "12-8": 43,
  "12-9": 44,
  "12-10": 44,
  "12-11": 45,
};

const weightsByHeightFemale = {
  49: 3,
  54: 4,
  57: 5,
  60: 6,
  62: 6,
  64: 7,
  66: 7,
  67: 8,
  69: 8,
  70: 8,
  72: 8,
  73: 9,
  74: 9,
  75: 9,
  76: 9,
  78: 10,
  79: 10,
  80: 10,
  81: 10,
  82: 10,
  83: 11,
  84: 11,
  85: 11,
  86: 11,
  87: 12,
  88: 12,
  89: 12,
  90: 13,
  91: 13,
  92: 13,
  93: 14,
  94: 14,
  95: 14,
  96: 14,
  97: 15,
  98: 15,
  99: 15,
  100: 15,
  101: 16,
  102: 16,
  103: 16,
  104: 17,
  105: 17,
  106: 17,
  107: 18,
  108: 18,
  109: 18,
  111: 18,
  112: 19,
  113: 19,
  114: 19,
  115: 20,
  116: 20,
  117: 21,
  118: 21,
  119: 21,
  120: 22,
  121: 22,
  122: 23,
  123: 23,
  124: 23,
  125: 24,
  126: 24,
  127: 25,
  128: 25,
  129: 26,
  130: 27,
  131: 27,
  132: 28,
  133: 28,
  134: 29,
  135: 29,
  136: 30,
  137: 30,
  138: 31,
  139: 32,
  140: 32,
  141: 33,
  142: 34,
  143: 34,
  144: 35,
  145: 36,
  146: 37,
  147: 38,
  148: 38,
  149: 39,
  150: 40,
  151: 40,
  152: 41,
  153: 42,
  154: 42,
  155: 43,
  156: 44,
};

export const useCalculatorBody = (
  label: string,
  gender: "Male" | "Female",
  age: string,
  kg: string,
  lbs: string,
  setKg: (value: string) => void,
  setLbs: (value: string) => void,
  cm?: string,
  setCm?: (value: string) => void,
  inch?: string,
  setInch?: (value: string) => void
) => {
  const [shownKg, setShownKg] = useState("");
  const [shownLbs, setShownLbs] = useState("");
  const isTotalWeight = useMemo(() => {
    return label === "Total";
  }, [label]);

  useEffect(() => {
    if (!isTotalWeight) {
      setCm && setCm("");
      setInch && setInch("");
      setShownLbs("");
      setShownKg("");
    }
  }, [kg, lbs, isTotalWeight]);

  useEffect(() => {
    if (!!cm && +cm > 153) {
      if (isTotalWeight) {
        setKg("");
        setLbs("");
        setShownLbs("");
        setShownKg("");
      } else {
        if (gender === "Male") {
          const idealKg = Math.round(50 + 0.91 * (+cm - 152.4)).toString();
          handleChangeShownKg(idealKg);
        } else {
          const idealKg = Math.round(45.5 + 0.91 * (+cm - 152.4)).toString();
          handleChangeShownKg(idealKg);
        }
      }
    } else if (!!age) {
      if (gender === "Male") {
        const idealKg =
          weightsByAge[age as keyof typeof weightsByAge]?.toString();
        idealKg && handleChangeShownKg(idealKg);
      } else {
        const idealKg =
          weightsByAgeFemale[
            age as keyof typeof weightsByAgeFemale
          ]?.toString();
        idealKg && handleChangeShownKg(idealKg);
      }
    } else if (!!cm) {
      if (isTotalWeight) {
        setKg("");
        setLbs("");
        setShownLbs("");
        setShownKg("");
      } else {
        if (gender === "Male") {
          const idealKg =
            weightsByHeight[cm as keyof typeof weightsByHeight]?.toString();
          idealKg && handleChangeShownKg(idealKg);
        } else {
          const idealKg =
            weightsByHeightFemale[
              +cm as keyof typeof weightsByHeightFemale
            ]?.toString();
          idealKg && handleChangeShownKg(idealKg);
        }
      }
    } else {
      setShownLbs("");
      setShownKg("");
    }
  }, [gender, cm, age, isTotalWeight]);

  const handleChangeKg = (value: string) => {
    if (!+value && value !== "") return;
    setKg(value);
    setLbs("");
    handleChangeShownKg(value);
  };

  const handleChangeShownKg = (value: string) => {
    if (!+value && value !== "") return;
    setShownKg(value);
    setShownLbs(Math.round(+value * 2.2046).toString());
    if (!isTotalWeight) {
    }
  };

  const handleChangeShownLbs = (value: string) => {
    if (!+value && value !== "") return;
    setShownLbs(value);
    setShownKg(Math.round(+value / 2.2046).toString());
  };

  const handleChangeLbs = (value: string) => {
    if (!+value && value !== "") return;
    setLbs(value);
    setKg("");
    handleChangeShownLbs(value);
  };

  const handleClear = () => {
    setKg("");
    setLbs("");
    setShownLbs("");
    setShownKg("");
    setInch && setInch("");
    setCm && setCm("");
  };

  return {
    isTotalWeight,
    lbs,
    kg,
    cm,
    inch,
    shownKg,
    shownLbs,
    handleChangeLbs,
    handleChangeKg,
    handleClear,
  };
};
