import { OnboardingLayout } from "~layouts";
import { Button, Input, ImagePicker } from "~components";
import { Camera } from "~icons";
import { useGetStarted } from "./hooks/useGetStarted";
import clsx from "clsx";
import styles from "./styles.module.scss";

export const GetStarted = () => {
  const {
    file,
    setFile,
    data,
    onChangeData,
    disableButton,
    mainLoading,
    onSubmit,
    avatarUri,
  } = useGetStarted();

  return (
    <OnboardingLayout isOnboarding={true}>
      <div className={styles.resetPassword}>
        <div className={styles.resetPassword__header}>You’re almost done</div>

        <ImagePicker
          cropShape="round"
          containerClassName={styles.resetPassword__picker}
          saveImage={setFile}
        >
          <div
            className={clsx(styles.resetPassword__pickerAvatar, {
              [styles.exist]: !!avatarUri,
            })}
          >
            {!!avatarUri ? <img src={avatarUri} /> : <Camera />}
          </div>

          <p>
            Change picture
            <br />
            (optional)
          </p>
        </ImagePicker>

        <Input
          label="First name"
          value={data.firstName}
          onChange={(value: string) => onChangeData(value, "firstName")}
          placeholder={"Enter your first name..."}
        />

        <div className={styles.resetPassword__input}>
          <Input
            label="Last name"
            value={data.lastName}
            onChange={(value: string) => onChangeData(value, "lastName")}
            placeholder={"Enter your last name..."}
          />
        </div>

        <div className={styles.resetPassword__button}>
          <Button
            loading={mainLoading}
            disable={disableButton}
            onClick={onSubmit}
            text="Finish"
            backgroundType={"green"}
          />
        </div>
      </div>
    </OnboardingLayout>
  );
};
