import { StrictModifierNames, Modifier } from "react-popper";
import { Options, createPopper } from "@popperjs/core";
import { Avatar, PopperPopup } from "~components";
import { IUser } from "~models";
import dayjs from "dayjs";
import clsx from "clsx";
import { DotsVertical, Pen } from "~icons";

import styles from "./styles.module.scss";
import { useUserItem } from "./useUserItem";

interface IProps {
  user: IUser;
  index: number;
}

export const UserItem = ({ user, index }: IProps) => {
  const { showPopper, handleOpenPopper, handleClosePopper, handleEditButton } =
    useUserItem({ user });

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  return (
    <>
      <div className={styles.userItem}>
        <div className={styles.userItem__user}>
          {!!user && (
            <>
              <Avatar
                uri={user.avatar}
                item={user}
                type="user"
                containerStyles={styles.userItem__userAvatar}
              />
              <div className={styles.userItem__userRight}>
                <p className={styles.userItem__userName}>
                  {user.firstName} {user.lastName}
                </p>
                <p className={styles.userItem__userEmail}>{user.email}</p>
              </div>
            </>
          )}
        </div>
        <div className={styles.userItem__organizations}>
          <div className={styles.userItem__organizationsLong}>
            3 organizations
          </div>
          <div className={styles.userItem__organizationsShort}>3</div>
        </div>

        <div className={styles.userItem__date}>
          <div className={styles.userItem__dateLong}>
            {dayjs().format("MMMM DD, YYYY")}
          </div>
          <div className={styles.userItem__dateShort}>
            {dayjs().format("DD/MM/YY")}
          </div>

          <PopperPopup
            showPopper={showPopper}
            onClosePopper={handleClosePopper}
            onOpenPopper={handleOpenPopper}
            options={popperOptions}
            trigger={<DotsVertical fill="#0B0F1C" />}
            popup={
              <div className={clsx(styles.userItem__modal, {})}>
                <div
                  onClick={handleEditButton}
                  className={styles.userItem__modalItem}
                >
                  <Pen fill="#0B0F1C" /> Edit
                </div>
              </div>
            }
          />
        </div>
      </div>
      <div className={styles.userItem__underline} />
    </>
  );
};
