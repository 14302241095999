import { usePDF } from "../../usePDF";
import { Loader } from "~icons";
import { IPdf } from "~models";
import { FC } from "react";

import styles from "./styles.module.scss";

interface IProps {
  pdf: IPdf;
  index: number;
}

export const PDFItemImportSquare: FC<IProps> = ({ pdf, index }: IProps) => {
  const { showLoader, handleImportButton } = usePDF({
    pdf,
    index,
  });

  return (
    <div>
      <div className={styles.pdfItem}>
        <div className={styles.content}>
          <div>
            <p className={styles.content__title}>{pdf.title}</p>
            <p className={styles.content__author}>
              {pdf.firstName} {pdf.lastName}
            </p>
          </div>
          <div onClick={handleImportButton} className={styles.content__button}>
            {showLoader ? (
              <Loader fill="#FFFFFF" />
            ) : (
              <p>Import to Checkem Builder</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
