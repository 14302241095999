import { CheckSquareBroken, Loader } from "~icons";
import { useChecklist } from "../../useChecklist";
import { IDraft } from "~models";
import dayjs from "dayjs";

import styles from "./styles.module.scss";

interface IProps {
  checklist: IDraft;
}

export const ChecklistItemImport = ({ checklist }: IProps) => {
  const { showLoader, handleImportButton } = useChecklist({
    checklist,
  });

  return (
    <div>
      <div className={styles.folderItem}>
        <div className={styles.folderItem__horizontal}>
          <div className={styles.folderItem__horizontalName}>
            <CheckSquareBroken stroke="#29B473" />
            {checklist.title}
          </div>
          <div className={styles.folderItem__horizontalDate}>
            {dayjs(checklist.createdAt).format("MMMM D, YYYY")}
          </div>
          <div className={styles.folderItem__horizontalAuthor}>
            <p>
              {checklist.firstName} {checklist.lastName}
            </p>
            <div
              onClick={handleImportButton}
              className={styles.folderItem__horizontalAuthorButton}
            >
              {showLoader ? (
                <Loader fill="#FFFFFF" />
              ) : (
                <p>Import to Checkem Builder</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.folderItem__underline} />
    </div>
  );
};
