import { useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { IMessage, MessageType } from "~models";
import { ASK_QUESTION_TO_CHAT, GET_ANSWER_FROM_CHAT } from "~mutations";

interface IProps {
  messages: IMessage[];
  setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>;
}

export const useChat = ({ messages, setMessages }: IProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const params = useParams();
  let id = !!params?.id ? +params.id : -1;

  const [askGuestion] = useMutation(ASK_QUESTION_TO_CHAT);
  const [getAnswer] = useMutation(GET_ANSWER_FROM_CHAT);

  const [question, setQuestion] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current;
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  };

  const fetchAnswer = () => {
    getAnswer({ variables: { input: { chatId: id } } }).then((resp) => {
      setMessages((prev) => {
        let state = structuredClone(prev);
        state.push(resp.data.getAnswerFromChat);
        return state;
      });
      setTimeout(() => {
        scrollToBottom();
        setShowLoader(false);
      }, 100);
    });
  };

  const addFakeQuestion = (data: string) => {
    setMessages((prev) => {
      let state = structuredClone(prev);
      state.push({
        createdAt: new Date().toString(),
        data,
        id: -1,
        type: MessageType.Question,
      });
      return state;
    });
    setTimeout(() => scrollToBottom(), 0);
  };

  const addRealQuestion = (questionItem: IMessage) => {
    setMessages((prev) => {
      let state = structuredClone(prev);
      state[state.length - 1] = questionItem;
      return state;
    });
  };

  const sendQuestions = () => {
    if (!question.length) return;
    let data = question;
    addFakeQuestion(data);
    setShowLoader(true);
    setQuestion("");
    askGuestion({ variables: { input: { chatId: id, data } } }).then((resp) => {
      addRealQuestion(resp.data.askQuestionToChat);
    });
  };

  const checkQuestionWithoutAnswer = () => {
    if (messages[messages.length - 1].type === MessageType.Question) {
      setShowLoader(true);
      fetchAnswer();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    !!messages.length &&
      messages[messages.length - 1].id !== -1 &&
      checkQuestionWithoutAnswer();
  }, [JSON.stringify(messages)]);

  return {
    showLoader,
    question,
    scrollContainerRef,
    setQuestion,
    sendQuestions,
    scrollToBottom,
  };
};
