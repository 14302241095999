import { gql } from "@apollo/client";

export const CREATE_ORGANIZATION = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
      name
      picture
      additionalMembers
      isPrivate
      plan
      customerId
      status
      members {
        id
        role
      }
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      name
      picture
      isPrivate
      additionalMembers
    }
  }
`;

export const REMOVE_ORGANIZATION = gql`
  mutation removeOrganization($id: Int!) {
    removeOrganization(id: $id)
  }
`;

export const INVITE_TO_ORGANIZATION = gql`
  mutation inviteToOrganization($input: InviteToOrganizationInput!) {
    inviteToOrganization(input: $input)
  }
`;

export const SEND_ORGANIZATION_REGUEST = gql`
  mutation sendOrganizationRequest($organizationId: Int!) {
    sendOrganizationRequest(organizationId: $organizationId) {
      id
      requests {
        id
        userId
      }
    }
  }
`;

export const CANCEL_ORGANIZATION_REGUEST = gql`
  mutation cancelOrganizationRequest($input: CancelOrganizationRequestInput!) {
    cancelOrganizationRequest(input: $input)
  }
`;

export const CREATE_SUBSCRIBTION = gql`
  mutation createSubscription($input: CreateSubscriptionInput!) {
    createSubscription(input: $input) {
      additionalMembers
      status
      plan
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscription($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input)
  }
`;

export const ACCEPT_ORGANIZATION_REQUEST = gql`
  mutation acceptOrganizationRequest($input: AcceptOrganizationRequestInput!) {
    acceptOrganizationRequest(input: $input)
  }
`;

export const ADD_CARD = gql`
  mutation attachPaymentMethod($input: AttachPaymentMethodInput!) {
    attachPaymentMethod(input: $input)
  }
`;

export const CHANGE_DEFAULT_CARD = gql`
  mutation changeDefaultPaymentMethod(
    $input: ChangeDefaultPaymentMethodInput!
  ) {
    changeDefaultPaymentMethod(input: $input)
  }
`;

export const TRANSFER_OWNER = gql`
  mutation transferOwner(
    $input: [TransferOwnerInput!]!
    $isDelete: Boolean!
    $userId: Int!
  ) {
    transferOwner(input: $input, isDelete: $isDelete, userId: $userId)
  }
`;
