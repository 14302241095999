import { regex } from "~constants";

interface IProps {
  password: string;
}
export const usePasswordRules = ({ password }: IProps) => {
  const showLengthError = !!password && password.length < 8;
  const showDigitError = !!password && !regex.atLeastOneNumber.test(password);
  const showSymbolError = !!password && !regex.atLeastOneSymbol.test(password);

  return { showLengthError, showDigitError, showSymbolError };
};
