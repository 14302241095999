import { useRef, useState } from "react";
import { IItem } from "../../../../../constants";
import { useOutsideClick } from "~hooks";

interface IProps {
  item: IItem;
}

export const useInputText = ({ item }: IProps) => {
  const [focused, setFocused] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleActiveFocus = () => setFocused(true);
  const handleDisactiveFocus = (
    e: React.FocusEvent<HTMLTextAreaElement, Element>
  ) => {
    e.stopPropagation();
    setFocused(false);
  };

  return { handleActiveFocus, handleDisactiveFocus, focused, textareaRef };
};
