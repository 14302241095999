import { useEffect, useMemo, useRef, useState } from "react";
import { PopupActions } from "reactjs-popup/dist/types";
import { useOutsideClick } from "~hooks";

export const useCalculatorPopup = () => {
  const popupRef = useRef<PopupActions>(null);
  const popupContentRef = useRef<HTMLDivElement>(null);
  const popupTriggerRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [gender, setGender] = useState<"Male" | "Female">("Male");
  const [cm, setCm] = useState("");
  const [inch, setInch] = useState("");

  const [kg, setKg] = useState("");
  const [lbs, setLbs] = useState("");

  const age = useMemo(() => {
    return isNaN(+year) || isNaN(+month) || !year || !month
      ? ""
      : `${year}-${month}`;
  }, [year, month]);

  useEffect(() => {
    if(+cm > 153) {
      setYear('Empty')
      setMonth('Empty')
    }
  }, [cm])

  const setGenderMale = () => {
    setGender("Male");
  };
  const setGenderFemale = () => {
    setGender("Female");
  };

  const togglePopup = () => {
    if (!showPopup) {
      popupRef.current?.open();
      setShowPopup(true);
    } else {
      popupRef.current?.close();
      setShowPopup(false);
    }
  };

  const handleShopPopup = () => {
    if (!showPopup) {
      popupRef.current?.open();
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    if (showPopup) {
      popupRef.current?.close();
      setShowPopup(false);
    }
  };

  useOutsideClick(
    popupContentRef,
    handleClosePopup,
    "mousedown",
    popupTriggerRef
  );

  return {
    popupRef,
    popupContentRef,
    popupTriggerRef,
    year,
    month,
    gender,
    cm,
    inch,
    age,
    kg,
    lbs,
    setLbs,
    setKg,
    setCm,
    setInch,
    setGenderMale,
    setGenderFemale,
    togglePopup,
    handleShopPopup,
    setYear,
    setMonth,
  };
};
