import { IUser } from "./IUser";

export enum OrganizationMemberRole {
  Owner = "Owner",
  Admin = "Admin",
  User = "User",
}

export enum OrganizationMemberStatus {
  Accepted = "Accepted",
  Pending = "Pending",
}

export interface OrganizationMember {
  id: number;
  userId: number;
  organizationId: number;
  role: OrganizationMemberRole;
  createdAt: string;
  user: IUser;
  status: OrganizationMemberStatus;
  appInvite?: {
    email: string;
  };
}
