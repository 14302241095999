import { OnboardingLayout } from "~layouts";
import { Button, Input, PasswordRules } from "~components";
import { useSignUp } from "./hooks/useSignUp";

import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";

export const SignUpPage = () => {
  const navigate = useNavigate();
  const {
    data,
    onChangeData,
    disableButton,
    loading,
    error,
    onSubmit,
  } = useSignUp();

  const navigateToSignIn = () => navigate("/signIn");

  return (
    <OnboardingLayout>
      <div className={styles.signUp}>
        <div className={styles.signUp__header}>First, enter your email</div>

        <Input
          label="Email address"
          value={data.email}
          onChange={(value) => onChangeData(value, "email")}
          placeholder={"Enter your email address..."}
          error={error.email}
        />

        <div className={styles.signUp__password}>
          <Input
            label="Password"
            value={data.password}
            onChange={(value) => onChangeData(value, "password")}
            placeholder={"Enter your password..."}
            type={"secure"}
            error={!!error.password ? " " : ""}
          />

          <PasswordRules password={data.password} />
        </div>

        <Input
          label="Repeat Password"
          value={data.confirmPassword}
          onChange={(value) => onChangeData(value, "confirmPassword")}
          placeholder={"Repeat your password..."}
          type={"secure"}
          error={error.confirmPassword}
        />

        <div className={styles.signUp__button}>
          <Button
            backgroundType="green"
            disable={disableButton}
            loading={loading}
            onClick={onSubmit}
            text="Sign Up"
          />
        </div>

        <p>
          Already have an account?{" "}
          <span onClick={navigateToSignIn}>Sign In</span>
        </p>
      </div>
    </OnboardingLayout>
  );
};
