import { StrictModifierNames, Modifier } from "react-popper";
import { useOrganizationItem } from "./useOrganizationItem";
import { Avatar, Button, PopperPopup } from "~components";
import { Options, createPopper } from "@popperjs/core";
import { LeaveOwnerModal } from "~modals";
import { IOrganization } from "~models";
import { DotsVertical } from "~icons";
import dayjs from "dayjs";

import styles from "./styles.module.scss";

interface IProps {
  item: IOrganization;
}

export const MyOrganizationItem = ({ item }: IProps) => {
  const {
    popupRef,
    showPopper,
    showLeaveOwnerModal,
    handleLeaveButton,
    leaveOrganization,
    handleCloseLeaveOwnerModal,
    handleClosePopper,
    handleOpenPopper,
  } = useOrganizationItem({ item });

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  return (
    <div>
      <div className={styles.myOrganization}>
        <div className={styles.myOrganization__organization}>
          <Avatar
            uri={item?.picture}
            item={item}
            type="organization"
            containerStyles={styles.myOrganization__organizationAvatar}
          />
          {item.name}
        </div>

        <div className={styles.myOrganization__members}>
          {item?.counters?.members + 1} members
          {/* {!!item.invations && (
          <div className={styles.myOrganization__membersCounter}>
            <NumberWidget
              text={item.invations}
              backgroundType={"red"}
            />
          </div>
        )} */}
        </div>

        <div className={styles.myOrganization__date}>
          {dayjs(item.createdAt).format("MMMM D, YYYY")}
        </div>

        <div className={styles.myOrganization__role}>
          {!!item?.members ? item?.members[0].role : ""}
          <div className={styles.myOrganization__roleRight}>
            {/* <div className={styles.myOrganization__roleButton}>
              <Button backgroundType={"green"} text={"View"} />
            </div> */}
            <PopperPopup
              showPopper={showPopper}
              onClosePopper={handleClosePopper}
              onOpenPopper={handleOpenPopper}
              options={popperOptions}
              trigger={<DotsVertical fill="#0B0F1C" />}
              popup={
                <div ref={popupRef} className={styles.myOrganization__modal}>
                  <div
                    className={styles.myOrganization__modalItem}
                    onClick={handleLeaveButton}
                  >
                    Leave organization
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.myOrganization__underline} />

      {showLeaveOwnerModal && (
        <LeaveOwnerModal
          organizations={[item]}
          onCloseModal={handleCloseLeaveOwnerModal}
          text={
            "Before you leave the organization, reassign the owner of the restriction"
          }
          handleSuccessResult={leaveOrganization}
        />
      )}
    </div>
  );
};
