import styles from "./styles.module.scss";

import { IItem } from "../../../../../constants";
import { useMedCheck } from "./useMedCheck";
import { Button, Input } from "~components";

interface IProps {
  item: IItem;
  isDisable: boolean;
}

export const MedCheck = ({ item, isDisable }: IProps) => {
  const {} = useMedCheck({ item });
  return (
    <div className={styles.medcheck}>
      <div className={styles.medcheck__inputs}>
        <Input
          disable={isDisable}
          label="Medication name"
          placeholder="Enter name"
        />
        <Input
          disable={isDisable}
          label="Expiration date"
          placeholder="MM/YYYY"
        />
      </div>
      <div className={styles.medcheck__buttons}>
        <Button
          disable={isDisable}
          backgroundType={"white"}
          showShadow
          text="Override"
        />
        <Button disable={isDisable} backgroundType={"green"} text="Check" />
      </div>
    </div>
  );
};
