import { gql } from "@apollo/client";

export const GET_PAGINATION_ADMINS_DATA = gql`
  query getPaginationAdminsData(
    $input: FindUserInput!
    $countInput: CountUserInput!
  ) {
    admins(input: $input) {
      id
      firstName
      lastName
      avatar
      email
      isVerified
      onboarding
      role
      authType
      providerId
      createdAt
      updatedAt
    }
    adminsCount(input: $countInput)
  }
`;
