import { IItem } from "~models";
import { useChecklist } from "../../context";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const useItem = ({ item, itemIndex }: IProps) => {
  const { progress, setProgress } = useChecklist();

  let progressItem = progress.data.items.find((elem) => elem.id === item.id);
  let progressIndex = progress.data.items.findIndex(
    (elem) => elem.id === item.id
  );

  if (typeof progressItem?.data === "string") {
    progressItem = { ...progressItem, data: JSON.parse(progressItem.data) };
  }

  let parsedItem = structuredClone(item);
  if (typeof parsedItem?.data === "string") {
    parsedItem.data = JSON.parse(parsedItem.data);
  }

  const toggleCheckbox = () => {
    if (progress.completed) return;
    setProgress((prev) => {
      let state = { ...prev };
      if (!progressItem?.isChecked) {
        state.data.items[progressIndex].checkedTimestamp = new Date().getTime();
      }
      state.data.items[progressIndex].isChecked = !progressItem?.isChecked;
      return state;
    });
  };

  const toggleCollapsed = () => {
    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[progressIndex].startCollapsable =
        !progressItem.startCollapsable;
      return state;
    });
  };

  const isDisable = progress.completed;

  return {
    progressItem,
    parsedItem,
    toggleCheckbox,
    toggleCollapsed,
    isDisable,
  };
};
