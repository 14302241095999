import {
  CopyTo,
  DotsVertical,
  DownArrow,
  Duplicate,
  ExclamationCircle,
  Rename,
  TrashAlt,
} from "~icons";
import Popup from "reactjs-popup";
import styles from "./styles.module.scss";
import { useTab } from "./useTab";
import { ITab } from "../../../../constants";
import clsx from "clsx";
import AutosizeInput from "../../../../../../../../components/InputAutosize";
interface IProps {
  tab: ITab;
}

export const Tab = ({ tab }: IProps) => {
  const {
    popupRef,
    popupContentRef,
    isEditable,
    isTabActive,
    tabRef,
    disableDeleteButton,
    showError,
    showPdfTab,
    togglePopup,
    changeActiveTab,
    handleEditableEnable,
    handleChangeName,
    handleDeleteTab,
    handleDuplicateTab,
  } = useTab({
    tab,
  });

  return (
    <div ref={tabRef} className={styles.tab__wrapper} onClick={changeActiveTab}>
      <div
        className={clsx(styles.tab, {
          [styles.active]: isTabActive && !showPdfTab,
          [styles.editable]: isEditable,
        })}
      >
        <Popup
          ref={popupRef}
          arrow={false}
          lockScroll={true}
          closeOnDocumentClick={false}
          position={"top left"}
          trigger={<div style={{ position: "absolute", left: 0, top: 0 }} />}
        >
          <div className={styles.tab__modal} ref={popupContentRef}>
            <div className={styles.tab__item} onClick={handleEditableEnable}>
              <Rename fill="#0B0F1C" />
              Rename
            </div>
            <div className={styles.tab__item} onClick={handleDuplicateTab}>
              <Duplicate fill="#0B0F1C" />
              Duplicate
            </div>
            <div className={styles.tab__item}>
              <CopyTo fill="#0B0F1C" />
              Copy to
              <div className={styles.tab__itemRightIcon}>
                <DownArrow fill="#0B0F1C" />
              </div>
            </div>
            <div className={styles.tab__modalUnderline} />
            <div
              className={clsx(styles.tab__item, {
                [styles.disable]: disableDeleteButton,
              })}
              onClick={handleDeleteTab}
            >
              <TrashAlt fill={disableDeleteButton ? "#DBDEE7" : "#0B0F1C"} />
              Delete
            </div>
          </div>
        </Popup>
        {isEditable ? (
          <>
            <AutosizeInput
              placeholder="Tab"
              value={tab.name}
              className={styles.tab__field}
              onChange={handleChangeName}
              name="form-field-name"
            />
          </>
        ) : (
          <>
            {tab.name}
            {showError && (
              <div className={styles.tab__exclamation}>
                <ExclamationCircle />
              </div>
            )}

            <div className={styles.tab__dots} onClick={togglePopup}>
              <DotsVertical fill="#29B473" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
