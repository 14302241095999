import { useMutation, useQuery } from "@apollo/client";
import { SubscriptionType } from "../../../../../../blocks/CreateOrganization/hooks";
import { useState } from "react";
import { Alert } from "~components";
import { GET_CHOOSED_ORGANIZATIOM, alertVar } from "~localCache";
import { UPDATE_SUBSCRIPTION } from "~mutations";

interface IProps {
  onClose: () => void;
  handleAddUser: () => void;
  updateData: () => void;
  subscriptionType: SubscriptionType;
}

export const useAddUsersModal = ({
  onClose,
  updateData,
  handleAddUser,
  subscriptionType,
}: IProps) => {
  const [updateSubscription, { loading }] = useMutation(UPDATE_SUBSCRIPTION);

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [additonalUsers, setAdditionalUsers] = useState(1);
  const [showLoader, setShowLoader] = useState(false);

  const onChangeAdditionalUsersCount = (value: number | string) =>
    setAdditionalUsers(+value);

  const handleCloseModal = () => {
    onClose();
    setAdditionalUsers(1);
  };

  const handleBuyButton = () => {
    updateSubscription({
      variables: {
        input: {
          organizationId: choosedOrganization.id,
          additionalMembers: additonalUsers,
          plan: subscriptionType,
        },
      },
    })
      .then((resp) => {
        alertVar({
          type: "green",
          text: "Additional users have been successfully purchased.",
        });
        updateData();
        onClose();
      })
      .catch((err) => console.log("err", err));
    // setAdditionalUsers(1);
  };

  const isDisable = additonalUsers === 0;
  return {
    loading,
    additonalUsers,
    isDisable,
    onChangeAdditionalUsersCount,
    handleCloseModal,
    handleBuyButton,
  };
};
