import styles from "./styles.module.scss";

import { IItem } from "../../../../../constants";
import { useCounter } from "./useCounter";
import { Counter as CounterComponent } from "~components";

interface IProps {
  item: IItem;
  isDisable: boolean;
}

export const Counter = ({ item, isDisable }: IProps) => {
  const {} = useCounter({
    item,
  });
  return (
    <div className={styles.counter}>
      <CounterComponent isDisable={isDisable} value={0} onChange={() => {}} />
    </div>
  );
};
