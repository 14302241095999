import { IData, IError } from "../contants/models";
import { regex } from "~constants";

export const checkDataErrors = (data: IData): IError | null => {
  if (data.password.length < 8) {
    return { password: "Password must have at least 8 characters" };
  } else if (!regex.atLeastOneNumber.test(data.password)) {
    return { password: "Password must have at least one digit" };
  } else if (!regex.atLeastOneSymbol.test(data.password)) {
    return { password: "Password must have at least one special character" };
  } else if (data.password !== data.confirmPassword) {
    return { confirmPassword: "Repeat password must match password" };
  } else return null;
};

export const isDisabled = (data: IData) =>
  data.password.length < 5 || data.confirmPassword !== data.password;
