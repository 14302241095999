import { OrganizationMemberRole } from "./IMember";
import { IOrganization } from "./IOrganization";

export enum UserAccessRole {
  User = "User",
  SuperAdmin = "SuperAdmin",
  SuperSuperAdmin = "SuperSuperAdmin",
}

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  avatar: string;
  email: string;
  isVerified: string;
  onboarding: boolean;
  createdAt: string;
  authType: "Google" | "Email" | "Apple";
  updatedAt: string;
  role: UserAccessRole;
}

export interface IUserInvitation {
  id: number;
  userId: number;
  organizationId: number;
  role: OrganizationMemberRole;
  organization: IOrganization;
  createdAt: string;
}
