import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "~hooks";
import { UPDATE_USER } from "~mutations";
import { GET_CURRENT_USER } from "~queries";
import { uploadFile } from "~services";
import { IData } from "../contants/models";
import { isDisabled } from "../helpers/dataValidator";

export const useGetStarted = () => {
  const navigate = useNavigate();
  const { setUser, user } = useAuth();

  const { data: userData } = useQuery(GET_CURRENT_USER);

  const [data, setData] = useState<IData>({
    firstName: userData?.currentUser?.firstName,
    lastName: userData?.currentUser?.lastName,
    avatar: userData?.currentUser?.avatar,
  });

  const [file, setFile] = useState<Blob | null>(null);

  const [loading, setLoading] = useState(false);

  const disableButton = isDisabled(data);

  const [mutateUpdateUser] = useMutation(UPDATE_USER);

  const onChangeData = (value: string, key: keyof IData) => {
    setData((prev) => {
      let state = { ...prev };
      state[key] = value;
      return state;
    });
  };

  const onSubmit = async () => {
    setLoading(true);
    let updateUserInput = {
      id: userData.currentUser.id,
      firstName: data.firstName,
      lastName: data.lastName,
      onboarding: true,
    };

    if (!!file) {
      let uri = await uploadFile(file, file.type);
      Object.assign(updateUserInput, { avatar: uri });
    }
    await mutateUpdateUser({
      variables: { updateUserInput },
    }).then(() => {
      setUser({
        ...user,
        firstName: data.firstName,
        lastName: data.lastName,
        onboarding: true,
      });
      setLoading(false);
      // navigate("/dashboard");
    });
  };

  const avatarUri = !!file ? URL.createObjectURL(file) : data.avatar;

  useEffect(() => {
    !!userData &&
      setData({
        firstName: userData.currentUser.firstName,
        lastName: userData.currentUser.lastName,
        avatar: userData.currentUser.avatar,
      });
  }, [userData]);

  return {
    file,
    setFile,
    data,
    onChangeData,
    mainLoading: loading,
    disableButton,
    onSubmit,
    avatarUri,
  };
};
