import { useBuilder } from "../../../../context";
import { IItem } from "../../../../constants";
import { regex } from "~constants";
interface IProps {
  item: IItem;
  itemIndex: number;
}

export const useTimeMarker = ({ item, itemIndex }: IProps) => {
  const { setList, activeTabIndex, activeItem } = useBuilder();

  const parsedItem = (item: string | { title: string }[]) => {
    if (typeof item === "string") {
      return JSON.parse(item);
    } else return item;
  };

  const handleTitleChange = (value: string, index: number) => {
    if (!activeItem || value.length >= 30) return;
    let buttons = structuredClone(parsedItem(item.data).buttons);
    buttons[index].title = value;

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = { buttons };
      return state;
    });
  };

  const disableDeleteButton = parsedItem(item.data).buttons.length === 1;

  const handleDeleteButton = (index: number) => {
    if (disableDeleteButton || !activeItem) return;

    let buttons = structuredClone(parsedItem(item.data).buttons);
    buttons.splice(index, 1);
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = { buttons };
      return state;
    });
  };

  const handleAddButton = () => {
    if (!activeItem) return;

    let buttons = structuredClone(parsedItem(item.data).buttons);
    buttons.push({ title: "Mark Time" });
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = { buttons };
      return state;
    });
  };

  const handleChooseColor = (color: string, index: number) => {
    if (!activeItem) return;
    let buttons = structuredClone(parsedItem(item.data).buttons);
    buttons[index].color = color;

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = { buttons };
      return state;
    });
  };

  const disableAddButtom = parsedItem(item.data).buttons.length === 10;

  return {
    disableDeleteButton,
    disableAddButtom,
    handleTitleChange,
    handleDeleteButton,
    handleAddButton,
    handleChooseColor,
  };
};
