import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useAuth } from "~hooks";
import { GET_CHOOSED_ORGANIZATIOM } from "~localCache";
import { IOrganization, OrganizationMemberRole } from "~models";
import { INVITE_TO_ORGANIZATION, UPDATE_USER } from "~mutations";
import { IMember } from "./constants";
import { validateMembers } from "./helpers/dataValidator";

interface IProps {
  onFinish?: () => void;
}

export const useInvite = ({ onFinish }: IProps) => {
  const { user, setUser } = useAuth();

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [mutateUpdateUser] = useMutation(UPDATE_USER);
  const [mutateInvireMembers] = useMutation(INVITE_TO_ORGANIZATION);

  const [members, setMembers] = useState<IMember[]>([{ email: "", role: "" }]);
  const [loading, setLoading] = useState(false);

  const handleChangeRole = (index: number, role: string) => {
    setMembers((prev) => {
      let state = [...prev];
      state[index].role =
        OrganizationMemberRole[role as keyof typeof OrganizationMemberRole];
      return state;
    });
  };

  const handleChangeEmail = (index: number, email: string) => {
    setMembers((prev) => {
      let state = [...prev];
      state[index].email = email;
      return state;
    });
  };

  const deleteMember = (index: number) => {
    setMembers((prev) => {
      let state = [...prev];
      state.splice(index, 1);
      return state;
    });
  };

  const createMember = () => {
    setMembers((prev) => {
      let state = [...prev];
      state.push({ email: "", role: "" });
      return state;
    });
  };

  const isDisable = !validateMembers(members);

  const handleFinish = () => {
    setLoading(true);
    mutateInvireMembers({
      variables: { input: { id: choosedOrganization?.id, members } },
    })
      .then(() => {
        setLoading(false);
        !!onFinish && onFinish();
      })
      .catch((err) => console.log("err", err));
  };

  return {
    members,
    handleChangeEmail,
    handleChangeRole,
    deleteMember,
    createMember,
    loading,
    isDisable,
    handleFinish,
  };
};
