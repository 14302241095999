import styles from "./styles.module.scss";

import { IItem } from "../../../../../constants";
import { useInputText } from "./useInputText";
import { Button, Input, TextArea } from "~components";
import TextareaAutosize from "react-textarea-autosize";
import clsx from "clsx";

interface IProps {
  item: IItem;
  isDisable: boolean;
}

export const InputText = ({ item, isDisable }: IProps) => {
  const { textareaRef, focused, handleActiveFocus, handleDisactiveFocus } =
    useInputText({
      item,
    });
  return (
    <div className={styles.inputText}>
      <TextareaAutosize
        disabled={isDisable}
        ref={textareaRef}
        onFocus={handleActiveFocus}
        onBlur={handleDisactiveFocus}
        placeholder="Enter text"
        className={clsx(styles.inputText__field, { [styles.active]: focused })}
      />
    </div>
  );
};
