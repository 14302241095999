import { GET_MY_ORGANIZATIONS } from "~queries";
import { IOrganization, IUser } from "~models";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { alertVar } from "~localCache";
import { useState } from "react";

interface IProps {
  isMyProfile: boolean;
  editableUser: IUser | null;
}

export const useDeleteAccount = ({ isMyProfile, editableUser }: IProps) => {
  const navigate = useNavigate();
  const [showDeleteUserModal, setShowDeleteUserrModal] = useState(false);

  const {
    data: { myOrganizations },
  } = useQuery(GET_MY_ORGANIZATIONS);

  const organizationsWhereIOwner = !!myOrganizations
    ? myOrganizations.filter(
        (item: IOrganization) =>
          !!item?.members && item.members[0].role === "Owner"
      )
    : [];

  const handleOpenDeleteUserModal = () => setShowDeleteUserrModal(true);
  const handleCloseDeleteUserModal = () => setShowDeleteUserrModal(false);

  const handleDeleteOtherAccount = () => {
    alertVar({ type: "green", text: "User deleted successfully" });
    navigate("/dashboard/usersManagement");
  };

  return {
    showDeleteUserModal,
    organizationsWhereIOwner,
    handleDeleteOtherAccount,
    handleOpenDeleteUserModal,
    handleCloseDeleteUserModal,
  };
};
