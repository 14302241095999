import { GENERATE_UPLOAD_URL } from "~mutations";
import { clientForQueryWithoutHook } from "./authService";

export const uploadFile = async (file: any, contentType?: string) => {
  let data: { generateUploadUrl: { putUrl: string; getUrl: string } } =
    await clientForQueryWithoutHook("").request(GENERATE_UPLOAD_URL, {
      input: { contentType },
    });

  if (!!data?.generateUploadUrl?.putUrl) {
    const uploadResult = await fetch(data.generateUploadUrl.putUrl, {
      method: "PUT",
      body: file,
    });

    if (uploadResult.status === 200) {
      return data.generateUploadUrl.getUrl;
    } else return "";
  } else return "";
};
