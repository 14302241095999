import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useAuth } from "~hooks";
import { choosedOrganizationVar } from "~localCache";
import { IOrganization } from "~models";
import { CREATE_ORGANIZATION, UPDATE_ORGANIZATION } from "~mutations";
import {
  GET_ALL_ORGANIZATIONS,
  GET_ALL_ORGANIZATIONS_COUNT,
  GET_MY_ORGANIZATIONS,
} from "~queries";
import { uploadFile } from "~services";
import { isValidFirstStep } from "../helpers/dataValidator";
import { useNavigate } from "react-router-dom";

export const useCreateOrganization = (
  isOnboarding: boolean,
  organizationFromProps?: IOrganization
) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [mutationCreateOrganization] = useMutation(CREATE_ORGANIZATION);
  const [mutationUpdateOrganization] = useMutation(UPDATE_ORGANIZATION);
  const [queryGetMyOrganization] = useLazyQuery(GET_MY_ORGANIZATIONS, {
    fetchPolicy: "network-only",
  });

  const [queryGetAllOrganizations] = useLazyQuery(GET_ALL_ORGANIZATIONS, {
    fetchPolicy: "network-only",
  });

  const [queryGetAllOrganizationsCount] = useLazyQuery(
    GET_ALL_ORGANIZATIONS_COUNT,
    {
      fetchPolicy: "network-only",
    }
  );

  const [page, setPage] = useState(!!organizationFromProps ? 2 : 1);

  const [name, setName] = useState<string>("");
  const [picture, setPicture] = useState<Blob | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [organization, setOrganization] = useState<IOrganization | null>(null);

  const [error, setError] = useState<string>("");

  const goNext = () => page < 3 && setPage(page + 1);
  const goBack = () => page > 1 && setPage(page - 1);

  const onChangeName = (value: string) => {
    !!error && setError("");
    setName(value);
  };

  const pictureUri = !!picture
    ? URL.createObjectURL(picture)
    : !!organization?.picture
    ? organization.picture
    : "";
  const isDisableFirstStep = !isValidFirstStep(name);

  const goToPayment = (data: IOrganization) => {
    setOrganization(data);
    if (isOnboarding) {
      localStorage.setItem(
        "organization",
        JSON.stringify({
          ...data,
          userId: user?.id,
        })
      );
    } else {
      queryGetMyOrganization({ variables: {} }).then((resp) => {
        if (resp.data.myOrganizations.length === 1) {
          choosedOrganizationVar(resp.data.myOrganizations[0]);
        }
      });
      queryGetAllOrganizationsCount({ variables: { input: {} } });
      queryGetAllOrganizations({
        variables: { input: { skip: 0, take: 10 } },
      });
    }
    setLoading(false);
    goNext();
    setPicture(null);
  };

  const updateOrganization = async () => {
    setLoading(true);
    const input = {
      id: organization?.id,
      name,
    };
    if (!!picture) {
      const pictureUri = await uploadFile(picture, picture.type);
      Object.assign(input, { picture: pictureUri });
    }

    mutationUpdateOrganization({ variables: { input } })
      .then((resp) => goToPayment(resp.data.updateOrganization))
      .catch((err) => {
        setLoading(false);
        setError(err?.graphQLErrors[0].message);
      });
  };

  const createOrganization = async () => {
    setLoading(true);
    const input = {
      name,
    };
    if (!!picture) {
      const pictureUri = await uploadFile(picture, picture.type);
      Object.assign(input, { picture: pictureUri });
    }
    mutationCreateOrganization({ variables: { input } })
      .then((resp) => {
        choosedOrganizationVar({
          ...resp.data.createOrganization,
          _count: { members: 1 },
          counters: { members: 0, admins: 0, membersLeft: 0, adminsLeft: 10 },
        });
        navigate("/dashboard/home/active");
        goToPayment(resp.data.createOrganization);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.graphQLErrors[0].message);
      });
  };

  useEffect(() => {
    !!organizationFromProps && setOrganization(organizationFromProps);
  }, [organizationFromProps]);

  return {
    error,
    name,
    page,
    setPage,
    onChangeName,
    goNext,
    goBack,
    setPicture,
    pictureUri,
    isDisableFirstStep,
    loading,
    createOrganization: !!organization
      ? updateOrganization
      : createOrganization,
    organization,
  };
};
