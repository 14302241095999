import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import {
  GET_CHOOSED_ORGANIZATIOM,
  alertVar,
  sortMethod,
  viewTypes,
} from "~localCache";
import {
  GET_ORGANIZATION_DRAFTS_DATA_AND_FOLDERS_NEW,
  GET_ORGANIZATION_DRAFTS_DATA_NEW,
} from "~queries";
import { PublishingStatus, IDraft, IFolder } from "~models";
import { useUpdateEffect } from "usehooks-ts";
import { useEffect, useState } from "react";
import {
  MOVE_DRAFT_TO_FOLDER,
  MOVE_FOLDER_TO_FOLDER,
  MOVE_PDF_TO_FOLDER,
} from "~mutations";
import { DropResult } from "react-beautiful-dnd";
import { isValueNode } from "graphql";

const PAGE_LIMIT = 10;

export const useDrafts = () => {
  const viewType = useReactiveVar(viewTypes);
  const sortBy = useReactiveVar(sortMethod);

  const setViewType = (type: "table" | "grid") => {
    viewTypes(type);
  };

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [movePdfToFolder] = useMutation(MOVE_PDF_TO_FOLDER);
  const [moveDraftToFolder] = useMutation(MOVE_DRAFT_TO_FOLDER);
  const [moveFolderToFolder] = useMutation(MOVE_FOLDER_TO_FOLDER);

  const [fetchedCount, setFetchedCount] = useState(0);
  const [drafts, setDrafts] = useState<IDraft[]>([]);
  const [allDraftsCount, setAllDraftsCount] = useState(0);
  const [folders, setFolders] = useState<IFolder[]>([]);

  const [searchValue, setSearchValue] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);

  const [getData] = useLazyQuery(GET_ORGANIZATION_DRAFTS_DATA_NEW);
  const [getInitialData] = useLazyQuery(
    GET_ORGANIZATION_DRAFTS_DATA_AND_FOLDERS_NEW
  );

  const sortAlphabetically = (data: IDraft[]) => {
    return data.sort((a, b) => {
      if (!a.title) {
        return 1;
      }
      if (!b.title) {
        return -1;
      }
      return a.title.localeCompare(b.title);
    });
  };

  const sortByTime = (data: IDraft[]) => {
    return data.sort((a, b) => {
      if (!a.lastModified) {
        return 1;
      }
      if (!b.lastModified) {
        return -1;
      }
      return (
        new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime()
      );
    });
  };

  const loadData = () => {
    setLoading(true);

    const folderInput = {
      skip: 0,
      take: 49,
      organizationId: choosedOrganization.id,
    };

    const countPdfInput = {
      organizationId: choosedOrganization.id,
    };

    const countDraftInput = {
      organizationId: choosedOrganization.id,
    };

    const input = {
      skip: 0,
      take: PAGE_LIMIT,
      organizationId: choosedOrganization.id,
    };

    if (!!searchValue) {
      Object.assign(countPdfInput, { search: searchValue });
      Object.assign(countDraftInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }

    getInitialData({
      variables: { folderInput, countPdfInput, countDraftInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      fetchedCount !== 0 && setFetchedCount(0);
      setFolders(resp.data.folders);
      setAllDraftsCount(resp.data.countDraft + resp.data.countPdf);
      sortBy === "alphabet"
        ? setDrafts(
            sortAlphabetically([
              ...resp.data.checkemBuilderData.pdfs,
              ...resp.data.checkemBuilderData.drafts,
            ])
          )
        : setDrafts(
            sortByTime([
              ...resp.data.checkemBuilderData.pdfs,
              ...resp.data.checkemBuilderData.drafts,
            ])
          );
      setLoading(false);
    });
  };

  useEffect(() => {
    console.log(sortBy);
    if (drafts.length) {
      sortBy === "alphabet"
        ? setDrafts((prev) => sortAlphabetically([...prev]))
        : setDrafts((prev) => sortByTime([...prev]));
    }
  }, [sortBy]);

  const updateDraftStatus = (status: PublishingStatus, index: number) => {
    setDrafts((prev) => {
      let state = [...prev];
      state[index].status = status;
      return state;
    });
  };

  const updateCurrentPage = () => {
    const maxPage = Math.ceil(allDraftsCount);
    let skip = fetchedCount;
    if (drafts.length === 1 && fetchedCount >= PAGE_LIMIT) {
      skip = skip - PAGE_LIMIT;
    }
    if (skip > maxPage) return;
    setLoading(true);
    let take = PAGE_LIMIT;
    if (allDraftsCount - skip < PAGE_LIMIT) {
      take = allDraftsCount - skip;
    }
    const countPdfInput = {
      organizationId: choosedOrganization.id,
    };
    const countDraftInput = {
      organizationId: choosedOrganization.id,
    };
    const input = {
      skip: skip,
      take: PAGE_LIMIT,
      organizationId: choosedOrganization.id,
    };
    if (!!searchValue) {
      Object.assign(countPdfInput, { search: searchValue });
      Object.assign(countDraftInput, { search: searchValue });

      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countPdfInput, countDraftInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllDraftsCount(resp.data.countDraft + resp.data.countPdf);
      setDrafts(
        sortAlphabetically([
          ...resp.data.checkemBuilderData.pdfs,
          ...resp.data.checkemBuilderData.drafts,
        ])
      );
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(allDraftsCount);
    const skip = fetchedCount + PAGE_LIMIT;
    if (skip >= maxPage) return;
    setPaginationLoading(true);
    let take = PAGE_LIMIT;
    if (allDraftsCount - skip < PAGE_LIMIT) {
      take = allDraftsCount - skip;
    }
    const countPdfInput = {
      organizationId: choosedOrganization.id,
    };
    const countDraftInput = {
      organizationId: choosedOrganization.id,
    };
    const input = {
      skip: skip,
      take: PAGE_LIMIT,
      organizationId: choosedOrganization.id,
    };
    if (!!searchValue) {
      Object.assign(countPdfInput, { search: searchValue });
      Object.assign(countDraftInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countPdfInput, countDraftInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllDraftsCount(resp.data.countDraft + resp.data.countPdf);
      setDrafts((prev) => [
        ...prev,
        ...resp.data.checkemBuilderData.pdfs,
        ...resp.data.checkemBuilderData.drafts,
      ]);
      setPaginationLoading(false);
    });
    setFetchedCount(skip);
  };

  const handlePrevPage = () => {
    const skip = fetchedCount - PAGE_LIMIT;
    if (skip < 0) return;
    setLoading(true);
    const countPdfInput = { organizationId: choosedOrganization.id };
    const countDraftInput = {
      organizationId: choosedOrganization.id,
    };
    const input = {
      skip: skip,
      take: PAGE_LIMIT,
      organizationId: choosedOrganization.id,
    };
    if (!!searchValue) {
      Object.assign(countPdfInput, { search: searchValue });
      Object.assign(countDraftInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countPdfInput, countDraftInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllDraftsCount(resp.data.countDraft + resp.data.countPdf);
      setDrafts([
        ...resp.data.checkemBuilderData.pdfs,
        ...resp.data.checkemBuilderData.drafts,
      ]);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, type } = result;

    if (!destination) return;

    const isFolderMooving = source.droppableId !== "ITEMS";

    let folder = folders.find((item) => item.id === +destination.droppableId);
    let item = isFolderMooving ? folders[source.index] : drafts[source.index];

    // if (isFolderMooving && folder?.id === item.id) {
    //   alert(1);
    //   return;
    // }

    const input = {};

    isFolderMooving
      ? Object.assign(input, {
          childFolderId: item.id,
          parentFolderId: folder?.id,
        })
      : item.type === "Pdf"
      ? Object.assign(input, { pdfId: item.id, folderId: folder?.id })
      : Object.assign(input, { draftId: item.id, folderId: folder?.id });

    const movingFunc = isFolderMooving
      ? moveFolderToFolder
      : item.type === "Pdf"
      ? movePdfToFolder
      : moveDraftToFolder;

    alertVar({
      text: "The PDF is moved to a folder",
      type: "blue",
      isLoading: true,
    });

    movingFunc({
      variables: { input },
    })
      .then(() => {
        isFolderMooving ? loadData() : updateCurrentPage();
        alertVar({
          text: `The ${
            isFolderMooving ? "folder" : item.type === "Pdf" ? "PDF" : "draft"
          } has been successfully moved to the folder`,
          type: "green",
        });
      })
      .catch((err) => {
        alertVar({
          text: err.graphQLErrors[0].message,
          type: "red",
        });
      });
  };

  useEffect(() => {
    loadData();
  }, [choosedOrganization?.id]);

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      loadData();
    }, 650);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    drafts,
    searchValue,
    viewType,
    draftsCount: allDraftsCount,
    fetchedCount,
    loading,
    PAGE_LIMIT,
    folders,
    showLoader: () => setLoading(true),
    loadData,
    onDragEnd,
    setSearchValue,
    setViewType,
    handleNextPage,
    handlePrevPage,
    updateDraftStatus,
    paginationLoading,
  };
};
