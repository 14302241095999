import { GridView, LeftArrow, Loader, TableView } from "~icons";
import ContentLoader from "react-content-loader";
import { PlusButton, UploadButton } from "./components";
import { DashboardLayout } from "~layouts";
import { useFolderBuilder } from "./useFolderBuilder";
import {
  DraftItemSquare,
  Pagination,
  SearchInput,
  PDFItem,
  PDFItemSquare,
  DraftItem,
  Table,
  TableLoader,
  TableDraggable,
  FolderItem,
  GridDraggable,
  FolderItemSquare,
  SearchBar,
} from "~components";

import styles from "./styles.module.scss";
import { useReactiveVar } from "@apollo/client";
import { viewTypes } from "~localCache";

export const FolderBuilder = () => {
  const {
    drafts,
    loading,
    paginationLoading,
    draftsCount,
    fetchedCount,
    searchValue,
    PAGE_LIMIT,
    viewType,
    folderId,
    folders,
    folderTitle,
    goBack,
    showLoader,
    loadData,
    setViewType,
    setSearchValue,
    handleNextPage,
    handlePrevPage,
    showTableLoader,
    updateDraftStatus,
    onDragEnd,
  } = useFolderBuilder();

  return (
    <DashboardLayout
      title="Checkem Builder"
      rightItem={<PlusButton folderId={folderId} refreshData={loadData} />}
    >
      <div className={styles.folderBuilder}>
        <div className={styles.folderBuilder__header}>
          <div onClick={goBack} className={styles.folderBuilder__headerIcon}>
            <LeftArrow fill="#979DB1" />
          </div>

          {!!folderTitle ? (
            folderTitle
          ) : (
            <ContentLoader
              speed={1}
              backgroundColor="#DBDEE7"
              foregroundColor="#ebedf2"
              title=""
              height={32}
            >
              <rect x="0" y="0" rx="7" ry="7" width="300" height="32" />
            </ContentLoader>
          )}
        </div>
        <SearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          viewType={viewType}
          setViewType={setViewType}
        />

        {loading && <TableLoader />}

        {!loading && viewType === "table" && (
          <TableDraggable
            onDragEnd={onDragEnd}
            loading={paginationLoading}
            handleLoad={handleNextPage}
            folders={folders}
            header={[
              { header: "Name", className: styles.folderBuilder__name },
              {
                header: "Last modified",
                className: styles.folderBuilder__date,
              },
              { header: "Action", className: styles.folderBuilder__action },
              { header: "Type", className: styles.folderBuilder__type },
              { header: "Status", className: styles.folderBuilder__status },
            ]}
            data={drafts}
            renderFolderItem={(folder, index, isDraggingOver) => {
              return (
                <FolderItem
                  key={`${folder.id} - ${index} - folder`}
                  folder={folder}
                  index={index}
                  isDraggingOver={isDraggingOver}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              );
            }}
            renderTableItem={(item, index) => {
              return item.type === "Pdf" ? (
                <PDFItem
                  pdf={item}
                  key={`${item.id} - ${index} - item`}
                  index={index}
                  updatePDFStatus={updateDraftStatus}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              ) : (
                <DraftItem
                  draft={item}
                  key={`${item.id} - ${index} - item`}
                  index={index}
                  updateDraftStatus={updateDraftStatus}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              );
            }}
          />
        )}

        {!loading && viewType === "grid" && (
          <GridDraggable
            data={drafts}
            folders={folders}
            loading={paginationLoading}
            handleLoad={handleNextPage}
            // draggableTypes={["Pdf"]}
            onDragEnd={onDragEnd}
            renderItem={(item, index) => {
              return item.type === "Pdf" ? (
                <PDFItemSquare
                  pdf={item}
                  key={`${item.id} - ${index} - item`}
                  index={index}
                  updatePDFStatus={updateDraftStatus}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              ) : (
                <DraftItemSquare
                  draft={item}
                  key={`${item.id} - ${index} - item`}
                  index={index}
                  updateDraftStatus={updateDraftStatus}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              );
            }}
            renderFolderItem={(folder, index, isDraggingOver) => {
              return (
                <FolderItemSquare
                  key={`${folder.id} - ${index} - folder`}
                  folder={folder}
                  index={index}
                  isDraggingOver={isDraggingOver}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              );
            }}
          />
        )}
        {paginationLoading && (
          <div className={styles.paginationLoader}>
            <Loader fill="#616A85" />
          </div>
        )}

        <div className={styles.pagination}>
          <div className={styles.pagination__left}>
            Showing{" "}
            <span>
              {fetchedCount + PAGE_LIMIT > draftsCount
                ? draftsCount
                : !!draftsCount && !!(fetchedCount + PAGE_LIMIT)
                ? fetchedCount + PAGE_LIMIT
                : 0}
            </span>{" "}
            of <span>{draftsCount}</span> Results
          </div>
        </div>

        {/* {!loading && viewType === "grid" && (
          <div className={styles.folderBuilder__gridWrapper}>
            <div className={styles.folderBuilder__grid}>
              {drafts.map((item: any, index) => {
                return item.type === "Pdf" ? (
                  <PDFItemSquare
                    pdf={item}
                    key={`${item} - ${index}`}
                    index={index}
                    updatePDFStatus={updateDraftStatus}
                    showTableLoader={showTableLoader}
                    updateTableData={loadData}
                  />
                ) : (
                  <DraftItemSquare
                    draft={item}
                    key={`${item} - ${index}`}
                    index={index}
                    updateDraftStatus={updateDraftStatus}
                    showTableLoader={showTableLoader}
                    updateTableData={loadData}
                  />
                );
              })}
            </div>
          </div>
        )} */}

        {/* <Pagination
          allResults={draftsCount}
          fetchedResult={fetchedCount + PAGE_LIMIT}
          limit={PAGE_LIMIT}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
        /> */}
      </div>
    </DashboardLayout>
  );
};
