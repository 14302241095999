import { useChecklist } from "../../../../context";
import { metronomeSounds } from "~audio";
import { useAudioPlayer } from "react-use-audio-player";
import { IItem } from "~models";
import { activeMetronomeVar } from "~localCache";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const useMetronome = ({ progressItem, isDisable }: IProps) => {
  const { list } = useChecklist();
  const activeMetronome = activeMetronomeVar();

  const { load, stop, play, stopped } = useAudioPlayer({
    autoplay: true,
  });

  const isMetronomeActive =
    list.id === activeMetronome?.listId &&
    progressItem.id === activeMetronome?.itemId;

  const handleClick = (
    e: React.MouseEvent,
    key: keyof typeof metronomeSounds
  ) => {
    e.stopPropagation();
    if (isDisable) return;
    if (isMetronomeActive && activeMetronome.key === key) {
      stop();
      activeMetronomeVar(null);
      return;
    }
    activeMetronomeVar({ key, listId: list.id, itemId: progressItem.id });
    load({
      src: metronomeSounds[key],
      autoplay: true,
      loop: true,
    });
    stopped && play();
  };
  return { handleClick, activeMetronome, isMetronomeActive };
};
