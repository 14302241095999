import React, { FC, useState } from "react";

import { clsx } from "clsx";

import styles from "./styles.module.scss";
import { CancelInput, Search } from "~icons";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";

interface IProps {
  label?: string;
  placeholder: string;
  value?: string;
  onChange?: (val: string) => void;
  error?: string;
}
export const SearchInput: FC<IProps> = ({
  label,
  placeholder,
  value = "",
  onChange,
  error,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const setActiveFocused = () => setIsFocused(true);
  const setDisactiveFocused = () => setIsFocused(false);

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    !!onChange && onChange(e.target.value);

  const clearInput = () => !!onChange && onChange("");
  const isDark = useReactiveVar(darkTheme);

  return (
    <div className={styles.inputContainer}>
      {!!label && <div className={styles.inputContainer__label}>{label}</div>}
      <div
        className={
          !isDark
            ? clsx(styles.inputWrapper, {
                [styles.focus]: isFocused,
                [styles.error]: !!error,
              })
            : clsx(styles.inputWrapper, styles.inputWrapperDark, {
                [styles.focus]: isFocused,
                [styles.error]: !!error,
              })
        }
      >
        <div
          className={
            !isDark
              ? styles.inputContainer__search
              : clsx(
                  styles.inputContainer__search,
                  styles.inputContainer__searchDark
                )
          }
        >
          <Search />
        </div>

        <input
          placeholder={placeholder}
          style={
            isDark
              ? { backgroundColor: "var(--lightGray)", color: "#ffffff" }
              : {}
          }
          onFocus={setActiveFocused}
          onBlur={setDisactiveFocused}
          value={value}
          onChange={onChangeValue}
        />

        {!!value && (
          <div className={styles.inputContainer__cancel} onClick={clearInput}>
            <CancelInput />
          </div>
        )}
      </div>
      {!!error && <div className={styles.inputContainer__error}>{error}</div>}
    </div>
  );
};
