import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { goBack } from "~helpers";
import { darkTheme } from "~localCache";
import { IUser } from "~models";
import { GET_CURRENT_USER, GET_USER_INFO } from "~queries";

export const useEditProfile = () => {
  const navigate = useNavigate();
  const [getUser] = useLazyQuery(GET_USER_INFO);
  const params = useParams();
  const [user, setUser] = useState<IUser | null>(null);

  const {
    data: { currentUser },
  } = useQuery(GET_CURRENT_USER);

  const isDark = useReactiveVar(darkTheme);

  const setIsDark = (value: boolean) => {
    darkTheme(value);
    localStorage.setItem("theme", value ? "dark" : "light");
  };

  const [showMainLoader, setShowMainLoader] = useState(true);

  const handleBackClick = () =>
    goBack({ navigate, defaultDestination: "/dashboard/home" });

  useEffect(() => {
    if (!!params?.id)
      getUser({ variables: { id: +params?.id } }).then((resp) => {
        setUser(resp.data.user);
        setShowMainLoader(false);
      });
  }, [params?.id]);

  return {
    showMainLoader,
    user,
    isMyProfile: currentUser?.id === user?.id,
    isDark,
    setIsDark,
    setUser,
    handleBackClick,
  };
};
