import { ColorPicker, SwitchField } from "~components";
import styles from "./styles.module.scss";
import { useSectionSettings } from "./useSectionSettings";

export const SectionSettings = () => {
  const {
    showBlock,
    section,
    toggleChecbox,
    toggleCollapsable,
    toggleStartCollapsable,
    toggleHeader,
    toggleDescription,
    onChangeColor,
  } = useSectionSettings();

  if (!showBlock || !section) return null;

  return (
    <div className={styles.sectionSettings}>
      <div className={styles.sectionSettings__field}>
        <SwitchField
          text="Header text"
          value={section.name !== null}
          onClick={toggleHeader}
        />
      </div>
      <div className={styles.sectionSettings__field}>
        <SwitchField
          text="Make Collapsable"
          value={section.collapsable}
          onClick={toggleCollapsable}
        />
      </div>
      {section.collapsable && (
        <>
          <div className={styles.sectionSettings__field}>
            <SwitchField
              text="Start as Collapsed"
              value={section.startCollapsable}
              onClick={toggleStartCollapsable}
            />
          </div>
        </>
      )}
      <div className={styles.sectionSettings__field}>
        <SwitchField
          text="Description text"
          value={section.description !== null}
          onClick={toggleDescription}
        />
      </div>
      <div className={styles.sectionSettings__field}>
        <SwitchField
          text="Checkbox"
          value={section.checkbox}
          onClick={toggleChecbox}
        />
      </div>
      <div className={styles.sectionSettings__field}>
        <ColorPicker
          color={section.color}
          showCircle
          onChoose={onChangeColor}
          text="Color"
        />
      </div>
    </div>
  );
};
