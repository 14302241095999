import { IOrganization } from "~models";

export const setChoosedOrganization = (organization: IOrganization) =>
  localStorage.setItem("choosedOrganization", JSON.stringify(organization));

export const getChoosedOrganization = () => {
  let organization = localStorage.getItem("choosedOrganization");
  return !!organization ? JSON.parse(organization) : null;
};

export const clearChoosedOrganization = () =>
  localStorage.removeItem("choosedOrganization");
