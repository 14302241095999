import { IItem } from "../../../../constants";
import { Input, SoundDropdown, SwitchField } from "~components";
import styles from "./styles.module.scss";
import { useTimer } from "./useTimer";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const TimerSettings = ({ item, itemIndex }: IProps) => {
  const {
    handleSoundName,
    handleMinutes,
    handleSeconds,
    toggleRecordTime,
    handleNotificationText,
  } = useTimer({
    item,
    itemIndex,
  });
  return (
    <div className={styles.timerSettings}>
      <SwitchField
        text="Record time at beginning and end of timer"
        value={item.data.record}
        onClick={toggleRecordTime}
      />
      <div className={styles.timerSettings__underline} />

      <Input
        onChange={handleNotificationText}
        value={item.data?.text}
        label="Notification text"
        placeholder="Notification text"
      />

      <div className={styles.timerSettings__timeInputs}>
        <Input
          onChange={handleMinutes}
          value={item.data?.minutes}
          label="Minutes"
          placeholder="Minutes"
        />
        <Input
          onChange={handleSeconds}
          value={item.data?.seconds}
          label="Seconds"
          placeholder="Seconds"
        />
      </div>
      <SoundDropdown
        onChange={handleSoundName}
        sound={item.data?.sound}
        label="Sound"
      />
    </div>
  );
};
