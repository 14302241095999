import { PublishingStatus, IDraft, IFolder } from "~models";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { GET_CHOOSED_ORGANIZATIOM, sortMethod, viewTypes } from "~localCache";
import { useUpdateEffect } from "usehooks-ts";
import { useEffect, useState } from "react";
import {
  GET_ADMIN_LIBRARY_DATA,
  GET_ADMIN_LIBRARY_DATA_AND_FOLDERS,
} from "~queries";

const PAGE_LIMIT = 10;

export const useActiveChecklists = () => {
  const viewType = useReactiveVar(viewTypes)
  const sortBy = useReactiveVar(sortMethod);

  const setViewType = (type: 'table' | 'grid') => {
    viewTypes(type)
  }

  const sortAlphabetically = (data: IDraft[]) => {
    return data.sort((a, b) => {
      if (!a.title) {
        return 1;
      }
      if (!b.title) {
        return -1;
      }
      return a.title.localeCompare(b.title);
    });
  };

  const sortByTime = (data: IDraft[]) => {
    return data.sort((a, b) => {
      if (!a.lastModified) {
        return 1;
      }
      if (!b.lastModified) {
        return -1;
      }
      return (
        new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime()
      );
    });
  };

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [fetchedCount, setFetchedCount] = useState(0);
  const [checklists, setChecklists] = useState<IDraft[]>([]);
  const [allChecklistsCount, setAllChecklistsCount] = useState(0);

  const [searchValue, setSearchValue] = useState<string>("");
  const [folders, setFolders] = useState<IFolder[]>([]);

  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false)

  const [getData] = useLazyQuery(GET_ADMIN_LIBRARY_DATA);
  const [getInitialData] = useLazyQuery(GET_ADMIN_LIBRARY_DATA_AND_FOLDERS);

  const loadData = () => {
    setLoading(true);

    const folderInput = {
      skip: 0,
      take: 49,
    };

    const countPdf = {
      status: PublishingStatus.Published,
    };

    const countChecklist = {};
    const input = {
      skip: 0,
      take: PAGE_LIMIT,
    };

    if (!!searchValue) {
      Object.assign(countPdf, { search: searchValue });
      Object.assign(countChecklist, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }

    getInitialData({
      variables: { folderInput, countPdf, countChecklist, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setFolders(resp.data.foldersLibrary);
      fetchedCount !== 0 && setFetchedCount(0);
      setAllChecklistsCount(
        resp.data.countChecklistLibrary + resp.data.countPdfLibrary
      );
      setChecklists([
        ...resp.data.checkemHomeDataLibrary.pdfs,
        ...resp.data.checkemHomeDataLibrary.checklists,
      ]);
      setLoading(false);
    });
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(allChecklistsCount);
    const skip = fetchedCount + PAGE_LIMIT;
    if (skip > maxPage) return;
    setPaginationLoading(true);
    let take = PAGE_LIMIT;
    if (allChecklistsCount - skip < PAGE_LIMIT) {
      take = allChecklistsCount - skip;
    }
    const countPdf = {
      status: PublishingStatus.Published,
    };

    const countChecklist = {};
    const input = {
      skip: skip,
      take: PAGE_LIMIT,
    };
    if (!!searchValue) {
      Object.assign(countPdf, { search: searchValue });
      Object.assign(countChecklist, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countPdf, countChecklist, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllChecklistsCount(
        resp.data.countChecklistLibrary + resp.data.countPdfLibrary
      );
      setChecklists(prev => [
        ...prev,
        ...resp.data.checkemHomeDataLibrary.pdfs,
        ...resp.data.checkemHomeDataLibrary.checklists,
      ]);
      setPaginationLoading(false);
    });
    setFetchedCount(skip);
  };

  useEffect(() => {
    console.log(checklists);
    if (checklists.length) {
      sortBy === "alphabet"
        ? setChecklists((prev) => sortAlphabetically([...prev]))
        : setChecklists((prev) => sortByTime([...prev]));
    }
  }, [sortBy]);

  const handlePrevPage = () => {
    const skip = fetchedCount - PAGE_LIMIT;
    if (skip < 0) return;
    setLoading(true);
    const countPdf = {
      status: PublishingStatus.Published,
    };

    const countChecklist = {};
    const input = {
      skip: skip,
      take: PAGE_LIMIT,
    };
    if (!!searchValue) {
      Object.assign(countPdf, { search: searchValue });
      Object.assign(countChecklist, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countPdf, countChecklist, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllChecklistsCount(
        resp.data.countChecklistLibrary + resp.data.countPdfLibrary
      );
      setChecklists([
        ...resp.data.checkemHomeDataLibrary.pdfs,
        ...resp.data.checkemHomeDataLibrary.checklists,
      ]);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  useEffect(() => {
    loadData();
  }, [choosedOrganization?.id]);

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      loadData();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    folders,
    loading,
    paginationLoading,
    viewType,
    checklists,
    PAGE_LIMIT,
    searchValue,
    fetchedCount,
    draftsCount: allChecklistsCount,
    setViewType,
    setSearchValue,
    handleNextPage,
    handlePrevPage,
  };
};
