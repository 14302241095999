import { gql } from "@apollo/client";

export const GET_CHOOSED_ORGANIZATIOM = gql`
  query GetChoosedOrganization {
    choosedOrganization @client
  }
`;

export const GET_ALERT = gql`
  query GetAlert {
    alert @client
  }
`;

export const GET_SHOW_PANEL = gql`
  query GetShowPanel {
    showPanel @client
  }
`;

export const GET_ACTIVE_METRONOME = gql`
  query GetActiveMetronome {
    activeMetronome @client
  }
`;
