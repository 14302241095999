import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { enResources } from "./i18n/en";
import { ukResources } from "./i18n/uk";

export const resources = {
  en: enResources,
  uk: ukResources,
} as const;

let defaultLanguage = localStorage.getItem("language");
if (!defaultLanguage) {
  defaultLanguage = "en";
}

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
