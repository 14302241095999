import { useState } from "react";
import { IData, IError } from "../contants/models";
import { checkDataErrors, isDisabled } from "../helpers/dataValidator";
import { RESEND_VERIFY, SIGN_IN } from "~mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_CURRENT_USER } from "~queries";
import { useAuth } from "~hooks";
import { useNavigate } from "react-router-dom";
import { alertVar } from "~localCache";

export const useSignIn = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const [data, setData] = useState<IData>({
    email: "",
    password: "",
  });

  const [error, setError] = useState<IError>({});

  const [mutateSignIn, { loading: mainLoading }] = useMutation(SIGN_IN);

  const [mutateResendVerify] = useMutation(RESEND_VERIFY);

  const [queryGetUser, { loading: userLoading }] =
    useLazyQuery(GET_CURRENT_USER);

  const checkUserInfo = () => {
    queryGetUser({ variables: {} }).then((resp) => {
      setUser(resp.data.currentUser);
    });
  };

  const resendCode = () => {
    mutateResendVerify({
      variables: { resendVerifyInput: { email: data.email } },
    }).then((err) => {
      navigate("/confirmCode", { state: { email: data.email } });
    });
  };

  const onChangeData = (value: string, key: keyof IData) => {
    !!error && setError({});
    setData((prev) => {
      let state = { ...prev };
      state[key] = value;
      return state;
    });
  };

  const disableButton = isDisabled(data);

  const onSubmit = () => {
    const error = checkDataErrors(data);
    if (!!error) {
      setError(error);
      return;
    }

    mutateSignIn({
      variables: {
        signInInput: {
          email: data.email,
          password: data.password,
        },
      },
    })
      .then((resp) => {
        if (!!resp.data.singIn.access_token) {
          localStorage.setItem("accessToken", resp.data.singIn.access_token);
          localStorage.setItem("refreshToken", resp.data.singIn.refresh_token);
          checkUserInfo();
        } else resendCode();
      })
      .catch((err) => {
        setError({
          [err.graphQLErrors[0].extensions.response.error]:
            err.graphQLErrors[0].extensions.response.message,
        });
        alertVar({ type: "red", text: err?.graphQLErrors[0]?.message });
      });
  };

  return {
    data,
    onChangeData,
    mainLoading,
    error,
    disableButton,
    onSubmit,
  };
};
