import clsx from "clsx";
import { FC } from "react";
import { Avatar, Button, NumberWidget } from "~components";
import { BellNotification, DownArrow, PlusSymbol } from "~icons";
import { useOrganization } from "./hooks/useOrganizations";
import { InvateMembersModal } from "./components/InvateMembersModal";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import { LargePopup } from "./components/LargePopup";

interface IProps {
  handleOrganizationModalOpen: () => void;
  onLinkClick: () => void;
}

export const OrganizationModule: FC<IProps> = ({
  handleOrganizationModalOpen,
  onLinkClick,
}) => {
  const navigate = useNavigate();

  const {
    popupRef,
    modalRef,
    myOrganizations,
    choosedOrganization,
    handleCreateOrgnization,
    handleModalClose,
    handleChooseOrganization,
    handleInviteModalClose,
    handleInviteModalOpen,
    showInviteModal,
    invitionsCount,
    requestsCount,
    handleModalOpen,
  } = useOrganization({ handleOrganizationModalOpen });

  const showAllOrganizations = () => {
    handleModalClose();
    navigate("/dashboard/common/allOrganizations");
    onLinkClick();
  };

  const showSentRequest = () => {
    handleModalClose();
    navigate("/dashboard/common/sentRequests");
    onLinkClick();
  };

  const showInvitations = () => {
    handleModalClose();
    navigate("/dashboard/common/invitations");
    onLinkClick();
  };

  return (
    <>
      {showInviteModal && (
        <InvateMembersModal handleCloseModal={handleInviteModalClose} />
      )}
      <div className={styles.organizationModule} onClick={handleModalOpen}>
        {!!choosedOrganization ? (
          <div className={styles.organizationModule__content}>
            {/* <div className={styles.organizationModule__logo}> */}
            {/* {!!choosedOrganization?.picture && (
                <img src={choosedOrganization.picture} />
              )} */}
            <Avatar
              uri={choosedOrganization?.picture}
              item={choosedOrganization}
              type="organization"
              containerStyles={styles.organizationModule__logo}
            />
            {/* </div> */}
            <div className={styles.organizationModule__contentCanter}>
              <p className={styles.organizationModule__title}>
                {choosedOrganization?.name}
              </p>
              <p className={styles.organizationModule__subtitle}>
                {choosedOrganization?.counters.members + 1} members
              </p>
            </div>
          </div>
        ) : (
          <div className={styles.organizationModule__emptyText}>
            No organizations
          </div>
        )}

        <DownArrow fill={"#979DB1"} />

        <Popup
          ref={popupRef}
          className={styles.popupContent}
          closeOnDocumentClick={false}
          arrow={false}
          position={"bottom left"}
          trigger={<div style={{ position: "absolute", bottom: 0, left: 0 }} />}
        >
          {!!choosedOrganization ? (
            <LargePopup
              modalRef={modalRef}
              choosedOrganization={choosedOrganization}
              myOrganizations={myOrganizations}
              handleInviteModalOpen={handleInviteModalOpen}
              handleChooseOrganization={handleChooseOrganization}
              requestsCount={requestsCount}
              invitionsCount={invitionsCount}
              showAllOrganizations={showAllOrganizations}
              handleCreateOrgnization={handleCreateOrgnization}
              showSentRequest={showSentRequest}
              showInvitations={showInvitations}
            />
          ) : (
            <div ref={modalRef} className={styles.modalSmall}>
              <div className={styles.modalSmall__subtitle}>
                My Organizations
              </div>
              <p className={styles.modalSmall__emptyText}>No organizations</p>

              <div className={styles.organizationModule__buttonLine} />
              <div
                className={styles.modalSmall__item}
                onClick={showSentRequest}
              >
                Sent Requests
                <NumberWidget text={requestsCount} backgroundType={"gray"} />
              </div>
              <div className={styles.organizationModule__buttonLine} />
              <div
                className={styles.modalSmall__item}
                onClick={showInvitations}
              >
                <div className={styles.organizationModule__buttonNotification}>
                  <BellNotification fill="#EF2F32" />
                  Invitations
                </div>
                <NumberWidget text={invitionsCount} backgroundType={"red"} />
              </div>

              <Button
                onClick={showAllOrganizations}
                text="Show All Organizations"
                buttonClassName={styles.modalSmall__button}
                backgroundType={"gray"}
              />
              <div className={styles.modalSmall__underline} />
              <Button
                text="Create New Organization"
                showShadow
                onClick={handleCreateOrgnization}
                buttonClassName={clsx(
                  styles.modalSmall__button,
                  styles.marginBottom0
                )}
                backgroundType={"white"}
                icon={<PlusSymbol fill="#0B0F1C" />}
              />
            </div>
          )}
        </Popup>
      </div>
    </>
  );
};
