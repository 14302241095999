import { useRef, useState } from "react";
import { IItem } from "~models";
import { useChecklist } from "../../../../context";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const useDropdownData = ({ item, isDisable }: IProps) => {
  const { progress, setProgress } = useChecklist();
  const itemIndex = progress.data.items.findIndex(
    (elem: { id: number }) => elem.id === item.id
  );

  const [focused, setFocused] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  let titles = item.data.tables.map((item: { title: string }) => item.title);

  const handleActiveFocus = () => setFocused(true);
  const handleDisactiveFocus = (
    e: React.FocusEvent<HTMLTextAreaElement, Element>
  ) => {
    e.stopPropagation();
    setFocused(false);
  };

  const handleChoose = (index: number) => {
    if (isDisable) return;
    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].choosedItem = item.data.tables[index];
      return state;
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (isDisable) return;
    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].value = e.target.value;
      return state;
    });
  };

  return {
    handleActiveFocus,
    handleDisactiveFocus,
    handleInputChange,
    focused,
    textareaRef,
    titles,
    handleChoose,
  };
};
