import { gql } from "@apollo/client";

export const GET_DRAFTS = gql`
  query drafts($input: FindDraftInput!) {
    drafts(input: $input) {
      id
      title
      organizationId
      status
    }
  }
`;

export const GET_DRAFT = gql`
  query draft($id: Int!) {
    draft(id: $id) {
      id
      title
      organizationId
      status
      lastModified
      pdfUrl
      pdfName
      isPdfDeleted
      tabs {
        id
        name
        order
        checklistId
        draftId
        sections {
          id
          name
          description
          color
          order
          checkbox
          collapsable
          startCollapsable
          checklistTabId
          items {
            id
            description
            type
            data
            checkbox
            collapsable
            order
            startCollapsable
            tabSectionId
          }
        }
      }
    }
  }
`;

export const GET_DRAFTS_PAGINATION_DATA = gql`
  query getDraftsPaginationData(
    $countInput: CountDraftInput!
    $draftsInput: FindDraftInput!
  ) {
    countDraft(input: $countInput)
    drafts(input: $draftsInput) {
      id
      title
      organizationId
      status
    }
  }
`;

export const GET_CHECKEM_DRAFTS_DATA = gql`
  query getCheckemDraftsData(
    $input: FindDraftInputLibrary!
    $countInput: CountDraftInputLibrary!
  ) {
    draftsLibrary(input: $input) {
      id
      title
      organizationId
      status
      lastModified
    }
    countDraftLibrary(input: $countInput)
  }
`;

//=====================NEW========================

export const GET_ORGANIZATION_DRAFTS_DATA_NEW = gql`
  query getOrganizationDraftsDataNew(
    $input: FindDraftInput!
    $countPdfInput: CountPdfInput!
    $countDraftInput: CountDraftInput!
  ) {
    checkemBuilderData(input: $input) {
      pdfs {
        id
        title
        status
        file
        type
      }
      drafts {
        id
        title
        status
        lastModified
        type
      }
    }
    countPdf(input: $countPdfInput)
    countDraft(input: $countDraftInput)
  }
`;

export const GET_ORGANIZATION_DRAFTS_DATA_AND_FOLDERS_NEW = gql`
  query getOrganizationDraftsDataAndFoldersNew(
    $input: FindDraftInput!
    $folderInput: FindFolderInput!
    $countPdfInput: CountPdfInput!
    $countDraftInput: CountDraftInput!
  ) {
    folders(input: $folderInput) {
      id
      title
    }
    checkemBuilderData(input: $input) {
      pdfs {
        id
        title
        status
        file
        type
      }
      drafts {
        id
        title
        status
        type
        lastModified
      }
    }
    countPdf(input: $countPdfInput)
    countDraft(input: $countDraftInput)
  }
`;
