import { FC, useState } from "react";
import { Modal } from "~components";
import styles from "./styles.module.scss";
import { Document, Outline, Page, pdfjs } from "react-pdf";

interface IProps {
  onCloseModal: () => void;
  isTermsOfService?: boolean;
}

export const PrivacyModal: FC<IProps> = ({
  onCloseModal,
  isTermsOfService,
}) => {
  const [numPages, setNumPages] = useState(0);

  const options = {
    cMapUrl: "cmaps/",
    standardFontDataUrl: "standard_fonts/",
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  return (
    <Modal
      onCloseModal={onCloseModal}
      containerClassName={styles.privacyModal__modalContainer}
      modalClassName={styles.privacyModal__modal}
    >
      <div className={styles.privacyModal}>
        <div className={styles.privacyModal__content}>
          <Document
            options={options}
            loading={null}
            file={require(isTermsOfService
              ? "./Terms.pdf"
              : "./RefactorPrivacy2.pdf")}
            className={styles.privacyModal__contentDocument}
            // className={styles.privacyModal__contentDocument}
            // onLoadSuccess={() => alert(1)}
          >
            <>
              {Array.from(new Array(17), (el, index) => {
                return (
                  <Page
                    error={<div></div>}
                    className={styles.privacyModal__contentPage}
                    loading={<div></div>}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                );
              })}
            </>
          </Document>
        </div>
      </div>
    </Modal>
  );
};
