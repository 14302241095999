import { Button, Dropdown, Input, Modal, TextArea } from "~components";
import styles from "./styles.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FeedbackModal = ({ isOpen, onClose }: IProps) => {
  if (!isOpen) return null;
  return (
    <Modal
      containerClassName={styles.feedbackModal__container}
      disableMediaStyles
      onCloseModal={onClose}
    >
      <div className={styles.feedbackModal}>
        <p className={styles.feedbackModal__title}>Help & Feedback</p>
        <p className={styles.feedbackModal__subtitle}>
          Please contact us using this form with any questions, concerns, or
          feedback. Alternatively, you can email us at{" "}
          <span>support@resusc.io</span> or contact us via call/text at (650)
          674-2069.
        </p>
        {/* <div className={styles.feedbackModal__field}>
          <Dropdown
            label="Category"
            placeholder="Category"
            data={["Plan & Billing", "AI Response"]}
          />
        </div> */}
        <div className={styles.feedbackModal__field}>
          <Input label="Title" placeholder="Title" />
        </div>
        <TextArea label="Message" placeholder="Message" />
        <div className={styles.feedbackModal__buttons}>
          <Button backgroundType="white" showShadow text="Cancel" />
          <Button backgroundType="green" text="Send" />
        </div>
      </div>
    </Modal>
  );
};
