import React from "react";
import styles from "./styles.module.scss";
import ReactPlayer from "react-player";

interface Props {
    url: string;
    title: string;
}

export const VideoItem:React.FC<Props> = ({title, url}) => {
  return (
    <div className={styles.player}>
      <ReactPlayer
        url={url}
        width={330}
        height={250}
        style={{borderRadius: 7, overflow: 'hidden', position: 'relative'}}
        config={{
          youtube: {
            playerVars: { showinfo: 0, controls: 1, autoplay: 1 },
          },
        }}
        light={
          <div className={styles.player__thumbnail}>
            <img
              src="https://img.youtube.com/vi/LXb3EKWsInQ/0.jpg"
              alt="Thumbnail"
              width='100%'
              height='100%'
            />
          </div>
        }
        playIcon={
          <div className={styles.player__button}>
            {/* <div className={styles.player__buttonBackground}></div>
             */}
             <img src={require('../../../../../assets/images/play.png')} width={60} height={60} alt="" />
          </div>
        }
      />
      <p className={styles.player__title}>{title}</p>
    </div>
  );
};
