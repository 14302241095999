import { useLazyQuery, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  GET_ALL_ACTIVE_PROGRESSES,
  GET_ALL_COMPLETED_PROGRESSES_DATA,
  GET_ALL_ORGANIZATION_COMPLETED_PROGRESSES,
} from "~queries";
import { GET_CHOOSED_ORGANIZATIOM, viewTypes } from "~localCache";
import { useNavigate } from "react-router-dom";
import { useUpdateEffect } from "usehooks-ts";
import { REMOVE_PROGRESS } from "~mutations";
import { useEffect, useState } from "react";
import { IActiveChecklist } from "~models";

const PAGE_LIMIT = 10;

export const useCompletedChecklists = () => {
  const navigate = useNavigate();

  const [removeProgress] = useMutation(REMOVE_PROGRESS);

  const viewType = useReactiveVar(viewTypes)

  const setViewType = (type: 'table' | 'grid') => {
    viewTypes(type)
  }


  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [updateActiveProgress] = useLazyQuery(GET_ALL_ACTIVE_PROGRESSES);

  const [fetchedCount, setFetchedCount] = useState(0);
  const [checklists, setChecklists] = useState<IActiveChecklist[]>([]);
  const [allChecklistsCount, setAllChecklistsCount] = useState(0);

  const [searchValue, setSearchValue] = useState<string>("");
  const [checklistDeletionId, setChecklistDeletionId] = useState<number | null>(
    null
  );

  const handleOpenDeleteModal = (id: number) => setChecklistDeletionId(id);
  const handleCloseDeleteModal = () => setChecklistDeletionId(null);

  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);

  const [getData] = useLazyQuery(GET_ALL_ORGANIZATION_COMPLETED_PROGRESSES);

  const loadData = () => {
    setLoading(true);

    const countInput = {
      completed: true,
    };

    const input = {
      skip: 0,
      take: PAGE_LIMIT,

      completed: true,
    };

    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }

    getData({
      variables: { countInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      fetchedCount !== 0 && setFetchedCount(0);

      setAllChecklistsCount(resp.data.getAllProgressesByCompleteStatusCount);
      setChecklists(resp.data.getAllProgressesByCompleteStatus);
      setLoading(false);
    });
  };

  const updateCurrentPage = () => {
    const maxPage = Math.ceil(allChecklistsCount);
    let skip = fetchedCount;
    if (checklists.length === 1 && fetchedCount >= PAGE_LIMIT) {
      skip = skip - PAGE_LIMIT;
    }
    if (skip > maxPage) return;
    setLoading(true);
    let take = PAGE_LIMIT;
    if (allChecklistsCount - skip < PAGE_LIMIT) {
      take = allChecklistsCount - skip;
    }
    const countInput = {
      completed: true,
    };
    const input = {
      skip: skip,
      take: PAGE_LIMIT,

      completed: true,
    };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllChecklistsCount(resp.data.getAllProgressesByCompleteStatusCount);
      setChecklists(resp.data.getAllProgressesByCompleteStatus);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(allChecklistsCount);
    const skip = fetchedCount + PAGE_LIMIT;
    if (skip >= maxPage) return;
    setPaginationLoading(true);
    let take = PAGE_LIMIT;
    if (allChecklistsCount - skip < PAGE_LIMIT) {
      take = allChecklistsCount - skip;
    }
    const countInput = {
      completed: true,
    };
    const input = {
      skip: skip,
      take: PAGE_LIMIT,

      completed: true,
    };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllChecklistsCount(resp.data.getAllProgressesByCompleteStatusCount);
      setChecklists((prev) => [
        ...prev,
        ...resp.data.getAllProgressesByCompleteStatus,
      ]);
      setPaginationLoading(false);
    });
    setFetchedCount(skip);
  };

  const handlePrevPage = () => {
    const skip = fetchedCount - PAGE_LIMIT;
    if (skip < 0) return;
    setLoading(true);
    const countInput = {
      completed: true,
    };
    const input = {
      skip: skip,
      take: PAGE_LIMIT,

      completed: true,
    };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllChecklistsCount(resp.data.getAllProgressesByCompleteStatusCount);
      setChecklists(resp.data.getAllProgressesByCompleteStatus);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  const handleContinue = (checklistId: number, progressId: number) => {
    navigate(`/checklist/${checklistId}?progressId=${progressId}`);
  };

  const deleteProgress = async (id: number) => {
    handleCloseDeleteModal();
    setLoading(true);
    await removeProgress({ variables: { id } }).then(async () => {
      updateCurrentPage();
      // await updateActiveProgress({
      //   variables: { organizationId: choosedOrganization.id },
      //   fetchPolicy: "network-only",
      // });
    });
  };

  useEffect(() => {
    loadData();
  }, [choosedOrganization?.id]);

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      loadData();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    checklists,
    searchValue,
    viewType,
    draftsCount: allChecklistsCount,
    fetchedCount,
    loading,
    paginationLoading,
    PAGE_LIMIT,
    checklistDeletionId,
    setSearchValue,
    setViewType,
    handleNextPage,
    handlePrevPage,
    handleContinue,
    deleteProgress,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
  };
};
