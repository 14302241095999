import React, { FC, useRef, useState } from "react";

import { clsx } from "clsx";

import styles from "./styles.module.scss";
import { DownArrow } from "~icons";
import { useOutsideClick } from "~hooks";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";
import FroalaEditor from "react-froala-wysiwyg";

interface IProps {
  label?: string;
  placeholder: string;
  value?: string;
  onChange?: (index: number, value: string) => void;
  data: string[];
  onChangeDropdownState?: (value: boolean) => void;
  rotate?: boolean;
  isDisable?: boolean;
}
export const Dropdown: FC<IProps> = ({
  label,
  placeholder,
  value = "",
  onChange,
  data,
  onChangeDropdownState,
  rotate,
  isDisable,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isDark = useReactiveVar(darkTheme);

  const handleDropdownChange = (index: number) => {
    if (isDisable) return;
    !!onChange && onChange(index, data[index]);
    setIsOpen(false);
  };

  const toggleDropdownState = () => {
    if (isDisable) return;
    !!onChangeDropdownState && onChangeDropdownState(!isOpen);
    setIsOpen(!isOpen);
  };
  const handleDropdownClose = () => {
    if (isDisable) return;
    if (isOpen) {
      !!onChangeDropdownState && onChangeDropdownState(false);
      setIsOpen(false);
    }
  };

  useOutsideClick(dropdownRef, handleDropdownClose);

  return (
    <div ref={dropdownRef} className={styles.dropdownContainer}>
      {!!label && (
        <div className={styles.dropdownContainer__label}>{label}</div>
      )}
      <div
        onClick={toggleDropdownState}
        className={clsx(styles.dropdownContainer__field, {
          [styles.dropdownContainer__fieldPlaceholder]: !value,
          [styles.active]: isOpen,
          [styles.disable]: isDisable,
        })}
      >
        <FroalaEditor
          model={!!value ? value : placeholder}
          config={{
            key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
            htmlUntouched: true,
            toolbarInline: true,
            toolbarVisibleWithoutSelection: true,
            isDisable: true,
            events: {
              initialized() {
                const editor: any = this;
                editor.edit.off();
              },
            },
          }}
        />
        <div
          className={clsx(styles.dropdownContainer__fieldIcon, {
            [styles.rotate]: isOpen,
          })}
        >
          <DownArrow
            fill={isDark ? "white" : isDisable ? "#DBDEE7" : "#0B0F1C"}
          />
        </div>
      </div>

      <div
        className={clsx(styles.dropdownContainer__select, {
          [styles.active60]: isOpen && data.length === 1,
          [styles.active105]: isOpen && data.length === 2,
          [styles.active150]: isOpen && data.length > 2,
          [styles.close]: !isOpen,
          [styles.rotate]: rotate,
        })}
      >
        <div className={styles.dropdownContainer__selectBlock}>
          {data.map((item, index) => (
            <div
              onClick={() => handleDropdownChange(index)}
              key={`${item} - ${index}`}
              className={styles.dropdownContainer__item}
            >
              <FroalaEditor
                model={item}
                config={{
                  key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                  htmlUntouched: true,
                  toolbarInline: true,
                  toolbarVisibleWithoutSelection: true,
                  isDisable: true,
                  events: {
                    initialized() {
                      const editor: any = this;
                      editor.edit.off();
                    },
                  },
                }}
              />
              {/* {item} */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
