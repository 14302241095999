import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { UPDATE_USER } from "~mutations";
import { alertVar } from "~localCache";
import { uploadFile } from "~services";
import { IUser } from "~models";

interface IProps {
  editableUser: IUser | null;
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
}

export const useEditGeneral = ({ editableUser, setUser }: IProps) => {
  const [mutateUpdateUser] = useMutation(UPDATE_USER);
  const [file, setFile] = useState<Blob | null>(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [loadingCredential, setLoadingCredential] = useState(false);

  let avatarUri = !!file
    ? URL.createObjectURL(file)
    : !!editableUser?.avatar
    ? editableUser?.avatar
    : "";

  const isNoChanges =
    firstName === editableUser?.firstName &&
    lastName === editableUser.lastName &&
    !file;

  const isDisableCredentialButtons =
    firstName.length < 2 || lastName.length < 2 || isNoChanges;

  const handleCancelCredential = () => {
    if (!editableUser) return;
    setFile(null);
    setFirstName(editableUser.firstName);
    setLastName(editableUser.lastName);
  };

  const saveCredentialChanges = async () => {
    setLoadingCredential(true);
    let updateUserInput = {
      id: editableUser?.id,
      firstName,
      lastName,
    };
    if (!!file) {
      let avatarUri = await uploadFile(file, file.type);
      Object.assign(updateUserInput, { avatar: avatarUri });
    }

    mutateUpdateUser({ variables: { updateUserInput } })
      .then((resp) => {
        setUser({ ...editableUser, ...resp.data.updateUser });
        !!file && setFile(null);
        setLoadingCredential(false);
        alertVar({ type: "green", text: "Changes was successfully saved" });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!!editableUser) {
      setFirstName(editableUser.firstName);
      setLastName(editableUser.lastName);
    }
  }, [editableUser]);

  return {
    firstName,
    lastName,
    file,
    avatarUri,
    loadingCredential,
    isDisableCredentialButtons,
    setFile,
    setFirstName,
    setLastName,
    handleCancelCredential,
    saveCredentialChanges,
  };
};
