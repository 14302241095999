import { IOrganization, IOrganizationRequest } from "~models";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { useAuth } from "~hooks";
import { alertVar } from "~localCache";
import {
  CANCEL_ORGANIZATION_REGUEST,
  SEND_ORGANIZATION_REGUEST,
} from "~mutations";
import {
  GET_MY_ORGANIZATION_REQUESTS,
  GET_ORGANIZATION_DETAIL,
} from "~queries";

interface IProps {
  item: IOrganization;
  index: number;
  handleChangeRequestsField: (
    index: number,

    value: IOrganizationRequest[]
  ) => void;
  updateOrganization: (index: number, value: IOrganization) => void;
}

export const useOrganizationItem = ({
  item,
  index,
  handleChangeRequestsField,
  updateOrganization: updateLocalOrganization,
}: IProps) => {
  const { user } = useAuth();

  const [mutateSendRequest] = useMutation(SEND_ORGANIZATION_REGUEST);
  const [mutateCancelRequest] = useMutation(CANCEL_ORGANIZATION_REGUEST);
  const [queryGetMyRequests] = useLazyQuery(GET_MY_ORGANIZATION_REQUESTS);
  const [getOrganizationDetail] = useLazyQuery(GET_ORGANIZATION_DETAIL);

  const [loading, setLoading] = useState(false);

  const updateOrganizationData = () => {
    queryGetMyRequests({ variables: {}, fetchPolicy: "network-only" });
  };

  const handleSendRequest = () => {
    setLoading(true);
    mutateSendRequest({ variables: { organizationId: item.id } })
      .then((resp) => {
        const requests = resp.data.sendOrganizationRequest.requests;
        handleChangeRequestsField(index, requests);
        updateOrganizationData();
        setLoading(false);
      })
      .catch((err) => {
        alertVar({ type: "red", text: err.graphQLErrors[0].message });
        setLoading(false);
      });
  };

  const updateOrganization = () => {
    getOrganizationDetail({ variables: { id: item.id } })
      .then((resp) => {
        updateLocalOrganization(index, { ...item, ...resp.data.organization });
        setLoading(false);
      })
      .catch((err) => {
        alertVar({ type: "red", text: err?.graphQLErrors[0]?.message });
        setLoading(false);
      });
  };

  const handleCancelRequest = () => {
    setLoading(true);
    let requestId = !!item?.requests ? item.requests[0].id : "";
    mutateCancelRequest({
      variables: {
        input: {
          requestId: requestId,
          organizationId: item.id,
          userId: user?.id,
        },
      },
    })
      .then((resp) => {
        handleChangeRequestsField(index, []);
        updateOrganizationData();
        setLoading(false);
      })
      .catch((err) => {
        if (
          err?.graphQLErrors[0]?.message ===
          "You already added to this organization"
        ) {
          alertVar({ type: "red", text: err?.graphQLErrors[0]?.message });
          updateOrganization();
        } else {
          alertVar({ type: "red", text: err?.graphQLErrors[0]?.message });
          handleChangeRequestsField(index, []);
          setLoading(false);
        }
      });
  };

  const isIAmMember = !!item.members && item.members.length > 0;
  const isISendRequest = !!item.requests && item.requests.length > 0;

  return {
    handleSendRequest,
    handleCancelRequest,
    loading,
    isIAmMember,
    isISendRequest,
  };
};
