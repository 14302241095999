import styles from "./styles.module.scss";

import { IItem } from "../../../../../constants";
import { Adults, Childrens } from "~icons";

interface IProps {
  item: IItem;
  isDisable: boolean;
}

export const BurnedAreas = ({ item, isDisable }: IProps) => {
  const type =
    typeof item.data === "string" ? JSON.parse(item.data).type : item.data.type;
  return (
    <div className={styles.burnedAreas}>
      {type === "Adult" ? <Adults /> : <Childrens />}
    </div>
  );
};
