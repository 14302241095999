import { useLazyQuery, useMutation } from "@apollo/client";
import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "~hooks";
import { APPLE_SIGN_IN, CREATE_USER, GOOGLE_SIGN_IN } from "~mutations";
import { GET_CURRENT_USER } from "~queries";
import { IData, IError } from "../contants/models";
import { checkDataErrors, isDisabled } from "../helpers/dataValidator";

export const useSignUp = () => {
  const { setUser } = useAuth();
  const navigate = useNavigate();

  const [mutateAppleSignIn, { loading: appleLoading }] =
    useMutation(APPLE_SIGN_IN);

  const [data, setData] = useState<IData>({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState<IError>({});

  const [mutateFunction, { loading, error: respError }] =
    useMutation(CREATE_USER);
  const [mutateGoogleSignIn, { loading: googleLoading }] =
    useMutation(GOOGLE_SIGN_IN);

  const [queryGetUser] = useLazyQuery(GET_CURRENT_USER);

  const onChangeData = (value: string, key: keyof IData) => {
    !!error && setError({});
    setData((prev) => {
      let state = { ...prev };
      state[key] = value;
      return state;
    });
  };

  const checkUserInfo = () => {
    queryGetUser({ variables: {} }).then((resp) => {
      setUser(resp.data.currentUser);
    });
  };

  const handleAppleResponse = (response: any) => {
    console.log("handleAppleResponse", handleAppleResponse);
    mutateAppleSignIn({
      variables: {
        signInAppleInput: {
          idToken: response?.authorization?.id_token,
        },
      },
    })
      .then((resp) => {
        localStorage.setItem("accessToken", resp.data.singInApple.access_token);
        localStorage.setItem(
          "refreshToken",
          resp.data.singInApple.refresh_token
        );
        checkUserInfo();
      })
      .catch((err) => {
        console.log("ERRRRR", err);
        setError({
          [err.graphQLErrors[0].extensions.response.error]:
            err.graphQLErrors[0].extensions.response.message,
        });
      });
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      !!error && setError({});
      mutateGoogleSignIn({
        variables: {
          signInGoogleInput: {
            token: tokenResponse.access_token,
          },
        },
      })
        .then((resp) => {
          localStorage.setItem(
            "accessToken",
            resp.data.singInGoogle.access_token
          );
          localStorage.setItem(
            "refreshToken",
            resp.data.singInGoogle.refresh_token
          );
          checkUserInfo();
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  });

  const disableButton = isDisabled(data);

  const onSubmit = () => {
    const error = checkDataErrors(data);
    if (!!error) {
      setError(error);
      return;
    }

    mutateFunction({
      variables: {
        createUserInput: {
          email: data.email,
          password: data.password,
        },
      },
    })
      .then((resp) => {
        navigate("/confirmCode", { state: { email: data.email } });
      })
      .catch((err) => {
        setError({
          [err.graphQLErrors[0].extensions.response.error]:
            err.graphQLErrors[0].extensions.response.message,
        });
      });
  };

  return {
    data,
    onChangeData,
    loading,
    error,
    disableButton,
    onSubmit,
    googleLoading,
    handleGoogleLogin,
    handleAppleResponse,
  };
};
