import { FC, ReactNode, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import styles from "./styles.module.scss";
import { Button, Modal } from "~components";
import { useImagePicker } from "./useImagePicker";

interface IProps {
  children: ReactNode;
  containerClassName: string;
  saveImage: (val: Blob) => void;
  cropShape?: "rect" | "round";
  subtitleComponent?: ReactNode;
}

export const ImagePicker: FC<IProps> = ({
  children,
  containerClassName,
  saveImage,
  cropShape = "rect",
  subtitleComponent,
}) => {
  const {
    onDrop,
    imageUrl,
    setImage,
    crop,
    zoom,
    setCrop,
    onCropComplete,
    setZoom,
    loading,
    cropImage,
  } = useImagePicker({ saveImage });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const style = {
    containerStyle: {
      width: "460px",
      height: "460px",
      margin: "0 auto",
      top: "20px",
    },
  };

  const renderCropper = useCallback(() => {
    return (
      <Cropper
        image={imageUrl}
        crop={crop}
        zoom={zoom}
        aspect={1 / 1}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        objectFit={"contain"}
        style={style}
        cropShape={cropShape}
      />
    );
  }, [imageUrl, crop, zoom]);

  return (
    <div>
      {!!imageUrl && (
        <Modal onCloseModal={() => setImage(null)}>
          <div className={styles.cropModal}>
            {renderCropper()}
            <div className={styles.cropModal__controls}>
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(+e.target.value);
                }}
                className={styles.cropModal__zoomRange}
              />
            </div>
            <div className={styles.cropModal__buttons}>
              <Button
                text="Cancel"
                onClick={() => setImage(null)}
                backgroundType={"white"}
                showShadow
              />
              <Button
                loading={loading}
                onClick={cropImage}
                text="Save"
                backgroundType={"green"}
              />
            </div>
          </div>
        </Modal>
      )}

      <div>
        <div className={containerClassName} {...getRootProps()}>
          <input {...getInputProps()} />
          {children}
          {subtitleComponent}
        </div>
      </div>
    </div>
  );
};
