import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation createUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      id
      firstName
      lastName
      avatar
      email
      isVerified
      createdAt
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input)
  }
`;

export const DELETE_USER = gql`
  mutation removeUser($id: Int!) {
    removeUser(id: $id)
  }
`;

export const RESEND_VERIFY = gql`
  mutation resendVerify($resendVerifyInput: ResendVerifyInput!) {
    resendVerify(resendVerifyInput: $resendVerifyInput)
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($verifyInput: VerifyEmailInput!) {
    verifyEmail(verifyInput: $verifyInput) {
      access_token
      refresh_token
    }
  }
`;

export const SIGN_IN = gql`
  mutation singIn($signInInput: SignInInput!) {
    singIn(signInInput: $signInInput) {
      access_token
      refresh_token
    }
  }
`;

export const GOOGLE_SIGN_IN = gql`
  mutation singInGoogle($signInGoogleInput: SignInGoogleInput!) {
    singInGoogle(signInGoogleInput: $signInGoogleInput) {
      access_token
      refresh_token
    }
  }
`;

export const APPLE_SIGN_IN = gql`
  mutation singInApple($signInAppleInput: SignInAppleInput!) {
    singInApple(signInAppleInput: $signInAppleInput) {
      access_token
      refresh_token
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($resetPasswordInput: CreateResetPasswordInput!) {
    resetPassword(resetPasswordInput: $resetPasswordInput)
  }
`;

export const CHECK_RESET_CODE = gql`
  mutation resetCheckCode($resetCheckCodeInput: CheckCodeInput!) {
    resetCheckCode(resetCheckCodeInput: $resetCheckCodeInput)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($changePasswordInput: ChangePasswordInput!) {
    changePassword(changePasswordInput: $changePasswordInput)
  }
`;

export const ACCEPT_ORGANIZATION_INVITATION = gql`
  mutation acceptOrganizationInvitations($id: Int!) {
    acceptOrganizationInvitations(id: $id) {
      id
      name
      picture
      additionalMembers
      isPrivate
      createdAt
      status
      plan
      _count {
        members
        requests
      }
      members {
        id
        role
      }
      counters {
        members
        admins
        adminsLeft
        membersLeft
      }
    }
  }
`;

export const DECLINE_ORGANIZATION_INVITATION = gql`
  mutation declineOrganizationInvitations($id: Int!) {
    declineOrganizationInvitations(id: $id)
  }
`;

export const LEAVE_ORGANIZATION = gql`
  mutation removeOrganizationMember($id: Int!) {
    removeOrganizationMember(id: $id)
  }
`;

export const UPDATE_EMAIL = gql`
  mutation updateEmail($input: UpdateEmailInput!) {
    updateEmail(input: $input) {
      id
    }
  }
`;

export const COMPLETE_UPDATE_EMAIL = gql`
  mutation completeUpdateEmail($input: VerifyEmailInput!) {
    completeUpdateEmail(input: $input) {
      id
      email
    }
  }
`;
