import { useEffect } from "react";
import { useChecklist } from "../../../../context";
import { useStopwatch as useStopwatchHoc } from "react-timer-hook";
import { alarmSounds } from "~audio";
import { IItem } from "~models";
import { useDidUpdateEffect } from "~hooks";
import { useMutation } from "@apollo/client";
import { SET_NOTIFICATION, REMOVE_NOTIFICATION } from "~mutations";

interface IProps {
  item: IItem;
  progressItem: any;
}

export const useStopwatch = ({ item, progressItem }: IProps) => {
  const { progress, setProgress } = useChecklist();

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [removeNotification] = useMutation(REMOVE_NOTIFICATION);

  const itemIndex = progress.data.items.findIndex(
    (elem: { id: number }) => elem.id === item.id
  );

  const { start, isRunning, pause, reset, seconds, minutes, hours } =
    useStopwatchHoc({
      autoStart: false,
    });

  const totalSeconds = hours * 60 * 60 + minutes * 60 + seconds;

  const continueTimersAfterEachOther = () => {
    let dates: any = [];
    let leftSeconds = !!progressItem?.seconds ? +progressItem?.seconds : 0;

    let prevDate = 0;
    let currentDate = new Date().getTime();

    console.log("currentDate", new Date());

    item.data.alarms.forEach((alarm: any) => {
      let minutes = !!alarm.minutes ? +alarm.minutes : 0;
      let seconds = !!alarm.seconds ? +alarm.seconds : 0;
      let ararmTotalSeconds = minutes * 60 + seconds;

      if (!!alarm.repeat || ararmTotalSeconds > leftSeconds) {
        const closestMultipleOfPeriod =
          Math.ceil(totalSeconds / +alarm.seconds) * +alarm.seconds;
        const secondsToNextAlarm =
          closestMultipleOfPeriod - totalSeconds > 0
            ? closestMultipleOfPeriod - totalSeconds + leftSeconds
            : +ararmTotalSeconds;

        console.log("seconds  toNextAlarm", secondsToNextAlarm);

        console.log(
          "timestamp toNextAlarm",
          currentDate + secondsToNextAlarm + prevDate - leftSeconds * 1000
        );

        let executeDate = new Date(
          currentDate + (secondsToNextAlarm + prevDate - leftSeconds) * 1000
        );

        if (!alarm?.repeat) prevDate = secondsToNextAlarm + prevDate;

        dates.push({
          repeat: alarm.repeat,
          message: alarm.text,
          sound: alarm.sound,
          seconds: ararmTotalSeconds,
          executeDate,
        });
      }
    });

    const data = {
      itemId: progressItem.id,
      itemType: progressItem.type,
      progressId: progress.id,
      scheduleData: dates,
    };

    return data;
  };

  // const startTimersAfterEachOther = () => {
  //   let dates: any = [];
  //   let leftSeconds = !!progressItem?.seconds ? +progressItem?.seconds : 0;

  //   let prevDate = 0;
  //   let currentDate = new Date().getTime();

  //   item.data.alarms.forEach((alarm: any) => {
  //     let minutes = !!alarm.minutes ? +alarm.minutes : 0;
  //     let seconds = !!alarm.seconds ? +alarm.seconds : 0;
  //     let ararmTotalSeconds = minutes * 60 + seconds;

  //     if (!!alarm.repeat || ararmTotalSeconds > leftSeconds) {
  //       const closestMultipleOfPeriod =
  //         Math.ceil(totalSeconds / +alarm.seconds) * +alarm.seconds;
  //       const secondsToNextAlarm =
  //         closestMultipleOfPeriod - totalSeconds > 0
  //           ? closestMultipleOfPeriod - totalSeconds
  //           : +ararmTotalSeconds;

  //       if (!alarm?.repeat) prevDate = secondsToNextAlarm + prevDate;

  //       let executeDate = new Date(
  //         currentDate + secondsToNextAlarm + prevDate * 1000
  //       );

  //       dates.push({
  //         repeat: alarm.repeat,
  //         message: alarm.text,
  //         sound: alarm.sound,
  //         seconds: ararmTotalSeconds,
  //         executeDate,
  //       });
  //     }
  //   });

  //   const data = {
  //     itemId: progressItem.id,
  //     itemType: progressItem.type,
  //     progressId: progress.id,
  //     scheduleData: dates,
  //   };

  //   return data;
  // };

  const test = (executeTime: number, secondsStep: number) => {
    if (totalSeconds < executeTime) {
      return executeTime - totalSeconds;
    } else {
      const totalSecondsWithoutRingTime = totalSeconds - executeTime;
      const stepsIntoTotalSeconds = Math.floor(
        totalSecondsWithoutRingTime / secondsStep
      );
      return (
        secondsStep -
        (totalSecondsWithoutRingTime - stepsIntoTotalSeconds * secondsStep)
      );
    }
  };

  const continueAllTimersAtOnce = (isСontinuation?: boolean) => {
    const minimizationDeviationInMiliseconds = isСontinuation ? 250 : 0;

    let dates: any = [];
    let leftSeconds = !!progressItem?.seconds ? +progressItem?.seconds : 0;
    let currentDate = new Date().getTime();

    item.data.alarms.forEach((alarm: any) => {
      let minutes = !!alarm.minutes ? +alarm.minutes : 0;
      let seconds = !!alarm.seconds ? +alarm.seconds : 0;
      let ararmTotalSeconds = minutes * 60 + seconds;

      if (!!alarm.repeat || ararmTotalSeconds > leftSeconds) {
        const closestMultipleOfPeriod =
          Math.ceil(totalSeconds / +alarm.seconds) * +alarm.seconds;
        const secondsToNextAlarm =
          closestMultipleOfPeriod - totalSeconds > 0
            ? closestMultipleOfPeriod - totalSeconds
            : +ararmTotalSeconds;

        const executeDate = new Date(
          currentDate +
            secondsToNextAlarm * 1000 -
            minimizationDeviationInMiliseconds
        );

        const ringMinutes = !!alarm.ringMinutes ? +alarm.ringMinutes : 0;
        const ringSeconds = !!alarm.ringSeconds ? +alarm.ringSeconds : 0;

        dates.push({
          repeat: alarm.repeat,
          message: alarm.text,
          sound: alarm.sound,
          seconds: ararmTotalSeconds,
          executeDate: !!alarm.repeat
            ? new Date(
                +new Date() +
                  test(ringMinutes * 60 + ringSeconds, ararmTotalSeconds) * 1000
              )
            : executeDate,
        });
      }
    });

    const data = {
      itemId: progressItem.id,
      itemType: progressItem.type,
      progressId: progress.id,
      scheduleData: dates,
    };

    return data;
  };

  // const startAllTimersAtOnce = (isСontinuation?: boolean) => {
  //   const minimizationDeviationInMiliseconds = isСontinuation ? 250 : 0;

  //   console.log("startAllTimersAtOnce");

  //   let dates: any = [];
  //   let leftSeconds = !!progressItem?.seconds ? +progressItem?.seconds : 0;
  //   let currentDate = new Date().getTime();

  //   item.data.alarms.forEach((alarm: any) => {
  //     let minutes = !!alarm.minutes ? +alarm.minutes : 0;
  //     let seconds = !!alarm.seconds ? +alarm.seconds : 0;
  //     let ararmTotalSeconds = minutes * 60 + seconds;

  //     if (!!alarm.repeat || ararmTotalSeconds > leftSeconds) {
  //       const closestMultipleOfPeriod =
  //         Math.ceil(totalSeconds / +alarm.seconds) * +alarm.seconds;
  //       const secondsToNextAlarm =
  //         closestMultipleOfPeriod - totalSeconds > 0
  //           ? closestMultipleOfPeriod - totalSeconds
  //           : +ararmTotalSeconds;

  //       const executeDate = new Date(
  //         currentDate +
  //           secondsToNextAlarm * 1000 -
  //           minimizationDeviationInMiliseconds
  //       );

  //       console.log("executeDate", executeDate);
  //       console.log("new Date()", new Date());

  //       const ringMinutes = !!alarm.ringMinutes ? +alarm.ringMinutes : 0;
  //       const ringSeconds = !!alarm.ringSeconds ? +alarm.ringSeconds : 0;

  //       dates.push({
  //         repeat: alarm.repeat,
  //         message: alarm.text,
  //         sound: alarm.sound,
  //         seconds: ararmTotalSeconds,
  //         executeDate: !!alarm.repeat
  //           ? new Date(+new Date() + (ringMinutes * 60 + ringSeconds) * 1000)
  //           : executeDate,
  //       });
  //     }
  //   });

  //   const data = {
  //     itemId: progressItem.id,
  //     itemType: progressItem.type,
  //     progressId: progress.id,
  //     scheduleData: dates,
  //   };

  //   return data;
  // };

  const handleStart = () => {
    let time = new Date().getTime();

    const timersData = continueAllTimersAtOnce();

    if (!progressItem.firstStart) {
      setProgress((prev) => {
        let state = { ...prev };
        state.data.items[itemIndex].firstStart = time;
        state.data.items[itemIndex].paused = false;
        return state;
      });
    } else {
      setProgress((prev) => {
        let state = { ...prev };
        state.data.items[itemIndex].lastStart = time;
        state.data.items[itemIndex].paused = false;
        return state;
      });
    }

    start();

    !progressItem.isMuted &&
      setNotification({ variables: { input: timersData } }).then(() => {});
  };

  const handlePause = () => {
    removeNotification({
      variables: {
        input: { itemId: progressItem.id },
      },
    });
    let time = new Date().getTime();
    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].seconds = totalSeconds;
      state.data.items[itemIndex].paused = true;
      state.data.items[itemIndex].lastPause = time;
      return state;
    });
    pause();
  };

  const handleResetWithServer = () => {
    removeNotification({
      variables: {
        input: { itemId: progressItem.id },
      },
    });
    handleReset();
  };

  const handleReset = () => {
    const time = new Date();
    reset(time, false);

    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].seconds = 0;
      state.data.items[itemIndex].firstStart = 0;
      state.data.items[itemIndex].lastStart = 0;
      state.data.items[itemIndex].lastPause = 0;
      return state;
    });
  };

  const toggleMute = () => {
    if (progressItem?.isMuted) {
      if (!progressItem.paused) {
        let data = continueAllTimersAtOnce();
        setNotification({ variables: { input: data } }).then(() => {});
      }
    } else {
      removeNotification({
        variables: {
          input: { itemId: progressItem.id, progressId: progress.id },
        },
      });
    }

    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].isMuted = !progressItem?.isMuted;
      return state;
    });
  };

  const toHHMMSS = (secs: string) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    let res = [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");

    if (+secs > 3600) {
      return res;
    } else return "00:" + res;
  };

  const continueStopwatch = (seconds: number) => {
    const fullSeconds = Math.floor(seconds);
    let miliseconds = +(Math.round(seconds * 100) / 100)
      .toFixed(3)
      .split(".")[1];

    const time = new Date();
    time.setSeconds(
      time.getSeconds() + fullSeconds,
      time.getMilliseconds() + miliseconds
    );
    reset(time, !progressItem?.paused);
  };

  useEffect(() => {
    if (progressItem?.paused) {
      if (!!progressItem?.seconds && +progressItem?.seconds) {
        continueStopwatch(+progressItem?.seconds);
      }
    } else {
      if (!!progressItem?.firstStart || !!progressItem?.lastStart) {
        let time = new Date().getTime();
        let difference =
          (!!progressItem?.lastStart
            ? time - +progressItem?.lastStart
            : time - +progressItem?.firstStart) / 1000;
        if (+progressItem?.seconds) {
          difference = difference + +progressItem.seconds;
        }
        continueStopwatch(difference);
      }
    }
  }, []);

  useDidUpdateEffect(() => {
    if (progressItem?.paused && isRunning) pause();
  }, [progressItem?.paused]);

  useEffect(() => {
    if (!progressItem?.firstStart) handleReset();
  }, [progressItem?.firstStart]);

  const disableResetButton = totalSeconds === 0;

  return {
    handleStart,
    handleReset,
    toggleMute,
    continueTimersAfterEachOther,
    handleResetWithServer,
    isRunning,
    handlePause,
    value: toHHMMSS(`${totalSeconds}`),
    disableResetButton,
  };
};
