import clsx from "clsx";
import { IItem, ISection } from "../../constants";
import styles from "./styles.module.scss";
import { useSection } from "./useSection";
import {
  DotsVertical,
  DownArrow,
  Duplicate,
  ExclamationCircle,
  LeftArrow,
  Loader,
  TrashAlt,
} from "~icons";
import { Button, Checkbox } from "~components";
import AutosizeInput from "../../../../../../components/InputAutosize";
import { Item } from "./Item";
import { Droppable, Draggable } from "react-beautiful-dnd";
import FroalaEditor from "react-froala-wysiwyg";

interface IProps {
  section: ISection;
  index: number;
  tabIndex?: number;
}

export const Section = ({ section, index, tabIndex }: IProps) => {
  const {
    isSectionActive,
    enableActiveSection,
    collapsed,
    sectionLoading,
    showError,
    showPopup,
    handleOpenPopup,
    popupContentRef,
    toggleCollapsed,
    addItem,
    sectionIndex,
    pdfFormat,
    deleteSection,
    handleChangeName,
    handleAddSection,
    handleChangeDescription,
    moveItemDown,
    moveItemUp,
  } = useSection({
    section,
    index,
  });

  if (section.id === 0) {
    return (
      <div className={styles.section__loader}>
        <Loader fill="#29B473" />
      </div>
    );
  }

  return (
    <Draggable
      isDragDisabled={pdfFormat}
      draggableId={`${section.id}`}
      index={index}
    >
      {(provided) => (
        <div
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={styles.section__wrapper}
        >
          <Droppable droppableId={`${section?.id}`} type="task">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                onClick={enableActiveSection}
                className={clsx(styles.section, {
                  [styles.active]: isSectionActive,
                  [styles.draggingOver]: !!snapshot.draggingOverWith,
                })}
                style={{
                  borderColor: isSectionActive ? "#3A6CFF" : section.color,
                }}
              >
                {showError && <ExclamationCircle />}

                {!(
                  !!snapshot.draggingOverWith && section.items.length === 0
                ) && (
                  <>
                    <div className={styles.section__header}>
                      {section.name !== null ? (
                        <div className={styles.section__name}>
                          <AutosizeInput
                            disabled={pdfFormat}
                            value={section.name}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleChangeName(e.target.value)}
                            placeholder="Section name"
                          />
                        </div>
                      ) : section.description !== null ? (
                        <div className={styles.section__text}>
                          {/* <AutosizeInput
                            disabled={pdfFormat}
                            value={section.description}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleChangeDescription(e.target.value)}
                            placeholder="Section text"
                          /> */}
                          {pdfFormat ? (
                            <FroalaEditor
                              model={section.description}
                              config={{
                                key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                                htmlUntouched: true,
                                toolbarInline: true,
                                toolbarVisibleWithoutSelection: true,
                                isDisable: true,
                                events: {
                                  initialized() {
                                    const editor: any = this;
                                    editor.edit.off();
                                  },
                                },
                              }}
                            />
                          ) : (
                            <FroalaEditor
                              model={section.description}
                              onModelChange={(value: string) =>
                                handleChangeDescription(value)
                              }
                              config={{
                                key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                                htmlUntouched: true,
                                listAdvancedTypes: false,
                                listAdvancedPositions: true,
                                fileupload: false,
                                placeholderText: "Section text",
                                // toolbarInline: true,
                                // toolbarVisibleWithoutSelection: true,
                                imageUpload: false,
                              }}
                            />
                          )}
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {section.checkbox && (
                        <Checkbox value={false} onClick={() => {}} />
                      )}
                    </div>

                    {section.description !== null && section.name !== null && (
                      <div className={styles.section__text}>
                        {pdfFormat ? (
                          <FroalaEditor
                            model={section.description}
                            config={{
                              key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                              htmlUntouched: true,
                              toolbarInline: true,
                              toolbarVisibleWithoutSelection: true,
                              isDisable: true,
                              events: {
                                initialized() {
                                  const editor: any = this;
                                  editor.edit.off();
                                },
                              },
                            }}
                          />
                        ) : (
                          <FroalaEditor
                            model={section.description}
                            onModelChange={(value: string) =>
                              handleChangeDescription(value)
                            }
                            config={{
                              key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                              htmlUntouched: true,
                              listAdvancedTypes: false,
                              listAdvancedPositions: true,
                              placeholderText: "Section text",
                              fileupload: false,
                              // toolbarInline: true,
                              // toolbarVisibleWithoutSelection: true,
                              imageUpload: false,
                            }}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}

                {section.collapsable && (
                  <div
                    onClick={toggleCollapsed}
                    className={clsx(styles.section__collapseArrow, {
                      [styles.rotate]: !collapsed,
                    })}
                  >
                    <DownArrow fill="#0B0F1C" />
                  </div>
                )}

                {section.collapsable && collapsed ? null : (
                  <div className={styles.footer}>
                    {section.items.map((item: IItem, index: number) => (
                      <Item
                        key={`${item.id} - ${index} - item`}
                        item={item}
                        sectionIndex={sectionIndex}
                        index={index}
                        sectionId={section.id}
                        tabIndex={tabIndex}
                      />
                    ))}
                  </div>
                )}

                {!snapshot.draggingOverWith && (
                  <div className={styles.section__footer}>
                    <div className={styles.section__footerButton}>
                      <Button
                        disable={sectionLoading || pdfFormat}
                        onClick={addItem}
                        text="Add item"
                        backgroundType={"white"}
                        showShadow
                      />
                    </div>

                    <div className={styles.section__footerButtons}>
                      <div onClick={moveItemUp}>
                        <LeftArrow fill="#616A85" />
                      </div>
                      <div className={styles.section__footerButtonsLine} />
                      <div onClick={moveItemDown}>
                        <LeftArrow fill="#616A85" />
                      </div>
                      <div className={styles.section__footerButtonsLine} />
                      <div onClick={handleOpenPopup}>
                        <DotsVertical fill="#616A85" />
                      </div>
                    </div>

                    {/* <div
                      onClick={handleOpenPopup}
                      className={styles.section__footerDots}
                    >
                      <DotsVertical fill="#616A85" />
                    </div> */}

                    <div
                      ref={popupContentRef}
                      className={clsx(styles.section__footerPopup, {
                        [styles.active]: showPopup,
                      })}
                    >
                      <div
                        className={styles.section__footerPopupItem}
                        onClick={deleteSection}
                      >
                        <TrashAlt fill="#0B0F1C" /> Delete
                      </div>
                      <div
                        className={styles.section__footerPopupItem}
                        onClick={handleAddSection}
                      >
                        <Duplicate fill="#0B0F1C" /> Duplicate
                      </div>
                    </div>
                  </div>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};
