import { Button } from "~components";
import styles from "./styles.module.scss";
import {
  CopyToClipboard as CopyToClipboardIcon,
  Attention,
  Undo,
  Loader,
} from "~icons";
import { useNotepad } from "./useNotepad";
import { INotepad } from "~models";
import { NotepadPanel } from "../NotepadPanel";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { alertVar } from "~localCache";

interface IProps {
  showMainLoader: boolean;
  notepad: INotepad | null;
  setNotepad: React.Dispatch<React.SetStateAction<INotepad | null>>;
}

export const Notepad = ({ showMainLoader, setNotepad, notepad }: IProps) => {
  const {
    handleChangeNotepadText,
    showRewriteButtonLoader,
    showUndoRewriteButtonLoader,
    handleRewriteButton,
    handleUndoRewriteButton,
    handleUpdateNotepad,
  } = useNotepad({
    setNotepad,
    notepad,
  });

  let textareaValue = !!notepad?.rewrited
    ? notepad.rewrited
    : !!notepad?.data
    ? notepad.data
    : "";

  const disableButtons = showMainLoader || !textareaValue;

  return (
    <>
      <div className={styles.container}>
        {showMainLoader ? (
          <div className={styles.loader}>
            <Loader fill="#29B473" />
          </div>
        ) : (
          <div className={styles.container__textAreaWrapper}>
            <textarea
              onBlur={handleUpdateNotepad}
              value={textareaValue}
              onChange={(e) => handleChangeNotepadText(e.target.value)}
              placeholder="Enter your text"
            ></textarea>

            {!textareaValue && (
              <div className={styles.container__attention}>
                <Attention />
                <p>
                  Attention. <br /> Do not include any personal information.
                </p>
              </div>
            )}
          </div>
        )}

        <div className={styles.container__buttons}>
          <div className={styles.container__buttonsRewrite}>
            <Button
              loading={showRewriteButtonLoader}
              onClick={handleRewriteButton}
              disable={disableButtons}
              text={"Rewrite notes into HPI"}
              backgroundType={"green"}
            />
          </div>
          <div className={styles.container__buttonsCopy}>
            <CopyToClipboard
              text={textareaValue}
              onCopy={() =>
                alertVar({ text: "Rewrite copied successfully", type: "green" })
              }
            >
              <Button
                disable={disableButtons}
                icon={
                  <CopyToClipboardIcon
                    fill={disableButtons ? "#DBDEE7" : "#0B0F1C"}
                  />
                }
                showShadow
                text="Copy rewrite to clipboard"
                backgroundType={"white"}
              />
            </CopyToClipboard>
          </div>

          <div className={styles.container__buttonsUndo}>
            <Button
              loading={showUndoRewriteButtonLoader}
              loaderColor={"#0B0F1C"}
              onClick={handleUndoRewriteButton}
              disable={disableButtons || !notepad?.rewrited}
              icon={
                <Undo
                  fill={
                    disableButtons || !notepad?.rewrited ? "#DBDEE7" : "#0B0F1C"
                  }
                />
              }
              showShadow
              text="Undo last rewrite"
              backgroundType={"white"}
            />
          </div>
        </div>

        <p className={styles.footer}>
          <span className={styles.footer__red}>Note:</span> The checkem team is
          not responsible for the consequences of СheckemAI responses, as this
          version of the chat is being tested!
        </p>
      </div>
      <NotepadPanel
        notepad={notepad}
        setNotepad={setNotepad}
        notepadText={textareaValue}
      />
    </>
  );
};
