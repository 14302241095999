import { useBuilder } from "../../../../context";

export const useSectionSettings = () => {
  const { activeItem, list, activeTabIndex, setList } = useBuilder();

  const showBlock = activeItem?.type === "section";

  const sectionIndex = list.tabs[activeTabIndex].sections.findIndex(
    (item) => item.id === activeItem?.id
  );

  const section =
    sectionIndex > -1 ? list.tabs[activeTabIndex].sections[sectionIndex] : null;

  const toggleCollapsable = () => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[sectionIndex].collapsable =
        !state.tabs[activeTabIndex].sections[sectionIndex].collapsable;
      return state;
    });
  };

  const toggleStartCollapsable = () => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[sectionIndex].startCollapsable =
        !state.tabs[activeTabIndex].sections[sectionIndex].startCollapsable;
      return state;
    });
  };

  const toggleHeader = () => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[sectionIndex].name =
        state.tabs[activeTabIndex].sections[sectionIndex].name === null
          ? ""
          : null;
      return state;
    });
  };

  const toggleDescription = () => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[sectionIndex].description =
        state.tabs[activeTabIndex].sections[sectionIndex].description === null
          ? ""
          : null;
      return state;
    });
  };

  const toggleChecbox = () => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[sectionIndex].checkbox =
        !state.tabs[activeTabIndex].sections[sectionIndex].checkbox;
      return state;
    });
  };

  const onChangeColor = (value: string) => {
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[sectionIndex].color = value;
      return state;
    });
  };

  return {
    showBlock,
    section,
    toggleChecbox,
    toggleStartCollapsable,
    toggleCollapsable,
    toggleHeader,
    toggleDescription,
    onChangeColor,
  };
};
