import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useNewNotification } from "../../subscriptions/newNotification";
import { useAuth } from "~hooks";

export const DashboardRoutes = ({}) => {
  const { user } = useAuth();
  const location = useLocation();

  useNewNotification();

  return !!user?.onboarding ? (
    <Outlet />
  ) : (
    <Navigate to={"/getStarted"} state={{ from: location }} replace />
  );
};
