import {
  CheckemAIAvatar,
  GoodEmoticon,
  GreatEmoticon,
  NeutralEmoticon,
  TerriblleEmoticon,
} from "~icons";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { IMessage, MessageRate, MessageType } from "~models";
import { Avatar } from "~components";
import { useMessage } from "./useMessage";

interface IProps {
  message: IMessage;
  messageIndex: number;
  setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>;
}

export const Message = ({ message, messageIndex, setMessages }: IProps) => {
  const isBot = message.type === MessageType.Answer;

  const { data, leaveRate } = useMessage({
    message,
    messageIndex,
    setMessages,
  });

  return (
    <div className={clsx(styles.container, { [styles.green]: isBot })}>
      <div className={styles.container__content}>
        {isBot ? (
          <div className={styles.container__avatar}>
            <CheckemAIAvatar />
          </div>
        ) : (
          <Avatar
            uri={undefined}
            item={data.currentUser}
            type="user"
            containerStyles={styles.container__avatar}
          />
        )}
        <div className={styles.container__message}>{message.data}</div>
      </div>

      {isBot && (
        <div className={styles.container__footer}>
          <p className={styles.container__footerTitle}>
            Rate Checkem AI response
          </p>

          <div className={styles.container__footerButtons}>
            <div
              onClick={() => leaveRate(MessageRate.Terrible)}
              className={clsx(
                styles.container__footerButton,
                message.rate === MessageRate.Terrible && styles.active
              )}
            >
              <TerriblleEmoticon />
              Terrible
            </div>

            <div
              onClick={() => leaveRate(MessageRate.Neutral)}
              className={clsx(
                styles.container__footerButton,
                message.rate === MessageRate.Neutral && styles.active
              )}
            >
              <NeutralEmoticon />
              Neutral
            </div>

            <div
              onClick={() => leaveRate(MessageRate.Good)}
              className={clsx(
                styles.container__footerButton,
                message.rate === MessageRate.Good && styles.active
              )}
            >
              <GoodEmoticon />
              Good
            </div>

            <div
              onClick={() => leaveRate(MessageRate.Great)}
              className={clsx(
                styles.container__footerButton,
                message.rate === MessageRate.Great && styles.active
              )}
            >
              <GreatEmoticon />
              Great
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
