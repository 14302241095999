import { useState } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { regex } from "~constants";
import InputMask from "react-input-mask";

interface IProps {
  label?: string;
  minutes: number;
  seconds: number;
  onChangeMinutes: (value: string) => void;
  onChangeSeconds: (value: string) => void;
}

export const MinutesPicker = ({
  label = "Ring at",
  minutes,
  seconds,
  onChangeMinutes,
  onChangeSeconds,
}: IProps) => {
  const [isMinutesFocused, setIsMinutesFocused] = useState<boolean>(false);
  const [isSecondsFocused, setIsSecondsFocused] = useState<boolean>(false);

  const setActiveMinutesFocused = () => setIsMinutesFocused(true);
  const setDisactiveMinutesFocused = () => setIsMinutesFocused(false);

  const setActiveSecondsFocused = () => setIsSecondsFocused(true);
  const setDisactiveSecondsFocused = () => setIsSecondsFocused(false);

  const handleMinutes = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.substring(0, e.target.value.length - 2).trim();
    if (regex.onlyNumbers.test(value) || value === "") {
      onChangeMinutes(value);
    } else {
      onChangeMinutes(`${minutes}`);
    }
  };

  const handleSeconds = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.substring(0, e.target.value.length - 2).trim();
    if (regex.onlyNumbers.test(value) || value === "") {
      onChangeSeconds(value);
    } else {
      onChangeSeconds(`${seconds}`);
    }
  };

  return (
    <div className={styles.minutesPicker}>
      <div className={styles.minutesPicker__label}>{label}</div>
      <div className={styles.minutesPicker__fields}>
        <div
          className={clsx(styles.minutesPicker__fieldsInput, {
            [styles.focus]: isMinutesFocused,
          })}
        >
          <InputMask
            value={minutes == 0 ? "" : minutes}
            onChange={handleMinutes}
            placeholder="0 m"
            onFocus={setActiveMinutesFocused}
            onBlur={setDisactiveMinutesFocused}
            mask={"99 m"}
            maskChar={" "}
          />
        </div>

        <p>:</p>

        <div
          className={clsx(styles.minutesPicker__fieldsInput, {
            [styles.focus]: isSecondsFocused,
          })}
        >
          <InputMask
            value={seconds == 0 ? "" : seconds}
            onChange={handleSeconds}
            placeholder="0 s"
            onFocus={setActiveSecondsFocused}
            onBlur={setDisactiveSecondsFocused}
            mask={"99 s"}
            maskChar={" "}
          />
        </div>
      </div>
    </div>
  );
};
