import { useQuery } from "@apollo/client";
import { GET_CHOOSED_ORGANIZATIOM, showPanelVar } from "~localCache";

export const useDashboardLayout = () => {
  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const handleOpenLeftPanel = () => {
    showPanelVar(true);
  };
  return {
    choosedOrganization,
    handleOpenLeftPanel,
  };
};
