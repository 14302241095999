import { AuthProvider } from "../context/AuthProvider/AuthProvider";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  LoadingPage,
  SignInPage,
  SignUpPage,
  ResetPassword,
  CodeConfirmation,
  ChooseNewPassword,
  GetStarted,
  HomePage,
  ActiveChecklists,
  UserCompletedChecklists,
  OrganizationDrafts,
  CompletedCheckems,
  Members,
  OrganizationSettings,
  HelpAndFeedback,
  EditProfile,
  ListBuilder,
  Checklist,
  AllOrganizations,
  AllMembers,
  ReceivedRequests,
  OrganizationEditing,
  Billing,
  MyOrganization,
  AllOrganization,
  MyRequests,
  MyInvitation,
  AdminsManagement,
  OrganizationManagement,
  UsersManagement,
  FolderBuilder,
  Folder,
  CheckemDrafts,
  Library,
  AdminFolder,
  PDFViewer,
  CheckemAI,
  Academy,
} from "~pages";
import { Dashboard } from "./Dashboard";
import {
  PrivateRoutes,
  OnboardingRoutes,
  PublicRoutes,
  DashboardRoutes,
  DefaultRedirect,
} from "./RedirectedRoutes";
import { GET_CHOOSED_ORGANIZATIOM } from "~localCache";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_USER } from "~queries";
import { UserAccessRole } from "~models";
import { checkIfMemberIsUser, checkIfUserIsSuperAdmin } from "~helpers";

export const MainNavigation = () => {
  const { data } = useQuery(GET_CHOOSED_ORGANIZATIOM);
  const { data: userData } = useQuery(GET_CURRENT_USER);
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<LoadingPage />} path="/loading" />
          <Route element={<PublicRoutes />}>
            <Route element={<SignInPage />} path="/signIn" />
            <Route element={<SignUpPage />} path="/signUp" />
            <Route element={<CodeConfirmation />} path="/confirmCode" />
            <Route element={<ResetPassword />} path="resetPassword" />
            <Route element={<ChooseNewPassword />} path="chooseNewPassword" />
          </Route>
          <Route path={"/*"} element={<PrivateRoutes />}>
            <Route element={<OnboardingRoutes />}>
              <Route index element={<GetStarted />} path="getStarted" />
              {/* <Route element={<WelcomePage />} path="welcomePage" /> */}
              {/* <Route element={<NewOrganization />} path="newOrganization" /> */}
            </Route>

            <Route element={<DashboardRoutes />}>
              <Route element={<Dashboard />} path={"dashboard/*"}>
                <Route element={<Academy />} path={"academy"} />
                <Route element={<AllOrganizations />} path={"common/*"}>
                  <Route
                    index
                    element={<AllOrganization />}
                    path="allOrganizations"
                  />
                  <Route element={<MyOrganization />} path="myOrganizations" />
                  <Route element={<MyRequests />} path="sentRequests" />
                  <Route element={<MyInvitation />} path="invitations" />
                  <Route
                    path="*"
                    element={<Navigate to={"allOrganizations"} />}
                  />
                </Route>

                {!!data?.choosedOrganization && (
                  <>
                    <Route element={<HomePage />} path={"home/*"}>
                      <Route
                        index
                        element={<ActiveChecklists />}
                        path="active"
                      />
                      <Route
                        element={<UserCompletedChecklists />}
                        path="completed"
                      />

                      <Route path="*" element={<Navigate to={"active"} />} />
                    </Route>

                    <Route element={<Folder />} path={"folder/:id"} />

                    {/* <Route element={<CheckemAI />} path={"chat"} /> */}

                    <Route
                      element={<AdminFolder />}
                      path={"folderImport/:id"}
                    />

                    {(!checkIfMemberIsUser(data.choosedOrganization) ||
                      checkIfUserIsSuperAdmin(userData?.currentUser)) && (
                      <>
                        <Route
                          element={<OrganizationDrafts />}
                          path={"lists"}
                        />
                        <Route
                          element={<FolderBuilder />}
                          path={"folderBuilder/:id"}
                        />
                        <Route element={<Library />} path={"library"} />
                        <Route
                          element={<CompletedCheckems />}
                          path={"completed"}
                        />
                        <Route element={<Members />} path={"members/*"}>
                          <Route index element={<AllMembers />} path="all" />
                          <Route
                            element={<ReceivedRequests />}
                            path="requests"
                          />
                          <Route path="*" element={<Navigate to={"all"} />} />
                        </Route>

                        <Route
                          element={<OrganizationSettings />}
                          path={"organizationSettings/*"}
                        >
                          <Route
                            index
                            element={<OrganizationEditing />}
                            path="editing"
                          />
                          <Route element={<Billing />} path="billing" />
                          <Route
                            path="*"
                            element={<Navigate to={"editing"} />}
                          />
                        </Route>
                      </>
                    )}
                  </>
                )}

                {!!userData?.currentUser &&
                  userData.currentUser?.role !== UserAccessRole.User && (
                    <>
                      <Route
                        element={<OrganizationManagement />}
                        path={"organizationsManagement"}
                      />

                      <Route
                        element={<UsersManagement />}
                        path={"usersManagement"}
                      />
                      <Route
                        element={<CheckemDrafts />}
                        path={"checkemDrafts"}
                      />
                    </>
                  )}

                {!!userData?.currentUser &&
                  userData.currentUser?.role ===
                    UserAccessRole.SuperSuperAdmin && (
                    <Route
                      element={<AdminsManagement />}
                      path={"adminsManagement"}
                    />
                  )}

                <Route element={<HelpAndFeedback />} path={"helpAndFeedback"} />
                <Route element={<EditProfile />} path="profile/:id" />

                <Route
                  path="*"
                  element={<Navigate to={"/dashboard/common"} replace />}
                />
              </Route>

              <Route element={<PDFViewer />} path={"pdf/:id"} />
              <Route element={<ListBuilder />} path={"builder/:id"} />
              <Route element={<Checklist />} path={"checklist/:id"} />
              <Route element={<CheckemAI />} path={"chat/:id"} />
            </Route>

            <Route path="*" element={<DefaultRedirect />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};
