import { useImportCodeModal } from "./useImportCodeModal";
import { Button, Modal } from "~components";

import styles from "./styles.module.scss";

interface IProps {
  isOpen: boolean;
  isAdmin?: boolean;
  folderId?: number;
  onCloseModal: () => void;
  handleSuccess: () => void;
}

export const ImportCodeModal = ({
  isOpen,
  isAdmin,
  folderId,
  onCloseModal,
  handleSuccess,
}: IProps) => {
  const { code, showLoader, handleModalClose, handleImport, handleCodeChange } =
    useImportCodeModal({ isAdmin, handleSuccess, onCloseModal, folderId });

  if (!isOpen) return null;

  return (
    <Modal onCloseModal={handleModalClose}>
      <div className={styles.modal}>
        <p className={styles.modal__title}>Enter code</p>
        <div className={styles.modal__field}>
          <input
            value={code}
            onChange={(e) => handleCodeChange(e.target.value)}
            placeholder="Enter Checkem code"
          />
        </div>
        <div className={styles.modal__underline} />

        <Button
          disable={!code}
          loading={showLoader}
          onClick={handleImport}
          text="Import"
          backgroundType={"green"}
        />
      </div>
    </Modal>
  );
};
