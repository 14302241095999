import { FC, useRef, useState } from "react";
import { clsx } from "clsx";
import styles from "./styles.module.scss";
import { DownArrow, Sound, UpDownArrow } from "~icons";
import { useOutsideClick } from "~hooks";
import { alarmSounds } from "~audio";

const data = [
  { name: "Sound 1", path: alarmSounds.sound1, key: "sound1" },
  { name: "Sound 2", path: alarmSounds.sound2, key: "sound2" },
  { name: "Sound 3", path: alarmSounds.sound3, key: "sound3" },
  { name: "Sound 4", path: alarmSounds.sound4, key: "sound4" },
  { name: "Sound 5", path: alarmSounds.sound5, key: "sound5" },
  { name: "Text-to-Speech", path: null, key: "test-to-speech" },
];

interface IProps {
  label?: string;
  sound: string;
  onChange?: (value: string, index: number) => void;
}
export const SoundDropdown: FC<IProps> = ({ label, sound, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const item = data.find((item) => item.key === sound);
  let audio = new Audio(item?.path);

  const handlePlay = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    audio.play();
  };

  const handleDropdownChange = (name: string, index: number) => {
    !!onChange && onChange(name, index);
    setIsOpen(false);
  };

  const toggleDropdownState = () => {
    setIsOpen(!isOpen);
  };
  const handleDropdownClose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useOutsideClick(dropdownRef, handleDropdownClose);

  return (
    <div ref={dropdownRef} className={styles.dropdownContainer}>
      {!!label && (
        <div className={styles.dropdownContainer__label}>{label}</div>
      )}
      <div
        onClick={toggleDropdownState}
        className={clsx(styles.dropdownContainer__field, {
          [styles.dropdownContainer__fieldPlaceholder]: !sound,
          [styles.active]: isOpen,
        })}
      >
        {!!sound ? (
          <div className={styles.dropdownContainer__fieldValue}>
            {item?.name}
          </div>
        ) : (
          <>Choose type</>
        )}
        <div className={clsx(styles.dropdownContainer__fieldIcons)}>
          {sound !== "test-to-speech" && (
            <div onClick={handlePlay}>
              <Sound />
            </div>
          )}

          <div
            className={clsx(styles.dropdownContainer__fieldIconsArrow, {
              [styles.rotate]: isOpen,
            })}
          >
            <DownArrow fill="#0B0F1C" />
          </div>
        </div>
      </div>

      <div
        className={clsx(styles.dropdownContainer__select, {
          [styles.close]: !isOpen,
        })}
      >
        <div className={styles.dropdownContainer__selectBlock}>
          {data.map((item, index) => (
            <div
              onClick={() => handleDropdownChange(item.key, index)}
              key={`${item.name} - ${index}`}
              className={styles.dropdownContainer__item}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
