import clsx from "clsx";
import React from "react";
import { Avatar, Button, NumberWidget } from "~components";
import { BellNotification, InviteMembers, PlusSymbol } from "~icons";
import { IOrganization } from "~models";
import { Item } from "../Item";
import styles from "./styles.module.scss";

interface IProps {
  choosedOrganization: IOrganization;
  myOrganizations: IOrganization[];
  handleInviteModalOpen: () => void;
  handleChooseOrganization: (data: IOrganization) => void;
  requestsCount: number;
  invitionsCount: number;
  showAllOrganizations: () => void;
  handleCreateOrgnization: () => void;
  modalRef: React.Ref<HTMLDivElement>;
  showSentRequest: () => void;
  showInvitations: () => void;
}

export const LargePopup = ({
  choosedOrganization,
  myOrganizations,
  modalRef,
  requestsCount,
  invitionsCount,
  showAllOrganizations,
  handleInviteModalOpen,
  handleCreateOrgnization,
  handleChooseOrganization,
  showSentRequest,
  showInvitations,
}: IProps) => {
  const isDisableInviteButton = choosedOrganization.status !== "active";
  return (
    <div ref={modalRef} className={styles.popupLarge}>
      <Avatar
        uri={choosedOrganization?.picture}
        item={choosedOrganization}
        type="organization"
        containerStyles={styles.popupLarge__icon}
      />
      <p className={styles.popupLarge__title}>{choosedOrganization?.name}</p>
      <p className={styles.popupLarge__members}>
        {choosedOrganization?.counters?.members + 1} members
      </p>
      <Button
        disable={isDisableInviteButton}
        onClick={handleInviteModalOpen}
        icon={
          <InviteMembers fill={isDisableInviteButton ? "#FFFFFF" : "#29B473"} />
        }
        text="Invite Members"
        backgroundColor={
          isDisableInviteButton ? "rgba(0,0,0,0.15)" : "rgba(41, 180, 115, 0.2)"
        }
        textColor={isDisableInviteButton ? "#FFFFFF" : "#29B473"}
        buttonClassName={styles.popupLarge__button}
      />
      <div className={styles.popupLarge__underline} />
      <p className={styles.popupLarge__subtitle}>My Organizations</p>

      <div
        className={clsx(styles.popupLarge__organiizations, {
          [styles.hideScrollbar]: myOrganizations.length <= 3,
        })}
      >
        {myOrganizations.map((item: IOrganization, index: number) => (
          <Item
            key={`${item.id} - ${index}`}
            item={item}
            index={index}
            choosedOrganizationId={choosedOrganization?.id}
            handleChooseOrganization={handleChooseOrganization}
          />
        ))}
      </div>

      <div className={styles.organizationModule__buttonLine} />
      <div
        className={styles.organizationModule__button}
        onClick={showSentRequest}
      >
        Sent Requests
        <NumberWidget text={requestsCount} backgroundType={"gray"} />
      </div>
      <div className={styles.organizationModule__buttonLine} />
      <div
        className={styles.organizationModule__button}
        onClick={showInvitations}
      >
        <div className={styles.organizationModule__buttonNotification}>
          <BellNotification fill="#EF2F32" />
          Invitations
        </div>
        <NumberWidget text={invitionsCount} backgroundType={"red"} />
      </div>

      <Button
        onClick={showAllOrganizations}
        text="Show All Organizations"
        buttonClassName={styles.popupLarge__button}
        backgroundType={"gray"}
      />
      <div className={styles.popupLarge__underline} />
      <Button
        text="Create New Organization"
        showShadow
        onClick={handleCreateOrgnization}
        buttonClassName={clsx(styles.popupLarge__button, styles.marginBottom0)}
        backgroundType={"white"}
        icon={<PlusSymbol fill="#0B0F1C" />}
      />
    </div>
  );
};
