import { OnboardingLayout } from "~layouts";
import { Button, Input, PasswordRules } from "~components";
import { useChoosePassword } from "./hooks/useChoosePassword";
import styles from "./styles.module.scss";
import clsx from "clsx";

export const ChooseNewPassword = () => {
  const { data, onChangeData, onSubmit, loading, disableButton, error } =
    useChoosePassword();

  return (
    <OnboardingLayout>
      <div className={styles.choosePassword}>
        <div className={styles.choosePassword__header}>Choose new password</div>
        <div className={styles.choosePassword__subtitle}>
          Enter your new password and you’re all set.
        </div>

        <div className={styles.choosePassword__passwordNew}>
          <Input
            type={"secure"}
            label="New Password"
            value={data.password}
            onChange={(value) => onChangeData(value, "password")}
            placeholder={"Enter your password..."}
            error={!!error.password ? " " : ""}
          />
          <PasswordRules password={data.password} />
        </div>

        <div className={styles.choosePassword__password}>
          <Input
            type={"secure"}
            label="Repeat Password"
            value={data.confirmPassword}
            onChange={(value) => onChangeData(value, "confirmPassword")}
            placeholder={"Repeat you password..."}
            error={error.confirmPassword}
          />
        </div>

        <div className={styles.choosePassword__button}>
          <Button
            disable={disableButton}
            loading={loading}
            onClick={onSubmit}
            text="Reset Password"
            backgroundType={"green"}
          />
        </div>
      </div>
    </OnboardingLayout>
  );
};
