import { useLazyQuery, useQuery } from "@apollo/client";
import { createContext, FC, ReactNode, useEffect, useState } from "react";
import { Loader } from "~icons";
import { IOrganization, IUser } from "~models";
import {
  GET_ALL_ACTIVE_PROGRESSES,
  GET_CURRENT_USER,
  GET_MY_ORGANIZATIONS,
} from "~queries";
import styles from "./styles.module.scss";
import {
  clearChoosedOrganization,
  getChoosedOrganization,
  setChoosedOrganization as setOrganizationInLS,
} from "~services";
import { choosedOrganizationVar } from "~localCache";

const AuthContext = createContext<{
  user: IUser | null;
  setUser: (val: any) => any;
  loading: boolean;
}>({ user: null, setUser: () => {}, loading: false });

interface IProps {
  children: ReactNode;
}
export const AuthProvider: FC<IProps> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);

  const [loading, setLoading] = useState(!!localStorage.getItem("accessToken"));
  const [getMyOrganizations] = useLazyQuery(GET_MY_ORGANIZATIONS);

  const [fetchActiveProgresses] = useLazyQuery(GET_ALL_ACTIVE_PROGRESSES);

  const [queryGetUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: "network-only",
  });

  const getActiveProgresses = (organization: IOrganization, user: IUser) => {
    fetchActiveProgresses({ variables: { organizationId: organization.id } })
      .then(() => setUser(user))
      .catch(() => setUser(user));
  };

  const setChoosedOrganization = async (data: IOrganization[], user: IUser) => {
    const organizationFromLS = getChoosedOrganization();
    if (!!organizationFromLS) {
      const item = data.find((item) => item.id === organizationFromLS.id);
      if (!!item) {
        choosedOrganizationVar(item);
        getActiveProgresses(item, user);
        return;
      } else if (data.length > 0) {
        choosedOrganizationVar(data[0]);
        setOrganizationInLS(data[0]);
        getActiveProgresses(data[0], user);
        return;
      } else {
        clearChoosedOrganization();
        setUser(user);
        return;
      }
    } else if (data.length > 0) {
      choosedOrganizationVar(data[0]);
      setOrganizationInLS(data[0]);
      getActiveProgresses(data[0], user);
      return;
    }
    setUser(user);
  };

  const checkUserOrganizations = (user: IUser) => {
    getMyOrganizations({ variables: {} })
      .then((resp) => setChoosedOrganization(resp.data.myOrganizations, user))
      .catch((err) => setUser(user));
  };

  useEffect(() => {
    if (!!localStorage.getItem("accessToken")) {
      queryGetUser()
        .then((resp) => {
          if (!resp?.data?.currentUser) {
            setLoading(false);
          } else {
            checkUserOrganizations(resp.data.currentUser);
          }
        })
        .catch(() => {});
    }
  }, []);

  useEffect(() => {
    if (!!user) {
      setTimeout(() => setLoading(false), 100);
    }
  }, [user]);

  if (loading)
    return (
      <div className={styles.authProvider}>
        <Loader fill="#616A85" />
      </div>
    );

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
