import { IItem } from "../../../../../constants";

interface IProps {
  item: IItem;
}

export const useSelector = ({ item }: IProps) => {
  const itemWithParsedData =
    typeof item.data === "string"
      ? { ...item, data: JSON.parse(item.data) }
      : item;
  const fieldSelections = itemWithParsedData.data.selections.filter(
    (item: string) => !!item
  );
  const isNoData = fieldSelections.length === 0;

  return { isNoData, fieldSelections, itemWithParsedData };
};
