import { useBuilder } from "../../../../context";
import { useState } from "react";
import { IItem } from "~models";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const useTextBox = ({ item, itemIndex }: IProps) => {
  const { setList, activeTabIndex, activeItem } = useBuilder();
  const [choosedItemIndex, setChoosedItemIndex] = useState<number | null>(null);

  let data = typeof item.data === "string" ? JSON.parse(item.data) : item.data;

  const handleChoosedItem = (index: number) => setChoosedItemIndex(index);
  const clearChoosedItem = () => setChoosedItemIndex(null);

  const onTemplateChange = (value: string) => {
    if (!activeItem) return;
    data = { ...data, template: value };
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const onModelChange = (value: string, index: number) => {
    if (!activeItem) return;
    data.tables[index] = { ...data.tables[index], model: value };
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const onTitleChange = (value: string, index: number) => {
    if (!activeItem) return;
    data.tables[index] = { ...data.tables[index], title: value };
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleAddButton = () => {
    if (!activeItem) return;
    data.tables.push({
      title: "",
      model: data?.tables.length > 0 ? data.tables[0].model : "",
    });
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleDeleteItem = (index: number) => {
    if (!activeItem) return;
    data.tables.splice(index, 1);
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  return {
    onModelChange,
    onTitleChange,
    handleAddButton,
    onTemplateChange,
    handleDeleteItem,
    handleChoosedItem,
    clearChoosedItem,
    choosedItemIndex,
    data,
  };
};
