import styles from "./styles.module.scss";
import { Button } from "~components";
import { AILogo, CheckMark, LeftArrow, Loader, Refresh, TrashAlt } from "~icons";
import clsx from "clsx";
import { useCheckemAI } from "./useCheckemAI";
import { Notepad } from "./Notepad";
import { Chat } from "./Chat";
import { NotepadPanel } from "./NotepadPanel";

export const CheckemAI = ({}) => {
  const {
    type,
    showMainLoader,
    messages,
    notepad,
    showRemoveLoader,
    showCompleteLoader,
    setType,
    handleGoBack,
    setNotepad,
    setMessages,
    handleRemoveButton,
    handleCompleteChat,
  } = useCheckemAI();

  return (
    <div className={styles.container}>
      <div className={styles.arrow}>
        <LeftArrow onClick={handleGoBack} fill={"#0B0F1C"} />
      </div>
      <div
        className={clsx(styles.header, {
          [styles.header__notepad]: type === "notepad",
        })}
      >
        <div className={styles.header__left}>
          <div className={styles.header__logo}>
            <AILogo />
            <h1 className={styles.header__logoText}>AI</h1>
          </div>
          {/* <img src={require("../../../../assets/images/checkemAI.png")} /> */}
          <div className={styles.header__leftVersion}>BETA 1.0</div>
        </div>
        <div
          className={clsx(styles.header__right, {
            [styles.header__rightNotepad]: type === "notepad",
          })}
        >
          <Button
            onClick={handleCompleteChat}
            disable={showMainLoader}
            loading={showCompleteLoader}
            icon={
              <CheckMark
                fill={showMainLoader ? "#979DB1" : "#FFFFFF"}
                stroke={showMainLoader ? "#979DB1" : "#FFFFFF"}
              />
            }
            text="Complete"
            backgroundType={"green"}
            buttonClassName={styles.header__rightCompleted}
          />

          <div
            className={clsx(styles.header__rightRefresh, {
              [styles.loading]: false,
              [styles.disable]: showMainLoader,
            })}
          >
            {false ? (
              <Loader fill={"#0B0F1C"} />
            ) : (
              <Refresh fill={showMainLoader ? "#DBDEE7" : "#0B0F1C"} />
            )}
          </div>

          <div
            className={clsx(
              styles.header__rightDelete,
              showMainLoader && styles.disable
            )}
            onClick={handleRemoveButton}
          >
            {showRemoveLoader ? (
              <div className={styles.header__rightDeleteLoader}>
                <Loader fill="#EF2F32" />
              </div>
            ) : (
              <TrashAlt fill="#EF2F32" />
            )}
          </div>
        </div>
      </div>
      <div className={styles.mainSwitcher}>
        <div
          onClick={() => setType("notepad")}
          className={clsx(
            styles.mainSwitcher__button,
            styles.mainSwitcher__buttonNotepad,
            { [styles.active]: type === "notepad" }
          )}
        >
          NOTEPAD
        </div>
        <div
          onClick={() => setType("chat")}
          className={clsx(
            styles.mainSwitcher__button,
            styles.mainSwitcher__buttonChat,
            { [styles.active]: type === "chat" }
          )}
        >
          CHAT
        </div>

        <div
          className={clsx(styles.mainSwitcher__indicator, {
            [styles.mainSwitcher__indicatorLeft]: type === "notepad",
          })}
        />
      </div>

      {type === "chat" ? (
        <Chat
          messages={messages}
          showMainLoader={showMainLoader}
          setMessages={setMessages}
        />
      ) : (
        <>
          <Notepad
            setNotepad={setNotepad}
            notepad={notepad}
            showMainLoader={showMainLoader}
          />
          {/* <NotepadPanel notepad={notepad} /> */}
        </>
      )}
    </div>
  );
};
