import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_MEMBER_ROLE } from "~mutations";
import { GET_CURRENT_USER } from "~queries";
import { useRef, useState } from "react";
import { useDidMount } from "~hooks";
import {
  IOrganization,
  OrganizationMember,
  OrganizationMemberRole,
  OrganizationMemberStatus,
} from "~models";

interface IProps {
  dataLength: number;
  item: OrganizationMember;
  index: number;
  scrollBlockRef?: React.RefObject<HTMLDivElement>;
  handleChangeRole: (value: OrganizationMemberRole, index: number) => void;
  choosedOrganization: IOrganization;
}

export const useMemberItem = ({
  item,
  index,
  scrollBlockRef,
  handleChangeRole,
  dataLength,
  choosedOrganization,
}: IProps) => {
  const {
    data: { currentUser },
  } = useQuery(GET_CURRENT_USER);

  const [showPopper, setShowPopper] = useState(false);

  const handleOpenPopper = () => setShowPopper(true);
  const handleClosePopper = () => setShowPopper(false);

  const itemRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const [rotate, setRotate] = useState(false);
  const isIUser = !!choosedOrganization.members
    ? choosedOrganization.members[0].role === "User"
    : true;

  const [showLoader, setShowLoader] = useState(false);
  const [updateMemberRole] = useMutation(UPDATE_MEMBER_ROLE);

  const onChangeMemberRole = (newRole: OrganizationMemberRole) => {
    if (newRole !== item.role) {
      const prevRole = item.role;
      setShowLoader(true);
      handleChangeRole(newRole, index);
      updateMemberRole({
        variables: { input: { id: item.id, role: newRole } },
      })
        .then(() => {
          setShowLoader(false);
        })
        .catch((err) => {
          handleChangeRole(prevRole, index);
        });
    }
  };

  const isSuperAdmin = false;

  const isMemberUser = item.role === "User";

  const isMyMember = item?.user?.id === currentUser?.id;

  const myRole = !!choosedOrganization?.members
    ? choosedOrganization.members[0].role
    : "User";

  const showEditButton = isMyMember || isSuperAdmin;

  const showDeleteButton =
    (myRole === "Admin" && isMemberUser) || (myRole === "Owner" && !isMyMember);

  const isTwoLasts = dataLength - 1 === index || dataLength - 2 === index;

  const isInvitedMember = item.status === OrganizationMemberStatus.Pending;

  const checkIsNeedRotate = () => {
    const scrollBlockHeight = scrollBlockRef?.current?.clientHeight;
    const itemOffsetTop = itemRef?.current?.offsetTop;

    const difference =
      typeof itemOffsetTop === "number" && typeof scrollBlockHeight === "number"
        ? scrollBlockHeight - itemOffsetTop
        : 1000;

    difference < 115 && isTwoLasts && setRotate(true);
  };

  useDidMount(checkIsNeedRotate);

  return {
    itemRef,
    showLoader,
    rotate,
    modalRef,
    isSuperAdmin,
    showPopper,
    isMyMember,
    showDeleteButton,
    showEditButton,
    isIUser,
    isInvitedMember,
    onChangeMemberRole,
    handleOpenPopper,
    handleClosePopper,
  };
};
