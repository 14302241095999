import { IItem, IStopwatchData } from "../../../../constants";
import {
  Button,
  Input,
  SoundDropdown,
  MinutesPicker,
  Switch,
  SwitchField,
} from "~components";
import styles from "./styles.module.scss";
import { PlusSymbol, Trash, TrashAlt } from "~icons";
import { useStopwatch } from "./useStopwatch";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const StopwatchSettings = ({ item, itemIndex }: IProps) => {
  const {
    handleTTS,
    handleRingMinutes,
    handleRingSeconds,
    toggleRecordTime,
    handleAddAlert,
    handleAlertName,
    handleSoundName,
    handleMinutes,
    handleSeconds,
    toggleRepeat,
    handleDeleteItem,
    changePlaybackOrder,
    handleChangeTextToSpeech,
  } = useStopwatch({ item, itemIndex });
  return (
    <div className={styles.metronomeSettings}>
      <SwitchField
        text="Record time at beginning and end of stopwatch"
        value={item.data.record}
        onClick={toggleRecordTime}
      />

      {/* <SwitchField
        text="Start alarms after each other"
        value={item.data?.afterEachOther}
        onClick={changePlaybackOrder}
        containerClassName={styles.metronomeSettings__switcher}
      /> */}

      <div className={styles.metronomeSettings__underline} />

      {!!item.data.alarms &&
        item.data.alarms.map((item: IStopwatchData, index: number) => {
          return (
            <div
              key={`${index} - ${itemIndex} - alarm`}
              className={styles.item}
            >
              <div className={styles.item__header}>
                Alarm {index + 1}
                <div
                  onClick={() => handleDeleteItem(index)}
                  className={styles.item__headerIcon}
                >
                  <Trash stroke="#616A85" />
                </div>
              </div>

              <Input
                label="Notification text"
                placeholder="Notification text"
                value={item.text}
                onChange={(value) => handleAlertName(value, index)}
              />
              <div className={styles.item__footer}>
                <div className={styles.item__footerDropdown}>
                  <SoundDropdown
                    label="Sound"
                    sound={item.sound}
                    onChange={(value) => handleSoundName(value, index)}
                  />
                </div>
                {item.repeat ? (
                  <MinutesPicker
                    minutes={item.ringMinutes}
                    seconds={item.ringSeconds}
                    onChangeMinutes={(value) => handleRingMinutes(value, index)}
                    onChangeSeconds={(value) => handleRingSeconds(value, index)}
                  />
                ) : (
                  <MinutesPicker
                    minutes={item.minutes}
                    seconds={item.seconds}
                    onChangeMinutes={(value) => handleMinutes(value, index)}
                    onChangeSeconds={(value) => handleSeconds(value, index)}
                  />
                )}

                <div className={styles.item__footerRepeat}>
                  <p>Repeat</p>
                  <Switch
                    value={item.repeat}
                    onClick={() => toggleRepeat(index)}
                  />
                </div>
              </div>

              {item.repeat && (
                <div className={styles.item__footer}>
                  <MinutesPicker
                    label="Repeat interval"
                    minutes={item.minutes}
                    seconds={item.seconds}
                    onChangeMinutes={(value) => handleMinutes(value, index)}
                    onChangeSeconds={(value) => handleSeconds(value, index)}
                  />
                </div>
              )}

              {/* <div className={styles.item__footerInput}>
                <Input
                  onBlur={() =>
                    !!item.textToSpeech && handleTTS(item.textToSpeech)
                  }
                  value={item.textToSpeech}
                  placeholder="Enter your text to speech"
                  label="Text-to-Speech"
                  onChange={(value) => handleChangeTextToSpeech(value, index)}
                />
              </div> */}
            </div>
          );
        })}
      <div className={styles.metronomeSettings__addButton}>
        <Button
          onClick={handleAddAlert}
          backgroundType="white"
          showShadow
          text="Add alarm"
          icon={<PlusSymbol fill="#29B473" />}
          textColor="#29B473"
        />
      </div>
    </div>
  );
};
