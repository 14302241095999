import { memo, useState } from "react";
import styles from "./styles.module.scss";
import { ToolbarSlot, toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { Viewer, Worker } from "@react-pdf-viewer/core";

interface IProps {
  url: string;
}

const PdfTab = ({ url }: IProps) => {

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;


  return (
    <div className={styles.pdfTab}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div
          className="rpv-core__viewer"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div className={styles.pdfViewer__content}>
            <Toolbar>
              {(slots: ToolbarSlot) => {
                const {
                  CurrentPageInput,
                  Download,
                  EnterFullScreen,
                  GoToNextPage,
                  GoToPreviousPage,
                  NumberOfPages,
                  Print,
                  ShowSearchPopover,
                  Zoom,
                  ZoomIn,
                  ZoomOut,
                } = slots;
                return (
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div style={{ padding: "0px 2px" }}>
                      <ShowSearchPopover />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <ZoomOut />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <Zoom />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <ZoomIn />
                    </div>
                    <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                      <GoToPreviousPage />
                    </div>
                    <div
                      style={{
                        padding: "0px 2px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CurrentPageInput /> / <NumberOfPages />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <GoToNextPage />
                    </div>
                    <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                      <EnterFullScreen />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <Download />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <Print />
                    </div>
                  </div>
                );
              }}
            </Toolbar>
          </div>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
            }}
          >
            <Viewer fileUrl={url} plugins={[toolbarPluginInstance]} />
          </div>
        </div>
      </Worker>
      {/* <Document
        options={options}
        loading={null}
        file={{
          url,
        }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {!!numPages && (
          <>
            {Array.from(new Array(numPages), (el, index) => {
              return <Page key={`page_${index + 1}`} pageNumber={index + 1} />;
            })}
          </>
        )}
      </Document> */}
    </div>
  );
};
export default memo(PdfTab, () => true);
