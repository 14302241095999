import { ISection } from "~models";
import { useChecklist } from "../../context";
import { useItem } from "../Item/useItem";

interface IProps {
  section: ISection;
}
export const useSection = ({ section }: IProps) => {
  const { progress, setProgress } = useChecklist();
  const sectionIndex = progress.data.sections.findIndex(
    (item) => item.id === section.id
  );
  const sectionItem = progress.data.sections[sectionIndex];

  const toggleCheckbox = () => {
    if (progress.completed) return;
    
    setProgress((prev) => {
      let state = { ...prev };
      if (!sectionItem?.isChecked) {
        state.data.sections[sectionIndex].checkedTimestamp =
          new Date().getTime();

          //Check all checkboxes in section
          section.items.forEach((item) => {
            let progressItem = progress.data.items.find(
              (elem) => elem.id === item.id
            );
              console.log(progressItem, 'item')
            let progressIndex = progress.data.items.findIndex(
              (elem) => elem.id === item.id
            );
    
            if (!progressItem?.isChecked && progressItem?.checkbox) {
              state.data.items[progressIndex].checkedTimestamp =
                new Date().getTime();
                state.data.items[progressIndex].isChecked = true;
            }
          });

          //Collapse section
          state.data.sections[sectionIndex].startCollapsable = true;
        }
        state.data.sections[sectionIndex].isChecked = !sectionItem?.isChecked;
      return state;
    });
  };

  const toggleCollapsed = () => {
    setProgress((prev) => {
      let state = { ...prev };
      state.data.sections[sectionIndex].startCollapsable =
        !sectionItem.startCollapsable;
      return state;
    });
  };

  return { toggleCheckbox, toggleCollapsed, sectionItem };
};
