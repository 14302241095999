import { useConfirmationModal } from "./useConfirmationModal";
import AuthCode from "react-auth-code-input";
import { Modal } from "~components";
import { Loader } from "~icons";
import clsx from "clsx";

import styles from "./styles.module.scss";

interface IProps {
  handleCloseModal: () => void;
  email: string;
}

export const CodeConfirmationModal = ({ handleCloseModal, email }: IProps) => {
  const { inputRef, seconds, showError, loading, resendCode, onChangeCode } =
    useConfirmationModal({
      handleCloseModal,
      email,
    });

  return (
    <Modal onCloseModal={handleCloseModal}>
      <div className={styles.codeConfirmation}>
        <div className={styles.codeConfirmation__header}>
          Check your email
          <br />
          for a code
        </div>

        <div className={styles.codeConfirmation__subtitle}>
          Please enter a 6-digit code we’ve sent to <span>{email}.</span>
          This code expires in 15 minutes
        </div>

        <div
          className={clsx(styles.codeConfirmation__inputs, {
            [styles.error]: showError,
          })}
        >
          <AuthCode
            ref={inputRef}
            disabled={loading}
            containerClassName={styles.codeConfirmation__inputsContainer}
            onChange={onChangeCode}
          />

          {showError && (
            <div className={styles.codeConfirmation__error}>Incorrect code</div>
          )}
        </div>
        <div className={styles.codeConfirmation__loader}>
          {loading && (
            <>
              <Loader fill={"#616A85"} />
              Checking your code
            </>
          )}
        </div>

        <div className={styles.codeConfirmation__resend}>
          <div
            className={clsx(styles.codeConfirmation__resendButton)}
            onClick={resendCode}
          >
            {seconds > 0
              ? `Resend code in ${
                  seconds === 60
                    ? "01:00"
                    : seconds > 9
                    ? `00:${seconds}`
                    : `00:0${seconds}`
                }`
              : "Resend code"}
            {/* {codeWasResended ? "Code was resended" : "Resend code"} */}
          </div>
        </div>
        <div className={styles.codeConfirmation__spamText}>
          Can’t find the code? Check your spam folder
        </div>
      </div>
    </Modal>
  );
};
