import { EmptyPlay, File } from "~icons";
import { Button } from "~components";
import { usePDF } from "../../usePDF";
import { IPdf } from "~models";

import styles from "./styles.module.scss";

interface IProps {
  pdf: IPdf;
}

export const PDFItemView = ({ pdf }: IProps) => {
  const { handleView } = usePDF({ pdf, index: 0 });
  return (
    <>
      <div className={styles.pdfItem}>
        <div className={styles.pdfItem__name}>
          <File fill="#0B0F1C" />
          {pdf.title}
        </div>

        <div>
          <Button
            onClick={handleView}
            text={`View`}
            backgroundColor="#3A6CFF"
            textColor="#FFFFFF"
            icon={<EmptyPlay stroke="white" />}
          />
        </div>
      </div>

      <div className={styles.pdfItem__underline} />
    </>
  );
};
