import { regex } from "~constants";
import { IItem } from "~models";
import { useChecklist } from "../../../../context";

interface IProps {
  item: IItem;
  progressItem: any;
}

export const useCalculator = ({ item, progressItem }: IProps) => {
  const { progress, setProgress } = useChecklist();

  const itemIndex = progress.data.items.findIndex(
    (elem: { id: number }) => elem.id === item.id
  );

  const itemWithParsedData =
    typeof item.data === "string"
      ? { ...item, data: JSON.parse(item.data) }
      : item;
  const isNoData =
    itemWithParsedData.data.inputs.findIndex(
      (item: { name: string }) => !!item.name
    ) === -1;

  const handleInputChange = (value: string, index: number) => {
    // if ((!/^\d*\.?\d*$/.test(value) && value !== "") || value[0] === ".")
    //   return;
    if (!/^\d*\.?\d*$/.test(value) && value !== "") return;

    setProgress((prev) => {
      let state = { ...prev };
      if (!state.data.items[itemIndex]?.inputs) {
        state.data.items[itemIndex].inputs = item.data.inputs.map(() => "");
      }
      state.data.items[itemIndex].inputs[index] = value;
      return state;
    });
  };

  function parse(str: string) {
    return Function(`'use strict'; return (${str})`)();
  }

  const calculate = () => {
    let resOutputs: { name: string; result: number }[] = [];
    item.data.outputs.forEach(
      (item: { logic: string; name: string }, index: number) => {
        const alphabet = ["a", "b", "c", "d", "e"];
        let exp = item.logic;
        let arrayFromExpression = exp.split("");

        arrayFromExpression = arrayFromExpression.map((item: string) => {
          if (alphabet.includes(item)) {
            const index = alphabet.findIndex((elem: string) => elem === item);
            return progressItem.inputs[index];
          } else {
            return item;
          }
        });

        let result = Number(parse(arrayFromExpression.join("")).toFixed(3));
        resOutputs.push({ name: item.name, result });
      }
    );

    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].outputs = resOutputs;
      return state;
    });
  };

  const disableCalculateButton =
    !progressItem?.inputs ||
    progressItem.inputs.findIndex(
      (item: string) => item === "" || item[item.length - 1] === "."
    ) > -1;

  return {
    isNoData,
    itemWithParsedData,
    disableCalculateButton,
    handleInputChange,
    calculate,
  };
};
