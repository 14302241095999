import React from "react";
import {
  Bell,
  BellOff,
  Pause,
  Play,
  Stopwatch as StopwatchIcon,
  Timer as TimerIcon,
} from "~icons";

import styles from "./styles.module.scss";
import { Button } from "~components";
import { useTimer } from "./useTimer";
import { IItem } from "~models";
import dayjs from "dayjs";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const Timer = ({ item, isDisable, progressItem }: IProps) => {
  const {
    converted,
    handlePause,
    handleStart,
    handleReset,
    toggleMute,
    isRunning,
    disableResetButton,
    disablePlayButton,
  } = useTimer({ item, progressItem });
  return (
    <div className={styles.timer}>
      <div className={styles.timer__content}>
        <div className={styles.timer__row}>
          <div className={styles.timer__contentIcon}>
            <TimerIcon />
          </div>
          <p>{converted}</p>
        </div>
        <div className={styles.timer__contentRight}>
          <div className={styles.timer__contentButton}>
            <Button
              onClick={isRunning ? handlePause : handleStart}
              disable={isDisable || disablePlayButton}
              icon={isRunning ? <Pause fill="#FFFFFF" /> : <Play />}
              backgroundType={"green"}
              text=""
            />
          </div>
          <div className={styles.timer__contentReset}>
            <Button
              onClick={handleReset}
              disable={isDisable || disableResetButton}
              backgroundType={"white"}
              text="Reset"
              showShadow
            />
          </div>
          <div className={styles.timer__contentButton}>
            <Button
              onClick={toggleMute}
              disable={isDisable}
              icon={progressItem?.isMuted ? <BellOff /> : <Bell />}
              backgroundType={"white"}
              text=""
              showShadow
            />
          </div>
        </div>
      </div>
      {item.data.record && !!progressItem.firstStart && (
        <p className={styles.timer__date}>
          {!!progressItem.firstStart && (
            <span>
              Start time: {dayjs(progressItem.firstStart).format("hh:mm:ss A")}{" "}
            </span>
          )}
          {!!progressItem.finisedTime && (
            <span>
              - End time: {dayjs(progressItem.finisedTime).format("hh:mm:ss A")}
            </span>
          )}
        </p>
      )}
    </div>
  );
};
