import { useBuilder } from "../../../../context";
import { useState } from "react";
import { IItem } from "~models";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const useTextBox = ({ item, itemIndex }: IProps) => {
  const { setList, activeTabIndex, activeItem } = useBuilder();

  const [showFroalaModal, setShowFroalaModal] = useState(false);

  const toogleFroalaModal = () => {
    setShowFroalaModal(!showFroalaModal);
  };
  const config = {
    // events:{
    //     'save'
    // }
  };

  let model =
    typeof item.data === "string"
      ? JSON.parse(item.data).model
      : item.data.model;

  const onModelChange = (value: string) => {
    if (!activeItem) return;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = { model: value };
      return state;
    });
  };

  return { model, showFroalaModal, onModelChange, toogleFroalaModal };
};
