import styles from "./styles.module.scss";
import { Message } from "./Message";
import { Loader } from "~icons";
import { useChat } from "./useChat";
import clsx from "clsx";
import { IMessage } from "~models";
import { useRef } from "react";

interface IProps {
  showMainLoader: boolean;
  messages: IMessage[];
  setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>;
}

export const Chat = ({ showMainLoader, messages, setMessages }: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    question,
    scrollContainerRef,
    showLoader,
    setQuestion,
    sendQuestions,
  } = useChat({
    setMessages,
    messages,
  });

  const handleEnterKey = (event: any) => {
    if (event.key === "Enter") {
      sendQuestions();
      inputRef.current?.blur();
    }
  };

  return (
    <div className={styles.container}>
      {showMainLoader ? (
        <div className={styles.loader}>
          <Loader fill="#29B473" />
        </div>
      ) : (
        <div ref={scrollContainerRef} className={styles.container__messages}>
          {messages.map((item, index) => (
            <Message
              key={`${item.id} - ${index}`}
              message={item}
              messageIndex={index}
              setMessages={setMessages}
            />
          ))}
        </div>
      )}

      <div className={styles.container__footer}>
        <p className={styles.container__footerExample}>
          <span>Input problem statement below.</span> For example: "Patient is a
          50 year old male with a past history of hypertension presenting with
          chest discomfort for the past hour. Patient describes the pain as..."
        </p>

        <div className={styles.container__footerInput}>
          <input
            ref={inputRef}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            onSubmit={() => alert(1)}
            disabled={showMainLoader}
            placeholder="Enter a request"
            onKeyDown={handleEnterKey}
          />
          {showLoader ? (
            <Loader fill={"#0B0F1C"} />
          ) : (
            <p
              onClick={sendQuestions}
              className={clsx(question.length === 0 && styles.inactive)}
            >
              Send
            </p>
          )}
        </div>

        <p className={styles.container__footerWarning}>
          <span>Note:</span> The checkem team is not responsible for the
          consequences of СheckemAI responses, as this version of the chat is
          being tested!
        </p>
      </div>
    </div>
  );
};
