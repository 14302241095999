import styles from "./styles.module.scss";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import { IFolder } from "~models";

interface IProps {
  data: any[];
  folders: IFolder[];
  renderItem: (item: any, index: number) => any;
  renderFolderItem: (item: IFolder, index: number) => any;
  handleLoad?: () => void;
  loading?: boolean;
}

export const GridWrapper = ({
  folders,
  data,
  renderItem,
  renderFolderItem,
  handleLoad,
  loading,
}: IProps) => {
  const contentRef = useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      contentRef.current as HTMLDivElement;
    if (scrollHeight - scrollTop <= clientHeight && !loading && handleLoad) {
      handleLoad();
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <div className={styles.gridDraggable} ref={contentRef} onScroll={handleScroll}>
      {!!folders && (
        <>
          <div
            className={clsx(
              styles.gridDraggable__items,
              styles.gridDraggable__itemsFolders
            )}
          >
            <div className={styles.gridDraggable__itemsContent}>
              {folders.map((folder, index) => renderFolderItem(folder, index))}
            </div>
          </div>
        </>
      )}

      <div className={styles.gridDraggable__items}>
        <div className={styles.gridDraggable__itemsContent}>
          {data.map((item, index: number) => renderItem(item, index))}
        </div>
      </div>
    </div>
  );
};
