import { Button, PageHeader } from "~components";
import { InvateMembersModal } from "~modals";
import { DashboardLayout } from "~layouts";
import { Outlet } from "react-router-dom";
import { useMembers } from "./useMembers";
import { InviteMembers } from "~icons";

import styles from "./styles.module.scss";
import { EnterPayment } from "../../../../blocks/CreateOrganization/modules";

export const Members = () => {
  const {
    pageHeader,
    isDisableInviteButton,
    showInviteModal,
    handleOpenInviteModal,
    handleCloseInviteModal,
    choosedOrganization,
    subscription,
    updateData
  } = useMembers();
  console.log(choosedOrganization, "organization");

  return (
    <DashboardLayout
      title="Members"
      rightItem={
        <Button
          disable={isDisableInviteButton}
          backgroundColor={
            isDisableInviteButton ? "rgba(0,0,0,0.15)" : undefined
          }
          onClick={handleOpenInviteModal}
          icon={<InviteMembers fill="#FFFFFF" />}
          text={"Invite Members"}
          backgroundType={"green"}
          textColor={isDisableInviteButton ? "#FFFFFF" : undefined}
          buttonClassName={styles.members__inviteButton}
        />
      }
    >
      <div className={styles.members}>
        {/* <PageHeader data={pageHeader} /> */}
        {showInviteModal && (
          <InvateMembersModal handleCloseModal={handleCloseInviteModal} />
        )}
        {subscription?.id ? (
          <Outlet />
        ) : (
          <EnterPayment
            goNext={() => console.log("next")}
            organization={choosedOrganization}
            organizationFromProps={choosedOrganization}
            handleSuccessPayment={updateData}
          />
        )}
      </div>
    </DashboardLayout>
  );
};
