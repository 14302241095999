import { alertVar, choosedOrganizationVar } from "~localCache";
import { PublishingStatus, IDraft } from "~models";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useRef, useState } from "react";

import {
  DUPLICATE_DRAFT,
  PUBLISH_DRAFT,
  REMOVE_DRAFT,
  REMOVE_DRAFT_FROM_FOLDER,
  UNPUBLISH_DRAFT,
} from "~mutations";

interface IProps {
  draft: IDraft;
  index: number;
  isAdminDraft?: boolean;
  updateDraftStatus: (status: PublishingStatus, index: number) => void;
  showTableLoader?: () => void;
  updateTableData?: () => void;
}

export const useDraft = ({
  draft,
  index,
  isAdminDraft,
  updateDraftStatus,
  showTableLoader,
  updateTableData,
}: IProps) => {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);

  const choosedOrganization = choosedOrganizationVar();

  const [publishDraft] = useMutation(PUBLISH_DRAFT);
  const [unpublishDaft] = useMutation(UNPUBLISH_DRAFT)
  const [duplicateDraft] = useMutation(DUPLICATE_DRAFT);
  const [removeDraft] = useMutation(REMOVE_DRAFT);
  const [removeDraftFromFolder] = useMutation(REMOVE_DRAFT_FROM_FOLDER);

  const [showPopper, setShowPopper] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [showShareCodeModal, setShowShareCodeModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false)

  const handleOpenMoveModal = () => {
    handleClosePopper();
    setShowMoveModal(true)
  }

  const handleCloseMoveModal = () => {
    handleClosePopper();
    setShowMoveModal(false)
  }


  const handlePublish = () => {
    alertVar({
      type: "blue",
      text: "Checklist is publishing",
      isLoading: true,
    });
    handleClosePopper();
    publishDraft({
      variables: { id: draft.id },
    })
      .then(() => {
        updateDraftStatus(PublishingStatus.Published, index);
        alertVar({
          type: "green",
          text: "Checklist was successfully published",
        });
      })
      .catch((err) => {
        alertVar({
          type: "red",
          text: "Error: this checklist can’t be published, because it has empty fields",
          isLoading: false,
        });
        navigate(`/builder/${draft.id}`, {
          state: {
            error: JSON.parse(err.graphQLErrors[0].extensions.response.error),
          },
        });
      });
  };

  const handleEdit = () => {
    navigate(`/builder/${draft.id}`, { state: { isAdmin: isAdminDraft } });
  };

  const handleDownloadPdf = () => {
    navigate(`/builder/${draft.id}`, { state: { pdf: true } });
  };

  const handleOpenPopper = () => setShowPopper(true);
  const handleClosePopper = () => setShowPopper(false);

  const handleOpenDeletionModal = () => {
    handleClosePopper();
    setShowDeletionModal(true);
  };
  const handleCloseDeletionModal = () => setShowDeletionModal(false);

  const handleOpenShareModal = () => {
    handleClosePopper();
    setShowShareCodeModal(true);
  };
  const handleCloseShareModal = () => setShowShareCodeModal(false);

  const handleDuplicate = () => {
    handleClosePopper();
    !!showTableLoader && showTableLoader();
    duplicateDraft({ variables: { id: draft.id } }).then(() => {
      !!updateTableData && updateTableData();
    });
  };

  const handleDelete = () => {
    handleClosePopper();
    !!showTableLoader && showTableLoader();
    handleCloseDeletionModal();
    removeDraft({ variables: { id: draft.id } }).then(() => {
      !!updateTableData && updateTableData();
    });
  };

  const handleUnpublish = () => {
    alertVar({
      type: "blue",
      text: "Checklist is unpublishing",
      isLoading: true,
    });
    handleClosePopper();
    unpublishDaft({
      variables: {
          id: draft.id,
      },
    }).then(() => {
      updateDraftStatus(PublishingStatus.Unpublished, index);
      alertVar({
        type: "green",
        text: "Checklist was successfully unpublished",
      });
    });
  };

  const handleMoveOut = () => {
    alertVar({
      type: "blue",
      text: "Checklist is moving out of folder",
      isLoading: true,
    });
    removeDraftFromFolder({ variables: { id: draft.id } }).then(() => {
      !!updateTableData && updateTableData();
      alertVar({
        type: "green",
        text: "Checklist was successfully moved out from folder",
      });
    });
  };

  return {
    modalRef,
    myRole: !!choosedOrganization?.members
      ? choosedOrganization.members[0].role
      : "User",
    showPopper,
    showDeletionModal,
    showShareCodeModal,
    showMoveModal,
    handleDelete,
    handleDuplicate,
    handleOpenPopper,
    handleClosePopper,
    handlePublish,
    handleEdit,
    handleDownloadPdf,
    handleUnpublish,
    handleOpenDeletionModal,
    handleCloseDeletionModal,
    handleOpenShareModal,
    handleCloseShareModal,
    handleMoveOut,
    handleOpenMoveModal,
    handleCloseMoveModal
  };
};
