import { IItem } from "../../../../constants";
import { Button, Input, SoundDropdown, SwitchField } from "~components";
import styles from "./styles.module.scss";
import { useAlgorithm } from "./useAlgorithm";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { AssesmentItem } from "./components/AssesmentItem";
import { PlusSymbol } from "~icons";
import { ResultItem } from "./components/ResultItem";

interface IProps {
  item: IItem;
  itemIndex: number;
}

interface IAssesment {
  title: string;
  description: string;
  findings: { name: string; score: number }[];
}

export const AlgorithmSettings = ({ item, itemIndex }: IProps) => {
  const { handleAddAlgorithm, onAssesmentDragEnd } = useAlgorithm({
    item,
    itemIndex,
  });

  return (
    <div className={styles.algorithmSettings}>
      <div className={styles.algorithmSettings__underline} />

      <DragDropContext onDragStart={() => {}} onDragEnd={onAssesmentDragEnd}>
        <Droppable droppableId="assesment" type="assesment">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {item.data.assessments.map(
                (assesment: IAssesment, assesmentIndex: number) => {
                  return (
                    <AssesmentItem
                      key={`assesment -  ${assesmentIndex}`}
                      item={item}
                      itemIndex={itemIndex}
                      assesment={assesment}
                      assesmentIndex={assesmentIndex}
                      assesmentsLength={item.data.assessments.length}
                    />
                  );
                }
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className={styles.algorithmSettings__addButton}>
        <Button
          onClick={handleAddAlgorithm}
          text="Add assessment"
          backgroundType={"green"}
          showShadow
          icon={<PlusSymbol fill="#ffffff" />}
        />
      </div>
      <div className={styles.algorithmSettings__underline} />

      <ResultItem item={item} itemIndex={itemIndex} />
    </div>
  );
};
