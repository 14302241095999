import { alertVar, choosedOrganizationVar } from "~localCache";
import { useMutation } from "@apollo/client";
import { CREATE_ADMIN_FOLDER, CREATE_FOLDER } from "~mutations";
import { useState } from "react";

interface IProps {
  isAdmin?: boolean;
  folderId?: number;
  onCloseModal: () => void;
  handleSuccess: () => void;
}

export const useCreateFolder = ({
  isAdmin,
  folderId,
  onCloseModal,
  handleSuccess,
}: IProps) => {
  const [createFolder] = useMutation(CREATE_FOLDER);
  const [createAdminFolder] = useMutation(CREATE_ADMIN_FOLDER);

  const choosedOrganization = choosedOrganizationVar();

  const [title, setTitle] = useState("");

  const [showLoader, setShowLoader] = useState(false);

  const handleTitleChange = (value: string) => setTitle(value);
  const handleCloseModal = () => {
    !!title && setTitle("");
    onCloseModal();
  };

  const handleCreateButton = () => {
    setShowLoader(true);
    const createFunc = isAdmin ? createAdminFolder : createFolder;
    const input = { title };
    !isAdmin &&
      Object.assign(input, { organizationId: choosedOrganization?.id });
    !!folderId && Object.assign(input, { folderId });
    createFunc({
      variables: { input },
    }).then(() => {
      setShowLoader(false);
      alertVar({ text: "Folder created successfully", type: "green" });
      handleSuccess();
      handleCloseModal();
    });
  };

  return {
    title,
    showLoader,
    handleCloseModal,
    handleTitleChange,
    handleCreateButton,
  };
};
