import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "~hooks";
import { CHECK_RESET_CODE, RESEND_VERIFY, VERIFY_EMAIL } from "~mutations";
import { GET_CURRENT_USER } from "~queries";
import { AuthCodeRef } from "react-auth-code-input";

export const useConfirmation = () => {
  const { setUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef<AuthCodeRef>(null);

  const [code, setCode] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [seconds, setSeconds] = useState(60);

  const [mutateFunction, { loading: verifyEmailLoading }] =
    useMutation(VERIFY_EMAIL);
  const [queryGetUser] = useLazyQuery(GET_CURRENT_USER);
  const [mutateCheckResetCode, { loading: checkResetCodeLoading }] =
    useMutation(CHECK_RESET_CODE);
  const [mutateResendVerify] = useMutation(RESEND_VERIFY);

  const checkUserInfo = () => {
    queryGetUser({ variables: {} }).then((resp) => {
      setUser(resp.data.currentUser);
    });
  };

  const resendCode = () => {
    if (seconds > 0) return;
    setSeconds(60);
    !!showError && setShowError(false);
    inputRef.current?.clear();
    inputRef.current?.focus();
    mutateResendVerify({
      variables: { resendVerifyInput: { email: location.state.email } },
    });
  };

  const onChangeCode = (value: string) => {
    setCode(value);
    showError && setShowError(false);
    if (value.length === 6) {
      mutateFunction({
        variables: {
          verifyInput: { email: location.state.email, code: +value },
        },
      })
        .then((resp) => {
          if (location.state.isReset) {
          } else {
            localStorage.setItem(
              "accessToken",
              resp.data.verifyEmail.access_token
            );
            localStorage.setItem(
              "refreshToken",
              resp.data.verifyEmail.refresh_token
            );
            checkUserInfo();
          }
        })
        .catch((err) => {
          setShowError(true);
        });
    }
  };

  const resetCode = (value: string) => {
    setCode(value);
    showError && setShowError(false);
    if (value.length === 6) {
      mutateCheckResetCode({
        variables: {
          resetCheckCodeInput: { email: location.state.email, code: +value },
        },
      })
        .then((resp) => {
          navigate("/chooseNewPassword", {
            state: { email: location.state.email },
          });
        })
        .catch((err) => {
          setShowError(true);
        });
    }
  };

  const disableButton = code.length < 6;

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        return;
      }
      if (seconds === 0) clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  });

  return {
    onChangeCode: !!location.state.isReset ? resetCode : onChangeCode,
    loading: verifyEmailLoading || checkResetCodeLoading,
    showError,
    disableButton,
    location,
    resendCode,
    inputRef,
    seconds,
  };
};
