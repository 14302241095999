import { IItem } from "../../../../../constants";

interface IProps {
  item: IItem;
}

export const useCalculator = ({ item }: IProps) => {
  const itemWithParsedData =
    typeof item.data === "string"
      ? { ...item, data: JSON.parse(item.data) }
      : item;
  const isNoData =
    itemWithParsedData.data.inputs.findIndex(
      (item: { name: string }) => !!item.name
    ) === -1;

  return { isNoData, itemWithParsedData };
};
