import { Cross, InfoCircle, InfoCircleSmall } from "~icons";
import styles from "./styles.module.scss";
import { INotepadAnswer, NotepadAnswerType } from "~models";
import { PopperPopup } from "~components";
import { useState } from "react";
import { Options, createPopper } from "@popperjs/core";
import { StrictModifierNames, Modifier } from "react-popper";

interface IProps {
  item?: INotepadAnswer;
  title?: string;
  onRemove?: () => void;
}

export const Item = ({ item, title, onRemove }: IProps) => {
  const [showPopper, setShowPopper] = useState(false);

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [-26, 15],
        },
      },
    ],
  };

  return (
    <div className={styles.container}>
      {!!item && (
        <PopperPopup
          options={popperOptions}
          trigger={
            <InfoCircleSmall style={{ marginRight: 10, minWidth: 18 }} />
          }
          showPopper={showPopper}
          onClosePopper={() => setShowPopper(false)}
          onOpenPopper={() => setShowPopper(true)}
          popup={
            <div className={styles.popup}>
              <div className={styles.popup__row}>
                <p>{item.explanation}</p>
                <Cross fill="#616A85" onClick={() => setShowPopper(false)} />
              </div>

              <p>Source:{item.source}</p>
            </div>
          }
        />
      )}

      <p>{!!item ? item.data : title}</p>
      <Cross onClick={onRemove} fill="#616A85" />
    </div>
  );
};
