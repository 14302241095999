import { DotsVertical, EmptyPlay, FileDownload, TrashAlt } from "~icons";
import { StrictModifierNames, Modifier } from "react-popper";
import { useProgressItem } from "../../useProgressItem";
import { Options, createPopper } from "@popperjs/core";
import { Button, PopperPopup } from "~components";
import { IActiveChecklist } from "~models";
import { FC } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";

interface IProps {
  activeChecklist: IActiveChecklist;
  index: number;
  handleContinue: () => void;
  deleteProgress: (id: number, index: number) => void;
}

export const ProgressItemSquare: FC<IProps> = ({
  activeChecklist,
  index,
  deleteProgress,
}: IProps) => {
  const {
    modalRef,
    myRole,
    openListPreview,
    openPdfPreview,
    showPopper,
    handleContinue,
    handleClosePopper,
    handleOpenPopper,
  } = useProgressItem({
    activeChecklist,
  });

  const isDark = useReactiveVar(darkTheme)

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-end",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  const popperPopup = (
    <PopperPopup
      showPopper={showPopper}
      onClosePopper={handleClosePopper}
      onOpenPopper={handleOpenPopper}
      options={popperOptions}
      trigger={<DotsVertical fill="#0B0F1C" />}
      popup={
        <div ref={modalRef} className={clsx(styles.progressItem__modal, {})}>
          {!activeChecklist.completed ? (
            <>
              <div
                onClick={handleContinue}
                className={styles.progressItem__modalItem}
              >
                <EmptyPlay stroke="#0B0F1C" />
                Complete
              </div>
            </>
          ) : (
            <>
              {myRole !== "User" && (
                <div
                  onClick={openPdfPreview}
                  className={styles.progressItem__modalItem}
                >
                  <FileDownload /> View
                </div>
              )}
              <div
                onClick={openPdfPreview}
                className={styles.progressItem__modalItem}
              >
                <FileDownload /> Generate PDF
              </div>
            </>
          )}

          {/* {myRole !== "User" && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleClosePopper();
                deleteProgress(activeChecklist.id, index);
              }}
              className={styles.progressItem__modalItem}
            >
              <TrashAlt fill="#0B0F1C" /> Delete
            </div>
          )} */}
        </div>
      }
    />
  );

  return (
    <div>
      <div className={isDark ? clsx(styles.progressItem, styles.progressItemDark) : styles.progressItem}>
        <div className={styles.progressItem__header}>
          <div className={clsx(styles.progressItem__headerStatus)}>
            {activeChecklist.completed ? "COMPLETED" : "IN PROGRESS"}
          </div>

          {popperPopup}
        </div>
        <div className={styles.progressItem__title}>
          {activeChecklist.checklist.title}
        </div>
        {!activeChecklist.completed ? (
          <div className={styles.progressItem__button}>
            <Button
              onClick={handleContinue}
              icon={<EmptyPlay stroke="white" />}
              text="Continue"
              backgroundType={"green"}
            />
          </div>
        ) : (
          <>
            {myRole !== "User" && (
              <div className={styles.progressItem__button}>
                <Button
                  onClick={openListPreview}
                  text="View"
                  backgroundType={"green"}
                  backgroundColor="#3A6CFF"
                  textColor="#FFFFFF"
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
