import React, { FC, ReactNode, useState } from "react";

import { clsx } from "clsx";

import styles from "./styles.module.scss";
import { EyeOff, EyeOn } from "~icons";
import InputMask from "react-input-mask";

interface IProps {
  label?: string;
  placeholder: string;
  value?: string;
  onChange?: (val: string) => void;
  type?: "general" | "secure" | "limited";
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  mask?: string;
  maskChar?: string;
  error?: string;
  disable?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  onBlur?: () => void;
}
export const Input: FC<IProps> = ({
  label,
  placeholder,
  value = "",
  type = "general",
  onChange,
  rightIcon,
  mask = "",
  maskChar = "",
  error,
  leftIcon,
  disable,
  labelClassName,
  onBlur,
  inputClassName,
  containerClassName,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showHideTest, setShowHideText] = useState(false);

  const setActiveFocused = () => setIsFocused(true);
  const setDisactiveFocused = () => {
    setIsFocused(false);
    !!onBlur && onBlur();
  };

  const isLemitOver = (val: string) => type !== "limited" || val.length <= 50;

  const onChangeSecureStatus = () => setShowHideText(!showHideTest);
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    !!onChange &&
    !disable &&
    isLemitOver(e.target.value) &&
    onChange(e.target.value);

  return (
    <div className={clsx(styles.inputContainer, containerClassName)}>
      {!!label && (
        <div className={clsx(styles.inputContainer__label, labelClassName)}>
          {label}
        </div>
      )}
      <div
        className={clsx(styles.inputWrapper, {
          [styles.focus]: isFocused,
          [styles.error]: !!error,
        })}
      >
        {!!leftIcon && leftIcon}

        <InputMask
          disabled={disable}
          type={type === "secure" && !showHideTest ? "password" : "text"}
          placeholder={placeholder}
          onFocus={setActiveFocused}
          onBlur={setDisactiveFocused}
          className={inputClassName}
          mask={mask}
          maskChar={maskChar}
          value={value}
          onChange={onChangeValue}
          autoComplete={type === "secure" ? "new-password" : "on"}
        />
        {type === "secure" && (
          <div
            className={styles.inputContainer__right}
            onClick={onChangeSecureStatus}
          >
            {showHideTest ? <EyeOn /> : <EyeOff />}
          </div>
        )}

        {type === "limited" && (
          <div
            className={styles.inputContainer__limited}
            onClick={onChangeSecureStatus}
          >
            {value.length}/50
          </div>
        )}
        {!!rightIcon && rightIcon}
      </div>
      {!!error && <div className={styles.inputContainer__error}>{error}</div>}
    </div>
  );
};
