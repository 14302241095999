import {
  GridDraggable,
  PDFItem,
  PDFItemSquare,
  Pagination,
  SearchInput,
  TableDraggable,
  TableLoader,
  DraftItem,
  DraftItemSquare,
  FolderItem,
  FolderItemSquare,
  SearchBar,
} from "~components";
import { PlusButton } from "./components";
import { GridView, TableView } from "~icons";
import { DashboardLayout } from "~layouts";
import { Loader } from "~icons";
import { useDrafts } from "./useDrafts";

import styles from "./styles.module.scss";

export const OrganizationDrafts = () => {
  const {
    drafts,
    loading,
    draftsCount,
    fetchedCount,
    searchValue,
    PAGE_LIMIT,
    viewType,
    paginationLoading,
    folders,
    showLoader,
    loadData,
    onDragEnd,
    setViewType,
    setSearchValue,
    handleNextPage,
    handlePrevPage,
    updateDraftStatus,
  } = useDrafts();

  console.log(drafts, 'items')
  return (
    <DashboardLayout
      title="Checkem Builder"
      rightItem={<PlusButton refreshData={loadData} />}
    >
      <div className={styles.organizationDrafts}>
        <SearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          viewType={viewType}
          setViewType={setViewType}
        />

        {loading && <TableLoader />}

        {!loading && viewType === "table" && (
          <TableDraggable
            header={[
              { header: "Name", className: styles.organizationDrafts__name },
              {
                header: "Last modified",
                className: styles.organizationDrafts__date,
              },
              {
                header: "Action",
                className: styles.organizationDrafts__action,
              },
              { header: "Type", className: styles.organizationDrafts__type },
              {
                header: "Status",
                className: styles.organizationDrafts__status,
              },
            ]}
            folders={folders}
            data={drafts}
            onDragEnd={onDragEnd}
            handleLoad={handleNextPage}
            loading={paginationLoading}
            renderFolderItem={(folder, index, isDraggingOver) => {
              return (
                <FolderItem
                  key={`${folder.id} - ${index} - folder`}
                  folder={folder}
                  index={index}
                  isDraggingOver={isDraggingOver}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              );
            }}
            renderTableItem={(item, index) => {
              return item.type === "Pdf" ? (
                <PDFItem
                  pdf={item}
                  key={`${item.id} - ${index} - item`}
                  index={index}
                  updatePDFStatus={updateDraftStatus}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              ) : (
                <DraftItem
                  draft={item}
                  key={`${item.id} - ${index} - item`}
                  index={index}
                  updateDraftStatus={updateDraftStatus}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              );
            }}
          />
        )}

        {!loading && viewType === "grid" && (
          <GridDraggable
            data={drafts}
            folders={folders}
            handleLoad={handleNextPage}
            loading={paginationLoading}
            // draggableTypes={["Pdf"]}
            onDragEnd={onDragEnd}
            renderItem={(item, index) => {
              return item.type === "Pdf" ? (
                <PDFItemSquare
                  pdf={item}
                  key={`${item.id} - ${index} - item`}
                  index={index}
                  updatePDFStatus={updateDraftStatus}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              ) : (
                <DraftItemSquare
                  draft={item}
                  key={`${item.id} - ${index} - item`}
                  index={index}
                  updateDraftStatus={updateDraftStatus}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              );
            }}
            renderFolderItem={(folder, index, isDraggingOver) => {
              return (
                <FolderItemSquare
                  key={`${folder.id} - ${index} - folder`}
                  folder={folder}
                  index={index}
                  isDraggingOver={isDraggingOver}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              );
            }}
          />
        )}
        {paginationLoading && (
          <div className={styles.paginationLoader}>
            <Loader fill="#616A85" />
          </div>
        )}

        {/* <Pagination
          allResults={draftsCount}
          fetchedResult={fetchedCount + PAGE_LIMIT}
          limit={PAGE_LIMIT}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
        /> */}
        <div className={styles.pagination}>
          <div className={styles.pagination__left}>
            Showing{" "}
            <span>
              {fetchedCount + PAGE_LIMIT > draftsCount
                ? draftsCount
                : !!draftsCount && !!(fetchedCount + PAGE_LIMIT)
                ? fetchedCount + PAGE_LIMIT
                : 0}
            </span>{" "}
            of <span>{draftsCount}</span> Results
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
