import { useBuilder } from "../../../../context";
import { IItem } from "../../../../constants";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const useCounter = ({ item, itemIndex }: IProps) => {
  const { setList, activeItem, activeTabIndex } = useBuilder();

  const toggleRecordTime = () => {
    if (!activeItem) return;
    let data = { ...item.data };
    data.record = !item.data.record;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;

      return state;
    });
  };

  const toggleAllowNegative = () => {
    if (!activeItem) return;
    let data = { ...item.data };
    data.allowNegative = !item.data.allowNegative;

    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  return { toggleRecordTime, toggleAllowNegative };
};
