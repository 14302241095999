import { gql } from "@apollo/client";

export const GET_ALL_PROGRESSES = gql`
  query getAllMyProgresses($input: FindProgressInput!) {
    getAllMyProgresses(input: $input) {
      id
      completed
      data
      organizationId
      checklistId
      userId
      createdAt
      updatedAt
      checklist {
        id
        title
      }
    }
  }
`;

export const GET_ALL_ACTIVE_PROGRESSES = gql`
  query getAllMyActiveProgresses($organizationId: Int!) {
    getAllMyActiveProgresses(organizationId: $organizationId) {
      id
      completed
      data
      organizationId
      checklistId
      userId
      createdAt
      updatedAt
      checklist {
        id
        title
      }
    }
  }
`;

export const GET_PAGINATION_PROGRESS_DATA = gql`
  query getPaginationProgressData(
    $countInput: CountProgressInput!
    $input: FindProgressInput!
  ) {
    getAllMyProgressesCount(input: $countInput)
    getAllMyProgresses(input: $input) {
      id
      completed
      data
      organizationId
      checklistId
      userId
      createdAt
      updatedAt
      checklist {
        id
        title
      }
    }
  }
`;

export const GET_ALL_ORGANIZATION_COMPLETED_PROGRESSES = gql`
  query getAllOrganizationCompletedProgresses(
    $input: FindProgressByCompleteStatusInput!
    $countInput: CountProgressByCompleteStatusInput!
  ) {
    getAllProgressesByCompleteStatus(input: $input) {
      id
      completed
      data
      organizationId
      checklistId
      checklist {
        id
        title
        version
        latest
        organizationId
        draftId
      }
      user {
        firstName
        lastName
        avatar
      }
      userId
      createdAt
      updatedAt
    }
    getAllProgressesByCompleteStatusCount(input: $countInput)
  }
`;

export const GET_ALL_COMPLETED_PROGRESSES_DATA = gql`
  query getCompletedProgressesData(
    $input: FindProgressInput!
    $countInput: CountProgressInput!
  ) {
    getAllProgresses(input: $input) {
      id
      completed
      data
      organizationId
      checklistId
      checklist {
        id
        title
        version
        latest
        organizationId
        draftId
      }
      userId
      createdAt
      updatedAt
    }
    getAllProgressesCount(input: $countInput)
  }
`;
