import { EmptyPlay, File } from "~icons";
import { usePDF } from "../../usePDF";
import { Button } from "~components";
import { IPdf } from "~models";
import { FC } from "react";

import styles from "./styles.module.scss";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";
import clsx from "clsx";

interface IProps {
  pdf: IPdf;
}

export const PDFItemViewSquare: FC<IProps> = ({ pdf }: IProps) => {
  const { handleView } = usePDF({ pdf });
  const isDark = useReactiveVar(darkTheme);

  return (
    <div>
      <div className={!isDark ? styles.pdfItem : clsx(styles.pdfItem, styles.pdfItem__dark)}>
        <div className={styles.pdfItem__left}>
          <File fill="#0B0F1C" />
        </div>
        <div className={styles.pdfItem__right}>
          <p>{pdf.title}</p>
          <Button
            onClick={handleView}
            text="View"
            backgroundColor="#3A6CFF"
            textColor="#FFFFFF"
            icon={<EmptyPlay stroke="white" />}
          />
        </div>
      </div>
    </div>
  );
};
