import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FormEvent, useRef, useState } from "react";
import { CREATE_SUBSCRIBTION } from "~mutations";
import { IError } from "../constants";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { IOrganization } from "~models";
import {
  GET_CHOOSED_ORGANIZATIOM,
  alertVar,
  choosedOrganizationVar,
} from "~localCache";
import { GET_MY_INVITATIONS } from "~queries";

interface IProps {
  goNext: () => void;
  organization: IOrganization | null;
  handleSuccessPayment?: () => void;
}
export enum SubscriptionType {
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
}

export const usePayment = ({
  goNext,
  organization,
  handleSuccessPayment,
}: IProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState<IError>({});

  const [selectedSubscription, setSelectedSubscription] =
    useState<SubscriptionType>(SubscriptionType.MONTHLY);

  const [isCardInputFilled, setIsCardInputFilled] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const [mutationCreateSubscription] = useMutation(CREATE_SUBSCRIBTION);
  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);
  const [getMyOrganizations] = useLazyQuery(GET_MY_INVITATIONS);

  const [usersCount, setUsersCount] = useState<number>(1);
  const [adminsCount, setAdminsCount] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);

  const onChangeUsersCount = (value: string | number) => {
    if (+value === 0) return;
    setUsersCount(+value);
  };

  const monthlyPrice = usersCount * 5;
  const yearlyPrice = usersCount * 50;

  const minimumPackagePrice = 0;
  const totalPrice = minimumPackagePrice + monthlyPrice;

  const [name, setName] = useState("");

  const handleNameChange = (value: string) => {
    !!error?.name && setError({});
    setName(value);
  };

  const isDisable = !isCardInputFilled || name.length < 2;

  const cardElement = elements?.getElement(CardElement);

  cardElement?.on("change", function (event) {
    setIsCardInputFilled(false);
    if (event.complete) {
      setIsCardInputFilled(true);
    }
  });

  const updateOrganizationData = (updatedOrganizationField: object) => {
    if (choosedOrganization.id === organization?.id) {
      choosedOrganizationVar({
        ...choosedOrganization,
        ...updatedOrganizationField,
      });
    }
    getMyOrganizations({ variables: {}, fetchPolicy: "network-only" });
    !!handleSuccessPayment && handleSuccessPayment();
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements || !cardElement) {
      return;
    }
    setLoading(true);
    const stripeResponse = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    const { error, paymentMethod } = stripeResponse;
    if (error || !paymentMethod) {
      return;
    }
    console.log(stripeResponse, "stripe");
    const paymentMethodId = paymentMethod.id;

    mutationCreateSubscription({
      variables: {
        input: {
          organizationId: organization?.id,
          additionalMembers: usersCount,
          paymentMethodId: paymentMethodId,
          plan: selectedSubscription
        },
      },
    })
      .then((resp) => {
        setLoading(false);
        updateOrganizationData(resp.data.createSubscription);
        goNext();
      })
      .catch((err) => {
        setLoading(false);
        if (!!err?.graphQLErrors[0]?.message) {
          alertVar({ type: "red", text: err.graphQLErrors[0].message });
        }
      });
  };

  const handleCheckoutClick = () => formRef.current?.requestSubmit();

  return {
    name,
    handleNameChange,
    usersCount,
    adminsCount,
    setUsersCount,
    setAdminsCount,
    yearlyPrice,
    monthlyPrice,
    minimumPackagePrice,
    totalPrice,
    isDisable,
    loading,
    handleSubmit,
    formRef,
    handleCheckoutClick,
    onChangeUsersCount,
    selectedSubscription,
    setSelectedSubscription,
  };
};
