import { StrictModifierNames, Modifier } from "react-popper";
import { Options, createPopper } from "@popperjs/core";
import { Avatar, Button, PopperPopup } from "~components";
import { IOrganization } from "~models";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  CopyTo,
  DotsVertical,
  Duplicate,
  FileDownload,
  Pen,
  Share,
} from "~icons";

import styles from "./styles.module.scss";
import { useOrganizationItem } from "./useOrganizationItem";

interface IProps {
  orgnanization: IOrganization;
  index: number;
}

export const OrganizationItem = ({ orgnanization, index }: IProps) => {
  const { showPopper, handleOpenPopper, handleClosePopper, handleViewButton } =
    useOrganizationItem({ orgnanization });

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  return (
    <>
      <div className={styles.folderItem}>
        <div className={styles.folderItem__organization}>
          <Avatar
            uri={orgnanization.picture}
            item={orgnanization}
            type="organization"
            containerStyles={styles.folderItem__organizationAvatar}
          />
          {orgnanization.name}
        </div>
        <div className={styles.folderItem__members}>
          <div className={styles.folderItem__membersLong}>26 members</div>
          <div className={styles.folderItem__membersShort}>26</div>
        </div>

        <div className={styles.folderItem__date}>
          {dayjs(orgnanization.createdAt).format("MMMM D, YYYY")}
        </div>
        <div className={styles.folderItem__actions}>
          <Button
            text={"View"}
            backgroundType="green"
            onClick={handleViewButton}
          />

          <PopperPopup
            showPopper={showPopper}
            onClosePopper={handleClosePopper}
            onOpenPopper={handleOpenPopper}
            options={popperOptions}
            trigger={<DotsVertical fill="#0B0F1C" />}
            popup={
              <div className={clsx(styles.folderItem__modal, {})}>
                <div className={styles.folderItem__modalItem}>
                  <Pen fill="#0B0F1C" /> Edit
                </div>
                <div
                  className={clsx(styles.folderItem__modalItem, styles.margin)}
                >
                  <Duplicate /> Duplicate
                </div>
                <div className={styles.folderItem__modalItem}>
                  <CopyTo /> Copy to
                </div>

                <div className={styles.folderItem__modalUnderline} />

                <div className={styles.folderItem__modalItem}>
                  <Share /> Share Checkem
                </div>
                <div className={styles.folderItem__modalItem}>
                  <FileDownload /> Generate PDF
                </div>
              </div>
            }
          />
        </div>
      </div>
      <div className={styles.folderItem__underline} />
    </>
  );
};
