import { regex } from "~constants";
import { useBuilder } from "../../../../../../context";
import { IItem } from "~models";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const useResultItem = ({ item, itemIndex }: IProps) => {
  const { setList, activeItem, activeTabIndex } = useBuilder();

  const blockDeletingResult = item.data.results.length === 1;

  let data =
    typeof item.data === "string"
      ? structuredClone(JSON.parse(item.data))
      : structuredClone(item.data);

  const handleAddResult = () => {
    if (!activeItem) return;
    data.results.push({ from: "", to: "", result: "" });
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleDeleteResult = (resultIndex: number) => {
    if (!activeItem || blockDeletingResult) return;
    data.results.splice(resultIndex, 1);
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleResultNameChange = (value: string, resultIndex: number) => {
    if (!activeItem) return;
    data.results[resultIndex].result = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleResultFromChange = (value: string, resultIndex: number) => {
    if (
      !activeItem ||
      (value !== "" &&
        value !== "-" &&
        !regex.negativeOrPositivNumbers.test(value))
    )
      return;
    data.results[resultIndex].from = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleResultToChange = (value: string, resultIndex: number) => {
    if (
      !activeItem ||
      (value !== "" &&
        value !== "-" &&
        !regex.negativeOrPositivNumbers.test(value))
    )
      return;
    data.results[resultIndex].to = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleBlurFrom = (resultIndex: number) => {
    if (data.results[resultIndex].from === "-") {
      handleResultFromChange("", resultIndex);
    }
  };

  const handleBlurTo = (resultIndex: number) => {
    if (data.results[resultIndex].to === "-") {
      handleResultToChange("", resultIndex);
    }
  };

  return {
    blockDeletingResult,
    handleAddResult,
    handleDeleteResult,
    handleResultNameChange,
    handleResultFromChange,
    handleResultToChange,
    handleBlurFrom,
    handleBlurTo,
  };
};
