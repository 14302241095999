import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { GET_CHOOSED_ORGANIZATIOM, alertVar } from "~localCache";
import { useMutation, useQuery } from "@apollo/client";
import { FormEvent, useRef, useState } from "react";
import { ADD_CARD } from "~mutations";

export const useAddCard = (
  handleCloseModal: () => void,
  handleFinish: () => void
) => {
  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const elements = useElements();
  const stripe = useStripe();

  const formRef = useRef<HTMLFormElement>(null);
  const cardElement = elements?.getElement(CardElement);

  const [addCard] = useMutation(ADD_CARD);

  const [isCardInputFilled, setIsCardInputFilled] = useState(false);

  cardElement?.on("change", function (event) {
    setIsCardInputFilled(false);
    if (event.complete) {
      setIsCardInputFilled(true);
    }
  });

  const [name, setName] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const isDisable = !isCardInputFilled || name.length < 2;

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements || !cardElement) {
      return;
    }
    setLoading(true);
    const stripeResponse = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    const { error, paymentMethod } = stripeResponse;
    if (error || !paymentMethod) {
      return;
    }

    const paymentMethodId = paymentMethod.id;

    addCard({
      variables: {
        input: {
          organizationId: choosedOrganization.id,
          paymentMethod: paymentMethodId,
        },
      },
    })
      .then((resp) => {
        setLoading(false);
        alertVar({ type: "green", text: "Payment Method added successfully" });
        handleCloseModal();
        handleFinish();
      })
      .catch((err) => {
        setLoading(false);
        if (!!err?.graphQLErrors[0]?.message) {
          alertVar({ type: "red", text: err.graphQLErrors[0].message });
        }
      });
  };

  const handleAcceptButton = () => formRef.current?.requestSubmit();

  return {
    formRef,
    name,
    loading,
    isDisable,
    setName,
    handleSubmit,
    handleAcceptButton,
  };
};
