import {
  PageHeader,
  Pagination,
  SearchBar,
  SearchInput,
  Table,
  TableLoader,
} from "~components";
import { useCompletedChecklists } from "./useCompletedChecklists";
import { ProgressItem, ProgressItemSquare } from "./components";
import { DeleteChecklistModal } from "./modals";
import { GridView, Loader, TableView } from "~icons";

import styles from "./styles.module.scss";
import { useEffect, useRef } from "react";
import { CompletedGridWrapper } from "./components/CompletedGridWrapper/CompletedGridWrapper";

const PAGE_HEADER = [
  { title: "Active", type: "active", to: "/dashboard/home/active" },
  {
    title: "My Completed / In Progress",
    type: "completed",
    to: "/dashboard/home/completed",
  },
];

export const UserCompletedChecklists = () => {
  const {
    checklists,
    loading,
    paginationLoading,
    draftsCount,
    fetchedCount,
    searchValue,
    PAGE_LIMIT,
    checklistDeletionId,
    viewType,
    handleContinue,
    deleteProgress,
    setViewType,
    handleNextPage,
    handlePrevPage,
    setSearchValue,
    handleCloseDeleteModal,
    handleOpenDeleteModal,
  } = useCompletedChecklists();

  return (
    <>
      <SearchBar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        viewType={viewType}
        setViewType={setViewType}
      />

      <div style={{ marginBottom: 20 }}>
        <PageHeader data={PAGE_HEADER} />
      </div>

      {loading && <TableLoader />}

      {!loading && viewType === "table" && (
        <Table
          data={checklists}
          handleLoad={handleNextPage}
          loading={paginationLoading}
          header={[
            { header: "Name", className: styles.completedChecklists__name },
            {
              header: "Date started",
              className: styles.completedChecklists__date,
            },
            { header: "Status", className: styles.completedChecklists__status },
          ]}
          renderTableItem={(item, index) => (
            <ProgressItem
              handleContinue={() => handleContinue(item.checklist.id, item.id)}
              activeChecklist={item}
              key={`${item} - ${index}`}
              index={index}
              deleteProgress={handleOpenDeleteModal}
            />
          )}
        />
      )}

      {!loading && viewType === "grid" && (
        <>
          <CompletedGridWrapper
            checklists={checklists}
            handleContinue={handleContinue}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleLoad={handleNextPage}
            loading={paginationLoading}
          />
          {/* <div className={styles.completedChecklists__gridWrapper}>
            <div className={styles.completedChecklists__grid}>
              {checklists.map((item, index) => (
                <ProgressItemSquare
                  handleContinue={() =>
                    handleContinue(item.checklist.id, item.id)
                  }
                  activeChecklist={item}
                  key={`${item} - ${index}`}
                  index={index}
                  deleteProgress={handleOpenDeleteModal}
                />
              ))}
            </div>
          </div> */}
        </>
      )}

      {paginationLoading && (
        <div className={styles.paginationLoader}>
          <Loader fill="#616A85" />
        </div>
      )}

      {/* <Pagination
        allResults={draftsCount}
        fetchedResult={fetchedCount + PAGE_LIMIT}
        limit={PAGE_LIMIT}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
      /> */}

      <div className={styles.pagination}>
        <div className={styles.pagination__left}>
          Showing{" "}
          <span>
            {fetchedCount + PAGE_LIMIT > draftsCount
              ? draftsCount
              : !!draftsCount && !!(fetchedCount + PAGE_LIMIT)
              ? fetchedCount + PAGE_LIMIT
              : 0}
          </span>{" "}
          of <span>{draftsCount}</span> Results
        </div>
      </div>

      <DeleteChecklistModal
        checklistDeletionId={checklistDeletionId}
        onCloseModal={handleCloseDeleteModal}
        handleDeleteChecklist={deleteProgress}
      />
    </>
  );
};
