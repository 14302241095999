import { GridView, TableView } from "~icons";
import { DashboardLayout } from "~layouts";
import { useCheckemDrafts } from "./useCheckemDrafts";
import {
  DraftItem,
  DraftItemSquare,
  FolderItem,
  FolderItemSquare,
  GridDraggable,
  PDFItem,
  PDFItemSquare,
  Pagination,
  SearchBar,
  SearchInput,
  TableDraggable,
  TableLoader,
} from "~components";

import styles from "./styles.module.scss";
import { PlusButton } from "./components/PlusButton";

export const CheckemDrafts = () => {
  const {
    drafts,
    loading,
    draftsCount,
    fetchedCount,
    searchValue,
    PAGE_LIMIT,
    viewType,
    folders,
    loadData,
    onDragEnd,
    showLoader,
    setViewType,
    setSearchValue,
    handleNextPage,
    handlePrevPage,
    updateDraftStatus,
    saveEnteredCode,
  } = useCheckemDrafts();

  console.log(drafts, 'items')
  return (
    <DashboardLayout
      title="Checkem Builder"
      rightItem={<PlusButton refreshData={loadData} />}
    >
      <div className={styles.drafts}>
        <SearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          viewType={viewType}
          setViewType={setViewType}
        />

        {loading && <TableLoader />}

        {!loading && viewType === "table" && (
          <TableDraggable
            header={[
              { header: "Name", className: styles.drafts__name },
              {
                header: "Last modified",
                className: styles.drafts__date,
              },
              { header: "Action", className: styles.drafts__action },
              { header: "Type", className: styles.drafts__type },
              { header: "Status", className: styles.drafts__status },
            ]}
            // draggableTypes={["Pdf"]}
            folders={folders}
            data={drafts}
            onDragEnd={onDragEnd}
            renderFolderItem={(folder, index, isDraggingOver) => {
              return (
                <FolderItem
                  key={`${folder.id} - ${index} - folder`}
                  folder={folder}
                  index={index}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                  isDraggingOver={isDraggingOver}
                />
              );
            }}
            renderTableItem={(item, index) => {
              return item.type === "Pdf" ? (
                <PDFItem
                  pdf={item}
                  key={`${item.id} - ${index} - item`}
                  index={index}
                  updatePDFStatus={updateDraftStatus}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              ) : (
                <DraftItem
                  draft={item}
                  index={index}
                  isAdminDraft={true}
                  key={`${item.id} - ${index} - item`}
                  updateDraftStatus={updateDraftStatus}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              );
            }}
          />
        )}

        {!loading && viewType === "grid" && (
          <GridDraggable
            data={drafts}
            folders={folders}
            draggableTypes={["Pdf"]}
            onDragEnd={onDragEnd}
            renderItem={(item, index) => {
              return item.type === "Pdf" ? (
                <PDFItemSquare
                  pdf={item}
                  key={`${item.id} - ${index} - item`}
                  index={index}
                  updatePDFStatus={updateDraftStatus}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              ) : (
                <DraftItemSquare
                  draft={item}
                  key={`${item.id} - ${index} - item`}
                  index={index}
                  updateDraftStatus={updateDraftStatus}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                />
              );
            }}
            renderFolderItem={(folder, index, isDraggingOver) => {
              return (
                <FolderItemSquare
                  key={`${folder.id} - ${index} - folder`}
                  folder={folder}
                  index={index}
                  showTableLoader={showLoader}
                  updateTableData={loadData}
                  isDraggingOver={isDraggingOver}
                />
              );
            }}
          />
        )}

        <Pagination
          allResults={draftsCount}
          fetchedResult={fetchedCount + PAGE_LIMIT}
          limit={PAGE_LIMIT}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
        />
      </div>
    </DashboardLayout>
  );
};
