import { RefObject } from "react";

import { useEventListener } from "usehooks-ts";

type Handler = (event: MouseEvent) => void;

export const useOutsideClick = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  mouseEvent: "mousedown" | "mouseup" = "mousedown",
  ref2?: RefObject<T>
): void => {
  useEventListener(mouseEvent, (event) => {
    const el = ref?.current;

    if (!!ref2) {
      const el2 = ref2?.current;
      if (
        !el ||
        el.contains(event.target as Node) ||
        el2?.contains(event.target as Node)
      )
        return;
    } else if (!el || el.contains(event.target as Node)) return;

    handler(event);
  });
};
