import Popup from "reactjs-popup";
import { Button, NumberWidget, SwitchField } from "~components";
import {
  CheckSquareBroken,
  ListOfChecklists,
  MessageIcon,
  PlusSymbol,
  Trash,
} from "~icons";

import styles from "./styles.module.scss";
import { useActiveListsModule } from "./useActiveListsModule";
import { useTranslation } from "react-i18next";
import { IActiveChecklist, IProgress } from "~models";
import clsx from "clsx";

const ns = "leftPanel";

export const ActiveListsModule = ({ noLabel }: { noLabel?: boolean }) => {
  const { t } = useTranslation(["leftPanel"]);

  const {
    proggress,
    popupRef,
    popupContentRef,
    popupTriggerRef,
    isDark,
    togglePopup,
    handleItemClick,
    handleCreateButtonClick,
    countDublicates,
    handleDeleteProgressButton,
    handleCreateChatAI,
    setIsDark
  } = useActiveListsModule();

  return (
    <div
      ref={popupTriggerRef}
      className={
        noLabel
          ? clsx(styles.activeLists, styles.activeListsShadow)
          : styles.activeLists
      }
      onClick={togglePopup}
    >
      <Popup
        ref={popupRef}
        arrow={false}
        lockScroll={true}
        closeOnDocumentClick={false}
        position={noLabel ? "bottom right" : "top left"}
        trigger={<div style={{ position: "absolute", left: 0, top: 0 }} />}
      >
        <div ref={popupContentRef} className={styles.popup}>
          {proggress.length === 0 ? (
            <div className={styles.activeLists__noData}>
              No Active In Progress
            </div>
          ) : (
            countDublicates(proggress).map(
              (item: IActiveChecklist, index: number) => (
                <div
                  key={`${index} - active list`}
                  onClick={() => handleItemClick(item.checklistId, item.id)}
                >
                  <div className={styles.popup__item}>
                    <CheckSquareBroken stroke="#29B473" />
                    <p>{item.checklist.title}</p>
                    <div
                      style={{ marginLeft: "auto", marginRight: 5 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteProgressButton(item.id);
                      }}
                    >
                      <Trash stroke="#979DB1" />
                    </div>
                  </div>
                  <div className={styles.popup__itemUnderline} />
                </div>
              )
            )
          )}
          <div>
            <div className={styles.popup__item} onClick={handleCreateChatAI}>
              <MessageIcon />
              <p>Checkem AI</p>
            </div>
            <div className={styles.popup__item}>
              <SwitchField
                containerClassName={styles.popup__darkMode}
                text="Dark theme"
                value={isDark}
                onClick={() => setIsDark(!isDark)}
              />
            </div>
            <div className={styles.popup__itemUnderline} />
          </div>
          <div className={styles.popup__button}>
            <Button
              onClick={handleCreateButtonClick}
              text="Start new"
              icon={<PlusSymbol fill="#FFFFFF" />}
              backgroundType="green"
            />
          </div>
        </div>
      </Popup>
      <ListOfChecklists />
      {!noLabel && t("Action bar", { ns })}
      {/* {proggress.length > 0 ? "Action bar" : "No Active In Progress"} */}

      <div className={styles.activeLists__counter}>
        <NumberWidget backgroundType={"blue"} text={proggress.length} />
      </div>
    </div>
  );
};
