import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { INotepad } from "~models";
import {
  GENERATE_NOTEPAD_RESPONSE,
  REWRITE_NOTEPAD,
  UNDO_REWRITE_NOTEPAD,
  UPDATE_NOTEPAD,
} from "~mutations";

interface IProps {
  notepad: INotepad | null;
  setNotepad: React.Dispatch<React.SetStateAction<INotepad | null>>;
}

export const useNotepad = ({ notepad, setNotepad }: IProps) => {
  const params = useParams();
  let id = !!params?.id ? +params.id : -1;

  const [generateNotepad] = useMutation(GENERATE_NOTEPAD_RESPONSE);
  const [updateNotepad] = useMutation(UPDATE_NOTEPAD);
  const [rewriteNotepad] = useMutation(REWRITE_NOTEPAD);
  const [undoRewriteNotepad] = useMutation(UNDO_REWRITE_NOTEPAD);

  const handleChangeNotepadText = (value: string) => {
    if (value.length === 0) {
      setNotepad((prev) => {
        let state = structuredClone(prev);
        if (state) {
          state.assessments = [];
          state.differentials = [];
          state.treatments = [];
          state.diagnosis = "";
          state.rewrited = "";
          state.data = "";
        }
        return state;
      });
      updateNotepad({
        variables: { input: { chatId: id, data: "" } },
      });
      return;
    }

    setNotepad((prev) => {
      let state = structuredClone(prev);
      if (state) {
        state.data = value;
        state.rewrited = "";
      }

      return state;
    });
  };

  const [showRewriteButtonLoader, setShowRewriteButtonLoader] = useState(false);
  const [showUndoRewriteButtonLoader, setShowUndoRewriteButtonLoader] =
    useState(false);

  const handleRewriteButton = () => {
    setShowRewriteButtonLoader(true);
    rewriteNotepad({
      variables: { input: { chatId: id, data: notepad?.data } },
    }).then((resp) => {
      setNotepad(resp.data.rewriteNotepad);
      setShowRewriteButtonLoader(false);
    });
  };

  const handleUndoRewriteButton = () => {
    setShowUndoRewriteButtonLoader(true);
    undoRewriteNotepad({ variables: { chatId: id } }).then((resp) => {
      setNotepad(resp.data.undoRewriteNotepad);
      setShowUndoRewriteButtonLoader(false);
    });
  };

  const handleUpdateNotepad = () => {
    if (!notepad?.rewrited) {
      updateNotepad({
        variables: { input: { chatId: id, data: notepad?.data } },
      });
    }
  };

  return {
    handleChangeNotepadText,
    showRewriteButtonLoader,
    showUndoRewriteButtonLoader,
    handleRewriteButton,
    handleUndoRewriteButton,
    handleUpdateNotepad,
  };
};
