import clsx from "clsx";
import { FC } from "react";
import { LeftArrow } from "~icons";
import styles from "./styles.module.scss";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";

interface IProps {
  allResults?: number;
  fetchedResult?: number;
  loading?: boolean;
  limit?: number;
  dataLength?: number;
  handleNextPage?: () => void;
  handlePrevPage?: () => void;
}

export const Pagination: FC<IProps> = ({
  allResults = 0,
  fetchedResult = 0,
  loading,
  limit = 10,
  dataLength = 0,
  handleNextPage,
  handlePrevPage,
}) => {
  const isDisable = loading;

  const from = !!allResults
    ? fetchedResult - limit > 0
      ? fetchedResult - limit + 1
      : 1
    : 0;

  const to =
    fetchedResult > allResults
      ? allResults
      : !!allResults && !!fetchedResult
      ? fetchedResult
      : dataLength;

  const isDark = useReactiveVar(darkTheme)

  return (
    <div className={styles.pagination}>
      <div className={styles.pagination__left}>
        Showing <span>{from}</span> to <span>{to}</span> of{" "}
        <span>{allResults}</span> Results
      </div>
      <div className={styles.pagination__right}>
        <div
          onClick={handlePrevPage}
          className={clsx(styles.pagination__rightButton, {
            [styles.disable]: isDisable,
          })}
        >
          <LeftArrow fill={isDisable ? "#DBDEE7" : isDark ? "#DFE9F2" :  "#0B0F1C"} />
        </div>

        <div
          onClick={handleNextPage}
          className={clsx(styles.pagination__rightButton, {
            [styles.disable]: isDisable,
          })}
        >
          <div className={styles.rotate}>
            <LeftArrow fill={isDisable ? "#DBDEE7" : isDark ? "#DFE9F2" :  "#0B0F1C"} />
          </div>
        </div>
      </div>
    </div>
  );
};
