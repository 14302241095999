import { StrictModifierNames, Modifier } from "react-popper";
import { Options, createPopper } from "@popperjs/core";
import { PublishingStatus, IDraft } from "~models";
import { Button, ColorPicker, PopperPopup } from "~components";
import { DeleteModal, MoveModal, ShareCodeModal } from "../../modals";
import { useDraft } from "../../useDraft";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  CheckSquareBroken,
  CopyTo,
  DotsVertical,
  Duplicate,
  FileDownload,
  FileNone,
  Pallete,
  Pen,
  Refresh,
  RightArrow,
  Share,
  TrashAlt,
  Undo,
} from "~icons";

import styles from "./styles.module.scss";

interface IProps {
  draft: IDraft;
  index: number;
  isAdminDraft?: boolean;
  updateDraftStatus: (status: PublishingStatus, index: number) => void;
  showTableLoader?: () => void;
  updateTableData?: () => void;
}

export const DraftItem = ({
  draft,
  index,
  isAdminDraft,
  updateDraftStatus,
  showTableLoader,
  updateTableData,
}: IProps) => {
  const {
    myRole,
    modalRef,
    showPopper,
    showDeletionModal,
    showShareCodeModal,
    showMoveModal,
    handleDelete,
    handlePublish,
    handleEdit,
    handleDownloadPdf,
    handleUnpublish,
    handleOpenPopper,
    handleClosePopper,
    handleDuplicate,
    handleOpenDeletionModal,
    handleCloseDeletionModal,
    handleOpenShareModal,
    handleCloseShareModal,
    handleMoveOut,
    handleCloseMoveModal,
    handleOpenMoveModal,
  } = useDraft({
    draft,
    index,
    isAdminDraft,
    updateDraftStatus,
    showTableLoader,
    updateTableData,
  });

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  return (
    <div>
      <div className={styles.folderItem}>
        <div className={styles.folderItem__horizontal}>
          <div className={styles.folderItem__horizontalName}>
            <CheckSquareBroken stroke="#29B473" />
            {draft.title}
          </div>
          <div className={styles.folderItem__horizontalDate}>
            {dayjs(new Date()).format("MMMM D, YYYY")}
          </div>

          <div className={styles.folderItem__horizontalAction}>
            <Button backgroundType={"green"} text="Edit" onClick={handleEdit} />
          </div>

          <div className={styles.folderItem__horizontalType}>
            <div className={styles.folderItem__horizontalTypeDraft}>
              Checkem
            </div>
          </div>

          <div className={styles.folderItem__horizontalStatus}>
            <div
              className={clsx(styles.folderItem__horizontalStatusLeft, {
                [styles.yellow]:
                  draft.status === PublishingStatus.UnpublishedChanges,
                [styles.red]: draft.status === PublishingStatus.Unpublished,
                [styles.green]: draft.status === PublishingStatus.Published,
              })}
            >
              <div
                className={clsx(styles.folderItem__horizontalStatusCircle, {
                  [styles.yellow]:
                    draft.status === PublishingStatus.UnpublishedChanges,
                  [styles.red]: draft.status === PublishingStatus.Unpublished,
                  [styles.green]: draft.status === PublishingStatus.Published,
                })}
              ></div>

              <p>
                {draft.status === PublishingStatus.UnpublishedChanges
                  ? "Unpublished Changes"
                  : draft.status}
              </p>

              {draft.status !== PublishingStatus.Published && (
                <div
                  onClick={handlePublish}
                  className={styles.folderItem__horizontalStatusRefresh}
                >
                  <Refresh fill="#29B473" />
                </div>
              )}
            </div>
            <div className={styles.folderItem__horizontalStatusRight}>
              <PopperPopup
                showPopper={showPopper}
                onClosePopper={handleClosePopper}
                onOpenPopper={handleOpenPopper}
                options={popperOptions}
                trigger={<DotsVertical fill="#0B0F1C" />}
                popup={
                  <div
                    ref={modalRef}
                    className={clsx(styles.folderItem__modal, {})}
                  >
                    <div
                      className={styles.folderItem__modalItem}
                      onClick={handleEdit}
                    >
                      <Pen fill="#0B0F1C" /> Edit
                    </div>

                    <div
                      className={clsx(
                        styles.folderItem__modalItem,
                        styles.margin
                      )}
                      onClick={handleDuplicate}
                    >
                      <Duplicate /> Duplicate
                    </div>
                    <div
                      className={styles.folderItem__modalItem}
                      onClick={handleOpenMoveModal}
                    >
                      <CopyTo /> Move to
                    </div>
                    <ColorPicker
                      // showOnlyCircle
                      text={
                        <div
                          className={styles.folderItem__modalItem}
                          // onClick={handleOpenMoveModal}
                        >
                          <Pallete /> Change color{" "}
                          <RightArrow style={{ marginLeft: "auto" }} />
                        </div>
                      }
                      color="red"
                      onChoose={() => console.log("asd")}
                    />

                    {/* {!!draft?.folderId && (
                      <div
                        onClick={handleMoveOut}
                        className={styles.folderItem__modalItem}
                      >
                        <Undo fill={"#0B0F1C"} /> Move out of folder
                      </div>
                    )} */}

                    <div className={styles.folderItem__modalUnderline} />
                    {draft.status !== PublishingStatus.Published && (
                      <div
                        onClick={handlePublish}
                        className={styles.folderItem__modalItem}
                      >
                        <Refresh width={20} height={20} fill="#0B0F1C" />{" "}
                        {draft.status === PublishingStatus.Unpublished
                          ? "Publish"
                          : "Publish latest changes"}
                      </div>
                    )}

                    {draft.status === PublishingStatus.Published && (
                      <div
                        onClick={handleUnpublish}
                        className={styles.folderItem__modalItem}
                      >
                        <FileNone /> Unpublish
                      </div>
                    )}

                    <div className={styles.folderItem__modalUnderline} />

                    <div
                      onClick={handleOpenShareModal}
                      className={styles.folderItem__modalItem}
                    >
                      <Share /> Share Checkem
                    </div>
                    {draft.type === "Draft" && (
                      <div
                        className={styles.folderItem__modalItem}
                        onClick={handleDownloadPdf}
                      >
                        <FileDownload /> Generate PDF
                      </div>
                    )}

                    {myRole !== "User" && (
                      <div
                        onClick={handleOpenDeletionModal}
                        className={styles.folderItem__modalItem}
                      >
                        <TrashAlt fill="#0B0F1C" /> Delete
                      </div>
                    )}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.folderItem__underline} />

      <DeleteModal
        isOpen={showDeletionModal}
        itemName="draft"
        onCloseModal={handleCloseDeletionModal}
        handleDelete={handleDelete}
      />

      <ShareCodeModal
        isOpen={showShareCodeModal}
        id={draft.id}
        type="Draft"
        onCloseModal={handleCloseShareModal}
      />

      <MoveModal
        isOpen={showMoveModal}
        onCloseModal={handleCloseMoveModal}
        itemToMove={draft}
        updateTableData={updateTableData}
      />
    </div>
  );
};
