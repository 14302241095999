import clsx from "clsx";
import styles from "./styles.module.scss";

import { useAlgorithm } from "./useAlgorithm";
import { IItem } from "~models";
import { Button } from "~components";
import FroalaEditor from "react-froala-wysiwyg";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

interface IAssesment {
  title: string;
  description: string;
  findings: { name: string; score: number }[];
}

const value = "<p><strong>asdfasdfasdf</strong></p>";

export const Algorithm = ({ item, isDisable, progressItem }: IProps) => {
  const { disableCheckButton, handleSelectFinding, handleCheckButton } =
    useAlgorithm({
      item,
      isDisable,
      progressItem,
    });
  return (
    <div className={styles.algorithm}>
      {item.data.assessments.map(
        (assesment: IAssesment, assesmentIndex: number) => {
          let choosedAssesmentIndex = !!progressItem?.data
            ? progressItem.data.findIndex(
                (item: { assesmentIndex: number }) =>
                  item.assesmentIndex === assesmentIndex
              )
            : -1;

          let isAssesmentChoosed = choosedAssesmentIndex > -1;

          return (
            <div key={`assesment - ${assesmentIndex}`}>
              <div className={styles.algorithm__underline} />
              <p className={styles.algorithm__title}>{assesment.title}</p>
              {/* <p className={styles.algorithm__description}>
                {assesment.description}
              </p> */}
              {assesment.description ? (
                <FroalaEditor
                  model={assesment.description
                    ?.replace(/^(<br>)+|(<br>)+$/g, "")
                    .replace(
                      /<p>\s*<br>\s*<\/p>|<p>\s*<\/p>|<p><strong><em><\/em><\/strong><br><\/p>|<p><strong><em><br><\/em><\/strong><\/p>/g,
                      ""
                    )}
                  config={{
                    key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                    htmlUntouched: true,
                    toolbarInline: true,
                    toolbarVisibleWithoutSelection: true,
                    isDisable: true,
                    events: {
                      initialized() {
                        const editor: any = this;
                        editor.edit.off();
                      },
                    },
                  }}
                />
              ) : (
                <p className={styles.algorithm__description}>
                  {assesment.description}
                </p>
              )}

              {assesment.findings.map((finding, findingIndex) => {
                let isFindingChoosed =
                  isAssesmentChoosed && !!progressItem?.data
                    ? progressItem.data[choosedAssesmentIndex].findingIndex ===
                      findingIndex
                    : false;
                return (
                  <div
                    key={`finding - ${findingIndex}`}
                    onClick={(e) =>
                      handleSelectFinding(
                        e,
                        assesmentIndex,
                        findingIndex,
                        finding.score
                      )
                    }
                    className={clsx(styles.algorithm__selection, {
                      [styles.active]: isFindingChoosed,
                      [styles.disable]: isDisable && !isFindingChoosed,
                      [styles.defaultCursor]: isDisable && isFindingChoosed,
                    })}
                  >
                    {finding.name}
                  </div>
                );
              })}
            </div>
          );
        }
      )}

      <div className={styles.algorithm__checkButton}>
        <Button
          onClick={handleCheckButton}
          disable={disableCheckButton || isDisable}
          backgroundType={"green"}
          text="Results"
        />
      </div>

      {!!progressItem?.result && (
        <p className={styles.algorithm__result}>
          <span>Result:</span> score={progressItem?.result.score}.{" "}
          {progressItem?.result.result}
        </p>
      )}
    </div>
  );
};
