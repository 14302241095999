import { CodeConfirmationModal } from "./modals/CodeConfirmationModal";
import {
  Button,
  Dropdown,
  ImagePicker,
  Input,
  PasswordRules,
  SwitchField,
} from "~components";
import { AppleLogo, Camera, GoogleLogo, LeftArrow } from "~icons";
import { DashboardLayout } from "~layouts";
import { DeleteUserModal } from "~modals";
import { uploadFile } from "~services";
import {
  useDeleteAccount,
  useEditEmail,
  useEditGeneral,
  useEditPassword,
  useEditLanguage,
  useEditProfile,
} from "./hooks";
import clsx from "clsx";

import styles from "./styles.module.scss";

export const EditProfile = () => {
  const {
    showMainLoader,
    user,
    isMyProfile,
    isDark,
    setIsDark,
    setUser,
    handleBackClick,
  } = useEditProfile();

  const {
    firstName,
    lastName,
    setFirstName,
    setFile,
    setLastName,
    file,
    avatarUri,
    isDisableCredentialButtons,
    handleCancelCredential,
    loadingCredential,
    saveCredentialChanges,
  } = useEditGeneral({ editableUser: user, setUser });

  const {
    email,
    isDisable,
    checkEmailLoader,
    changeEmailError,
    authType,
    showConfirmationModal,
    onChangeEmail,
    handleSaveEmail,
    onCancelEmailChange,
    handleCloseModal,
  } = useEditEmail({ editableUser: user, setUser });

  const {
    passwordData,
    onChangePasswordData,
    loadingPasswordBlock,
    isDisablePasswordSave,
    handeCancelPasswordChanges,
    savePassword,
    isDisablePasswordCancel,
    passwordError,
  } = useEditPassword();

  const {
    choosedLanguageValue,
    disableLangugeBlockButtons,
    handleChangeLanguage,
    handleSaveLanguage,
    handleCancelLanguage,
  } = useEditLanguage();

  const {
    showDeleteUserModal,
    handleOpenDeleteUserModal,
    handleCloseDeleteUserModal,
    handleDeleteOtherAccount,
  } = useDeleteAccount({ isMyProfile, editableUser: user });

  return (
    <DashboardLayout
      title={isMyProfile ? "My Account" : ""}
      customLeftComponent={
        isMyProfile || showMainLoader ? null : (
          <div className={styles.editProfile__dashboardHeader}>
            <div
              onClick={handleBackClick}
              className={styles.editProfile__dashboardHeaderArrow}
            >
              <LeftArrow fill="#0B0F1C" />
            </div>
            <div>
              <p className={styles.editProfile__dashboardHeaderName}>
                {user?.firstName} {user?.lastName}
              </p>
              <p className={styles.editProfile__dashboardHeaderTitle}>
                User Profile
              </p>
            </div>
          </div>
        )
      }
      contentClassName={styles.editProfile__content}
    >
      <div className={styles.editProfile}>
        {showMainLoader ? (
          <></>
        ) : (
          <>
            {" "}
            <p
              onClick={() => uploadFile(file, file?.type)}
              className={styles.editProfile__title}
            >
              General
            </p>
            <ImagePicker
              cropShape="round"
              containerClassName={styles.editProfile__picker}
              saveImage={setFile}
            >
              <div
                className={clsx(styles.editProfile__pickerAvatar, {
                  [styles.exist]: !!avatarUri,
                })}
              >
                {!!avatarUri ? <img src={avatarUri} /> : <Camera />}
              </div>

              <p>Change picture</p>
            </ImagePicker>
            <div className={styles.editProfile__input}>
              <Input
                value={firstName}
                onChange={setFirstName}
                label="First Name"
                placeholder="Enter your first name..."
              />
            </div>
            <div className={styles.editProfile__input}>
              <Input
                value={lastName}
                onChange={setLastName}
                label="Last Name"
                placeholder="Enter your last name..."
              />
            </div>
            <div className={styles.editProfile__buttons}>
              <Button
                loading={loadingCredential}
                onClick={saveCredentialChanges}
                disable={isDisableCredentialButtons}
                backgroundType={"green"}
                text={"Save"}
              />
              <Button
                onClick={handleCancelCredential}
                disable={isDisableCredentialButtons}
                backgroundType={"white"}
                showShadow
                text={"Cancel"}
              />
            </div>
            {isMyProfile && (
              <>
                <div className={styles.editProfile__underline} />
                <p className={styles.editProfile__title}>Contact Info</p>
                <p className={styles.editProfile__subtitle}>
                  Access your Companies with any email address, or by connecting
                  an account.
                </p>
                <div className={styles.editProfile__accounts}>
                  <div className={styles.editProfile__accountsInput}>
                    <Input
                      disable={authType !== "Email"}
                      label="Email"
                      value={email}
                      onChange={onChangeEmail}
                      placeholder="Enter your email..."
                      error={changeEmailError}
                    />
                  </div>
                  {authType !== "Email" && (
                    <div className={styles.editProfile__accountsButtons}>
                      <p className={styles.editProfile__accountsTitle}>
                        Connected Accounts
                      </p>
                      {authType === "Google" && (
                        <div
                          className={clsx(styles.editProfile__accountsButton, {
                            [styles.active]: true,
                          })}
                        >
                          <div
                            className={
                              styles.editProfile__accountsButtonContent
                            }
                          >
                            <div
                              className={styles.editProfile__accountsButtonLogo}
                            >
                              <GoogleLogo />
                            </div>
                            Google is connected
                          </div>
                        </div>
                      )}

                      {authType === "Apple" && (
                        <div
                          className={clsx(styles.editProfile__accountsButton, {
                            [styles.active]: false,
                          })}
                        >
                          <div
                            className={
                              styles.editProfile__accountsButtonContent
                            }
                          >
                            <div
                              className={styles.editProfile__accountsButtonLogo}
                            >
                              <AppleLogo />
                            </div>
                            Connect Apple
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {authType === "Email" && (
                  <div className={styles.editProfile__buttons}>
                    <Button
                      onClick={handleSaveEmail}
                      loading={checkEmailLoader}
                      disable={isDisable}
                      backgroundType={"green"}
                      text={"Update"}
                    />
                    <Button
                      onClick={onCancelEmailChange}
                      disable={isDisable}
                      backgroundType={"white"}
                      showShadow
                      text={"Cancel"}
                    />
                  </div>
                )}
                {authType === "Email" && (
                  <>
                    <div className={styles.editProfile__underline} />
                    <p className={styles.editProfile__title}>Password</p>
                    <p className={styles.editProfile__subtitle}>
                      Note that if you signed in with a connected account, you
                      are using that account’s login information and we cannot
                      change or reset those passwords here.
                    </p>
                    <div className={styles.editProfile__input}>
                      <Input
                        type={"secure"}
                        value={passwordData.currentPassword}
                        onChange={(value) =>
                          onChangePasswordData(value, "currentPassword")
                        }
                        label="Current Password"
                        placeholder="Enter your current password..."
                        error={passwordError?.oldPassword}
                      />
                    </div>
                    <div className={styles.editProfile__input}>
                      <Input
                        type={"secure"}
                        value={passwordData.password}
                        onChange={(value) =>
                          onChangePasswordData(value, "password")
                        }
                        label="New Password"
                        placeholder="Enter your new password..."
                        error={!!passwordError?.password ? " " : ""}
                      />

                      <PasswordRules password={passwordData.password} />
                    </div>

                    <div className={styles.editProfile__input}>
                      <Input
                        type={"secure"}
                        value={passwordData.confirmPassword}
                        onChange={(value) =>
                          onChangePasswordData(value, "confirmPassword")
                        }
                        label="Confirm Password"
                        placeholder="Confirm your password..."
                        error={passwordError?.confirmPassword}
                      />
                    </div>
                    <div className={styles.editProfile__buttons}>
                      <Button
                        loading={loadingPasswordBlock}
                        onClick={savePassword}
                        disable={isDisablePasswordSave}
                        backgroundType={"green"}
                        text={"Save"}
                      />
                      <Button
                        disable={isDisablePasswordCancel}
                        onClick={handeCancelPasswordChanges}
                        backgroundType={"white"}
                        showShadow
                        text={"Cancel"}
                      />
                    </div>
                  </>
                )}
                <div className={styles.editProfile__underline} />
                <p className={styles.editProfile__title}>Dark Mode</p>
                <SwitchField
                  text="Dark theme"
                  value={isDark}
                  onClick={() => setIsDark(!isDark)}
                />
                <div className={styles.editProfile__underline} />
                <p className={styles.editProfile__title}>Language</p>
                <p className={styles.editProfile__subtitle}>
                  Update your language
                </p>
                <div className={styles.editProfile__input}>
                  <Dropdown
                    data={["English (US)", "Ukraine (UK)"]}
                    onChange={(index) => handleChangeLanguage(index)}
                    value={choosedLanguageValue}
                    placeholder={"Choose language"}
                  />
                </div>
                <div className={styles.editProfile__buttons}>
                  <Button
                    onClick={handleSaveLanguage}
                    disable={disableLangugeBlockButtons}
                    backgroundType={"green"}
                    text={"Save"}
                  />
                  <Button
                    onClick={handleCancelLanguage}
                    disable={disableLangugeBlockButtons}
                    backgroundType={"white"}
                    showShadow
                    text={"Cancel"}
                  />
                </div>
              </>
            )}
            <div className={styles.editProfile__underline} />
            <p className={styles.editProfile__title}>Delete Account</p>
            <p className={styles.editProfile__subtitle}>
              Note if you delete your account we cannot undo this action
            </p>
            <div className={styles.editProfile__deleteButton}>
              <Button
                onClick={handleOpenDeleteUserModal}
                text="Delete Account"
                backgroundColor="#EF2F32"
                textColor="#FFFFFF"
              />
            </div>
            {showDeleteUserModal && (
              <DeleteUserModal
                userId={user?.id}
                onCloseModal={handleCloseDeleteUserModal}
                handleSuccessResult={handleDeleteOtherAccount}
              />
            )}
            {showConfirmationModal && (
              <CodeConfirmationModal
                handleCloseModal={handleCloseModal}
                email={email}
              />
            )}
          </>
        )}
      </div>
    </DashboardLayout>
  );
};
