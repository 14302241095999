import styles from "./styles.module.scss";
import Popup from "reactjs-popup";
import clsx from "clsx";
import { DownArrow, PlusSymbol } from "~icons";
import { ChromePicker } from "react-color";
import { useColorPicker } from "./useColorPicker";
import { ReactNode } from "react";

interface IProps {
  color: string;
  onChoose: (color: string) => void;
  text: string | ReactNode;
  showOnlyCircle?: boolean;
  showCircle?: boolean;
}

const defaultColors = [
  "#DBDEE7",
  "#EF2F32",
  "#29B473",
  "#3A6CFF",
  "#FDBB12",
  "#979DB1",
  "#FF00E5",
  "#AC5641",
  "#0082AB",
  "#7B07A4",
  "#616A85",
  "#C31AFF",
  "#E9F64D",
  "#01FFFF",
  "#900000",
  "#0B0F1C",
  "#00C2FF",
  "#3A6CFF",
  "#AFF68D",
  "#614AD3",
];

export const ColorPicker = ({
  text,
  color = "#29B473",
  showOnlyCircle,
  showCircle,
  onChoose,
}: IProps) => {
  const {
    handleOpenPopup,
    popupRef,
    modalRef,
    showPicker,
    choosedColor,
    handleChange,
    handleChangeComplete,
    setShowPicker,
    handleSaveColor,
  } = useColorPicker({ onChoose });

  return (
    <div
      className={
        showOnlyCircle || !showCircle ? styles.colorPicker__relativeBlock : styles.colorPicker
      }
      onClick={!showCircle ? handleOpenPopup : () => {}}
    >
      {!showOnlyCircle && <> {text}</>}

      {showCircle && (
        <div
          onClick={handleOpenPopup}
          className={styles.colorPicker__button}
          style={{ backgroundColor: color }}
        />
      )}

      <Popup
        ref={popupRef}
        arrow={false}
        lockScroll={true}
        closeOnDocumentClick={false}
        position={"bottom right"}
        trigger={<div style={{ position: "absolute", bottom: 0, right: 0 }} />}
      >
        <div ref={modalRef} className={styles.pickerModal}>
          <div className={styles.pickerModal__top}>
            {!showPicker ? (
              <>
                {defaultColors.map((item, index) => (
                  <div
                    key={`${item} - ${index}`}
                    onClick={() => handleSaveColor(item)}
                    className={clsx(styles.pickerModal__topButton, {
                      [styles.last]: (index + 1) % 4 === 0,
                    })}
                  >
                    <div style={{ backgroundColor: item }} />
                    {color === item && (
                      <div className={styles.pickerModal__topButtonBorder} />
                    )}
                  </div>
                ))}
              </>
            ) : (
              <ChromePicker
                styles={{
                  default: {
                    picker: {
                      boxShadow: "none",
                    },
                  },
                }}
                color={choosedColor}
                onChange={handleChange}
                onChangeComplete={handleChangeComplete}
              />
            )}
          </div>
          <div className={styles.pickerModal__underline} />
          <div className={styles.pickerModal__bottom}>
            {showPicker ? (
              <>
                <div
                  className={styles.pickerModal__bottomArrow}
                  onClick={() => setShowPicker(false)}
                >
                  <DownArrow fill="#979DB1" />
                </div>
                <div
                  onClick={() => {
                    handleSaveColor(choosedColor);
                  }}
                  className={styles.pickerModal__bottomButton}
                >
                  Save
                </div>
              </>
            ) : (
              <div
                className={styles.pickerModal__bottomArrow}
                onClick={() => setShowPicker(true)}
              >
                <PlusSymbol fill="#979DB1" />
              </div>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
};
