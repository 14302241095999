import { IItem } from "../../../../constants";
import { Input, SoundDropdown, SwitchField } from "~components";
import styles from "./styles.module.scss";
import { useCounter } from "./useCounter";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const CounterSettings = ({ item, itemIndex }: IProps) => {
  const { toggleAllowNegative, toggleRecordTime } = useCounter({
    item,
    itemIndex,
  });
  return (
    <div className={styles.counterSettings}>
      <div className={styles.counterSettings__field}>
        <SwitchField
          text="Allow negative numbers"
          value={item.data.allowNegative}
          onClick={toggleAllowNegative}
        />
      </div>
      <SwitchField
        text="Record time after each “+” click"
        value={item.data.record}
        onClick={toggleRecordTime}
      />
    </div>
  );
};
