import styles from "./styles.module.scss";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/lists.min.js";

import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/link.min.js";

import { useTextBox } from "./useTextBox";
import { IItem } from "~models";
import { Button, Input, Modal } from "~components";
import { Trash } from "~icons";
import { uploadFile } from "~services";

import "./froala.css";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const DropdownDataSettings = ({ item, itemIndex }: IProps) => {
  const {
    onModelChange,
    onTitleChange,
    onTemplateChange,
    handleAddButton,
    handleDeleteItem,
    handleChoosedItem,
    clearChoosedItem,
    choosedItemIndex,

    data,
  } = useTextBox({
    item,
    itemIndex,
  });

  return (
    <div className={styles.textboxSettings}>
      <div className={styles.textboxSettings__underline} />
      <p className={styles.textboxSettings__title}>Dropdown categories:</p>

      {data.tables.map(
        (item: { model: string; title: string }, index: number) => {
          return (
            <>
              <div
                key={`result - ${index}`}
                className={styles.textboxSettings__item}
              >
                <p className={styles.textboxSettings__subtitle}>
                  Category title
                </p>
                <div className={styles.textboxSettings__itemHeader}>
                  {/* <Input
                    placeholder="Add title"
                    value={item.title}
                    onChange={(value) => onTitleChange(value, index)}
                  /> */}
                  <FroalaEditor
                  model={item.title}
                  onModelChange={(value: string) => onTitleChange(value, index)}
                  config={{
                    key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                    htmlUntouched: true,
                    listAdvancedTypes: true,
                    listAdvancedPositions: true,
                    // toolbarInline: true,
                    // toolbarVisibleWithoutSelection: true,
                    imageUpload: false,
                    // imageDefaultAlign: "left",
                    // imageDefaultDisplay: "inline-block",
                    // Set max image size to 5MB.
                    imageAllowedTypes: [],
                  }}
                />
                  <div onClick={() => handleDeleteItem(index)}>
                    <Trash stroke="#616A85" />
                  </div>
                </div>

                <div className={styles.textboxSettings__subtitleHeader}>
                  <p className={styles.textboxSettings__subtitle}>
                    Category data
                  </p>
                  <Button
                    backgroundType={"green"}
                    text="Full screen"
                    onClick={() => handleChoosedItem(index)}
                    // onClick={toogleFroalaModal}
                  />
                </div>

                <FroalaEditor
                  model={item.model}
                  onModelChange={(value: string) => onModelChange(value, index)}
                  config={{
                    key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                    htmlUntouched: true,
                    listAdvancedTypes: true,
                    listAdvancedPositions: true,
                    // toolbarInline: true,
                    // toolbarVisibleWithoutSelection: true,
                    imageUpload: true,
                    // imageDefaultAlign: "left",
                    // imageDefaultDisplay: "inline-block",
                    // Set max image size to 5MB.
                    imageMaxSize: 5 * 1024 * 1024,
                    imageAllowedTypes: ["jpeg", "jpg", "png"],
                    events: {
                      "image.beforeUpload"(data: Blob[]) {
                        const editor: any = this;
                        uploadFile(data[0], data[0].type)
                          .then((resp) => {
                            editor.image.insert(
                              resp,
                              null,
                              null,
                              editor.image.get()
                            );
                          })
                          .catch((err) => {
                            console.log("UPLOAD IMAGE ERR", err);
                          });
                        return false;
                      },
                      "image.inserted"($img: any) {
                        const currentStyles = $img.attr("style"); // Отримуємо поточний список стилів
                        const newStyles =
                          currentStyles + ` height: ${$img[0].height}px;`; // Додаємо новий стиль height
                        $img.attr("style", newStyles);
                      },
                    },
                  }}
                />
              </div>
              <div className={styles.textboxSettings__underline} />
            </>
          );
        }
      )}

      <div className={styles.textboxSettings__addButton}>
        <Button
          onClick={handleAddButton}
          backgroundType="white"
          showShadow
          text="Add category"
        />
      </div>

      {choosedItemIndex !== null && (
        <Modal
          containerClassName={styles.textboxSettings__modalContainer}
          modalClassName={styles.textboxSettings__modal}
          onCloseModal={clearChoosedItem}
        >
          <div className={styles.textboxSettings__modalContent}>
            <FroalaEditor
              model={data.tables[choosedItemIndex].model}
              onModelChange={(value: string) =>
                onModelChange(value, choosedItemIndex)
              }
              config={{
                key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                imageUpload: true,
                imageMaxSize: 5 * 1024 * 1024,
                imageAllowedTypes: ["jpeg", "jpg", "png"],
                events: {
                  "image.beforeUpload"(data: Blob[]) {
                    const editor: any = this;
                    uploadFile(data[0], data[0].type)
                      .then((resp) => {
                        editor.image.insert(
                          resp,
                          null,
                          null,
                          editor.image.get()
                        );
                      })
                      .catch((err) => {
                        console.log("UPLOAD IMAGE ERR", err);
                      });
                    return false;
                  },
                  "image.inserted"($img: any) {
                    const currentStyles = $img.attr("style"); // Отримуємо поточний список стилів
                    const newStyles =
                      currentStyles + ` height: ${$img[0].height}px;`; // Додаємо новий стиль height
                    $img.attr("style", newStyles);
                  },
                },
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};
