import { IOrganization, IOrganizationRequest } from "~models";
import { GET_ORGANIZATIONS_PAGINATION_DATA } from "~queries";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useUpdateEffect } from "usehooks-ts";

const PAGE_LIMIT = 10;

export const useAllOrganization = () => {
  const [fetchedCount, setFetchedCount] = useState(0);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [allOrganizationsCount, setAllOrganizationsCount] = useState(0);

  const [searchValue, setSearchValue] = useState("");

  const [loading, setLoading] = useState(false);

  const [getData] = useLazyQuery(GET_ORGANIZATIONS_PAGINATION_DATA);

  const loadData = () => {
    setLoading(true);

    const countInput = {};
    const organizationInput = { skip: 0, take: PAGE_LIMIT };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(organizationInput, { search: searchValue });
    }

    getData({
      variables: { countInput, organizationInput },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      fetchedCount !== 0 && setFetchedCount(0);

      setAllOrganizationsCount(resp.data.organizationsCount);
      setOrganizations(resp.data.organizations);
      setLoading(false);
    });
  };

  const handleChangeRequestsField = (
    index: number,
    value: IOrganizationRequest[]
  ) => {
    setOrganizations((prev) => {
      let state = [...prev];
      state[index].requests = value;
      return state;
    });
  };

  const updateOrganization = (index: number, value: IOrganization) => {
    setOrganizations((prev) => {
      let state = [...prev];
      state[index] = value;
      return state;
    });
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(allOrganizationsCount);
    const skip = fetchedCount + PAGE_LIMIT;
    if (skip >= maxPage) return;
    setLoading(true);
    let take = PAGE_LIMIT;
    if (allOrganizationsCount - skip < 10) {
      take = allOrganizationsCount - skip;
    }
    const countInput = {};
    const organizationInput = { skip: skip, take: PAGE_LIMIT };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(organizationInput, { search: searchValue });
    }
    getData({
      variables: { countInput, organizationInput },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllOrganizationsCount(resp.data.organizationsCount);
      setOrganizations(resp.data.organizations);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  const handlePrevPage = () => {
    const skip = fetchedCount - PAGE_LIMIT;
    if (skip < 0) return;
    setLoading(true);
    const countInput = {};
    const organizationInput = { skip: skip, take: PAGE_LIMIT };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(organizationInput, { search: searchValue });
    }
    getData({
      variables: { countInput, organizationInput },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllOrganizationsCount(resp.data.organizationsCount);
      setOrganizations(resp.data.organizations);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  useEffect(() => {
    loadData();
  }, []);

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      loadData();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    organizations,
    loadingOrganization: false,
    organizationsCount: allOrganizationsCount,
    handleNextPage,
    handlePrevPage,
    fetchedCount,
    loading,
    searchValue,
    setSearchValue,
    handleChangeRequestsField,
    updateOrganization,
  };
};
