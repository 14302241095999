import { useBuilder } from "../../../../context";
import { IItem } from "../../../../constants";
import { regex } from "~constants";
interface IProps {
  item: IItem;
  itemIndex: number;
}

export const useMetronome = ({ item, itemIndex }: IProps) => {
  const { setList, activeTabIndex, activeItem } = useBuilder();

  const handleType = (value: string) => {
    if (!activeItem) return;
    let data = { ...item.data, type: value };

    setList((prev) => {
      let state = { ...prev };
      if (!activeItem) return state;
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  return {
    handleType,
  };
};
