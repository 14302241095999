import { ChecklistItemType, PublishingStatus, IDraft } from "~models";

export const defaultSection: ISection = {
  id: new Date().getTime(),
  name: null,
  description: "",
  collapsable: false,
  startCollapsable: true,
  checkbox: false,
  color: "#29B473",
  checklistTabId: 1,
  items: [],
  order: 1,
};

export const testChecklist: IDraft = {
  id: 1,
  title: "test",
  organizationId: 2,
  status: PublishingStatus.Unpublished,
  tabs: [
    {
      id: new Date().getTime(),
      name: "Tab 1",
      checklistId: 2,
      order: 1,
      sections: [{ ...defaultSection }],
    },
  ],
  pdfUrl: null,
  pdfName: null,
  type: "Draft",
};

export const defaultTab: ITab = {
  id: new Date().getTime(),
  name: "Tab 1",
  checklistId: 2,
  order: 1,
  sections: [
    {
      id: new Date().getTime(),
      name: null,
      description: "",
      collapsable: false,
      startCollapsable: true,
      checkbox: false,
      color: "#29B473",
      checklistTabId: 1,
      items: [],
      order: 1,
    },
  ],
};

export interface IError {
  itemIds: number[];
  sectionIds: number[];
  tabIds: number[];
}

export const defaultError = {
  itemIds: [],
  sectionIds: [],
  tabIds: [],
};

export interface ITab {
  id: number;
  name: string;
  checklistId: number;
  order: number;
  sections: ISection[];
}

export interface ISection {
  id: number;
  name: null | string;
  description: null | string;
  collapsable: boolean;
  startCollapsable: boolean;
  checkbox: boolean;
  color: string;
  checklistTabId: number;
  order: number;
  items: IItem[];
}

export interface IActiveItem {
  type: "section" | "item";
  id: number;
  sectionIndex: number;
  sectionId: number;
}

// export enum ChecklistItemType {
//   TextBox = "TextBox",
//   Timer = "Timer",
//   Metronome = "Metronome",
//   Stopwatch = "Stopwatch",
//   Calculator = "Calculator",
//   MedCheck = "MedCheck",
//   InputText = "InputText",
//   Counter = "Counter",
//   DropdownSelector = "DropdownSelector",
// }

export interface IItem {
  id: number;
  type: null | ChecklistItemType;
  data: null | any;
  tabSectionId: number;
  order: number;
  description: null | string;
  checkbox: boolean;
  collapsable: boolean;
  startCollapsable: boolean;
  __typename?: string;
}

export const defaultItem: IItem = {
  id: 1,
  type: null,
  data: null,
  tabSectionId: 1,
  order: 1,
  description: null,
  checkbox: false,
  collapsable: false,
  startCollapsable: false,
};

export interface IStopwatchData {
  text: string;
  sound: string;
  minutes: number;
  seconds: number;
  repeat: boolean;
  textToSpeech?: string;
  ringMinutes: number;
  ringSeconds: number;
}
