import { alertVar, choosedOrganizationVar } from "~localCache";
import { USE_SHARING_CODE, USE_SHARING_CODE_ADMIN } from "~mutations";
import { useMutation } from "@apollo/client";
import { useState } from "react";

interface IProps {
  isAdmin?: boolean;
  folderId?: number;
  handleSuccess: () => void;
  onCloseModal: () => void;
}

export const useImportCodeModal = ({
  isAdmin,
  folderId,
  handleSuccess,
  onCloseModal,
}: IProps) => {
  const [showLoader, setShowLoader] = useState(false);
  const choosedOrganization = choosedOrganizationVar();
  const [code, setCode] = useState("");

  const [importItem] = useMutation(USE_SHARING_CODE);
  const [importItemAdmin] = useMutation(USE_SHARING_CODE_ADMIN);

  const handleModalClose = () => {
    !!code && setCode("");
    showLoader && setShowLoader(false);
    onCloseModal();
  };

  const handleImport = () => {
    setShowLoader(true);
    const importFunc = isAdmin ? importItemAdmin : importItem;
    const input = { code };
    !isAdmin &&
      Object.assign(input, { organizationId: choosedOrganization?.id });
    !!folderId && Object.assign(input, { folderId });
    importFunc({
      variables: { input },
    })
      .then(() => {
        setShowLoader(false);
        alertVar({ text: "Successfully imported", type: "green" });
        handleSuccess();
        handleModalClose();
      })
      .catch(() => {
        alertVar({ text: "Invalid code", type: "red" });
        setShowLoader(false);
      });
  };

  const handleCodeChange = (value: string) => setCode(value);

  return { code, showLoader, handleModalClose, handleCodeChange, handleImport };
};
