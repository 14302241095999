import { useMutation } from "@apollo/client";
import { useState } from "react";
import { alertVar } from "~localCache";
import { IOrganization } from "~models";
import { CREATE_USER_OR_ADMIN } from "~mutations";

interface IProps {
  onClose: () => void;
  handleCreateUser: (isSuperAdmin: boolean) => void;
}

export const useCreateUserModal = ({ onClose, handleCreateUser }: IProps) => {
  const [createUser] = useMutation(CREATE_USER_OR_ADMIN);

  const [error, setError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const [showOrganizationPopup, setShowOrganizationPopup] = useState(false);
  const [choosedOrganization, setChoosedOrganization] =
    useState<IOrganization | null>(null);

  const [showCreatingLoader, setShowCreatingLoader] = useState(false);

  const handleFirstNameChange = (value: string) => {
    setFirstName(value);
  };
  const handleLastNameChange = (value: string) => {
    setLastName(value);
  };

  const updateChoosedOrganization = (value: IOrganization | null) =>
    setChoosedOrganization(value);

  const handleEmailChange = (value: string) => {
    !!error && setError("");
    setEmail(value);
  };
  const handlePasswordChange = (value: string) => setPassword(value);

  const toggleSuperAdminValue = () => setIsSuperAdmin(!isSuperAdmin);
  const toggleOrganizationValue = () =>
    setShowOrganizationPopup(!showOrganizationPopup);

  const disableCreateButton =
    !firstName || !lastName || !email || !password || !!error;

  const handleCreateButton = () => {
    setShowCreatingLoader(true);
    let input = {
      firstName,
      lastName,
      email,
      password,
      makeSuperAdmin: isSuperAdmin,
    };
    createUser({ variables: { input } })
      .then(() => {
        handleCreateUser(isSuperAdmin);
        alertVar({
          type: "green",
          text: "The user has been successfully created",
        });
        onClose();
      })
      .catch((err) => {
        setShowCreatingLoader(false);
        setError(err.graphQLErrors[0].message);
      });
  };

  return {
    email,
    password,
    isSuperAdmin,
    choosedOrganization,
    showOrganizationPopup,
    disableCreateButton,
    showCreatingLoader,
    error,
    firstName,
    lastName,
    handleEmailChange,
    handlePasswordChange,
    toggleOrganizationValue,
    toggleSuperAdminValue,
    updateChoosedOrganization,
    handleCreateButton,
    handleFirstNameChange,
    handleLastNameChange,
  };
};
