import { AddSection, Download, LeftArrow, TrashAlt } from "~icons";
import styles from "./styles.module.scss";
import { Footer, Section } from "./modules";
import { useListBuilder } from "./useListBuilder";
import { BuilderProvider } from "./context";
import { Button } from "~components";
import { Settings } from "./modules/Settings";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { ISection } from "./constants";
import ContentLoader from "react-content-loader";
import { DeleteDraftModal } from "./modals";
import { useDragAndDrop } from "./useDragAndDrop";
import { PdfTab } from "./modules/PdfTab";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";

export const WrappedListBuilder = () => {
  const {
    list,
    tabInputRef,
    draftLoading,
    showSavingLoader,
    activeTabIndex,
    tabLoading,
    containerRef,
    sectionLoading,
    pdfFormat,
    myRole,
    showDeleteModal,
    showDeletionLoader,
    showPdfTab,
    handleAddSection,
    saveListTitleOnServer,
    goBack,
    handleTabTitleChange,
    onChangeListTitle,
    handleDeleteDraft,
    handleCloseDeleteModal,
    handleOpenDeleteModal,
  } = useListBuilder();

  const { hideButton, onDragEnd, onDragStart } = useDragAndDrop();
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef?.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [activeTabIndex]);

  const isDark = useReactiveVar(darkTheme);

  return (
    <div className={styles.listBuilder}>
      <div
        className={
          isDark
            ? clsx(styles.listBuilder__left, styles.listBuilder__leftDark)
            : styles.listBuilder__left
        }
      >
        <div className={styles.listBuilder__header}>
          <div ref={containerRef} className={styles.listBuilder__headerLeft}>
            {pdfFormat && (
              <div
                className={styles.listBuilder__headerLeftArrow}
                onClick={goBack}
              >
                <LeftArrow fill="#0B0F1C" />
              </div>
            )}
          </div>

          {draftLoading ? (
            <ContentLoader
              speed={1}
              width={"100%"}
              height={40}
              backgroundColor="#DBDEE7"
              foregroundColor="#ebedf2"
            >
              <rect x="0" y="0" rx="7" ry="7" width="412" height="40" />
            </ContentLoader>
          ) : pdfFormat ? (
            <div
              className={clsx(styles.listBuilder__headerRight, {
                [styles.pdfFormat]: pdfFormat,
              })}
            >
              <input
                disabled
                onBlur={saveListTitleOnServer}
                value={list.title}
                onChange={onChangeListTitle}
                placeholder="Untitled"
              />

              <Button
                backgroundType="green"
                // showShadow

                text="Download PDF"
                onClick={() => window.print()}
                buttonClassName={styles.pdfButton}
              />
            </div>
          ) : (
            <div
              className={
                isDark
                  ? clsx(
                      styles.listBuilder__headerRight,
                      styles.listBuilder__headerRightDark
                    )
                  : styles.listBuilder__headerRight
              }
            >
              <input
                onBlur={saveListTitleOnServer}
                value={list.title}
                onChange={onChangeListTitle}
                placeholder="Untitled"
              />

              <Button
                loading={showSavingLoader}
                backgroundType="green"
                text="Save and exit"
                buttonClassName={styles.saveButton}
                onClick={goBack}
              />

              {myRole !== "User" && (
                <div
                  onClick={handleOpenDeleteModal}
                  className={styles.deleteButton}
                >
                  <TrashAlt fill="#EF2F32" />
                </div>
              )}
            </div>
          )}
        </div>

        {!showPdfTab && (
          <>
            {draftLoading || tabLoading ? (
              <>
                <ContentLoader
                  speed={1}
                  width={"100%"}
                  height={32}
                  style={{ margin: "20px 0 15px 68px" }}
                  backgroundColor="#DBDEE7"
                  foregroundColor="#ebedf2"
                >
                  <rect x="0" y="0" rx="7" ry="7" width="233" height="32" />
                </ContentLoader>
              </>
            ) : pdfFormat ? null : (
              <p
                className={
                  isDark
                    ? clsx(
                        styles.listBuilder__tabTitle,
                        styles.listBuilder__tabTitleDark
                      )
                    : styles.listBuilder__tabTitle
                }
              >
                <input
                  ref={tabInputRef}
                  type="text"
                  value={list.tabs[activeTabIndex].name}
                  onChange={handleTabTitleChange}
                />
              </p>
            )}
          </>
        )}

        {showPdfTab ? (
          <PdfTab />
        ) : (
          <div
            ref={contentRef}
            className={clsx(styles.listBuilder__content, {
              [styles.pdfPreview]: !draftLoading && pdfFormat,
            })}
          >
            {draftLoading || tabLoading ? (
              <>
                <ContentLoader
                  speed={1}
                  width={"100%"}
                  height={100}
                  backgroundColor="#DBDEE7"
                  foregroundColor="#ebedf2"
                >
                  <rect x="0" y="0" rx="7" ry="7" width="100%" height="100" />
                </ContentLoader>
              </>
            ) : (
              <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <Droppable droppableId="all-columns" type="section">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {pdfFormat ? (
                        <>
                          {list.tabs.map((tab, index) => {
                            return (
                              <div key={`${tab.id} - ${index} - tab`}>
                                <p
                                  className={clsx(
                                    styles.listBuilder__tabTitle,
                                    {
                                      [styles.noneMargin]: true,
                                    }
                                  )}
                                >
                                  <input
                                    disabled={true}
                                    ref={tabInputRef}
                                    type="text"
                                    value={tab.name}
                                    onChange={handleTabTitleChange}
                                  />
                                </p>

                                {tab.sections.map(
                                  (section: ISection, index: number) => {
                                    return (
                                      <Section
                                        key={`${section?.id} - ${index} - section`}
                                        section={section}
                                        index={index}
                                        tabIndex={index}
                                      />
                                    );
                                  }
                                )}
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {list.tabs[activeTabIndex].sections.map(
                            (item: ISection, index: number) => (
                              <Section
                                key={`${item?.id} - ${index} - section`}
                                section={item}
                                index={index}
                              />
                            )
                          )}

                          {!hideButton && !sectionLoading && (
                            <Button
                              onClick={handleAddSection}
                              buttonClassName={
                                styles.listBuilder__contentButton
                              }
                              icon={<AddSection />}
                              text="Add Section"
                              backgroundType={"green"}
                            />
                          )}
                        </>
                      )}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
        )}

        {!draftLoading && !pdfFormat && <Footer tabLoading={tabLoading} />}
      </div>
      {!pdfFormat && !showPdfTab && <Settings />}

      <DeleteDraftModal
        isOpen={showDeleteModal}
        onCloseModal={handleCloseDeleteModal}
        handleDeleteChecklist={handleDeleteDraft}
        showLoader={showDeletionLoader}
      />
    </div>
  );
};

export const ListBuilder = () => {
  return (
    <BuilderProvider>
      <WrappedListBuilder />
    </BuilderProvider>
  );
};
