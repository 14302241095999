import { GET_CHOOSED_ORGANIZATIOM } from "~localCache";
import { GET_ORGANIZATION_REQUESTS } from "~queries";
import { useUpdateEffect } from "usehooks-ts";
import { OrganizationMember } from "~models";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { client } from "~services";

export const useAllRequests = () => {
  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const { data, loading } = useQuery(GET_ORGANIZATION_REQUESTS, {
    variables: {
      input: { organizationId: choosedOrganization?.id },
    },
    fetchPolicy: "network-only",
  });

  const [searchValue, setSearchValue] = useState("");
  const [defaultData, setDefaultData] = useState<OrganizationMember[]>([]);
  const [filteredData, setFilteredData] = useState<OrganizationMember[]>([]);

  const removeRequestFromCache = (id: number) => {
    const normalizedId = client.cache.identify({
      id,
      __typename: "OrganizationRequest",
    });
    client.cache.evict({ id: normalizedId });
    client.cache.gc();
  };

  const removeItemFromData = async (index: number, id: number) => {
    setFilteredData((prev) => {
      let state = [...prev];
      state.splice(index, 1);
      return state;
    });

    let defaultDataIndex = defaultData.findIndex((item) => item.id === id);
    if (defaultDataIndex > -1) {
      setDefaultData((prev) => {
        let state = [...prev];
        state.splice(defaultDataIndex, 1);
        return state;
      });
    }

    removeRequestFromCache(id);
  };

  useEffect(() => {
    !!data &&
      data?.organizationRequests &&
      setDefaultData(data?.organizationRequests);
    !!data &&
      data?.organizationRequests &&
      !searchValue &&
      setFilteredData(data?.organizationRequests);
  }, [data]);

  const filterRquests = () => {
    if (!!searchValue) {
      let filteredData = defaultData.filter(
        (item: OrganizationMember) =>
          item.user.firstName
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item.user.lastName
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item.user.email?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredData(filteredData);
    } else {
      setFilteredData(defaultData);
    }
  };

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      filterRquests();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, defaultData]);

  return {
    members: filteredData,
    loadingMembers: false,
    membersCount: 0,
    searchValue,
    loading,
    setSearchValue,
    removeItemFromData,
  };
};
