import { gql } from "@apollo/client";

export const GENERATE_UPLOAD_URL = gql`
  mutation generateUploadUrl($input: GenerateLinksInput!) {
    generateUploadUrl(input: $input) {
      getUrl
      putUrl
    }
  }
`;

export const REMOVE_FILE_BY_URL = gql`
  mutation removeFileByUrl($input: RemoveFileByUrl!) {
    removeFileByUrl(input: $input)
  }
`;

export const GET_SHARING_CODE = gql`
  mutation createShareCode($input: CreateShareCodeInput!) {
    createShareCode(input: $input)
  }
`;

export const USE_SHARING_CODE = gql`
  mutation useShareCode($input: UseShareCodeInput!) {
    useShareCode(input: $input)
  }
`;

export const USE_SHARING_CODE_ADMIN = gql`
  mutation useShareCodeLibrary($input: UseShareCodeInputLibrary!) {
    useShareCodeLibrary(input: $input)
  }
`;
