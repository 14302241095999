import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "~hooks";

export const OnboardingRoutes = ({}) => {
  const { user } = useAuth();

  // return !!user && (!user?.firstName || !user?.lastName) ? (
  //   <Outlet />
  // ) : (
  //   <Navigate to={"/dashboard"} />
  // );

  return !!user?.onboarding ? <Navigate to={"/dashboard"} /> : <Outlet />;
  // return <Outlet />;
};
