import { gql } from "@apollo/client";

// ====== PDF ======

export const CREATE_PDF = gql`
  mutation createPdf($input: CreatePdfInput!) {
    createPdf(input: $input) {
      id
    }
  }
`;

export const CREATE_ADMIN_PDF = gql`
  mutation createPdfLibrary($input: CreatePdfInputLibrary!) {
    createPdfLibrary(input: $input) {
      id
    }
  }
`;

export const UPDATE_PDF = gql`
  mutation updatePdf($input: UpdatePdfInput!) {
    updatePdf(input: $input) {
      id
    }
  }
`;

export const REMOVE_PDF = gql`
  mutation removePdf($id: Int!) {
    removePdf(id: $id)
  }
`;

export const MOVE_PDF_TO_FOLDER = gql`
  mutation addPdfToFolder($input: AddPdfToFolderInput!) {
    addPdfToFolder(input: $input) {
      id
    }
  }
`;

export const REMOVE_PDF_FROM_FOLDER = gql`
  mutation removePdfFromFolder($id: Int!) {
    removePdfFromFolder(id: $id)
  }
`;

export const PUBLISH_PDF = gql`
  mutation publishPdf($id: Int!) {
    publishPdf(id: $id)
  }
`;

export const IMPORT_PDF = gql`
  mutation importPdf($input: ImportPdfInput!) {
    importPdf(input: $input) {
      id
      title
      status
      file
    }
  }
`;

export const DUPLICATE_PDF = gql`
  mutation duplicatePdf($id: Int!) {
    duplicatePdf(id: $id) {
      id
    }
  }
`;
