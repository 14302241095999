import { useLazyQuery, useQuery, useSubscription } from "@apollo/client";
import { useEffect } from "react";
import { GET_CHOOSED_ORGANIZATIOM, choosedOrganizationVar } from "~localCache";
import { GET_ORGANIZATION_REQUESTS } from "~queries";
import { MEMBER_REQUEST_SUBSCRIPTION } from "~subscriptions";

export const useRequestsSubscriptions = () => {
  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [updateRequests] = useLazyQuery(GET_ORGANIZATION_REQUESTS);

  const { data } = useSubscription(MEMBER_REQUEST_SUBSCRIPTION, {
    variables: { organizationId: choosedOrganization?.id },
  });

  const getRequestsCount = async () => {
    await updateRequests({
      variables: {
        input: {
          organizationId: choosedOrganization?.id,
        },
      },
      fetchPolicy: "network-only",
    });
  };

  useEffect(() => {
    if (!!data) {
      getRequestsCount();
      // updateChoosedOrganization();
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    !!choosedOrganization?.id && getRequestsCount();
  }, [choosedOrganization?.id]);
};
