export enum MessageType {
  Question = "Question",
  Answer = "Answer",
}

export enum MessageRate {
  Terrible = "Terrible",
  Neutral = "Neutral",
  Good = "Good",
  Great = "Great",
}

export interface IMessage {
  id: number;
  data: string;
  type: MessageType;
  rate?: MessageRate;
  createdAt: string;
}
