import { alertVar } from "~localCache";
import { IItem } from "../../../../constants";
import { useBuilder } from "../../../../context";
import { checkIsExpressionValid } from "./helper";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const useCalculator = ({ item, itemIndex }: IProps) => {
  const { setList, activeItem, activeTabIndex } = useBuilder();
  const alphabet = ["a", "b", "c", "d", "e"];

  let data =
    typeof item.data === "string"
      ? structuredClone(JSON.parse(item.data))
      : structuredClone(item.data);

  const handleAddInput = () => {
    if (item.data.inputs.length === 5) {
      alertVar({
        type: "red",
        text: "You have used the maximum number of inputs",
      });
    } else {
      if (!activeItem) return;
      data.inputs.push({ name: "" });
      setList((prev) => {
        let state = { ...prev };
        state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
          itemIndex
        ].data = data;
        return state;
      });
    }
  };

  const handleDeleteInput = (inputIndex: number) => {
    if (!activeItem) return;
    const outputsWithoutСurrentOperand = item.data.outputs.filter(
      (item: { logic: string }) => !item.logic.includes(alphabet[inputIndex])
    );
    data.inputs.splice(inputIndex, 1);
    data.outputs = outputsWithoutСurrentOperand;
    setList((prev) => {
      let state = { ...prev };

      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleChangeInput = (value: string, inputIndex: number) => {
    if (!activeItem) return;
    data.inputs[inputIndex].name = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleAddOutput = () => {
    if (!activeItem) return;
    data.outputs.push({ name: "", logic: "" });
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleDeleteOutput = (outputIndex: number) => {
    if (!activeItem) return;
    data.outputs.splice(outputIndex, 1);
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleChangeOutputName = (value: string, outputIndex: number) => {
    if (!activeItem) return;
    data.outputs[outputIndex].name = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleChangeOutputLogic = (value: string, outputIndex: number) => {
    if (!activeItem || !checkIsExpressionValid(value, item.data.inputs.length))
      return;
    data.outputs[outputIndex].logic = value;
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const disableAddInputButton = item.data.inputs.length === 5;

  return {
    alphabet,
    disableAddInputButton,
    handleAddInput,
    handleDeleteInput,
    handleChangeInput,
    handleAddOutput,
    handleDeleteOutput,
    handleChangeOutputName,
    handleChangeOutputLogic,
  };
};
