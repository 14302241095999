import { ImportCodeModal, UploadPdfModal, CreateFolderModal } from "~modals";
import { GET_CHOOSED_ORGANIZATIOM } from "~localCache";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { CREATE_DRAFT, CREATE_DRAFT_LIBRARY } from "~mutations";
import { useRef, useState } from "react";
import { useOutsideClick } from "~hooks";
import { Button } from "~components";
import clsx from "clsx";
import {
  CheckSquareBroken,
  FileUpload,
  Folder,
  Loader,
  PlusSymbol,
  Upload,
} from "~icons";

import styles from "./styles.module.scss";

interface IProps {
  refreshData: () => void;
}

export const PlusButton = ({ refreshData }: IProps) => {
  const navigate = useNavigate();
  const [mutationCreateDraft] = useMutation(CREATE_DRAFT_LIBRARY);

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [showPdfModal, setShowPdfModal] = useState(false);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenModal = () => setShowPopup(true);
  const handleCloseModal = () => setShowPopup(false);

  useOutsideClick(modalRef, handleCloseModal);

  const createDraft = () => {
    setShowLoader(true);
    mutationCreateDraft({
      variables: { input: {} },
    }).then((resp) => {
      console.log("RESP", resp);
      navigate(`/builder/${resp.data.createDraftLibrary.id}`, {
        state: { isAdmin: true },
      });
      setShowLoader(false);
    });
  };

  return (
    <div className={styles.plusButton}>
      <Button
        text="NEW"
        backgroundType={"green"}
        icon={<PlusSymbol fill="#FFFFFF" />}
        onClick={handleOpenModal}
      />

      <div
        ref={modalRef}
        className={clsx(styles.plusButton__modal, {
          [styles.active]: showPopup,
        })}
      >
        <div
          onClick={() => {
            handleCloseModal();
            setShowFolderModal(true);
          }}
          className={clsx(styles.plusButton__modalItem, styles.marginBottom)}
        >
          <Folder /> New folder
        </div>
        <div className={styles.plusButton__modalItem} onClick={createDraft}>
          <CheckSquareBroken stroke="#0B0F1C" /> New Checkem
          {showLoader && (
            <div className={styles.plusButton__modalItemLoader}>
              <Loader fill="#0B0F1C" />
            </div>
          )}
        </div>

        <div className={styles.plusButton__modalUnderline} />

        <div
          onClick={() => {
            handleCloseModal();
            setShowImportModal(true);
          }}
          className={styles.plusButton__modalItem}
        >
          <Upload /> Import Checkem or folder
        </div>

        <div
          onClick={() => {
            handleCloseModal();
            setShowPdfModal(true);
          }}
          className={styles.plusButton__modalItem}
        >
          <FileUpload /> PDF upload
        </div>
      </div>

      <UploadPdfModal
        isAdmin
        isOpen={showPdfModal}
        onCloseModal={() => setShowPdfModal(false)}
        handleSuccessResult={refreshData}
      />

      <ImportCodeModal
        isAdmin
        isOpen={showImportModal}
        onCloseModal={() => setShowImportModal(false)}
        handleSuccess={refreshData}
      />

      <CreateFolderModal
        isAdmin
        isOpen={showFolderModal}
        onCloseModal={() => setShowFolderModal(false)}
        handleSuccess={refreshData}
      />
    </div>
  );
};
