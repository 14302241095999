import React, { useState } from "react";
import { DashboardLayout } from "~layouts";
import styles from "./styles.module.scss";
import { SearchInput } from "~components";
import { VideoItem } from "./components";

const questions = [
  {
    title: "Lorem ipsum dolor sit amet consectetur?",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Ornare blandit consequat aliquet tincidunt risus nunc suspendisse. Elementum eu amet diam duis sed sagittis et. Adipiscing hendrerit tortor arcu eu sit dictum. Tempor placerat pellentesque senectus blandit.",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur?",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Ornare blandit consequat aliquet tincidunt risus nunc suspendisse. Elementum eu amet diam duis sed sagittis et. Adipiscing hendrerit tortor arcu eu sit dictum. Tempor placerat pellentesque senectus blandit.",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur?",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Ornare blandit consequat aliquet tincidunt risus nunc suspendisse. Elementum eu amet diam duis sed sagittis et. Adipiscing hendrerit tortor arcu eu sit dictum. Tempor placerat pellentesque senectus blandit.",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur?",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Ornare blandit consequat aliquet tincidunt risus nunc suspendisse. Elementum eu amet diam duis sed sagittis et. Adipiscing hendrerit tortor arcu eu sit dictum. Tempor placerat pellentesque senectus blandit.",
  },
];

const videos = [
  {
    title: "Lorem ipsum dolor sit amet consectetur",
    url: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur",
    url: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur",
    url: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur",
    url: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur",
    url: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur",
    url: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
  },
];

export const Academy = () => {
  const [showAnswer, setShowAnswer] = useState<Record<number, boolean>>({
    0: false,
  });
  return (
    <DashboardLayout title="Academy">
      <div className={styles.academy}>
        <SearchInput placeholder="Search" />
        <div className={styles.academy__videos}>
          {videos.map((video, index) => (
            <VideoItem title={video.title} url={video.url} key={index} />
          ))}
        </div>
        <div className={styles.academy__QA}>
          <p className={styles.academy__QATitle}>Q&A</p>
          <div className={styles.academy__QAContainer}>
            {questions.map((item, index) => (
              <div className={styles.academy__QAItem} key={index}>
                <div className={styles.academy__QAItemHeader}>
                  <p className={styles.academy__QAItemTitle}>{item.title}</p>
                  <p
                    onClick={() =>
                      setShowAnswer((prev) => ({
                        ...prev,
                        [index]: !prev[index],
                      }))
                    }
                    className={styles.academy__QAItemPlus}
                  >
                    {showAnswer[index] ? "-" : "+"}
                  </p>
                </div>
                {showAnswer[index] && (
                  <p className={styles.academy__QAItemAnswer}>{item.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
