import styles from "./styles.module.scss";
import {
  CopyTo,
  DotsVertical,
  Duplicate,
  FileDownload,
  Pen,
  Share,
} from "~icons";
import clsx from "clsx";
import { useFeedbackItem } from "./useFeedbackItem";
import { StrictModifierNames, Modifier } from "react-popper";
import { Options, createPopper } from "@popperjs/core";
import { PopperPopup } from "~components";

export const FeedbackItem = () => {
  const { showPopper, handleOpenPopper, handleClosePopper } = useFeedbackItem();

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  return (
    <>
      <div className={styles.feedbackItem}>
        <div className={styles.feedbackItem__reporter}>
          <div className={styles.feedbackItem__reporterAvatar} />
          <div className={styles.feedbackItem__reporterRight}>
            <p className={styles.feedbackItem__reporterName}>
              Bolyubash Bohdan
            </p>
            <p className={styles.feedbackItem__reporterEmail}>
              bbolyubash@gmail.com
            </p>
          </div>
        </div>
        <div className={styles.feedbackItem__category}>
          <p className={styles.feedbackItem__categoryTitle}>Plans & Billing</p>
          <p className={styles.feedbackItem__categoryMessage}>
            I found the billing process to be confusing and not very
            user-friendly. The information about my billing plan and charges was
            not easily accessible...
          </p>
        </div>
        <div className={styles.feedbackItem__date}>
          <p className={styles.feedbackItem__dateDate}>September 9, 2022</p>
          <div className={styles.feedbackItem__dateRight}>
            <div className={styles.feedbackItem__dateButton}>Show Response</div>
            <PopperPopup
              showPopper={showPopper}
              onClosePopper={handleClosePopper}
              onOpenPopper={handleOpenPopper}
              options={popperOptions}
              trigger={<DotsVertical fill="#0B0F1C" />}
              popup={
                <div className={clsx(styles.feedbackItem__modal, {})}>
                  <div className={styles.feedbackItem__modalItem}>
                    <Pen fill="#0B0F1C" /> Edit
                  </div>
                  <div
                    className={clsx(
                      styles.feedbackItem__modalItem,
                      styles.margin
                    )}
                  >
                    <Duplicate /> Duplicate
                  </div>
                  <div className={styles.feedbackItem__modalItem}>
                    <CopyTo /> Copy to
                  </div>

                  <div className={styles.feedbackItem__modalUnderline} />

                  <div className={styles.feedbackItem__modalItem}>
                    <Share /> Share Checkem
                  </div>
                  <div className={styles.feedbackItem__modalItem}>
                    <FileDownload /> Generate PDF
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.feedbackItem__underline} />
    </>
  );
};
