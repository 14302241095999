import { gql } from "@apollo/client";

export const GET_FOLDER = gql`
  query folder($id: Int!) {
    folder(id: $id) {
      id
      title
    }
  }
`;

export const GET_ORGANIZATION_BUILDER_FOLDER_DATA = gql`
  query getOrganizationBuilderFolderData(
    $input: FolderDataInput!
    $countPdfInput: CountFolderPdfInput!
    $countDraftInput: CountFolderDraftInput!
  ) {
    checkemBuilderFolderData(input: $input) {
      pdfs {
        id
        title
        status
        file
        type
        status
        folderId
      }
      drafts {
        id
        title
        status
        type
        lastModified
        folderId
      }
    }
    countFolderPdf(input: $countPdfInput)
    countFolderDraft(input: $countDraftInput)
  }
`;

export const GET_ORGANIZATION_FOLDERS = gql`
  query getOrganizationFolders($foldersInput: FindFolderInput!) {
    folders(input: $foldersInput) {
      id
      title
      count
      status
      organizationId
      parentFolderId
    }
  }
`;

export const GET_ORGANIZATION_BUILDER_FOLDER_INITIAL_DATA = gql`
  query getOrganizationBuilderFolderInitialData(
    $foldersInput: FindFolderInput!
    $input: FolderDataInput!
    $countPdfInput: CountFolderPdfInput!
    $countDraftInput: CountFolderDraftInput!
  ) {
    folders(input: $foldersInput) {
      id
      title
      count
      status
      organizationId
      parentFolderId
    }
    checkemBuilderFolderData(input: $input) {
      pdfs {
        id
        title
        status
        file
        type
        status
        folderId
      }
      drafts {
        id
        title
        status
        type
        lastModified
        folderId
      }
    }
    countFolderPdf(input: $countPdfInput)
    countFolderDraft(input: $countDraftInput)
  }
`;

export const GET_ORGANIZATION_HOME_FOLDER_DATA = gql`
  query getOrganizationHomeFolderData(
    $input: FolderDataInput!
    $countFolderPdf: CountFolderPdfInput!
    $countChecklistInput: CountFolderChecklistInput!
  ) {
    checkemHomeFolderData(input: $input) {
      pdfs {
        id
        title
        status
        file
        type
        status
        firstName
        lastName
        createdAt
      }
      checklists {
        id
        title
        version
        latest
        type
        firstName
        lastName
        createdAt
      }
    }
    countFolderPdf(input: $countFolderPdf)
    countFolderChecklist(input: $countChecklistInput)
  }
`;

export const GET_ORGANIZATION_HOME_FOLDER_INITIAL_DATA = gql`
  query getOrganizationHomeFolderInitialData(
    $foldersInput: FindFolderInput!
    $input: FolderDataInput!
    $countFolderPdf: CountFolderPdfInput!
    $countChecklistInput: CountFolderChecklistInput!
  ) {
    folders(input: $foldersInput) {
      id
      title
      count
      status
      organizationId
      firstName
      lastName
      createdAt
      updatedAt
    }
    checkemHomeFolderData(input: $input) {
      pdfs {
        id
        title
        status
        file
        type
        status
        firstName
        lastName
        createdAt
      }
      checklists {
        id
        title
        version
        latest
        type
        firstName
        lastName
        createdAt
      }
    }
    countFolderPdf(input: $countFolderPdf)
    countFolderChecklist(input: $countChecklistInput)
  }
`;

export const GET_ADMIN_BUILDER_DATA = gql`
  query getAdminBuilderData(
    $input: FindDraftInputLibrary!
    $countDraft: CountDraftInputLibrary!
    $countPdf: CountPdfInputLibrary!
  ) {
    checkemBuilderDataLibrary(input: $input) {
      pdfs {
        id
        title
        status
        file
        type
        status
      }
      drafts {
        id
        title
        status
        lastModified
        type
      }
    }
    countDraftLibrary(input: $countDraft)
    countPdfLibrary(input: $countPdf)
  }
`;

export const GET_ADMIN_BUILDER_DATA_AND_FOLDERS = gql`
  query getAdminBuilderDataAndFolders(
    $folderInput: FindFolderInputLibrary!
    $input: FindDraftInputLibrary!
    $countDraft: CountDraftInputLibrary!
    $countPdf: CountPdfInputLibrary!
  ) {
    foldersLibrary(input: $folderInput) {
      id
      title
    }
    checkemBuilderDataLibrary(input: $input) {
      pdfs {
        id
        title
        status
        file
        type
        status
      }
      drafts {
        id
        title
        status
        lastModified
        type
      }
    }
    countDraftLibrary(input: $countDraft)
    countPdfLibrary(input: $countPdf)
  }
`;

export const GET_ADMIN_LIBRARY_DATA = gql`
  query getAdminLibraryData(
    $input: FindChecklistLibraryInput!
    $countChecklist: CountChecklistInputLibrary!
    $countPdf: CountPdfInputLibrary!
  ) {
    checkemHomeDataLibrary(input: $input) {
      pdfs {
        id
        title
        status
        file
        type
        status
        firstName
        lastName
      }
      checklists {
        id
        title
        version
        latest
        type
        firstName
        lastName
      }
    }
    countChecklistLibrary(input: $countChecklist)
    countPdfLibrary(input: $countPdf)
  }
`;

export const GET_ADMIN_LIBRARY_DATA_AND_FOLDERS = gql`
  query getAdminLibraryDataAndFolders(
    $folderInput: FindFolderInputLibrary!
    $input: FindChecklistLibraryInput!
    $countChecklist: CountChecklistInputLibrary!
    $countPdf: CountPdfInputLibrary!
  ) {
    foldersLibrary(input: $folderInput) {
      id
      title
      firstName
      lastName
      createdAt
      updatedAt
    }
    checkemHomeDataLibrary(input: $input) {
      pdfs {
        id
        title
        status
        file
        type
        status
        firstName
        lastName
      }
      checklists {
        id
        title
        version
        latest
        type
        firstName
        lastName
      }
    }
    countChecklistLibrary(input: $countChecklist)
    countPdfLibrary(input: $countPdf)
  }
`;
