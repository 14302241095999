import React, { FC, useEffect, useRef } from "react";
import { ProgressItemSquare } from "../ProgressItemSquare";
import { IActiveChecklist } from "~models";
import styles from "./styles.module.scss";

interface IProps {
  handleContinue: (checklistId: number, progressId: number) => void;
  handleOpenDeleteModal: (id: number) => void;
  loading: boolean;
  handleLoad: () => void;
  checklists: IActiveChecklist[];
}

export const CompletedGridWrapper: FC<IProps> = ({
  checklists,
  handleContinue,
  handleLoad,
  loading,
  handleOpenDeleteModal,
}) => {
  const gridContentRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      gridContentRef.current as HTMLDivElement;
    if (scrollHeight - scrollTop <= clientHeight && !loading && handleLoad) {
      handleLoad();
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);
  return (
    <div
      className={styles.gridWrapper}
      ref={gridContentRef}
      onScroll={handleScroll}
    >
      <div className={styles.grid}>
        {checklists.map((item, index) => (
          <ProgressItemSquare
            handleContinue={() => handleContinue(item.checklist.id, item.id)}
            activeChecklist={item}
            key={`${item} - ${index}`}
            index={index}
            deleteProgress={handleOpenDeleteModal}
          />
        ))}
      </div>
    </div>
  );
};
