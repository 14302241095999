import { StrictModifierNames, Modifier } from "react-popper";
import { Options, createPopper } from "@popperjs/core";
import { PublishingStatus, IPdf } from "~models";
import { DeleteModal, MoveModal, ShareCodeModal } from "../../modals";
import { Button, PopperPopup } from "~components";
import { usePDF } from "../../usePDF";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  CopyTo,
  DotsVertical,
  Duplicate,
  FileNone,
  Refresh,
  Share,
  TrashAlt,
  File,
  Undo,
} from "~icons";

import styles from "./styles.module.scss";

interface IProps {
  pdf: IPdf;
  index: number;
  showTableLoader?: () => void;
  updateTableData?: () => void;
  updatePDFStatus: (status: PublishingStatus, index: number) => void;
}

export const PDFItem = ({
  pdf,
  index,
  updatePDFStatus,
  showTableLoader,
  updateTableData,
}: IProps) => {
  const {
    myRole,
    modalRef,
    showPopper,
    showDeletionModal,
    showShareCodeModal,
    showMoveModal,
    handleDelete,
    handleView,
    handleMoveOut,
    handlePublish,
    handleUnpublish,
    handleDuplicate,
    handleOpenPopper,
    handleClosePopper,
    handleOpenDeletionModal,
    handleCloseDeletionModal,
    handleOpenShareModal,
    handleCloseShareModal,
    handleCloseMoveModal,
    handleOpenMoveModal
  } = usePDF({
    pdf,
    index,
    updatePDFStatus,
    showTableLoader,
    updateTableData,
  });

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-start",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  return (
    <div>
      <div className={styles.pdfItem}>
        <div className={styles.pdfItem__horizontal}>
          <div className={styles.pdfItem__horizontalName}>
            <File fill="#0B0F1C" />
            {pdf.title}
          </div>
          <div className={styles.pdfItem__horizontalDate}>
            {dayjs(new Date()).format("MMMM D, YYYY")}
          </div>

          <div className={styles.pdfItem__horizontalAction}>
            <Button
              text="View"
              backgroundColor="#3A6CFF"
              textColor="#FFFFFF"
              onClick={handleView}
            />
          </div>

          <div className={styles.pdfItem__horizontalType}>
            <div className={styles.pdfItem__horizontalTypePdf}>PDF</div>
          </div>

          <div className={styles.pdfItem__horizontalStatus}>
            <div
              className={clsx(styles.pdfItem__horizontalStatusLeft, {
                [styles.yellow]:
                  pdf.status === PublishingStatus.UnpublishedChanges,
                [styles.red]: pdf.status === PublishingStatus.Unpublished,
                [styles.green]: pdf.status === PublishingStatus.Published,
              })}
            >
              <div
                className={clsx(styles.pdfItem__horizontalStatusCircle, {
                  [styles.yellow]:
                    pdf.status === PublishingStatus.UnpublishedChanges,
                  [styles.red]: pdf.status === PublishingStatus.Unpublished,
                  [styles.green]: pdf.status === PublishingStatus.Published,
                })}
              ></div>

              <p>
                {pdf.status === PublishingStatus.UnpublishedChanges
                  ? "Unpublished Changes"
                  : pdf.status}
              </p>

              {pdf.status !== PublishingStatus.Published && (
                <div
                  onClick={handlePublish}
                  className={styles.pdfItem__horizontalStatusRefresh}
                >
                  <Refresh fill="#29B473" />
                </div>
              )}
            </div>
            <div className={styles.pdfItem__horizontalStatusRight}>
              <PopperPopup
                showPopper={showPopper}
                onClosePopper={handleClosePopper}
                onOpenPopper={handleOpenPopper}
                options={popperOptions}
                trigger={<DotsVertical fill="#0B0F1C" />}
                popup={
                  <div
                    ref={modalRef}
                    className={clsx(styles.pdfItem__modal, {})}
                  >
                    <div
                      onClick={handleDuplicate}
                      className={clsx(styles.pdfItem__modalItem, styles.margin)}
                    >
                      <Duplicate /> Duplicate
                    </div>
                    <div className={styles.pdfItem__modalItem} onClick={handleOpenMoveModal}>
                      <CopyTo /> Move to
                    </div>

                    {/* {!!pdf?.folderId && (
                      <div
                        onClick={handleMoveOut}
                        className={styles.pdfItem__modalItem}
                      >
                        <Undo fill={"#0B0F1C"} /> Move out of folder
                      </div>
                    )} */}

                    <div className={styles.pdfItem__modalUnderline} />
                    {pdf.status !== PublishingStatus.Published && (
                      <div
                        onClick={handlePublish}
                        className={styles.pdfItem__modalItem}
                      >
                        <Refresh width={20} height={20} fill="#0B0F1C" />{" "}
                        {pdf.status === PublishingStatus.Unpublished
                          ? "Publish"
                          : "Publish latest changes"}
                      </div>
                    )}

                    {pdf.status === PublishingStatus.Published && (
                      <div
                        onClick={handleUnpublish}
                        className={styles.pdfItem__modalItem}
                      >
                        <FileNone /> Unpublish
                      </div>
                    )}

                    <div className={styles.pdfItem__modalUnderline} />

                    <div
                      onClick={handleOpenShareModal}
                      className={styles.pdfItem__modalItem}
                    >
                      <Share /> Share PDF
                    </div>

                    {myRole !== "User" && (
                      <div
                        onClick={handleOpenDeletionModal}
                        className={styles.pdfItem__modalItem}
                      >
                        <TrashAlt fill="#0B0F1C" /> Delete
                      </div>
                    )}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.pdfItem__underline} />

      <DeleteModal
        isOpen={showDeletionModal}
        itemName="pdf"
        onCloseModal={handleCloseDeletionModal}
        handleDelete={handleDelete}
      />

      <ShareCodeModal
        isOpen={showShareCodeModal}
        id={pdf.id}
        type="Pdf"
        onCloseModal={handleCloseShareModal}
      />

      <MoveModal
        isOpen={showMoveModal}
        onCloseModal={handleCloseMoveModal}
        itemToMove={pdf}
        updateTableData={updateTableData}
      />
    </div>
  );
};
