import { Button, Dropdown, Input, Modal } from "~components";
import { PlusSymbol, Trash } from "~icons";
import { OrganizationMemberRole } from "~models";
import styles from "./styles.module.scss";
import { useInvite } from "./useInvite";

interface IProps {
  handleCloseModal: () => void;
}

export const InvateMembersModal = ({ handleCloseModal }: IProps) => {
  const {
    members,
    handleChangeEmail,
    handleChangeRole,
    deleteMember,
    createMember,
    handleFinish,
    isDisable,
    loading,
  } = useInvite({ onFinish: handleCloseModal });

  return (
    <Modal
      modalClassName={styles.inviteMembers__modal}
      onCloseModal={handleCloseModal}
    >
      <div className={styles.inviteMembers}>
        <div className={styles.inviteMembers__title}>Invite Members</div>
        <div className={styles.inviteMembers__subtitle}>
          Invite members to your organization.
        </div>

        {members.map((item, index) => {
          const number = index + 1;
          const inputLabel = index === 0 ? "Email" : "";
          const dropdownLabel = index === 0 ? "Role" : "";
          return (
            <div
              className={styles.inviteMembers__row}
              key={`member - ${index}`}
            >
              <div className={styles.inviteMembers__rowNumber}>{number}</div>
              <div className={styles.inviteMembers__rowInput}>
                <Input
                  value={members[index].email}
                  onChange={(value) => handleChangeEmail(index, value)}
                  label={inputLabel}
                  placeholder="Ex. james@test.com"
                />
              </div>
              <div className={styles.inviteMembers__rowDropdown}>
                <Dropdown
                  value={members[index].role}
                  label={dropdownLabel}
                  placeholder="Role"
                  data={[
                    OrganizationMemberRole.Admin,
                    OrganizationMemberRole.User,
                  ]}
                  onChange={(valIndex, value) => handleChangeRole(index, value)}
                />
              </div>
              <div
                className={styles.inviteMembers__rowIcon}
                onClick={() => deleteMember(index)}
              >
                {members.length > 1 && <Trash stroke="#979DB1" />}
              </div>
            </div>
          );
        })}

        <div className={styles.inviteMembers__addButton}>
          <Button
            onClick={createMember}
            text="Add Email"
            backgroundColor="#DBDEE7"
            icon={<PlusSymbol fill="#0B0F1C" />}
          />
        </div>

        <div className={styles.inviteMembers__buttons}>
          <Button
            disable={isDisable}
            loading={loading}
            onClick={handleFinish}
            text="Invite"
            backgroundType={"green"}
          />
        </div>
      </div>
    </Modal>
  );
};
