import { useMutation } from "@apollo/client";
import { UPDATE_EMAIL } from "~mutations";
import { regex } from "~constants";
import { useState } from "react";
import { IUser } from "~models";

interface IProps {
  editableUser: IUser | null;
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
}

export const useEditEmail = ({ editableUser, setUser }: IProps) => {
  const [email, setEmail] = useState(
    !!editableUser?.email ? editableUser?.email : ""
  );

  const [checkEmailLoader, setCheckEmailLoader] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [changeEmailError, setChangeEmailError] = useState("");

  const isDisable = email === editableUser?.email || !regex.email.test(email);

  const [updateEmail] = useMutation(UPDATE_EMAIL);

  const onChangeEmail = (value: string) => {
    setEmail(value);
  };

  const onCancelEmailChange = () => {
    if (!editableUser) return null;
    !!changeEmailError && setChangeEmailError("");
    setEmail(editableUser.email);
  };

  const handleSaveEmail = () => {
    setCheckEmailLoader(true);
    updateEmail({ variables: { input: { email: email } } })
      .then((resp) => {
        !!editableUser && setUser({ ...editableUser, email: email });
        !!changeEmailError && setChangeEmailError("");
        setCheckEmailLoader(false);
        setShowConfirmationModal(true);
      })
      .catch((err) => {
        setCheckEmailLoader(false);
        setChangeEmailError(err?.graphQLErrors[0].message);
      });
  };

  const handleCloseModal = () => setShowConfirmationModal(false);

  return {
    email,
    isDisable,
    checkEmailLoader,
    changeEmailError,
    authType: editableUser?.authType,
    showConfirmationModal,
    onChangeEmail,
    handleSaveEmail,
    onCancelEmailChange,
    handleCloseModal,
  };
};
