import styles from "./styles.module.scss";

import { IItem } from "~models";

import {
  BackAdultBody,
  BackAdultHead,
  BackAdultTorso,
  BackAdultHand,
  FrontAdultBody,
  FrontAdultHead,
  FrontAdultTorso,
  Groin,
  FrontAdultHand,
  FrontAdultLeg,
  FrontChildBody,
  FrontChildHead,
  FrontChildTorso,
  FrontChildHand,
  FrontChildLeg,
  BackChildBody,
  BackChildHead,
  BackChildTorso,
  BackChildHand,
  BackChildLeg,
  BackAdultLeg,
} from "~icons";
import clsx from "clsx";
import { useBurnedAreas } from "./useBurnedAreas";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const BurnedAreas = ({ item, progressItem, isDisable }: IProps) => {
  const { BODY_PARTS, handleClick,  isChoosed, percent } = useBurnedAreas({
    item,
    progressItem,
    isDisable,
  });

  return (
    <div className={styles.burnedAreas__wrapper}>
      <div className={styles.burnedAreas}>
        {item.data.type === "Adult" ? (
          <>
            <div className={styles.burnedAreas__adultFront}>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.FRONT_HEAD)}
                className={clsx(styles.burnedAreas__adultFrontHead, {
                  [styles.active]: isChoosed(BODY_PARTS.FRONT_HEAD),
                  [styles.disable]: isDisable,
                })}
              >
                <FrontAdultHead />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.FRONT_TORSO)}
                className={clsx(styles.burnedAreas__adultFrontTorso, {
                  [styles.active]: isChoosed(BODY_PARTS.FRONT_TORSO),
                  [styles.disable]: isDisable,
                })}
              >
                <FrontAdultTorso />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.FRONT_RIGHT_HAND)}
                className={clsx(styles.burnedAreas__adultFrontRightHand, {
                  [styles.active]: isChoosed(BODY_PARTS.FRONT_RIGHT_HAND),
                  [styles.disable]: isDisable,
                })}
              >
                <FrontAdultHand />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.FRONT_LEFT_HAND)}
                className={clsx(styles.burnedAreas__adultFrontLeftHand, {
                  [styles.active]: isChoosed(BODY_PARTS.FRONT_LEFT_HAND),
                  [styles.disable]: isDisable,
                })}
              >
                <FrontAdultHand />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.FRONT_RIGHT_LEG)}
                className={clsx(styles.burnedAreas__adultFrontRightLeg, {
                  [styles.active]: isChoosed(BODY_PARTS.FRONT_RIGHT_LEG),
                  [styles.disable]: isDisable,
                })}
              >
                <FrontAdultLeg />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.FRONT_LEFT_LEG)}
                className={clsx(styles.burnedAreas__adultFrontLeftLeg, {
                  [styles.active]: isChoosed(BODY_PARTS.FRONT_LEFT_LEG),
                  [styles.disable]: isDisable,
                })}
              >
                <FrontAdultLeg />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.GROIN)}
                className={clsx(styles.burnedAreas__adultFrontGroin, {
                  [styles.active]: isChoosed(BODY_PARTS.GROIN),
                  [styles.disable]: isDisable,
                })}
              >
                <Groin />
              </div>
              <FrontAdultBody />
            </div>
            <div className={styles.burnedAreas__adultBack}>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.BACK_HEAD)}
                className={clsx(styles.burnedAreas__adultBackHead, {
                  [styles.active]: isChoosed(BODY_PARTS.BACK_HEAD),
                  [styles.disable]: isDisable,
                })}
              >
                <BackAdultHead />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.BACK_TORSO)}
                className={clsx(styles.burnedAreas__adultBackTorso, {
                  [styles.active]: isChoosed(BODY_PARTS.BACK_TORSO),
                  [styles.disable]: isDisable,
                })}
              >
                <BackAdultTorso />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.BACK_RIGHT_HAND)}
                className={clsx(styles.burnedAreas__adultBackRightHand, {
                  [styles.active]: isChoosed(BODY_PARTS.BACK_RIGHT_HAND),
                  [styles.disable]: isDisable,
                })}
              >
                <BackAdultHand />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.BACK_LEFT_HAND)}
                className={clsx(styles.burnedAreas__adultBackLeftHand, {
                  [styles.active]: isChoosed(BODY_PARTS.BACK_LEFT_HAND),
                  [styles.disable]: isDisable,
                })}
              >
                <BackAdultHand />
              </div>

              <div
                onClick={(e) => handleClick(e, BODY_PARTS.BACK_RIGHT_LEG)}
                className={clsx(styles.burnedAreas__adultBackRightLeg, {
                  [styles.active]: isChoosed(BODY_PARTS.BACK_RIGHT_LEG),
                  [styles.disable]: isDisable,
                })}
              >
                <BackAdultLeg />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.BACK_LEFT_LEG)}
                className={clsx(styles.burnedAreas__adultBackLeftLeg, {
                  [styles.active]: isChoosed(BODY_PARTS.BACK_LEFT_LEG),
                  [styles.disable]: isDisable,
                })}
              >
                <BackAdultLeg />
              </div>

              <BackAdultBody />
            </div>
          </>
        ) : (
          <>
            <div className={styles.burnedAreas__childFront}>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.FRONT_HEAD)}
                className={clsx(styles.burnedAreas__childFrontHead, {
                  [styles.active]: isChoosed(BODY_PARTS.FRONT_HEAD),
                  [styles.disable]: isDisable,
                })}
              >
                {/* <FrontChildHead /> */}
                <BackChildHead />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.FRONT_TORSO)}
                className={clsx(styles.burnedAreas__childFrontTorso, {
                  [styles.active]: isChoosed(BODY_PARTS.FRONT_TORSO),
                  [styles.disable]: isDisable,
                })}
              >
                <FrontChildTorso />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.FRONT_RIGHT_HAND)}
                className={clsx(styles.burnedAreas__childFrontRightHand, {
                  [styles.active]: isChoosed(BODY_PARTS.FRONT_RIGHT_HAND),
                  [styles.disable]: isDisable,
                })}
              >
                <FrontChildHand />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.FRONT_LEFT_HAND)}
                className={clsx(styles.burnedAreas__childFrontLeftHand, {
                  [styles.active]: isChoosed(BODY_PARTS.FRONT_LEFT_HAND),
                  [styles.disable]: isDisable,
                })}
              >
                <FrontChildHand />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.FRONT_RIGHT_LEG)}
                className={clsx(styles.burnedAreas__childFrontRightLeg, {
                  [styles.active]: isChoosed(BODY_PARTS.FRONT_RIGHT_LEG),
                  [styles.disable]: isDisable,
                })}
              >
                <FrontChildLeg />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.FRONT_LEFT_LEG)}
                className={clsx(styles.burnedAreas__childFrontLeftLeg, {
                  [styles.active]: isChoosed(BODY_PARTS.FRONT_LEFT_LEG),
                  [styles.disable]: isDisable,
                })}
              >
                <FrontChildLeg />
              </div>
              <FrontChildBody />
            </div>
            <div className={styles.burnedAreas__childBack}>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.BACK_HEAD)}
                className={clsx(styles.burnedAreas__childBackHead, {
                  [styles.active]: isChoosed(BODY_PARTS.BACK_HEAD),
                  [styles.disable]: isDisable,
                })}
              >
                <BackChildHead />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.BACK_TORSO)}
                className={clsx(styles.burnedAreas__childBackTorso, {
                  [styles.active]: isChoosed(BODY_PARTS.BACK_TORSO),
                  [styles.disable]: isDisable,
                })}
              >
                <BackChildTorso />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.BACK_RIGHT_HAND)}
                className={clsx(styles.burnedAreas__childBackRightHand, {
                  [styles.active]: isChoosed(BODY_PARTS.BACK_RIGHT_HAND),
                  [styles.disable]: isDisable,
                })}
              >
                <BackChildHand />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.BACK_LEFT_HAND)}
                className={clsx(styles.burnedAreas__childBackLeftHand, {
                  [styles.active]: isChoosed(BODY_PARTS.BACK_LEFT_HAND),
                  [styles.disable]: isDisable,
                })}
              >
                <BackChildHand />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.BACK_RIGHT_LEG)}
                className={clsx(styles.burnedAreas__childBackRightLeg, {
                  [styles.active]: isChoosed(BODY_PARTS.BACK_RIGHT_LEG),
                  [styles.disable]: isDisable,
                })}
              >
                <BackChildLeg />
              </div>
              <div
                onClick={(e) => handleClick(e, BODY_PARTS.BACK_LEFT_LEG)}
                className={clsx(styles.burnedAreas__childBackLeftLeg, {
                  [styles.active]: isChoosed(BODY_PARTS.BACK_LEFT_LEG),
                  [styles.disable]: isDisable,
                })}
              >
                <BackChildLeg />
              </div>
              <BackChildBody />
            </div>
          </>
        )}
      </div>
      <p className={styles.burnedAreas__percent}>{percent}%</p>
    </div>
  );
};
