import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import {
  GET_CHOOSED_ORGANIZATIOM,
  alertVar,
  sortMethod,
  viewTypes,
} from "~localCache";
import {
  GET_FOLDER,
  GET_ORGANIZATION_BUILDER_FOLDER_DATA,
  GET_ORGANIZATION_BUILDER_FOLDER_INITIAL_DATA,
} from "~queries";
import { PublishingStatus, IDraft, IFolder } from "~models";
import { useUpdateEffect } from "usehooks-ts";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DropResult } from "react-beautiful-dnd";
import {
  MOVE_DRAFT_TO_FOLDER,
  MOVE_FOLDER_TO_FOLDER,
  MOVE_PDF_TO_FOLDER,
} from "~mutations";

const PAGE_LIMIT = 10;

export const useFolderBuilder = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [movePdfToFolder] = useMutation(MOVE_PDF_TO_FOLDER);
  const [moveDraftToFolder] = useMutation(MOVE_DRAFT_TO_FOLDER);
  const [moveFolderToFolder] = useMutation(MOVE_FOLDER_TO_FOLDER);

  const viewType = useReactiveVar(viewTypes);

  const setViewType = (type: "table" | "grid") => {
    viewTypes(type);
  };

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);
  const sortBy = useReactiveVar(sortMethod);

  const [getFolder] = useLazyQuery(GET_FOLDER);

  const [folders, setFolders] = useState<IFolder[]>([]);

  const [fetchedCount, setFetchedCount] = useState(0);
  const [drafts, setDrafts] = useState<IDraft[]>([]);
  const [allDraftsCount, setAllDraftsCount] = useState(0);

  const [searchValue, setSearchValue] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);

  const [folderTitle, setFolderTitle] = useState("");

  const [getData] = useLazyQuery(GET_ORGANIZATION_BUILDER_FOLDER_DATA);
  const [getInitialData] = useLazyQuery(
    GET_ORGANIZATION_BUILDER_FOLDER_INITIAL_DATA
  );

  const sortAlphabetically = (data: IDraft[]) => {
    return data.sort((a, b) => {
      if (!a.title) {
        return 1;
      }
      if (!b.title) {
        return -1;
      }
      return a.title.localeCompare(b.title);
    });
  };

  const sortByTime = (data: IDraft[]) => {
    return data.sort((a, b) => {
      if (!a.lastModified) {
        return 1;
      }
      if (!b.lastModified) {
        return -1;
      }
      return (
        new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime()
      );
    });
  };

  const loadData = () => {
    setLoading(true);
    if (!params?.id) return;

    const foldersInput = {
      skip: 0,
      take: 49,
      parentFolderId: +params?.id,
      organizationId: +choosedOrganization.id,
    };

    const countPdfInput = {
      folderId: +params?.id,
    };

    const countDraftInput = {
      folderId: +params?.id,
    };

    const input = {
      folderId: +params?.id,
      skip: 0,
      take: PAGE_LIMIT,
    };

    if (!!searchValue) {
      Object.assign(countPdfInput, { search: searchValue });
      Object.assign(countDraftInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }

    getInitialData({
      variables: { countPdfInput, foldersInput, input, countDraftInput },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setFolders(resp.data.folders);
      fetchedCount !== 0 && setFetchedCount(0);
      setAllDraftsCount(resp.data.countFolderPdf + resp.data.countFolderDraft);
      sortBy === "alphabet"
        ? setDrafts(
            sortAlphabetically([
              ...resp.data.checkemBuilderFolderData.pdfs,
              ...resp.data.checkemBuilderFolderData.drafts,
            ])
          )
        : setDrafts(
            sortByTime([
              ...resp.data.checkemBuilderFolderData.pdfs,
              ...resp.data.checkemBuilderFolderData.drafts,
            ])
          );
      setLoading(false);
    });
  };

  const getFolderData = () => {
    if (!params?.id) return;
    getFolder({ variables: { id: +params?.id } }).then((resp) => {
      setFolderTitle(resp.data.folder.title);
    });
  };

  const updateDraftStatus = (status: PublishingStatus, index: number) => {
    setDrafts((prev) => {
      let state = [...prev];
      state[index].status = status;
      return state;
    });
  };
  useEffect(() => {
    console.log(sortBy, 'sortBy')
    if (drafts.length) {
      sortBy === "alphabet"
        ? setDrafts((prev) => sortAlphabetically([...prev]))
        : setDrafts((prev) => sortByTime([...prev]));
    }
  }, [sortBy, drafts.length]);
  const handleNextPage = () => {
    if (!params?.id) return;

    const maxPage = Math.ceil(allDraftsCount);
    const skip = fetchedCount + PAGE_LIMIT;
    if (skip >= maxPage) return;
    setPaginationLoading(true);
    let take = PAGE_LIMIT;
    if (allDraftsCount - skip < PAGE_LIMIT) {
      take = allDraftsCount - skip;
    }
    const countPdfInput = {
      folderId: +params?.id,
    };

    const countDraftInput = {
      folderId: +params?.id,
    };

    const input = {
      folderId: +params?.id,
      skip: skip,
      take: PAGE_LIMIT,
    };
    if (!!searchValue) {
      Object.assign(countPdfInput, { search: searchValue });
      Object.assign(countDraftInput, { search: searchValue });

      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countPdfInput, input, countDraftInput },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllDraftsCount(resp.data.countFolderPdf + resp.data.countFolderDraft);
      setDrafts((prev) => [
        ...prev,
        ...resp.data.checkemBuilderFolderData.pdfs,
        ...resp.data.checkemBuilderFolderData.drafts,
      ]);
      setPaginationLoading(false);
    });
    setFetchedCount(skip);
  };

  const handlePrevPage = () => {
    if (!params?.id) return;

    const skip = fetchedCount - PAGE_LIMIT;
    if (skip < 0) return;
    setLoading(true);
    const countPdfInput = {
      folderId: +params?.id,
    };

    const countDraftInput = {
      folderId: +params?.id,
    };

    const input = {
      folderId: +params?.id,
      skip: skip,
      take: PAGE_LIMIT,
    };
    if (!!searchValue) {
      Object.assign(countPdfInput, { search: searchValue });
      Object.assign(countDraftInput, { search: searchValue });

      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countPdfInput, input, countDraftInput },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllDraftsCount(resp.data.countFolderPdf + resp.data.countFolderDraft);
      setDrafts([
        ...resp.data.checkemBuilderFolderData.pdfs,
        ...resp.data.checkemBuilderFolderData.drafts,
      ]);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  const updateCurrentPage = () => {
    if (!params?.id) return;
    const maxPage = Math.ceil(allDraftsCount);
    let skip = fetchedCount;
    if (drafts.length === 1 && fetchedCount >= PAGE_LIMIT) {
      skip = skip - PAGE_LIMIT;
    }
    if (skip > maxPage) return;
    setLoading(true);
    let take = PAGE_LIMIT;
    if (allDraftsCount - skip < PAGE_LIMIT) {
      take = allDraftsCount - skip;
    }

    const countPdfInput = {
      folderId: +params?.id,
    };

    const countDraftInput = {
      folderId: +params?.id,
    };

    const input = {
      folderId: +params?.id,
      skip: skip,
      take: PAGE_LIMIT,
    };
    if (!!searchValue) {
      Object.assign(countPdfInput, { search: searchValue });
      Object.assign(countDraftInput, { search: searchValue });

      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countPdfInput, input, countDraftInput },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllDraftsCount(resp.data.countFolderPdf + resp.data.countFolderDraft);
      setDrafts([
        ...resp.data.checkemBuilderFolderData.pdfs,
        ...resp.data.checkemBuilderFolderData.drafts,
      ]);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  const goBack = () => {
    window.history.state && window.history.state.idx > 0
      ? navigate(-1)
      : navigate("/dashboard/lists");
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source, type } = result;

    if (!destination) return;

    const isFolderMooving = source.droppableId !== "ITEMS";

    let folder = folders.find((item) => item.id === +destination.droppableId);
    let item = isFolderMooving ? folders[source.index] : drafts[source.index];

    if (isFolderMooving && folder?.id === item.id) {
      return;
    }

    const input = {};

    isFolderMooving
      ? Object.assign(input, {
          childFolderId: item.id,
          parentFolderId: folder?.id,
        })
      : item.type === "Pdf"
      ? Object.assign(input, { pdfId: item.id, folderId: folder?.id })
      : Object.assign(input, { draftId: item.id, folderId: folder?.id });

    const movingFunc = isFolderMooving
      ? moveFolderToFolder
      : item.type === "Pdf"
      ? movePdfToFolder
      : moveDraftToFolder;

    alertVar({
      text: "The PDF is moved to a folder",
      type: "blue",
      isLoading: true,
    });

    movingFunc({
      variables: { input },
    })
      .then(() => {
        isFolderMooving ? loadData() : updateCurrentPage();
        alertVar({
          text: `The ${
            isFolderMooving ? "folder" : item.type === "Pdf" ? "PDF" : "draft"
          } has been successfully moved to the folder`,
          type: "green",
        });
      })
      .catch((err) => {
        alertVar({
          text: err.graphQLErrors[0].message,
          type: "red",
        });
      });
  };

  useEffect(() => {
    loadData();
    getFolderData();
  }, [params?.id]);

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      loadData();
    }, 650);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    drafts,
    searchValue,
    viewType,
    draftsCount: allDraftsCount,
    fetchedCount,
    loading,
    paginationLoading,
    PAGE_LIMIT,
    folderId: !!params?.id ? +params?.id : undefined,
    folderTitle,
    folders,
    showLoader: () => setLoading(true),

    showTableLoader: () => setLoading(true),
    goBack,
    loadData,
    setSearchValue,
    setViewType,
    handleNextPage,
    handlePrevPage,
    updateDraftStatus,
    onDragEnd,
  };
};
