import { useBuilder } from "../../../../context";
import { IItem } from "../../../../constants";
import { regex } from "~constants";
interface IProps {
  item: IItem;
  itemIndex: number;
}

export const useTimer = ({ item, itemIndex }: IProps) => {
  const { setList, activeTabIndex, activeItem } = useBuilder();

  const toggleRecordTime = () => {
    if (!activeItem) return;
    let data = { ...item.data, record: !item.data.record };
    setList((prev) => {
      let state = { ...prev };
      if (!activeItem) return state;
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;

      return state;
    });
  };

  const handleNotificationText = (value: string) => {
    if (!activeItem) return;
    let data = { ...item.data, text: value };
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleSoundName = (value: string) => {
    if (!activeItem) return;
    let data = { ...item.data, sound: value };
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleMinutes = (value: string) => {
    if (
      (regex.onlyNumbers.test(value) || value === "") &&
      !(value.length > 1 && value[0] === "0") &&
      !!activeItem
    ) {
      let data = { ...item.data, minutes: value };
      setList((prev) => {
        let state = { ...prev };
        state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
          itemIndex
        ].data = data;
        return state;
      });
    }
  };

  const handleSeconds = (value: string) => {
    if (
      (regex.onlyNumbers.test(value) || value === "") &&
      !(value.length > 1 && value[0] === "0") &&
      !!activeItem
    ) {
      let data = { ...item.data, seconds: value };
      setList((prev) => {
        let state = { ...prev };
        state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
          itemIndex
        ].data = data;
        return state;
      });
    }
  };

  return {
    handleNotificationText,
    handleSoundName,
    handleMinutes,
    handleSeconds,
    toggleRecordTime,
  };
};
