import { PageHeader, Pagination, SearchInput, TableLoader } from "~components";
import { useAllOrganization } from "./useAllOrganization";
import { OrganizationItem } from "./components";
import { IOrganization } from "~models";

import styles from "./styles.module.scss";
import { useHomePage } from "../AllOrganizations/useHomePage";
import { ActiveListsModule } from "../../../../navigations/Dashboard/LeftPanel/modules";

export const AllOrganization = () => {
  const {
    organizations,
    organizationsCount,
    handleNextPage,
    handlePrevPage,
    fetchedCount,
    loading,
    searchValue,
    setSearchValue,
    handleChangeRequestsField,
    updateOrganization,
  } = useAllOrganization();

  const { pageHeader } = useHomePage();

  return (
    <>
      <div className={styles.allOrganization__search}>
        <SearchInput
          placeholder="Search for companies"
          value={searchValue}
          onChange={setSearchValue}
        />
        <ActiveListsModule noLabel />
      </div>
      <div style={{ marginBottom: 20 }}>
        <PageHeader data={pageHeader} />
      </div>
      {loading ? (
        <TableLoader />
      ) : (
        <>
          <div className={styles.allOrganization}>
            {organizations.map((item: IOrganization, index: number) => (
              <OrganizationItem
                key={`${item.id} - ${index}`}
                item={item}
                index={index}
                handleChangeRequestsField={handleChangeRequestsField}
                updateOrganization={updateOrganization}
              />
            ))}
          </div>
        </>
      )}
      <Pagination
        allResults={organizationsCount}
        fetchedResult={fetchedCount + 10}
        limit={10}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
      />
    </>
  );
};
