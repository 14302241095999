import { OnboardingLayout } from "~layouts";
import { Button, Input } from "~components";
import styles from "./styles.module.scss";
import { useSignIn } from "./hooks/useSignIn";
import { useNavigate } from "react-router-dom";

export const SignInPage = () => {
  const navigate = useNavigate();
  const {
    data,
    mainLoading,
    onChangeData,
    disableButton,
    onSubmit,
    error,
  } = useSignIn();

  const navigateToSignUp = () => navigate("/signUp");
  const navigateToResetPassword = () => navigate("/resetPassword");

  return (
    <OnboardingLayout>
      <div className={styles.signIn}>
        <div className={styles.signIn__header}>Sign In to Checkem</div>
        {/* 
        <table style={{ width: "100%" }}>
          <tbody>
            <td>
              <tr></tr>
            </td>
          </tbody>
        </table> */}

        
        <Input
          label="Email address"
          value={data.email}
          onChange={(value) => onChangeData(value, "email")}
          placeholder={"Enter your email address..."}
          error={error.email}
        />

        <div className={styles.signIn__password}>
          <Input
            label="Password"
            value={data.password}
            onChange={(value) => onChangeData(value, "password")}
            placeholder={"Enter your password..."}
            type={"secure"}
            error={error.password}
          />
          <div className={styles.signIn__forgotPassword}>
            <div onClick={navigateToResetPassword}>Forgot Password?</div>
          </div>
        </div>

        <div className={styles.signIn__button}>
          <Button
            disable={disableButton}
            loading={mainLoading}
            onClick={onSubmit}
            text="Sign In"
            backgroundType={"green"}
          />
        </div>

        <p>
          No Account? <span onClick={navigateToSignUp}>Sign Up</span>
        </p>
      </div>
    </OnboardingLayout>
  );
};
