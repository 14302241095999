import { useMutation, useQuery } from "@apollo/client";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PopupActions } from "reactjs-popup/dist/types";
import { useOutsideClick } from "~hooks";
import { choosedOrganizationVar, GET_CHOOSED_ORGANIZATIOM } from "~localCache";
import { IOrganization } from "~models";
import { LEAVE_ORGANIZATION } from "~mutations";
import {
  GET_MY_INVITATIONS,
  GET_MY_ORGANIZATIONS,
  GET_MY_ORGANIZATION_REQUESTS,
} from "~queries";
import {
  clearChoosedOrganization,
  client,
  setChoosedOrganization,
} from "~services";

interface IProps {
  handleOrganizationModalOpen: () => void;
}

export const useOrganization = ({ handleOrganizationModalOpen }: IProps) => {
  const navigate = useNavigate();
  const popupRef = useRef<PopupActions>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const [showInviteModal, setShowInviteModal] = useState(false);

  const {
    data: { myOrganizations },
  } = useQuery(GET_MY_ORGANIZATIONS);

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const { data: invitationsData } = useQuery(GET_MY_INVITATIONS, {
    variables: { input: {} },
  });

  const { data: requestsData } = useQuery(GET_MY_ORGANIZATION_REQUESTS);

  const handleModalClose = () => popupRef.current?.close();
  const handleModalOpen = () => popupRef.current?.open();

  const handleInviteModalOpen = () => {
    handleModalClose();
    setShowInviteModal(true);
  };
  const handleInviteModalClose = () => setShowInviteModal(false);

  const handleChooseOrganization = (data: IOrganization) => {
    choosedOrganizationVar(data);
    setChoosedOrganization(data);
  };

  const handleCreateOrgnization = () => {
    handleModalClose();
    handleOrganizationModalOpen();
  };

  useOutsideClick(modalRef, handleModalClose);

  // const removeOrganizationFromCache = (item: IOrganization) => {
  //   const normalizedId = client.cache.identify({
  //     id: item.id,
  //     __typename: "Organization",
  //   });
  //   client.cache.evict({ id: normalizedId });
  //   client.cache.gc();
  // };

  // const leaveOrganization = (item: IOrganization) => {
  //   if (!item?.members) return;
  //   if (myOrganizations.length === 1) {
  //     navigate("/dashboard/home");
  //     clearChoosedOrganization();
  //     choosedOrganizationVar(null);
  //   } else {
  //     const index = myOrganizations.findIndex(
  //       (organization: IOrganization) => organization.id === item.id
  //     );

  //     if (index > 0) {
  //       choosedOrganizationVar(myOrganizations[0]);
  //       setChoosedOrganization(myOrganizations[0]);
  //     } else {
  //       choosedOrganizationVar(myOrganizations[1]);
  //       setChoosedOrganization(myOrganizations[1]);
  //     }
  //   }
  //   removeOrganizationFromCache(item);
  //   mutationLeaveOrganization({ variables: { id: item?.members[0].id } });
  // };

  // const membersCount = choosedOrganization.counters.members + choosedOrganization.counters

  return {
    popupRef,
    modalRef,
    myOrganizations,
    choosedOrganization,
    showInviteModal,
    handleModalOpen,
    handleModalClose,
    handleCreateOrgnization,
    handleChooseOrganization,
    handleInviteModalOpen,
    handleInviteModalClose,
    invitionsCount: !!invitationsData?.organizationInvitations
      ? invitationsData?.organizationInvitations.length
      : 0,
    requestsCount: !!requestsData?.myOrganizationRequests
      ? requestsData?.myOrganizationRequests.length
      : 0,
  };
};
