import { alertVar, choosedOrganizationVar } from "~localCache";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_ADMIN_PDF, CREATE_PDF, MOVE_PDF_TO_FOLDER } from "~mutations";
import { uploadFile } from "~services";

interface IProps {
  folderId?: number;
  isAdmin?: boolean;
  onCloseModal: () => void;
  handleSuccessResult: () => void;
}

export const useUploadPdfModal = ({
  folderId,
  isAdmin,
  onCloseModal,
  handleSuccessResult,
}: IProps) => {
  const choosedOrganization = choosedOrganizationVar();
  const [createPdf] = useMutation(CREATE_PDF);
  const [createAdminPdf] = useMutation(CREATE_ADMIN_PDF);

  const [movePdf] = useMutation(MOVE_PDF_TO_FOLDER);

  const [title, setTitle] = useState("");
  const [file, setFile] = useState<any>(null);

  const handleFileChange = (value: any) => setFile(value);

  const [showLoader, setShowLoader] = useState(false);

  const handleTitleChange = (value: string) => setTitle(value);
  const handleModalClose = () => {
    !!file && setFile(null);
    !!title && setTitle("");
    onCloseModal();
  };

  const onDrop = async (files: any) => {
    if (files.length > 0) {
      setFile(files[0]);
    } else {
      alertVar({ type: "red", text: "This file is not a pdf file" });
    }
  };

  const movePdfToFolder = (id: number) => {
    movePdf({ variables: { input: { pdfId: id, folderId: folderId } } }).then(
      (resp) => {
        setShowLoader(false);
        alertVar({ text: "File uploaded successfully", type: "green" });
        handleSuccessResult();
        handleModalClose();
      }
    );
  };

  const uploadPdf = async () => {
    setShowLoader(true);
    const url = await uploadFile(file, file.type);

    let input = {
      title,
      file: url,
    };

    !isAdmin &&
      Object.assign(input, { organizationId: choosedOrganization?.id });

    !!folderId && Object.assign(input, { folderId });

    let createFunc = isAdmin ? createAdminPdf : createPdf;
    createFunc({
      variables: {
        input,
      },
    }).then((resp) => {
      // if (!!folderId) {
      //   movePdfToFolder(resp.data.createPdf.id);
      //   return;
      // }

      setShowLoader(false);
      alertVar({ text: "File uploaded successfully", type: "green" });
      handleSuccessResult();
      handleModalClose();
    });
  };

  const disableUploadButton = !title || !file;

  return {
    showLoader,
    file,
    title,
    disableUploadButton,
    onDrop,
    handleTitleChange,
    handleFileChange,
    handleModalClose,
    uploadPdf,
  };
};
