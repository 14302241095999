import { StrictModifierNames, Modifier } from "react-popper";
import { DotsVertical, Folder, FolderPlusAdd, TrashAlt } from "~icons";
import { Options, createPopper } from "@popperjs/core";
import { useFolder } from "../../useFolder";
import { DeleteModal } from "../../modals";
import { PopperPopup } from "~components";
import { IFolder } from "~models";

import styles from "./styles.module.scss";
import clsx from "clsx";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";

interface IProps {
  folder: IFolder;
  index: number;
  hideActions?: boolean;
  handleView?: boolean;
  handleImport?: boolean;
  isDraggingOver?: boolean;
  showTableLoader?: () => void;
  updateTableData?: () => void;
}

export const FolderItemSquare = ({
  folder,
  handleView,
  hideActions,
  handleImport,
  isDraggingOver,
  showTableLoader,
  updateTableData,
}: IProps) => {
  const {
    showPopper,
    showDeletionModal,
    handleDelete,
    handleFolderClick,
    handleOpenPopper,
    handleClosePopper,
    handleOpenDeletionModal,
    handleCloseDeletionModal,
  } = useFolder({
    folder,
    handleView,
    handleImport,
    showTableLoader,
    updateTableData,
  });

  const popperOptions: Omit<Partial<Options>, "modifiers"> & {
    createPopper?: typeof createPopper;
    modifiers?: ReadonlyArray<Modifier<StrictModifierNames>>;
  } = {
    placement: "bottom-end",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: null,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, -20],
        },
      },
    ],
  };

  const isDark = useReactiveVar(darkTheme);

  return (
    <div onClick={handleFolderClick}>
      <div
        className={
          isDark
            ? clsx(styles.folderItemSquare, styles.folderItemSquareDark, {
                [styles.folderItemSquare__active]: isDraggingOver,
              })
            : clsx(styles.folderItemSquare, {
                [styles.folderItemSquare__active]: isDraggingOver,
              })
        }
      >
        <div
          className={
            isDark
              ? clsx(
                  styles.folderItemSquare__left,
                  styles.folderItemSquareDark__left
                )
              : styles.folderItemSquare__left
          }
        >
          {isDraggingOver ? <FolderPlusAdd /> : <Folder />}
        </div>
        <div className={styles.folderItemSquare__center}>
          <p className={styles.folderItemSquare__centerTitle}>{folder.title}</p>
          {/* <p className={styles.folderItemSquare__centerCount}>Main Cheklists</p> */}
        </div>
        {!hideActions && (
          <PopperPopup
            showPopper={showPopper}
            onClosePopper={handleClosePopper}
            onOpenPopper={handleOpenPopper}
            options={popperOptions}
            trigger={<DotsVertical fill={isDark ? "white" : "#0B0F1C"} />}
            popup={
              <div
                onClick={(e) => e.stopPropagation()}
                className={styles.folderItemSquare__modal}
              >
                <div
                  onClick={handleOpenDeletionModal}
                  className={styles.folderItemSquare__modalItem}
                >
                  <TrashAlt fill={isDark ? "white" : "#0B0F1C"} /> Delete
                </div>
              </div>
            }
          />
        )}
      </div>

      <DeleteModal
        isOpen={showDeletionModal}
        itemName="folder"
        onCloseModal={handleCloseDeletionModal}
        handleDelete={handleDelete}
      />
    </div>
  );
};
