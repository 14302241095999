import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOutsideClick } from "~hooks";
import { choosedOrganizationVar } from "~localCache";
import { IOrganization } from "~models";
import { LEAVE_ORGANIZATION } from "~mutations";
import { GET_MY_ORGANIZATIONS } from "~queries";
import {
  clearChoosedOrganization,
  client,
  setChoosedOrganization,
} from "~services";

interface IProps {
  item: IOrganization;
}

export const useOrganizationItem = ({ item }: IProps) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [mutationLeaveOrganization] = useMutation(LEAVE_ORGANIZATION);
  const {
    data: { myOrganizations },
  } = useQuery(GET_MY_ORGANIZATIONS);

  const [updateMyOrganizations] = useLazyQuery(GET_MY_ORGANIZATIONS);

  const [showLeaveOwnerModal, setShowLeaveOwnerModal] = useState(false);

  const [showPopper, setShowPopper] = useState(false);

  const handleOpenPopper = () => setShowPopper(true);
  const handleClosePopper = () => setShowPopper(false);

  const handleCloseLeaveOwnerModal = () => setShowLeaveOwnerModal(false);

  const removeOrganizationFromCache = () => {
    const normalizedId = client.cache.identify({
      id: item.id,
      __typename: "Organization",
    });
    client.cache.evict({ id: normalizedId });
    client.cache.gc();
  };

  const leaveOrganization = (fetchRequest?: boolean) => {
    if (!item?.members) return;
    if (myOrganizations.length === 1) {
      navigate("/dashboard/common");
      clearChoosedOrganization();
      choosedOrganizationVar(null);
    } else {
      const index = myOrganizations.findIndex(
        (organization: IOrganization) => organization.id === item.id
      );

      if (index > 0) {
        choosedOrganizationVar(myOrganizations[0]);
        setChoosedOrganization(myOrganizations[0]);
      } else {
        choosedOrganizationVar(myOrganizations[1]);
        setChoosedOrganization(myOrganizations[1]);
      }
    }
    removeOrganizationFromCache();
    if (!!fetchRequest) {
      mutationLeaveOrganization({
        variables: { id: item?.members[0].id },
      }).then(async () => {
        await updateMyOrganizations({
          variables: {},
          fetchPolicy: "network-only",
        });
      });
    }
  };

  const handleLeaveButton = () => {
    if (!!item.members && item.members[0].role === "Owner") {
      setShowLeaveOwnerModal(true);
    } else leaveOrganization(true);
  };

  return {
    popupRef,
    showPopper,
    showLeaveOwnerModal,
    leaveOrganization,
    handleCloseLeaveOwnerModal,
    handleLeaveButton,
    handleOpenPopper,
    handleClosePopper,
  };
};
