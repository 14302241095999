import React from "react";
import clsx from "clsx";
import { useRef, useState } from "react";
import { useOutsideClick } from "~hooks";
import { DotsVertical } from "~icons";
import { IOrganization } from "~models";

import styles from "./styles.module.scss";
import { Avatar } from "~components";

interface IProps {
  item: IOrganization;
  index: number;
  handleChooseOrganization: (value: IOrganization) => void;
  choosedOrganizationId: string | undefined;
}

export const Item = ({
  item,
  index,
  choosedOrganizationId,
  handleChooseOrganization,
}: IProps) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const isChoosed = choosedOrganizationId === item.id;
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setShowPopup(true);
  };

  return (
    <div
      ref={popupRef}
      onClick={(e) => {
        e.stopPropagation();
        handleChooseOrganization(item);
      }}
      key={`${item.id} - ${index}`}
      className={styles.organizationItem}
    >
      {isChoosed && <div className={styles.organizationItem__indicator} />}
      {/* <div className={styles.organizationItem__icon}>
        {!!item.picture && <img src={item.picture} />}
      </div> */}
      <Avatar
        containerStyles={styles.organizationItem__icon}
        uri={item?.picture}
        item={item}
        type="organization"
      />
      <div className={styles.organizationItem__center}>
        <p className={styles.organizationItem__title}>{item.name}</p>
        <p className={styles.organizationItem__members}>
          {item.counters.members + 1} members
        </p>
      </div>
    </div>
  );
};
