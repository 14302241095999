import { useActiveChecklists } from "./useActiveChecklists";
import { GridView, Loader, TableView } from "~icons";
import { DashboardLayout } from "~layouts";
import {
  ChecklistItemImport,
  ChecklistItemImportSquare,
  FolderItem,
  FolderItemSquare,
  PDFItemImport,
  PDFItemImportSquare,
  Pagination,
  SearchInput,
  Table,
  GridWrapper,
  TableLoader,
  SearchBar,
} from "~components";

import styles from "./styles.module.scss";

export const Library = () => {
  const {
    folders,
    loading,
    paginationLoading,
    viewType,
    PAGE_LIMIT,
    checklists,
    draftsCount,
    searchValue,
    fetchedCount,
    setViewType,
    handleNextPage,
    handlePrevPage,
    setSearchValue,
  } = useActiveChecklists();

  return (
    <DashboardLayout title="Library">
      <>
        <SearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          viewType={viewType}
          setViewType={setViewType}
        />

        {loading && <TableLoader />}

        {console.log("1111111")}

        {!loading && viewType === "table" && (
          <Table
            folders={folders}
            loading={paginationLoading}
            handleLoad={handleNextPage}
            header={[
              { header: "Name", className: styles.library__name },
              {
                header: "Last modified",
                className: styles.library__date,
              },
              { header: "", className: styles.library__author },
            ]}
            data={checklists}
            renderFolderItem={(folder, index) => {
              return (
                <FolderItem
                  handleImport
                  hideActions
                  folder={folder}
                  index={index}
                />
              );
            }}
            renderTableItem={(item, index) => {
              return item.type === "Pdf" ? (
                <PDFItemImport
                  pdf={item}
                  index={index}
                  key={`${item} - ${index}`}
                />
              ) : (
                <ChecklistItemImport
                  checklist={item}
                  key={`${item} - ${index}`}
                />
              );
            }}
          />
        )}

        {!loading && viewType === "grid" && (
          <GridWrapper
            folders={folders}
            data={checklists}
            loading={paginationLoading}
            handleLoad={handleNextPage}
            renderFolderItem={(folder, index) => {
              return (
                <FolderItemSquare
                  handleImport
                  folder={folder}
                  index={index}
                  hideActions
                />
              );
            }}
            renderItem={(item, index) => {
              return item.type === "Pdf" ? (
                <PDFItemImportSquare
                  pdf={item}
                  index={index}
                  key={`${item} - ${index}`}
                />
              ) : (
                <ChecklistItemImportSquare
                  checklist={item}
                  key={`${item} - ${index}`}
                />
              );
            }}
          />
        )}

        {paginationLoading && (
          <div className={styles.paginationLoader}>
            <Loader fill="#616A85" />
          </div>
        )}

        <div className={styles.pagination}>
          <div className={styles.pagination__left}>
            Showing{" "}
            <span>
              {fetchedCount + PAGE_LIMIT > draftsCount
                ? draftsCount
                : !!draftsCount && !!(fetchedCount + PAGE_LIMIT)
                ? fetchedCount + PAGE_LIMIT
                : 0}
            </span>{" "}
            of <span>{draftsCount}</span> Results
          </div>
        </div>

        {/* <Pagination
          allResults={draftsCount}
          fetchedResult={fetchedCount + PAGE_LIMIT}
          limit={PAGE_LIMIT}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
        /> */}
      </>
    </DashboardLayout>
  );
};
