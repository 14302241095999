import { useMutation, useQuery } from "@apollo/client";
import {
  useMembersSubscription,
  useRequestsSubscriptions,
} from "../../../subscriptions";
import {
  GET_CHOOSED_ORGANIZATIOM,
  GET_SHOW_PANEL,
  showPanelVar,
} from "~localCache";
import { GET_CURRENT_USER, GET_ORGANIZATION_REQUESTS } from "~queries";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAccessRole } from "~models";
import { CREATE_CHAT } from "~mutations";

export const useLeftPanel = () => {
  const { data: userData } = useQuery(GET_CURRENT_USER);

  const [createChat] = useMutation(CREATE_CHAT);

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const {
    data: { showPanel },
  } = useQuery(GET_SHOW_PANEL);

  const { data: requestsData, error } = useQuery(GET_ORGANIZATION_REQUESTS, {
    variables: {
      input: {
        organizationId: choosedOrganization?.id,
      },
    },
    skip: !choosedOrganization?.id,
  });

  const navigate = useNavigate();

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const handleOpenFeedbackModal = () => setShowFeedbackModal(true);
  const handleCloseFeedbackModal = () => setShowFeedbackModal(false);

  useRequestsSubscriptions();
  useMembersSubscription();
  // useNewNotification();

  const onLinkClick = () => showPanel && showPanelVar(false);

  const handleAvatarClick = () =>
    !!choosedOrganization
      ? navigate("/dashboard/home/active")
      : navigate("/dashboard/common/allOrganizations");

  const handleCreateChatAI = async () => {
    createChat({ variables: { input: { title: "Some default title" } } }).then(
      (resp) => {
        navigate(`/chat/${resp.data.createChat.id}`);
      }
    );
  };

  return {
    showFeedbackModal,
    requestsCount: !!requestsData?.organizationRequests
      ? requestsData.organizationRequests.length
      : 0,
    currentUser: userData.currentUser,
    role: !!userData?.currentUser
      ? userData.currentUser?.role
      : UserAccessRole.User,
    choosedOrganization,
    onLinkClick,
    handleAvatarClick,
    handleOpenFeedbackModal,
    handleCloseFeedbackModal,
    handleCreateChatAI,
  };
};
