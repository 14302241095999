import { useAdminsManagement } from "./useAdminsManagement";
import {
  Button,
  Modal,
  Pagination,
  SearchInput,
  Table,
  TableLoader,
} from "~components";
import { DashboardLayout } from "~layouts";
import { PlusSymbol } from "~icons";

import styles from "./styles.module.scss";
import { IOrganization } from "~models";
import { AdminItem } from "./AdminItem";
import { CreateUserModal } from "~modals";

export const AdminsManagement = () => {
  const {
    members,
    loadingMembers,
    allMembersCount,
    membersCount,
    handleNextPage,
    handlePrevPage,
    fetchedCount,
    loading,
    searchValue,
    setSearchValue,
    handleChangeRole,
    handleCreateUser,
    limit: PAGE_LIMIT,
    choosedOrganization,
    deleteMember,
    showCreateUserModal,
    handleOpenCreateUserModal,
    handleCloseCreateUserModal,
    handleDeleteAdmin,
  } = useAdminsManagement();
  return (
    <DashboardLayout
      title="All Admins"
      rightItem={
        <div
          onClick={handleOpenCreateUserModal}
          className={styles.adminsManagement__createButton}
        >
          <PlusSymbol fill="#FFFFFF" />
          <p />
        </div>
      }
    >
      <div className={styles.adminsManagement}>
        <div className={styles.adminsManagement__search}>
          <SearchInput
            placeholder="Search for companies"
            value={searchValue}
            onChange={setSearchValue}
          />
        </div>
        {loading ? (
          <TableLoader />
        ) : (
          <Table
            header={[
              {
                header: "User",
                className: styles.adminsManagement__user,
              },
              {
                header: "Organizations",
                className: styles.adminsManagement__organizations,
              },
              {
                header: "",
                className: styles.adminsManagement__date,
              },
            ]}
            data={members}
            renderTableItem={(item, index) => (
              <AdminItem
                key={`${item} - ${index}`}
                user={item}
                index={index}
                handleDeleteAdmin={handleDeleteAdmin}
              />
            )}
          />
        )}
        <Pagination
          allResults={allMembersCount}
          fetchedResult={fetchedCount + 10}
          limit={10}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
        />

        <CreateUserModal
          isOpen={showCreateUserModal}
          onClose={handleCloseCreateUserModal}
          handleCreateUser={handleCreateUser}
        />
      </div>
    </DashboardLayout>
  );
};
