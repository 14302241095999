import {
  Pagination,
  SearchBar,
  SearchInput,
  Table,
  TableLoader,
} from "~components";
import { useCompletedChecklists } from "./useCompletedChecklists";
import { ProgressItem, ProgressItemSquare } from "./components";
import { DeleteChecklistModal } from "./modals";
import { GridView, Loader, TableView } from "~icons";

import styles from "./styles.module.scss";
import { DashboardLayout } from "~layouts";
import { CompletedGridWrapper } from "../UserCompletedChecklists/components/CompletedGridWrapper/CompletedGridWrapper";

export const CompletedCheckems = () => {
  const {
    checklists,
    loading,
    paginationLoading,
    draftsCount,
    fetchedCount,
    searchValue,
    PAGE_LIMIT,
    checklistDeletionId,
    viewType,
    handleContinue,
    deleteProgress,
    setViewType,
    handleNextPage,
    handlePrevPage,
    setSearchValue,
    handleCloseDeleteModal,
    handleOpenDeleteModal,
  } = useCompletedChecklists();

  return (
    <>
      <DashboardLayout title="Completed Checkems">
        <SearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          viewType={viewType}
          setViewType={setViewType}
        />

        {loading && <TableLoader />}

        {!loading && viewType === "table" && (
          <Table
            header={[
              { header: "Name", className: styles.completedChecklists__name },
              {
                header: "Member",
                className: styles.completedChecklists__member,
              },
              {
                header: "Date started",
                className: styles.completedChecklists__date,
              },
            ]}
            data={checklists}
            loading={paginationLoading}
            handleLoad={handleNextPage}
            renderTableItem={(item, index) => (
              <ProgressItem
                handleContinue={() =>
                  handleContinue(item.checklist.id, item.id)
                }
                activeChecklist={item}
                key={`${item} - ${index}`}
                index={index}
                deleteProgress={handleOpenDeleteModal}
              />
            )}
          />
        )}

        {!loading && viewType === "grid" && (
          <CompletedGridWrapper
            checklists={checklists}
            handleContinue={handleContinue}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleLoad={handleNextPage}
            loading={paginationLoading}
          />
          // <div className={styles.completedChecklists__gridWrapper}>
          //   <div className={styles.completedChecklists__grid}>
          //     {checklists.map((item, index) => (
          //       <ProgressItemSquare
          //         handleContinue={() =>
          //           handleContinue(item.checklist.id, item.id)
          //         }
          //         activeChecklist={item}
          //         key={`${item} - ${index}`}
          //         index={index}
          //         deleteProgress={handleOpenDeleteModal}
          //       />
          //     ))}
          //   </div>
          // </div>
        )}

        {paginationLoading && (
          <div className={styles.paginationLoader}>
            <Loader fill="#616A85" />
          </div>
        )}
        <div className={styles.pagination}>
          <div className={styles.pagination__left}>
            Showing{" "}
            <span>
              {fetchedCount + PAGE_LIMIT > draftsCount
                ? draftsCount
                : !!draftsCount && !!(fetchedCount + PAGE_LIMIT)
                ? fetchedCount + PAGE_LIMIT
                : 0}
            </span>{" "}
            of <span>{draftsCount}</span> Results
          </div>
        </div>

        {/* <Pagination
          allResults={draftsCount}
          fetchedResult={fetchedCount + PAGE_LIMIT}
          limit={PAGE_LIMIT}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
        /> */}

        <DeleteChecklistModal
          checklistDeletionId={checklistDeletionId}
          onCloseModal={handleCloseDeleteModal}
          handleDeleteChecklist={deleteProgress}
        />
      </DashboardLayout>
    </>
  );
};
