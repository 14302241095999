import styles from "./styles.module.scss";

import { IItem } from "~models";
import { useMetronome } from "./useMetronome";
import clsx from "clsx";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const Metronome = ({ item, isDisable, progressItem }: IProps) => {
  const { handleClick, isMetronomeActive, activeMetronome } = useMetronome({
    isDisable,
    item,
    progressItem,
  });

  const isDark = useReactiveVar(darkTheme);
  return (
    <div className={styles.metronome}>
      {item.data.type === "CPR" ? (
        <div className={styles.metronome__cpr}>
          <div
            className={
              isDark
                ? clsx(styles.metronome__cprRow, styles.metronome__cprRowDark)
                : styles.metronome__cprRow
            }
          >
            <div
              className={clsx(styles.metronome__cprButton, {
                [styles.disable]: isDisable,
                [styles.active]:
                  isMetronomeActive && activeMetronome?.key === "110bmp",
              })}
              onClick={(e) => handleClick(e, "110bmp")}
            >
              Continuous
            </div>
            <div
              className={clsx(styles.metronome__cprButton, {
                [styles.disable]: isDisable,
                [styles.active]:
                  isMetronomeActive && activeMetronome?.key === "ventilation",
              })}
              onClick={(e) => handleClick(e, "ventilation")}
            >
              Continuous+10 BPM Ventilations
            </div>
          </div>
          <div
            className={
              isDark
                ? clsx(styles.metronome__cprRow, styles.metronome__cprRowDark)
                : styles.metronome__cprRow
            }
          >
            <div
              className={clsx(styles.metronome__cprButton, {
                [styles.disable]: isDisable,
                [styles.active]:
                  isMetronomeActive && activeMetronome?.key === "30_2bmp",
              })}
              onClick={(e) => handleClick(e, "30_2bmp")}
            >
              30:2
            </div>
            <div
              className={clsx(styles.metronome__cprButton, {
                [styles.disable]: isDisable,
                [styles.active]:
                  isMetronomeActive && activeMetronome?.key === "15_2bmp",
              })}
              onClick={(e) => handleClick(e, "15_2bmp")}
            >
              15:2
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.metronome__ventilations}>
          <div className={styles.metronome__ventilationsColumn}>
            <div
              className={clsx(styles.metronome__ventilationsButton, {
                [styles.disable]: isDisable,
                [styles.active]:
                  isMetronomeActive && activeMetronome?.key === "8bmp",
              })}
              onClick={(e) => handleClick(e, "8bmp")}
            >
              8 BPM
            </div>
            <div
              className={clsx(styles.metronome__ventilationsButton, {
                [styles.disable]: isDisable,
                [styles.active]:
                  isMetronomeActive && activeMetronome?.key === "12bmp",
              })}
              onClick={(e) => handleClick(e, "12bmp")}
            >
              12 BPM
            </div>
            <div
              className={clsx(styles.metronome__ventilationsButton, {
                [styles.disable]: isDisable,
                [styles.active]:
                  isMetronomeActive && activeMetronome?.key === "16bmp",
              })}
              onClick={(e) => handleClick(e, "16bmp")}
            >
              16 BPM
            </div>
          </div>
          <div className={styles.metronome__ventilationsColumn}>
            <div
              className={clsx(styles.metronome__ventilationsButton, {
                [styles.disable]: isDisable,
                [styles.active]:
                  isMetronomeActive && activeMetronome?.key === "10bmp",
              })}
              onClick={(e) => handleClick(e, "10bmp")}
            >
              10 BPM
            </div>
          </div>
          <div className={styles.metronome__ventilationsColumn}>
            <div
              className={clsx(styles.metronome__ventilationsButton, {
                [styles.disable]: isDisable,
                [styles.active]:
                  isMetronomeActive && activeMetronome?.key === "20bmp",
              })}
              onClick={(e) => handleClick(e, "20bmp")}
            >
              20 BPM
            </div>
            <div
              className={clsx(styles.metronome__ventilationsButton, {
                [styles.disable]: isDisable,
                [styles.active]:
                  isMetronomeActive && activeMetronome?.key === "25bmp",
              })}
              onClick={(e) => handleClick(e, "25bmp")}
            >
              25 BPM
            </div>
            <div
              className={clsx(styles.metronome__ventilationsButton, {
                [styles.disable]: isDisable,
                [styles.active]:
                  isMetronomeActive && activeMetronome?.key === "30bmp",
              })}
              onClick={(e) => handleClick(e, "30bmp")}
            >
              30 BPM
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
