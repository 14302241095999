import { CheckSquareBroken, EmptyPlay, File } from "~icons";
import { useChecklist } from "../../useChecklist";
import { Button } from "~components";
import { IDraft } from "~models";

import styles from "./styles.module.scss";

interface IProps {
  checklist: IDraft;
}

export const ChecklistItem = ({ checklist }: IProps) => {
  const { showLoader, handleStart } = useChecklist({ checklist });
  return (
    <>
      <div className={styles.activeChecklist}>
        <div className={styles.activeChecklist__name}>
          <CheckSquareBroken stroke="#29B473" />
          {checklist.title}
        </div>

        <div>
          <Button
            loading={showLoader}
            onClick={handleStart}
            text={`Start`}
            backgroundType={"green"}
            icon={<EmptyPlay stroke="white" />}
          />
        </div>
      </div>

      <div className={styles.activeChecklist__underline} />
    </>
  );
};
