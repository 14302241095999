import { useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { AuthCodeRef } from "react-auth-code-input";
import { COMPLETE_UPDATE_EMAIL, RESEND_VERIFY } from "~mutations";

interface IProps {
  handleCloseModal: () => void;
  email: string;
}

export const useConfirmationModal = ({ email, handleCloseModal }: IProps) => {
  const inputRef = useRef<AuthCodeRef>(null);
  const [seconds, setSeconds] = useState(60);

  const [completeUpdateEmail, { loading }] = useMutation(COMPLETE_UPDATE_EMAIL);
  const [mutateResendVerify] = useMutation(RESEND_VERIFY);

  const [code, setCode] = useState<string>("");
  const [showError, setShowError] = useState(false);

  const resendCode = () => {
    if (seconds > 0) return;
    setSeconds(60);
    !!showError && setShowError(false);
    inputRef.current?.clear();
    inputRef.current?.focus();
    mutateResendVerify({
      variables: { resendVerifyInput: { email } },
    });
  };

  const onChangeCode = (value: string) => {
    setCode(value);
    showError && setShowError(false);
    if (value.length === 6) {
      completeUpdateEmail({
        variables: {
          input: { email, code: +value },
        },
      })
        .then(() => {
          handleCloseModal();
        })
        .catch((err) => {
          setShowError(true);
        });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        return;
      }
      if (seconds === 0) clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  });

  return {
    inputRef,
    loading,
    seconds,
    showError,
    resendCode,
    onChangeCode,
  };
};
