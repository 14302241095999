import styles from "./styles.module.scss";

import { IItem } from "~models";
import { useSelector } from "./useSelector";
import clsx from "clsx";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const Selector = ({ item, isDisable, progressItem }: IProps) => {
  const { isNoData, fieldSelections, handleItemClick } = useSelector({
    item,
    isDisable,
    progressItem,
  });
  return (
    <div className={styles.selector}>
      {isNoData ? (
        <div className={styles.selector__noData}>No data</div>
      ) : (
        <>
          {fieldSelections.map((item: string, index: number) => {
            const isChoosed = !progressItem?.selections
              ? false
              : progressItem?.selections.findIndex(
                  (elem: string) => elem === item
                ) > -1;
            return (
              <div
                key={`${item} - ${index}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleItemClick(item);
                }}
                className={clsx(styles.selector__selection, {
                  [styles.disable]: isDisable && !isChoosed,
                  [styles.defaultCursor]: isDisable && isChoosed,
                  [styles.choosed]: isChoosed,
                })}
              >
                {item}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
