import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDidUpdateEffect } from "~hooks";
import { INotepad } from "~models";
import {
  GENERATE_NOTEPAD_RESPONSE,
  GENERATE_NOTEPAD_TREATMENTS,
  REMOVE_NOTEPAD_ANSWER,
} from "~mutations";

interface IProps {
  notepad: INotepad | null;
  notepadText: string;
  setNotepad: React.Dispatch<React.SetStateAction<INotepad | null>>;
}

export const useNotepadPanel = ({
  notepad,
  notepadText,
  setNotepad,
}: IProps) => {
  const params = useParams();
  let id = !!params?.id ? +params.id : -1;

  const [showGenerateButtonLoader, setShowGenerateButtonLoader] =
    useState(false);

  const [generateNotepad] = useMutation(GENERATE_NOTEPAD_RESPONSE);
  const [generateNotepadTreatments] = useMutation(GENERATE_NOTEPAD_TREATMENTS);
  const [removeNotepadAnser] = useMutation(REMOVE_NOTEPAD_ANSWER);

  const [newDiagnosis, setDiagnosis] = useState("");

  const assessments = notepad?.assessments;
  const differentials = notepad?.differentials;
  const diagnosis =
    notepad?.diagnosis.length === 0 ? [] : notepad?.diagnosis.split(",");
  const treatments = notepad?.treatments;

  function getUniqueArray(inputArray: string[]) {
    const uniqueArray: string[] = [];
    for (const item of inputArray) {
      !uniqueArray.includes(item) && uniqueArray.push(item);
    }
    return uniqueArray;
  }

  const handleSaveDiagnosis = () => {
    if (
      (!assessments?.length && !differentials?.length) ||
      !newDiagnosis.length
    )
      return;

    let newValues = getUniqueArray(
      newDiagnosis.split(",").map((item) => item.trim())
    )
      .filter(
        (item) =>
          !diagnosis
            ?.map((item) => item.toLowerCase())
            .includes(item.toLowerCase())
      )
      .join(",");

    !!newValues &&
      setNotepad((prev) => {
        let state = structuredClone(prev);
        if (state) {
          if (!!state.diagnosis) {
            state.diagnosis = state.diagnosis + "," + newValues;
          } else {
            state.diagnosis = newValues;
          }
        }
        return state;
      });
    setDiagnosis("");
  };

  const handleDeleteDiagnos = (index: number) => {
    setNotepad((prev) => {
      let state = structuredClone(prev);
      diagnosis?.splice(index, 1);
      if (state) {
        state.diagnosis = !diagnosis ? "" : diagnosis?.join(",");
      }
      return state;
    });
  };

  const handleGenerateTreatments = () => {
    // if (!notepad?.diagnosis || (!notepad.data && !notepad.rewrited)) return;
    generateNotepadTreatments({
      variables: {
        input: {
          data: !!notepad?.rewrited ? notepad.rewrited : notepad?.data,
          chatId: id,
          diagnosis: notepad?.diagnosis,
        },
      },
    }).then((resp) => {
      setNotepad(resp.data.generateNotepadTreatments);
    });
  };

  const handleGenerateButton = () => {
    setShowGenerateButtonLoader(true);
    generateNotepad({
      variables: { input: { chatId: id, data: notepadText } },
    }).then((resp) => {
      setNotepad(resp.data.generateNotepadResponse);
      setShowGenerateButtonLoader(false);
    });
  };

  const handleRemoveAssesment = (index: number) => {
    !!assessments &&
      removeNotepadAnser({
        variables: { chatId: id, id: assessments[index].id },
      });

    setNotepad((prev) => {
      let state = structuredClone(prev);
      assessments?.splice(index, 1);
      if (state && assessments) {
        state.assessments = assessments;
      }
      return state;
    });
  };

  const handleRemoveDifferentials = (index: number) => {
    !!differentials &&
      removeNotepadAnser({
        variables: { chatId: id, id: differentials[index].id },
      });

    setNotepad((prev) => {
      let state = structuredClone(prev);
      differentials?.splice(index, 1);
      if (state && differentials) {
        state.differentials = differentials;
      }
      return state;
    });
  };

  const handleRemoveTreatments = (index: number) => {
    !!treatments &&
      removeNotepadAnser({
        variables: { chatId: id, id: treatments[index].id },
      });

    setNotepad((prev) => {
      let state = structuredClone(prev);
      treatments?.splice(index, 1);
      if (state && treatments) {
        state.treatments = treatments;
      }
      return state;
    });
  };

  useDidUpdateEffect(() => {
    handleGenerateTreatments();
  }, [notepad?.diagnosis]);

  return {
    newDiagnosis,
    showGenerateButtonLoader,
    assessments,
    differentials,
    diagnosis,
    treatments,
    setDiagnosis,
    handleGenerateButton,
    handleSaveDiagnosis,
    handleDeleteDiagnos,
    handleRemoveAssesment,
    handleRemoveDifferentials,
    handleRemoveTreatments,
  };
};
