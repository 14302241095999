import { useCreateOrganization } from "./hooks/useCreateOrganization";
import { OrganizationName, EnterPayment, InviteMembers } from "./modules";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { FC } from "react";
import { CrossInSquare } from "~icons";
import { IOrganization } from "~models";

interface IProps {
  containerClassName?: string;
  onCrossClick?: () => void;
  showTopIcons?: boolean;
  isOnboarding?: boolean;
  onFinish?: () => void;
  handleSuccessPayment?: () => void;
  organization?: IOrganization;
}

export const CreateOrganization: FC<IProps> = ({
  containerClassName,
  onCrossClick,
  showTopIcons,
  isOnboarding,
  onFinish,
  organization: organizationFromProps,
  handleSuccessPayment,
}) => {
  const {
    name,
    onChangeName,
    page,
    goNext,
    goBack,
    setPicture,
    pictureUri,
    isDisableFirstStep,
    organization,
    error,
    loading,
    createOrganization,
  } = useCreateOrganization(!!isOnboarding, organizationFromProps);

  return (
    <div className={clsx(styles.createOrganization, containerClassName)}>
      {showTopIcons && (
        <div className={styles.createOrganization__icons}>
          <div>{/* <DownArrow fill={"#0B0F1C"} /> */}</div>
          <div onClick={onCrossClick}>
            <CrossInSquare />
          </div>
        </div>
      )}

      <div className={styles.createOrganization__header}>
        <div
          className={clsx(styles.createOrganization__headerItem, {
            [styles.active]: page >= 1,
          })}
        />
        <div
          className={clsx(styles.createOrganization__headerItem, {
            [styles.active]: page >= 2,
          })}
        />
        <div
          className={clsx(styles.createOrganization__headerItem, {
            [styles.active]: page === 3,
          })}
        />
      </div>
      {page === 1 && (
        <OrganizationName
          name={name}
          setName={onChangeName}
          goNext={goNext}
          pictureUri={pictureUri}
          setPicture={setPicture}
          isDisable={isDisableFirstStep}
          error={error}
          createOrganization={createOrganization}
          loading={loading}
        />
      )}
      {page === 2 && (
        <EnterPayment
          goBack={goBack}
          goNext={goNext}
          organization={organization}
          organizationFromProps={organizationFromProps}
          handleSuccessPayment={handleSuccessPayment}
        />
      )}
      {page === 3 && (
        <InviteMembers
          name={name}
          organization={organization}
          isOnboarding={!!isOnboarding}
          onFinish={onFinish}
        />
      )}
    </div>
  );
};
