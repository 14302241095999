import { useQuery } from "@apollo/client";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, useOutsideClick } from "~hooks";
import { Logout, UpDownArrow, UserProfile } from "~icons";
import { GET_CURRENT_USER } from "~queries";
import styles from "./styles.module.scss";
import { client } from "~services";
import { choosedOrganizationVar } from "~localCache";
import { Avatar, Button, Modal } from "~components";
import Popup from "reactjs-popup";
import { PopupActions } from "reactjs-popup/dist/types";

interface IProps {
  onLinkClick: () => void;
}

export const UserModule = ({ onLinkClick }: IProps) => {
  const {
    data: { currentUser },
  } = useQuery(GET_CURRENT_USER);

  const popupRef = useRef<PopupActions>(null);

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const { setUser } = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const moduleRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleModalClose = () => {
    popupRef.current?.close();
    setShowModal(false);
  };

  const handleModalOpen = () => {
    if (!showModal) {
      popupRef.current?.open();
      setShowModal(true);
    } else {
      popupRef.current?.close();
      setShowModal(false);
    }
  };

  const toogleModalState = () => {
    !showLogoutModal && setShowModal(!showModal);
  };

  const navigateToProfile = () => {
    navigate(`profile/${currentUser.id}`);
    onLinkClick();
    handleModalClose();
  };

  const hanleOpenLogoutModal = () => {
    popupRef.current?.close();
    setShowModal(false);
    setShowLogoutModal(true);
  };
  const handleCloseLogoutModal = () => setShowLogoutModal(false);

  useOutsideClick(moduleRef, handleModalClose, "mousedown", modalRef);

  const logOut = () => {
    handleCloseLogoutModal();
    localStorage.clear();
    setUser(null);
    choosedOrganizationVar(null);

    setTimeout(() => {
      client.resetStore();
    }, 100);
  };

  return (
    <div ref={modalRef} className={styles.userModule} onClick={handleModalOpen}>
      <Popup
        ref={popupRef}
        arrow={false}
        lockScroll={true}
        closeOnDocumentClick={false}
        position={"top left"}
        trigger={<div style={{ position: "absolute", left: 0, top: 0 }} />}
      >
        <div ref={moduleRef} className={styles.userModule__modal}>
          {/* <div className={styles.userModule__modalAvatar}>
            {!!currentUser.avatar && <img src={currentUser.avatar} />}
          </div> */}

          <Avatar
            uri={currentUser.avatar}
            item={currentUser}
            type="user"
            containerStyles={styles.userModule__modalAvatar}
          />
          <p className={styles.userModule__modalTitle}>
            {currentUser.firstName} {currentUser.lastName}
          </p>
          <p className={styles.userModule__modalSubtitle}>
            {currentUser.email}
          </p>
          <div className={styles.userModule__modalUnderline} />

          <div
            onClick={navigateToProfile}
            className={styles.userModule__modalButton}
          >
            <UserProfile stroke="#979DB1" /> My Account
          </div>

          <div
            className={styles.userModule__modalButton}
            onClick={hanleOpenLogoutModal}
          >
            <Logout /> Log Out
          </div>
        </div>
      </Popup>

      {/* <div className={styles.userModule__avatar}>
        {!!currentUser.avatar && <img src={currentUser.avatar} />}
      </div> */}
      <Avatar
        uri={currentUser.avatar}
        item={currentUser}
        type="user"
        containerStyles={styles.userModule__avatar}
      />
      <div className={styles.userModule__center}>
        <p className={styles.userModule__centerTitle}>
          {currentUser.firstName} {currentUser.lastName}
        </p>
        <p className={styles.userModule__centerSubtitle}>{currentUser.email}</p>
      </div>

      <UpDownArrow fill="#979DB1" />

      {showLogoutModal && (
        <Modal onCloseModal={handleCloseLogoutModal}>
          <div className={styles.logoutModal}>
            <p className={styles.logoutModal__title}>
              Are you sure you want to log out?
            </p>

            <div className={styles.logoutModal__buttons}>
              <Button
                onClick={handleCloseLogoutModal}
                text="Cancel"
                backgroundColor="#979DB1"
                textColor="#FFFFFF"
              />

              <Button
                onClick={logOut}
                text="Log out"
                backgroundColor="#EF2F32"
                textColor="#FFFFFF"
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
