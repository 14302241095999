import { NavigateFunction } from "react-router-dom";

interface IProps {
  navigate: NavigateFunction;
  defaultDestination: string;
  destination?: string;
}

export const goBack = ({
  navigate,
  defaultDestination,
  destination,
}: IProps) => {
  if (!!destination) {
    navigate(destination);
  } else {
    // console.log(window.history.state && window.history.state.idx > 0);
    window.history.state && window.history.state.idx > 0
      ? navigate(-1)
      : navigate(defaultDestination);
  }
};
