import styles from "./styles.module.scss";

import { IItem } from "../../../../../constants";
import { useCalculator } from "./useCalculator";
import { Button, Input } from "~components";

interface IProps {
  item: IItem;
  isDisable: boolean;
}

export const Calculator = ({ item, isDisable }: IProps) => {
  const { isNoData, itemWithParsedData } = useCalculator({ item });
  return (
    <div className={styles.calculator}>
      {isNoData ? (
        <div className={styles.calculator__noData}>No data</div>
      ) : (
        <>
          {itemWithParsedData.data.inputs.map(
            (item: { name: string }, index: number) => {
              return !!item.name ? (
                <div
                  key={`${item.name} - ${index}`}
                  className={styles.calculator__field}
                >
                  <Input
                    disable={isDisable}
                    label={item.name}
                    placeholder="Enter value"
                  />
                </div>
              ) : null;
            }
          )}

          <Button
            disable={isDisable}
            backgroundType={"green"}
            text="Calculate"
          />

          {/* {item.data.outputs.map((item: { name: string; logic: string }) => {
            return !!item.name ? (
              <div className={styles.calculator__field}>
                <Input label={item.name} placeholder="Enter value" />
              </div>
            ) : null;
          })} */}
        </>
      )}
    </div>
  );
};
