import { IOrganization, IUser } from "~models";
import styles from "./styles.module.scss";
import clsx from "clsx";
interface IProps {
  uri?: string;
  item: IUser | IOrganization;
  type: "user" | "organization";
  containerStyles?: string;
}
export const Avatar = ({ uri, type, item, containerStyles }: IProps) => {
  let initials = "";
  if (type === "user") {
    let organization = item as IUser;
    if (!!organization.firstName)
      initials = `${organization.firstName[0]}${organization.lastName[0]}`;
  } else {
    let organization = item as IOrganization;
    if (!!organization?.name) {
      initials = organization.name[0];
    }
  }
  initials = initials.toUpperCase();
  return !!uri ? (
    <div className={clsx(styles.avatar, containerStyles)}>
      <img src={uri} />
    </div>
  ) : (
    <div className={clsx(styles.initials, containerStyles)}>{initials}</div>
  );
};
