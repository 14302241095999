import React, { FC } from "react";
import style from "./styles.module.scss";
import clsx from "clsx";

interface IProps {
  value: boolean;
  onClick: () => void;
}

export const Switch: FC<IProps> = ({ value, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={clsx(style.switch, { [style.active]: value })}
    >
      <div
        className={clsx(style.switch__circle, { [style.active]: value })}
      ></div>
    </div>
  );
};
