import { IItem } from "~models";

interface IProps {
  item: IItem;
  isDisable: boolean;
}

export const useAlgorithm = ({ item }: IProps) => {
  let data =
    typeof item.data === "string"
      ? structuredClone(JSON.parse(item.data))
      : item.data;
  return { data };
};
