import { PageHeader, SearchInput, Table, TableLoader } from "~components";
import { RequestItem } from "./components/RequestItem";
import { useAllRequests } from "./useAllRequests";
import { useRef } from "react";

import styles from "./styles.module.scss";
import { useMembers } from "../Members/useMembers";

export const ReceivedRequests = () => {
  const { loading, searchValue, setSearchValue, members, removeItemFromData } =
    useAllRequests();
  const { pageHeader } = useMembers();


  const scrollBlockRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={styles.userRequests__search}>
        <SearchInput
          value={searchValue}
          onChange={setSearchValue}
          placeholder={"Search for users, emails"}
        />
      </div>

      <div style={{ marginBottom: 20 }}>
        <PageHeader data={pageHeader} />
      </div>

      {loading ? (
        <TableLoader />
      ) : (
        <Table
          scrollBlockRef={scrollBlockRef}
          header={[
            { header: "Member", className: styles.userRequests__member },
            { header: "Request Date", className: styles.userRequests__date },
            { header: "Role", className: styles.userRequests__role },
            { header: "Action", className: styles.userRequests__action },
          ]}
          data={members}
          renderTableItem={(item, index) => (
            <RequestItem
              dataLength={members.length}
              item={item}
              index={index}
              scrollBlockRef={scrollBlockRef}
              removeItemFromData={removeItemFromData}
            />
          )}
        />
      )}
    </>
  );
};
