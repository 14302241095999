import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  choosedOrganizationVar,
  GET_CHOOSED_ORGANIZATIOM,
  GET_SHOW_PANEL,
} from "~localCache";
import { IOrganization } from "~models";
import { GET_MY_ORGANIZATIONS } from "~queries";
import {
  clearChoosedOrganization,
  getChoosedOrganization,
  setChoosedOrganization as setOrganizationInLS,
} from "~services";
import {
  useInvitationSubscription,
  useNewNotification,
} from "../../subscriptions";

export const useDashboard = () => {
  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);
  const {
    data: { showPanel },
  } = useQuery(GET_SHOW_PANEL);
  const [getMyOrganizations] = useLazyQuery(GET_MY_ORGANIZATIONS);

  const [loading, setShowLoading] = useState(!choosedOrganization);

  const setChoosedOrganization = (data: IOrganization[]) => {
    const organizationFromLS = getChoosedOrganization();
    if (!!organizationFromLS) {
      const item = data.find((item) => item.id === organizationFromLS.id);
      if (!!item) {
        choosedOrganizationVar(item);
      } else if (data.length > 0) {
        choosedOrganizationVar(data[0]);
        setOrganizationInLS(data[0]);
        setShowLoading(false);
        return;
      } else {
        clearChoosedOrganization();
        setShowLoading(false);
        return;
      }
    } else if (data.length > 0) {
      choosedOrganizationVar(data[0]);
      setOrganizationInLS(data[0]);
    }

    setShowLoading(false);
  };

  useEffect(() => {
    if (!choosedOrganization) {
      getMyOrganizations({ variables: {} })
        .then((resp) => setChoosedOrganization(resp.data.myOrganizations))
        .catch((err) => console.log("ERR", err));
    }
  }, []);

  useInvitationSubscription();
  // useNewNotification();

  return {
    loading,
    showPanel,
  };
};
