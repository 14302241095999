import {
  IOrganization,
  IUser,
  OrganizationMemberRole,
  UserAccessRole,
} from "~models";

export const checkIfMemberIsUser = (organization: IOrganization) => {
  return (
    !!organization?.members &&
    organization?.members.length > 0 &&
    organization?.members[0].role === OrganizationMemberRole.User
  );
};

export const checkIfUserIsSuperAdmin = (user: IUser) => {
  return !!user?.role && user.role !== UserAccessRole.User;
};
