import styles from "./styles.module.scss";
import FroalaEditor from "react-froala-wysiwyg";

import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/colors.min.js";
// import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
// import "froala-editor/js/plugins/line_height.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/align.min.js";
// import "froala-editor/js/plugins/file.min.js";
// import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/plugins/video.min.css";
import "froala-editor/css/plugins/colors.min.css";
// import "froala-editor/css/plugins/emoticons.min.css";
// import "froala-editor/css/plugins/file.min.css";
// import "froala-editor/css/plugins/file.min.css";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/link.min.js";

import "froala-editor/js/plugins/table.min.js";

import { useTextBox } from "./useTextBox";
import { IItem } from "~models";
import { uploadFile } from "~services";
import { useEffect } from "react";
import { Button, Modal } from "~components";

import "./froala.css";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const TextBoxSettings = ({ item, itemIndex }: IProps) => {
  const { onModelChange, model, toogleFroalaModal, showFroalaModal } =
    useTextBox({ item, itemIndex });

  return (
    <div className={styles.textboxSettings}>
      <div className={styles.textboxSettings__underline} />

      <FroalaEditor
        model={model}
        onModelChange={onModelChange}
        config={{
          key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
          imageUpload: true,
          videoUpload: true,
          listAdvancedTypes: true,
          listAdvancedPositions: true,
          // imageDefaultAlign: "left",
          // imageDefaultDisplay: "inline-block",
          // Set max image size to 5MB.
          imageMaxSize: 5 * 1024 * 1024,
          videoAllowedTypes: ["mp4", "webm", "ogg", "mov"],
          imageAllowedTypes: ["jpeg", "jpg", "png"],
          // videoUploadURL: "",
          // videoUploadToS3: {
          //   bucket: "checkem-bucket",
          //   // Your bucket region.
          //   region: "us-east-1",
          //   keyStart: "uploads/",
          //   params: {
          //     acl: "public-read", // ACL according to Amazon Documentation.
          //     AWSAccessKeyId: "AKIAYL4NGE5SNUDVMCMA", // Access Key from Amazon.
          //     // policy: 'POLICY_STRING', // Policy string computed in the backend.
          //     // signature: '', // Signature computed in the backend.

          //     // If you are using Amazon Signature V4, the followings should be used instead.
          //     "X-Amz-Credential":
          //       "AKIAYL4NGE5SNUDVMCMA%2F20230525%2Fus-east-1%2Fs3%2Faws4_request",
          //     "X-Amz-Algorithm": "AWS4-HMAC-SHA256",
          //     "X-Amz-Date": "20230525T104248Z",
          //     // Policy: "...", //
          //     "X-Amz-Signature":
          //       "a16a352ff0e2f61972cf74229145741050a6d2b5fe992c45c7e96d5343fb6f24", // computed in backend
          //   },
          // },
          events: {
            "image.beforeUpload"(data: Blob[]) {
              const editor: any = this;
              uploadFile(data[0], data[0].type)
                .then((resp) => {
                  editor.image.insert(resp, null, null, editor.image.get());
                })
                .catch((err) => {});
              return false;
            },
            "video.beforeUpload"(data: Blob[]) {
              const editor: any = this;
              uploadFile(data[0], data[0].type)
                .then((resp) => {
                  editor.html.insert(
                    `<span contenteditable="false" draggable="true" class="fr-video fr-deletable fr-fvc fr-dvb fr-draggable"><iframe width="640" height="360" src="${resp}" frameborder="0" allowfullscreen="" class="fr-draggable"></iframe></span><br>`,
                    true
                  );
                })
                .catch((err) => {});
              return false;
            },
            "image.inserted"($img: any) {
              const currentStyles = $img.attr("style"); // Отримуємо поточний список стилів
              const newStyles = currentStyles + ` height: ${$img[0].height}px;`; // Додаємо новий стиль height
              $img.attr("style", newStyles);
            },
          },
        }}
      />
      <div className={styles.textboxSettings__button}>
        <Button
          backgroundType={"green"}
          text="Full screen"
          onClick={toogleFroalaModal}
        />
      </div>
      {showFroalaModal && (
        <Modal
          containerClassName={styles.textboxSettings__modalContainer}
          modalClassName={styles.textboxSettings__modal}
          onCloseModal={toogleFroalaModal}
        >
          <div className={styles.textboxSettings__modalContent}>
            <FroalaEditor
              model={model}
              onModelChange={onModelChange}
              config={{
                key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                imageUpload: true,
                imageMaxSize: 5 * 1024 * 1024,
                imageAllowedTypes: ["jpeg", "jpg", "png"],
                videoAllowedTypes: ["mp4", "webm", "ogg", "mov"],
                events: {
                  "image.beforeUpload"(data: Blob[]) {
                    const editor: any = this;
                    uploadFile(data[0], data[0].type)
                      .then((resp) => {
                        editor.image.insert(
                          resp,
                          null,
                          null,
                          editor.image.get()
                        );
                      })
                      .catch((err) => {
                        console.log("UPLOAD IMAGE ERR", err);
                      });
                    return false;
                  },
                  "image.inserted"($img: any) {
                    const currentStyles = $img.attr("style"); // Отримуємо поточний список стилів
                    const newStyles =
                      currentStyles + ` height: ${$img[0].height}px;`; // Додаємо новий стиль height
                    $img.attr("style", newStyles);
                  },
                },
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};
