import { useOrganizationSearch } from "./useOrganizationSearch";
import { Cross, Loader, Search } from "~icons";
import { Avatar } from "../Avatar";
import clsx from "clsx";

import styles from "./styles.module.scss";
import { UIEvent } from "react";
import { IOrganization } from "~models";

interface IProps {
  choosedOrganization: IOrganization | null;
  updateChoosedOrganization: (value: IOrganization | null) => void;
}

export const OrganizationSearch = ({
  choosedOrganization,
  updateChoosedOrganization,
}: IProps) => {
  const {
    organizations,
    inputRef,
    organizationsCount,
    isFocused,
    handleNextPage,
    loading,
    searchValue,
    setSearchValue,
    setActiveFocus,
    handleCrossClick,
    setDisactiveFocus,
  } = useOrganizationSearch({ choosedOrganization, updateChoosedOrganization });

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const bottom =
      (e.target as HTMLDivElement).scrollHeight -
        (e.target as HTMLDivElement).scrollTop ===
      (e.target as HTMLDivElement).clientHeight;
    if (bottom) {
      handleNextPage();
    }
  };

  return (
    <div ref={inputRef} className={styles.organizationSearch}>
      <div
        onClick={() => !choosedOrganization && setActiveFocus()}
        className={clsx(styles.input, {
          [styles.focus]: isFocused,
          [styles.input__defaultCursor]: !!choosedOrganization,
        })}
      >
        <Search fill="#0B0F1C" />
        {!!choosedOrganization ? (
          <div className={styles.input__organizationWrapper}>
            <div className={styles.input__organization}>
              <Avatar
                uri={choosedOrganization.picture}
                type="organization"
                item={choosedOrganization}
                containerStyles={styles.input__organizationImage}
              />
              <p>{choosedOrganization.name}</p>
            </div>
          </div>
        ) : (
          <input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search for organization"
          />
        )}

        <div className={styles.input__icon}>
          {((!!searchValue && !loading) || !!choosedOrganization) && (
            <div onClick={handleCrossClick} className={styles.input__cross}>
              <Cross fill="#FFFFFF" />
            </div>
          )}

          {loading && isFocused && (
            <div className={styles.input__loader}>
              <Loader fill="#0B0F1C" />
            </div>
          )}
        </div>
      </div>

      {isFocused && (
        <div className={styles.select}>
          <div onScroll={handleScroll} className={styles.selectBlock}>
            {organizations.map((item, index) => (
              <div
                onClick={() => {
                  setDisactiveFocus();
                  updateChoosedOrganization(item);
                }}
                key={`${item} - ${index}`}
                className={clsx(styles.item, {
                  [styles.lastItem]: organizations.length - 1 === index,
                })}
              >
                <Avatar
                  uri={item.picture}
                  type="organization"
                  item={item}
                  containerStyles={styles.item__image}
                />

                <div>
                  <div className={styles.item__name}>{item.name}</div>
                  <div className={styles.item__members}>35 mebers</div>
                </div>
              </div>
            ))}
            {organizations.length < organizationsCount && (
              <div className={styles.item__loader}>
                <Loader fill="#0B0F1C" />
              </div>
            )}
            {organizations.length === 0 && !!searchValue && (
              <div className={styles.item__empty}>No data</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
