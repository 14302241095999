import { usePasswordRules } from "./usePasswordRules";

import styles from "./styles.module.scss";
import clsx from "clsx";

interface IProps {
  password: string;
}

export const PasswordRules = ({ password }: IProps) => {
  const { showLengthError, showDigitError, showSymbolError } = usePasswordRules(
    { password }
  );
  return (
    <p className={styles.warning}>
      Must contain:{" "}
      <span
        className={clsx({
          [styles.warning__red]: showLengthError,
        })}
      >
        at least 8 characters
      </span>
      ,{" "}
      <span
        className={clsx({
          [styles.warning__red]: showDigitError,
        })}
      >
        one digit
      </span>
      ,{" "}
      <span
        className={clsx({
          [styles.warning__red]: showSymbolError,
        })}
      >
        special character
      </span>
    </p>
  );
};
