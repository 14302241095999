import { MyOrganizationItem } from "./components/MyOrganizationItem";
import { useMyOrganization } from "./useMyOrganizations";
import { PageHeader, SearchInput, Table } from "~components";
import { IOrganization } from "~models";

import styles from "./styles.module.scss";
import { useHomePage } from "../AllOrganizations/useHomePage";
import { ActiveListsModule } from "../../../../navigations/Dashboard/LeftPanel/modules";

export const MyOrganization = () => {
  const { myOrganizations, searchValue, setSearchValue } = useMyOrganization();
  const { pageHeader } = useHomePage();

  return (
    <>
      <div className={styles.myOrganization__search}>
        <SearchInput
          placeholder="Search for companies"
          value={searchValue}
          onChange={setSearchValue}
        />
        <ActiveListsModule noLabel />
      </div>

      <div style={{ marginBottom: 20 }}>
        <PageHeader data={pageHeader} />
      </div>
      
      <Table
        header={[
          {
            header: "Organization",
            className: styles.myOrganization__organizationHeader,
          },
          {
            header: "Members",
            className: styles.myOrganization__membersHeader,
          },
          {
            header: "Date of creation",
            className: styles.myOrganization__dateHeader,
          },
          {
            header: "Role",
            className: styles.myOrganization__roleHeader,
          },
        ]}
        data={myOrganizations}
        renderTableItem={(item: IOrganization, index) => (
          <MyOrganizationItem item={item} key={`${item.id} - ${index}`} />
        )}
      />
    </>
  );
};
