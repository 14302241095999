import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";
import { IFolder } from "~models";

interface IProps {
  header: {
    header: string;
    flex?: number;
    className?: string;
  }[];
  folders?: IFolder[];
  data: any[];
  renderTableItem: (item: any, index: number) => any;
  renderFolderItem?: (item: IFolder, index: number) => any;
  scrollBlockRef?: React.Ref<HTMLDivElement>;
  handleLoad?: () => void;
  loading?: boolean;
}

export const Table: FC<IProps> = ({
  header,
  data,
  folders,
  renderFolderItem,
  renderTableItem,
  scrollBlockRef,
  handleLoad,
  loading,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  const handleScroll = () => {
    if (contentRef?.current) {
      const { scrollTop, clientHeight, scrollHeight } =
        contentRef.current as HTMLDivElement;

      const scrollable =
        contentRef.current.scrollHeight > contentRef.current.clientHeight;
      setIsScrollable(scrollable);
      if (scrollHeight - scrollTop <= clientHeight && !loading && handleLoad) {
        handleLoad();
      }
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);

  useEffect(() => {
    if (contentRef?.current && !isScrollable) {
      const scrollable =
        contentRef.current.scrollHeight > contentRef.current.clientHeight;
      setIsScrollable(scrollable);
      handleLoad && handleLoad();
    }
  }, [data]);

  return (
    <div className={styles.table}>
      <div className={styles.table__header}>
        {header.map((item, index) => {
          return (
            <div
              key={`${item.header} - ${index}`}
              className={clsx(styles.table__headerItem, item?.className)}
              style={!!item.flex ? { flex: item.flex } : {}}
            >
              <p> {item.header}</p>
            </div>
          );
        })}
      </div>

      <div
        ref={contentRef}
        onScroll={handleScroll}
        className={styles.table__rows}
      >
        {!!folders && (
          <>
            {folders.map((item, index) => (
              <Fragment key={`${item.id} - ${index} - folder`}>
                {!!renderFolderItem && renderFolderItem(item, index)}
              </Fragment>
            ))}
          </>
        )}
        {data.map((item, index) => (
          <Fragment key={`${item?.id} - ${index} - table item`}>
            {renderTableItem(item, index)}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
