import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  GET_ALL_APP_ORGANIZAZTION,
  GET_ALL_ORGANIZATIONS_COUNT,
  GET_MY_INVITATIONS,
  GET_MY_ORGANIZATIONS,
  GET_MY_ORGANIZATION_REQUESTS,
  GET_ORGANIZATIONS_PAGINATION_DATA,
} from "~queries";

import { GET_CHOOSED_ORGANIZATIOM } from "~localCache";
import { useUpdateEffect } from "usehooks-ts";
import { IOrganization, IOrganizationRequest } from "~models";

const PAGE_LIMIT = 10;

export const useOrganizationsManagement = () => {
  const { data: organizationData } = useQuery(GET_ALL_ORGANIZATIONS_COUNT, {
    variables: { input: {} },
  });

  const {
    data: { myOrganizations },
  } = useQuery(GET_MY_ORGANIZATIONS);

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const { data: requestsData } = useQuery(GET_MY_ORGANIZATION_REQUESTS, {
    variables: {},
  });

  const { data: invitationsData } = useQuery(GET_MY_INVITATIONS, {
    variables: { input: {} },
  });

  const [showOrganizationModal, setShowOrganizationModal] = useState(false);

  const handleOrganizationModalOpen = () => {
    setShowOrganizationModal(true);
  };

  const handleOrganizationModalClose = () => {
    setShowOrganizationModal(false);
  };

  const [fetchedCount, setFetchedCount] = useState(0);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [allOrganizationsCount, setAllOrganizationsCount] = useState(0);

  const [searchValue, setSearchValue] = useState("");

  const [loading, setLoading] = useState(false);

  const [getData] = useLazyQuery(GET_ALL_APP_ORGANIZAZTION);

  const loadData = () => {
    setLoading(true);

    const countInput = {};
    const input = { skip: 0, take: PAGE_LIMIT };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }

    getData({
      variables: { countInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      fetchedCount !== 0 && setFetchedCount(0);

      setAllOrganizationsCount(resp.data.countAllOrganizations);
      setOrganizations(resp.data.findAllOrganizations);
      setLoading(false);
    });
  };

  const handleChangeRequestsField = (
    index: number,
    value: IOrganizationRequest[]
  ) => {
    setOrganizations((prev) => {
      let state = [...prev];
      state[index].requests = value;
      return state;
    });
  };

  const updateOrganization = (index: number, value: IOrganization) => {
    setOrganizations((prev) => {
      let state = [...prev];
      state[index] = value;
      return state;
    });
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(allOrganizationsCount);
    const skip = fetchedCount + PAGE_LIMIT;
    if (skip >= maxPage) return;
    setLoading(true);
    let take = PAGE_LIMIT;
    if (allOrganizationsCount - skip < 10) {
      take = allOrganizationsCount - skip;
    }
    const countInput = {};
    const input = { skip: skip, take: PAGE_LIMIT };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllOrganizationsCount(resp.data.countAllOrganizations);
      setOrganizations(resp.data.findAllOrganizations);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  const handlePrevPage = () => {
    const skip = fetchedCount - PAGE_LIMIT;
    if (skip < 0) return;
    setLoading(true);
    const countInput = {};
    const input = { skip: skip, take: PAGE_LIMIT };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllOrganizationsCount(resp.data.countAllOrganizations);
      setOrganizations(resp.data.findAllOrganizations);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  useEffect(() => {
    loadData();
  }, []);

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      loadData();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    choosedOrganization,
    showOrganizationModal,
    handleOrganizationModalOpen,
    handleOrganizationModalClose,
    organizations,
    loadingOrganization: false,
    organizationsCount: allOrganizationsCount,
    handleNextPage,
    handlePrevPage,
    fetchedCount,
    loading,
    searchValue,
    setSearchValue,
    handleChangeRequestsField,
    updateOrganization,
  };
};
