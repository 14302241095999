import { GET_MY_ORGANIZATIONS } from "~queries";
import { useUpdateEffect } from "usehooks-ts";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { IOrganization } from "~models";

export const useMyOrganization = () => {
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState<IOrganization[]>([]);
  const {
    data: { myOrganizations },
  } = useQuery(GET_MY_ORGANIZATIONS);

  const filterOrganization = () => {
    if (!!searchValue) {
      let filteredData = myOrganizations.filter((item: IOrganization) =>
        item.name?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setData(filteredData);
    } else {
      setData(myOrganizations);
    }
  };

  useEffect(() => {
    !!myOrganizations && setData(myOrganizations);
  }, [myOrganizations]);

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      filterOrganization();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    myOrganizations: data,
    searchValue,
    setSearchValue,
  };
};
