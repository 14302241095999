import { regex } from "~constants";
import { IMember } from "../constants";

export const isValidFirstStep = (value: string) => value.length >= 2;
export const isValidSecondStep = () => {
  return true;
};

export const validateMembers = (members: IMember[]) => {
  const isNotFullInformation =
    members.findIndex(
      (item) => (!!item.email && !item.role) || (!item.email && !!item.role)
    ) > -1;

  if (isNotFullInformation) return false;

  const minimumOneValidMember =
    members.findIndex(
      (item) => !!item.email && !!item.role && regex.email.test(item.email)
    ) > -1;

  if (!minimumOneValidMember) return false;

  return true;
};
