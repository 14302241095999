import { useActiveChecklists } from "./useActiveChecklists";
import { GridView, Loader, SortIcon, TableView } from "~icons";
import {
  ChecklistItem,
  ChecklistItemSquare,
  FolderItem,
  FolderItemSquare,
  PDFItemView,
  PDFItemViewSquare,
  Pagination,
  SearchInput,
  TableLoader,
  Table,
  GridWrapper,
  PageHeader,
  SearchBar,
} from "~components";

import styles from "./styles.module.scss";

const PAGE_HEADER = [
  { title: "Active", type: "active", to: "/dashboard/home/active" },
  {
    title: "My Completed / In Progress",
    type: "completed",
    to: "/dashboard/home/completed",
  },
];

export const ActiveChecklists = () => {
  const {
    folders,
    loading,
    paginationLoading,
    viewType,
    PAGE_LIMIT,
    checklists,
    draftsCount,
    searchValue,
    fetchedCount,
    setViewType,
    handleNextPage,
    handlePrevPage,
    setSearchValue,
  } = useActiveChecklists();

  return (
    <>
      <SearchBar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        viewType={viewType}
        setViewType={setViewType}
      />
      {/* <div className={styles.allChecklists__search}>
        <SearchInput
          value={searchValue}
          onChange={setSearchValue}
          placeholder="Search for Checkems or items"
        />

        <div className={styles.allChecklists__searchRight}>
          <div
            className={styles.allChecklists__searchButton}
            onClick={() => setViewType("table")}
          >
            <TableView fill={viewType === "table" ? "#29B473" : "#979DB1"} />
          </div>

          <div
            className={styles.allChecklists__searchButton}
            onClick={() => setViewType("grid")}
          >
            <GridView fill={viewType === "grid" ? "#29B473" : "#979DB1"} />
          </div>
        </div>
        <div className={styles.allChecklists__searchButton}>
          <SortIcon />
        </div>
      </div> */}

      <div style={{ marginBottom: 20 }}>
        <PageHeader data={PAGE_HEADER} />
      </div>

      {loading && <TableLoader />}

      {!loading && viewType === "table" && (
        <Table
          folders={folders}
          header={[{ header: "Name", className: styles.allChecklists__name }]}
          data={checklists}
          handleLoad={handleNextPage}
          loading={paginationLoading}
          renderTableItem={(item, index) =>
            item.type === "Pdf" ? (
              <PDFItemView pdf={item} key={`${item} - ${index}`} />
            ) : (
              <ChecklistItem checklist={item} key={`${item} - ${index}`} />
            )
          }
          renderFolderItem={(folder, index) => (
            <FolderItem handleView hideActions folder={folder} index={index} />
          )}
        />
      )}

      {!loading && viewType === "grid" && (
        <GridWrapper
          folders={folders}
          data={checklists}
          handleLoad={handleNextPage}
          loading={paginationLoading}
          renderFolderItem={(folder, index) => (
            <FolderItemSquare
              handleView
              hideActions
              folder={folder}
              index={index}
            />
          )}
          renderItem={(item, index) => {
            return item.type === "Pdf" ? (
              <PDFItemViewSquare pdf={item} key={`${item} - ${index}`} />
            ) : (
              <ChecklistItemSquare
                checklist={item}
                key={`${item} - ${index}`}
              />
            );
          }}
        />
      )}
      {paginationLoading && (
        <div className={styles.paginationLoader}>
          <Loader fill="#616A85" />
        </div>
      )}
      <div className={styles.pagination}>
        <div className={styles.pagination__left}>
          Showing{" "}
          <span>
            {fetchedCount + PAGE_LIMIT > draftsCount
              ? draftsCount
              : !!draftsCount && !!(fetchedCount + PAGE_LIMIT)
              ? fetchedCount + PAGE_LIMIT
              : 0}
          </span>{" "}
          of <span>{draftsCount}</span> Results
        </div>
      </div>
      {/* <Pagination
        allResults={draftsCount}
        fetchedResult={fetchedCount + PAGE_LIMIT}
        limit={PAGE_LIMIT}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
      /> */}
    </>
  );
};
