import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_BILLING_PAGE_INFO, GET_CARDS } from "~queries";
import { GET_CHOOSED_ORGANIZATIOM } from "~localCache";
import { CHANGE_DEFAULT_CARD } from "~mutations";
import { ICard, ISubscription } from "~models";
import { useEffect, useState } from "react";

export const useBilling = () => {
  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [getData] = useLazyQuery(GET_BILLING_PAGE_INFO);
  const [getCards] = useLazyQuery(GET_CARDS);
  const [changeDefaultCard] = useMutation(CHANGE_DEFAULT_CARD);

  const [cards, setCards] = useState<ICard[]>([]);
  const [subscription, setSubscription] = useState<ISubscription | null>(null);

  const [showCreateOrganizationModal, setShowCreateOrganizationModal] =
    useState(false);
  const [showAddCardModal, setShowAddCardModal] = useState(false);

  const [showLoader, setShowLoader] = useState(true);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const handleOpenAddUserModal = () => setShowAddUserModal(true);
  const handleCloseAddUserModal = () => setShowAddUserModal(false);

  const handleCloseCreateOrganizationModal = () =>
    setShowCreateOrganizationModal(false);

  const handleCloseAddCardModal = () => setShowAddCardModal(false);

  const handleAddPaymentButton = () => {
    if (choosedOrganization.status === "active") {
      setShowAddCardModal(true);
    } else {
      setShowCreateOrganizationModal(true);
    }
  };

  const handleChangeCardButton = (index: number) => {
    const defaultCardIndex = cards.findIndex((item) => item.default);
    setCards((prev) => {
      let state = [...prev];
      state[defaultCardIndex] = { ...state[defaultCardIndex], default: false };
      state[index] = { ...state[index], default: true };
      return state;
    });

    changeDefaultCard({
      variables: {
        input: {
          organizationId: choosedOrganization.id,
          paymentMethod: cards[index].id,
        },
      },
    });
  };

  const updateData = () => {
    setShowLoader(true);

    getData({
      variables: {
        subscriptionInput: choosedOrganization.id,
        cardsInut: choosedOrganization.id,
      },
      fetchPolicy: "network-only",
    }).then((resp) => {
      console.log(resp.data, 'data')
      setCards(resp.data.retrieveCards);
      setSubscription(resp.data.retrieveSubscription);
      setShowLoader(false);
    });
  };

  const updateCards = () => {
    getCards({
      variables: {
        organizationId: choosedOrganization.id,
      },
      fetchPolicy: "network-only",
    }).then((resp) => {
      setCards(resp.data.retrieveCards);
    });
  };

  const handleAddModal = () => {};

  useEffect(() => {
    updateData();
  }, [choosedOrganization.id]);

  return {
    cards,
    showLoader,
    subscription,
    showAddCardModal,
    showAddUserModal,
    choosedOrganization,
    showCreateOrganizationModal,
    updateData,
    updateCards,
    handleAddModal,
    handleAddPaymentButton,
    handleCloseAddCardModal,
    handleChangeCardButton,
    handleOpenAddUserModal,
    handleCloseAddUserModal,
    handleCloseCreateOrganizationModal,
  };
};
