import { ISection } from "~models";
import styles from "./styles.module.scss";
import { Checkbox } from "~components";
import { Item } from "../Item";
import clsx from "clsx";
import { useSection } from "./useSection";
import { DownArrow } from "~icons";
import dayjs from "dayjs";
import FroalaEditor from "react-froala-wysiwyg";
interface IProps {
  section: ISection;
  index: number;
}
export const Section = ({ section, index }: IProps) => {
  const { toggleCheckbox, toggleCollapsed, sectionItem } = useSection({
    section,
  });
  return (
    <div
      style={{ borderColor: section.color }}
      className={clsx(styles.section, {
        [styles.checked]: sectionItem?.isChecked,
      })}
    >
      <div className={styles.section__header}>
        {!!section.name ? (
          <p
            className={clsx(styles.section__headerHeader, {
              [styles.section__headerHeaderChecked]: sectionItem?.isChecked,
            })}
          >
            {section.name}
          </p>
        ) : !!section.description ? (
          <FroalaEditor
            model={section.description}
            config={{
              key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
              htmlUntouched: true,
              toolbarInline: true,
              toolbarVisibleWithoutSelection: true,
              isDisable: true,
              events: {
                initialized() {
                  const editor: any = this;
                  editor.edit.off();
                },
              },
            }}
          />
        ) : (
          <div />
        )}

        {/* <div>
          {sectionItem?.isChecked && !!sectionItem.checkedTimestamp && (
            <p className={styles.section__headerDate}>
              {dayjs(sectionItem.checkedTimestamp).format("hh:mm:ss A")}
            </p>
          )}

          {sectionItem?.checkbox && (
            <Checkbox value={sectionItem?.isChecked} onClick={toggleCheckbox} />
          )}
        </div> */}

        <div
          className={clsx(styles.section__headerRight, {
            [styles.section__headerRightChecked]: sectionItem?.isChecked,
          })}
        >
          {sectionItem.checkbox && (
            <Checkbox value={sectionItem?.isChecked} onClick={toggleCheckbox} />
          )}
          {sectionItem?.isChecked && !!sectionItem.checkedTimestamp && (
            <p className={styles.section__headerRightDate}>
              {dayjs(sectionItem?.checkedTimestamp).format("hh:mm:ss A")}
            </p>
          )}
        </div>
      </div>
      {!!section.description && !!section.name ? (
        <p className={styles.section__description}>{section.description}</p>
      ) : (
        <div />
      )}

      {sectionItem?.collapsable && (
        <div
          onClick={toggleCollapsed}
          className={clsx(styles.section__collapseArrow, {
            [styles.rotate]: !sectionItem?.startCollapsable,
          })}
        >
          <DownArrow fill="#0B0F1C" />
        </div>
      )}

      <div
        className={clsx({
          [styles.hide]:
            sectionItem?.collapsable && sectionItem?.startCollapsable,
        })}
      >
        {section.items.map((item, index) => (
          <Item key={`${item.id} - ${index}`} item={item} itemIndex={index} />
        ))}
      </div>
    </div>
  );
};
