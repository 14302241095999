import { PublishingStatus, IDraft, IFolder } from "~models";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { GET_CHOOSED_ORGANIZATIOM, viewTypes } from "~localCache";
import { useUpdateEffect } from "usehooks-ts";
import { useEffect, useState } from "react";
import {
  GET_ORGANIZATION_HOME_DATA_AND_FOLDERS_NEW,
  GET_ORGANIZATION_HOME_DATA_NEW,
} from "~queries";

const PAGE_LIMIT = 10;

export const useActiveChecklists = () => {
  const viewType = useReactiveVar(viewTypes)

  const setViewType = (type: 'table' | 'grid') => {
    viewTypes(type)
  }


  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [fetchedCount, setFetchedCount] = useState(0);
  const [checklists, setChecklists] = useState<IDraft[]>([]);
  const [allChecklistsCount, setAllChecklistsCount] = useState(0);

  const [searchValue, setSearchValue] = useState<string>("");
  const [folders, setFolders] = useState<IFolder[]>([]);

  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false)

  const [getData] = useLazyQuery(GET_ORGANIZATION_HOME_DATA_NEW);
  const [getInitialData] = useLazyQuery(
    GET_ORGANIZATION_HOME_DATA_AND_FOLDERS_NEW
  );

  const loadData = () => {
    setLoading(true);

    const folderInput = {
      skip: 0,
      take: 49,
      organizationId: choosedOrganization.id,
    };

    const countPdfInput = {
      status: PublishingStatus.Published,
      organizationId: choosedOrganization.id,
    };

    const countChecklistInput = {
      organizationId: choosedOrganization.id,
    };
    const input = {
      skip: 0,
      take: PAGE_LIMIT,
      organizationId: choosedOrganization.id,
    };

    if (!!searchValue) {
      Object.assign(countPdfInput, { search: searchValue });
      Object.assign(countChecklistInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }

    getInitialData({
      variables: { folderInput, countPdfInput, countChecklistInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setFolders(resp.data.folders);
      fetchedCount !== 0 && setFetchedCount(0);
      setAllChecklistsCount(resp.data.countPdf + resp.data.countChecklist);
      setChecklists([
        ...resp.data.checkemHomeData.pdfs,
        ...resp.data.checkemHomeData.checklists,
      ]);
      setLoading(false);
    });
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(allChecklistsCount);
    const skip = fetchedCount + PAGE_LIMIT;
    if (skip > maxPage) return;
    setPaginationLoading(true);
    let take = PAGE_LIMIT;
    if (allChecklistsCount - skip < PAGE_LIMIT) {
      take = allChecklistsCount - skip;
    }
    const countPdfInput = {
      status: PublishingStatus.Published,
      organizationId: choosedOrganization.id,
    };

    const countChecklistInput = {
      organizationId: choosedOrganization.id,
    };
    const input = {
      skip: skip,
      take: PAGE_LIMIT,
      organizationId: choosedOrganization.id,
    };
    if (!!searchValue) {
      Object.assign(countPdfInput, { search: searchValue });
      Object.assign(countChecklistInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countPdfInput, countChecklistInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllChecklistsCount(resp.data.countPdf + resp.data.countChecklist);
      setChecklists(prev => [
        ...prev,
        ...resp.data.checkemHomeData.pdfs,
        ...resp.data.checkemHomeData.checklists,
      ]);
      setPaginationLoading(false);
    });
    setFetchedCount(skip);
  };

  const handlePrevPage = () => {
    const skip = fetchedCount - PAGE_LIMIT;
    if (skip < 0) return;
    setLoading(true);
    const countPdfInput = {
      status: PublishingStatus.Published,
      organizationId: choosedOrganization.id,
    };

    const countChecklistInput = {
      organizationId: choosedOrganization.id,
    };
    const input = {
      skip: skip,
      take: PAGE_LIMIT,
      organizationId: choosedOrganization.id,
    };
    if (!!searchValue) {
      Object.assign(countPdfInput, { search: searchValue });
      Object.assign(countChecklistInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countPdfInput, countChecklistInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllChecklistsCount(resp.data.countPdf + resp.data.countChecklist);
      setChecklists([
        ...resp.data.checkemHomeData.pdfs,
        ...resp.data.checkemHomeData.checklists,
      ]);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  useEffect(() => {
    loadData();
  }, [choosedOrganization?.id]);

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      loadData();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    folders,
    loading,
    paginationLoading,
    viewType,
    checklists,
    PAGE_LIMIT,
    searchValue,
    fetchedCount,
    draftsCount: allChecklistsCount,
    setViewType,
    setSearchValue,
    handleNextPage,
    handlePrevPage,
  };
};
