import { gql } from "@apollo/client";

export const CREATE_FOLDER = gql`
  mutation createFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
      id
    }
  }
`;

export const CREATE_ADMIN_FOLDER = gql`
  mutation createFolderLibrary($input: CreateFolderInputLibrary!) {
    createFolderLibrary(input: $input) {
      id
    }
  }
`;

export const REMOVE_FOLDER = gql`
  mutation removeFolder($id: Int!) {
    removeFolder(id: $id)
  }
`;

export const UPDATE_FOLDER = gql`
  mutation updateFolder($input: UpdateFolderInput!) {
    updateFolder(input: $input) {
      id
      title
    }
  }
`;

export const MOVE_FOLDER_TO_FOLDER = gql`
  mutation addFolderToFolder($input: AddFolderToFolderInput!) {
    addFolderToFolder(input: $input) {
      id
    }
  }
`;

export const REMOVE_FOLDER_FROM_FOLDER = gql`
  mutation removeFolderFromFolder($id: Int!) {
    removeFolderFromFolder(id: $id)
  }
`;

export const IMPORT_FOLDER = gql`
  mutation importFolder($input: ImportFolderInput!) {
    importFolder(input: $input) {
      id
    }
  }
`;
