import { useInvitationItem } from "./useInvitationItem";
import { AcceptOwnershipModal } from "~modals";
import { Avatar, Button } from "~components";
import { IUserInvitation } from "~models";
import dayjs from "dayjs";
import { FC } from "react";

import styles from "./styles.module.scss";

interface IProps {
  item: IUserInvitation;
  index: number;
  handleButtonClick: (index: number, id: number) => void;
}

export const InvationItem: FC<IProps> = ({
  item,
  index,
  handleButtonClick,
}) => {
  const {
    showModal,
    handleAcceptButton,
    handleDeclineButton,
    showAcceptLoader,
    showDeclineLoader,
    handleCloseModal,
  } = useInvitationItem({ item, index, handleButtonClick });

  return (
    <div>
      <div className={styles.invationItem}>
        <div className={styles.invationItem__organization}>
          {/* <div className={styles.invationItem__organizationAvatar}>
            {!!item.organization.picture && (
              <img src={item.organization.picture} />
            )}
          </div> */}
          <Avatar
            uri={item.organization.picture}
            item={item.organization}
            type="organization"
            containerStyles={styles.invationItem__organizationAvatar}
          />
          {item.organization.name}
        </div>

        <div className={styles.invationItem__members}>
          {item.organization?._count?.members} members
          {/* {!!item.invations && (
            <div className={styles.invationItem__membersCounter}>
              <NumberWidget text={item.invations} backgroundType={"red"} />
            </div>
          )} */}
        </div>

        <div className={styles.invationItem__date}>
          {dayjs(item.createdAt).format("MMMM D, YYYY")}
        </div>

        <div className={styles.invationItem__role}>
          <span>{item.role}</span>

          <div className={styles.invationItem__roleRight}>
            <div className={styles.invationItem__roleButton}>
              <Button
                onClick={handleAcceptButton}
                loading={showAcceptLoader}
                backgroundType={"green"}
                text={"Accept"}
              />
            </div>

            <div className={styles.invationItem__roleButton}>
              <Button
                onClick={handleDeclineButton}
                loading={showDeclineLoader}
                backgroundType={"none"}
                backgroundColor={"#EF2F32"}
                text={"Decline"}
                textColor={"#FFFFFF"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.invationItem__underline} />

      {showModal && (
        <AcceptOwnershipModal
          invitation={item}
          removeLocalFile={() => handleButtonClick(index, item.id)}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
};
