import { choosedOrganizationVar, GET_CHOOSED_ORGANIZATIOM } from "~localCache";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_MY_ORGANIZATIONS, GET_ORGANIZATION_DETAIL } from "~queries";
import { IUserInvitation } from "~models";
import { useState } from "react";
import {
  ACCEPT_ORGANIZATION_INVITATION,
  DECLINE_ORGANIZATION_INVITATION,
} from "~mutations";

interface IProps {
  item: IUserInvitation;
  index: number;
  handleButtonClick: (value: number, id: number) => void;
}

export const useInvitationItem = ({
  item,
  index,
  handleButtonClick,
}: IProps) => {
  const [queryGetMyOrganization] = useLazyQuery(GET_MY_ORGANIZATIONS, {
    fetchPolicy: "network-only",
  });

  const [getOrganizationDetail] = useLazyQuery(GET_ORGANIZATION_DETAIL, {
    fetchPolicy: "network-only",
  });

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [showAcceptLoader, setShowAcceptLoader] = useState(false);
  const [showDeclineLoader, setShowDeclineLoader] = useState(false);

  const [mutationAcceptInvitation] = useMutation(
    ACCEPT_ORGANIZATION_INVITATION
  );
  const [mutationDeclineInvitation] = useMutation(
    DECLINE_ORGANIZATION_INVITATION
  );

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  const handleAcceptButton = () => {
    // if (item.role === "Owner") {
    //   setShowModal(true);
    // } else {
    setShowAcceptLoader(true);
    mutationAcceptInvitation({ variables: { id: item.id } }).then(
      async (resp) => {
        await queryGetMyOrganization({ variables: {} });
        !choosedOrganization &&
          choosedOrganizationVar(resp.data.acceptOrganizationInvitations);
        setShowAcceptLoader(false);
        handleButtonClick(index, item.id);
      }
    );
    // }
  };

  const handleDeclineButton = () => {
    setShowDeclineLoader(true);
    mutationDeclineInvitation({ variables: { id: item.id } }).then(() => {
      setShowDeclineLoader(false);
      handleButtonClick(index, item.id);
    });
  };

  return {
    showModal,
    showAcceptLoader,
    showDeclineLoader,
    handleCloseModal,
    handleAcceptButton,
    handleDeclineButton,
  };
};
