import ContentLoader from "react-content-loader";
import { Outlet } from "react-router-dom";
import { LeftPanel } from "./LeftPanel";
import { useDashboard } from "./useDashboard";
import styles from "./styles.module.scss";

export const Dashboard = () => {
  const { loading, showPanel } = useDashboard();

  return (
    <div className={styles.dashboardNavigation}>
      <LeftPanel loading={loading} showPanel={showPanel} />
      {!showPanel && (
        <div className={styles.dashboardNavigation__content}>
          {loading ? (
            <div className={styles.loaderBlock}>
              <div className={styles.loaderBlock__header}>
                <ContentLoader
                  speed={1}
                  width={1096}
                  height={32}
                  style={{ marginTop: "31px", marginBottom: "31px" }}
                  viewBox="0 0 1096 32"
                  backgroundColor="#DBDEE7"
                  foregroundColor="#ebedf2"
                >
                  <rect x="0" y="0" rx="7" ry="7" width="277" height="32" />
                </ContentLoader>
              </div>
              <div className={styles.loaderBlock__content}>
                <ContentLoader
                  speed={1}
                  width={1164}
                  height={770}
                  backgroundColor="#DBDEE7"
                  foregroundColor="#ebedf2"
                  title=""
                  style={{ width: "100%" }}
                >
                  <rect x="0" y="0" rx="7" ry="7" width="412" height="32" />
                  <rect x="0" y="57" rx="7" ry="7" width="100em" height="44" />
                  <rect x="0" y="126" rx="7" ry="7" width="100em" height="40" />

                  <rect x="12" y="180" rx="5" ry="5" width="20" height="20" />
                  <rect x="52" y="180" rx="5" ry="5" width="268" height="20" />
                  <rect x="0" y="214" rx="7" ry="7" width="100em" height="2" />

                  <rect x="12" y="229" rx="5" ry="5" width="20" height="20" />
                  <rect x="52" y="229" rx="5" ry="5" width="268" height="20" />
                  <rect x="0" y="263" rx="7" ry="7" width="100em" height="2" />

                  <rect x="12" y="278" rx="5" ry="5" width="20" height="20" />
                  <rect x="52" y="278" rx="5" ry="5" width="268" height="20" />
                  <rect x="0" y="312" rx="7" ry="7" width="100em" height="2" />

                  <rect x="12" y="327" rx="5" ry="5" width="20" height="20" />
                  <rect x="52" y="327" rx="5" ry="5" width="268" height="20" />
                  <rect x="0" y="361" rx="7" ry="7" width="100em" height="2" />

                  <rect x="12" y="376" rx="5" ry="5" width="20" height="20" />
                  <rect x="52" y="376" rx="5" ry="5" width="268" height="20" />
                  <rect x="0" y="410" rx="7" ry="7" width="100em" height="2" />

                  <rect x="12" y="425" rx="5" ry="5" width="20" height="20" />
                  <rect x="52" y="425" rx="5" ry="5" width="268" height="20" />
                  <rect x="0" y="459" rx="7" ry="7" width="100em" height="2" />

                  <rect x="12" y="474" rx="5" ry="5" width="20" height="20" />
                  <rect x="52" y="474" rx="5" ry="5" width="268" height="20" />
                  <rect x="0" y="508" rx="7" ry="7" width="100em" height="2" />

                  <rect x="12" y="523" rx="5" ry="5" width="20" height="20" />
                  <rect x="52" y="523" rx="5" ry="5" width="268" height="20" />
                  <rect x="0" y="557" rx="7" ry="7" width="100em" height="2" />

                  <rect x="12" y="572" rx="5" ry="5" width="20" height="20" />
                  <rect x="52" y="572" rx="5" ry="5" width="268" height="20" />
                  <rect x="0" y="606" rx="7" ry="7" width="100em" height="2" />

                  <rect x="12" y="621" rx="5" ry="5" width="20" height="20" />
                  <rect x="52" y="621" rx="5" ry="5" width="268" height="20" />
                  <rect x="0" y="655" rx="7" ry="7" width="100em" height="2" />

                  <rect x="12" y="670" rx="5" ry="5" width="20" height="20" />
                  <rect x="52" y="670" rx="5" ry="5" width="268" height="20" />
                  <rect x="0" y="704" rx="7" ry="7" width="100em" height="2" />
                </ContentLoader>
              </div>
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      )}
    </div>
  );
};
