import styles from "./styles.module.scss";
import { useChecklist } from "./useChecklist";
import { BuilderProvider } from "./context";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import { CheckMark, LeftArrow, Loader, Refresh, TrashAlt } from "~icons";
import { Button } from "~components";
import { Footer, Section } from "./modules";
import clsx from "clsx";

import dayjs from "dayjs";
import { CompleteChecklistModal, DeleteChecklistModal } from "./modals";
import PdfTab from "./modules/PdfTab";
import { useEffect, useRef } from "react";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";
import { ActiveListsModule } from "../../../../navigations/Dashboard/LeftPanel/modules";

export const WrappedChecklist = () => {
  const {
    list,
    pdfFormat,
    activeTabIndex,
    showCompleteLoader,
    showRemoveLoader,
    progress,
    myRole,
    checklistLoading,
    showCompleteModal,
    showResetLoader,
    showDeleteModal,
    showPdfTab,
    saveLastChanges,
    goBack,
    handleBackArrowClick,
    handleCompleteClick,
    handleDeleteButton,
    hideCompleteModal,
    completeChecklist,
    handleResetButton,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
  } = useChecklist();

  const handleExportWithMethod = () => {
    window.print();
  };
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [activeTabIndex]);

  const isDark = useReactiveVar(darkTheme);

  return (
    <div
      className={
        isDark
          ? clsx(
              styles.checklist,
              styles.checklistDark,
              pdfFormat && styles.fullHeight
            )
          : clsx(styles.checklist, pdfFormat && styles.fullHeight)
      }
    >
      <div className={styles.checklist__arrow} onClick={handleBackArrowClick}>
        <LeftArrow fill="#0B0F1C" />
      </div>

      <div className={styles.checklist__content}>
        {checklistLoading ? (
          <div className={styles.checklist__loader}>
            <div className={styles.checklist__loaderTitle}></div>
            <div className={styles.checklist__loaderSubtitle}></div>

            <div className={styles.checklist__loaderContent}>
              <Loader fill="#29B473" />
            </div>
          </div>
        ) : (
          <>
            <div className={styles.checklist__header}>
              <div className={styles.checklist__headerContent}>
                <div
                  className={clsx(styles.checklist__headerLeft, {
                    [styles.completed]: progress.completed,
                  })}
                >
                  <p>{list.title}</p>
                  <div>{progress.completed ? "Completed" : "In progress"}</div>
                </div>
                <div className={styles.checklist__headerRight}>
                  <div className={styles.checklist__headerRightActionButton}>
                    <ActiveListsModule noLabel />
                  </div>
                  {pdfFormat ? (
                    <div className={styles.checklist__headerRightPdf}>
                      <Button
                        onClick={handleExportWithMethod}
                        text="Download PDF"
                        backgroundType={"green"}
                      />
                    </div>
                  ) : (
                    <>
                      {!progress.completed && (
                        <>
                          <div
                            onClick={handleResetButton}
                            className={clsx(
                              styles.checklist__headerRightReset,
                              {
                                [styles.loading]: showResetLoader,
                              }
                            )}
                          >
                            {/* <Button
                            loading={showResetLoader}
                            onClick={handleResetButton}
                            text="Reset"
                            backgroundType={"green"}
                          /> */}
                            {showResetLoader ? (
                              <Loader fill="#0B0F1C" />
                            ) : (
                              <Refresh fill="#0B0F1C" />
                            )}
                          </div>
                          <div
                            className={styles.checklist__headerRightCompleted}
                          >
                            <Button
                              loading={showCompleteLoader}
                              onClick={handleCompleteClick}
                              icon={
                                <CheckMark fill="#FFFFFF" stroke="#FFFFFF" />
                              }
                              text="Complete"
                              backgroundType={"green"}
                            />
                          </div>
                        </>
                      )}

                      {!(myRole === "User" && progress.completed) && (
                        <div
                          className={styles.checklist__deleteButton}
                          onClick={handleOpenDeleteModal}
                        >
                          {showRemoveLoader ? (
                            <div
                              className={styles.checklist__deleteButtonLoader}
                            >
                              <Loader fill="#EF2F32" />
                            </div>
                          ) : (
                            <TrashAlt fill="#EF2F32" />
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                {/* {progress.completed && !pdfFormat && (
                <>
                  <div className={styles.checklist__dates}>
                    <div className={styles.checklist__datesStart}>
                      STARTED:{" "}
                      {dayjs(progress.createdAt).format("hh:mm:ss A DD.MM.YY")}
                    </div>
                    <div className={styles.checklist__datesFinish}>
                      FINISHED{" "}
                      {dayjs(progress.updatedAt).format("hh:mm:ss A DD.MM.YY")}
                    </div>
                  </div>
                </>
              )} */}
              </div>
            </div>

            {progress.completed && (
              <>
                <div
                  className={clsx(styles.checklist__dates, {
                    [styles.padding]: true,
                  })}
                >
                  <div className={styles.checklist__datesStart}>
                    STARTED:{" "}
                    {dayjs(progress.createdAt).format("hh:mm:ss A DD.MM.YY")}
                  </div>
                  <div className={styles.checklist__datesFinish}>
                    FINISHED{" "}
                    {dayjs(progress.updatedAt).format("hh:mm:ss A DD.MM.YY")}
                  </div>
                </div>
              </>
            )}

            {progress.completed && !!progress?.user?.email && (
              <div className={styles.checklist__email}>
                {progress.user.email}
              </div>
            )}

            {showPdfTab ? (
              <div className={styles.checklist__contentPdf}>
                {list.pdfUrl && <PdfTab url={list.pdfUrl} />}
              </div>
            ) : (
              <>
                {pdfFormat ? (
                  <div className="toPdf">
                    {list.tabs.map((item, index) => {
                      return (
                        <div key={`${item.id} - ${index}`}>
                          <p className={styles.checklist__tabTitle}>
                            {item.name}
                          </p>
                          <div className={styles.checklist__contentSections}>
                            {item.sections.map((item, index) => (
                              <Section
                                key={`${item.id} - ${index} - section`}
                                section={item}
                                index={index}
                              />
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    <p className={styles.checklist__tabTitle}>
                      {list.tabs[activeTabIndex].name}
                    </p>
                    <div
                      ref={containerRef}
                      className={styles.checklist__contentSections}
                    >
                      {list.tabs[activeTabIndex].sections.map((item, index) => (
                        <Section
                          key={`${item.id} - ${index} - section`}
                          section={item}
                          index={index}
                        />
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>

      {!pdfFormat && !checklistLoading && (
        <Footer saveLastChanges={saveLastChanges} />
      )}

      <CompleteChecklistModal
        showLoader={showCompleteLoader}
        isOpen={showCompleteModal}
        onCloseModal={hideCompleteModal}
        handleCompleteChecklist={completeChecklist}
      />

      <DeleteChecklistModal
        isOpen={showDeleteModal}
        onCloseModal={handleCloseDeleteModal}
        handleDeleteChecklist={handleDeleteButton}
        showLoader={showRemoveLoader}
      />
    </div>
  );
};

export const Checklist = () => {
  return (
    <BuilderProvider>
      <WrappedChecklist />
    </BuilderProvider>
  );
};
