import { PlusSymbol, Trash } from "~icons";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { Button, Input } from "~components";
import { useResultItem } from "./useResultItem";
import { IItem } from "~models";

interface IProps {
  item: IItem;
  itemIndex: number;
}

interface IResult {
  from: string;
  to: string;
  result: string;
}

export const ResultItem = ({ item, itemIndex }: IProps) => {
  const {
    blockDeletingResult,
    handleAddResult,
    handleDeleteResult,
    handleResultNameChange,
    handleResultFromChange,
    handleResultToChange,
    handleBlurFrom,
    handleBlurTo,
  } = useResultItem({
    item,
    itemIndex,
  });
  return (
    <div className={styles.resultItem}>
      <p className={styles.resultItem__label}>Assessment Result</p>

      {item.data.results.map((result: IResult, resultIndex: number) => (
        <div
          key={`result - ${resultIndex}`}
          className={styles.resultItem__item}
        >
          <div className={styles.resultItem__itemScore}>
            <Input
              onBlur={() => handleBlurFrom(resultIndex)}
              label={resultIndex === 0 ? "Score" : undefined}
              placeholder="From"
              value={result.from}
              onChange={(value) => handleResultFromChange(value, resultIndex)}
            />
          </div>
          <p>-</p>
          <div className={styles.resultItem__itemScore}>
            <Input
              onBlur={() => handleBlurTo(resultIndex)}
              value={result.to}
              placeholder="To"
              onChange={(value) => handleResultToChange(value, resultIndex)}
            />
          </div>
          <div className={styles.resultItem__itemResult}>
            <Input
              value={result.result}
              onChange={(value) => handleResultNameChange(value, resultIndex)}
              label={resultIndex === 0 ? "Result" : undefined}
              placeholder="Enter result..."
            />
          </div>
          <div
            className={clsx({ [styles.disable]: blockDeletingResult })}
            onClick={() => handleDeleteResult(resultIndex)}
          >
            <Trash
              stroke={blockDeletingResult ? "hsl(240, 11%, 96%)" : "#979DB1"}
            />
          </div>
        </div>
      ))}

      <div className={styles.resultItem__addButton}>
        <Button
          onClick={handleAddResult}
          text="Add result"
          backgroundType={"white"}
          showShadow
          textColor="#29B473"
          icon={<PlusSymbol fill="#29B473" />}
        />
      </div>
    </div>
  );
};
