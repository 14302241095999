import { useChecklist } from "../../../../context";
import { IItem } from "~models";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const useAlgorithm = ({ item, isDisable, progressItem }: IProps) => {
  const { setProgress, progress } = useChecklist();

  const disableCheckButton = !!progressItem?.data
    ? progressItem?.data.length < item.data.assessments.length
    : true;

  const itemIndex = progress.data.items.findIndex(
    (elem: { id: number }) => elem.id === item.id
  );

  const handleSelectFinding = (
    e: React.MouseEvent,
    assesmentIndex: number,
    findingIndex: number,
    score: number
  ) => {
    e.stopPropagation()
    if (isDisable) return;

    setProgress((prev) => {
      let state = { ...prev };
      if (!!state.data.items[itemIndex]?.data) {
        let index = state.data.items[itemIndex].data.findIndex(
          (item: { assesmentIndex: number; findingIndex: number }) =>
            item.assesmentIndex === assesmentIndex
        );

        if (index > -1) {
          if (
            state.data.items[itemIndex].data[index].findingIndex ===
            findingIndex
          ) {
            state.data.items[itemIndex].data.splice(index, 1);
          } else {
            state.data.items[itemIndex].data[index] = {
              assesmentIndex,
              findingIndex,
              score,
            };
          }
        } else {
          state.data.items[itemIndex].data.push({
            assesmentIndex,
            findingIndex,
            score,
          });
        }
      } else {
        state.data.items[itemIndex].data = [
          { assesmentIndex, findingIndex, score },
        ];
      }
      return state;
    });
  };

  const handleCheckButton = () => {
    if (isDisable) return;

    let sum = progressItem.data.reduce(
      (changingValue: any, currentValue: any) =>
        changingValue + +currentValue.score,
      0
    );

    const resultIndex = item.data.results.findIndex(
      (item: { from: string; to: string }) =>
        +item.from <= sum && +item.to >= sum
    );

    if (resultIndex > -1) {
      setProgress((prev) => {
        let state = { ...prev };
        state.data.items[itemIndex].result = {
          ...item.data.results[resultIndex],
          score: sum,
        };
        return state;
      });
    } else {
      setProgress((prev) => {
        let state = { ...prev };
        state.data.items[itemIndex].result = null;
        return state;
      });
    }
  };

  return { disableCheckButton, handleSelectFinding, handleCheckButton };
};
