import { CheckSquareBroken, ListOfChecklists, PlusSymbol } from "~icons";
import styles from "./styles.module.scss";
import { Button, NumberWidget } from "~components";
import { useFooter } from "./useFooter";
import clsx from "clsx";
import Popup from "reactjs-popup";
import { IActiveChecklist } from "~models";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

interface IProps {
  saveLastChanges: () => void;
}

export const Footer = ({ saveLastChanges }: IProps) => {
  const [showLargeActionButton, setShowLargeActionButton] = useState(
    window.innerWidth > 826
  );

  const ACTION_BUTTON_WIDTH = showLargeActionButton ? 280 : 85;

  const {
    isPreview,
    allProggress,
    progress,
    popupRef,
    list,
    activeTabIndex,
    popupContentRef,
    popupTriggerRef,
    showPdfTab,
    handleTabButton,
    handleShopPopup,
    handleItemClick,
    handleShowPdfTab,
    handleCreateButtonClick,
    countDublicates,
  } = useFooter();

  const [removeCenteringItems, setRemoveCenteringItems] = useState(false);

  const itemsWrapperRef = useRef<HTMLDivElement>(null);

  function handleWindowResize() {
    if (itemsWrapperRef?.current) {
      const ACTION_BUTTON_WIDTH = window.innerWidth > 826 ? 280 : 85;
      if (
        itemsWrapperRef.current.clientWidth + ACTION_BUTTON_WIDTH * 2 >=
        window.innerWidth
      ) {
        setRemoveCenteringItems(true);
      } else {
        setRemoveCenteringItems(false);
      }
    }

    setShowLargeActionButton(window.innerWidth > 826);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useLayoutEffect(() => {
    handleWindowResize();
  }, []);

  return (
    <div className={styles.footer}>
      <Popup
        ref={popupRef}
        arrow={false}
        lockScroll={true}
        closeOnDocumentClick={false}
        position={"top left"}
        trigger={<div style={{ position: "absolute", left: 0, top: 0 }} />}
      >
        <div ref={popupContentRef} className={styles.popup}>
          {countDublicates(allProggress).map(
            (item: IActiveChecklist, index: number) => (
              <div
                key={`${index} - active list`}
                onClick={() => {
                  if (item.id !== progress.id) {
                    saveLastChanges();
                    handleItemClick(item.checklistId, item.id);
                  }
                }}
              >
                <div
                  className={clsx(styles.popup__item, {
                    [styles.disable]: item.id === progress.id,
                  })}
                >
                  <CheckSquareBroken stroke="#29B473" />
                  <p>{item.checklist.title}</p>
                </div>
                {index !== allProggress.length - 1 && (
                  <div className={styles.popup__itemUnderline} />
                )}
              </div>
            )
          )}
          <div className={styles.popup__button}>
            <Button
              onClick={() => {
                saveLastChanges();
                handleCreateButtonClick();
              }}
              text="Start new"
              icon={<PlusSymbol fill="#FFFFFF" />}
              backgroundType="green"
            />
          </div>
        </div>
      </Popup>

      {/* {allProggress.length > 0 && !isPreview && (
        <div
          ref={popupTriggerRef}
          className={styles.footer__actionButton}
          onClick={handleShopPopup}
          style={{ width: ACTION_BUTTON_WIDTH }}
        >
          <div>
            <ListOfChecklists />
            {showLargeActionButton && <>Action bar</>} */}

            {/* {allProggress.length > 0 ? "Action bar" : "No Active In Progress"} */}
          {/* </div>
          <NumberWidget text={allProggress.length} backgroundType={"blue"} />
        </div> */}
      {/* )} */}

      <div
        className={clsx(
          styles.footer__scrollZone,
          (removeCenteringItems || allProggress.length === 0 || isPreview) &&
            styles.removeCenteringItems
        )}
        style={{
          paddingRight:
            allProggress.length === 0 || isPreview || removeCenteringItems
              ? 0
              : ACTION_BUTTON_WIDTH,
        }}
      >
        <div
          ref={itemsWrapperRef}
          className={clsx(
            styles.footer__itemsWrapper,
            (allProggress.length === 0 || isPreview) && styles.centering
          )}
        >
          {!!list.pdfUrl && (
            <div className={styles.footer__pdfTab} onClick={handleShowPdfTab}>
              PDF protocol
            </div>
          )}

          {list.tabs.map((item, index) => (
            <div
              key={`${item.id} - ${index}`}
              onClick={() => handleTabButton(item.id)}
              className={clsx(styles.footer__item, {
                [styles.leftBorder]: index === 0,
                [styles.active]: activeTabIndex === index && !showPdfTab,
              })}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
