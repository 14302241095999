import { IItem } from "~models";
import { useChecklist } from "../../../../context";
import dayjs from "dayjs";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const checkInputExpDate = (value: string) => {
  if (value.length > 0 && +value[0] !== 0 && +value[0] !== 1) return false;

  if (value.length >= 2) {
    const month = +value.substring(0, 2);
    if (month > 12) return false;
  }

  if (value.length >= 4 && (+value[3] > 2 || +value[3] === 0)) return;
  // if (value.length >= 6 && +value[5] < 2) return false;

  return true;
};

export const checkExpDate = (value: string) => {
  if (value.length < 7) return false;
  const month = +value.substring(0, 2);
  const year = +value.substring(3, 7);

  const currentYear = dayjs().year();
  const currentMonth = dayjs().month() + 1;

  if (year < currentYear || (year === currentYear && month < currentMonth))
    return false;

  return true;
};

const CHECK_STATUSES = {
  INVALID_NAME_AND_DATE:
    "Warning: incorrect medication name entered and medication has expired",
  INVALID_NAME: "Warning: incorrect medication name entered",
  DATE_EXPIRED: "Warning: medication has expired",
  HAS_NOT_EXPIRED: "Med-check successful!",
  OVERRODE: "Caution: med-check overrode",
};

export const useMedCheck = ({ item, isDisable, progressItem }: IProps) => {
  const { progress, setProgress } = useChecklist();

  const itemIndex = progress.data.items.findIndex(
    (elem: { id: number }) => elem.id === item.id
  );

  const handleNameChange = (value: string) => {
    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].name = value;
      if (!!state.data.items[itemIndex].status) {
        state.data.items[itemIndex].status = "";
      }
      return state;
    });
  };

  const handleDateChange = (value: string) => {
    if (!checkInputExpDate(value)) return;
    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].exp = value;
      if (!!state.data.items[itemIndex].status) {
        state.data.items[itemIndex].status = "";
      }
      return state;
    });
  };

  const handleChangeStatus = (key: keyof typeof CHECK_STATUSES) => {
    setProgress((prev) => {
      let state = { ...prev };
      state.data.items[itemIndex].status = key;
      return state;
    });
  };

  const handleCheck = () => {
    if (
      item.data.findIndex(
        (item: string) => item.toLowerCase() === progressItem.name.toLowerCase()
      ) === -1
    ) {
      if (!checkExpDate(progressItem.exp)) {
        handleChangeStatus("INVALID_NAME_AND_DATE");
        return;
      }
      handleChangeStatus("INVALID_NAME");
      return;
    }

    if (!checkExpDate(progressItem.exp)) {
      handleChangeStatus("DATE_EXPIRED");
      return;
    }

    handleChangeStatus("HAS_NOT_EXPIRED");
  };

  const handleOverrideClick = () => handleChangeStatus("OVERRODE");

  const disableCheckButton =
    isDisable ||
    !progressItem?.name ||
    !progressItem?.exp ||
    progressItem?.exp.length !== 7;

  const disableOverrideButton =
    isDisable ||
    !progressItem.status ||
    progressItem.status === "HAS_NOT_EXPIRED";

  return {
    disableCheckButton,
    disableOverrideButton,
    CHECK_STATUSES,
    handleNameChange,
    handleDateChange,
    handleCheck,
    handleOverrideClick,
  };
};
