import { DashboardLayout } from "~layouts";
import styles from "./styles.module.scss";
import { Pagination, SearchInput, Table, TableLoader } from "~components";
import { useHelpAndFeedback } from "./useHelpAndFeedback";
import { FeedbackItem } from "./FeedbackItem";

export const HelpAndFeedback = () => {
  const { loading, searchValue, handleSerchValueChange } = useHelpAndFeedback();
  return (
    <DashboardLayout title="Help and Feedback">
      <div className={styles.helpAndFeedback}>
        <div className={styles.helpAndFeedback__search}>
          <SearchInput
            placeholder="Search"
            value={searchValue}
            onChange={handleSerchValueChange}
          />
        </div>

        {loading && <TableLoader />}

        {!loading && (
          <Table
            header={[
              {
                header: "Reporter",
                className: styles.helpAndFeedback__reporter,
              },
              {
                header: "Category",
                className: styles.helpAndFeedback__category,
              },
              {
                header: "Date of Feedback",
                className: styles.helpAndFeedback__date,
              },
            ]}
            data={["1", "2", "3"]}
            renderTableItem={() => <FeedbackItem />}
          />
        )}

        <Pagination />
      </div>
    </DashboardLayout>
  );
};
