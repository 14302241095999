import { CheckSquareBroken, EmptyPlay } from "~icons";
import { useChecklist } from "../../useChecklist";
import { Button } from "~components";
import { IDraft } from "~models";
import { FC } from "react";

import styles from "./styles.module.scss";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";
import clsx from "clsx";

interface IProps {
  checklist: IDraft;
}

export const ChecklistItemSquare: FC<IProps> = ({ checklist }: IProps) => {
  const { showLoader, handleStart } = useChecklist({ checklist });
  const isDark = useReactiveVar(darkTheme);

  return (
    <div>
      <div className={isDark ? clsx(styles.checklistItemSquare, styles.checklistItemSquareDark) : styles.checklistItemSquare}>
        <div className={styles.checklistItemSquare__left}>
          <CheckSquareBroken stroke="#29B473" />
        </div>
        <div className={styles.checklistItemSquare__right}>
          <p>{checklist.title}</p>
          <Button
            loading={showLoader}
            onClick={handleStart}
            text="Start"
            backgroundType={"green"}
            icon={<EmptyPlay stroke="white" />}
          />
        </div>
      </div>
    </div>
  );
};
