import { useEffect, useRef, useState } from "react";
import { IItem } from "~models";
import { useChecklist } from "../../../../context";

interface IProps {
  item: IItem;
  progressItem: any;
  isDisable: boolean;
}

export const useCounter = ({ item, progressItem, isDisable }: IProps) => {
  const { progress, setProgress } = useChecklist();

  const itemIndex = progress.data.items.findIndex(
    (elem: { id: number }) => elem.id === item.id
  );

  const [focused, setFocused] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleActiveFocus = () => setFocused(true);
  const handleDisactiveFocus = (
    e: React.FocusEvent<HTMLTextAreaElement, Element>
  ) => {
    e.stopPropagation();
    setFocused(false);
  };

  const minValue = item.data.allowNegative ? null : 0;

  const onChange = (value: string) => {
    setProgress((prev) => {
      let state = { ...prev };

      state.data.items[itemIndex].value = value;
      return state;
    });
  };

  const disableMinusButton =
    (minValue !== null && progressItem.value == minValue) || isDisable;

  const addTimestamp = () => {
    let timestamp = new Date().getTime();
    setProgress((prev) => {
      let state = { ...prev };
      if (!!state.data.items[itemIndex].timestamps) {
        state.data.items[itemIndex].timestamps.push(timestamp);
      } else {
        state.data.items[itemIndex].timestamps = [timestamp];
      }
      return state;
    });
  };

  const removeLastTimestamp = () => {
    setProgress((prev) => {
      let state = { ...prev };
      if (
        Array.isArray(state.data.items[itemIndex]?.timestamps) &&
        state.data.items[itemIndex].timestamps.length > 0
      )
        state.data.items[itemIndex].timestamps.pop();

      return state;
    });
  };

  const handleMinusButton = () => {
    if (!disableMinusButton && !isDisable) {
      +progressItem?.value > 0 ? removeLastTimestamp() : addTimestamp();
      onChange(`${+progressItem?.value - 1}`);
    }
  };
  const handlePlusButton = () => {
    if (!isDisable) {
      onChange(`${+progressItem?.value + 1}`);
      +progressItem?.value < 0 ? removeLastTimestamp() : addTimestamp();
    }
  };

  const onBlur = () => {
    if (
      progressItem?.value.length === 0 ||
      (progressItem?.value.length === 1 && progressItem?.value === "-")
    ) {
      onChange("0");
    } else {
      addTimestamp();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      /^[0-9]*$/.test(e.target.value) ||
      e.target.value === "" ||
      (minValue !== 0 && e.target.value[0] === "-")
    ) {
      const number = +e.target.value;
      if (typeof minValue === "number" && number < minValue) return;

      if (
        e.target.value.length >= 2 &&
        e.target.value[0] === "-" &&
        e.target.value[1] === "0"
      ) {
        return;
      }

      if (e.target.value.length >= 2 && e.target.value[0] === "0") {
        onChange(e.target.value.substring(1));
        return;
      }

      onChange(e.target.value);
    }
  };

  useEffect(() => {
    if (typeof progressItem?.value === "undefined") {
      onChange("0");
    }
  }, []);

  return {
    handleActiveFocus,
    handleDisactiveFocus,
    handleMinusButton,
    handleInputChange,
    handlePlusButton,
    onBlur,
    disableMinusButton,
    focused,
    textareaRef,
  };
};
