import styles from "./styles.module.scss";

import { IItem } from "../../../../../constants";
import { Button, Input } from "~components";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

interface IProps {
  item: IItem;
  isDisable: boolean;
}

export const TextBox = ({ item, isDisable }: IProps) => {
  let model =
    typeof item.data === "string"
      ? JSON.parse(item.data).model
      : item.data.model;

  return (
    <div className={styles.textBox}>
      {!model ? (
        <div className={styles.textBox__noData}>No data</div>
      ) : (
        <>
          <FroalaEditorView model={model} />
        </>
      )}
    </div>
  );
};
