import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  GET_ALL_ORGANIZATIONS_COUNT,
  GET_MEMBERS_PAGINATION_DATA,
  GET_MY_INVITATIONS,
  GET_MY_ORGANIZATIONS,
  GET_MY_ORGANIZATION_REQUESTS,
  GET_ORGANIZATIONS_PAGINATION_DATA,
  GET_PAGINATION_ADMINS_DATA,
} from "~queries";

import { GET_CHOOSED_ORGANIZATIOM } from "~localCache";
import { useUpdateEffect } from "usehooks-ts";
import {
  IOrganization,
  IOrganizationRequest,
  OrganizationMember,
  OrganizationMemberRole,
} from "~models";
import { DELETE_USER, LEAVE_ORGANIZATION } from "~mutations";

const PAGE_LIMIT = 10;

export const useAdminsManagement = () => {
  const [fetchedCount, setFetchedCount] = useState(0);
  const [members, setMembers] = useState<OrganizationMember[]>([]);
  const [allMembersCount, setAllMembersCount] = useState(0);

  const [searchValue, setSearchValue] = useState("");

  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [getData] = useLazyQuery(GET_PAGINATION_ADMINS_DATA);
  const [mutationLeaveOrganization] = useMutation(LEAVE_ORGANIZATION);
  const [deleteUser] = useMutation(DELETE_USER);

  const handleOpenCreateUserModal = () => setShowCreateUserModal(true);
  const handleCloseCreateUserModal = () => setShowCreateUserModal(false);

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const loadData = () => {
    setLoading(true);
    const countInput = {};
    const input = {
      skip: 0,
      take: PAGE_LIMIT,
    };

    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }

    getData({
      variables: { countInput, input },
      fetchPolicy: "no-cache",
    })
      .then((resp) => {
        setAllMembersCount(resp.data.adminsCount);
        setMembers(resp.data.admins);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const deleteMember = (id: number) => {
    setLoading(true);
    mutationLeaveOrganization({ variables: { id } }).then(() =>
      updateCurrentPage()
    );
  };

  const handleChangeRole = (newRole: OrganizationMemberRole, index: number) => {
    setMembers((prev) => {
      let state = [...prev];
      state[index].role = newRole;
      return state;
    });
  };

  const updateCurrentPage = () => {
    const maxPage = Math.ceil(allMembersCount);
    let skip = fetchedCount;
    if (members.length === 1 && fetchedCount >= PAGE_LIMIT) {
      skip = skip - PAGE_LIMIT;
    }
    if (skip > maxPage) return;
    setLoading(true);
    let take = PAGE_LIMIT;
    if (allMembersCount - skip < 10) {
      take = allMembersCount - skip;
    }
    const countInput = {};
    const input = {
      skip: skip,
      take: PAGE_LIMIT,
    };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllMembersCount(resp.data.adminsCount);
      setMembers(resp.data.admins);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(allMembersCount);
    const skip = fetchedCount + PAGE_LIMIT;
    if (skip >= maxPage) return;
    setLoading(true);
    let take = PAGE_LIMIT;
    if (allMembersCount - skip < 10) {
      take = allMembersCount - skip;
    }
    const countInput = {};
    const input = {
      skip: skip,
      take: PAGE_LIMIT,
    };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllMembersCount(resp.data.adminsCount);
      setMembers(resp.data.admins);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  const handlePrevPage = () => {
    const skip = fetchedCount - PAGE_LIMIT;
    if (skip < 0) return;
    setLoading(true);
    const countInput = {};
    const input = {
      skip: skip,
      take: PAGE_LIMIT,
    };
    if (!!searchValue) {
      Object.assign(countInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countInput, input },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllMembersCount(resp.data.adminsCount);
      setMembers(resp.data.admins);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  const handleDeleteAdmin = (id: number) => {
    setLoading(true);
    deleteUser({ variables: { id } }).then(() => updateCurrentPage());
  };

  const handleCreateUser = (isSuperAdmin: boolean) => {
    isSuperAdmin && loadData();
  };

  useUpdateEffect(() => {
    const skip = fetchedCount - PAGE_LIMIT;

    if (skip <= 0) loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [choosedOrganization?.id]);

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      loadData();
    }, 650);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    members,
    loadingMembers: false,
    allMembersCount,
    membersCount: 0,
    handleNextPage,
    handlePrevPage,
    fetchedCount,
    loading,
    searchValue,
    handleCreateUser,
    setSearchValue,
    handleChangeRole,
    limit: PAGE_LIMIT,
    choosedOrganization,
    deleteMember,
    showCreateUserModal,
    handleOpenCreateUserModal,
    handleCloseCreateUserModal,
    handleDeleteAdmin,
  };
};
