import { IItem } from "../../../../constants";
import { Input, SoundDropdown } from "~components";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { useCalculator } from "./useCalculator";
import { PlusSymbol, Trash } from "~icons";

interface IProps {
  item: IItem;
  itemIndex: number;
}

export const CalculatorSettings = ({ item, itemIndex }: IProps) => {
  const {
    alphabet,
    disableAddInputButton,
    handleAddInput,
    handleDeleteInput,
    handleChangeInput,
    handleAddOutput,
    handleDeleteOutput,
    handleChangeOutputName,
    handleChangeOutputLogic,
  } = useCalculator({ item, itemIndex });

  return (
    <div className={styles.calculatorSettings}>
      <div className={styles.calculatorSettings__underlineTop} />

      {item.data.inputs.map((input: { name: string }, index: number) => (
        <div
          key={`calculator - input - ${index}`}
          className={styles.calculatorSettings__input}
        >
          <p>{alphabet[index]}</p>{" "}
          <Input
            value={input.name}
            onChange={(value) => handleChangeInput(value, index)}
            placeholder="Enter input name..."
          />
          <div onClick={() => handleDeleteInput(index)}>
            <Trash stroke="#979DB1" />
          </div>
        </div>
      ))}

      <div
        onClick={handleAddInput}
        className={clsx(styles.calculatorSettings__addButton, {
          [styles.disable]: disableAddInputButton,
        })}
      >
        <PlusSymbol fill={disableAddInputButton ? "#DBDEE7" : "#29B473"} />
        Add another input
      </div>

      <div className={styles.calculatorSettings__underline} />

      {item.data.outputs.map(
        (output: { name: ""; logic: "" }, index: number) => (
          <>
            <div
              key={`calculator - output - ${index}`}
              className={styles.calculatorSettings__outputName}
            >
              <Input
                value={output.name}
                onChange={(value) => handleChangeOutputName(value, index)}
                label={`Output ${index + 1}`}
                placeholder="Enter output name..."
              />
              <div onClick={() => handleDeleteOutput(index)}>
                <Trash stroke="#979DB1" />
              </div>
            </div>
            <div className={styles.calculatorSettings__output}>
              <Input
                value={output.logic}
                onChange={(value) => handleChangeOutputLogic(value, index)}
                label="Calculating logic"
                placeholder="Ex: a*50"
              />

              <p>=</p>
              <div>Output {index + 1}</div>
            </div>
            <div className={styles.calculatorSettings__underline} />
          </>
        )
      )}

      <div
        onClick={handleAddOutput}
        className={styles.calculatorSettings__addButton}
      >
        <PlusSymbol fill="#29B473" />
        Add another output
      </div>
    </div>
  );
};
