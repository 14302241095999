import { Navigate } from "react-router-dom";
import { useAuth } from "~hooks";

export const DefaultRedirect = ({}) => {
  const { user } = useAuth();

  return !!user?.onboarding ? (
    <Navigate to={"/dashboard/home"} />
  ) : (
    <Navigate to={"/getStarted"} />
  );
};
