import { Button, Modal } from "~components";
import styles from "./styles.module.scss";

interface IProps {
  isOpen: boolean;
  showLoader: boolean;
  onCloseModal: () => void;
  handleDelete: () => void;
}

export const DeleteOrganization = ({
  isOpen,
  showLoader,
  onCloseModal,
  handleDelete,
}: IProps) => {
  if (!isOpen) return null;

  return (
    <Modal disableMediaStyles onCloseModal={onCloseModal}>
      <div className={styles.modal}>
        <p className={styles.modal__title}>
          Are you sure you want to delete the organization?
        </p>

        <div className={styles.modal__buttons}>
          <Button
            onClick={onCloseModal}
            text="Cancel"
            backgroundColor="#979DB1"
            textColor="#FFFFFF"
          />
          <Button
            loading={showLoader}
            onClick={handleDelete}
            text="Delete"
            backgroundColor="#EF2F32"
            textColor="#FFFFFF"
          />
        </div>
      </div>
    </Modal>
  );
};
