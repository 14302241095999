import styles from "./styles.module.scss";

import { IItem } from "../../../../../constants";
import { Button, Counter as CounterComponent } from "~components";
import { Return } from "~icons";
import { useAlgorithm } from "./useAlgorithm";
import FroalaEditor from "react-froala-wysiwyg";

interface IProps {
  item: IItem;
  isDisable: boolean;
}

interface IAssesment {
  title: string;
  description: string;
  findings: { name: string; score: number }[];
}

export const Algorithm = ({ item, isDisable }: IProps) => {
  const { data } = useAlgorithm({ item, isDisable });
  console.log(data.assessments, "desssc");
  return (
    <div className={styles.algorithm}>
      {data.assessments.map((assesment: IAssesment, assesmentIndex: number) => (
        <div key={`assesment - ${assesmentIndex}`}>
          <div className={styles.algorithm__underline} />
          <p className={styles.algorithm__title}>{assesment.title}</p>
          {/* <p className={styles.algorithm__description}>
            {assesment.description}
          </p> */}
          {assesment?.description ? (
            <FroalaEditor
              model={assesment.description}
              config={{
                key: "PYC4mA3A13C11D6B6C5A3eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4E4E1B9D6C3F5A5==",
                htmlUntouched: true,
                toolbarInline: true,
                toolbarVisibleWithoutSelection: true,
                isDisable: true,
                events: {
                  initialized() {
                    const editor: any = this;
                    editor.edit.off();
                  },
                },
              }}
            />
          ) : (
            <p className={styles.algorithm__description}>
              {assesment.description}
            </p>
          )}

          {assesment.findings.map((finding, findingIndex) => (
            <div
              key={`finding - ${findingIndex}`}
              className={styles.algorithm__selection}
            >
              {finding.name}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
