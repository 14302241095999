import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IMessage, INotepad } from "~models";
import { COMPLETE_CHAT, REMOVE_CHAT } from "~mutations";
import { GET_CHAT_AI_DATA, GET_CURRENT_USER } from "~queries";

export const useCheckemAI = () => {
  const [type, setType] = useState<"notepad" | "chat">("notepad");
  const params = useParams();
  const navigate = useNavigate();

  let id = !!params?.id ? +params.id : -1;

  const { data } = useQuery(GET_CURRENT_USER);

  const [getData] = useLazyQuery(GET_CHAT_AI_DATA);
  const [removeChat] = useMutation(REMOVE_CHAT);
  const [completeChat] = useMutation(COMPLETE_CHAT);

  const [showMainLoader, setShowMainLoader] = useState(true);
  const [showRemoveLoader, setShowRemoveLoader] = useState(false);
  const [showCompleteLoader, setShowCompleteLoader] = useState(false);

  const [messages, setMessages] = useState<IMessage[]>([]);
  const [notepad, setNotepad] = useState<INotepad | null>(null);

  const getInitialData = () => {
    setShowMainLoader(true);
    getData({
      variables: { chatId: id, input: { chatId: id } },
      fetchPolicy: "network-only",
    }).then((resp) => {
      setNotepad(resp.data.notepad);
      setMessages(resp.data.chatItems);
      setShowMainLoader(false);
    });
  };

  const handleRemoveButton = () => {
    setShowRemoveLoader(true);
    removeChat({ variables: { id } }).then(() => {
      navigate("/dashboard/home");
    });
  };

  const handleCompleteChat = () => {
    setShowCompleteLoader(true);
    completeChat({ variables: { id } }).then(() => {
      navigate("/dashboard/home");
    });
  };

  const handleGoBack = () => {
    navigate("/dashboard/home");
  };

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    if (
      window.confirm(
        "Please use the arrow button for exit, otherwise some changes may not be saved"
      )
    ) {
      handleGoBack();
    } else {
      window.history.pushState(null, "", window.location.pathname);
    }
  };

  useEffect(() => {
    getInitialData();
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  return {
    type,
    showMainLoader,
    messages,
    showRemoveLoader,
    notepad,
    showCompleteLoader,
    handleGoBack,
    setType,
    setNotepad,
    setMessages,
    handleRemoveButton,
    handleCompleteChat,
  };
};
