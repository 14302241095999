import {
  createContext,
  FC,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from "react";
import { IActiveItem, IError, defaultError, testChecklist } from "./constants";
import { IDraft, IOrganization } from "~models";
import { useQuery } from "@apollo/client";

const BuilderContext = createContext<{
  list: IDraft;
  activeTabId: number;
  activeTabIndex: number;
  activeItem: null | IActiveItem;
  isDragging: boolean;
  error: IError;
  pdfFormat: boolean;
  showPdfTab: boolean;
  setList: React.Dispatch<React.SetStateAction<IDraft>>;
  setActiveTabId: React.Dispatch<React.SetStateAction<number>>;
  setActiveItem: React.Dispatch<React.SetStateAction<IActiveItem | null>>;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPdfFormat: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<IError>>;
  setShowPdfTab: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  list: testChecklist,
  activeTabId: testChecklist.tabs[0].id,
  activeTabIndex: 0,
  activeItem: null,
  isDragging: false,
  error: defaultError,
  pdfFormat: false,
  showPdfTab: false,
  setList: () => {},
  setActiveTabId: () => {},
  setActiveItem: () => {},
  setIsDragging: () => {},
  setIsPdfFormat: () => {},
  setError: () => {},
  setShowPdfTab: () => {},
});

interface IProps {
  children: ReactNode;
}

export const useBuilder = () => {
  return useContext(BuilderContext);
};

export const BuilderProvider: FC<IProps> = ({ children }) => {
  let [list, setList] = useState<IDraft>(
    JSON.parse(JSON.stringify(testChecklist))
  );

  const [showPdfTab, setShowPdfTab] = useState(false);
  const [pdfFormat, setIsPdfFormat] = useState<boolean>(false);

  let [error, setError] = useState<IError>(defaultError);

  const [isDragging, setIsDragging] = useState(false);
  const [activeTabId, setActiveTabId] = useState<number>(
    testChecklist.tabs[0].id
  );
  const [activeItem, setActiveItem] = useState<IActiveItem | null>(null);

  const activeTabIndex = list.tabs.findIndex((item) => item.id === activeTabId);
  if (activeTabIndex === -1) {
    setActiveTabId(list.tabs[0].id);
  }

  return (
    <BuilderContext.Provider
      value={{
        list,
        activeTabId,
        activeItem,
        activeTabIndex: activeTabIndex > -1 ? activeTabIndex : 0,
        isDragging,
        error,
        pdfFormat,
        showPdfTab,
        setShowPdfTab,
        setIsPdfFormat,
        setError,
        setList,
        setActiveTabId,
        setActiveItem,
        setIsDragging,
      }}
    >
      {children}
    </BuilderContext.Provider>
  );
};

export default BuilderContext;
