import styles from "./styles.module.scss";

import { IItem } from "../../../../../constants";
import { Button, Counter as CounterComponent } from "~components";
import { Return } from "~icons";
import { Fragment } from "react";

interface IProps {
  item: IItem;
  isDisable: boolean;
}

export const TimeMarker = ({ item, isDisable }: IProps) => {
  const parsedItem =
    typeof item.data === "string" ? JSON.parse(item.data) : item.data;

  let buttons =
    typeof item.data === "string"
      ? JSON.parse(item.data).buttons
      : item.data.buttons;

  return (
    <div className={styles.timeMarker}>
      <div className={styles.timeMarker__markButtons}>
        {/* {buttons.map((buttomItem: { title: string }, index: number) => {
          return (
            <div className={styles.timeMarker__markButton}>
              <Button
                disable={isDisable}
                text={buttomItem.title}
                backgroundType={"green"}
              />
            </div>
          );
        })} */}

        {!!parsedItem?.buttonTitle ? (
          <div className={styles.timeMarker__markButton}>
            <Button
              disable={isDisable}
              text={parsedItem.buttonTitle}
              backgroundColor={"#29B473"}
              textColor="#FFFFFF"
            />
          </div>
        ) : (
          buttons.map(
            (buttomItem: { title: string; color: string }, index: number) => {
              return (
                <div
                  key={`${index} - button`}
                  className={styles.timeMarker__markButton}
                >
                  <Button
                    disable={isDisable}
                    text={buttomItem.title}
                    // backgroundType={"zreen"}
                    textColor="#FFFFFF"
                    backgroundColor={
                      buttomItem?.color ? buttomItem.color : "#29B473"
                    }
                  />
                </div>
              );
            }
          )
        )}
      </div>

      <div className={styles.timeMarker__buttons}>
        <div className={styles.timeMarker__returnButton}>
          <Return fill="#DBDEE7" />
        </div>

        <div className={styles.timeMarker__resetButton}>
          <Button
            disable={isDisable}
            text="Reset"
            backgroundType={"white"}
            showShadow
          />
        </div>
      </div>

      {/* <div className={styles.timeMarker__markButton}>
        <Button
          disable={isDisable}
          text={buttonTitle}
          backgroundType={"green"}
        />
      </div> */}
    </div>
  );
};
