import { IData, IError } from "../contants/models";
import { regex } from "~constants";

export const checkDataErrors = (data: IData): IError | null => {
  if (!data.email) {
    return { email: "Email field is requiered" };
  } else if (!regex.email.test(data.email)) {
    return { email: "Invalid email" };
  } else return null;
};

export const isDisabled = (data: IData) => !regex.email.test(data.email);
