import {
  GET_FOLDER,
  GET_ORGANIZATION_HOME_FOLDER_DATA,
  GET_ORGANIZATION_HOME_FOLDER_INITIAL_DATA,
} from "~queries";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { GET_CHOOSED_ORGANIZATIOM, sortMethod, viewTypes } from "~localCache";
import { useUpdateEffect } from "usehooks-ts";
import { useEffect, useState } from "react";
import { IDraft, IFolder } from "~models";

const PAGE_LIMIT = 10;

export const useFolder = () => {
  const params = useParams();
  const navigate = useNavigate();

  const viewType = useReactiveVar(viewTypes);

  const setViewType = (type: "table" | "grid") => {
    viewTypes(type);
  };

  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [folders, setFolders] = useState<IFolder[]>([]);
  const sortBy = useReactiveVar(sortMethod);

  const [getFolder] = useLazyQuery(GET_FOLDER);

  const [fetchedCount, setFetchedCount] = useState(0);
  const [drafts, setDrafts] = useState<IDraft[]>([]);
  const [allDraftsCount, setAllDraftsCount] = useState(0);

  const [searchValue, setSearchValue] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);

  const [folderTitle, setFolderTitle] = useState("");

  const [getData] = useLazyQuery(GET_ORGANIZATION_HOME_FOLDER_DATA);
  const [getInitialData] = useLazyQuery(
    GET_ORGANIZATION_HOME_FOLDER_INITIAL_DATA
  );

  const sortAlphabetically = (data: IDraft[]) => {
    return data.sort((a, b) => {
      if (!a.title) {
        return 1;
      }
      if (!b.title) {
        return -1;
      }
      return a.title.localeCompare(b.title);
    });
  };

  const sortByTime = (data: IDraft[]) => {
    return data.sort((a, b) => {
      if (!a.lastModified) {
        return 1;
      }
      if (!b.lastModified) {
        return -1;
      }
      return (
        new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime()
      );
    });
  };

  const loadData = () => {
    setLoading(true);
    if (!params?.id) return;

    const foldersInput = {
      skip: 0,
      take: 49,
      parentFolderId: +params?.id,
      organizationId: +choosedOrganization.id,
    };

    const countFolderPdf = {
      folderId: +params?.id,
    };

    const countChecklistInput = {
      folderId: +params?.id,
    };

    const input = {
      folderId: +params?.id,
      skip: 0,
      take: PAGE_LIMIT,
    };

    if (!!searchValue) {
      Object.assign(countFolderPdf, { search: searchValue });
      Object.assign(countChecklistInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }

    getInitialData({
      variables: { countFolderPdf, foldersInput, input, countChecklistInput },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setFolders(resp.data.folders);
      fetchedCount !== 0 && setFetchedCount(0);
      setAllDraftsCount(
        resp.data.countFolderChecklist + resp.data.countFolderPdf
      );
      sortBy === "alphabet"
        ? setDrafts(
            sortAlphabetically([
              ...resp.data.checkemHomeFolderData.pdfs,
              ...resp.data.checkemHomeFolderData.checklists,
            ])
          )
        : setDrafts(
            sortByTime([
              ...resp.data.checkemHomeFolderData.pdfs,
              ...resp.data.checkemHomeFolderData.checklists,
            ])
          );
      setLoading(false);
    });
  };

  useEffect(() => {
    if (drafts.length) {
      sortBy === "alphabet"
        ? setDrafts((prev) => sortAlphabetically([...prev]))
        : setDrafts((prev) => sortByTime([...prev]));
    }
  }, [sortBy]);

  const getFolderData = () => {
    if (!params?.id) return;
    getFolder({ variables: { id: +params?.id } }).then((resp) => {
      setFolderTitle(resp.data.folder.title);
    });
  };

  const handleNextPage = () => {
    if (!params?.id) return;

    const maxPage = Math.ceil(allDraftsCount);
    const skip = fetchedCount + PAGE_LIMIT;
    if (skip >= maxPage) return;
    setPaginationLoading(true);
    let take = PAGE_LIMIT;
    if (allDraftsCount - skip < PAGE_LIMIT) {
      take = allDraftsCount - skip;
    }
    const countFolderPdf = {
      folderId: +params?.id,
    };

    const countChecklistInput = {
      folderId: +params?.id,
    };

    const input = {
      folderId: +params?.id,
      skip: skip,
      take: PAGE_LIMIT,
    };
    if (!!searchValue) {
      Object.assign(countFolderPdf, { search: searchValue });
      Object.assign(countChecklistInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countFolderPdf, input, countChecklistInput },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllDraftsCount(
        resp.data.countFolderChecklist + resp.data.countFolderPdf
      );
      setDrafts((prev) => [
        ...prev,
        ...resp.data.checkemHomeFolderData.pdfs,
        ...resp.data.checkemHomeFolderData.checklists,
      ]);
      setPaginationLoading(false);
    });
    setFetchedCount(skip);
  };

  const handlePrevPage = () => {
    if (!params?.id) return;

    const skip = fetchedCount - PAGE_LIMIT;
    if (skip < 0) return;
    setLoading(true);
    const countFolderPdf = {
      folderId: +params?.id,
    };

    const countChecklistInput = {
      folderId: +params?.id,
    };

    const input = {
      folderId: +params?.id,
      skip: skip,
      take: PAGE_LIMIT,
    };
    if (!!searchValue) {
      Object.assign(countFolderPdf, { search: searchValue });
      Object.assign(countChecklistInput, { search: searchValue });
      Object.assign(input, { search: searchValue });
    }
    getData({
      variables: { countFolderPdf, input, countChecklistInput },
      fetchPolicy: "no-cache",
    }).then((resp) => {
      setAllDraftsCount(
        resp.data.countFolderChecklist + resp.data.countFolderPdf
      );
      setDrafts([
        ...resp.data.checkemHomeFolderData.pdfs,
        ...resp.data.checkemHomeFolderData.checklists,
      ]);
      setLoading(false);
    });
    setFetchedCount(skip);
  };

  const goBack = () => {
    window.history.state && window.history.state.idx > 0
      ? navigate(-1)
      : navigate("/dashboard/lists");
  };

  useEffect(() => {
    loadData();
    getFolderData();
  }, [params?.id]);

  useUpdateEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      loadData();
    }, 650);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    drafts,
    searchValue,
    viewType,
    draftsCount: allDraftsCount,
    fetchedCount,
    loading,
    paginationLoading,
    PAGE_LIMIT,
    folderTitle,
    folders,
    goBack,
    setSearchValue,
    setViewType,
    handleNextPage,
    handlePrevPage,
  };
};
