import { gql } from "@apollo/client";

export const CREATE_CHAT = gql`
  mutation createChat($input: CreateChatInput!) {
    createChat(input: $input) {
      id
    }
  }
`;

export const ASK_QUESTION_TO_CHAT = gql`
  mutation askQuestionToChat($input: CreateChatItemInput!) {
    askQuestionToChat(input: $input) {
      id
      data
      type
      rate
      createdAt
    }
  }
`;

export const GET_ANSWER_FROM_CHAT = gql`
  mutation getAnswerFromChat($input: FindAllChatItemsInput!) {
    getAnswerFromChat(input: $input) {
      id
      data
      type
      rate
      createdAt
    }
  }
`;

export const RATE_ANSWER = gql`
  mutation rateAnswe($input: RateChatItemsInput!) {
    rateAnswer(input: $input) {
      id
      rate
    }
  }
`;

export const COMPLETE_CHAT = gql`
  mutation completeChat($id: Int!) {
    completeChat(id: $id) {
      id
    }
  }
`;

export const REMOVE_CHAT = gql`
  mutation removeChat($id: Int!) {
    removeChat(id: $id) {
      id
    }
  }
`;
