import React from "react";
import { Bell, BellOff, Pause, Play, Stopwatch as StopwatchIcon } from "~icons";

import styles from "./styles.module.scss";
import { Button } from "~components";
import { IItem } from "~models";
import { useStopwatch } from "./useStopwatch";
import dayjs from "dayjs";

interface IProps {
  item: IItem;
  isDisable: boolean;
  progressItem: any;
}

export const Stopwatch = ({ item, isDisable, progressItem }: IProps) => {
  const {
    handleStart,
    isRunning,
    handlePause,
    continueTimersAfterEachOther,
    handleReset,
    value,
    disableResetButton,
    toggleMute,
    handleResetWithServer,
  } = useStopwatch({
    item,
    progressItem,
  });

  return (
    <div className={styles.stopwatch}>
      <div className={styles.stopwatch__content}>
        <div className={styles.stopwatch__row}>
          <div className={styles.stopwatch__contentIcon}>
            <StopwatchIcon />
          </div>
          <p>{value}</p>
        </div>
        <div className={styles.stopwatch__contentRight}>
          <div className={styles.stopwatch__contentButton}>
            <Button
              onClick={isRunning ? handlePause : handleStart}
              disable={isDisable}
              icon={isRunning ? <Pause fill="#FFFFFF" /> : <Play />}
              backgroundType={"green"}
              text=""
            />
          </div>
          <div className={styles.stopwatch__contentReset}>
            <Button
              onClick={handleResetWithServer}
              disable={isDisable || disableResetButton}
              backgroundType={"white"}
              text="Reset"
              showShadow
            />
          </div>
          <div className={styles.stopwatch__contentButton}>
            <Button
              onClick={toggleMute}
              disable={isDisable}
              icon={progressItem?.isMuted ? <BellOff /> : <Bell />}
              backgroundType={"white"}
              text=""
              showShadow
            />
          </div>
        </div>
      </div>
      {item.data.record && !!progressItem.firstStart && (
        <p className={styles.stopwatch__date}>
          {!!progressItem.firstStart && (
            <span>
              Start time: {dayjs(progressItem.firstStart).format("hh:mm:ss A")}{" "}
            </span>
          )}
          {!!progressItem.lastPause && (
            <span>
              - End time: {dayjs(progressItem.lastPause).format("hh:mm:ss A")}
            </span>
          )}
        </p>
      )}
    </div>
  );
};
