import { useBuilder } from "../../../../context";
import { IItem } from "../../../../constants";
import { regex } from "~constants";
import { useRef, useState } from "react";
import { useOutsideClick } from "~hooks";
interface IProps {
  item: IItem;
  itemIndex: number;
}

export const useMedCheck = ({ item, itemIndex }: IProps) => {
  const { setList, activeTabIndex, activeItem } = useBuilder();

  const inputRef = useRef<HTMLInputElement>(null);
  const fieldRef = useRef<HTMLDivElement>(null);

  const [inputValue, setInputValue] = useState("");
  const [focus, setFocus] = useState(false);

  const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInputValue(e.target.value);

  const handleActiveFocus = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === event.currentTarget) {
      inputRef.current?.focus();
      setFocus(true);
    }
  };

  const handleDeleteItem = (index: number) => {
    if (!activeItem) return;
    let data = [...item.data];
    data.splice(index, 1);
    setList((prev) => {
      let state = { ...prev };
      state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
        itemIndex
      ].data = data;
      return state;
    });
  };

  const handleDisactiveFocus = () => {
    if (!!inputValue && !!activeItem) {
      let newValues = inputValue.split(",").map((item) => item.trim());
      let data = [...item.data, ...newValues];
      setList((prev) => {
        let state = { ...prev };
        state.tabs[activeTabIndex].sections[activeItem?.sectionIndex].items[
          itemIndex
        ].data = data;
        return state;
      });
      setInputValue("");
    }
    setFocus(false);
  };

  useOutsideClick(fieldRef, handleDisactiveFocus);

  return {
    inputValue,
    inputRef,
    fieldRef,
    focus,
    onChangeInputValue,
    handleActiveFocus,
    handleDeleteItem,
  };
};
