import { useCallback, useEffect, useState } from "react";
import { Area } from "react-easy-crop";
import { getCroppedImg } from "./cropHelper";

interface IProps {
  saveImage: (val: Blob) => void;
}

export const useImagePicker = ({ saveImage }: IProps) => {
  const [image, setImage] = useState<Blob | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");

  const onDrop = useCallback((acceptedFiles: Blob[]) => {
    setImage(acceptedFiles[0]);
  }, []);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const [loading, setLoading] = useState(false);

  const onCropComplete = (
    croppedAreaPercentage: Area,
    croppedAreaPixels: Area
  ) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    setLoading(true);
    if (!croppedAreaPixels || !image?.type) return;
    try {
      let res = await getCroppedImg(imageUrl, croppedAreaPixels, image?.type);
      if (!!res) saveImage(res.file);
      setImage(null);
    } catch (error) {}

    setLoading(false);
  };

  useEffect(() => {
    if (!!image) {
      setImageUrl(URL.createObjectURL(image));
    } else {
      setImageUrl("");
    }
  }, [image]);
  return {
    onDrop,
    imageUrl,
    setImage,
    crop,
    zoom,
    setCrop,
    onCropComplete,
    setZoom,
    loading,
    cropImage,
  };
};
