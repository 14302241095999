import clsx from "clsx";
import {
  CheckCircle,
  Cross,
  CrossInCircle,
  ExclamationCircle,
  InfoCircle,
  Loader,
} from "~icons";
import styles from "./styles.module.scss";
import { useAlert } from "./useAlert";

export const Alert = () => {
  const { data, handleCloseAlert } = useAlert();

  if (!data) return null;

  const getCrossColor = () => {
    switch (data.type) {
      case "red":
        return "#EF2F32";
      case "blue":
        return "#3A6CFF";
      case "green":
        return "#29B473";
      case "orange":
        return "#FDBB12";
    }
  };

  return (
    <div
      className={clsx(styles.alertModule, {
        [styles.red]: data.type === "red",
        [styles.blue]: data.type === "blue",
        [styles.green]: data.type === "green",
        [styles.orange]: data.type === "orange",
      })}
    >
      {data?.isLoading ? (
        <div className={styles.alertModule__loader}>
          <Loader fill={getCrossColor()} />
        </div>
      ) : (
        <>
          {data.type === "red" && <CrossInCircle />}
          {data.type === "blue" && <InfoCircle />}
          {data.type === "green" && <CheckCircle />}
          {data.type === "orange" && <ExclamationCircle />}
        </>
      )}

      <p>{data.text}</p>

      <div className={styles.alertModule__button} onClick={handleCloseAlert}>
        <Cross fill={getCrossColor()} />
      </div>
    </div>
  );
};
