import { Button, Counter, Modal } from "~components";

import styles from "./styles.module.scss";
import { useAddUsersModal } from "./useAddUsersModal";
import { SubscriptionType } from "../../../../../../blocks/CreateOrganization/hooks";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddUser: () => void;
  updateData: () => void;
  subscriptionType: SubscriptionType;
}

export const AddUsersModal = ({
  isOpen,
  onClose,
  updateData,
  handleAddUser,
  subscriptionType,
}: IProps) => {
  const {
    loading,
    isDisable,
    additonalUsers,
    handleCloseModal,
    handleBuyButton,
    onChangeAdditionalUsersCount,
  } = useAddUsersModal({ onClose, handleAddUser, updateData, subscriptionType });
  if (!isOpen) return null;
  return (
    <Modal
      disableMediaStyles
      modalClassName={styles.modal__content}
      onCloseModal={handleCloseModal}
    >
      <div className={styles.modal}>
        <p className={styles.modal__title}>Buy more users</p>
        <div className={styles.modal__row}>
          <p className={styles.modal__rowTitle}>Additional users</p>
          <div className={styles.modal__rowRight}>
            <Counter
              value={additonalUsers}
              onChange={onChangeAdditionalUsersCount}
            />
            <p className={styles.modal__rowPrice}>
              $
              {subscriptionType === SubscriptionType.YEARLY
                ? additonalUsers * 50
                : additonalUsers * 5}{" "}
              USD
            </p>
          </div>
        </div>
        <div className={styles.modal__underline} />
        <div className={styles.modal__button}>
          <Button
            loading={loading}
            onClick={handleBuyButton}
            disable={isDisable}
            backgroundType={isDisable ? "gray" : "green"}
            text="Buy now"
          />
        </div>
      </div>
    </Modal>
  );
};
