import { GET_CHOOSED_ORGANIZATIOM } from "~localCache";
import { useNavigate } from "react-router-dom";
import { IActiveChecklist } from "~models";
import { useQuery } from "@apollo/client";
import { useRef, useState } from "react";

interface IProps {
  activeChecklist: IActiveChecklist;
}

export const useProgressItem = ({ activeChecklist }: IProps) => {
  const navigate = useNavigate();
  const {
    data: { choosedOrganization },
  } = useQuery(GET_CHOOSED_ORGANIZATIOM);

  const [showPopper, setShowPopper] = useState(false);

  const handleOpenPopper = () => setShowPopper(true);
  const handleClosePopper = () => setShowPopper(false);

  const modalRef = useRef<HTMLDivElement>(null);

  const openListPreview = () => {
    if (!activeChecklist.completed) return;
    navigate(
      `/checklist/${activeChecklist.checklist.id}?preview=true&progressId=${activeChecklist.id}`
    );
  };

  const openPdfPreview = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    navigate(
      `/checklist/${activeChecklist.checklist.id}?pdf=true&progressId=${activeChecklist.id}`
    );
  };

  return {
    modalRef,
    myRole: choosedOrganization.members[0].role,
    showPopper,
    openListPreview,
    openPdfPreview,
    handleOpenPopper,
    handleClosePopper,
  };
};
