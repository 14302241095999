import { INotepad } from "~models";
import styles from "./styles.module.scss";
import { Button, Input } from "~components";
import { EmptyItem, Item } from "./components";
import { useNotepadPanel } from "./useNotepadPanel";
import { PlusSymbol } from "~icons";
import clsx from "clsx";

interface IProps {
  notepad: INotepad | null;
  notepadText: string;
  setNotepad: React.Dispatch<React.SetStateAction<INotepad | null>>;
}

export const NotepadPanel = ({ notepad, notepadText, setNotepad }: IProps) => {
  const {
    newDiagnosis,
    showGenerateButtonLoader,
    assessments,
    differentials,
    diagnosis,
    treatments,
    setDiagnosis,
    handleGenerateButton,
    handleSaveDiagnosis,
    handleDeleteDiagnos,
    handleRemoveAssesment,
    handleRemoveDifferentials,
    handleRemoveTreatments,
  } = useNotepadPanel({ notepad, setNotepad, notepadText });

  return (
    <div className={styles.container}>
      <Button
        loading={showGenerateButtonLoader}
        onClick={handleGenerateButton}
        disable={!notepadText}
        backgroundType={"green"}
        text="Generate"
        buttonClassName={styles.button}
      />

      <div className={styles.block}>
        <p className={styles.title}>Consider Assessments:</p>
        {assessments?.length === 0 && (
          <EmptyItem title="Assessments will appear here" />
        )}
        <div className={styles.row}>
          {assessments?.map((item, index) => (
            <Item
              item={item}
              key={`${item.id} - ${index}`}
              onRemove={() => handleRemoveAssesment(index)}
            />
          ))}
        </div>
      </div>

      <div className={styles.block}>
        <p className={styles.title}>Consider Differentials:</p>
        {differentials?.length === 0 && (
          <EmptyItem title="Differentials will appear here" />
        )}
        <div className={styles.row}>
          {differentials?.map((item, index) => (
            <Item
              key={`${item.id} - ${index}`}
              item={item}
              onRemove={() => handleRemoveDifferentials(index)}
            />
          ))}
        </div>
      </div>

      <div className={styles.block}>
        <p className={styles.title}>Working Diagnosis:</p>

        {/* <Button
          disable={!assessments?.length || !differentials?.length}
          buttonClassName={styles.addButton}
          backgroundType={"white"}
          showShadow
          text="Click to add"
          icon={
            <PlusSymbol
              fill={
                !assessments?.length || !differentials?.length
                  ? "#DBDEE7"
                  : "#0B0F1C"
              }
            />
          }
        /> */}
        <div className={styles.row}>
          {diagnosis?.map((item: string, index: number) => (
            <Item
              key={`${item} - ${index}`}
              title={item}
              onRemove={() => handleDeleteDiagnos(index)}
            />
          ))}

          <Input
            disable={!assessments?.length && !differentials?.length}
            value={newDiagnosis}
            onChange={setDiagnosis}
            placeholder="Enter your diagnosis"
            containerClassName={styles.diagnosisInput}
            rightIcon={
              <div
                onClick={handleSaveDiagnosis}
                className={clsx(
                  styles.diagnosisInput__button,
                  ((!assessments?.length && !differentials?.length) ||
                    !newDiagnosis.length) &&
                    styles.disable
                )}
              >
                Save
              </div>
            }
          />
        </div>
      </div>

      <div className={styles.block}>
        <p className={styles.title}>Consider Treatments:</p>
        {treatments?.length === 0 && (
          <EmptyItem title="Treatmetnts will appear here" />
        )}
        <div className={styles.row}>
          {treatments?.map((item, index) => (
            <Item
              key={`${item.id} - ${index}`}
              item={item}
              onRemove={() => handleRemoveTreatments(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
