import { gql } from "@apollo/client";

export const UPDATE_PROGRESS = gql`
  mutation updateProgress($updateProgressInput: UpdateProgressInput!) {
    updateProgress(updateProgressInput: $updateProgressInput) {
      id
    }
  }
`;

export const COMPLETE_PROGRESS = gql`
  mutation completeProgress($completeProgressInput: UpdateProgressInput!) {
    completeProgress(completeProgressInput: $completeProgressInput) {
      id
    }
  }
`;

export const REMOVE_PROGRESS = gql`
  mutation removeProgress($id: Int!) {
    removeProgress(id: $id) {
      id
    }
  }
`;

export const RESET_PROGRESS = gql`
  mutation resetProgress($resetProgressInput: ResetProgressInput!) {
    resetProgress(resetProgressInput: $resetProgressInput) {
      id
      completed
      data
      organizationId
      checklistId
      userId
      createdAt
      updatedAt
    }
  }
`;

export const SET_NOTIFICATION = gql`
  mutation setNotification($input: SetNotificationInput!) {
    setNotification(input: $input)
  }
`;

export const REMOVE_NOTIFICATION = gql`
  mutation stopNotification($input: StopNotificationInput!) {
    stopNotification(input: $input)
  }
`;

export const CREATE_NEW_PROGRESS_BY_CHECKLIST = gql`
  mutation createNewProgressByChecklist($id: Int!) {
    createNewProgressByChecklist(checklistId: $id) {
      id
    }
  }
`;
