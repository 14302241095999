import { gql } from "@apollo/client";

export const GET_CHECKLIST = gql`
  query checklist($id: Int!) {
    checklist(id: $id) {
      id
      title
      version
      latest
      organizationId
      draftId
      tabs {
        id
        name
        order
        checklistId
        draftId
        sections {
          id
          name
          description
          color
          order
          checkbox
          collapsable
          startCollapsable
          checklistTabId
          items {
            id
            description
            type
            data
            checkbox
            collapsable
            order
            startCollapsable
            tabSectionId
          }
        }
      }
    }
  }
`;

export const GET_CHECKLIST_AND_PROGRESS_DATA = gql`
  query getChecklistAndProgressData($progressId: Int!, $checklistId: Int!) {
    getMyProgress(id: $progressId) {
      id
      completed
      data
      organizationId
      checklistId
      userId
      createdAt
      updatedAt
    }
    checklist(id: $checklistId) {
      id
      title
      version
      latest
      organizationId
      draftId
      pdfUrl
      tabs {
        id
        name
        order
        checklistId
        draftId
        sections {
          id
          name
          description
          color
          order
          checkbox
          collapsable
          startCollapsable
          checklistTabId
          items {
            id
            description
            type
            data
            checkbox
            collapsable
            order
            startCollapsable
            tabSectionId
          }
        }
      }
    }
  }
`;

export const GET_CHECKLIST_AND_CREATED_PROGRESS_DATA = gql`
  query getChecklistAndCreatedProgressData(
    $progressId: Int!
    $checklistId: Int!
  ) {
    progress(id: $progressId) {
      id
      completed
      data
      organizationId
      checklistId
      userId
      createdAt
      updatedAt
      user {
        id
        email
      }
    }
    checklist(id: $checklistId) {
      id
      title
      version
      latest
      organizationId
      draftId
      pdfUrl
      tabs {
        id
        name
        order
        checklistId
        draftId
        sections {
          id
          name
          description
          color
          order
          checkbox
          collapsable
          startCollapsable
          checklistTabId
          items {
            id
            description
            type
            data
            checkbox
            collapsable
            order
            startCollapsable
            tabSectionId
          }
        }
      }
    }
  }
`;

export const GET_CHECKLISTS = gql`
  query checklists($input: FindChecklistInput!) {
    checklists(input: $input) {
      id
      title
      version
      latest
      organizationId
      draftId
    }
  }
`;

export const GET_CHECKLISTS_PAGINATION_DATA = gql`
  query getChecklistsPaginationData(
    $countInput: CountChecklistInput!
    $checklistsInput: FindChecklistInput!
  ) {
    countChecklist(input: $countInput)
    checklists(input: $checklistsInput) {
      id
      title
      version
      latest
      organizationId
      draftId
    }
  }
`;

//=====================NEW========================

export const GET_ORGANIZATION_HOME_DATA_NEW = gql`
  query getOrganizationHomeDataNew(
    $input: FindChecklistInput!
    $countPdfInput: CountPdfInput!
    $countChecklistInput: CountChecklistInput!
  ) {
    checkemHomeData(input: $input) {
      pdfs {
        id
        title
        status
        file
        type
      }
      checklists {
        id
        title
        version
        type
      }
    }
    countPdf(input: $countPdfInput)
    countChecklist(input: $countChecklistInput)
  }
`;

export const GET_ORGANIZATION_HOME_DATA_AND_FOLDERS_NEW = gql`
  query getOrganizationHomeDataAndFoldersNew(
    $input: FindChecklistInput!
    $folderInput: FindFolderInput!
    $countPdfInput: CountPdfInput!
    $countChecklistInput: CountChecklistInput!
  ) {
    folders(input: $folderInput) {
      id
      title
    }
    checkemHomeData(input: $input) {
      pdfs {
        id
        title
        status
        file
        type
      }
      checklists {
        id
        title
        version
        type
      }
    }
    countPdf(input: $countPdfInput)
    countChecklist(input: $countChecklistInput)
  }
`;

// export const GET_ORGANIZATION_HOME_DATA_NEW = gql`
//   query getOrganizationHomeDataNew(
//     $input: FindChecklistInput!
//     $countPdfInput: CountPdfInput!
//     $countChecklistInput: CountChecklistInput!
//   ) {
//     checkemHomeData(input: $input) {
//       pdfs {
//         id
//         title
//         status
//         file
//         type
//       }
//       checklists {
//         id
//         title
//         version
//         type
//       }
//     }
//     countPdf(input: $countPdfInput)
//     countChecklist(input: $countChecklistInput)
//   }
// `;
