import { useState } from "react";
import { alertVar } from "~localCache";
import { uploadFile } from "~services";
import { useListBuilder } from "../../useListBuilder";
import { useBuilder } from "../../context";
import { useMutation } from "@apollo/client";
import { REMOVE_FILE_BY_URL, UPDATE_DRAFT } from "~mutations";
import { PublishingStatus } from "~models";

export const usePdfTab = () => {
  const { list, setList } = useBuilder();
  const [updateDraft] = useMutation(UPDATE_DRAFT);
  const [removeFile] = useMutation(REMOVE_FILE_BY_URL);

  const [showLoader, setShowLoader] = useState(false);

  const removePdfUrl = () => {
    let url = list.pdfUrl;
    setList((prev) => {
      let state = { ...prev };
      state.pdfUrl = null;
      state.pdfName = null;
      return state;
    });

    updateDraft({
      variables: {
        input: {
          id: list.id,
          pdfUrl: null,
          pdfName: null,
        },
      },
    });

    removeFile({ variables: { input: { url } } });
  };

  const updatePdfUrlOnServer = (url: string, name: string) => {
    updateDraft({
      variables: {
        input: {
          id: list.id,
          pdfUrl: url,
          pdfName: name,
        },
      },
    }).then(() => {
      setShowLoader(false);
      setList((prev) => {
        let state = { ...prev };
        state.pdfUrl = url;
        state.pdfName = name;
        return state;
      });
    });
  };

  const onDrop = async (files: any) => {
    if (files.length > 0) {
      setShowLoader(true);
      const url = await uploadFile(files[0], files[0].type);
      updatePdfUrlOnServer(url, files[0].name);
    } else {
      alertVar({ type: "red", text: "This file is not a pdf file" });
    }
  };
  return { list, showLoader, onDrop, removePdfUrl };
};
