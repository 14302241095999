import { FC, ReactNode, useEffect } from "react";
import style from "./styles.module.scss";
import { Cross } from "~icons";
import clsx from "clsx";
import ReactModal from "react-modal";

interface IProps {
  children: ReactNode;
  containerClassName?: string;
  modalClassName?: string;
  onCloseModal: () => void;
  hideCross?: boolean;
  disableMediaStyles?: boolean;
}

export const Modal: FC<IProps> = ({
  children,
  containerClassName,
  modalClassName,
  onCloseModal,
  hideCross,
  disableMediaStyles,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const customStyles = {
    overlay: {
      transition: "none",
      opacity: 1,
      overflow: "auto",
      zIndex: 2000,
    },
    content: {
      outline: "none",
      border: 0,
    },
  };

  return (
    <ReactModal
      isOpen
      className={clsx(
        style.modalBlock,
        { [style.fullScreen]: !disableMediaStyles },
        containerClassName
      )}
      overlayClassName={style.modalBlock__overlay}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onCloseModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={clsx(
          style.modalBlock__modal,
          { [style.fullScreen]: !disableMediaStyles },
          modalClassName
        )}
      >
        {!hideCross && (
          <div onClick={onCloseModal} className={style.modalBlock__modalIcon}>
            <Cross fill="#0B0F1C" />
          </div>
        )}

        {children}
      </div>
    </ReactModal>
  );
};
