import { useChecklist } from "../../useChecklist";
import { IDraft } from "~models";
import { FC } from "react";

import styles from "./styles.module.scss";
import { Loader } from "~icons";
import { useReactiveVar } from "@apollo/client";
import { darkTheme } from "~localCache";
import clsx from "clsx";

interface IProps {
  checklist: IDraft;
}

export const ChecklistItemImportSquare: FC<IProps> = ({
  checklist,
}: IProps) => {
  const { showLoader, handleImportButton } = useChecklist({
    checklist,
  });
  const isDark = useReactiveVar(darkTheme)

  return (
    <div>
      <div className={styles.draftItem}>
        <div className={isDark ? clsx(styles.content, styles.contentDark) : styles.content}>
          <div>
            <p className={styles.content__title}>{checklist.title}</p>
            <p className={styles.content__author}>
              {checklist.firstName} {checklist.lastName}
            </p>
          </div>
          <div onClick={handleImportButton} className={styles.content__button}>
            {showLoader ? (
              <Loader fill="#FFFFFF" />
            ) : (
              <p>Import to Checkem Builder</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
