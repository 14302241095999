import { Avatar, PageHeader, SearchInput, Table } from "~components";
import { useMyRequests } from "./useMyRequests";
import { IOrganizationRequest } from "~models";
import { Loader } from "~icons";
import dayjs from "dayjs";
import clsx from "clsx";

import styles from "./styles.module.scss";
import { useHomePage } from "../AllOrganizations/useHomePage";
import { ActiveListsModule } from "../../../../navigations/Dashboard/LeftPanel/modules";

export const MyRequests = () => {
  const {
    searchValue,
    showLoaderIndex,
    myOrganizationRequests,
    setSearchValue,
    handleCancelRequest,
  } = useMyRequests();
  const { pageHeader } = useHomePage();

  return (
    <>
      <div className={styles.sentRequest__search}>
        <SearchInput
          placeholder="Search for companies"
          value={searchValue}
          onChange={setSearchValue}
        />
        <ActiveListsModule noLabel />
      </div>

      <div style={{ marginBottom: 20 }}>
        <PageHeader data={pageHeader} />
      </div>

      <Table
        header={[
          {
            header: "Organization",
            className: styles.sentRequest__organizationHeader,
          },
          { header: "Members", className: styles.sentRequest__membersHeader },
          { header: "Request sent", className: styles.sentRequest__roleHeader },
        ]}
        data={myOrganizationRequests}
        renderTableItem={(item: IOrganizationRequest, index) => {
          const showLoader = index === showLoaderIndex;
          return (
            <div>
              <div className={styles.sentRequest}>
                <div className={styles.sentRequest__organization}>
                  {/* <div className={styles.sentRequest__organizationAvatar}>
                    {!!item.organization.picture && (
                      <img src={item.organization.picture} />
                    )}
                  </div> */}
                  <Avatar
                    uri={item.organization.picture}
                    item={item.organization}
                    type="organization"
                    containerStyles={styles.sentRequest__organizationAvatar}
                  />
                  {item.organization.name}
                </div>

                <div className={styles.sentRequest__members}>
                  {item.organization?._count?.members} members
                  {/* {!!item.invations && (
                  <div className={styles.sentRequest__membersCounter}>
                    <NumberWidget
                      text={item.invations}
                      backgroundType={"red"}
                    />
                  </div>
                )} */}
                </div>

                <div className={styles.sentRequest__role}>
                  {dayjs(item.createdAt).format("MMMM D, YYYY")}

                  <div className={styles.sentRequest__roleRight}>
                    <div
                      className={clsx(styles.sentRequest__roleButton, {
                        [styles.showText]: !showLoader,
                      })}
                      onClick={() => handleCancelRequest(index, item.id)}
                    >
                      {showLoader && <Loader fill="#0B0F1C" />}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.sentRequest__underline} />
            </div>
          );
        }}
      />
    </>
  );
};
